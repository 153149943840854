import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  buyerDetailsWrapper: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} `,
    "@media(max-width:640px)":{
      height:"100%",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      fontSize: 12,
    },
    "& .continueBtn": {
      marginTop: theme.spacing(2),
    },
    "& .dudaBuyerContinueBtn":{
      marginTop:"3rem !important",
      "@media(max-width:640px)":{
        marginTop:"1.6rem !important"
      }
    }
  },
  scrollingWrapper:{
      overflowY:"auto",
      paddingRight:"1.1rem",
      overflowX:"hidden",
      paddingTop:theme.spacing(1), 
      "&.dudaBuyerScroll":{
        maxHeight:"100%",
        minHeight:"auto",
        overflow:"inherit",
        "@media(max-width:640px)":{
          maxHeight:"calc((var(--vh, 1vh) * 100) - 23rem)",
          minHeight:"calc((var(--vh, 1vh) * 100) - 23rem)",
        }
      }
  },
  fullWidth: {
    width: "100%",
  },
  ticketGenerateLanding:{
    "& .MuiFormControlLabel-root":{
      "& svg":{
           height:"20px",width:"20px",
           fontSize:"14px",
         }
     },
  },
  mobFooterStyle: {
    marginTop: "0px",
    paddingTop: theme.spacing(2),
    overflowY: "auto",
    // maxHeight: "calc((var(--vh, 1vh) * 100) - 4rem)",
    "& .continueBtn": {
      position: "absolute",
      bottom: 0,
      width: "100%",
      background:theme.palette.background.cardColor,
      left: 0,
      padding: theme.spacing(2),
      borderTop:`1px solid ${theme.palette.divider.default}`,
    },
    "& .title": {
      marginTop: theme.spacing(5),
    },
  },
  widgetBuyerDetails:{
    margin:0,
    height:"100%",
    position:"relative",
    padding:`${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    overflow:"hidden",
    borderRadius:theme.spacing(1),
    background:theme.palette.background.cardColor,
    "& .widgetBuyerScrolling":{
      maxHeight:"calc(100% - 18.5rem)",
      minHeight:"calc(100% - 18.5rem)",
      paddingBottom:"4.8rem",
      "@media(max-width:480px)":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 25rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 25rem)",
        paddingBottom:"3.2rem",
        overflowX:"hidden",
      },
    },
   "@media(max-width:480px)":{
      maxHeight:"100%",
      overflow:"hidden",
      height:"100%",
      paddingRight:theme.spacing(1),
    },
    "& .continueBtn":{
      background: `linear-gradient(to bottom, ${theme.palette.background.cardColor}, ${theme.palette.background.lightGray})`,
      position:"absolute",
      bottom:"0rem",
      width:"100%",
      left:0,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      "&.scrollContinue":{
        opacity:0,
      },
      "&.scrollstop":{
        opacity: 1,
        transition: "opacity .4s ease-out",
      },
      "&>button":{
        width:320,
        "@media(max-width:480px)":{
            width:"100%",
            margin:"0px auto"
        }
      }
    }
  },
 
  landingBuyerDetails:{
    margin:0,
    padding:0,
    position:"relative",
    height:"calc((var(--vh, 1vh) * 100) - 0rem)",
    "@media(max-width:640px)":{
      padding:theme.spacing(2),
      maxHeight:"100%",
      height:"100%",
    },
    "& .landingScrollingWrapper":{
      maxHeight:"calc((var(--vh, 1vh) * 100) - 24rem)",
      minHeight:"calc((var(--vh, 1vh) * 100) - 24rem)",
      paddingLeft:theme.spacing(2.5),
      paddingRight:theme.spacing(2.5),
      "@media(max-width:1140px)":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 27rem)",
        
        minHeight:"calc((var(--vh, 1vh) * 100) - 27rem)",
      },
      "@media(max-width:640px)":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 28rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 28rem)",
        paddingLeft:theme.spacing(0),
        paddingRight:theme.spacing(0),
      },
    },
    "& .continueBtn":{
      position:"absolute",
      bottom:0,
      left:0,
      background:theme.palette.background.cardColor,
      padding:theme.spacing(2.5),
      borderTop:`1px solid ${theme.palette.divider.default}`,
      width:"100%"
    }
  }
}));
