export default {
        "already-registered": "Już zarejestrowany?",
        "add-to-calendar": "Dodaj do kalendarza",
        "event-starts-in": "Wydarzenie rozpoczyna się za",
        "event-is-live": "WYDARZENIE NA ŻYWO",
        "event-has-ended": "WYDARZENIE ZAKOŃCZYŁO SIĘ",
        "powered-by": "Obsługiwane przez",
        "please-enter-your-email-to-continue": "Aby kontynuować, wpisz swój adres e-mail",
        "add": "Dodać",
        "offers": "Oferuje",
        "free": "Bezpłatny",
        "available-till": "Dostępne do {{DATE}}",
        "enter-email-address": "Wprowadź adres e-mail",
        "sold-out": "Wyprzedane",
        "the-ticket-has-reached-the-maximum-number": "Bilet osiągnął maksymalną liczbę",
        "seat-left": "{{SEAT}} lewe siedzenie",
        "seats-left": "{{SEAT}} pozostały miejsca",
        "proceed": "Przystępować",
        "summary": "Streszczenie",
        "buyer-info": "Informacje o kupującym",
        "attendees-info": "Informacje o uczestnikach",
        "registration": "Rejestracja",
        "continue": "Kontynuować",
        "attendees-information": "Informacje o uczestniku",
        "submit": "Przesyłać",
        "register-to-join-the-events": "Zarejestruj się, aby dołączyć do wydarzeń",
        "by-clicking-on-the-button": "Po kliknięciu przycisku zostaniesz przekierowany na platformę sprzedaży biletów, gdzie będziesz mógł zarejestrować się na to wydarzenie.",
        "other": "Inny",
        "please-enter-a-value-for": "Wprowadź wartość dla <FIELD_NAME>",
        "please-select-a-value-for": "Wybierz wartość dla <FIELD_NAME>",
        "enter-other-value-for": "Wprowadź inną wartość dla <FIELD_NAME>",
        "enter-a-valid": "Wprowadź prawidłowe <FIELD_NAME>",
        "should-be-at-least": "Wartość <FIELD_NAME> powinna wynosić co najmniej <FIELD_LENGTH> <FIELD_TYPE>",
        "cannot-be-more-than": "Wartość <FIELD_NAME> nie może być większa niż <FIELD_LENGTH> <FIELD_TYPE>",
        "is-required": "<FIELD_NAME> jest wymagane",
        "at-least-required": "Co najmniej <FIELD_LENGTH> <FIELD_NAME> <VERB> wymagany",
        "maximum-allowed": "Maksymalnie <FIELD_LENGTH> <FIELD_NAME> <VERB>dozwolony",
        "user-with-same-email-id-already-exists": "Użytkownik o tym samym identyfikatorze e-mail już istnieje. Proszę użyć innego.",
        "user-with-same-email-id-has-already-registered-for-this-event": "Użytkownik o tym samym identyfikatorze e-mail zarejestrował się już na to wydarzenie. Proszę użyć innego.",
        "mail-successfully-sent": "Poczta została pomyślnie wysłana!",
        "we-are-unable-to-deliver-your-mail-at-this-moment": "W tej chwili nie możemy dostarczyć twojej poczty! Spróbuj za jakiś czas",
        "something-went-wrong": "Coś poszło nie tak. Naprawiamy to. Przepraszamy za powstałe niedogodności.",
        "register-now": "Zarejestruj się teraz",
        "add-to-your-calendar": "Dodaj do kalendarza",
        "please-select-a-ticket-to-proceed": "Aby kontynuować, wybierz bilet",
        "resend-link": "Wyślij ponownie link",
        "go-to-event": "Przejdź do Wydarzenia",
        "ticket-registration-done-by": "Rejestracja biletów wykonana przez",
        "you-have-already-purchased-the-ticket-for": "Ty masz już zakupiona bilet na",
        "purchased": "Zakupione",
        "event-date": "Data wydarzenia",
        "ticket-quantity": "Ilość biletów",
        "payment": "Zapłata",
        "unable-to-submit-data": "Niezdolny do przesłania danych",
        "unable-to-apply-promo-code": "Niezdolny aby zastosować kod promocyjny",
        "invalid-promo-code": "Nieprawidłowa promocja kod",
        "apply-now": "Zastosować",
        "enter-discount-coupon": "Wprowadź rabat kupon",
        "same-as-buyer": "Tak samo jak kupujący",
        "please-check-all-the-fields-are-correctly-filled": "Sprawdź, czy wszystkie pola są poprawnie wypełnione",
        "ticket-purchased": "Bilet zakupiony!",
        "ticket-purchase-failed": "Zakup biletu nie powiódł się!",
        "you-have-successfully-purchased-the-ticket": "Ty masz pomyślnie zakupił bilet. Dzięki temu biletowi będziesz mógł wziąć udział w wydarzeniu. Dziękujemy za zakupy!",
        "there-was-an-error-while-booking-your-tickets": "Wystąpił błąd podczas rezerwacji biletów. Proszę spróbuj ponownie.",
        "try-again": "Spróbuj ponownie",
        "you-dont-have-access": "Ty nie masz możliwości zakupu niektórych biletów",
        "please-enter-a-valid-email": "Proszę podać poprawny adres e-mail",
        "please-enter-email-to-continue": "Wpisz adres e-mail, aby kontynuować",
        "payment-summary": "Podsumowanie płatności",
        "apply-discount": "Pomyślnie zastosowano {{CODE}}. Zaoszczędziłeś dodatkowe {{AMOUNT}}.",
        "and": "I",
        "taxation-concent": "Chcesz wygenerować fakturę VAT?",
        "taxation-tnc-pp": "Kontynuując, zgadzasz się z naszymi",
        "taxation-tnc": "Zasady i warunki",
        "taxation-pp": "Polityka prywatności",
        "company-name-label": "Nazwa firmy",
        "company-name-place-holder": "Wprowadź nazwę firmy",
        "taxId-label": "Firma Podatek ID",
        "taxId-place-holder": "Wchodzić Firma Podatek ID",
        "company-address-label": "Adres firmy",
        "company-address-place-holder": "Adres",
        "country-place-holder": "Wybierz kraj",
        "state-place-holder": "Wybierz Stan",
        "city-place-holder": "Wpisz Miasto",
        "zipcode-label": "Kod pocztowy",
        "zipcode-place-holder": "Wprowadź kod pocztowy",
        "company-error-msg": "Proszę wpisać nazwę firmy",
        "taxId-error-msg": "Proszę wpisać firmę Podatek ID",
        "address-error-msg": "Proszę wpisać adres",
        "country-error-msg": "Proszę wybrać kraj",
        "state-error-msg": "Proszę wybrać stan",
        "city-error-msg": "Proszę wpisać Miasto",
        "zipcode-error-msg": "Proszę wprowadzić kod pocztowy",
        "firstName": "Pierwsze imię",
        "lastName": "Nazwisko",
        "email": "E-mail",
        "phone": "Telefon",
        "dialerCode": "Kod dialera",
        "you-have-already-purchased-ticket-for": "Kupiłeś już bilet na",
        "browse-a-file-to-upload": "Przeglądaj plik do przesłania",
        "upload-file": "Przesyłanie pliku",
        "max-size": "największy rozmiar",
        "file-supported-types": "Plik obsługiwane typy",
        "uploaded-successfully": "Przesłano pomyślnie",
        "failed!-please-retry": "Przegrany! Proszę spróbuj ponownie",
        "this-file-type-is-not-supported-supported-attachments-are": "Ten typ pliku nie jest obsługiwany. Obsługiwane załączniki to",
        "the-file-size-is-too-high-please-upload-document-lesser-than": "Plik rozmiar jest za wysoki. Proszę przesłać dokument mniejszy niż",
        "person": "Osoba",
        "select": "Wybierać",
        "search": "Szukaj",
        "uploaded-file": "Przesłany plik",
        "uploading": "Przesyłanie",
        "delete": "Usuwać",
        "no-item": "Brak przedmiotu",
        "read-more": "Czytaj więcej",
        "read-less": "Mniej czytać",
        "about-title": "Około",
        "google-calendar": "Google  Kalendarz",
        "outlook-calendar": "Outlooka Kalendarz",
        "yahoo-calendar": "Yahoo Kalendarz",
        "verb-are": "Czy",
        "verb-is": "jest",
        "character": "postać",
        "date-format": "DD / MM / RRRR",
        "timer-hr": "godzina",
        "timer-hrs": "godziny",
        "timer-min": "minuta",
        "timer-mins": "minuty",
        "timer-sec": "drugi",
        "timer-secs": "sekundy",
        "timer-day": "dzień",
        "timer-days": "dni",
        "select-file-title": "Nie wybrano żadnego pliku",
        "today": "Dzisiaj",
        "yesterday": "Wczoraj",
        "tomorrow": "Jutro",
        "last": "Ostatni",
        "internet-down": "Internet wydaje się być odłączony!",
        "internet-up": "Internet jest teraz podłączony",
        "network-issue": "Wystąpił problem z siecią. Komunikat o błędzie. Spróbuj ponownie!",
        "registrationSuccess": "Rejestracja pomyślnie zakończona!",
        "registrationSuccessDetail": "Pomyślnie zarejestrowałeś się na wydarzenie. Dziękuję!",
        "search-here": "Szukaj tutaj..",
        "did-you-mean": "Miałeś na myśli",
        "DISPOSABLE_EMAIL": "Podany adres e-mail należy do jednorazowej usługi pocztowej",
        "RISTRICTED_DOMAIN": "Podany adres e-mail znajduje się w domenie zastrzeżonej. Proszę wpisać aktualny adres e-mail",
        "INVALID_EMAIL": "Proszę podać poprawny adres e-mail",
        "ORDERED": "Zamówione",
        "CHOOSE_PAYMENT_METHOD": "Wybierz metodę płatności",
        "PAY_NOW": "Zapłać teraz",
        "INSTANT_TICKET_CONFIRMATION": "Natychmiastowe potwierdzenie biletu",
        "PAY_VIA_OTHER_METHODS": "Zapłać innymi metodami",
        "FUNDS_TRANSFER": "Transfer funduszy",
        "DIGITAL_WALLET": "Cyfrowy portfel",
        "CHEQUE": "Sprawdzać",
        "PAY_AT_VENUE": "Zapłać na miejscu",
        "TICKET_NOT_CONFIRMED_ORGANIZER": "Bilety nie zostaną potwierdzone do czasu potwierdzenia przez organizatora wydarzenia otrzymania płatności i podlegają anulowaniu.",
        "TICKET_NOT_CONFIRMED_UNTIL_PAYMENT": "Bilety nie zostaną potwierdzone do czasu dokonania płatności na miejscu wydarzenia i podlegają anulowaniu.",
        "TICKET_CONFIRMED_BY_ORGANIZER": "Bilety zostaną potwierdzone, gdy organizator wydarzenia zatwierdzi otrzymanie płatności.",
        "COMPLETE_PAYMENT_OF": "Całkowita płatność",
        "COMPLETE_PAYMENT_TICKET": "Dokonaj płatności za bilet(y).",
        "PAYMENT_CONFIRMATION_TITLE": "Wprowadź unikalny identyfikator transakcji dla organizatora wydarzenia, aby zweryfikować płatność.",
        "ENTER_TRANSACTION_ID": "Wprowadź transakcję ID",
        "SHARE_DETAILS_TITLE": "Organizator wydarzenia może zatwierdzić Twoją płatność tylko wtedy, gdy udostępnisz szczegóły. Twoje bilety, w tym bilety bezpłatne, zostaną potwierdzone po zatwierdzeniu.",
        "UPLOAD_SCREENSHOT": "Prześlij zrzut ekranu (opcjonalnie)",
        "SHARE_PAYMENT_DETAILS": "Udostępnij szczegóły płatności",
        "TICKET_STATUS": "Stan biletu",
        "ORGANIZER_YET_TO_CONFIRM": "Organizator wydarzenia nie potwierdził jeszcze otrzymania płatności.",
        "TICKET_CONFIRMATION_ORGANIZER": "Twoje bilety, w tym bilety bezpłatne, oraz faktura zostaną potwierdzone i udostępnione po zatwierdzeniu przez organizatora wydarzenia.",
        "PAYMENT_DETAILS": "Szczegóły płatności",
        "FAQ_TITLE": "W przypadku jakichkolwiek pytań, zapoznaj się z często zadawanymi pytaniami",
        "MAKE_PAYMENT_TITLE": "Dokonaj płatności na miejscu wydarzenia, aby potwierdzić zakup biletu(ów).",
        "CONFIRM_TICKET_PRIOR_EVENT": "Jeśli chcesz potwierdzić swój bilet(y) przed wydarzeniem, zapłać teraz inną metodą.",
        "CONFIRM_TICKETS_PRIOR_EVENT": "Jeśli chcesz potwierdzić swój bilet(y) przed wydarzeniem,",
        "PAY_NOW_2": "Zapłać teraz",
        "WITH_DIFFERENT_METHOD": "z innym metoda",
        "PAYMENT_NOT_RCVD": "Organizator wydarzenia nie otrzymał wpłaty.",
        "PAYMENT_COULD_NOT_BE_VERIFIED": "Organizator wydarzenia nie mógł zweryfikować podanych szczegółów płatności.",
        "TICKETS_NOT_AVAILABLE": "Bilety na to wydarzenie nie są już dostępne.",
        "PAYMENT_DETAILS_YET_TO_SHARE": "Jeszcze jesteś udostępnić szczegóły płatności za bilet(y).",
        "WELCOME_BACK": "Witamy spowrotem!",
        "VIEW_OR_PROCEED_PURCHASE": "Możesz zobaczyć swoje poprzednie bilety lub kontynuować zakup wybranych biletów.",
        "VIEW_PREV_TICKETS": "Zobacz poprzednie bilety",
        "PROCEED_TO_PURCHASE": "Przejdź do zakupu",
        "PREVIOUS_TICKETS": "Poprzednie bilety",
        "PENDING_TICKETS": "Oczekujące bilety",
        "CONFIRMED_TICKETS": "Potwierdzone bilety",
        "PAYMENT_PENDING": "Płatność oczekująca",
        "AWAITING_ORG_APPROVAL": "Oczekuje na akceptację organizatora",
        "CHECK_TICKET_STATUS": "Sprawdź status biletu",
        "PAYMENT_METHOD_NOT_AVAILABLE": "Zapłata metoda jest niedostępna, spróbuj ponownie.",
        "HERE": "Tutaj.",
        "RE_ENTER_EMAIL": "Prosimy o zaktualizowanie unikalnych informacji o uczestniku dla wszystkich zakupionych biletów. Dzięki temu wszyscy uczestnicy będą mieli dostęp do wydarzenia.",
        "DUPLICATE_EMAIL": "Kupiłeś już ten bilet. Możesz zdecydować się na ponowną rejestrację. W takim przypadku zapisane zostaną ostatnio wprowadzone dane.",
        "DEFAULT_DUPLICATE_EMAIL": "Ten adres e-mail jest już wykorzystany w innym zgłoszeniu. Sprawdź dokładnie wprowadzony adres e-mail lub możesz użyć tego samego adresu e-mail w przypadku wielu biletów.",
        "REGISTER_NOW": "Zarejestruj się teraz",
        "REG_FAILED": "Rejestracja nie powiodła się",
        "REG_FAILED_DESC": "Twoja rejestracja nie powiodła się. Proszę spróbuj ponownie.",
        "REG_DONE_BY": "Rejestracja dokonana przez",
        "INVITE_AGAIN": "Chcesz zaproszenie ponownie?",
        "REG_SUCCESS_DONE": "Rejestracja została pomyślnie wykonana",
        "WATCH_REC_LINK": "Na adres e-mail został wysłany link umożliwiający obejrzenie nagrania",
        "WEBINAR_INV_LINK": "E-mail z zaproszeniem zawierający link do webinaru został wysłany na adres",
        "MAIL_SENT_SUCCESS": "Poczta została pomyślnie wysłana",
        "ALREADY_REGISTERED": "Już się zarejestrowałeś",
        "RESEND_INVITE": "Wyślij ponownie zaproszenie",
        "WATCH_RECORDING_REGISTER_DESC": "Nagranie webinaru możesz obejrzeć rejestrując się poniżej.",

    };