// Language: Chinese

export default {
    "already-registered": "已注册?",
    "add-to-calendar": "添加到日历",
    "event-starts-in": "活动始于",
    "event-is-live": "活动现场直播",
    "event-has-ended": "活动已经结束",
    "powered-by": "技术支持",
    "please-enter-your-email-to-continue": "请输入您的电子邮件以继续",
    add: "添加",
    offers: "提供",
    free: "免费",
    "available-till": "有效期至 {{DATE}}",
    "enter-email-address": "输入电子邮件地址",
    "sold-out": "售出",
    "the-ticket-has-reached-the-maximum-number": "门票已达到最大数量",
    "seat-left": "剩余 {{SEAT}} 个座位",
    "seats-left": "剩余 {{SEAT}} 个座位",
    proceed: "继续",
    summary: "总结",
    "buyer-info": "买家信息",
    "attendees-info": "与会者信息",
    "registration": "注册",
    continue: "持续",
    "attendees-information": "与会者信息",
    submit: "提交",
    "register-to-join-the-events": "报名参加活动",
    "by-clicking-on-the-button": "点击按钮，您将被重定向到票务平台，您可以在那里注册此活动。",
    other: "其他",
    "please-enter-a-value-for": "请为 <FIELD_NAME> 输入一个值",
    "please-select-a-value-for": "请为 <FIELD_NAME> 选择一个值",
    "enter-other-value-for": "为 <FIELD_NAME> 输入其他值",
    "enter-a-valid": "输入有效的 <FIELD_NAME>",
    "should-be-at-least": "<FIELD_NAME> 应至少为 <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> 不能超过 <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> 是必需的。",
    "at-least-required": "至少需要 <FIELD_LENGTH> <FIELD_NAME> <VERB>",
    "maximum-allowed": "允许最大 <FIELD_LENGTH> <FIELD_NAME> <VERB>",
    "user-with-same-email-id-already-exists": "已存在具有相同电子邮件账号的用户。请换一个。",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "具有相同电子邮件账号的用户已为此活动注册。请换一个。",
    "mail-successfully-sent": "邮件成功发送!",
    "we-are-unable-to-deliver-your-mail-at-this-moment": "我们现在无法发送您的邮件! 请稍后再试",
    "something-went-wrong": "出了点问题! 请稍后再试",
    "register-now": "现在注册",
    "add-to-your-calendar": "添加到您的日历中",
    calendar: "日历",
    "please-select-a-ticket-to-proceed": "请选择要继续的门票",
    "resend-link": "重发链接",
    "go-to-event": "参加活动",
    "ticket-registration-done-by": "票务登记由...完成",
    "you-have-already-purchased-the-ticket-for": "您已经为...买了门票",
    purchased: "购买",
    "event-date": "活动日期",
    "ticket-quantity": "票数",
    payment: "付款",
    "unable-to-submit-data": "无法提交数据",
    "unable-to-apply-promo-code": "无法应用折扣代码",
    "invalid-promo-code": "无效的折扣代码",
    "apply-now": "申请",
    "enter-discount-coupon": "输入折扣券",
    "same-as-buyer": "与买方相同",
    "please-check-all-the-fields-are-correctly-filled": "请检查所有字段是否正确填写",
    "ticket-purchased": "购票",
    "ticket-purchase-failed": "购票失败",
    "you-have-successfully-purchased-the-ticket": "您已成功购买了车票。这张票可以让您参加活动。谢谢您的购买!",
    "there-was-an-error-while-booking-your-tickets": "订票时出错。请再试一次。",
    "try-again": "再试一次",
    "you-dont-have-access": "您没有权利买一些票",
    "please-enter-a-valid-email": "请输入有效的电子邮件",
    "please-enter-email-to-continue": "请输入您的电子邮件以继续",
    "payment-summary": "付款汇总表",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}}成功应用。你保存了额外的{{AMOUNT}}。",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "想要生成一张税务发票？",
    "taxation-tnc-pp": "继续您同意我们的",
    "taxation-tnc": "条款和条件",
    and: "和",
    "taxation-pp": "隐私政策",
    "company-name-label": "公司名称",
    "company-name-place-holder": "输入公司名称",
    "taxId-label": "公司税号",
    "taxId-place-holder": "输入公司税号",
    "company-address-label": "公司地址",
    "company-address-place-holder": "地址",
    "country-place-holder": "选择国家",
    "state-place-holder": "选择国家",
    "city-place-holder": "输入城市",
    "zipcode-label": "邮政编码",
    "zipcode-place-holder": "输入邮编",
    "company-error-msg": "请输入公司名称",
    "taxId-error-msg": "请输入有效的公司税号",
    "address-error-msg": "请输入地址",
    "country-error-msg": "请选择国家",
    "state-error-msg": "请选择国家",
    "city-error-msg": "请输入城市",
    "zipcode-error-msg": "请输入邮政编码",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "名",
    lastName: "姓",
    email: "电子邮件",
    phone: "电话",
    dialerCode: "拨号代码",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "你已经购买了以下的门票",
    "upload-file": "上传一个文件",
    "browse-a-file-to-upload": "浏览一个要上传的文件",
    "max-size": "最大尺寸",
    "file-supported-types": "支持的文件类型",
    "uploaded-successfully": "已成功上传",
    "failed!-please-retry": "失败了! 请重试",
    "this-file-type-is-not-supported-supported-attachments-are": "不支持这种文件类型。支持的附件是",
    "the-file-size-is-too-high-please-upload-document-lesser-than": "文件大小太高。请上传小于以下尺寸的文件",
    person: "个人",
    select: "选择",
    search: "搜索",
    "uploaded-file": "上传的文件",
    uploading: "上传",
    delete: "删除",
    "no-item": "没有项目",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "谷歌日历",
    "outlook-calendar": "Outlook日历",
    "yahoo-calendar": "雅虎日历",
    "read-more": "了解更多",
    "read-less": "了解更多",
    "about-title": "关于我们",
    "verb-are": "是",
    "verb-is": "是",
    character: "特点",
    // [HUB-9250] Date format translation
    "date-format": "日/月/年",
    "timer-hr": "小时",
    "timer-hrs": "小时",
    "timer-min": "分钟",
    "timer-mins": "分钟",
    "timer-sec": "秒",
    "timer-secs": "秒",
    "timer-day": "日",
    "timer-days": "日",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "没有选择文件",
    today: "今天",
    yesterday: "昨天",
    tomorrow: "明天",
    last: "最后的",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "注册成功!",
    registrationSuccessDetail: "您已成功注册该活动。谢谢您!",
    "search-here": "在这里搜索..",
    "did-you-mean": "你是说 ",
    DISPOSABLE_EMAIL: "给定的电子邮件地址属于一次性的电子邮件服务",
    RISTRICTED_DOMAIN: "所给的电子邮件地址是在一个受限制的域内。请输入一个有效的电子邮件地址",
    INVALID_EMAIL: "请输入有效的电子邮件",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "选择支付方式",
    PAY_NOW: "现在支付",
    INSTANT_TICKET_CONFIRMATION: "即时确认车票",
    PAY_VIA_OTHER_METHODS: "通过其他方式支付",
    FUNDS_TRANSFER: "资金转账",
    DIGITAL_WALLET: "数字钱包",
    CHEQUE: "支票",
    PAY_AT_VENUE: "在会场支付",
    TICKET_NOT_CONFIRMED_ORGANIZER: "在活动主办方确认收到付款后，门票才会被确认，并有可能被取消。",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "在您在活动现场付款之前，门票将不会被确认，并有可能被取消。",
    TICKET_CONFIRMED_BY_ORGANIZER: "当活动组织者确认收到付款后，门票将被确认。",
    COMPLETE_PAYMENT_OF: "完成支付",
    COMPLETE_PAYMENT_TICKET: "完成您的门票付款。",
    PAYMENT_CONFIRMATION_TITLE: "输入唯一的交易ID，以便活动主办方核实您的付款。",
    ENTER_TRANSACTION_ID: "输入交易ID",
    SHARE_DETAILS_TITLE: "只有当您分享您的详细资料时，活动组织者才能批准您的付款。您的门票，包括任何免费门票，将在批准后被确认。",
    UPLOAD_SCREENSHOT: "上传屏幕截图(可选)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "分享付款细节",
    TICKET_STATUS: "门票状态", // not found
    ORGANIZER_YET_TO_CONFIRM: "活动主办方尚未确认已收到付款。",
    TICKET_CONFIRMATION_ORGANIZER: "您的门票（包括任何免费门票）和发票将在活动组织者批准后确认并与您分享。",
    PAYMENT_DETAILS: "付款细节",
    FAQ_TITLE: "如有任何问题，请参阅常见问题",
    MAKE_PAYMENT_TITLE: "在活动现场付款，以确认您的门票购买。",
    CONFIRM_TICKET_PRIOR_EVENT: "如果您希望在活动前确认您的门票。现在用另一种方式付款。", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "如果您希望在活动前确认您的门票。",
    PAY_NOW_2: "现在支付",
    WITH_DIFFERENT_METHOD: "用另一种方式",
    PAYMENT_NOT_RCVD: "活动组织者没有收到付款。",
    PAYMENT_COULD_NOT_BE_VERIFIED: "活动组织者无法核实所分享的付款细节。",
    TICKETS_NOT_AVAILABLE: "该活动的门票已不再提供。",
    PAYMENT_DETAILS_YET_TO_SHARE: "您还没有分享门票的付款细节。", // not found in code
    WELCOME_BACK: "欢迎回来!",
    VIEW_OR_PROCEED_PURCHASE: "您可以查看您以前的门票或继续购买您刚刚选择的门票。",
    VIEW_PREV_TICKETS: "查看以前的门票",
    PROCEED_TO_PURCHASE: "继续购买",
    PREVIOUS_TICKETS: "以前的车票",
    PENDING_TICKETS: "待定票",
    CONFIRMED_TICKETS: "已确认的车票",
    PAYMENT_PENDING: "等待付款",
    AWAITING_ORG_APPROVAL: "等待主办方批准",
    CHECK_TICKET_STATUS: "查看门票状态",
    PAYMENT_METHOD_NOT_AVAILABLE: "付款方式不可用，请重试。", // not found in code
    HERE: "这儿。",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "请为所有购买的门票更新唯一的参会者信息。这将使所有这些参会者能够进入该活动。",
    DUPLICATE_EMAIL: "您已经购买了这张票。 您可以选择重新注册。 在这种情况下，将保存最近输入的数据。",
    DEFAULT_DUPLICATE_EMAIL: "此电子邮件已用于另一张票。 请仔细检查您输入的电子邮件地址，或者随意对多张票证使用同一电子邮件地址。",
    "REGISTER_NOW": "现在注册",
    "REG_FAILED": "注册失败",
    "REG_FAILED_DESC": "您的注册失败了。请再试一次。",
    "REG_DONE_BY": "被完成注册",
    "INVITE_AGAIN": "需要再次邀请您吗？",
    "REG_SUCCESS_DONE": "注册成功完成",
    "WATCH_REC_LINK": "带有观看录制链接的电子邮件已发送至",
    "WEBINAR_INV_LINK": "带有网络研讨会链接的邀请电子邮件已发送至",
    "MAIL_SENT_SUCCESS": "邮件成功发送",
    "ALREADY_REGISTERED": "您已经注册",
    "RESEND_INVITE": "重新发送邀请",
    "WATCH_RECORDING_REGISTER_DESC": "您可以通过以下注册来观看网络研讨会的录制。",

};
