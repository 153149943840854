import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useStore } from "Context";

const FAQModal = (props: { faqList: Array<{ question: string; answer: string; }>; orderNo?: string | number | undefined; onClose?: (event: React.SyntheticEvent) => void; }): JSX.Element => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [faqModalEmail, setFaqModalEmail] = React.useState<string>("");
  const { faqList } = props;
  const { store } = useStore();

  useEffect(() => {
    const query = new URLSearchParams(window?.location?.search);
    if (query) {
      const faqQuery = query.get("emailForOfflinePaymentMethods");
      if (faqQuery) {
        setFaqModalEmail(faqQuery);
      }
    }
  }, []);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ minHeight: "calc((var(--vh, 1vh) * 100) - 85px)", "@media(max-width:640px)": { minHeight: "auto" } }}>
      <Box p={2.5} sx={{ "@media(max-width:640px)": { p: 2, pt: 6 } }}>
        <Box sx={{ display: "flex", mb: 2, alignItems: "center", justifyContent: "space-between" }}>
          <Button onClick={props?.onClose} disableRipple disableFocusRipple size="small" color="primary" sx={{ padding: 0, minWidth: 0, "&:hover": { background: "transparent" } }}>Back</Button>
        </Box>
        {store.orderNo && <Typography sx={{ mt: 0.4, mb: "0px !important", fontSize: "14px !important" }} component="p" color="textSecondary">Order ID : {store.orderNo}</Typography>}
        <Typography component="h3" sx={{ fontSize: "24px", fontWeight: 700, "@media(max-width:640px)": { fontSize: "22px !important" } }}>FAQs</Typography>
        <Typography component="p" sx={{ mt: 1, mb: "0px !important", fontWeight: 700, fontSize: "14px !important" }} color="textPrimary">Got a question? We are here to help you.</Typography>
      </Box>
      {faqList.map((item: { question: string; answer: string; }, index: any) => (
        <Accordion
          key={index}
          sx={(theme: any) => ({ background: theme.palette.background.cardColor, "&.Mui-expanded": { mt: 0, mb: 0 }, boxShadow: "none", px: 2.5, py: 2, borderTop: `1px solid ${theme.palette.divider.default}` })}
          expanded={expanded === `panel_${index}`}
          onChange={handleChange(`panel_${index}`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={(theme: any) => ({
              padding: 0,
              margin: 0,

              minHeight: "auto",
              "&.Mui-expanded": {
                minHeight: "auto",
              },
              "& .MuiAccordionSummary-expandIconWrapper": {
                "&>svg": {
                  color: theme.palette.text.primary,
                }
              },
              "& .MuiAccordionSummary-content": {
                m: 0,
                "&.Mui-expanded": {
                  m: 0
                }
              }
            })}
          >
            <Typography sx={{ fontSize: "14px !important", fontWeight: 400, cursor: "pointer", }} component="label">
              {item.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, mt: 1 }}>
            <Typography color="textSecondary" sx={{ fontSize: "14px !important", fontWeight: 400, mt: "0px !important", mb: "0px !important" }} component="p">
              {item.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>))}
      <Typography color="textPrimary" sx={(theme: any) => ({ p: 2.5, mt: "0px !important", mb: "0px !important", fontSize: "12px !important", borderTop: `1px solid ${theme.palette.divider.default}` })} component="p">In case of any queries, please reach out to
        <Typography component="span" sx={(theme: any) => ({ fontSize: "12px !important", display: "inline", cursor: "pointer", ml: 0.5, color: theme.palette.primary.main })}>{store?.otherPaymentDetails?.emailForOfflinePaymentMethods || store?.paymentStatusDetails?.emailForOfflinePaymentMethods || faqModalEmail}</Typography></Typography>
    </Box>
  );
};

export default FAQModal;