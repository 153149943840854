import { IHeadersRequest } from "Types/Ticket";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";

export const trackWebinarRegistrationView = (headers: IHeadersRequest) => {
    try {
        (async ()=>{
            postApi<any, any>(
            AppConstants.webinarRegistrationView, //todo please change sourav
                headers
            );
        })();
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log("Registration Tracking Failed",err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
};
