/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FileUploadWithError from "../FileUploadWithError";
import TextField from "../InputField";
import FlatPicker from "../FlatPicker";
import RadioButtonsGroup from "../RadioButtonsGroup";
import CheckBoxGroup from "../CheckBoxGroup";
import get from "lodash/get";
import produce from "immer";
import { upperCase, updateUserField } from "./helper";
import { getPhoneCode } from "../../Utils/common";
import TextBoxAutoSelect from "../TextBoxAutoSelect";
import TextArea from "../TextArea";
import DropDown from "../../Components/DropdownWithError";
import CountryList from "../../Utils/regions";
import GroupComponent from "../GroupComponent";
import { IRegistrationField } from "../../Types/BuyerDetails";
import { useTranslation } from "react-i18next";
import { useStyles } from "./attendeeFieldStyles";
import Typography from "@mui/material/Typography";
import InfoViewIcon from "../../Assets/Svg/InfoViewIcon";
import { DEFAULT_DUPLICATE_EMAIL_ERROR_MESSAGE, DUPLICATE_EMAIL_ERROR_MESSAGE } from "Constants/ERROR_MESSAGES";


export type TCountryList = {
  countryName?: string;
  countryShortCode?: string;
};
const COUNTRIES: TCountryList[] = [...CountryList];
const NAME_FIELDS: string[] = ["firstName", "lastName"];
const CONTACT_FIELDS: string[] = ["email"];

const BUYERS_DEFAULT_FIELDS: string[] = [...CONTACT_FIELDS];

export interface IFieldProps {
  customGroupValueArray: any;
  children: any;
  id: string | number;
  label: string;
  fieldType: string;
  type: string;
  isMandatory: boolean;
  isVisible: boolean;
  minLength?: number;
  maxLength?: number;
  isError?: boolean;
  labelHint?: string;
  regex?: string;
  value?: string;
  valueOptions?: string[];
  otherValue?: string;
  parentObject?: any;
  parent?: string;
  optionId?: string;
}

export interface IRegion {
  name: string;
  dial_code: string;
  code: string;
}

export type TCountryRegion = {
  name?: string;
  shortCode?: string;
};

export interface iFileType {
  id: number;
  name: string;
  value: boolean;
}

export interface IFileProps {
  maxFileSize?: number;
  fileTypes?: iFileType[];
}

export interface IGroupProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errData?: any[];
}

export interface IChoices {
  label: string;
  selectedByDefault?: boolean;
  value?: string;
}

export interface IGroupValue {
  id: string;
  fieldValue: string;
  team: number;
}

const AttendeeFields = (props: any) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    activeTicketId,
    attendeeIndex,
    onRegionSelect,
    onBlurPhone,
    onKeyupTextHandler,
    onBlurTextHandler,
    onChangeTextHandler,
    onRadioChangeHandler,
    onCheckboxChangeHandler,
    onChangeFile,
    onChangeCountry,
    onChangeSelect,
    onDateChange,
    onChangeGroupHandler,
    attendees,
    accessToken,
  } = props;

  const attendee = attendees[activeTicketId][attendeeIndex];
  let tempAttendeeArr: any[] = [];
  const onlyParentFields = attendee?.filter((field: any) => !field?.parent);
  const onlyChildFields = attendee?.filter((field: any) => field?.parent);
  onlyParentFields?.forEach((field: any) => {
    tempAttendeeArr = [...tempAttendeeArr, field];
    onlyChildFields?.forEach((childField: any) => {
      if (field?.id === childField?.parent) {
        tempAttendeeArr = [...tempAttendeeArr, childField];
      }
    });
  });
  const isCountryExists = attendees[activeTicketId][attendeeIndex].findIndex(
    ({ fieldType = "" }: IRegistrationField) =>
      fieldType && fieldType.toUpperCase() === "COUNTRY"
  );
  const handleOtherChange = (id: string, event: any) => {
    const initVal = event.target.value;
    const index = attendees[activeTicketId][attendeeIndex].findIndex(
      (field: any) => String(field.id) === String(id)
    );
    if (index > -1 && (initVal || initVal === "")) {
      const _field = attendees[activeTicketId][attendeeIndex][index];
      const fetchType = get(_field, "type", "");
      if (fetchType.toUpperCase() === "DROPDOWN") {
        updateUserField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            otherValue: initVal || "",
            fieldValue: t("other"),
          },
          attendees
        );
      } else if (fetchType.toUpperCase() === "CHECKBOX") {
        updateUserField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            otherValue: initVal || "",
            valueOptions: _field.valueOptions,
          },
          attendees
        );
      } else if (fetchType.toUpperCase() === "RADIO") {
        updateUserField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            otherValue: initVal || "",
            fieldValue: t("other"),
          },
          attendees
        );
      }
    }
  };

  return (
    attendee &&
    tempAttendeeArr?.map((field: any, index: number) => {
      const {
        id = -1,
        label = "",
        isMandatory,
        maxLength,
        minLength,
        fieldType = "",
        otherValue = "",
        isError,
        labelHint = "",
        regex = "",
        suggestion = "",
        isListed = 0,
      } = field;
      const isVisible = true;
      const fieldValue = get(field, "value");
      const type = upperCase(get(field, "type", ""));
      const value = fieldValue || "";

      const choices = get(field, "choices", []);
      const showOtherOption = get(field, "showOtherOption", false);
      const showBlankOption = get(field, "showBlankOption", false);
      let updateOptions = [...choices];
      if (choices && Array.isArray(choices)) {
        if (showOtherOption) {
          updateOptions = produce(updateOptions, (draft) => {
            draft.push({ label: t("other"), selectedByDefault: false });
          });
        }

        if (showBlankOption) {
          updateOptions = produce(updateOptions, (draft) => {
            draft.unshift({ label: "None", selectedByDefault: true });
          });
        }
      }

      if (field?.parent) {
        const parentField = tempAttendeeArr?.find((item: any) => item.id === field?.parent);
        const optionField = parentField?.parentOptions?.find(
          (opt: any) => opt.optionId === field.optionId
        )?.name;
        if (parentField?.type === "CHECKBOX") {
          if (!(parentField?.valueOptions?.find((x: string) => x === optionField))) {
            return <></>;
          }
        } else if (parentField?.type === "RADIO" || parentField?.type === "DROPDOWN") {
          if (parentField?.value !== optionField) {
            return <></>;
          }
        }
      }

      if (["TEXT", "NUMBER"].includes(type)) {
        const isEmail: string | boolean = fieldType === "email";
        const isNumber: boolean = type === "NUMBER";
        const isMobile: boolean = isNumber && fieldType === "phone";

        const isDefaultField: boolean =
          BUYERS_DEFAULT_FIELDS.includes(fieldType);
        const multiLangLabel = isDefaultField ? fieldType : label;

        if (isMobile) {
          const [phoneCode, phoneNumber] = getPhoneCode(value);
          return (
            <Grid item xs={12} key={index}>
        
              <TextBoxAutoSelect
                id={String(id)}
                key={index}
                placeHolder={t(`${multiLangLabel}`)}
                textLabel={t(`${multiLangLabel}`)}
                withoutRadius="mobileTextField"
                name={multiLangLabel}
                countryCode={String(phoneCode)}
                value={String(phoneNumber)}
                textType={"text"}
                className="inputText mobiInput"
                iconColor="themecolor"
                iconAlign="start"
                bgColor="bgColor"
                position="top"
                errorMsg={labelHint}
                testId="mobileNumber"
                onSelect={(region: any) =>
                  onRegionSelect(region, id, attendeeIndex)
                }
                onBlur={(e: any) => onBlurPhone(e, id, attendeeIndex)}
                hintText={labelHint}
                errorType={isError ? "error" : ""}
                otherLabelKey={"dial_code"}
                isCharLimit={maxLength && maxLength > 0}
                charLimit={maxLength - String(phoneNumber).length}
                isMandatory={isMandatory}
                onChange={(e) => {
                  const val = `${phoneCode}-${e.target.value}`;
                  onChangeTextHandler(val, id, fieldType, attendeeIndex);
                }}
              />
            </Grid>
          );
        } else {
          return (
            <>
                        <Grid
              item
              xs={12}
              lg={NAME_FIELDS.includes(fieldType) ? 6 : 12}
              key={index}
            >
              <TextField
                key={index}
                regex={regex}
                id={String(id)}
                type={isEmail ? "email" : isNumber ? "number" : "text"}
                name={label}
                required={isMandatory}
                placeHolder={t(`${multiLangLabel}`)}
                label={t(`${multiLangLabel}`)}
                value={value}
                errorMsg={labelHint}
                // onKeyUp={(e) =>
                //   isEmail
                //     ? onKeyupTextHandler(e, id, fieldType, attendeeIndex)
                //     : null
                // }
                onChange={(e) => {
                    if (isEmail) onKeyupTextHandler(e, id, fieldType, attendeeIndex);
                    onChangeTextHandler(e, id, fieldType, attendeeIndex);
                }}
                onBlur={(e: any) =>
                  isEmail
                    ? null
                    : onBlurTextHandler(e, id, fieldType, attendeeIndex)
                }
                endIcon={
                  maxLength
                    ? (value.length > 0
                        ? maxLength - value.length
                        : maxLength
                      ).toString()
                    : null
                }
                maxLength={maxLength}
              />
              {isEmail && suggestion && (
                <Typography className={classes.suggestionBox} component="p">
                  {suggestion}
                </Typography>
              )}
              {Boolean(isEmail && isListed) && (
                <Box className={classes.inputEmailWrapperWidget} mr={2} display="flex" alignItems="center">
                  <Box mr={1.5}><InfoViewIcon className={classes.infoViewIcon} /></Box>
                  <Typography
                    variant="caption"
                    component="p"
                    sx={{ fontWeight: 400 }}
                  >
                    {t(isListed === 1 ? DUPLICATE_EMAIL_ERROR_MESSAGE : DEFAULT_DUPLICATE_EMAIL_ERROR_MESSAGE)}
                  </Typography>
                </Box>
              )}
            </Grid>
       
            </>
          );
        }
      } else if (type === "TEXTAREA") {
        return (
          <Grid item xs={12} key={index}>
            <TextArea
              key={index}
              id={String(id)}
              name={label}
              required={isMandatory}
              label={label}
              placeholder={label}
              value={value.toString()}
              onChange={(e: any) => {
                onChangeTextHandler(e, id, fieldType, attendeeIndex);
              }}
              errorMsg={labelHint}
              onBlur={(e: any) =>
                onBlurTextHandler(e, id, fieldType, attendeeIndex)
              }
              isCharLimit={!!(maxLength && maxLength > 0)}
              charLimit={maxLength - value.toString().length}
              maxLength={maxLength}
            />
          </Grid>
        );
      } else if (type === "RADIO") {
        const isVisible = get(field, "isVisible", false);
        const showOtherOption = get(field, "showOtherOption", false);
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onRadioChangeHandler(String(id), initValue);
        }

        return (
          <Grid item xs={12} key={index}>
            <RadioButtonsGroup
              key={String(id)}
              id={String(id)}
              label={label}
              name={label}
              isVisible={isVisible}
              options={[...updateOptions]}
              value={initValue}
              handleChange={(id, value) =>
                onRadioChangeHandler(id, value, attendeeIndex)
              }
              hasError={isError}
              errMsg={labelHint}
              otherValue={otherValue}
              isMandatory={isMandatory}
              hasOther={showOtherOption}
              handleOtherChange={(id, e) => handleOtherChange(id, e)}
            />
          </Grid>
        );
      } else if (type === "CHECKBOX") {
        const isVisible = get(field, "isVisible", false);
        const valueOptions = get(field, "valueOptions", []);

        const defaultSelectedChoices = Array.isArray(choices)
          ? choices
              .filter((choice) => choice.selectedByDefault)
              .map((choice) => choice.label)
          : [];

        let initValue = valueOptions;
        const hasValue =
          initValue && Array.isArray(initValue) && initValue.length;
        if (defaultSelectedChoices.length && !hasValue) {
          initValue = defaultSelectedChoices.filter(
            (value, index, self) => self.indexOf(value.trim()) === index
          );
          onCheckboxChangeHandler(String(id), initValue, attendeeIndex);
        }

        return (
          <Grid item xs={12} key={index}>
            <CheckBoxGroup
              key={String(id)}
              id={String(id)}
              label={label}
              isVisible={isVisible}
              value={initValue}
              handleChange={(id, value) =>
                onCheckboxChangeHandler(id, value, attendeeIndex)
              }
              options={[...updateOptions]}
              hasError={isError}
              errMsg={labelHint}
              otherValue={otherValue}
              handleOtherChange={(id, e) => handleOtherChange(id, e)}
              isMandatory={isMandatory}
            />
          </Grid>
        );
      } else if (type === "FILE") {
        const maxFileSize = get(field, "maxFileSize", 0);
        const fileTypes = get(field, "fileTypes", []);
        const isDisabledField = get(field, "isDisabledField", false);

        return (
          <Grid item xs={12} key={index}>
            <FileUploadWithError
              key={index}
              id={String(id)}
              accessToken={accessToken}
              value={value}
              label={label}
              onChange={(val: string) =>
                onChangeFile(String(id), val, attendeeIndex)
              }
              maxFileSize={maxFileSize}
              fileTypes={fileTypes}
              isVisible={isVisible}
              isRequired={isMandatory}
              hasError={isError}
              errMsg={labelHint}
              disabled={isDisabledField}
            />
          </Grid>
        );
      } else if (type === "DROPDOWN" && fieldType.toLowerCase() === "country") {
        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              label={label}
              placeHolder={t("country-place-holder")}
              className={classes.fullWidth}
              isMandatory={isMandatory}
              value={value}
              otherValue={otherValue}
              name="countryName"
              valueKey="countryName"
              labelKey="countryName"
              options={[...COUNTRIES]}
              errorMsg={labelHint}
              onChange={(val) => {
                onChangeCountry(id, val, attendeeIndex, "countryName");
              }}
              onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
            />
          </Grid>
        );
      } else if (type === "DROPDOWN" && fieldType.toLowerCase() === "state") {
        const fetchCountry = get(
          attendees[activeTicketId][attendeeIndex][isCountryExists],
          "value",
          ""
        );
        const fetchRegions = COUNTRIES.find(
          ({ countryName = "" }) => countryName && countryName === fetchCountry
        );
        const regions: TCountryRegion[] = get(fetchRegions, "regions", []);
        if (regions && Array.isArray(regions) && isCountryExists !== -1) {
          return (
            <Grid item xs={12} key={index}>
              <DropDown
                key={id}
                id={String(id)}
                label={label}
                isMandatory={isMandatory}
                value={value}
                otherValue={otherValue}
                name="name"
                placeHolder={t("state-place-holder")}
                valueKey="name"
                labelKey="name"
                options={[...regions]}
                onChange={(val) => {
                  onChangeSelect(id, val, attendeeIndex, "name");
                }}
                errorMsg={labelHint}
                onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
              />
            </Grid>
          );
        }
      } else if (type === "DROPDOWN" && fieldType.toUpperCase() === "CUSTOM") {
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onChangeSelect(String(id), initValue, attendeeIndex, "label");
        }

        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              label={label}
              name="label"
              isMandatory={isMandatory}
              value={initValue}
              otherValue={otherValue}
              labelKey="label"
              valueKey="label"
              options={updateOptions}
              errorMsg={labelHint}
              isError={isError}
              labelHint={labelHint}
              placeHolder={t("select")}
              onChange={(val) => {
                onChangeSelect(String(id), val, attendeeIndex, "label");
              }}
              onChangeOther={(e: any) => handleOtherChange(String(id), e)}
            />
          </Grid>
        );
      } else if (type === "DATE") {
        return (
          <Grid item xs={12} key={index}>
            <TextField
              key={id}
              id={String(id)}
              value={value}
              required={isMandatory}
              label={label}
              name={label}
              placeHolder={t("date-format")}
              type="text"
              icon
              endIcon={
                <FlatPicker
                  options={{ dateFormat: "d/m/Y" }}
                  onChange={(date, val) =>
                    onDateChange(String(id), val, attendeeIndex)
                  }
                  btnClass
                />
              }
              errorMsg={labelHint}
            />
          </Grid>
        );
      } else if (type === "LINK") {
        return (
          <Grid item xs={12} key={index}>
            <TextField
              key={id}
              id={String(id)}
              name={label}
              value={value}
              label={label}
              required={isMandatory}
              placeHolder={label}
              errorMsg={labelHint}
              onChange={(e) => {
                onChangeTextHandler(e, id, fieldType, attendeeIndex);
              }}
              onBlur={(e: any) =>
                onBlurTextHandler(e, id, fieldType, attendeeIndex)
              }
            />
          </Grid>
        );
      } else if (type === "GROUP") {
        const customGroupValueArray = get(field, "customGroupValueArray", []);
        const isShowAddButton = get(field, "isShowAddButton", false);
        const buttonLabel = get(field, "buttonLabel", "");
        const fieldTypeName = get(field, "fieldTypeName", "");
        const errData = get(field, "errData", []);

        const fieldComp = (
          <Grid item xs={12} key={index}>
            <GroupComponent
              key={index}
              id={String(id)}
              value={customGroupValueArray}
              label={`${label}`}
              isMandatory={isMandatory}
              onChange={(id, val) =>
                onChangeGroupHandler(id, val, attendeeIndex)
              }
              // onBlur={(id: any, field: any, pId: any) =>
              // //  onBlurField(id, field, pId)
              // }
              isVisible={isVisible}
              required={isMandatory}
              fields={field.children}
              errData={errData}
              hasError={isError}
              errMsg={labelHint}
              minLength={minLength}
              maxLength={maxLength}
              isShowAddButton={isShowAddButton}
              buttonLabel={buttonLabel}
              fieldTypeName={fieldTypeName}
            />
          </Grid>
        );
        return fieldComp;
      }

      return <></>;
    })
  );
};

export default AttendeeFields;
