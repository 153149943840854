// Language: Thai

export default {
  "already-registered": "ลงทะเบียนเรียบร้อยแล้ว?",
  "add-to-calendar": "เพิ่มในปฏิทิน",
  "event-starts-in": "งานเริ่มใน",
  "event-is-live": "งานแสดงสด",
  "event-has-ended": "งานสิ้นสุดแล้ว",
  "powered-by": "ขับเคลื่อนโดย",
  "please-enter-your-email-to-continue": "กรุณากรอกอีเมล์เพื่อดำเนินการต่อ",
  add: "เพิ่ม",
  offers: "ข้อเสนอ",
  free: "ฟรี",
  "available-till": "มีจำหน่ายจนถึง {{DATE}}",
  "enter-email-address": "ใส่ที่อยู่อีเมล",
  "sold-out": "ขายหมดแล้ว",
  "the-ticket-has-reached-the-maximum-number": "ตั๋วถึงจำนวนสูงสุดแล้ว",
  "seat-left": "{{SEAT}} ที่นั่งซ้าย",
  "seats-left": "{{SEAT}} ที่นั่งซ้าย",
  proceed: "ดำเนินการ",
  summary: "สรุป",
  "buyer-info": "ข้อมูลผู้ซื้อ",
  "attendees-info": "ข้อมูลผู้เข้าร่วม",
  continue: "ดำเนินการต่อ",
  "attendees-information": "ข้อมูลผู้เข้าร่วม",
  submit: "ส่ง",
  "register-to-join-the-events": "ลงทะเบียนเข้าร่วมกิจกรรม",
  "by-clicking-on-the-button":
    "เมื่อคลิกที่ปุ่ม คุณจะถูกเปลี่ยนเส้นทางไปยังแพลตฟอร์มการออกตั๋วซึ่งคุณสามารถลงทะเบียนสำหรับงานนี้",
  other: "อื่น",
  "please-enter-a-value-for": "โปรดป้อนค่าสำหรับ <FIELD_NAME>",
  "please-select-a-value-for": "กรุณาเลือกค่าสำหรับ <FIELD_NAME>",
  "enter-other-value-for": "ป้อนค่าอื่นสำหรับ <FIELD_NAME>",
  "enter-a-valid": "ใส่ valid <FIELD_NAME>",
  "should-be-at-least":
    "<FIELD_NAME> อย่างน้อยก็ควร <FIELD_LENGTH> <FIELD_TYPE>",
  "cannot-be-more-than": "<FIELD_NAME> ไม่เกิน <FIELD_LENGTH> <FIELD_TYPE>",
  "is-required": "<FIELD_NAME> ต้องระบุ",
  "at-least-required": "At least <FIELD_LENGTH> <FIELD_NAME> <VERB> required",
  "maximum-allowed": "Maximum <FIELD_LENGTH> <FIELD_NAME> <VERB> allowed",
  "user-with-same-email-id-already-exists":
    "มีผู้ใช้ที่มีรหัสอีเมลเดียวกันอยู่แล้ว โปรดใช้อันอื่น",
  "user-with-same-email-id-has-already-registered-for-this-event":
    "ผู้ใช้ที่มีรหัสอีเมลเดียวกันได้ลงทะเบียนสำหรับกิจกรรมนี้แล้ว โปรดใช้อันอื่น",
  "mail-successfully-sent": "ส่งจดหมายเรียบร้อยแล้ว!",
  "we-are-unable-to-deliver-your-mail-at-this-moment":
    "เราไม่สามารถส่งจดหมายของคุณได้ในขณะนี้! โปรดลองอีกครั้งในภายหลัง",
  "something-went-wrong":
    "บางอย่างผิดพลาด. เรากำลังแก้ไขปัญหานี้ เราขออภัยในความไม่สะดวกที่เกิดขึ้น",
  "register-now": "สมัครตอนนี้",
  registrationSuccess: "ลงทะเบียนเรียบร้อย!",
  "add-to-your-calendar": "เพิ่มในปฏิทิน",
  "please-select-a-ticket-to-proceed": "โปรดเลือกตั๋วเพื่อดำเนินการต่อ",
  "resend-link": "ส่งลิงค์อีกครั้ง",
  "go-to-event": "ไปที่กิจกรรม",
  "ticket-registration-done-by": "ลงทะเบียนตั๋วโดย",
  "you-have-already-purchased-the-ticket-for": "คุณได้ซื้อตั๋วสำหรับ .แล้ว",
  purchased: "ซื้อแล้ว",
  "event-date": "วันที่จัดงาน",
  "ticket-quantity": "จำนวนตั๋ว",
  payment: "การชำระเงิน",
  "unable-to-submit-data": "ส่งข้อมูลไม่ได้",
  "unable-to-apply-promo-code": "ใช้รหัสโปรโมชั่นไม่ได้",
  "invalid-promo-code": "Codi promocional no vàlid",
  "apply-now": "นำมาใช้",
  "enter-discount-coupon": "ใส่คูปองส่วนลด",
  "same-as-buyer": "เช่นเดียวกับผู้ซื้อ",
  "please-check-all-the-fields-are-correctly-filled":
    "กรุณาตรวจสอบทุกช่องให้ถูกต้อง",
  "ticket-purchased": "ซื้อตั๋วแล้ว!",
  "ticket-purchase-failed": "การซื้อตั๋วล้มเหลว!",
  "you-have-successfully-purchased-the-ticket":
    "คุณซื้อตั๋วสำเร็จแล้ว ตั๋วนี้จะช่วยให้คุณเข้าร่วมงานได้ ขอขอบคุณสำหรับการซื้อของคุณ!",
  "there-was-an-error-while-booking-your-tickets":
    "เกิดข้อผิดพลาดขณะจองตั๋วของคุณ กรุณาลองอีกครั้ง.",
  "try-again": "ลองอีกครั้ง",
  "you-dont-have-access": "คุณไม่สามารถซื้อตั๋วได้บางส่วน",
  "please-enter-a-valid-email": "กรุณาใส่อีเมลที่ถูกต้อง",
  "please-enter-email-to-continue": "กรุณากรอกอีเมล์เพื่อดำเนินการต่อ",
  "payment-summary": "สรุปการชำระเงิน",
  // [HUB-8587] Added multiple language support for Promo Code Success message
  "apply-discount":
    "{{CODE}} successfully applied. You saved additional {{AMOUNT}}.",
  // [HUB-8597] Added multiple language support for Buyer Taxation Support
  and: "และ",
  "taxation-concent": "ต้องการสร้างใบกำกับภาษีหรือไม่?",
  "taxation-tnc-pp": "การดำเนินการต่อในแสดงว่าคุณยอมรับ",
  "taxation-tnc": "ข้อกำหนดและเงื่อนไข",
  "taxation-pp": "นโยบายความเป็นส่วนตัว",
  "company-name-label": "ชื่อ บริษัท",
  "company-name-place-holder": "ใส่ชื่อบริษัท",
  "taxId-label": "หมายเลขประจำตัวผู้เสียภาษีของบริษัท",
  "taxId-place-holder": "ป้อนหมายเลขประจำตัวผู้เสียภาษีบริษัท",
  "company-address-label": "ที่อยู่ บริษัท",
  "company-address-place-holder": "ที่อยู่",
  "country-place-holder": "เลือกประเทศ",
  "state-place-holder": "เลือกรัฐ",
  "city-place-holder": "เข้าเมือง",
  "zipcode-label": "รหัสไปรษณีย์",
  "zipcode-place-holder": "กรอกรหัสไปรษณีย์",
  "company-error-msg": "กรุณากรอกชื่อบริษัท",
  "taxId-error-msg": "กรุณากรอกหมายเลขประจำตัวผู้เสียภาษีบริษัท",
  "address-error-msg": "กรุณากรอกที่อยู่",
  "country-error-msg": "กรุณาเลือกประเทศ",
  "state-error-msg": "กรุณาเลือกรัฐ",
  "city-error-msg": "กรุณากรอกชื่อเมือง",
  "zipcode-error-msg": "กรุณากรอกรหัสไปรษณีย์",
  // [HUB-8579] Added multiple language support for Buyer Default Fields
  firstName: "ชื่อจริง",
  lastName: "นามสกุล",
  email: "อีเมล",
  phone: "โทรศัพท์",
  dialerCode: "รหัสโทรศัพท์",
  // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
  "you-have-already-purchased-ticket-for": "คุณได้ซื้อตั๋วสำหรับ .แล้ว",
  "browse-a-file-to-upload": "เรียกดูไฟล์ที่จะอัปโหลด",
  "upload-file": "อัพโหลดไฟล์",
  "max-size": "ขนาดสูงสุด",
  "file-supported-types": "ประเภทไฟล์ที่รองรับ",
  "uploaded-successfully": "อัพโหลดสำเร็จ",
  "failed!-please-retry": "ล้มเหลว! กรุณาลองใหม่อีกครั้ง",
  "this-file-type-is-not-supported-supported-attachments-are":
    "ไม่รองรับไฟล์ประเภทนี้ ไฟล์แนบที่รองรับคือ",
  "the-file-size-is-too-high-please-upload-document-lesser-than":
    "ขนาดไฟล์สูงเกินไป โปรดอัปโหลดเอกสารที่น้อยกว่า",
  person: "บุคคล ",
  select: "เลือก",
  search: "ค้นหา",
  "uploaded-file": "ไฟล์ที่อัพโหลด",
  uploading: "กำลังอัปโหลด",
  delete: "ลบ",
  "no-item": "ไม่มีรายการ",
  // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
  "read-more": "อ่านเพิ่มเติม",
  "read-less": "อ่านน้อยลง",
  "about-title": "เกี่ยวกับ",
  // [HUB-9217] On buyer form and attendee form for group field in validation message "is" text is coming
  "google-calendar": "Google ปฏิทิน",
  "outlook-calendar": "ปฏิทิน Outlook",
  "yahoo-calendar": "ปฏิทิน Yahoo",
  "verb-are": "เป็น",
  "verb-is": "เป็น",
  character: "อักขระ",
  // [HUB-9250] Date format translation
  "date-format": "วว/ดด/ปปปป",
  "timer-hr": "ชั่วโมง",
  "timer-hrs": "ชั่วโมง",
  "timer-min": "นาที",
  "timer-mins": "นาที",
  "timer-sec": "วินาที",
  "timer-secs": "วินาที",
  "timer-day": "วัน",
  "timer-days": "วัน",
  // [HUB-9352] On Hover of file upload button content shown in english language only
  "select-file-title": "ไม่มีไฟล์ที่เลือก",
  today: "วันนี้",
  yesterday: "เมื่อวาน",
  tomorrow: "พรุ่งนี้",
  last: "ล่าสุด",
  "internet-down": "อินเทอร์เน็ตดูเหมือนจะถูกตัดการเชื่อมต่อ!",
  "internet-up": "เชื่อมต่ออินเทอร์เน็ตแล้ว",
  "network-issue":
    "ข้อความแสดงข้อผิดพลาดเกี่ยวกับเครือข่ายบางอย่างเกิดขึ้น โปรดลองอีกครั้ง!",
  registrationSuccessDetail:
    "คุณได้ลงทะเบียนสำหรับกิจกรรมเรียบร้อยแล้ว ขอขอบคุณ!",
  "search-here": "ค้นหาที่นี่..",
  "did-you-mean": "คุณหมายถึง ",
  DISPOSABLE_EMAIL: "ที่อยู่อีเมลที่ระบุเป็นของบริการอีเมลที่ใช้แล้วทิ้ง",
  RISTRICTED_DOMAIN: "ที่อยู่อีเมลที่ระบุอยู่ในโดเมนที่ถูกจำกัด โปรดป้อนที่อยู่อีเมลที่ถูกต้อง",
  INVALID_EMAIL: "กรุณาใส่อีเมลที่ถูกต้อง",

  // New Keys
  CHOOSE_PAYMENT_METHOD: "เลือกวิธีการชำระเงิน",
  PAY_NOW: "จ่ายตอนนี้",
  INSTANT_TICKET_CONFIRMATION: "ยืนยันตั๋วทันที",
  PAY_VIA_OTHER_METHODS: "ชำระผ่านช่องทางอื่น",
  FUNDS_TRANSFER: "การโอนเงิน",
  DIGITAL_WALLET: "กระเป๋าสตางค์ดิจิตอล",
  CHEQUE: "เช็ค",
  PAY_AT_VENUE: "ชำระณสถานที่จัดงาน",
  TICKET_NOT_CONFIRMED_ORGANIZER: "บัตรโดยสารจะไม่ถูกยืนยันจนกว่าผู้จัดงานจะอนุมัติการชำระเงินและอาจมีการยกเลิก",
  TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "บัตรเข้างานจะไม่ได้รับการยืนยันจนกว่าท่านจะชำระเงินณสถานที่จัดงานและอาจมีการยกเลิก",
  TICKET_CONFIRMED_BY_ORGANIZER: "บัตรจะได้รับการยืนยันเมื่อผู้จัดงานอนุมัติการชำระเงินแล้ว",
  COMPLETE_PAYMENT_OF: "การชำระเงินที่สมบูรณ์ของ",
  COMPLETE_PAYMENT_TICKET: "ชำระค่าตั๋วให้เสร็จสมบูรณ์",
  PAYMENT_CONFIRMATION_TITLE: "ป้อนรหัสธุรกรรมเฉพาะสำหรับผู้จัดงานเพื่อยืนยันการชำระเงิน",
  ENTER_TRANSACTION_ID: "ป้อนรหัสธุรกรรม",
  SHARE_DETAILS_TITLE: "ผู้จัดงานสามารถอนุมัติการชำระเงินของคุณหากคุณแบ่งปันรายละเอียดเท่านั้น ตั๋วของคุณรวมถึงตั๋วฟรีใดๆจะได้รับการยืนยันหลังจากการอนุมัติ",
  UPLOAD_SCREENSHOT: "อัปโหลดภาพหน้าจอ(ถ้ามี)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
  SHARE_PAYMENT_DETAILS: "รายละเอียดการชำระเงินหุ้น",
  TICKET_STATUS: "สถานะตั๋ว", // not found
  ORGANIZER_YET_TO_CONFIRM: "ผู้จัดงานยังไม่ยืนยันว่าได้รับการชำระเงินแล้ว",
  TICKET_CONFIRMATION_ORGANIZER: "ตั๋วของคุณรวมถึงตั๋วฟรีใดๆและใบแจ้งหนี้จะได้รับการยืนยันและแชร์กับคุณหลังจากได้รับอนุมัติจากผู้จัดงานแล้ว",
  PAYMENT_DETAILS: "รายละเอียดการชำระเงิน",
  FAQ_TITLE: "ในกรณีที่มีข้อสงสัยโปรดดูที่คำถามที่พบบ่อย",
  MAKE_PAYMENT_TITLE: "ชำระเงินณสถานที่จัดงานเพื่อยืนยันการซื้อตั๋ว",
  CONFIRM_TICKET_PRIOR_EVENT: "หากท่านต้องการยืนยันตั๋วก่อนการแข่งขัน, จ่ายตอนนี้ด้วยวิธีการที่แตกต่างกัน", // need to handle differently 
  CONFIRM_TICKETS_PRIOR_EVENT: "หากท่านต้องการยืนยันตั๋วของท่านก่อนเข้าร่วมกิจกรรม,",
  PAY_NOW_2: "จ่ายตอนนี้",
  WITH_DIFFERENT_METHOD: "ด้วยวิธีการที่แตกต่างกัน",
  PAYMENT_NOT_RCVD: "ผู้จัดงานยังไม่ได้รับการชำระเงิน",
  PAYMENT_COULD_NOT_BE_VERIFIED: "รายละเอียดการชำระเงินที่แชร์ไม่สามารถตรวจสอบโดยผู้จัดงานได้",
  TICKETS_NOT_AVAILABLE: "ตั๋วไม่สามารถใช้ได้สำหรับกิจกรรมนี้อีกต่อไป",
  PAYMENT_DETAILS_YET_TO_SHARE: "คุณยังไม่ได้แบ่งปันรายละเอียดการชำระเงินสำหรับตั๋ว", // not found in code
  WELCOME_BACK: "ยินดีต้อนรับกลับมา!",
  VIEW_OR_PROCEED_PURCHASE: "คุณสามารถดูตั๋วก่อนหน้าของคุณหรือดำเนินการกับการซื้อตั๋วที่คุณเลือก",
  VIEW_PREV_TICKETS: "ดูตั๋วก่อนหน้า",
  PROCEED_TO_PURCHASE: "ดำเนินการสั่งซื้อ",
  PREVIOUS_TICKETS: "ตั๋วก่อนหน้า",
  PENDING_TICKETS: "ตั๋วที่รอดำเนินการ",
  CONFIRMED_TICKETS: "ตั๋วยืนยัน",
  PAYMENT_PENDING: "การชำระเงินที่รอดำเนินการ",
  AWAITING_ORG_APPROVAL: "กำลังรอการอนุมัติผู้จัดงาน",
  CHECK_TICKET_STATUS: "ตรวจสอบสถานะตั๋ว",
  PAYMENT_METHOD_NOT_AVAILABLE: "วิธีการชำระเงินไม่สามารถใช้ได้โปรดลองอีกครั้ง", // not found in code
  HERE: "ที่นี่",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "กรุณาปรับปรุงข้อมูลผู้เข้าร่วมที่ไม่ซ้ำกันสำหรับตั๋วทั้งหมดที่ซื้อ ผู้เข้าร่วมประชุมทุกคนในการเข้าถึงเหตุการณ์",
    DUPLICATE_EMAIL: "คุณได้ซื้อตั๋วนี้แล้ว คุณอาจเลือกที่จะลงทะเบียนใหม่ ในกรณีนี้ ข้อมูลล่าสุดที่ป้อนจะถูกบันทึกไว้",
    DEFAULT_DUPLICATE_EMAIL: "อีเมลนี้ใช้สำหรับตั๋วอื่นแล้ว โปรดตรวจสอบที่อยู่อีเมลที่คุณป้อนอีกครั้ง หรืออย่าลังเลที่จะใช้อีเมลเดียวกันสำหรับตั๋วหลายใบ",
    "REGISTER_NOW": "สมัครตอนนี้",
    "REG_FAILED": "การลงทะเบียนล้มเหลว",
    "REG_FAILED_DESC": "การลงทะเบียนของคุณล้มเหลว โปรดลองอีกครั้ง",
    "REG_DONE_BY": "การลงทะเบียนสำเร็จแล้วโดย",
    "INVITE_AGAIN": "ต้องการส่งคำเชิญอีกครั้งหรือไม่?",
    "REG_SUCCESS_DONE": "ทำการลงทะเบียนสำเร็จแล้ว",
    "WATCH_REC_LINK": "อีเมลพร้อมลิงก์เพื่อรับชมบันทึกการจัดงานได้ถูกส่งถึง....แล้ว",
    "WEBINAR_INV_LINK": "อีเมลคำเชิญพร้อมลิงก์การจัดงานสัมมนาออนไลน์ได้ถูกส่งถึง...แล้ว",
    "MAIL_SENT_SUCCESS": "ส่งอีเมลสำเร็จแล้ว",
    "ALREADY_REGISTERED": "คุณได้ลงทะเบียนแล้วสำหรับ",
    "RESEND_INVITE": "ส่งคำเชิญอีกครั้ง",
    "WATCH_RECORDING_REGISTER_DESC": "คุณสามารถรับชมบันทึกการจัดงานสัมมนาออนไลน์ได้โดยลงทะเบียนด้านล่าง",

  };
