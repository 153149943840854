import React from "react";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme: any) => ({
  textAreaBox: {
    borderRadius: "4px !important",
    border: `1px solid ${theme.palette.divider.default} !important`,
    marginTop: "3px !important",
    resize: "none",
    color:theme.palette.text.primary,
    padding: "10px !important",
    background: "none !important",
    fontFamily: "Helvetica Neue New !important",
    width: "100% !important",
    backgroundColor: "transparent !important",
    fontSize: "14px !important",
    "&.Mui-focused": {
      boxShadow: "none !important",  
    },
    "&:focus-visible": {
      outline: "none !important",
      boxShadow: "none !important",  
    },
    "&.cursor-not-allowed": {
      color: theme.palette.background.gray,
    },
    // boxSizing: 'border-box',
    "&:focus": {
      outline: "none",
      boxShadow: "none !important",
      border: `1px solid ${theme.palette.primary.main} !important`,
    },
    "&::-webkit-input-placeholder": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },
    "&::-moz-input-placeholder": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },
    "&:-ms-input-placeholder": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },
    "&:-moz-placeholder": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },
    "&::placeholder": {
      color: theme.palette.text.secondary,
      fontSize: 14,
    },
  },
  borderDanger: {
    border: `1px solid ${theme.palette.error.main} !important`,
  },
}));

export type TextAreaBoxProps = {
  rowMin?: number;
  placeholder?: string;
  disabled?: boolean;
  id?: string;
  name?: string;
  value?: string;
  label?: string;
  charLimit?: number;
  isCharLimit?: boolean;
  hintText?: string;
  className?: string;
  onChange?: any;
  required?: boolean;
  error?: string;
  maxLength?: number;
  onBlur?: any;
  errorMsg?: string;
};

const TextAreaBox = ({
  rowMin = 3,
  placeholder = "",
  disabled,
  id,
  value,
  label,
  name,
  className,
  required,
  onChange,
  hintText,
  maxLength,
  onBlur,
  charLimit,
  isCharLimit,
  errorMsg,
}: TextAreaBoxProps): JSX.Element => {
  const [isFocused, toggleFocus] = React.useState(false);
  const classes = useStyles();
  return (
    <Box sx={{ textAlign: "left !important" }}>
      <Typography
        sx={(theme) => ({
          color: theme.palette.primary.main,
          fontWeight: 400,
          textAlign: "left",
        })}
        variant="caption"
        component="label"
      >
        {label}{" "}
        {required ? (
          <Typography
            component="span"
            sx={(theme: any) => ({
              color: theme.palette.primary.main,
              fontSize: 16,
              lineHeight: 0,
            })}
          >
            *
          </Typography>
        ) : (
          ""
        )}{" "}
      </Typography>
      <Box position="relative">
        <TextareaAutosize
          disabled={disabled}
          minRows={rowMin}
          placeholder={placeholder}
          className={`${disabled ? "cursor-not-allowed" : ""} ${
            classes.textAreaBox
          } ${isFocused ? "Mui-focused" : ""} ${className || ""} ${
            errorMsg ? classes.borderDanger : ""
          }`}
          id={id}
          onFocus={() => {
            toggleFocus(true);
          }}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          name={name}
          maxLength={maxLength}
        />
        {isCharLimit && charLimit?.toString() ? (
          <Typography
            component="span"
            variant="caption"
            sx={(theme) => ({
              color: theme.palette.text.secondary,
              position: "absolute",
              right: "1.1rem",
              bottom: "1.1rem",
            })}
          >
            {charLimit?.toString()}
          </Typography>
        ) : (
          false
        )}
      </Box>
      {errorMsg ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            color: theme.palette.error.main,
            fontWeight: 400,
            marginTop: "-2px",
          })}
        >
          {errorMsg}
        </Typography>
      ) : (
        false
      )}
      {hintText ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme) => ({
            marginTop: "2px",
            color: theme.palette.text.secondary,
            fontWeight: 400,
          })}
        >
          {hintText}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default TextAreaBox;
