import { makeStyles } from "@mui/styles";

const ticketSummaryStyle = makeStyles((theme: any) => ({
  TicketSummaryWrapper: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    "@media(max-width:640px)":{
      height:"100%",
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      fontSize: 12,
    },
    "& .continueBtn": {
      marginTop: theme.spacing(2),
    },
    "& .mobFooterStyle":{
      borderTop: `1px solid ${theme.palette.divider.default}`,
    },
  },
  // widgetEventInfo:{
  //   "& .mobFooterStyle":{
  //     background:"gray",
  //     padding:theme.spacing(1),
  //     borderTopLeftRadius:theme.spacing(2),
  //     borderTopRightRadius:theme.spacing(2),

  //   },
  // },
  ticketSummaryWidget:{
    display: "block",
    width:"auto",
    padding:"0rem",
    height:"100%",
    position: "relative",
    overflowY:"auto",
    "& .ticketSummryWidgetList":{
      padding:`${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
      overflow:"hidden",
          // border:`1px solid ${theme.palette.divider.default}`,
          background: theme.palette.background.cardColor,
          borderRadius:theme.spacing(1),
        "@media screen and (max-width: 1024px)": {
          padding:"1.6rem 0.8rem 1.6rem 1.6rem",
            border:"none",
            borderRadius:0,
        },
      "@media screen and (max-width: 480px)": {
        padding:"1.6rem 0.8rem 1.6rem 1.6rem" ,
        border:"none",
      },
    },
    "& .mobFooterStyle":{
      background:theme.palette.background.cardColor,
      // border:`1px solid ${theme.palette.divider.default}`,
      // borderTopLeftRadius:theme.spacing(2),
      // borderTopRightRadius:theme.spacing(2),
      "& .mobBtn":{
        padding:"1.6rem",
      }

    },
    "@media screen and (min-width: 1024px)": {
      display:"flex",
      justifyContent:"space-between",
      // padding:theme.spacing(3),
      background: theme.palette.background.lightGray,
    
      "&>div":{
        "&:first-child":{

          width:"60%"
        },
        "&:last-child":{
          width:"38%",
          position:"absolute",
          right:0,
          borderTop:"none",
          left:"auto",
          top:0,
          height:"auto",
        }
      },

      "& .mobFooterStyle":{
        backgroundColor:theme.palette.background.cardColor,
        borderRadius:"8px",
        height:"100%",
        "& .mobBtn":{
          padding:"1.6rem",
        }
      },
    },
    "& .scrollForWidget":{
      paddingTop:0,
      maxHeight:"calc(100% - 115px)",
      minHeight:"calc(100% - 115px)",
      "@media screen and (max-width: 1024px)": {
        minHeight:"100%",
        maxHeight:"100%",
      },
      "@media screen and (max-width: 480px)": {
        minHeight:"calc((var(--vh, 1vh) * 100) - 32rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 32rem)",
      },
    }
  },

  mobScrollForTicketCode: {
    overflowY: "auto",
    paddingTop:theme.spacing(2),
    paddingRight:"1.2rem",
  
    "@media(max-width:1920px)":{
      maxHeight: "calc((var(--vh, 1vh) * 100) - 58.4rem)",
      minHeight: "calc((var(--vh, 1vh) * 100) - 58.4rem)",
    },
    "@media(max-width:1680px)":{
      maxHeight: "calc((var(--vh, 1vh) * 100) - 47.2rem)",
      minHeight: "calc((var(--vh, 1vh) * 100) - 47.2rem)",
    },
    "@media(max-width:1440px)":{
      maxHeight: "calc((var(--vh, 1vh) * 100) - 44rem)",
      minHeight: "calc((var(--vh, 1vh) * 100) - 44rem)",
    },
    "@media(max-width:1140px)":{
      maxHeight: "calc((var(--vh, 1vh) * 100) - 40.8rem)",
      minHeight: "calc((var(--vh, 1vh) * 100) - 40.8rem)",
    },
    "@media(max-width:640px)":{
      paddingTop:theme.spacing(0),
      maxHeight: "calc((var(--vh, 1vh) * 100) - 44rem)",
      minHeight: "calc((var(--vh, 1vh) * 100) - 44rem)",
    },
    "&.dudaWidgetTicketInfo":{
      maxHeight: "initial !important",
      minHeight: "auto !important",
      "@media screen and (max-width: 640px)": {
        maxHeight: "calc((var(--vh, 1vh) * 100) - 32rem) !important",
       minHeight: "calc((var(--vh, 1vh) * 100) - 32rem) !important",
      },
    },
  },
  mobFooterStyle: {
    "& .mobFooterStyle": {
      paddingTop: theme.spacing(1),
      background: theme.palette.background.cardColor,
      borderTop: `1px solid ${theme.palette.divider.default}`,
      position: "absolute",
      bottom: 0,
      right: 0,
      width: "100%",
      "& .mobBtn": {
        padding: theme.spacing(2),
      },
      "@media(max-width:640px)":{
        "& .mobBtn": {
          paddingTop: theme.spacing(1.5),
          paddingBottom: theme.spacing(1.5),

        },
      }
    },
    "& .title": {
      paddingTop: theme.spacing(1),
      "@media(max-width:640px)":{
        paddingTop: theme.spacing(0),

      }
    },
  },
  landingSummryFlow:{
    padding:0,
    position:"relative",
    height:"calc((var(--vh, 1vh) * 100) - 0rem)",
    "@media(max-width:640px)":{
      padding:theme.spacing(2),
      height:"100%",
    }, 
    "& .landingticketScroll":{
      padding:`${theme.spacing(0)} ${theme.spacing(2.5)} ${theme.spacing(0)}`,
      maxHeight:"calc((var(--vh, 1vh) * 100) - 30.5rem)",
      minHeight:"calc((var(--vh, 1vh) * 100) - 30.5rem)", 
      "@media(max-width:1140px)":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 36.5rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 36.5rem)", 
      },
      "@media(max-width:640px)":{
        padding:`${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(0)} ${theme.spacing(0)}`,
        maxHeight:"calc((var(--vh, 1vh) * 100) - 32rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 32rem)",
      }
    }
  
  },
  landingSummryDetailsFlow:{
    position:"absolute",
    bottom:0,
    left:0,
    borderTop:`1px solid ${theme.palette.divider.default}`,
    width:"100%",
    paddingTop:theme.spacing(0),
    paddingBottom:theme.spacing(2),
    background: theme.palette.background.cardColor,
    "@media(max-width:640px)":{
      paddingBottom:theme.spacing(0),
      maxHeight:"24rem",
      overflowY:"auto",
    }
  },
  stepper: {
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: theme.spacing(0.5),
      fontSize: "12px",
      color: theme.palette.text.secondary,
      fontWeight: 400,
      "&.Mui-active": {
        color: theme.palette.primary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiStepIcon-root": {
      height: 20,
      width: 20,
      borderRadius: "50%",
      background: theme.palette.divider.default,
      position: "relative",
      "&.Mui-active": {
        background: theme.palette.primary.main,
      },
      "&.Mui-completed": {
        background: theme.palette.primary.main,
      },
      "&>circle": {
        display: "none",
      },
      "&>path": {
        display: "none",
      },
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepConnector-root": {
      top: 17,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
      "&>.MuiStepConnector-line": {
        border: `1.5px solid ${theme.palette.divider.default}`,
      },
      "&.Mui-active": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
        "&~.MuiStepLabel-alternativeLabel": {
          "& .MuiStepLabel-iconContainer": {
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              display: "block",
              borderRadius: "50%",
              background: theme.palette.primary.lighter,
              height: 28,
              width: 28,
              top: -4,
              left: -4,
            },
          },
        },
      },
      "&.Mui-completed": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
}));

export default ticketSummaryStyle;
