export interface IWebinarFormValues {
    form_field_id: string;
    label: string;
    value: string;
  }
  export interface IWebinarFormRequest {
    payload: {
      data: {
        values: Array<IWebinarFormValues>;
      };
    };
  }

  export interface IWebinarFormResponse {
    isGdprComplianceEnabled: boolean;
    gdprComplianceConsentText: string;
    communityLoginUrl?: string;
    gdprLinks: {
        cookiePrivacyPolicy: { 
             label: string;
             link: string;
           };
          termsAndConditions: { 
             label: string;
             link: string;
           };
          privacyPolicy: { 
             label: string;
             link: string;
           };
      };
      goToEventCtaLabel: string;
      showGoToEventCTA: boolean;
      ticketId: number; /* Used when submitting the form */
      attendeeFormFields: any;
      data?: {
        code?: string;
        type?: string;
      }
      message?: string;
      isODSessionEmailRegEnabled?: boolean;
  }

  export interface ISideBarCommonProps {
    onSubmit: () => void;
    onCloseSideBar: () => void;
  }
  export interface IAuthDetails {
    accessToken?: string;
  }
  
  // Webinar Form Interfaces => Start
  export interface IFieldProps {
    customGroupValueArray: any;
    children?: any;
    id: string | number;
    label: string;
    fieldType: string;
    type: string;
    isMandatory: boolean;
    isVisible: boolean;
    minLength?: number;
    maxLength?: number;
    isError?: boolean;
    labelHint?: string;
    regex?: string;
    value?: string;
    valueOptions?: string[];
    otherValue?: string;
  }
  export interface IChoices {
    label: string;
    selectedByDefault?: boolean;
    value?: string;
  }
  
  export interface IGroupValue {
    id: string;
    fieldValue: string;
    team: number;
  }
  
  export interface iFileType {
    id: number;
    name: string;
    value: boolean;
  }
  export interface IFileProps {
    maxFileSize?: number;
    fileTypes?: iFileType[];
  }
  
  export interface IGroupProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errData?: any[];
  }
  
  export interface IRegistrationField
    extends IFieldProps,
      IFileProps,
      IGroupProps {
    showOtherOption?: boolean;
    showBlankOption?: boolean;
    isEdited?: boolean;
    // [HUB-8579] Added multiple language support for Webinar Default Fields
    isDefaultField?: boolean;
    valueOptions?: string[];
    choices?: IChoices[];
    children?: IFieldProps[];
    groupValueArray?: IGroupValue[];
    isShowAddButton?: boolean;
    fieldTypeName?: string;
    buttonLabel?: string;
  }
  
  export interface IGetWebinarRequest extends IAuthDetails {
    orderToken: string;
    buyerEmail?: string;
  }
  
  export interface IGetWebinarResponse {
    fields: IRegistrationField[];
  }
  
  export interface IWebinarSubmits {
    id: string | number;
    ticketId: string;
    orderTicketId: number;
    fieldType: string;
    label: string;
    value: string;
  }
  export interface ISetWebinarRequest extends IAuthDetails {
    values: IWebinarSubmits[];
  }
  
  export interface ISetWebinarResponse {
    message?: string;
  }
  
  export type TCountryList = {
    countryName?: string;
    countryShortCode?: string;
  };

  export interface IRegion {
    name: string;
    dial_code: string;
    code: string;
  }

  export enum ActiveModalTypes {
    SUCCESS = "success",
    ERROR = "error",
    RESEND = "resend"
  }
  
  export interface IActiveModalData {
    title: string;
    subTitle: string;
    type: ActiveModalTypes;
  }
  
  export type TCountryRegion = {
    name?: string;
    shortCode?: string;
  };

  export enum PaymentStatus {
    SUCCESS = "success",
    ERROR = "error"
  }