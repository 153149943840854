import { useState, useEffect } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IApplyDiscountRequest } from "../Types/ApplyDiscount";
import {
  IBuyerFormSetResponse,
} from "../Types/BuyerFormSet";
import { IHeadersRequest } from "../Types/Ticket";

const useBuyerFormSet = (
  headers?: IHeadersRequest,
  input?: IApplyDiscountRequest
) => {
  const [data, setData] = useState<IBuyerFormSetResponse>(
    {} as IBuyerFormSetResponse
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>();

  useEffect(() => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await postApi<
          IBuyerFormSetResponse,
          IApplyDiscountRequest
        >(AppConstants.buyerFormSet, headers, input);

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as IBuyerFormSetResponse);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};

export default useBuyerFormSet;
