import React, { useEffect, useState, useMemo } from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import FileUploadWithError from "../FileUploadWithError";
import DropDown from "../DropdownWithError";
import TextField from "../InputField";
import TextAreaComponent from "../TextArea";
import produce from "immer";
import { NAME_FIELDS } from "../../Constants/Ticketing";
import CheckBoxGroup from "../CheckBoxGroup";
import TextBoxAutoSelect from "../TextBoxAutoSelect";
import RadioButtonsGroup from "../RadioButtonsGroup";
import {
  getFormFieldsAuthenticate,
  getNumberValidate,
  getTextValidate,
} from "../../Utils/FieldsValidation";
import {
  addSomePropertiesInField,
  updateFieldValue,
} from "../../Utils/FieldsPropertiesHelpers";
import CountryList from "../../Utils/regions";
import FlatPicker from "../FlatPicker";
import GroupComponent from "../GroupComponent";
import {
  getObjectElement,
  getPhoneCode,
  isTrueValueArray,
} from "../../Utils/common";
import useWebinarForm from "../../Hooks/useWebinarForm";
import { getFilterPhoneContact, isFormAuthentic } from "./Helper";
import { useStore } from "../../Context";
import { useTranslation } from "react-i18next";
import {
  IWebinarSubmits,
  IRegistrationField,
  IRegion,
  TCountryRegion,
  TCountryList,
  IFieldProps,
} from "Types/WebinarForm";
import WebinarTermsAndConditions from "./WebinarTermsAndConditions";
import { IHeadersRequest } from "Types/Ticket";
import Track from "Lib/ThirdParty/amplitude";
import { makeStyles } from "@mui/styles";
import WebinarTitle from "./WebinarTitle";
import { upperCase } from "../AttendeesDetails/helper";
import { debounce } from "lodash";
import useValidateAttendeeEmail from "Hooks/useValidateAttendeeEmail";
import { IValidateAttendeeEmailRequest } from "Types/ValidateAttendeeEmail";
// import { ClassNames } from "@emotion/react";
import customRegex from "../../Utils/RegEx";
import BtnLoader from "../../Components/Loaders/BtnLoader";

// Constants
const BUYERS_DEFAULT_FIELDS = ["email"];
const useStyles = makeStyles(() => ({
  midFormDataHolder: {
    overflowY: "auto",
    display: "flex",
    alignItems: "start",
    paddingRight: "1rem",
    flexDirection: "column",
    justifyContent: "center",
    "@media(max-width:768px)": {
      maxHeight: "calc(100% - 6.4rem)",
      minHeight: "calc(100% - 6.4rem)",
    },
  },
  isLengthyData: {
    justifyContent: "start",
  },
  registerBtnBtm: {
    position: "relative",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  isHavingFullHeight: {
    position: "relative",
    "@media(max-width:768px)": {
      height: "100%",
    },
  },
}));

const COUNTRIES: TCountryList[] = [...CountryList];

export type WebinarDynamicFormProps = {
  className?: string;
  apiData?: any;
  accessToken?: any;
  configData?: any;
  setFormData?: any;
  trackingPixelApps?: any;
};

const WebinarDynamicForm = ({
  apiData,
  accessToken,
  configData,
  setFormData,
  trackingPixelApps,
}: WebinarDynamicFormProps): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [checked, setChecked] = useState<boolean>(false);

  const { store, setToast, setWebinarFields } = useStore();
  const allowSessionOnDemandValue =
    // @ts-ignore
    store?.landingPageData?.sessions?.data[0]?.data[0]?.streamTypeId === 6
      ? // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.preRecordedIsReplayVideo
      : // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.allowSessionOnDemand;
  const eventEnd = store?.landingPageData?.eventEndtime && (store?.landingPageData?.eventEndtime && Date.now() > Number(store?.landingPageData?.eventEndtime));

  useEffect(() => {
    if (store?.configData?.webinarData?.isGdprComplianceEnabled === false) {
      setChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.configData?.webinarData?.isGdprComplianceEnabled]);
  const results =
    apiData?.attendeeFormFields &&
    addSomePropertiesInField(apiData?.attendeeFormFields);
  let tempFields: any[] = [];
  const onlyParentFields = results?.filter((field: any) => !field?.parent);
  const onlyChildFields = results?.filter((field: any) => field?.parent);
  onlyParentFields?.forEach((field: any) => {
    tempFields = [...tempFields, field];
    onlyChildFields?.forEach((childField: any) => {
      if (field?.id === childField?.parent) {
        tempFields = [...tempFields, childField];
      }
    });
  });
  const ticketId = apiData?.ticketId;
  const [profileFields, setProfileFields] = React.useState(tempFields);
  const [isVisible, setIsVisible] = React.useState<boolean>(true);
  const [hasManyFields, setHasManyFields] = React.useState<boolean>(false);
  useEffect(() => {
    if (results) {
      if (results.length >= 4) {
        setHasManyFields(true);
      } else if (
        results.filter((field: IFieldProps) =>
          ["RADIO", "CHECKBOX"].includes(field.fieldType)
        ).length
      ) {
        setHasManyFields(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: validateEmailResponse, validateAttendeeEmail } =
    useValidateAttendeeEmail();

  const resetProfileFields = (): void => {
    setProfileFields(results);
  };
  const { submitWebinarForm, setSubmitLoading, isSubmitLoading, isSubmitLoader } =
    useWebinarForm(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
        trackingPixelApps: trackingPixelApps,
      } as IHeadersRequest,
      null,
      resetProfileFields
    );

  const handleScroll = () => {
    setIsVisible(false);
    if (store?.widgetData?.isWidgetExpand) {
      setTimeout(() => {
        setIsVisible(true);
      }, 300);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateAttendeeEmailApi = useMemo(
    () =>
      debounce(async (email: string, ticketId = "") => {
        try {
          if (customRegex.emailRegEx.test(email)) {
            await validateAttendeeEmail(
              {
                authorization: configData?.accessToken,
                language: configData?.language,
              } as IHeadersRequest,
              {
                payload: {
                  data: {
                    email,
                    ticketId,
                  },
                },
              } as IValidateAttendeeEmailRequest
            );
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validateAttendeeEmail]
  );

  useEffect(() => {
    if (validateEmailResponse.isRestrictedDomain) {
      const fieldIndex = profileFields.findIndex(
        (i) => i.fieldType === "email"
      );

      const updateFields = produce([...profileFields], (draft) => {
        draft[fieldIndex] = {
          ...draft[fieldIndex],
          isError: true,
          labelHint: validateEmailResponse.message,
        };
      });
      setProfileFields([...updateFields]);
    } else {
      const fieldIndex = profileFields.findIndex(
        (i) => i.fieldType === "email"
      );

      const updateFields = produce([...profileFields], (draft) => {
        draft[fieldIndex] = {
          ...draft[fieldIndex],
          isError: false,
          labelHint: "",
        };
      });
      setProfileFields([...updateFields]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailResponse]);

  const onchangeHandler = (e: any, field: any) => {
    const updatedItems = profileFields.map((pField: any) =>
      pField?.id === field?.id
        ? {
            ...pField,
            value: field.fieldType === "phone" ? e : e.target.value,
            isError: false,
            labelHint: "",
      }
        : pField,
    );
    setProfileFields(updatedItems);

    // below code to handle email filtering scenerio
    if (
      field.fieldType === "email" &&
      customRegex.emailRegEx.test(e.target.value)
    ) {
      validateAttendeeEmailApi(e.target.value, field?.ticket_id[0]);
    } else if (
      field.fieldType === "email" &&
      !customRegex.emailRegEx.test(e.target.value)
    ) {
      const fieldIndex = profileFields.findIndex(
        (i) => i.fieldType === "email"
      );

      const updateFields = produce([...profileFields], (draft) => {
        draft[fieldIndex] = {
          ...draft[fieldIndex],
          value: e.target.value,
          isError: false,
          labelHint: "",
        };
      });
      setProfileFields([...updateFields]);
    }
  };

  const onBlurTextHandler = (event: any, fieldData: any) => {
    const currVal = event.target.value;
    const fetchFields = [...profileFields];
    const isFieldsEmpty =
      fetchFields && Array.isArray(fetchFields) && fetchFields.length;
    const fieldIndex = isFieldsEmpty
      ? fetchFields.findIndex((field) => field.id && fieldData.id === field.id)
      : -1;
    if (fieldIndex > -1) {
      const field = { ...fetchFields[fieldIndex], value: currVal };
      if (field) {
        const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(
          field.fieldType
        );
        const { hasError, errMsg } = getTextValidate(
          isDefaultField ? { ...field, isDefaultField } : field
        );

        const updateFields = produce(fetchFields, (draft) => {
          draft[fieldIndex] = {
            ...draft[fieldIndex],
            value: currVal,
            isError: draft[fieldIndex]?.isError || hasError,
            labelHint: draft[fieldIndex]?.labelHint || errMsg,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };
  const handleUpdateDetails = (id: string, value: unknown) => {
    const getFields = updateFieldValue(profileFields, id, value);
    const isFieldExists =
      getFields && Array.isArray(getFields) && getFields.length;
    if (isFieldExists && typeof getFields !== "boolean") {
      setProfileFields([...getFields]);
    }
  };

  const onChangeFile = (id: string, value: string) => {
    handleUpdateDetails(id, value);
  };

  const onCheckboxChangeHandler = (id: string, value: string[]) => {
    handleUpdateDetails(id, value);
  };
  const onRadioChangeHandler = (id: string, value: string) => {
    handleUpdateDetails(id, value);
  };

  const handleOtherChange = (id: string, event: any) => {
    const initVal = event.target.value;
    const index = profileFields.findIndex(
      (ele: any) => String(ele.id) === String(id)
    );
    if (index > -1 && (initVal || initVal === "")) {
      const fetchType = get(profileFields[index], "type", "");
      if (["DROPDOWN", "RADIO", "CHECKBOX"].includes(fetchType)) {
        const loadField = {
          ...profileFields[index],
          otherValue: initVal || "",
          frontendData: {},
        };
        const authField = getFormFieldsAuthenticate(loadField);
        const hasError = get(authField, "hasError", false);
        const errMsg = get(authField, "errMsg", "");
        const errData = get(authField, "errData", []);

        const hasErrorData =
          errData && Array.isArray(errData) && errData.length;

        const fetchField = hasErrorData
          ? { ...loadField, isError: hasError, labelHint: errMsg, errData }
          : { ...loadField, isError: hasError, labelHint: errMsg };
        const updateFields = produce(profileFields, (draft) => {
          draft[index] = { ...fetchField };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onChangeCountry = (id: string, value: any, key: string) => {
    const isStateIndex = profileFields.findIndex(
      (fieldType: any) => fieldType && fieldType === "state"
    );
    let stateId = "";
    if (isStateIndex > -1) {
      const stateField = profileFields[isStateIndex];
      stateId = stateField.id;
    }
    const updateValue = typeof value === "object" && key ? value[key] : value;
    let getFields = updateFieldValue(profileFields, id, updateValue);
    if (stateId && typeof getFields !== "boolean")
      getFields = updateFieldValue(getFields, stateId, "");
    const isFieldExists =
      getFields && Array.isArray(getFields) && getFields.length;
    if (isFieldExists && typeof getFields !== "boolean") {
      setProfileFields([...getFields]);
    }
  };

  const onDateChange = (id: string, value: any) => {
    handleUpdateDetails(id, value);
  };

  const onChangeGroupHandler = (id: string, value: any): void => {
    handleUpdateDetails(id, value);
  };

  const generateFormValues = (
    fields: IRegistrationField[]
  ): IWebinarSubmits[] => {
    const fieldsValue: IWebinarSubmits[] = [];
    fields.forEach((field) => {
      if (field.value) {
        let finalValue;
        if (Array.isArray(field.value)) {
          if (field.value.includes("Other")) {
            finalValue = field.value
              .map((eachValue) =>
                eachValue === "Other" ? field.otherValue : eachValue
              )
              .join(",");
          } else {
            finalValue = field.value.join(",");
          }
        } else if (
          field.type.toUpperCase() === "RADIO" &&
          field.value === "Other" &&
          field.otherValue
        ) {
          finalValue = field.otherValue;
        } else {
          finalValue = field.value;
        }
        if (finalValue) {
          fieldsValue.push({
            ticketId,
            orderTicketId: 0,
            id: field.id,
            label: field.label,
            value: finalValue,
            fieldType: field.fieldType,
          });
        }
      } else if (
        field.valueOptions &&
        Array.isArray(field.valueOptions) &&
        field.valueOptions.length
      ) {
        fieldsValue.push({
          ticketId,
          orderTicketId: 0,
          id: field.id,
          label: field.label,
          value: field.valueOptions
            .map((eachValue) =>
              eachValue === "Other" ? field.otherValue : eachValue
            )
            .join(","),
          fieldType: field.fieldType,
        });
      } else if (field.customGroupValueArray) {
        const data: { id: any; value: any }[] = [];
        field?.children?.forEach((child: any) => {
          data.push({
            id: child.id,
            value: field.customGroupValueArray
              .filter((x: any) => x)
              .map((childField: any) => childField[child.id])
              .join(","),
          });
        });
        fieldsValue.push({
          ticketId,
          orderTicketId: 0,
          id: field.id,
          label: field.label,
          value: JSON.stringify(data),
          fieldType: field.fieldType,
        });
      }
    });

    return fieldsValue;
  };

  const handleSubmitFields = () => {
    const fetchFields = [...profileFields];
    const values = generateFormValues(fetchFields);

    Track.webinarRegistrationDetails({
      eventId: store?.configData?.previewData?.eventId,
      eventName: store?.landingPageData?.eventName,
      organiserId: store?.configData?.previewData?.organiserId,
      totalFieldsCount: fetchFields.length,
      totalFieldsFilled: fetchFields.filter(
        (field) => field.value === field.value || field.valueOptions
      ).length,
    });
    setFormData(values);
    submitWebinarForm({
      payload: {
        data: {
          values,
          ticketingLink: `${configData?.communityUrl}/register`,
          isSimpleRegistrationEnabled: true,
          orderToken: "",
          ...(allowSessionOnDemandValue === 1 && eventEnd ? { allowSessionOnDemand: true } : {}),
        },
        ticketId: store?.configData?.webinarData?.ticketId || "",
      },
    });
    setWebinarFields(profileFields);
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const handleContinueClick = () => {
    const { isAuth } = isFormAuthentic(profileFields);
    setSubmitLoading(true);

    // index required for email filtering error check
    const emailFieldIndex = profileFields.findIndex(
      (i) => i.fieldType === "email"
    );

    // eslint-disable-next-line no-constant-condition
    if (isAuth && !profileFields[emailFieldIndex]?.isError) {
      handleSubmitFields();
    } else {
      handleToastError(t("please-check-all-the-fields-are-correctly-filled"));
      setSubmitLoading(false);
      // if (fields && Array.isArray(fields) && fields.length) {
      //   setProfileFields([...fields]);
      // }
    }
  };

  const onBlurField = (id: any, currField: any, pId: any) => {
    const fIndex = profileFields.findIndex(
      (field: any) => String(field.id) === String(id)
    );
    if (fIndex > -1) {
      const isArray = isTrueValueArray(currField);
      if (isArray) {
        const fetchField = { ...profileFields[fIndex] };
        const errData = getObjectElement(fetchField, "errData", []);
        const updateErrData = produce(errData, (draft) => {
          currField.forEach((item: any) => {
            if (item.isError && item.labelHint) {
              draft[pId] = { [id]: item.labelHint };
            } else {
              if (errData[pId] && errData[pId][id]) draft[pId] = undefined;
            }
          });
        });

        const updateFields = produce(profileFields, (draft) => {
          draft[fIndex] = {
            ...fetchField,
            children: [...currField],
            errData: updateErrData,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onChangeSelect = (id: string, value: any, key: string) => {
    const updateValue = typeof value === "object" && key ? value[key] : value;
    handleUpdateDetails(id, updateValue);
  };

  const onBlurPhone = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ): void => {
    const currVal = event.target.value;
    const fetchFields: IRegistrationField[] = [...profileFields];
    const isFieldsEmpty =
      fetchFields && Array.isArray(fetchFields) && fetchFields.length;
    const fieldIndex = isFieldsEmpty
      ? fetchFields.findIndex(
        (field) => field.id && String(id) === String(field.id)
      )
      : -1;

    if (fieldIndex > -1) {
      const initValue = get(profileFields[fieldIndex], "value", "");
      const [prevDialer] = getPhoneCode(initValue);
      const finalContactNo = getFilterPhoneContact(
        String(prevDialer),
        String(currVal)
      );
      const field = {
        ...fetchFields[fieldIndex],
        value: finalContactNo,
      };
      if (field) {
        const { hasError, errMsg } = getNumberValidate(field);
        const updateFields = produce(fetchFields, (draft) => {
          draft[fieldIndex] = {
            ...draft[fieldIndex],
            value: finalContactNo,
            isError: hasError,
            labelHint: errMsg,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onRegionSelect = (region: IRegion, id: string | number) => {
    const initVal = get(region, "dial_code", "");
    const index = profileFields.findIndex(
      (ele: any) => String(ele.id) === String(id)
    );
    if (index > -1 && initVal) {
      const initValue = get(profileFields[index], "value", "");
      const [, prevNumber] = getPhoneCode(initValue);

      const fetchField = {
        ...profileFields[index],
        isDefaultField: true,
        value: `${initVal}-${prevNumber}`,
      };
      const { hasError, errMsg } = getNumberValidate(fetchField);
      const updateFields = produce(profileFields, (draft) => {
        draft[index] = { ...fetchField, isError: hasError, labelHint: errMsg };
      });
      setProfileFields([...updateFields]);
    }
  };

  const generateFieldUI = () => {
    const isCountryExists = profileFields?.findIndex(
      ({ fieldType = "" }) => fieldType && fieldType === "country"
    );

    return profileFields?.map((field: any, index: number) => {
      const {
        id = -1,
        label = "",
        fieldType = "",
        isMandatory,
        isVisible,
        minLength,
        maxLength,
        otherValue = "",
        isError,
        labelHint = "",
        regex = "",
      } = field;

      const type = upperCase(get(field, "type", ""));
      const isNameFields: string | boolean =
        fieldType && NAME_FIELDS.includes(fieldType);
      const fieldValue = get(field, "value");
      const value = fieldValue || "";

      const WrapperClassName = isNameFields ? "" : "";

      const choices = get(field, "choices", []);
      const showOtherOption = get(field, "showOtherOption", false);
      const showBlankOption = get(field, "showBlankOption", false);
      let updateOptions = [...choices];
      if (choices && Array.isArray(choices)) {
        if (showOtherOption) {
          updateOptions = produce(updateOptions, (draft: any) => {
            draft.push({ label: "Other", selectedByDefault: false });
          });
        }

        if (showBlankOption) {
          updateOptions = produce(updateOptions, (draft: any) => {
            draft.unshift({ label: "None", selectedByDefault: true });
          });
        }
      }

      if (field?.parent) {
        const parentField = profileFields?.find(
          (item: any) => item.id === field?.parent
        );
        const optionField = parentField?.parentOptions?.find(
          (opt: any) => opt.optionId === field.optionId
        )?.name;
        if (parentField?.type === "CHECKBOX") {
          if (
            !parentField?.valueOptions?.find((x: string) => x === optionField)
          ) {
            return <></>;
          }
        } else if (
          parentField?.type === "RADIO" ||
          parentField?.type === "DROPDOWN"
        ) {
          if (parentField?.value !== optionField) {
            return <></>;
          }
        }
      }

      if (["TEXT", "NUMBER"].includes(type)) {
        const isEmail: string | boolean = fieldType === "email";
        const isNumber: boolean = type === "NUMBER";
        const isMobile: boolean = isNumber && fieldType === "phone";

        const isDefaultField: boolean =
          BUYERS_DEFAULT_FIELDS.includes(fieldType);
        const multiLangLabel = isDefaultField ? t(fieldType) : label;

        if (isMobile) {
          const [phoneCode, phoneNumber] = getPhoneCode(value);
          return (
            <Grid item xs={12} key={index}>
              <TextBoxAutoSelect
                id={String(id)}
                key={index}
                placeHolder={multiLangLabel}
                textLabel={`${multiLangLabel}`}
                withoutRadius="mobileTextField"
                name={multiLangLabel}
                countryCode={String(phoneCode)}
                value={String(phoneNumber)}
                textType={"text"}
                position="top"
                className="inputText mobiInput"
                iconColor="themecolor"
                iconAlign="start"
                bgColor="bgColor"
                errorMsg={labelHint}
                testId="mobileNumber"
                onSelect={(region: any) => onRegionSelect(region, id)}
                onBlur={(e: any) => onBlurPhone(e, id)}
                hintText={labelHint}
                errorType={isError ? "error" : ""}
                otherLabelKey={"dial_code"}
                maxLength={maxLength}
                isCharLimit={maxLength && maxLength > 0}
                charLimit={maxLength - String(phoneNumber).length}
                isMandatory={isMandatory}
                onChange={(e) => {
                  const val = `${phoneCode}-${e.target.value}`;
                  onchangeHandler(val, field);
                }}
              />
            </Grid>
          );
        } else {
          return (
            <Grid
              item
              xs={12}
              lg={
                isEmail
                  ? 12
                  : multiLangLabel == "Text" || multiLangLabel == "Number"
                    ? 12
                    : 6
              }
              key={index}
            >
              <TextField
                key={index}
                regex={regex}
                id={String(id)}
                type={isEmail ? "email" : isNumber ? "number" : "text"}
                name={multiLangLabel}
                required={isMandatory}
                label={multiLangLabel}
                placeHolder={multiLangLabel}
                value={value.toString()}
                errorMsg={labelHint}
                onChange={(e) => {
                  onchangeHandler(e, field);
                }}
                onBlur={(e: any) => onBlurTextHandler(e, field)}
                endIcon={
                  maxLength
                    ? (value.length > 0
                      ? maxLength - value.length
                      : maxLength
                    ).toString()
                    : null
                }
                maxLength={maxLength}
              />
            </Grid>
          );
        }
      }

      if (type === "DATE") {
        return (
          <Grid item xs={12} key={index}>
            <TextField
              key={id}
              id={String(id)}
              value={value}
              required={isMandatory}
              label={label}
              name={label}
              placeHolder={t("date-format")}
              type="text"
              icon
              endIcon={
                <FlatPicker
                  options={{ dateFormat: "d/m/Y" }}
                  onChange={(date, val) => onDateChange(String(id), val)}
                  btnClass
                />
              }
              errorMsg={labelHint}
            />
          </Grid>
        );
      }

      if (type === "GROUP") {
        const customGroupValueArray = get(field, "customGroupValueArray", []);
        const isShowAddButton = get(field, "isShowAddButton", false);
        const buttonLabel = get(field, "buttonLabel", "");
        const fieldTypeName = get(field, "fieldTypeName", "");
        const errData = get(field, "errData", []);

        const fieldComp = (
          <Grid item xs={12} key={index}>
            <GroupComponent
              key={index}
              id={String(id)}
              value={customGroupValueArray}
              label={`${label}`}
              isMandatory={isMandatory}
              onChange={(id, val) => onChangeGroupHandler(id, val)}
              onBlur={(id: any, field: any, pId: any) =>
                onBlurField(id, field, pId)
              }
              isVisible={isVisible}
              required={isMandatory}
              fields={field.children}
              errData={errData}
              hasError={isError}
              errMsg={labelHint}
              minLength={minLength}
              maxLength={maxLength}
              isShowAddButton={isShowAddButton}
              buttonLabel={buttonLabel}
              fieldTypeName={fieldTypeName}
            />
          </Grid>
        );
        return fieldComp;
      }

      if (type === "TEXTAREA") {
        return (
          <Grid item xs={12} key={index}>
            <TextAreaComponent
              key={index}
              id={String(id)}
              name={label}
              required={isMandatory}
              label={label}
              placeholder={label}
              value={value.toString()}
              onChange={(e: any) => {
                onchangeHandler(e, field);
              }}
              errorMsg={labelHint}
              onBlur={(e: any) => onBlurTextHandler(e, field)}
              isCharLimit={!!(maxLength && maxLength > 0)}
              charLimit={maxLength - value.toString().length}
              maxLength={maxLength}
            />
          </Grid>
        );
      }

      if (type === "RADIO") {
        const isVisible = get(field, "isVisible", false);
        const showOtherOption = get(field, "showOtherOption", false);
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onRadioChangeHandler(String(id), initValue);
        }

        return (
          <Grid item xs={12} key={index}>
            <RadioButtonsGroup
              key={String(id)}
              id={String(id)}
              label={label}
              name={label}
              isVisible={isVisible}
              options={[...updateOptions]}
              value={initValue}
              handleChange={(id, value) => onRadioChangeHandler(id, value)}
              hasError={isError}
              errMsg={labelHint}
              otherValue={otherValue}
              isMandatory={isMandatory}
              hasOther={showOtherOption}
              handleOtherChange={(id, e) => handleOtherChange(id, e)}
            />
          </Grid>
        );
      }

      if (type === "CHECKBOX") {
        const isVisible = get(field, "isVisible", false);
        const valueOptions = get(field, "valueOptions", []);

        const defaultSelectedChoices = Array.isArray(choices)
          ? choices
            .filter((choice) => choice.selectedByDefault)
            .map((choice) => choice.label)
          : [];

        let initValue = valueOptions;
        const hasValue =
          initValue && Array.isArray(initValue) && initValue.length;
        if (defaultSelectedChoices.length && !hasValue) {
          initValue = defaultSelectedChoices.filter(
            (value, index, self) => self.indexOf(value?.trim()) === index
          );
          onCheckboxChangeHandler(String(id), initValue);
        }

        return (
          <Grid item xs={12} key={index}>
            <CheckBoxGroup
              key={String(id)}
              id={String(id)}
              label={label}
              isVisible={isVisible}
              value={initValue}
              handleChange={(id, value) => onCheckboxChangeHandler(id, value)}
              options={[...updateOptions]}
              hasError={isError}
              errMsg={labelHint}
              otherValue={otherValue}
              handleOtherChange={(id, e) => handleOtherChange(id, e)}
              isMandatory={isMandatory}
            />
          </Grid>
        );
      }

      if (type === "FILE") {
        const maxFileSize = get(field, "maxFileSize", 0);
        const fileTypes = get(field, "fileTypes", []).map((fileType: any) => {
          return { ...fileType, value: true };
        });
        const isDisabledField = get(field, "isDisabledField", false);

        return (
          <Grid item xs={12} key={index}>
            <FileUploadWithError
              key={index}
              id={String(id)}
              accessToken={accessToken}
              value={value}
              label={label}
              onChange={(val: string) => onChangeFile(String(id), val)}
              maxFileSize={maxFileSize}
              fileTypes={fileTypes}
              isVisible={isVisible}
              isRequired={isMandatory}
              hasError={isError}
              errMsg={labelHint}
              disabled={isDisabledField}
            />
          </Grid>
        );
      }

      if (type === "DROPDOWN" && fieldType.toLowerCase() === "country") {
        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              placeHolder={t("country-place-holder")}
              isMandatory={isMandatory}
              value={value}
              otherValue={otherValue}
              label={label}
              name="countryName"
              valueKey="countryName"
              labelKey="countryName"
              options={[...COUNTRIES]}
              errorMsg={labelHint}
              onChange={(val) => onChangeCountry(id, val, "countryName")}
              onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
            />
          </Grid>
        );
      }

      if (type === "DROPDOWN" && fieldType.toLowerCase() === "state") {
        const fetchCountry = get(profileFields[isCountryExists], "value", "");
        const fetchRegions = COUNTRIES.find(
          ({ countryName = "" }) => countryName && countryName === fetchCountry
        );
        const regions: TCountryRegion[] = get(fetchRegions, "regions", []);
        if (regions && Array.isArray(regions) && isCountryExists !== -1) {
          return (
            <Grid item xs={12} key={index}>
              <DropDown
                key={id}
                id={String(id)}
                label={label}
                isMandatory={isMandatory}
                value={value}
                otherValue={otherValue}
                name="name"
                placeHolder={t("state-place-holder")}
                valueKey="name"
                labelKey="name"
                options={[...regions]}
                onChange={(val) => {
                  onChangeSelect(id, val, "name");
                }}
                onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
              />
            </Grid>
          );
        }
      }

      if (type === "DROPDOWN" && fieldType.toLowerCase() === "custom") {
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onChangeSelect(String(id), initValue, "label");
        }

        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              label={label}
              name="label"
              isMandatory={isMandatory}
              value={initValue}
              otherValue={otherValue}
              labelKey="label"
              valueKey="label"
              options={updateOptions}
              errorMsg={labelHint}
              isError={isError}
              labelHint={labelHint}
              placeHolder={"Select"}
              onChange={(val) => {
                onChangeSelect(String(id), val, "label");
              }}
              // eslint-disable-next-line no-console
              onChangeOther={(e: any) => {
                handleOtherChange(String(id), e);
              }}
            />
          </Grid>
        );
      }

      if (type === "LINK") {
        return (
          <Grid item xs={12} key={index}>
            <Box key={index} className={WrapperClassName}>
              <TextField
                key={id}
                id={String(id)}
                name={label}
                value={value}
                label={label}
                required={isMandatory}
                placeHolder={label}
                errorMsg={labelHint}
                onChange={(e) => {
                  onchangeHandler(e, field);
                }}
                onBlur={(e: any) => onBlurTextHandler(e, field)}
              />
            </Box>
          </Grid>
        );
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          maxHeight: store.configData?.widgetFixedHeight || "540px",
          minHeight: store.configData?.widgetFixedHeight || "540px",
        }}
        className={`${classes.midFormDataHolder} ${!hasManyFields ? "" : classes.isLengthyData
          }`}
      >
        <WebinarTitle />
        <Grid container spacing={2}>
          {generateFieldUI()}
        </Grid>
        {store?.configData?.webinarData?.isGdprComplianceEnabled && (
          <Box
            sx={{
              position: "relative",
              "@media(max-width:768px)": {
                width: "100%",
                position: "relative",
                bottom: 0,
                left: 0,
                padding: "0 0rem",
              },
            }}
            className={`continueBtn ${isVisible ? "scrollstop" : "scrollContinue"
              }`}
          >
            <WebinarTermsAndConditions
              consent={apiData?.gdprComplianceConsentText}
              checked={checked}
              setChecked={() => setChecked((state) => !state)}
            />
          </Box>
        )}
      </Box>
      <Box className={classes.registerBtnBtm}>
        <Button
          color="primary"
          variant="contained"
          sx={{ marginTop: "1.6rem", width: "100%" }}
          fullWidth
          onClick={handleContinueClick}
          disabled={isSubmitLoading || !checked || isSubmitLoader}
        >
          {isSubmitLoader ? <BtnLoader /> : t("submit")}
        </Button>
      </Box>
    </>
  );
};

export default function WebinarRegistrationForm(props: any) {
  const { configData } = props;
  const classes = useStyles();

  return (
    <>
      <Box className={classes.isHavingFullHeight}>
        <Box
          sx={{
            "@media(max-width:768px)": {
              height: "100%",
              overflowY: "auto",
              overflowX: "hidden",
              paddingRight: "1.6rem",
            },
          }}
        >
          <WebinarDynamicForm
            accessToken={configData?.accessToken}
            {...props}
          />
        </Box>
      </Box>
    </>
  );
}
