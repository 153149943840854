import React, { useState } from "react";
import { IconButton, Typography, Box } from "@mui/material";
import NewTabIcon from "../Assets/Svg/NewTabIcon";
import InputUploadBox from "./UploadBox";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import {
  getFileUploadDetails,
  EXTRACT_REGEX,
  TFileSelect,
  generateAllowTypes,
  IFileUpload,
} from "../Utils/FileHelper";
import { iFileType } from "../Types/BuyerDetails";
import useFileUpload from "../Hooks/useFileUpload";
import { useTranslation } from "react-i18next";
import { useStore } from "../Context";

const useStyle = makeStyles((theme: any) => ({
  actionMsg: {
    color: theme.palette.text.secondary,
    "&.successText": {
      color: theme.palette.success.main,
    },
    "&.failText": {
      color: theme.palette.error.main,
    },
  },
  closeBtn: {
    padding: theme.spacing(0.6),
    marginLeft: theme.spacing(0.6),
  },
}));

// interfaces
interface TCompProps {
  id: string;
  className?: string;
  value: string;
  label: string;
  onChange?: (path: string) => void;
  maxFileSize?: number;
  fileTypes?: iFileType[];
  isVisible: boolean;
  isRequired: boolean;
  hasError: boolean;
  errMsg: string;
  disabled: boolean;
  tabIndex?: number;
  accessToken?: any;
  withoutInput?: boolean;
  customUploadIcon?: React.ReactNode;
  customUploadText?: string;
}

interface TCompState {
  uploadStatus: string;
  files: string | TFileSelect;
}

// constants
const initState: TCompState = {
  uploadStatus: "IDEAL",
  files: "",
};

const FileUploadWithError = (currProps: TCompProps): JSX.Element => {
  const { setToast } = useStore();
  const [t] = useTranslation();
  const [state, setState] = useState<TCompState>({ ...initState });

  const classes = useStyle();

  const {
    data: FileResponseData,
    uploadFile,
    setUploadingPer,
    uploadingPer,
    loading,
    error: fail,
  } = useFileUpload();

  const {
    id,
    value,
    label,
    onChange,
    isRequired,
    hasError,
    errMsg,
    disabled,
    tabIndex,
    maxFileSize = 0,
    fileTypes = [],
    accessToken,
    withoutInput = false,
    customUploadText,
    customUploadIcon,
  } = currProps;

  React.useEffect(() => {
    if (FileResponseData?.fileURL && value === "") {
      if (onChange) onChange(FileResponseData?.fileURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FileResponseData]);

  const handleUploadProgress = (val: number) => {
    setUploadingPer(val);
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const resetFileSelection = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ele: any = document.getElementById(id);
    if (ele) ele.value = "";
  };

  const { files } = state;

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const regArr = EXTRACT_REGEX.exec(file.name);
      const ext =
        regArr && Array.isArray(regArr) && regArr.length > 1 ? regArr[1] : "";
      const allowedType = generateAllowTypes(fileTypes);
      let errorMsg = "";
      if (!ext || !allowedType.includes(`.${ext.toLocaleLowerCase()}`)) {
        errorMsg = `${t(
          "this-file-type-is-not-supported-supported-attachments-are"
        )} ${allowedType.toString()} `;
      }

      const currFileSize = (file.size / (1024 * 1024)).toFixed(2);
      if (Number(currFileSize) > Number(maxFileSize)) {
        errorMsg = `${t(
          "the-file-size-is-too-high-please-upload-document-lesser-than"
        )} ${maxFileSize}MB`;
      }
      if (errorMsg) {
        handleToastError(errorMsg);
        resetFileSelection();
        return;
      }
      const valueFile = event.target.files[0];

      const fileDetails = getFileUploadDetails(valueFile);

      const payload: IFileUpload = {
        progressEmmiter: handleUploadProgress,
        appendData: {
          ...fileDetails,
          fileName: fileDetails.fileName.replace(/\s/g, ""), // Space Removed from file Name
        },
        token: accessToken,
      };

      setState({ ...state, files: valueFile, uploadStatus: "UPLOADING" });
      uploadFile(payload, file);
    } else {
      handleToastError("Choose valid file type");
      resetFileSelection();
      return;
    }
  };

  const removeFileChange = (): void => {
    if (value && onChange) onChange("");
    setState({ files: "", uploadStatus: "IDEAL" });
    setUploadingPer(0);
  };

  let hintMsg;
  if (maxFileSize) {
    hintMsg = (
      <>
        {t("max-size")} {maxFileSize} MB
      </>
    );
  }
  if (fileTypes && Array.isArray(fileTypes) && fileTypes.length) {
    if (hintMsg) {
      hintMsg = (
        <>
          {hintMsg}
          <br />
          {t("file-supported-types")} {generateAllowTypes(fileTypes).join(", ")}
        </>
      );
    } else {
      hintMsg = (
        <>
          {t("file-supported-types")} {generateAllowTypes(fileTypes).join(", ")}
        </>
      );
    }
  }

  return (
    <Box sx={{ textAlign: "left !important" }}>
      {value ? (
        <Box sx={{ textAlign: "left !important" }}>
          {label ? (
            <Typography
              variant="caption"
              component="label"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontWeight: 400,
                textAlign: "left",
              })}
            >
              {label || " "}

              {isRequired ? (
                <Typography
                  component="span"
                  sx={(theme: any) => ({
                    color: theme.palette.primary.main,
                    fontSize: 16,
                    lineHeight: 0,
                  })}
                >
                  *
                </Typography>
              ) : (
                false
              )}
            </Typography>
          ) : (
            false
          )}

          <Box
            display="flex"
            px={1}
            alignItems="center"
            justifyContent="space-between"
            sx={(theme: any) => ({
              minHeight: "40px",
              borderRadius: "4px",
              border: `1px solid ${theme.palette.divider.default}`,
            })}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ "&>svg": { minWidth: "2.4rem" } }}
            >
              <NewTabIcon height="18px" width="18px" />
              <Typography
                variant="body2"
                component="label"
                sx={(theme: any) => ({
                  marginLeft: 0.7,
                  fontWeight: 400,
                  color: theme.palette.text.secondary,
                })}
                onClick={value ? () => window.open(value, "_blank") : undefined}
              >
                {t("uploaded-file")}
              </Typography>
            </Box>
            {value ? (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  component="span"
                  className={`${classes.actionMsg} ${
                    !loading ? "successText" : false
                  } ${fail ? "failText" : false}`}
                  sx={{ fontWeight: 400 }}
                >
                  {" "}
                  {!loading ? `${t("uploaded-successfully")}` : false}{" "}
                </Typography>
                <IconButton
                  className={classes.closeBtn}
                  onClick={() => removeFileChange()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : !value && files && fail ? (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  component="span"
                  className={`${classes.actionMsg}  ${
                    fail ? "failText" : false
                  }`}
                  sx={{ fontWeight: 400 }}
                >
                  {" "}
                  {fail ? `${t("failed!-please-retry")}` : false}
                </Typography>
                <IconButton
                  className={classes.closeBtn}
                  onClick={() => removeFileChange()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : !value && files && loading ? (
              <Box display="flex" alignItems="center">
                <Typography
                  variant="caption"
                  component="span"
                  className={`${classes.actionMsg} ${
                    !loading ? "successText" : false
                  }`}
                  sx={{ fontWeight: 400 }}
                >
                  {" "}
                  {loading ? `${t("uploading")} ${uploadingPer}%` : false}{" "}
                </Typography>
                <IconButton
                  className={classes.closeBtn}
                  onClick={() => removeFileChange()}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              false
            )}
          </Box>
        </Box>
      ) : (
        <InputUploadBox
          placeHolder={t("browse-a-file-to-upload")}
          id={id}
          label={label}
          acceptFile={generateAllowTypes(fileTypes).toString()}
          onChange={onFileChange}
          value={value}
          errorType={hasError ? "error" : ""}
          errorMsg={errMsg}
          hintText={errMsg}
          withoutInput={withoutInput}
          hintMsg={hintMsg}
          disabled={disabled}
          customUploadIcon={customUploadIcon}
          customUploadText={customUploadText}
          tabIndex={tabIndex}
          required={isRequired}
        />
      )}
    </Box>
  );
};
export default FileUploadWithError;
