/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useEffect, useCallback } from "react";
import Button from "@mui/material/Button";
import CustomModal from "./CustomModal";
import Box from "@mui/material/Box";
import TicketPurchaseSuccessIcon from "../Assets/Svg/TicketPurchaseSuccessIcon";
import TicketPurchaseFailedIcon from "../Assets/Svg/TicketPurchaseFailedIcon";
import { CircularProgress, Typography } from "@mui/material";
import { ITicketInfoProps } from "..";
import ModalLoader from "./Loaders/ModalLoader";
import { useTranslation } from "react-i18next";
import TicketPaymentSummary from "./TicketPaymentSummary";
import useGetNewStripeSession from "../Hooks/useGetNewStripeSession";
import {
  getParameterByName,
  roundOffTo2,
  getPathName,
  stripQueryParams,
  getUTMUrlPath,
} from "../Utils/common";
import { useStore } from "../Context";
import { IHeadersRequest } from "../Types/Ticket";
import { getBannerImage } from "../Utils/getImage";
import { getOrganiserId } from "../Utils/getIds";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Track from "Lib/ThirdParty/amplitude";
import { trackEventPixelsByApp, AppType } from "Utils/trackingPixels";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { IConfigData, ICustomLandingPage } from "../Types/LandingPage";
import envConfig from "config";
import FAQModal from "./OffLinePayment/FaqModal";
import { faqList } from "./OffLinePayment/OrderStatus";
import usePaymentVerify from "Hooks/usePaymentVerify";
import { isAndroidIPhoneIPad } from "Utils/navigator";

const PAYMENT_OPTIONS: string[] = ["CONFIRM", "FAIL"];

interface ITicketPaymentStatus extends ITicketInfoProps {
  isLoading: boolean;
  getPaymentId?: any;
  setKeepMounted: () => void;
  onClose: () => void;
  onOpen: () => void;
  handleClose?: () => void;
  microFeEventHandler?: () => void;
  isShowGoToEventCTA?: boolean;
}

const TicketPaymentStatus = (props: ITicketPaymentStatus) => {
  const [t] = useTranslation();
  const [open, toggleModal] = useState(true);
  const [faqModal, setFaqModal] = useState(false);
  const isMobile = isAndroidIPhoneIPad();

  const ERROR_MESSAGES: any = {
    NOT_RCVD: t("PAYMENT_NOT_RCVD"),
    DTL_MSMCH: t("PAYMENT_COULD_NOT_BE_VERIFIED"),
    TKTS_NA: t("TICKETS_NOT_AVAILABLE"),
  };

  const {
    data: rePaymentToken,
    getNewStripeSessionId,
    error: rePayError,
    loading: newStripeDataLoading,
  } = useGetNewStripeSession();

  const { data: paymentVerifyData, error: paymentVerifyError } =
    usePaymentVerify();

  const {
    configData,
    landingPageData,
    eventInfo,
    isLoading,
    getPaymentId,
    setKeepMounted,
    onClose,
    onOpen,
    microFeEventHandler,
  } = props;
  const {
    communityUrl = "",
    isCustomHosting,
    originalCommunityUrl,
  } = configData as IConfigData;
  // const { communityPageShow = false } = eventInfo as IEventInfo;

  const orgId = getOrganiserId(configData?.accessToken || "");
  const {
    store,
    setPaymentDetails,
    setBuyerEmail,
    setSelectedTickets,
    setRegistrationToggle,
    setToast,
  } = useStore();
  const isShowSnowBall =
    envConfig.SNOWBALL_WIDGET?.indexOf(
      props?.eventInfo?.previewData?.eventId
    ) !== -1;
  const {
    paymentDetails,
    paymentDetails: { paidTicketDtls = {} },
    startFromSummary,
    stripeAccountId,
  } = store;

  const getEventBanner = get(landingPageData, "eventBanner", []);
  const lpConfig = get(
    landingPageData,
    "customLandingPageData",
    {}
  ) as ICustomLandingPage;
  const isBannerExists =
    getEventBanner && Array.isArray(getEventBanner) && getEventBanner.length;
  const currentBanner = isBannerExists ? getEventBanner[0] : "";

  const paymentStatus = paymentDetails?.currPaymentStatus === "CONFIRM";
  const getPaymentStatus = getParameterByName<string>("payment");

  const isRePaymentLoading = newStripeDataLoading && !paymentStatus;
  const isSimpleRegistrationEnabled = store?.registrationToggle;

  // refs
  const refOrderId = useRef<string>();

  useEffect(() => {
    const getOrderToken = getParameterByName<number>("orderid");
    const isPaymentExists = getOrderToken && getPaymentStatus;
    if (isPaymentExists) {
      refOrderId.current = String(getOrderToken);
    }
    return () => {
      // resetToken()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackPurchasedTicket = async (amplitude: any) => {
    Track.purchasedTicket({
      isWidget: store?.widgetData?.isWidgetExpand,
      ...amplitude,
    });
  };

  const resetRoute = (isSuccess: boolean) => {
    let updateRoute = "";
    const utmSearchStr = getUTMUrlPath();
    if (isCustomHosting) {
      // const current = new URL(window.location.href);
      // current.searchParams.delete("payment");
      // current.searchParams.delete("token");
      // current.searchParams.delete("orderid");
      // updateRoute = current.toString();
      
      // updateRoute = `${window.location.protocol}//${window.location.host}/register`;
    } else if (store?.widgetData?.isWidgetExpand)
      updateRoute = stripQueryParams(configData?.communityUrl as string);
    else updateRoute = getPathName(configData?.communityUrl as string);
    if (updateRoute) {
      // eslint-disable-next-line no-console
      if (
        lpConfig?.isCustomLandingPageWorking &&
        lpConfig?.isUsingCustomLandingPage &&
        !store?.widgetData?.isWidgetExpand
      ) {
        // ESCAPE
       
      } else {

        window.history.replaceState(
          null,
          "",
          `${updateRoute}${
            isSuccess
              ? `${utmSearchStr ? "&" : "?"}success=${getPaymentId}`
              : utmSearchStr
          }`
        );
      }
    }
  };

  const captureFailedRegEvent = () => {
    const stepsToTrack = props.eventInfo?.stepsToTrack;
    const trackingPixelApps = props.eventInfo?.trackingPixelApps;
    // Step 3: 'Failed Registration'
    const failedEvent = stepsToTrack?.find((eachStep) => eachStep.id === 3);
    if (failedEvent?.isEnabled) {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(trackingPixelApps) &&
        trackingPixelApps
          ?.filter((eachApp) => eachApp.isEnabled)
          .forEach((eachItem: { app: AppType }) => {
            trackEventPixelsByApp(
              eachItem.app,
              failedEvent.eventName as unknown as string
            );
          });
    }
  };

  useEffect(() => {
    const stepsToTrack = props.eventInfo?.stepsToTrack;
    const trackingPixelApps = props.eventInfo?.trackingPixelApps;
    if (!isEmpty(paymentVerifyData)) {
      resetRoute(paymentVerifyData.type === "CONFIRM");
      if (paymentVerifyData.type === "CONFIRM") {
        // Amplitude
        trackPurchasedTicket(paymentVerifyData.amplitude);
        // Step 2: 'Successful Registration'
        const successEvent = stepsToTrack?.find(
          (eachStep) => eachStep.id === 2
        );
        if (successEvent?.isEnabled) {
          // eslint-disable-next-line no-unused-expressions
          !isEmpty(trackingPixelApps) &&
            trackingPixelApps
              ?.filter((eachApp: { isEnabled: boolean }) => eachApp.isEnabled)
              .filter((eachItem: { app: AppType }) => {
                return trackEventPixelsByApp(
                  eachItem.app,
                  successEvent.eventName as unknown as string
                );
              });
        }
        setPaymentDetails({
          ...paymentDetails,
          paidTicketDtls: { ...paymentVerifyData },
        });
      } else {
        resetRoute(false);
        captureFailedRegEvent();
        setPaymentDetails({
          ...paymentDetails,
          paidTicketDtls: { ...paymentVerifyData },
        });
      }
    } else if (paymentVerifyError) {
      resetRoute(false);
      captureFailedRegEvent();
      setPaymentDetails({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentVerifyData, paymentVerifyError]);

  const handleStripeCheckout = useCallback(
    async (token: string) => {
      if (!token) return;
      let stripe: Stripe | null;
      if (stripeAccountId) {
        stripe = await loadStripe(envConfig?.STRIPE_KEY || "", {
          stripeAccount: stripeAccountId,
        });
      } else {
        stripe = await loadStripe(envConfig?.STRIPE_KEY || "");
      }
      const result = await stripe?.redirectToCheckout({
        sessionId: token,
      });
      if (result?.error) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
      }
      return result;
    },
    [stripeAccountId]
  );

  React.useEffect(() => {
    if (rePaymentToken?.stripeSessionId) {
      handleStripeCheckout(rePaymentToken?.stripeSessionId);
    }
    if (rePayError && typeof rePayError === "string" && !rePaymentToken) {
      setToast({
        message: String(rePayError),
        type: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rePaymentToken, handleStripeCheckout, rePayError]);

  const handleRePayment = () => {
    if ((refOrderId && refOrderId.current) || getPaymentId) {
      getNewStripeSessionId(
        {
          authorization: configData?.accessToken || "",
          language: props?.configData?.language || "",
        } as IHeadersRequest,
        {
          payload: {
            data: {
              orderToken: refOrderId.current
                ? refOrderId.current
                : getPaymentId,
              ticketingLink: store.widgetData?.isWidgetExpand
                ? communityUrl
                : `${communityUrl}/register`,
            },
          },
        }
      );
    }
  };

  const handlePaymentAction = (isConfirm?: boolean) => {
    if (isConfirm) {
      Track.goToEvent({
        lpType: startFromSummary
          ? "default landing page"
          : "custom landing page",
      });
      // Step 4: 'Event Login Page'
      const trackingPixelApps = eventInfo?.trackingPixelApps;
      const loginEvent = eventInfo?.stepsToTrack?.find(
        (eachStep) => eachStep.id === 4
      );
      if (loginEvent?.isEnabled) {
        !isEmpty(trackingPixelApps) &&
          trackingPixelApps
            ?.filter((eachApp) => eachApp.isEnabled)
            .map((eachItem: { app: any }) => {
              trackEventPixelsByApp(
                eachItem.app,
                loginEvent.eventName as unknown as string
              );
            });
      }
      if (originalCommunityUrl) {
        window.open(originalCommunityUrl);
      } else {
        const location = new URL(
          window.location.href.replace("register", "login")
        );
        location.search = "";
        // const location = window.location.href.replace("register", "login");
        window.open(location.toString(), "_blank");
      }
      return;
    }
    Track.clickTryAgainTxnFailure();
    handleRePayment();
  };

  const netAmount = (array: any) => {
    const getNet = array?.find((data: any) => data.type === "NET");
    if (getNet) {
      const netAmount = getNet.amount;
      return netAmount;
    }
  };

  const getUnloadPayment = () => {
    let returnData = false;
    if (paymentDetails?.currPaymentStatus) {
      const isOtherStatus = !PAYMENT_OPTIONS.includes(
        paymentDetails?.currPaymentStatus
      );
      if (isOtherStatus) returnData = true;
    } else returnData = true;

    return returnData;
  };

  const openFaq = () => {
    setFaqModal(true);
  };

  const onClosed = () => {
    setFaqModal(false);
  };

  const isUnloadPayment = getUnloadPayment();
  if (isUnloadPayment) return <></>;

  const showGoToEventCTA =
    ((paidTicketDtls?.showGoToEventCTA ||
      paymentDetails?.showGoToEventCTA ||
      false) &&
      eventInfo?.communityPageShow) ||
    store?.paymentStatusDetails?.showGoToEventCTA;

  const goToEventCtaLabel =
    paidTicketDtls?.goToEventCtaLabel ||
    paymentDetails?.goToEventCtaLabel ||
    store?.paymentStatusDetails?.goToEventCtaLabel ||
    "";

  const onRequestModalClose = () => {
    if (isMobile) {
      window.location.reload(); // If we close on Mobile Mode if will be reload so it will reset the state
      return;
    }
    resetRoute(false);
    toggleModal(!open);
    if (paymentStatus) {
      setPaymentDetails({});
      setSelectedTickets([]);
      setRegistrationToggle(false);
      // The value is set to "\n" because in ticketList we have condition for truthy check
      setBuyerEmail("\n");
    }
    setKeepMounted?.();
    onClose?.();
    onOpen?.();
    microFeEventHandler?.();
  };

  const successTicketPurchaseMessage =
    envConfig?.PAID_REGISTRATION_EVENT_ID?.includes(
      props?.eventInfo?.previewData?.eventId
    )
      ? "You have successfully registered for the event. This will allow you to attend the event. Thank you."
      : t("you-have-successfully-purchased-the-ticket");
  const successTitle =  envConfig?.PAID_REGISTRATION_EVENT_ID?.includes(
    props?.eventInfo?.previewData?.eventId
  )
    ? "Registration Successful!"
    : t("ticket-purchased");
  return (
    <>
      <CustomModal
        isScrollModal={Boolean(paymentStatus && currentBanner)}
        open={open}
        onRequestClose={() => {
          resetRoute(false);
          toggleModal(!open);
          if (paymentStatus) {
            setPaymentDetails({});
            setSelectedTickets([]);
            setRegistrationToggle(false);
            // The value is set to "\n" because in ticketList we have condition for truthy check
            setBuyerEmail("\n");
          }
          setKeepMounted?.();
          onClose?.();
          onOpen?.();
          microFeEventHandler?.();
          if (isMobile) {
            window.location.reload(); // If we close on Mobile Mode if will be reload so it will reset the state
          }
        }}
        handleClose={() => {
          resetRoute(false);
          setPaymentDetails({});
          setSelectedTickets([]);
          setRegistrationToggle(false);
          // The value is set to "\n" because in ticketList we have condition for truthy check
          setBuyerEmail("\n");
          setKeepMounted?.();
          onClose?.();
          onOpen?.();
          microFeEventHandler?.();
        }}
        isOnlyChildren
      >
        {isLoading ? (
          <ModalLoader />
        ) : (
          <>
            <Box>
              <Box display="flex" justifyContent="center" mb={1.2} pt={5}>
                {paymentStatus ? (
                  <TicketPurchaseSuccessIcon />
                ) : (
                  <TicketPurchaseFailedIcon />
                )}
              </Box>
              <Typography
                sx={{ textAlign: "center",fontSize:"22px !important" }}
                color="textPrimary"
                component="h1"
              >
                {paymentStatus
                  ? isSimpleRegistrationEnabled
                    ? t("registrationSuccess")
                    : successTitle
                  : t("ticket-purchase-failed")}
              </Typography>
              <Typography
                sx={(theme) => ({
                  textAlign: "center",
                  marginTop: 1.5,
                  paddingLeft: 2,
                  paddingRight: 2,
                  fontSize:"14px !important",
                  color: theme.palette.text.secondary,
                })}
                component="p"
              >
                {paymentStatus
                  ? isSimpleRegistrationEnabled
                    ? t("registrationSuccessDetail")
                    : successTicketPurchaseMessage
                  : `${
                      store?.paymentStatusDetails &&
                      store?.paymentStatusDetails.verificationDeniedReason
                        ? ERROR_MESSAGES[
                            store?.paymentStatusDetails.verificationDeniedReason
                          ]
                        : t("there-was-an-error-while-booking-your-tickets")
                    }`}
                {/* The payment details shared could not be verified by the event organizer. */}
              </Typography>

              {paymentStatus && isShowSnowBall && (
                <div
                  id="snoball-share-container"
                  data-custom1="User Message"
                  data-custom2="https://example.com/image.jpg"
                  data-email={store?.buyerBasicDetail?.buyerEmail}
                  data-first-name={store?.buyerBasicDetail?.buyerFirstName}
                  data-last-name={store?.buyerBasicDetail?.buyerLastName}
                ></div>
              )}

              {currentBanner ? (
                <Box
                  mt={2}
                  width="100%"
                  sx={{
                    paddingTop: 1,
                    paddingLeft: 2,
                    paddingRight: 2,
                    "&>img": {
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    },
                  }}
                >
                  <img
                    src={getBannerImage(currentBanner.image, orgId)}
                    alt="no-banner"
                  />
                </Box>
              ) : (
                false
              )}
              <Typography
                color="textPrimary"
                component="h1"
                sx={{
                  fontSize:"20px !important",
                  paddingTop: "16px !important",
                  paddingLeft: "16px !important",
                  paddingRight: "16px !important",
                  margin: "16px 0px !important",
                  // borderTop: "1px solid #c6c6c6",
                }}
              >
                {landingPageData?.eventName}
              </Typography>
              {/* <Typography
                variant="body2"
                sx={(theme: any) => ({ color: theme.palette.text.secondary })}
                component="p"
              >
                Event Hosted By
                <Typography
                  component="span"
                  sx={(theme: any) => ({ color: theme.palette.primary.main })}
                >
                  {" "}
                  Hubilo
                </Typography>
              </Typography> */}
              {!isEmpty(paidTicketDtls) && (
                <TicketPaymentSummary
                  isSummary={paidTicketDtls.type}
                  currencySymbol={paidTicketDtls.currency}
                  payments={paidTicketDtls.details?.map((eachPayment: any) => ({
                    ...eachPayment,
                    amount: roundOffTo2(eachPayment.amount),
                  }))}
                />
              )}
              {!isEmpty(store?.paymentStatusDetails) &&
                store?.paymentStatusDetails?.type === "PAID" &&
                store?.paymentStatusDetails?.details && (
                  <TicketPaymentSummary
                    isSummary={!!store?.paymentStatusDetails?.type}
                    currencySymbol={store?.paymentStatusDetails?.currency}
                    payments={store?.paymentStatusDetails?.details?.map(
                      (eachPayment: any) => ({
                        ...eachPayment,
                        amount: roundOffTo2(eachPayment.amount),
                      })
                    )}
                  />
                )}

              {store?.paymentStatusDetails &&
              store?.paymentStatusDetails?.type === "APRVL_DND" &&
              store?.paymentStatusDetails?.tickets ? (
                <Box sx={{ px: 2, mt: 2.5 }}>
                  <Box
                    sx={(theme: any) => ({
                      borderRadius: "12px !important",
                      border: `1px solid ${theme.palette.divider.default}`,
                    })}
                  >
                    <Typography
                      sx={(theme: any) => ({
                        p: 2,
                        fontWeight: 700,
                        fontSize:"18px !important",
                        borderBottom: `1px solid ${theme.palette.divider.default}`,
                      })}
                      component="h4"
                    >
                      Ticket Details
                    </Typography>
                    {store?.paymentStatusDetails?.tickets.map(
                      (_item: any, i: any) => {
                        const { quantity = 0 } = _item;
                        if (quantity > 0) {
                          return (
                            _item.quantity && (
                              <Box sx={{ px: 2, pt: 2, pb: 1 }} key={i}>
                                <Box
                                  sx={{
                                    mb: 1.5,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                  key={i}
                                >
                                  <Typography
                                    component="label"
                                    sx={{
                                      fontWeight: 700,
                                      fontSize:"14px !important",
                                      width: "calc(100% - 132px)",
                                    }}
                                  >
                                    {_item.title}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                      width: "120px",
                                      ml: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography
                                        sx={{ fontWeight: 400 , fontSize:"14px !important" }}
                                        component="p"
                                      >
                                        {quantity}
                                      </Typography>
                                      <Typography
                                        sx={{fontSize:"14px !important", fontWeight: 400 }}
                                        component="p"
                                      >
                                        {_item.currency} {_item.total}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            )
                          );
                        }
                      }
                    )}
                    <Box
                      sx={(theme: any) => ({
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        p: 2,
                        borderTop: `1px solid ${theme.palette.divider.default}`,
                      })}
                    >
                      <Typography
                        component="p"
                        sx={{ fontWeight: 700,fontSize:"14px !important" }}
                      >
                        Total
                      </Typography>
                      <Typography
                        component="p"
                        sx={(theme: any) => ({
                          fontWeight: 700,
                          fontSize:"14px",
                          color: theme.palette.primary.main,
                        })}
                      >
                        {store?.paymentStatusDetails?.tickets
                          ? store?.paymentStatusDetails?.currency
                          : ""}{" "}
                        {netAmount(store?.paymentStatusDetails?.details)}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography sx={{ mt: 2 ,fontSize:"12px !important"}} component="p">
                    {t("FAQ_TITLE")}
                    <Typography
                      component="span"
                      onClick={openFaq}
                      sx={(theme: any) => ({
                        display: "inline",
                        cursor: "pointer",
                        fontSize:"12px !important",
                        ml: 0.5,
                        color: theme.palette.primary.main,
                      })}
                    >
                      {t("HERE")}
                    </Typography>
                  </Typography>
                </Box>
              ) : (
                false
              )}

              {!paymentStatus ? (
                <Box
                  px={2}
                  mt={2}
                  py={2}
                  sx={(theme: any) => ({
                    borderTop: `1px solid ${theme.palette.divider.default}`,
                  })}
                >
                  <Button
                    onClick={() => {
                      if (
                        !isEmpty(store?.paymentStatusDetails) &&
                        store?.paymentStatusDetails?.paymentMethodType === "OFL"
                      ) {
                        onRequestModalClose();
                      } else {
                        handlePaymentAction(paymentStatus);
                      }
                    }}
                    disabled={isRePaymentLoading}
                    sx={{ width: "100%",height:"42px !important" }}
                    variant="contained"
                  >
                    {isRePaymentLoading ? (
                      <CircularProgress
                        sx={(theme: any) => ({
                          width: "20px !important",
                          height: "20px !important",
                          color: theme.palette.text.secondaryFontColor,
                        })}
                      />
                    ) : (
                      t("try-again")
                    )}
                  </Button>
                </Box>
              ) : showGoToEventCTA ? (
                !props?.eventInfo?.communityPageShow &&
                store?.paymentStatusDetails?.paymentMethodType ===
                  "OFL" ? null : (
                  <Box mt={2} p={2}>
                    <Button
                      onClick={() => handlePaymentAction(paymentStatus)}
                 
                      sx={{ width: "100%",height:"42px !important" }}
                      variant="contained"
                    >
                      {goToEventCtaLabel}
                    </Button>
                  </Box>
                )
              ) : null}
            </Box>
          </>
        )}
        <CustomModal
          onRequestClose={onClosed}
          isOnlyChildren
          open={faqModal}
          isScrollModal
        >
          <FAQModal
            onClose={onClosed}
            faqList={faqList}
            orderNo={store?.orderNo}
          />
        </CustomModal>
      </CustomModal>
    </>
  );
};

export default TicketPaymentStatus;
