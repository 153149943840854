import Box from "@mui/material/Box";
import React from "react";
import SkeletonLoader from "../SkeletonLoader";


export const TicketSummaryBillLoader = (): JSX.Element => {
  return (
    <>
      <Box p={2}>
        {[1, 2, 3].map((i) => (
          <Box
            key={i}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <SkeletonLoader height="22px" width="120px" variant="text" />
            <SkeletonLoader height="22px" width="120px" variant="text" />
          </Box>
        ))}
      </Box>
      <SkeletonLoader height="30px" width="100%" mt="10px" variant="rect" />
      <SkeletonLoader height="40px" width="100%" mt="10px" variant="rect" />
    </>
  );
};

export default function TicketSummaryLoader() {
  // const classes = useStyles();
  return (
    <>
      {[1, 2, 3, 4].map((i) => (
        <Box
          key={i}
          mt={2}
          sx={(theme: any) => ({
            borderBottom: `1px solid ${theme.palette.divider.default}`,
            paddingBottom: "9px",
          })}
        >
          <Box display="flex" justifyContent="space-between">
            <SkeletonLoader height="20px" width="120px" />
            <SkeletonLoader height="20px" width="70px" />
          </Box>
          <SkeletonLoader height="40px" width="100%" variant="rect" mt="6px" />
        </Box>
      ))}
      <TicketSummaryBillLoader />
    </>
  );
}
