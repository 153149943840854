/* eslint-disable react-hooks/exhaustive-deps */
import InputField from "../InputField";
import { Typography, Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import EditIcon from "../../Assets/Svg/EditIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LoaderSvg from "../../Assets/Svg/LoaderSvg";
import { PaymentStatusLoader } from "../Loaders/PaymentMethodLoader";
import { useTranslation } from "react-i18next";
import { useStore } from "Context";
import FileUploadWithError from "../../Components/FileUploadWithError";
import ImageUploadIcon from "../../Assets/Svg/ImageUploadIcon";
// import usePaymentShare from "Hooks/usePaymentShare";
import usePaymentStatus from "Hooks/usePaymentStatus";
import { IHeadersRequest } from "Types/Ticket";
import { IVerifyPaymentStatus, IVerifyPaymentStatusRequest } from "Types/VerifyStatus";
import { PAYMENT_METHOD_DTLS } from "../PaymentMethod/index";



// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PaymentDetailModal = ({ configData, isLoading, handlePaymentShareSubmit }: any) => {
  const { store, setOtherPaymentDetails, setOtherPaymentModal, setTotalAmount, setTotalAmountSymbol } =
    useStore();
  const [paymentDetails, setPaymentDetails] = React.useState({
    transactionId: "",
    screenShot: "",
  });
  const [t] = useTranslation();
  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();

  // const { setSubmitLoading, paymentShare } = usePaymentShare();
  const {
    data: paymentStatusVerifyData,
    paymentStatus,
  } = usePaymentStatus();

  function BreaklineText(props:any) {
    const text = props?.text || "";
    // eslint-disable-next-line react/jsx-key
    const newText = text?.split("\n")?.map((str: string) => <p>{str}</p>);

    return newText;
  }

  useEffect(() => {
    const payload: IVerifyPaymentStatus = {
      orderToken: store?.orderToken || "",
    };
    if (store?.userDataLandingPage?.case === "PRF_PND") {
      paymentStatus(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: { ...payload },
          },
        } as IVerifyPaymentStatusRequest
      );
    }

  }, []);

  useEffect(() => {
    if (store?.userDataLandingPage?.case === "PRF_PND") {
      const defineSelectedPayment = paymentStatusVerifyData?.paymentMethods &&
        paymentStatusVerifyData?.paymentMethods.filter(item => item.name === paymentStatusVerifyData.paymentMethod);
      const paymentSelection = defineSelectedPayment && defineSelectedPayment[0];
      const paymentMethodTypesAvailable: string[] = [];
      if (paymentStatusVerifyData?.paymentMethods) {
        for (let i = 0; i < paymentStatusVerifyData?.paymentMethods.length; i++) {
          if (!paymentMethodTypesAvailable.includes(paymentStatusVerifyData?.paymentMethods[i].type)) {
            if (paymentStatusVerifyData?.paymentMethods[i].isActive) {
              paymentMethodTypesAvailable.push(paymentStatusVerifyData?.paymentMethods[i].type);
            }
          }
        }
      }
      setOtherPaymentDetails(
        {
          ...store.otherPaymentDetails,
          //@ts-ignore;
          selectedPaymentMethod: { ...paymentSelection, ...NEW_PAYMENT_METHOD_DTLS[paymentSelection?.name] },
          paymentMethods: paymentStatusVerifyData.paymentMethods,
          emailForOfflinePaymentMethods: paymentStatusVerifyData.emailForOfflinePaymentMethods,
          paymentMethodTypesAvailable
        });
      setTotalAmount(paymentStatusVerifyData?.details && paymentStatusVerifyData?.details[1]?.amount || 0);
      setTotalAmountSymbol(String(paymentStatusVerifyData?.currency));
    }
  }, [paymentStatusVerifyData]);


  const openChoosePaymentModal = () => {
    setOtherPaymentModal({
      choosePaymentMethod: true,
      faqModal: false,
      orderStatus: false,
      paymentDetailModal: false,
      returningUserModal: false,
      sharePaymentDetails: false,
      ticketConfirmStatus: false,
    });
  };

  return (
    <>
      {isLoading ? (
        <PaymentStatusLoader />
      ) : (
        <Box
          p={3}
          sx={{
            position: "relative",
            "@media(max-width:640px)": {
              height: "100%",
              pb: 2,
              pr: 0.5,
              pl: 2,
              pt: 8,
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              "@media(max-width:640px)": {
                maxHeight: "calc(100% - 200px)",
                pr: 1.5,
                overflowY: "auto",
              },
            }}
          >
            <Typography
              sx={{ mt: 0, mb: 0 }}
              component="label"
              color="textSecondary"
              variant="body2"
            >
              Order ID : {store.orderNo}
            </Typography>
            <Box
              sx={(theme: any) => ({
                mt: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                "& .loaderSvg": { color: theme.palette.primary.main },
              })}
            >
              <LoaderSvg height="72px" width="72px" className="loaderSvg" />
              <Typography
                color="textPrimary"
                sx={{ fontWeight: 700, mt: 3 }}
                component="h3"
                variant="h3"
              >
                {t("COMPLETE_PAYMENT_OF")}
              </Typography>
              {store && store.totalAmount && (
                <Typography
                component="h3"
                variant="h2"
                sx={(theme: any) => ({
                  fontWeight: 700,
                  color: `${theme.palette.primary.main} !important`,
                  mt: 0.5,
                  fontSize: "30px !important",
                })}
              >
                {store?.totalAmountSymbol || ""} {store?.totalAmount || ""}
              </Typography>
              )}
            </Box>
            <Typography
              color="textPrimary"
              sx={{ mt: 3, display: "block", fontWeight: 700 }}
              component="label"
              variant="h5"
            >
              Step 1{" "}
            </Typography>
            <Typography
              component="p"
              color="textPrimary"
              sx={{
                fontWeight: 400,
                mt: "0px !important",
                mb: "0px !important",
              }}
              variant="body2"
            >
              {t("COMPLETE_PAYMENT_TICKET")}
            </Typography>
            <Box
              mt={2}
              sx={(theme: any) => ({
                border: `1px solid ${theme.palette.divider.default}`,
                borderRadius: "12px",
              })}
            >
              <Box
                sx={(theme: any) => ({
                  p: 2,
                  borderBottom: `1px solid ${theme.palette.divider.default}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                })}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={(theme: any) => ({
                      "&>svg": { color: theme.palette.text.primary },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderRadius: "50%",
                      height: 36,
                      width: 36,
                      minWidth: 36,
                    })}
                  >
                    {/* <ChequeTransferIcon/> */}
                    {store?.otherPaymentDetails?.selectedPaymentMethod?.svg}
                  </Box>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ ml: 1.5 }}
                    component="label"
                  >
                    {store?.otherPaymentDetails?.selectedPaymentMethod?.label}
                  </Typography>
                </Box>
                {store?.otherPaymentDetails?.paymentMethods && store?.otherPaymentDetails?.paymentMethods?.length > 1 ?
                  <IconButton
                    size="small"
                    onClick={openChoosePaymentModal}
                    sx={(theme: any) => ({
                      "&>svg>path": { fill: theme.palette.primary.main },
                    })}
                  >
                    <EditIcon height="18px" width="18px" />
                  </IconButton>
                  :
                  false}

              </Box>
              <Box sx={{ p: 2 }}>
                <BreaklineText text={store?.otherPaymentDetails?.selectedPaymentMethod?.details || ""} />
              </Box>
            </Box>
            <Typography
              color="textPrimary"
              sx={{ mt: 2, display: "block", fontWeight: 700 }}
              component="label"
              variant="h5"
            >
              Step 2
            </Typography>
            <Typography
              color="textPrimary"
              sx={{
                fontWeight: 400,
                mt: "0px !important",
                mb: "0px !important",
              }}
              component="p"
              variant="body2"
            >
              {t("PAYMENT_CONFIRMATION_TITLE")}
            </Typography>
            <Box mt={2}>
              <InputField
                value={paymentDetails.transactionId}
                placeHolder={t("ENTER_TRANSACTION_ID")}
                onChange={(e) =>
                  setPaymentDetails({
                    ...paymentDetails,
                    transactionId: e.target.value,
                  })
                }
                label="Transaction ID"
                required
              />
            </Box>
            <Box mt={1}>
              {/* <UploadNewUI /> */}
              <FileUploadWithError
                key="screenShot"
                id="screenShot"
                accessToken={configData?.accessToken}
                withoutInput
                customUploadText={t("UPLOAD_SCREENSHOT")}
                customUploadIcon={<ImageUploadIcon className="svgIconTheme" />}
                value={paymentDetails.screenShot}
                label=""
                onChange={(val: string) => {
                  setPaymentDetails({ ...paymentDetails, screenShot: val });
                }}
                maxFileSize={2}
                fileTypes={[
                  {
                    id: 1,
                    name: "PDF",
                    value: true,
                  },
                  {
                    id: 3,
                    name: "IMAGE",
                    value: true,
                  },
                ]}
                isVisible={true}
                isRequired={false}
                hasError={false}
                errMsg={""}
                disabled={false}
              />
            </Box>
          </Box>
          <Box
            mt={2}
            sx={(theme: any) => ({
              "@media(max-width:640px)": {
                position: "absolute",
                bottom: 0,
                left: 0,
                background: theme.palette.background.cardColor,
                p: 2,
                borderTop: `1px solid ${theme.palette.divider.default}`,
              },
            })}
          >
            <Box
              sx={(theme: any) => ({
                background: theme.palette.error.light,
                "&>svg": {
                  height: "20px",
                  width: "20px",
                  color: theme.palette.error.main,
                },
                border: `1px solid ${theme.palette.error.main}`,
                borderRadius: "10px",
                p: 2,
                display: "flex",
                alignItems: "center",
              })}
            >
              <ErrorOutlineIcon />
              <Typography
                component="p"
                sx={{ color: "#202020", ml: 2, fontWeight: 400 }}
                variant="body2"
              >
                {t("SHARE_DETAILS_TITLE")}
              </Typography>
            </Box>
            <Button
              sx={{ mt: 2, width: "100%" }}
              disabled={paymentDetails.transactionId.trim().length === 0}
              color="primary"
              variant="contained"
              onClick={() => handlePaymentShareSubmit(paymentDetails)}
            >
              {t("SHARE_PAYMENT_DETAILS")}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PaymentDetailModal;
