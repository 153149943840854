import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import {
  IPaymentStatusRequest,
  IPaymentStatusResponse,
} from "../Types/VerifyPayment";
import { IHeadersRequest } from "../Types/Ticket";

const usePaymentVerify = () => {
  const [data, setData] = useState<IPaymentStatusResponse>(
    {} as IPaymentStatusResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();

  const paymentVerify = useCallback(
    (headers?: IHeadersRequest, input?: IPaymentStatusRequest) => {
      setLoading(true);
      let didCancel = false;
      async function fetchApi() {
        try {
          const response = await postApi<
            IPaymentStatusResponse,
            IPaymentStatusRequest
          >(AppConstants.paymentVerify, headers, input);
          if (!response.status) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.success?.data as IPaymentStatusResponse);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, loading, error, paymentVerify };
};

export default usePaymentVerify;
