import React,{useEffect} from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import OfferIcon from "../Assets/Svg/OfferIcon";

interface IToolTipProps {
  children?: React.ReactNode;
  variant?: "contained" | "outlined";
  size?: "large" | "small";
  btnLabel?: string;
}
const BasicPopover = ({
  children,
  size,
  variant,
  btnLabel,
}: IToolTipProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    const root = document.getElementsByTagName("html")[0];
    if(open){
      root.style.overflow = "hidden";
    } else {
      root.style.overflow = "auto";
    }
  },[open]);

  return (
    <Box>
      <Button
        startIcon={<OfferIcon />}
        sx={{ paddingLeft: 1.5, paddingRight: 1.5 }}
        aria-describedby={id}
        size={size || "medium"}
        variant={variant}
        onClick={handleClick}
      >
        <Typography variant="caption" component="span">
          {btnLabel}
        </Typography>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={(theme: any) => ({
          "& .MuiPaper-root": {
            marginTop: theme.spacing(1),
            boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.25)",
            background: theme.palette.background.cardColor,
          },
        })}
      >
        <Box
          position="relative"
          sx={{ p: 1.25, maxHeight: 110, overflowY: "auto" }}
        >
          <IconButton
            onClick={handleClose}
            sx={(theme: any) => ({
              position: "absolute",
              right: "1.5px",
              top: "1.5px",
              padding: "2px",
              "&>svg": { fontSize: 12, color: theme.palette.text.primary },
            })}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <>{children}</>
        </Box>
      </Popover>
    </Box>
  );
};

export default BasicPopover;
