import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  termsStringBlock:{
"@media(max-width:1023px)":{
  paddingBottom:"7rem !important",
  },
  },
  sliderBox: {
    border: `1px solid ${theme.palette.divider.default}`,
    color: theme.palette.text.primary,
    borderRadius: "5px",
    padding: "10px 15px",
    marginRight: theme.spacing(1),
    width: "auto !important",
    cursor: "pointer",
    lineHeight:"1.4 !important",
    marginLeft: "1px",
    fontFamily: "Helvetica Neue New",
    fontSize: "14px",
    "&.sliderBoxResize":{
      padding: "9px 15px",
    },
    "&>label": {
      cursor: "pointer",
    },
    "&.active": {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      backgroundColor:theme.palette.primary.lighter,
    },
    "&.error": {
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.primary.main,
    },
  },
  sameAsBuyerForLandingPage:{
    "& .MuiFormControlLabel-root":{
       "& .MuiCheckbox-root":{
         paddingLeft:0,
          "& svg":{
            height:"1.5em",width:"1.5em"
          }
        },
      }
  },
  attendeesDetailsMain: {
    padding: `${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`,
    "@media(max-width:640px)":{
      height:"100%",
    },
    "& .MuiPaper-root": {
      overflow: "visible !important",
    },
    "@media(min-width:640px)":{
      "&.dudaWrapperAttendee":{
        height:"auto !important"
      }
    }
  },
  simpleRegistrationMain: {
    margin: theme.spacing(0),
  },

  widgetWithFlowBtn:{
    // bottom:"2rem !important",
  },
  widgetAttendeeDetails:{
    margin:0,
    overflow:"hidden",
    padding:`${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} `,
    position: "relative",
    height:"100%",
    borderRadius:theme.spacing(1),
    background: theme.palette.background.cardColor,
    "@media(max-width:640px)":{
      paddingRight:theme.spacing(1),

    },
    "& .widgetAttendeeScrolling":{
      minHeight:"calc(100% - 100px)",
      maxHeight:"calc(100% - 100px)",
      paddingRight:"1.1rem",

      "@media(max-width:480px)":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 160px)",
        minHeight:0,
        paddingRight:"0.8rem",
        paddingBottom:"1.6rem",
        overflowX:"hidden",
      },
      "&>h2":{
        borderBottom:0,
        paddingBottom:0,
      }
    },
    "& .continueBtn":{
      background: `linear-gradient(to bottom, ${theme.palette.background.cardColor}, ${theme.palette.background.lightGray})`,
      display:"flex",
      position:"absolute",
      bottom:0,
      left:0,
      padding:0,

      width:"100%",
      alignItems:"center",
      justifyContent:"center",
      "&.scrollContinue":{
        opacity:0,
      },
      "&.scrollstop":{
        opacity: 1,
        transition: "opacity .4s ease-out",
      },
      "&>button":{
        width:320,
        "@media(max-width:480px)":{
          width:"100%",
        },
      },
      "@media(max-width:480px)":{
        position:"absolute",
        padding:theme.spacing(2),
        borderTop:`1px solid ${theme.palette.divider.default}`,
        width:"100%",
        bottom:0,
        left:0,
      }
    }
  },

  widgetWithSimRegFlow:{
    // background:"none",
    // padding:0,
    "& .widgetWithSimRegFlowWrapper":{
       marginTop:"3.2rem",
       maxHeight:"calc(100% - 8rem)",
       minHeight:"calc(100% - 8rem)",
       "@media(max-width:420px)":{
         paddingRight:0,
       }
    }
  },
  attendeeScrollingWrapper:{
    overflowY:"auto",
    marginBottom:theme.spacing(1),
    marginTop:theme.spacing(1),
    overflowX:"hidden",
    "@media(max-width:1920px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 43.2rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 43.2rem)",
      "&.dudaWrapperScroll":{
        maxHeight:"inherit",
        minHeight:"inherit",
      }
    },
    "@media(max-width:1680px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 33.6rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 33.6rem)",
    },
    "@media(max-width:1440px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 25.6rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 25.6rem)",
    },
    "@media(max-width:1366px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 21rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 21rem)",
    },
   
    "@media(max-width:640px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 22.4rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 22.4rem)",
      "&.dudaWrapperScroll":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 18rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 18rem)",
      }
    },
    "&.simpleWBWithRG":{
      paddingRight:"10px",
      paddingBottom:"40px",
      marginTop:theme.spacing(2),
      "@media(max-width:1920px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 44.8rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 44.8rem)",
      },
      "@media(max-width:1800px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 39.2rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 39.2rem)",
      },
      "@media(max-width:1680px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 29.6rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 29.6rem)",
      },
      "@media(max-width:1440px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 21.6rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 21.6rem)",
      },
      "@media(max-width:1366px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 11.2rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 11.2rem)",
      },
      "@media(max-width:640px)":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
      },
    },
  },
  landingWithoutBuyer:{
    paddingTop:0,
    paddingBottom:0,
    "@media(max-width:640px)":{
      paddingTop:"10px",
    },
    "& .landingWithoutBuyerScroll":{
    overflowY:"auto",
    marginBottom:theme.spacing(0),
    marginTop:theme.spacing(0),
    overflowX:"hidden",
    paddingRight: theme.spacing(1),
    "@media(max-width:1920px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 27.2rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 27.2rem)",
    },
    "@media(max-width:1680px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 22.4rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 22.4rem)",
    },
    "@media(max-width:1440px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 14.4rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 14.4rem)",
    },
    "@media(max-width:640px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 14.4rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 14.4rem)",
    },
  }
  },
  continueWithOutBuyer:{
    position:"absolute",
    padding:"20px 0px",
    // borderTop:`1px solid ${theme.palette.divider.default}`,
    width:"calc(100% - 32px)",
  },
  simpleRegistrationScrollingWrapper: {
    overflowY:"auto",
    paddingRight:"10px",
    marginTop: theme.spacing(4),
    marginBottom:theme.spacing(2),
    paddingBottom:"45px",
    "@media(max-width:1920px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 13.2rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 13.2rem)",
    },
    "@media(max-width:1680px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 15.6rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 15.6rem)",
    },
    "@media(max-width:1440px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 15rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 15rem)",
    },
    "@media(max-width:1366px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
    },
    "@media(max-width:640px)":{
      minHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
      maxHeight:"calc((var(--vh, 1vh) * 100) - 16rem)",
    },
    "& h2": {
      borderBottom: `1px solid ${theme.palette.divider.default}`,
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(1.2),
    },
  },
  simpleRegForLanding:{
    minHeight:"calc(100% - 8rem)",
    maxHeight:"calc(100% - 8rem)",
    overflowY:"auto",
    paddingRight:"10px",
    marginTop: theme.spacing(0),
    marginBottom:theme.spacing(0),
  },
  attendeesDetailsWrapper: {
    marginTop: 20,
    background: "transparent",
    "& .MuiCardContent-root": {
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider.default}`,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 12,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      fontSize: 12,
    },
  },
  checkBoxGroupWrapper: {
    "& .MuiFormControlLabel-root": {
      border: `1px solid ${theme.palette.divider.default}`,
      paddingRight: 16,
      marginLeft: 0,
      borderRadius: 6,
      width: "30%",
      marginBottom: 12,
      "& svg":{
        height:"20px",
        width:"20px",
        fontSize:"14px",
      }
    },
  },
  
  tabsSlider: {
    "& .oneSideArrow": {
      "&>.slick-prev, .slick-next": {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider.default}`,
      },
      "&>.slick-disabled": {
        backgroundColor: theme.palette.background.gray,
      },
    },
  },
  mobFooterStyle: {
    marginTop: "0px",
    overflowY: "auto",
    // maxHeight: "calc((var(--vh, 1vh) * 100) - 4rem)",
    "& .continueBtn": {
      position: "absolute",
      bottom: 0,
      width: "100%",
      left: 0,
      background:theme.palette.background.cardColor,
      padding: theme.spacing(2),
      borderTop:`1px solid ${theme.palette.divider.default}`,
    },
    "& .title": {
      marginTop: theme.spacing(5),
    },
  },
  simpleRegistrationContinueBtn: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    left: 0,
    padding: theme.spacing(2.5),
    backgroundColor:theme.palette.background.cardColor,
  },
  checkboxWrapper: {
    textAlign:"left",
    alignItems: "flex-start",
    "& .policyForReg":{
      // marginTop:"1px",
      "@media(max-width:420px)":{
        marginTop:0,
      }
    },
    "& .MuiCheckbox-root": {
      padding:"0px 9px !important",
      "&>input":{
        minWidth:0,
        width:0,
      },
      "&>svg":{
        height:"20px",
        width:"20px",
      }
    },
    "@media (max-width: 453px)": {
      
      "& .MuiCheckbox-root": {
        textAlign:"left !important",
        padding: "0px 10px !important",
      },
    },
    "& .MuiTypography-root": {
      fontSize: 12,
      marginTop:"0px !important",
      marginBottom:"0px !important",
      fontWeight: 400,
      "&>span":{
        display:"block",
      },
      "& a":{
        fontSize:"12px !important",
      },
    },
  },
  termandpolicy: {
    color: theme.palette.text.primary,
    fontSize: "12px !important",
    "& a": {
      color: `${theme.palette.primary.main} !important `,
      textDecoration: "none !important",
      fontSize: "14px !important",
    },
    "& p": {
      margin: 0,
      lineHeight:"20px",
      fontSize: "12px !important",
      marginTop:"0px !important",
      marginBottom:"0px !important",
    },
  },
  registration: {
    border: `1px solid ${theme.palette.divider.default}`,
    borderRadius: "8px",
    "&:nth-of-type(n+2)": { marginTop: theme.spacing(4) },
    "&:last-child": { marginBottom: theme.spacing(2) },
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3), 
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  simpleRegistration: {
    border: "none",
    borderRadius: "0px",
    "&:nth-of-type(n+2)": { marginTop: theme.spacing(4) },
    "&:last-child": { marginBottom: theme.spacing(0.5) },
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1), 
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  widgetWithSimpleRegFlow:{
    // maxHeight:"calc(100% - 80rem)",
    // minHeight:"calc(100% - 80rem)",
    overflowY:"auto",
    position:"relative",
    overflowX:"hidden",
    border:"none",
    padding:"10px 0px 10px 0px",
    marginBottom:theme.spacing(1),
    "@media(max-width:420px)":{
      paddingRight:"10px"
    }
  },
  simpleRegistrationCloseBtn: {
    display: "block",
    "& svg": {
      color: theme.palette.text.primary,
    },
  },
  simpleRegistrationwrapper: {
    position:"relative",
    "@media (max-width: 1023px)": {
      padding: theme.spacing(0),
    },
  },
  landingAttendeeWrapper:{
    height:"calc((var(--vh, 1vh) * 100) - 0rem)",
    position:"relative",
    margin:0,
    padding:0,
    "@media(max-width:640px)":{
      padding:theme.spacing(2),
      height:"100%"
    },
    "& .landingAttendeeScrolling":{
        minHeight:"calc((var(--vh, 1vh) * 100) - 15rem)",
        maxHeight:"calc((var(--vh, 1vh) * 100) - 15rem)",
        paddingLeft:theme.spacing(2.5),
        paddingRight:theme.spacing(2.5),
        "@media(max-width:1140px)":{
          minHeight:"calc((var(--vh, 1vh) * 100) - 18rem)",
          maxHeight:"calc((var(--vh, 1vh) * 100) - 18rem)",
        },
        "@media(max-width:640px)":{
          paddingLeft:theme.spacing(0),
          paddingRight:theme.spacing(0),
        },
    },
    "& .continueBtn":{
      position:"absolute",
      bottom:0,
      left:0,
      padding:"20px",
      width:"100%",
      borderTop:`1px solid ${theme.palette.divider.default}`,
      backgroundColor:theme.palette.background.cardColor,
    }
  },
  landingWithSimple:{
    height:"calc((var(--vh, 1vh) * 100) - 0rem)",
    marginTop:0,
    marginBottom:0,    
    "@media(max-width:640px)":{
      height:"100%"
    }
  },
  simpleWithoutStartFromSummary:{
    height:"calc((var(--vh, 1vh) * 100) - 0rem)",
    "@media(max-width:640px)":{
      height:"100%",
    }
  },
  widgetWithoutStartFromSummary:{
    height:"100%",
  },
  scrollingBlockAttendee:{
    maxHeight:"100%"
  },
  spacingSidebar: {
    padding: 20
  },
}));
