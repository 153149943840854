import Box from "@mui/material/Box";
import React from "react";
import Skeleton from "@mui/material/Skeleton";

export  const OrderStatusLoader = ():JSX.Element => {
    return(
        <Box p={2.5} sx={{"@media(max-width:640px)":{p:2}}}>   
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "30px" ,width:"200px"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "24px" ,width:"150px"}} />
            <Skeleton animation="wave" variant="text" sx={{ mt:3,fontSize: "15px" ,width:"100%"}} />
            <Skeleton animation="wave" variant="text" sx={{fontSize: "15px" ,width:"50%"}} />
            <Box sx={(theme:any)=>({mt:4,p:2,border:`1px solid ${theme.palette.divider.default}`})}>
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "30px" ,width:"150px"}} />

            <Box sx={(theme:any)=>({display:"flex",mt:2,pt:2,borderTop:`1px solid ${theme.palette.divider.default}`,alignItems:"center",justifyContent:"space-between",width:"100%"})}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={{mt:2,display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={(theme:any)=>({borderTop:`1px solid ${theme.palette.divider.default}`,mt:2,pt:2,display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"})}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100px"}} />
            </Box>
            </Box>
            <Box sx={(theme:any)=>({mt:4,p:2,border:`1px solid ${theme.palette.divider.default}`})}>
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "30px" ,width:"150px"}} />

            <Box sx={(theme:any)=>({display:"flex",mt:2,pt:2,borderTop:`1px solid ${theme.palette.divider.default}`,alignItems:"center",width:"100%"})}>
                <Skeleton animation="wave"  variant="circular" width={35} height={35}  />
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={(theme:any)=>({borderTop:`1px solid ${theme.palette.divider.default}`,mt:2,pt:2,width:"100%"})}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100%"}} />
              
            </Box>
            </Box>
        </Box>
    );
};



export const PaymentStatusLoader = ():JSX.Element => {
    return(
        <Box p={3} sx={{"@media(max-width:640px)":{p:2}}}>
            
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "24px" ,width:"150px"}} />
            <Box sx={{flexDirection:"column",display:"flex",mt:2,pt:2,alignItems:"center",width:"100%"}}>
                <Skeleton animation="wave"  variant="circular" width={80} height={80}  />
                <Skeleton animation="wave" variant="text" sx={{mt:3, fontSize: "24px" ,width:"300px"}} />
                <Skeleton animation="wave" variant="text" sx={{fontSize: "44px" ,width:"100px"}} />

            </Box>
            <Skeleton animation="wave" variant="text" sx={{mt:4, fontSize: "24px" ,width:"200px"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"100%"}} />
            <Box sx={(theme:any)=>({mt:4,p:2,border:`1px solid ${theme.palette.divider.default}`})}>
            <Box sx={(theme:any)=>({display:"flex",mb:2,pb:2,borderBottom:`1px solid ${theme.palette.divider.default}`,alignItems:"center",width:"100%"})}>
                <Skeleton animation="wave"  variant="circular" width={35} height={35}  />
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={{mt:2,width:"100%"}}>
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"100%"}} />
                <Skeleton animation="wave" variant="text" sx={{ fontSize: "20px" ,width:"50%"}} />
              
            </Box>
            </Box>
            <Skeleton animation="wave" variant="text" sx={{mt:4, fontSize: "24px" ,width:"200px"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"100%"}} />
            <Skeleton animation="wave" variant="rectangular" sx={{mt:2}} height={36}/>
            <Skeleton animation="wave" variant="rectangular" sx={{mt:1}} height={36}/>


        </Box>
    );
};



export const ChoosePaymentMethodLoader = ():JSX.Element => {
    return(
        <Box p={3} sx={{"@media(max-width:640px)":{p:2}}}>
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "30px" ,width:"200px"}} />

            <Skeleton animation="wave" variant="text" sx={{ fontSize: "24px" ,width:"150px"}} />
           
            <Skeleton animation="wave" variant="text" sx={{mt:3, fontSize: "24px" ,width:"100%"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />
            <Box sx={(theme:any)=>({mt:4,p:2,border:`1px solid ${theme.palette.divider.default}`})}>
            <Box sx={(theme:any)=>({display:"flex",mb:2,pb:2,borderBottom:`1px solid ${theme.palette.divider.default}`,alignItems:"center",width:"100%"})}>
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "30px" ,width:"100px"}} />
            </Box>
            <Box sx={{mt:2,width:"100%",display:"flex",alignItems:"center"}}>
            <Skeleton animation="wave"  variant="circular" width={35} height={35}  />
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={{mt:2,width:"100%",display:"flex",alignItems:"center"}}>
            <Skeleton animation="wave"  variant="circular" width={35} height={35}  />
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "20px" ,width:"100px"}} />
            </Box>
            <Box sx={{mt:2,width:"100%",display:"flex",alignItems:"center"}}>
            <Skeleton animation="wave"  variant="circular" width={35} height={35}  />
                <Skeleton animation="wave" variant="text" sx={{ml:1, fontSize: "20px" ,width:"100px"}} />
            </Box>
            </Box>
            <Skeleton animation="wave" variant="rectangular" sx={{mt:2}} height={36}/>


        </Box>
    );
};


export const PendingTicketLoader = ():JSX.Element => {
    return(
        <Box mt={2} p={2} sx={(theme:any)=>({border:`1px solid ${theme.palette.divider.default}`,borderRadius:"6px"})}>
            <Skeleton animation="wave" variant="text" sx={{fontSize: "24px" ,width:"100%"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />

            <Skeleton animation="wave" variant="text" sx={{mt:3 ,fontSize: "18px" ,width:"50%"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />
            <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />
            <Skeleton animation="wave" variant="rectangular" sx={{mt:2}} height={50}/>
            <Skeleton animation="wave" variant="rectangular" sx={{mt:2}} height={36}/>
        </Box>
    );
};


export const ConfirmTicketLoader = ():JSX.Element => {
    return(
        <Box mt={2} px={2} pt={2} pb={1} sx={(theme:any)=>({border:`1px solid ${theme.palette.divider.default}`,borderRadius:"6px"})}>
        <Skeleton animation="wave" variant="text" sx={{fontSize: "24px" ,width:"100%"}} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />

        <Skeleton animation="wave" variant="text" sx={{mt:3 ,fontSize: "18px" ,width:"100%"}} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"100%"}} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"50%"}} />
        <Skeleton animation="wave" variant="text" sx={{mt:2, fontSize: "18px" ,width:"50%"}} />
        <Box sx={(theme:any)=>({pt:1,borderTop:`1px solid ${theme.palette.divider.default}`,display:"flex",alignItems:"center",mt:2,width:"100%",justifyContent:"space-between"})}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "18px" ,width:"100px"}} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: "34px" ,width:"30px"}} />
        </Box>

    </Box>
    );
};