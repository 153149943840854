const PAYMENT_FLOW_STEPS = [
  "summary",
  "buyer-info",
  "attendees-info",
  "payment",
];
const linkTermsCondition = "https://legal.hubilo.com/terms/3599";
const linkPrivacyPolicy = "https://legal.hubilo.com/privacy/3599";

const NAME_FIELDS: string[] = ["firstName", "lastName"];
const CONTACT_FIELDS: string[] = ["email"];
// [HUB-8579] Added multiple language support for Buyer Default Fields
const BUYERS_DEFAULT_FIELDS: string[] = [...NAME_FIELDS, ...CONTACT_FIELDS];

const TOAST_RESET_COUNT = 3000; // 3 secs

const SAMPLE_OFFER_SELECT = {
  id: 2,
  desc: "Current Offer 2",
  couponCode: "PROMO-0002",
};

const SAMPLE_ATTENDEE_FIELDS = [
  {
    id: 188,
    name: "asfasg",
    formFields: [
      {
        user_profile_field_type: 2,
        position: 4,
        ticket_id: 188,
        label: "About",
        minLength: 1,
        maxLength: 1000,
        id: "5fd2fafa4b75052c581ebece",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: false,
        showOtherOption: true,
        type: "Text Area",
        fieldType: "about",
        choices: [],
      },
      {
        user_profile_field_type: 7,
        position: 12,
        ticket_id: 188,
        label: "Website",
        id: "5fd2fafa4b75052c581ebed4",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: false,
        showOtherOption: true,
        type: "Link",
        fieldType: "website",
        choices: [],
      },
      {
        user_profile_field_type: 8,
        position: 17,
        ticket_id: 188,
        label: "Groups",
        minLength: 1,
        maxLength: 3,
        id: "5fd30b874b7505108a47c608",
        isDefault: false,
        isVisible: true,
        hasOptions: false,
        isMandatory: false,
        showOtherOption: true,
        type: "Group",
        fieldType: "",
        choices: [],
      },
    ],
  },
  {
    id: 251,
    name: "aaa",
    formFields: [
      {
        user_profile_field_type: 1,
        position: 1,
        ticket_id: 251,
        label: "First Name",
        id: "5fd2fafa4b75052c581ebec9",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: true,
        showOtherOption: true,
        type: "Text",
        fieldType: "firstName",
        choices: [],
      },
      {
        user_profile_field_type: 1,
        position: 2,
        ticket_id: 251,
        label: "Last Name",
        id: "5fd2fafa4b75052c581ebeca",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: true,
        showOtherOption: true,
        type: "Text",
        fieldType: "lastName",
        choices: [],
      },
      {
        user_profile_field_type: 1,
        position: 11,
        ticket_id: 251,
        label: "City/Town",
        id: "5fd2fafa4b75052c581ebed3",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: false,
        showOtherOption: false,
        type: "Text",
        fieldType: "city",
        choices: [],
      },
    ],
  },
  {
    id: 300,
    name: "Ticket # 3",
    formFields: [
      {
        user_profile_field_type: 4,
        position: 10,
        ticket_id: 300,
        label: "Statex",
        id: "5fd2fafa4b75052c581ebed2",
        isDefault: true,
        isVisible: true,
        hasOptions: true,
        isMandatory: false,
        showOtherOption: false,
        type: "Dropdown",
        fieldType: "state",
        choices: [],
      },
      {
        user_profile_field_type: 1,
        position: 0,
        ticket_id: 300,
        label: "Phone",
        id: "5fd2fafa4b75052c581ebecc",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: false,
        showOtherOption: false,
        type: "Text",
        fieldType: "phone",
        choices: [],
      },
      {
        user_profile_field_type: 6,
        position: 6,
        ticket_id: 300,
        label: "Genderx",
        id: "5fd2fafa4b75052c581ebecd",
        isDefault: true,
        isVisible: true,
        hasOptions: true,
        isMandatory: false,
        showOtherOption: true,
        type: "Radio",
        fieldType: "gender",
        choices: [
          {
            label: "Male",
            selectedByDefault: false,
          },
          {
            label: "Female",
            selectedByDefault: false,
          },
          {
            label: "Other",
            selectedByDefault: false,
          },
        ],
      },
      {
        user_profile_field_type: 1,
        position: 7,
        ticket_id: 300,
        label: "Designation",
        minLength: 1,
        maxLength: 50,
        id: "5fd2fafa4b75052c581ebecf",
        isDefault: true,
        isVisible: true,
        hasOptions: false,
        isMandatory: true,
        showOtherOption: true,
        type: "Text",
        fieldType: "designation",
        choices: [],
      },
    ],
  },
];
const SAMPLE_ATTENDEE_USERS = {};
// range(1, 5).forEach((ticketId) => {
//     SAMPLE_ATTENDEE_FIELDS[ticketId] = {
//         fields: PRE_SAMPLE_ATTENDEE_FIELDS,
//     };
// });

// range(1, 5).forEach((ticketId) => {
//     SAMPLE_ATTENDEE_USERS[ticketId] = {
//         users: PRE_SAMPLE_ATTENDEE_FIELDS,
//     };
// });

const getCurrentStep = (fetchStep?: string): number => {
  if (fetchStep) {
    switch (fetchStep) {
      case "SUMMARY":
        return 0;
      case "BUYERS":
        return 1;
      case "ATTENDEE":
        return 2;
      default:
        break;
    }
  }
  return 0;
};

const supportedLanguages = [
  { id: 1, name: "English", isBase: 1, shortCode: "en" },
  { id: 2, name: "Hindi", isBase: 0, shortCode: "hi" },
  { id: 3, name: "French", isBase: 0, shortCode: "fr" },
];

export {
  SAMPLE_OFFER_SELECT,
  SAMPLE_ATTENDEE_FIELDS,
  SAMPLE_ATTENDEE_USERS,
  PAYMENT_FLOW_STEPS,
  NAME_FIELDS,
  BUYERS_DEFAULT_FIELDS,
  CONTACT_FIELDS,
  TOAST_RESET_COUNT,
  getCurrentStep,
  supportedLanguages,
  linkTermsCondition,
  linkPrivacyPolicy,
};
