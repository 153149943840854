// Language: Spanish

export default {
    "already-registered": "Ya está registrado",
    "add-to-calendar": "Añadir al calendario",
    "event-starts-in": "El evento comienza en",
    "event-is-live": "EL EVENTO ESTÁ EN VIVO",
    "event-has-ended": "EL EVENTO HA TERMINADO",
    "powered-by": "Desarrollado por",
    "please-enter-your-email-to-continue": "Por favor, introduzca su correo electrónico para continuar",
    add: "Añadir",
    offers: "Ofertas",
    free: "Gratis",
    "available-till": "Disponible hasta {{DATE}}",
    "enter-email-address": "Introduzca su dirección de correo electrónico",
    "sold-out": "Agotado",
    "the-ticket-has-reached-the-maximum-number": "La entrada ha alcanzado el número máximo",
    "seat-left": "Queda {{SEAT}} asiento",
    "seats-left": "Quedan {{SEAT}} asientos",
    proceed: "Proceda a",
    summary: "Resumen",
    "buyer-info": "Información del comprador",
    continue: "Continuar",
    "attendees-info": "Información de los asistentes",
    "registration": "Registro",
    "attendees-information": "Información de los asistentes",
    submit: "Enviar",
    "register-to-join-events": "Regístrese para participar en los eventos",
    "by-clicking-on-the-button":
        "Al hacer clic en el botón, se le redirigirá a la plataforma de venta de entradas donde podrá inscribirse en este evento.",
    other: "Otros",
    "please-enter-a-value-for": "Por favor, introduzca un valor para <FIELD_NAME>",
    "please-select-a-value-for": "Por favor, seleccione un valor para <FIELD_NAME>",
    "enter-other-value-for": "Introduzca otro valor para <FIELD_NAME>",
    "enter-a-valid": "Introduzca un <FIELD_NAME> válido",
    "should-be-at-least": "<FIELD_NAME> debe ser como mínimo <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> no puede ser superior a <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> es obligatorio.",
    "at-least-required": "Se requiere al menos <FIELD_LENGTH> <FIELD_NAME> <VERB>.",
    "maximum-allowed": "Máximo <FIELD_LENGTH> <FIELD_NAME> <VERB> permitido",
    "user-with-same-email-id-already-exists":
        "El usuario con el mismo ID de correo electrónico ya existe. Por favor, utilice uno diferente.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "El usuario con el mismo ID de correo electrónico ya se ha registrado para este evento. Por favor, utilice uno diferente.",
    "mail-successfully-sent": "¡El correo se ha enviado con éxito!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "¡No podemos entregar su correo en este momento! Por favor, inténtelo después de un tiempo",
    "something-went-wrong": "¡Algo ha Ido Mal! Por favor, inténtelo de nuevo más tarde",
    "please-select-a-ticket-to-proceed": "Por favor, seleccione una entrada para continuar",
    "register-now": "Regístrese Ahora",
    "add-to-your-calendar": "Añadir a su Calendario",
    calendar: "Calendario",
    "resend-link": "Reenviar Enlace",
    "go-to-event": "Ir al Evento",
    "ticket-registration-done-by": "Registro de entradas realizado por",
    "you-have-already-purchased-the-ticket-for": "Ya ha comprado la entrada para",
    purchased: "Comprado",
    "event-date": "Fecha del Evento",
    "ticket-quantity": "Cantidad de Entradas",
    payment: "Pago",
    "unable-to-submit-data": "No se pueden enviar los datos",
    "unable-to-apply-promo-code": "No se puede aplicar el código de promoción",
    "invalid-promo-code": "Código promocional inválido",
    "apply-now": "Solicitar",
    "enter-discount-coupon": "Introduzca el cupón de descuento",
    "same-as-buyer": "Igual que el comprador",
    "please-check-all-the-fields-are-correctly-filled":
        "Por favor, compruebe que todos los campos están correctamente rellenados",
    "ticket-purchased": "Billete Comprado",
    "ticket-purchase-failed": "Fallo en la Compra de la Entrada",
    "you-have-successfully-purchased-the-ticket":
        "Ha comprado correctamente la entrada. Esta entrada le permitirá asistir al evento. ¡Gracias por su compra!",
    "there-was-an-error-while-booking-your-tickets":
        "Se ha producido un error al reservar sus entradas. Por favor, inténtelo de nuevo.",
    "try-again": "Inténtelo de Nuevo",
    "you-dont-have-access": "No tiene acceso para comprar algunas de las entradas",
    "please-enter-a-valid-email": "Por favor, introduzca un correo electrónico válido",
    "payment-summary": "Resumen de Pagos",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} aplicado con éxito. Has ahorrado {{AMOUNT}} adicional.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "¿Quiere generar una factura fiscal?",
    "taxation-tnc-pp": "Al continuar, aceptas nuestros",
    "taxation-tnc": "Términos y condiciones",
    and: "y",
    "taxation-pp": "Política de privacidad",
    "company-name-label": "Nombre de la empresa",
    "company-name-place-holder": "Nombre de empresa",
    "taxId-label": "Número de registro identificación fiscal de la empresa",
    "taxId-place-holder": "Identificación del impuesto",
    "company-address-label": "Dirección de la empresa",
    "company-address-place-holder": "Dirección",
    "country-place-holder": "Seleccione el país",
    "state-place-holder": "Seleccione el Estado",
    "city-place-holder": "Introduzca la ciudad",
    "zipcode-label": "El código postal",
    "zipcode-place-holder": "Introduzca el código postal",
    "company-error-msg": "Por favor, introduzca el nombre de la empresa",
    "taxId-error-msg": "Por favor, introduzca el identificación fiscal de la empresa",
    "address-error-msg": "Por favor ingrese la dirección",
    "country-error-msg": "Seleccione el país",
    "state-error-msg": "Por favor, seleccione el estado",
    "city-error-msg": "Por favor, introduzca la ciudad",
    "zipcode-error-msg": "Por favor, introduzca el código postal",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    phone: "Teléfono",
    dialerCode: "Código de marcador",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Ya ha comprado el billete para",
    "upload-file": "Cargar archivo",
    "browse-a-file-to-upload": "Examinar un archivo para cargarlo",
    "max-size": "Tamaño máximo",
    "file-supported-types": "Tipos de archivo admitidos",
    "uploaded-successfully": "Subido con éxito",
    "failed!-please-retry": "No se ha podido subir. Vuelva a intentarlo",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Este tipo de archivo no es compatible. Los archivos adjuntos admitidos son",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "El tamaño del archivo es demasiado grande. Por favor, suba un documento menor que",
    person: "Persona",
    select: "Seleccione",
    search: "Buscar en",
    "uploaded-file": "Archivo subido",
    uploading: "Cargar",
    delete: "Borrar",
    "no-item": "Ningún elemento",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Calendario de Google",
    "outlook-calendar": "Calendario de Outlook",
    "yahoo-calendar": "Calendario de Yahoo",
    "read-more": "Leer más",
    "read-less": "Leer menos",
    "about-title": "Acerca de",
    "verb-are": "son",
    "verb-is": "es",
    character: "letras",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/AAAA",
    "timer-hr": "H",
    "timer-hrs": "H",
    "timer-min": "Min",
    "timer-mins": "Min",
    "timer-sec": "Seg",
    "timer-secs": "Segs",
    "timer-day": "Día",
    "timer-days": "Días",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "No se ha elegido ningún archivo",
    today: "Hoy dia",
    yesterday: "Ayer",
    tomorrow: "Mañana",
    last: "Último",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Registro Realizado Con Éxito!",
    registrationSuccessDetail: "Se ha registrado con éxito para el evento. ¡Gracias!",
    "search-here": "Busca aquí..",
    "did-you-mean": "Quieres decir ",
    DISPOSABLE_EMAIL: "La dirección de correo electrónico proporcionada pertenece a un servicio de correo electrónico desechable",
    RISTRICTED_DOMAIN: "La dirección de correo electrónico indicada está dentro de un dominio restringido. Por favor, introduzca una dirección de correo electrónico válida",
    INVALID_EMAIL: "Por favor, introduzca un correo electrónico válido",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Elija el método de pago",
    PAY_NOW: "Pagar ahora",
    INSTANT_TICKET_CONFIRMATION: "Confirmación instantánea del billete",
    PAY_VIA_OTHER_METHODS: "Pagar a través de otros métodos",
    FUNDS_TRANSFER: "Transferencia de fondos",
    DIGITAL_WALLET: "Billetera digital",
    CHEQUE: "Cheque",
    PAY_AT_VENUE: "Pago en el lugar del evento",
    TICKET_NOT_CONFIRMED_ORGANIZER: "Las entradas no se confirmarán hasta que el organizador del evento apruebe que se ha recibido el pago y están sujetas a cancelación.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Las entradas no se confirmarán hasta que se realice el pago en el lugar del evento y están sujetas a cancelación.",
    TICKET_CONFIRMED_BY_ORGANIZER: "Las entradas se confirmarán cuando el organizador del evento apruebe que se ha recibido el pago.",
    COMPLETE_PAYMENT_OF: "Completar el pago de",
    COMPLETE_PAYMENT_TICKET: "Complete el pago de su(s) entrada(s).",
    PAYMENT_CONFIRMATION_TITLE: "Introduzca el ID de transacción único para que el organizador del evento verifique su pago.",
    ENTER_TRANSACTION_ID: "Introduzca el ID de la transacción",
    SHARE_DETAILS_TITLE: "El organizador del evento sólo puede aprobar su pago si usted comparte los detalles. Su(s) entrada(s), incluida(s) la(s) entrada(s) gratuita(s), será(n) confirmada(s) después de la aprobación.",
    UPLOAD_SCREENSHOT: "Cargue una captura de pantalla (opcional)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Comparta los detalles del pago",
    TICKET_STATUS: "Estado de la entrada", // not found
    ORGANIZER_YET_TO_CONFIRM: "El organizador del evento aún no ha confirmado la recepción del pago.",
    TICKET_CONFIRMATION_ORGANIZER: "Su(s) entrada(s), incluida(s) la(s) entrada(s) gratuita(s), y la factura serán confirmadas y compartidas con usted después de la aprobación del organizador del evento.",
    PAYMENT_DETAILS: "Detalles del pago",
    FAQ_TITLE: "Si tiene alguna duda, consulte las preguntas frecuentes.",
    MAKE_PAYMENT_TITLE: "Realice el pago en el lugar del evento para confirmar la compra de su(s) entrada(s).",
    CONFIRM_TICKET_PRIOR_EVENT: "Si desea confirmar su(s) entrada(s) antes del evento, pague ahora con otro método.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Si desea confirmar su(s) entrada (s) antes del evento,",
    PAY_NOW_2: "paga ahora",
    WITH_DIFFERENT_METHOD: "con un método diferente",
    PAYMENT_NOT_RCVD: "El organizador del evento no ha recibido el pago.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Los datos de pago compartidos no han podido ser verificados por el organizador del evento.",
    TICKETS_NOT_AVAILABLE: "Las entradas ya no están disponibles para el evento.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Todavía no has compartido los datos de pago de la(s) entrada(s).", // not found in code
    WELCOME_BACK: "¡Bienvenido de nuevo!",
    VIEW_OR_PROCEED_PURCHASE: "Puede ver sus entradas anteriores o proceder a la compra de las entradas que acaba de seleccionar.",
    VIEW_PREV_TICKETS: "Ver entradas anteriores",
    PROCEED_TO_PURCHASE: "Proceder a la compra",
    PREVIOUS_TICKETS: "Entradas anteriores",
    PENDING_TICKETS: "Entradas pendientes",
    CONFIRMED_TICKETS: "Entradas confirmadas",
    PAYMENT_PENDING: "Pago pendiente",
    AWAITING_ORG_APPROVAL: "A la espera de la aprobación del organizador",
    CHECK_TICKET_STATUS: "Comprobar el estado de la entrada",
    PAYMENT_METHOD_NOT_AVAILABLE: "El método de pago no está disponible, por favor inténtelo de nuevo.", // not found in code
    HERE: "aquí.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Por favor, actualice la información única de los asistentes para todas las entradas adquiridas. Esto permitirá a todos los asistentes acceder al evento.",
    DUPLICATE_EMAIL: "Ya has comprado este billete. Puede optar por volver a registrarse. En este caso, se guardarán los últimos datos introducidos.",
    DEFAULT_DUPLICATE_EMAIL: "Este correo electrónico ya se usó para otro ticket. Vuelva a verificar la dirección de correo electrónico que ingresó o siéntase libre de usar el mismo correo electrónico para múltiples boletos.",
    "REGISTER_NOW": "Regístrese Ahora",
    "REG_FAILED": "Error de registro",
    "REG_FAILED_DESC": "No se pudo completar el registro. Por favor intenta de nuevo",
    "REG_DONE_BY": "Registro realizado por",
    "INVITE_AGAIN": "¿Quieres volver a recibir la invitación?",
    "REG_SUCCESS_DONE": "Registro realizado con éxito",
    "WATCH_REC_LINK": "Se ha enviado un correo electrónico con un enlace para ver la grabación a",
    "WEBINAR_INV_LINK": "Se ha enviado un correo electrónico de invitación con el enlace del seminario web a",
    "MAIL_SENT_SUCCESS": "Correo enviado con éxito",
    "ALREADY_REGISTERED": "Ya te has registrado ",
    "RESEND_INVITE": "Reenviar invitación",
    "WATCH_RECORDING_REGISTER_DESC": "Puede ver la grabación del seminario web registrándose a continuación.",

};
