import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import EditIcon from "../../Assets/Svg/EditIcon";
import Box from "@mui/material/Box";
import React from "react";
import ImageUploadIcon from "../../Assets/Svg/ImageUploadIcon";
import DeleteIcon from "../../Assets/Svg/DeleteIcon";
import { useTranslation } from "react-i18next";

export type UploadNewUIProps = {
  className?: string;
  placeHolder?: string;
  id?: string;
  label?: string;
  hintMsg?: string | JSX.Element;
  errorMsg?: string;
  acceptFile?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  tabIndex?: number;
  value?: string;
  name?: string;
  errorType?: "error" | "success" | "";
  disabled?: boolean;
  hintText?: string;
};

const UploadNewUI = ({
  id = "",
  errorMsg = "",
  onChange,
  acceptFile = "",
  hintMsg = "",
  tabIndex = 1,
}: UploadNewUIProps): JSX.Element => {
  const [t] = useTranslation();

  return (
    <>
      <Box display="flex" alignItems="center" overflow="hidden">
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            border:`1px solid ${theme.palette.divider.default}`,
            borderRadius: "4px",
            width:"100%",
            padding: "9.8px 12px",
            cursor: "pointer",
            "&>input": {
              height: "6.4rem",
              width: "100%",
              left: 0,
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
            },
          })}
        >
          <input
            id={id || "upload"}
            type="file"
            name="img"
            onChange={onChange}
            accept={acceptFile || ""}
            tabIndex={tabIndex + 1}
          />
          <Box display="flex" alignItems="center" sx={(theme:any)=>({"&>svg>path":{fill:theme.palette.primary.main}, width:"100%",cursor: "pointer",justifyContent:"center" })}>
            <ImageUploadIcon/>
            <Typography
              variant="caption"
              component="span"
              sx={{fontSize:"10px !important", marginLeft: 1, whiteSpace: "nowrap", cursor: "pointer" }}
            >
              {t("UPLOAD_SCREENSHOT")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{mt:1,display:"flex",alignItems:"center",justifyContent:"space-between"}}>
        <Typography variant="body2" color="textSecondary" sx={{fontWeight:400}}>
            file name
        </Typography>
        <Box>
            <IconButton size="small" sx={(theme:any)=>({"&>svg":{color:theme.palette.primary.main}})}>
                <DeleteIcon height="18px" width="18px"/>
            </IconButton>
            <IconButton size="small" sx={(theme:any)=>({"&>svg>path":{fill:theme.palette.primary.main}})}>
                <EditIcon height="15px" width="15px"/>
            </IconButton>
        </Box>
      </Box>
      {hintMsg && (
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontWeight: 400,
            marginTop: "4px",
            color: theme.palette.text.secondary,
          })}
          component="p"
        >
          {hintMsg}{" "}
        </Typography>
      )}
      {errorMsg && (
        <Typography
          variant="caption"
          component="p"
          sx={(theme: any) => ({
            fontWeight: 400,
            marginTop: "4px",
            color: theme.palette.error.main,
          })}
        >
          {errorMsg}
        </Typography>
      )}
    </>
  );
};

export default UploadNewUI;
