import React from "react";
import produce from "immer";
import get from "lodash/get";

// helpers component
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import FlatPicker from "./FlatPicker";
// import styles from "./UserField.module.scss";
import TextField from "./InputField";

// svgs
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "../Assets/Svg/DeleteIcon";

// Utils
import { getFormFieldsAuthenticate } from "../Utils/FieldsValidation";
import { isTrueValueArray } from "../Utils/common";

// interface
import { IFieldProps } from "../Types/BuyerDetails";
import { useTranslation } from "react-i18next";
// Custom Interfaces

interface IGroupValue {
  fieldValue: string;
  id: string;
  team: number;
  [key: string]: string | number;
}

interface ICompProps {
  id: string;
  className?: string;
  value: IGroupValue[];
  label: string;
  onChange?: (arg1: string, arg2: any) => void;
  onBlur?: (arg1?: any, arg2?: any, arg3?: any) => void;
  isVisible: boolean;
  fields: IFieldProps[];
  hasError?: boolean;
  buttonLabel?: string;
  minLength?: number;
  maxLength?: number;
  fieldTypeName?: string;
  errData?: any[];
  errMsg: string;
  required: boolean;
  initTabIndex?: number;
  isMandatory?: any;
  isShowAddButton?: boolean;
}

const GroupComponent = (props: ICompProps): JSX.Element => {
  const {
    id,
    label,
    value,
    buttonLabel,
    minLength,
    maxLength,
    fieldTypeName,
    fields = [],
    hasError,
    errData,
    required,
    errMsg,
    onChange,
    onBlur,
    initTabIndex = 1,
  } = props;
  
  const [t] = useTranslation();

  const onRemove = (index: number) => {
    const updateState = produce(value, (draft) => {
      draft.splice(index, 1);
    });
    if (onChange) onChange(id, updateState);
  };

  const addMember = () => {
    const val = [...value, {}];
    if (onChange) onChange(id, val);
  };

  const onChangeField = (
    currVal: string,
    fieldId: string | number,
    groupIndex: number
  ) => {
    const updateState = produce(value, (draft) => {
      draft[groupIndex] = produce(draft[groupIndex], (objDraft) => {
        objDraft[fieldId] = currVal;
      });
    });
    if (onChange) onChange(id, updateState);
  };

  const onBlurField = (
    currVal: string,
    fieldId: string | number,
    groupIndex: number
  ) => {
    const isArray = isTrueValueArray(fields);
    if (isArray) {
      const fIndex = fields.findIndex(
        (field: IFieldProps) => String(field.id) === String(fieldId)
      );
      if (fIndex > -1) {
        const fetchAuth = getFormFieldsAuthenticate({
          ...fields[fIndex],
          value: currVal,
        });
        const hasError = get(fetchAuth, "hasError", false);
        const errMsg = get(fetchAuth, "errMsg", "");
        const updateState = produce(fields, (draft) => {
          draft[fIndex] = {
            ...fields[fIndex],
            isError: hasError,
            labelHint: errMsg,
          };
        });
        if (onBlur) onBlur(id, updateState, groupIndex);
      }
    }
  };

  const onDateChange = (
    date: string | undefined,
    fieldId: string | number,
    groupIndex: number
  ) => {
    let newDate = "";
    if (date) newDate = date;
    else newDate = "";
    onChangeField(newDate, fieldId, groupIndex);
  };

  let min = 0;
  let max = 1;
  let applyFieldTypeName = "Person";
  let applyButtonLabel = "Add New Person";

  if (minLength) min = minLength;
  if (maxLength) max = maxLength;
  if (fieldTypeName) applyFieldTypeName = fieldTypeName as string;
  if (buttonLabel) applyButtonLabel = buttonLabel as string;

  const userAddMemberBtnIndex =
    initTabIndex + value.length * (fields.length + 1);

  return (
    <Box id={id}>
      <Box>
        <Box sx={{ textAlign: "left" }} mt={1}>
          {label ? (
            <Typography
              component="label"
              variant="h4"
              htmlFor={id}
              
              sx={(theme: any) => ({
                color: theme.palette.text.primary,
                fontWeight: 600,
                textAlign: "left"
              })}
            >
              {label}{" "}
              {required ? (
                <Typography
                  component="span"
                  sx={(theme: any) => ({
                    color: theme.palette.primary.main,
                    fontSize: 16,
                    lineHeight: 0,
                  })}
                >
                  *
                </Typography>
              ) : (
                ""
              )}{" "}
            </Typography>
          ) : null}
          {errMsg || hasError ? (
            <Typography
              component="p"
              variant="caption"
              sx={(theme: any) => ({
                color: theme.palette.error.main,
                fontWeight: 400,
                marginTop: "2px",
              })}
            >
              {errMsg}
            </Typography>
          ) : (
            false
          )}
        </Box>
      </Box>

      {Array.isArray(value) &&
        value.map(
          (group: IGroupValue, index: number): boolean | JSX.Element => {
            if (index === 0 || !group) return false; // just 0 index ignore
            return (
              <Box key={index}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={2.5}
                >
                  <Box>
                    <Typography
                      component="p"
                      variant="body2"
                      sx={(theme: any) => ({
                        color: theme.palette.text.secondary,
                        fontWeight: 400,
                      })}
                    >
                      {applyFieldTypeName === "Person"
                        ? t("person")
                        : applyFieldTypeName}{" "}
                      {index}
                    </Typography>
                  </Box>
                  {min < index ? (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<DeleteIcon />}
                      tabIndex={initTabIndex + index}
                      onClick={() => onRemove(index)}
                    >
                      {t("delete")}
                    </Button>
                  ) : (
                    false
                  )}
                </Box>
                {fields &&
                  fields.map((field, fIndex): JSX.Element => {
                    let errorMsg = "";
                    let groupErr: { [key: string]: string | number } = {};
                    if (errData && errData[index]) {
                      groupErr = errData[index];
                      errorMsg = groupErr[field.id]
                        ? String(groupErr[field.id])
                        : "";
                    }

                    const customFieldId =
                      field.type === "DATE" ? field.id : `${id}-${field.id}`;
                    const isNumber: boolean = field && field.type === "NUMBER";

                    if (field.type === "DATE") {
                      return (
                        <Box sx={{ pb: 2 }}>
                          <TextField
                            key={fIndex}
                            id={String(customFieldId)}
                            value={
                              group[field.id] ? String(group[field.id]) : ""
                            }
                            label={field.label}
                            name={field.label}
                            placeHolder={t("date-format")}
                            type="text"
                            icon
                            endIcon={
                              <FlatPicker
                                options={{ dateFormat: "d/m/Y" }}
                                onChange={(date: any, val: any) =>
                                  onDateChange(val, field.id, index)
                                }
                                btnClass
                              />
                            }
                            errorMsg={errorMsg}
                            hintText={errorMsg}
                            tabIndex={initTabIndex + index + fIndex}
                          />
                        </Box>
                      );
                    } else {
                      return (
                        <Box key={fIndex} sx={{ pb: 2 }}>
                          <TextField
                            id={String(customFieldId)}
                            name={field.label}
                            label={field.label}
                            placeHolder={field.label}
                            regex={field.regex}
                            onChange={(event: any) =>
                              onChangeField(event.target.value, field.id, index)
                            }
                            onBlur={(event: any) =>
                              onBlurField(event.target.value, field.id, index)
                            }
                            value={
                              group[field.id] ? String(group[field.id]) : ""
                            }
                            type={isNumber ? "number" : "text"}
                            errorMsg={errorMsg}
                            hintText={errorMsg}
                            tabIndex={initTabIndex + index + fIndex}
                          />
                        </Box>
                      );
                    }
                  })}
              </Box>
            );
          }
        )}

      {value.length - 1 < max ? (
        <Box sx={{ my: 2.5, textAlign: "center" }}>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            tabIndex={userAddMemberBtnIndex}
            onClick={() => addMember()}
            sx={{whiteSpace:"initial"}}
          >
            {applyButtonLabel}
          </Button>
        </Box>
      ) : (
        false
      )}
    </Box>
  );
};

export default GroupComponent;
