import React from "react";

interface EditIconProps {
  className?: string;
  width?: string;
  height?: string;
}

const EditIcon = ({ width = "18px", height = "18px", className = "" }: EditIconProps): JSX.Element => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 21 21" fill="none">
<path d="M19 11C18.7348 11 18.4804 11.1054 18.2929 11.2929C18.1054 11.4804 18 11.7348 18 12V18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8946 17.2652 19 17 19H3C2.73478 19 2.48043 18.8946 2.29289 18.7071C2.10536 18.5196 2 18.2652 2 18V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H9C9.26522 3 9.51957 2.89464 9.70711 2.70711C9.89464 2.51957 10 2.26522 10 2C10 1.73478 9.89464 1.48043 9.70711 1.29289C9.51957 1.10536 9.26522 1 9 1H3C2.20435 1 1.44129 1.31607 0.87868 1.87868C0.316071 2.44129 0 3.20435 0 4V18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H17C17.7956 21 18.5587 20.6839 19.1213 20.1213C19.6839 19.5587 20 18.7956 20 18V12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11ZM4 11.76V16C4 16.2652 4.10536 16.5196 4.29289 16.7071C4.48043 16.8946 4.73478 17 5 17H9.24C9.37161 17.0008 9.50207 16.9755 9.62391 16.9258C9.74574 16.876 9.85656 16.8027 9.95 16.71L16.87 9.78L19.71 7C19.8037 6.90704 19.8781 6.79644 19.9289 6.67458C19.9797 6.55272 20.0058 6.42201 20.0058 6.29C20.0058 6.15799 19.9797 6.02728 19.9289 5.90542C19.8781 5.78356 19.8037 5.67296 19.71 5.58L15.47 1.29C15.377 1.19627 15.2664 1.12188 15.1446 1.07111C15.0227 1.02034 14.892 0.994202 14.76 0.994202C14.628 0.994202 14.4973 1.02034 14.3754 1.07111C14.2536 1.12188 14.143 1.19627 14.05 1.29L11.23 4.12L4.29 11.05C4.19732 11.1434 4.12399 11.2543 4.07423 11.3761C4.02446 11.4979 3.99924 11.6284 4 11.76ZM14.76 3.41L17.59 6.24L16.17 7.66L13.34 4.83L14.76 3.41ZM6 12.17L11.93 6.24L14.76 9.07L8.83 15H6V12.17Z" fill="currentColor"/>
</svg>);
};

export default EditIcon;