/* eslint-disable */
// let amplitude = typeof window !== "undefined" ? require("amplitude-js") : null;
// amplitude = amplitude?.default;
import { shouldApplied } from "./init";

// reset user

export async function resetUserID() {
    // if (shouldApplied) {
    //     // amplitude?.getInstance().setUserId(null);
    //     // disabling regenerate device id
    //     // amplitude.getInstance().regenerateDeviceId();
    // }
}

// get session id

export async function getAmplitudeSessionId() {
    if (shouldApplied) {
        // const id = amplitude?.getInstance()._sessionId;
        return "";
    }
}

// general

export async function onPage({ page, lpType }: { page: string; lpType: string }) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "on page";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     page: page,
    //     //     "lp type": lpType
    //     // });
    // }
}

export async function selectTicket({ ticketId, ticketName, email, quantity, currency, amount, lpType }
    : { ticketId: number | null, ticketName: string, email: string, quantity: number, currency: string, amount: number, lpType:string }
) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "select ticket and proceed to pay";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     "ticket id": ticketId,
    //     //     "ticket name": ticketName,
    //     //     email: email,
    //     //     quantity: quantity,
    //     //     currency: currency,
    //     //     amount: amount,
    //     //     "lp type": lpType
    //     // });
    // }
}

export async function continuePayment(
    { ticketId, ticketName, discountApplied, quantity, currency, amount }: { ticketId: number, ticketName: string, discountApplied: boolean , quantity: number, currency: string, amount: number }
) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "continue payment";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     "ticket id": ticketId,
    //     //     "ticket name": ticketName,
    //     //     discountApplied: discountApplied,
    //     //     quantity: quantity,
    //     //     currency: currency,
    //     //     amount: amount,
    //     // });
    // }
}

export async function addBuyerDetails({
    email,
    numberOfFieldsEncountered,
    numberOfFieldsEntered,
    numberOfMandatoryFields,
}: {
    email: string,
    numberOfFieldsEncountered: number,
    numberOfFieldsEntered: number,
    numberOfMandatoryFields: number,
}) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "add buyer details";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     email: email,
    //     //     "number of fields encountered": numberOfFieldsEncountered,
    //     //     "number of fields entered": numberOfFieldsEntered,
    //     //     "number of mandatory fields": numberOfMandatoryFields,
    //     // });
    // }
}

export async function addAttendeeDetails({
    numberOfFieldsEncountered,
    numberOfFieldsEntered,
    numberOfMandatoryFields,
    ticketCount,
}: {
    numberOfFieldsEncountered: number,
    numberOfFieldsEntered: number,
    numberOfMandatoryFields: number,
    ticketCount: number,
}) {
    // if (shouldApplied) {
    //     // const EVENT_NAME = "add attendee details";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     "number of fields encountered": numberOfFieldsEncountered,
    //     //     "number of fields entered": numberOfFieldsEntered,
    //     //     "number of mandatory fields": numberOfMandatoryFields,
    //     //     "ticket count": ticketCount,
    //     // });
    // }
}

export async function webinarRegistrationDetails({
    eventId,
    eventName,
    organiserId,
    totalFieldsCount,
    totalFieldsFilled,
}: {
    eventId: number,
    eventName: string,
    organiserId: number,
    totalFieldsCount: number,
    totalFieldsFilled: number,
}) {
    // if (shouldApplied) {
    //     // const EVENT_NAME = "click register now";
    //     // amplitude?.getInstance().logEvent(EVENT_NAME, {
    //     //     "source": "webinar lp",
    //     //     "event id": eventId,
    //     //     "event name": eventName,
    //     //     "org id": organiserId,
    //     //     "total field count": totalFieldsCount,
    //     //     "total fields filled": totalFieldsFilled,
    //     // });
    // }
}

export async function goToEvent({lpType}:{lpType: string}) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "go to event";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {"lp type": lpType});
    // }
}

export async function clickSocialLinks({ source, channel, lpType }: { source: string, channel: string, lpType: string }) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "click social links";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {
    //         source,
    //         channel,
    //         "lp type": lpType
    //     });
    // }
}

export async function purchasedTicket(
    { amount, ticketCount, currency, ticketId, ticketName, txnStatus, isWidget }
        : { amount: number, ticketCount: number, currency: string, ticketId: number, ticketName: string, txnStatus: boolean, isWidget: boolean }) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "purchased ticket";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {
    //         amount,
    //         "ticket count": ticketCount,
    //         currency,
    //         "ticket id": ticketId,
    //         "ticket name": ticketName,
    //         txnStatus: txnStatus,
    //         "from widget": isWidget,
    //     });
    // }
}

export async function clickTryAgainTxnFailure() {
    // if (shouldApplied) {
    //     const EVENT_NAME = "click try again txn failure";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {});
    // }
}

export async function addToCalendar({ type, lpType }: { type: ICalendarTypes, lpType: string }) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "add to calendar";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {
    //         type,
    //         "lp type": lpType
    //     });
    // }
}

export async function widgetData({ isWidget, clientUrl, eventInfo, language }: { isWidget: boolean, clientUrl: string, eventInfo: boolean, language: string,}) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "Widget Data";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {
    //         "is widget":isWidget,
    //         "client url": clientUrl,
    //         "Display event name and event date": eventInfo,
    //         "language":language,
    //     });
    // }
}

export async function pendingApprovalOrderCreated( payment_method : string, payment_details: string, order_id: string, payout: string) {
    // if (shouldApplied) {
    //     const EVENT_NAME = "PendingApprovalOrderCreated";
    //     amplitude?.getInstance().logEvent(EVENT_NAME, {
    //         "payment_method": payment_method,
    //         "payment_details": payment_details,
    //         "order_id": order_id,
    //         "payout": payout,
    //     });
    // }
}

