// LIBRARIES
import { useState, useEffect } from "react";

// API HELPERS
import { getApi } from "../Core/HttpClient";

// INTERFACES
import { IOrderTicketsResponse } from "Types/SubmitPayment";

// UTILS
import { stringToBase64 } from "Utils/common";

// CONSTANTS
const initialState: IOrderTicketsResponse = {
    confirmedTickets: [],
    pendingOrders: []
};

const useOrderTickets = (headers: any, email: string) => {
    const [data, setData] = useState<IOrderTicketsResponse>({ ...initialState });
    const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown | null>();
    const encodedAddress = stringToBase64(email);

    useEffect(() => {
        let didCancel = false;
        async function fetchApi() {
            try {
                const response = await getApi<IOrderTicketsResponse>(
                    `orders/${encodedAddress}`,
                    headers,
                );

                if (!response.status) {
                    throw new Error(response.error.message);
                }
                if (!didCancel) {
                    setData(response?.success?.data as IOrderTicketsResponse);
                }
            } catch (err) {
                setError(err);
            } finally {
                setSubmitLoading(false);
            }
        }
        fetchApi();
        return () => {
          didCancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    // const getOrders = useCallback((headers, email: string) => {
    //     const encodedAddress = stringToBase64(email);
    //     let didCancel = false;
    //     async function fetchApi() {
    //         try {
    //             const response = await getApi<IOrderTicketsResponse>(
    //                 `orders/${encodedAddress}`,
    //                 headers,
    //             );

    //             if (!response.status) {
    //                 throw new Error(response.error.message);
    //             }
    //             if (!didCancel) {
    //                 setData(response?.success?.data as IOrderTicketsResponse);
    //             }
    //         } catch (err) {
    //             setError(err);
    //         } finally {
    //             setSubmitLoading(false);
    //         }
    //     }
    //     fetchApi();
    //     return () => {
    //         didCancel = true;
    //     };
    // }, []);
    return { data, isSubmitLoading, setSubmitLoading, error };
};

export default useOrderTickets;
