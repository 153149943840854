// Language: French

export default {
    "already-registered": "Déjà inscrit",
    "add-to-calendar": "Ajoutez au calendrier",
    "event-starts-in": "L'événement débute à",
    "event-is-live": "L'ÉVÉNEMENT EST EN COURS",
    "event-has-ended": "L'ÉVÉNEMENT EST TERMINÉ",
    "powered-by": "Animé par",
    "please-enter-your-email-to-continue": "Veuillez entrer votre email pour continuer",
    add: "Ajoutez",
    offers: "Offres",
    free: "Gratuites",
    "available-till": "Disponible jusqu'à {{DATE}}",
    "enter-email-address": "Entrez votre adresse email",
    "sold-out": "Vendu",
    "the-ticket-has-reached-the-maximum-number": "Le billet a atteint le nombre maximum",
    "seat-left": "{{SEAT}} place restante",
    "seats-left": "{{SEAT}} places restantes",
    proceed: "Continuez",
    summary: "Résumé",
    "buyer-info": "Informations sur l'acheteur",
    continue: "Continuez",
    "attendees-info": "Informations sur les participants",
    "registration": "Enregistrement",
    "attendees-information": "Informations sur les participants",
    submit: "Envoyez",
    "register-to-join-events": "Inscrivez-vous pour participer aux événements",
    "by-clicking-on-the-button":
        "En cliquant sur le bouton, vous serez redirigé vers la plateforme de billetterie où vous pourrez vous inscrire à cet événement.",
    other: "Autre",
    "please-enter-a-value-for": "Veuillez entrer une valeur pour <FIELD_NAME>",
    "please-select-a-value-for": "Veuillez sélectionner une valeur pour <FIELD_NAME>",
    "enter-other-value-for": "Entrez une autre valeur pour <FIELD_NAME>",
    "enter-a-valid": "Entrez une valeur valide pour <FIELD_NAME>",
    "should-be-at-least": "<FIELD_NAME> doit être au moins égal à <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> ne peut pas être supérieur à <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> est obligatoire.",
    "at-least-required": "Au moins <FIELD_LENGTH> <FIELD_NAME> <VERB> requis",
    "maximum-allowed": "Maximum <FIELD_LENGTH> <FIELD_NAME> <VERB> autorisé",
    "user-with-same-email-id-already-exists":
        "L'utilisateur ayant le même identifiant électronique existe déjà. Veuillez en utiliser un autre.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "L'utilisateur ayant le même identifiant email s'est déjà inscrit à cet événement. Veuillez en utiliser un autre.",
    "mail-successfully-sent": "Email envoyé avec succès!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "Nous ne sommes pas en mesure d'envoyer votre message pour le moment! Veuillez réessayer dans quelques instants.",
    "something-went-wrong": "Quelque chose a été mal tourné ! Veuillez réessayer plus tard",
    "please-select-a-ticket-to-proceed": "Veuillez sélectionner un billet pour continuer",
    "register-now": "Inscrivez-vous maintenant",
    "add-to-your-calendar": "Ajoutez à votre calendrier",
    calendar: "Calendrier",
    "resend-link": "Renvoyez le lien",
    "go-to-event": "Accédez à l'événement",
    "ticket-registration-done-by": "Enregistrement du billet effectué par",
    "you-have-already-purchased-the-ticket-for": "Vous avez déjà acheté le billet pour",
    purchased: "Acheté",
    "event-date": "Date de l'événement",
    "ticket-quantity": "Quantité de billets",
    payment: "Paiement",
    "unable-to-submit-data": "Impossible d’envoyer les données",
    "unable-to-apply-promo-code": "Impossible d'appliquer le code promotionnel",
    "invalid-promo-code": "Code promotionnel non valide.",
    "apply-now": "Appliquer",
    "enter-discount-coupon": "Entrez le coupon de réduction",
    "same-as-buyer": "Identique à l'acheteur",
    "please-check-all-the-fields-are-correctly-filled":
        "Veuillez vérifier que tous les champs sont correctement remplis",
    "ticket-purchased": "Billet acheté",
    "ticket-purchase-failed": "Échec de l'achat du billet",
    "you-have-successfully-purchased-the-ticket":
        "Vous avez acheté le billet avec succès. Ce billet vous permettra d'assister à l'événement. Merci pour votre achat!",
    "there-was-an-error-while-booking-your-tickets":
        "Une erreur s'est produite lors de la réservation de vos billets. Veuillez réessayer.",
    "try-again": "Réessayez",
    "you-dont-have-access": "Vous n'avez pas accès à l'achat de quelques billets.",
    "please-enter-a-valid-email": "Veuillez entrer une adresse email valide",
    "payment-summary": "Résumé du paiement",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} appliqué avec succès. Vous avez économisé un {{AMOUNT}} supplémentaire.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    and: "et",
    "taxation-concent": "Vous voulez générer une facture fiscale ?",
    "taxation-tnc-pp": "En continuant, vous acceptez les",
    "taxation-tnc": "Modalités et conditions,",
    "taxation-pp": "Politique de confidentialité,",
    "company-name-label": "Nom de la société",
    "company-name-place-holder": "Entrez le nom de la société",
    "taxId-label": "numéro d'identification fiscale de l'entreprise",
    "taxId-place-holder": "Entrer l'identifiant fiscal",
    "company-address-label": "Adresse de la société",
    "company-address-place-holder": "Adresse",
    "country-place-holder": "Sélectionnez le pays",
    "state-place-holder": "Sélectionnez l'État",
    "city-place-holder": "Entrez la ville",
    "zipcode-label": "Le code postal",
    "zipcode-place-holder": "Entrez le code postal",
    "company-error-msg": "Veuillez indiquer le nom de l'entreprise",
    "taxId-error-msg": "Veuillez entrer le numéro de numéro d'identification fiscale de l'entreprise",
    "address-error-msg": "Veuillez entrer l'adresse",
    "country-error-msg": "Veuillez sélectionner le pays",
    "state-error-msg": "Veuillez sélectionner l'État",
    "city-error-msg": "Veuillez entrer la ville",
    "zipcode-error-msg": "Veuillez entrer le code postal",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Prénom",
    lastName: "Nom de famille",
    email: "E-mail",
    phone: "Téléphone",
    dialerCode: "Code de numérotation",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Vous avez déjà acheté le billet pour",
    "upload-file": "Télécharger un fichier",
    "browse-a-file-to-upload": "Parcourir un fichier à charger",
    "max-size": "Taille maximale",
    "file-supported-types": "Types de fichiers pris en charge",
    "uploaded-successfully": "Le fichier est téléchargé avec succès",
    "failed!-please-retry": "Échec ! Veuillez réessayer",
    "this-file-type-is-not-supported-supported-attachments-are": "Ce type de fichier n'est pas pris en charge. Les pièces jointes prises en charge sont",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "La taille du fichier est trop élevée. Veuillez télécharger un document de moins de",
    person: "Personne",
    select: "Sélectionnez",
    search: "Recherche",
    "uploaded-file": "téléchargé fichier",
    uploading: "téléchargement",
    delete: "Supprimer",
    "no-item": "Aucun élément",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "read-more": "Lire la suite",
    "read-less": "Lire moins",
    "about-title": "À propos de",
    "verb-are": "sont",
    "verb-is": "est",
    character: "caractères",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "h",
    "timer-hrs": "h",
    "timer-min": "mn",
    "timer-mins": "mn",
    "timer-sec": "sec",
    "timer-secs": "sec",
    "timer-day": "Jour",
    "timer-days": "Jours",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Aucun fichier choisi",
    today: "Aujourd'hui",
    yesterday: "Hier",
    tomorrow: "Demain",
    last: "Dernier",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Inscription Réussie!",
    registrationSuccessDetail: "Vous vous êtes inscrit avec succès à l événement. Je vous remercie!",
    "search-here": "Cherche ici..",
    "did-you-mean": "Vous voulez dire ",
    DISPOSABLE_EMAIL: "L'adresse e-mail donnée appartient à un service d'e-mail jetable",
    RISTRICTED_DOMAIN: "L'adresse électronique indiquée fait partie d'un domaine restreint. Veuillez entrer une adresse électronique valide",
    INVALID_EMAIL: "Veuillez entrer une adresse email valide",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Choisissez le mode de paiement",
    PAY_NOW: "Payez maintenant",
    INSTANT_TICKET_CONFIRMATION: "Confirmation instantanée du billet",
    PAY_VIA_OTHER_METHODS: "Payer via d'autres méthodes",
    FUNDS_TRANSFER: "Transfert de Fonds",
    DIGITAL_WALLET: "Portefeuille Numérique",
    CHEQUE: "Chèque",
    PAY_AT_VENUE: "Payer sur place",
    TICKET_NOT_CONFIRMED_ORGANIZER: "Les billets ne seront pas confirmés tant que l'organisateur de l'événement n'aura pas approuvé la réception du paiement et sont sujets à annulation.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Les billets ne seront pas confirmés tant que vous n'aurez pas effectué le paiement sur le lieu de l'événement et sont sujets à annulation.",
    TICKET_CONFIRMED_BY_ORGANIZER: "Les billets seront confirmés lorsque l'organisateur de l'événement approuvera la réception du paiement.",
    COMPLETE_PAYMENT_OF: "Paiement complet de",
    COMPLETE_PAYMENT_TICKET: "Complétez le paiement de votre(vos) billet (s).",
    PAYMENT_CONFIRMATION_TITLE: "Saisissez l'identifiant de transaction unique de l'organisateur de l'événement pour vérifier votre paiement.",
    ENTER_TRANSACTION_ID: "Entrez l'ID de la transaction",
    SHARE_DETAILS_TITLE: "L'organisateur de l'événement ne peut approuver votre paiement que si vous partagez les détails. Votre (vos) billet(s), y compris tout billet (s) gratuit (s), sera (seront) confirmé (s) après approbation.",
    UPLOAD_SCREENSHOT: "Télécharger une capture d'écran (facultatif)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Partager Les Détails de Paiement",
    TICKET_STATUS: "Statut du Ticket", // not found
    ORGANIZER_YET_TO_CONFIRM: "L'organisateur de l'événement n'a pas encore confirmé que le paiement a été reçu.",
    TICKET_CONFIRMATION_ORGANIZER: "Vos billets, y compris les billets gratuits, et votre facture seront confirmés et partagés avec vous après l'approbation de l'organisateur de l'événement.",
    PAYMENT_DETAILS: "Détails de Paiement",
    FAQ_TITLE: "En cas de questions, reportez-vous à la FAQ",
    MAKE_PAYMENT_TITLE: "Effectuez le paiement sur le lieu de l'événement pour confirmer l'achat de votre(vos) billet (s).",
    CONFIRM_TICKET_PRIOR_EVENT: "Si vous souhaitez confirmer votre(vos) billet (s) avant l'événement, payez maintenant avec une méthode différente.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Si vous souhaitez confirmer votre(vos) billet (s) avant l'événement,",
    PAY_NOW_2: "payez maintenant",
    WITH_DIFFERENT_METHOD: "avec une méthode différente",
    PAYMENT_NOT_RCVD: "L'organisateur de l'événement n'a pas reçu le paiement.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Les détails de paiement partagés n'ont pas pu être vérifiés par l'organisateur de l'événement.",
    TICKETS_NOT_AVAILABLE: "Les billets ne sont plus disponibles pour l'événement.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Vous n'avez pas encore communiqué les détails de paiement du ou des billets.", // not found in code
    WELCOME_BACK: "Soyez de nouveau le bienvenu!",
    VIEW_OR_PROCEED_PURCHASE: "Vous pouvez consulter vos billets précédents ou procéder à l'achat des billets que vous venez de sélectionner.",
    VIEW_PREV_TICKETS: "Voir Les Billets Précédents",
    PROCEED_TO_PURCHASE: "Procéder à l'achat",
    PREVIOUS_TICKETS: "Billets Précédents",
    PENDING_TICKETS: "Tickets En Attente",
    CONFIRMED_TICKETS: "Billets Confirmés",
    PAYMENT_PENDING: "Paiement en attente",
    AWAITING_ORG_APPROVAL: "En attente de l'approbation de l'organisateur",
    CHECK_TICKET_STATUS: "Vérifier L'État du Ticket",
    PAYMENT_METHOD_NOT_AVAILABLE: "mode de paiement non disponible, veuillez réessayer.", // not found in code
    HERE: "ici.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Veuillez mettre à jour les informations uniques sur les participants pour tous les billets achetés. Cela permettra à tous ces participants d'accéder à l'événement.",
    DUPLICATE_EMAIL: "Vous avez déjà acheté ce billet. Vous pouvez choisir de vous réinscrire. Dans ce cas, les dernières données saisies seront enregistrées.",
    DEFAULT_DUPLICATE_EMAIL: "Cet email est déjà utilisé pour un autre ticket. Veuillez revérifier l'adresse e-mail que vous avez saisie ou n'hésitez pas à utiliser la même adresse e-mail pour plusieurs billets.",
    "REGISTER_NOW": "Inscrivez-vous maintenant",
    "REG_FAILED": "Échec de l’inscription",
    "REG_FAILED_DESC": "Votre inscription a échoué. Veuillez réessayer.",
    "REG_DONE_BY": "Inscription effectuée par",
    "INVITE_AGAIN": "Vous souhaitez recevoir à nouveau l’invitation ?",
    "REG_SUCCESS_DONE": "L’inscription a été effectuée avec succès",
    "WATCH_REC_LINK": "Un courriel contenant un lien pour visionner l’enregistrement a été envoyé à",
    "WEBINAR_INV_LINK": "Un courriel d’invitation contenant le lien vers le webinaire a été envoyé à",
    "MAIL_SENT_SUCCESS": "Courriel envoyé avec succès",
    "ALREADY_REGISTERED": "Vous vous êtes déjà inscrit à",
    "RESEND_INVITE": "Renvoyer l’invitation",
    "WATCH_RECORDING_REGISTER_DESC": "Vous pouvez regarder l’enregistrement du webinaire en vous inscrivant ci-dessous.",

};
