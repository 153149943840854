import React from "react";
import { IIconProps } from "./types";

const InfoViewIcon = ({
  width = "20",
  height = "20",
  className = "",
}: IIconProps): JSX.Element => {
  return (
   
    
<svg  width={width}
      height={height}
      className={className}
        viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_13005_46226)">
<path d="M9.99999 18.3337C14.6024 18.3337 18.3333 14.6027 18.3333 10.0003C18.3333 5.39795 14.6024 1.66699 9.99999 1.66699C5.39762 1.66699 1.66666 5.39795 1.66666 10.0003C1.66666 14.6027 5.39762 18.3337 9.99999 18.3337Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 13.3333V10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10 6.66699H10.0083" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_13005_46226">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};
export default InfoViewIcon;
