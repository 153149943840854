import React from "react";
import NoSSR from "../Components/NoSSR";
import { Box, createTheme, Typography } from "@mui/material";
import { StylesProvider, ThemeProvider, createGenerateClassName } from "@mui/styles";
import { StoreProvider } from "Context";
import i18n from "Internationalization";
import { I18nextProvider } from "react-i18next";
import Ticket404 from "Assets/Svg/Ticket404";
import theme from "../theme";

const generateClassName = createGenerateClassName({ productionPrefix: "tmf" });
const ErrorPage= (): JSX.Element => {
  
    const themeObj = {
      "id": 2737,
      "isDefault": 0,
      "eventId": 30385,
      "organiserId": 345136,
      "name": "Primary Theme-Copy",
      "primaryColor": {
          "type": "solid",
          "color1": "#FFFFFF"
      },
      "secondaryColor": {
          "type": "solid",
          "color1": "#353535"
      },
      "accentColor": {
          "type": "gradient",
          "color1": "#80c9ac",
          "color2": "#60b3c2"
      },
      "headbarColor": {
          "type": "solid",
          "color1": "#FFFFFF"
      },
      "navbarColor": {
          "type": "solid",
          "color1": "#F9F9F9"
      },
      "fontColor": {
          "type": "solid",
          "color1": "#353535"
      },
      "secondaryFontColor": {
          "type": "solid",
          "color1": "#FFFFFF"
      },
      "backgroundType": "IMAGE",
      "backgroundImage": {
          "img_file_name": "3884_5807_450212001657718988.png",
          "opacity": 10,
          "vignette": 9,
          "blur": 0,
          "url": ""
      },
      "mainBackgroundColor": ""
  };
    // @ts-ignore
      const themeData = createTheme(theme(themeObj));
  
    
    return (
      <NoSSR>
        <I18nextProvider i18n={i18n}>
          <StoreProvider>
            <StylesProvider generateClassName={generateClassName}>
              <ThemeProvider theme={themeData}>
                <Box  p={2} sx={(theme:any)=>({backgroundColor:theme.palette.background.lightGray})} display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="400px">
                  <Ticket404/>
                  <Typography color="textPrimary" sx={(theme:any)=>({marginBottom:theme.spacing(1),marginTop:theme.spacing(5)})} variant="body1" component="label">Hi There! Unable to load this page</Typography>
                  <Typography color="textSecondary" sx={(theme:any)=>({fontWeight:400 ,marginBottom:theme.spacing(3)})} variant="body2" component="p">Looks like the website you are on is not whitelisted</Typography>
                </Box>
              </ThemeProvider>
            </StylesProvider>
          </StoreProvider>
        </I18nextProvider>
      </NoSSR>
    );
  };

export default ErrorPage;