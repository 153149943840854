import { IMainComponentStandaloneProps } from "index";
import React, { createContext, useContext, useState } from "react";
import { IPaymentMethods } from "Types/AttendeeForm";
import { ITicketDetailsResponse } from "Types/Ticket";
import { IAddTicket } from "Types/ValidateEmail";
import { IVerifyPaymentStatusResponse } from "Types/VerifyStatus";
import { IActiveModalData } from "Types/WebinarForm";
import { PageState } from "./Constants/AppConstants";
import { IApplyDiscount } from "./Types/ApplyDiscount";
import { IAttendeeFormSetRequest } from "./Types/AttendeeFormSet";
import { ITicketData } from "./Types/LandingPage";

export interface IPaymentDescription {
  label: string;
  type: string;
  amount: number;
  symbol?: string;
}

export interface ILanguage {
  id: number;
  name: string;
  shortCode: string;
  isBase: number;
}

export interface IPaymentDetails {
  isLoading?: boolean;
  isPurchase?: boolean;
  currPaymentStatus?: string;
  description?: IPaymentDescription[];
  isPaymentExists?: boolean;
  paidTicketDtls?: any;
  showGoToEventCTA?: boolean;
  goToEventCtaLabel?: string;
  hasDuplicateAttendee?: boolean;
}

export interface IToastComp {
  message: string;
  type: "error" | "success";
}

export interface IPendingTaxation {
  enableBuyerTaxId?: boolean;
  isCheckTaxation?: boolean;
  isTaxationExists?: boolean;
}

export interface TCompanyDetails {
  name?: any;
  taxId?: any;
  address?: any;
  country?: any;
  state?: any;
  city?: any;
  zipCode?: any;
}
export interface TBuyerBasicDetail {
  buyerFirstName?: string;
  buyerLastName?: string;
  buyerEmail?: string;
}

export interface TPayementSelectionModule {
  choosePaymentMethod?: false;
}

export interface IOtherPaymentDetail {
  paymentMethodTypesAvailable?: string[],
  paymentMethods?: IPaymentMethods,
  emailForOfflinePaymentMethods?: string,
  selectedPaymentMethod?: any,
  paymentSelectionModule?: any,
}

export interface IOtherPaymentModal {
  choosePaymentMethod?: boolean,
  faqModal?: boolean,
  orderStatus?: boolean,
  paymentDetailModal?: boolean,
  returningUserModal?: boolean,
  sharePaymentDetails?: boolean,
  ticketConfirmStatus?: boolean,
  paymentStatusModal?: boolean;
}

export interface IAuthEmailResponse {
  case?: string;
  ticketIds?: Array<number>;
  tickets?: ITicketDetailsResponse;
  buyerEmail?: string;
  buyerAlreadyRegisteredAsAttendee?: boolean;
  canShowResendLink?: boolean;
  code?: string;
}

export interface IStateType {
  pageState?: number;
  discountData?: IApplyDiscount;
  attendeeData?: IAttendeeFormSetRequest;
  ticketList?: Array<ITicketData>;
  ticketStore?: Array<ITicketData>;
  totalAmount?: number;
  totalAmountSymbol?: string;
  buyerEmail?: string;
  orderNo?: string | number;
  orderToken?: string;
  buyerFields?: any;
  webinarFields?: any;
  startFromSummary: boolean;
  buyerRegisteredAsAttendee?: any;
  isBuyerFrom?: any;
  supportedLanguages?: ILanguage[];
  paymentDetails: IPaymentDetails;
  companyDetails: TCompanyDetails;
  toast?: IToastComp;
  taxationDetails?: IPendingTaxation;
  selectedTicket?: IAddTicket[];
  registrationToggle?: boolean;
  hideFreeLabel?: boolean;
  buyerBasicDetail?: TBuyerBasicDetail;
  widgetData?: IMainComponentStandaloneProps | undefined;
  stripeAccountId: string | null;
  otherPaymentDetails: IOtherPaymentDetail;
  otherPaymentModal: IOtherPaymentModal;
  transactionId: string;
  paymentStatusDetails?: IVerifyPaymentStatusResponse;
  userDataLandingPage?: IAuthEmailResponse
  landingPageData: any;
  configData: any;
  activeModalData: IActiveModalData | null;
  isWebinarModalOpen: boolean;
}

export interface IStoreType {
  store: IStateType;
  setPageState?: (pageState: number) => void;
  setDiscount: (pageState: number, discountData: IApplyDiscount) => void;
  setAttendeeFormRequest: (
    pageState: number,
    attendeeData: IAttendeeFormSetRequest
  ) => void;
  setWebinarFields: (webinarFields: any) => void;
  setBuyerFields: (buyerFields: any) => void;
  setTicketList: (ticketData: Array<ITicketData>) => void;
  setTicketStore: (list: Array<ITicketData>) => void;
  setTotalAmount: (amountValue: number) => void;
  setTotalAmountSymbol: (amountsymbol: string) => void;
  setBuyerEmail: (currentEmail: string) => void;
  setOrderNo: (id: any) => void;
  setOrderToken: (id: any) => void;
  setStartFromSummary: () => void;
  setBuyerRegisteredAsAttendee: (buyerRegisteredAsAttendee: any) => void;
  setIsBuyerFrom: (isBuyerFrom: any) => void;
  setPaymentDetails: (paymentDetails: IPaymentDetails) => void;
  setSupportedLanguages: (supportedLanguages: ILanguage[]) => void;
  setToast: (toast: IToastComp) => void;
  setTaxationDetails: (taxationDetails: IPendingTaxation) => void;
  setCompanyDetails: (companyDetails: TCompanyDetails) => void;
  setSelectedTickets: (selectedTicket: IAddTicket[]) => void;
  setRegistrationToggle: (registrationToggle: any) => void;
  setHideFreeLabel: (hideFreeLabel: any) => void;
  setBuyerBasicDetail: (buyerBasicDetail: TBuyerBasicDetail) => void;
  setWidgetData: (buyerBasicDetail: IMainComponentStandaloneProps | undefined) => void;
  setStripeAccountId: (stripeAccountId: string | null) => void;
  setOtherPaymentDetails: (otherPaymentDetails: IOtherPaymentDetail) => void;
  setOtherPaymentModal: (otherPaymentModal: IOtherPaymentModal) => void;
  setTransactionId: (id: any) => void;
  setPaymentStatusDetails: (paymentStatusDetails: IVerifyPaymentStatusResponse) => void;
  setUserData: (userDataLandingPage: IAuthEmailResponse) => void;
  setLandingPageData: (landingPageData: any) => void;
  setConfigData: (configData: any) => void;
  setActiveModalData: (activeModalData: IActiveModalData | null) => void;
  setWebinarModalOpen: (isWebinarModalOpen: boolean) => void;
}


const initialState: IStateType = {
  pageState: PageState.TicketInfo,
  discountData: {} as IApplyDiscount,
  ticketList: [] as Array<ITicketData>,
  ticketStore: [] as Array<ITicketData>,
  totalAmount: 0,
  totalAmountSymbol: "",
  buyerEmail: "",
  orderNo: "",
  orderToken: "",
  startFromSummary: false,
  paymentDetails: {} as IPaymentDetails,
  supportedLanguages: [] as ILanguage[],
  taxationDetails: {} as IPendingTaxation,
  companyDetails: {} as TCompanyDetails,
  registrationToggle: false,
  hideFreeLabel: false,
  buyerBasicDetail: {} as TBuyerBasicDetail,
  widgetData: {} as IMainComponentStandaloneProps | undefined,
  stripeAccountId: null,
  otherPaymentDetails: {} as IOtherPaymentDetail,
  otherPaymentModal: {
    choosePaymentMethod: false,
    faqModal: false,
    orderStatus: false,
    paymentDetailModal: false,
    returningUserModal: false,
    sharePaymentDetails: false,
    ticketConfirmStatus: false,
  },
  transactionId: "",
  paymentStatusDetails: {},
  userDataLandingPage: {} as IAuthEmailResponse,
  landingPageData: null,
  configData: {},
  activeModalData: null,
  isWebinarModalOpen: false,
};

const StoreContext = createContext({} as IStoreType);

const StoreProvider = ({ children }: any) => {
  const [store, setStore] = useState<IStateType>({ ...initialState });

  const setDiscount = (pageState: number, discountData: IApplyDiscount) => {
    setStore((store) => ({
      ...store,
      pageState: pageState,
      discountData: discountData,
    }));
  };
  const setAttendeeFormRequest = (
    pageState: number,
    attendeeData: IAttendeeFormSetRequest
  ) => {
    setStore((store) => ({
      ...store,
      pageState: pageState,
      attendeeData: attendeeData,
    }));
  };
  const setBuyerFields = (buyerFields: any) => {
    setStore((store) => ({
      ...store,
      buyerFields: buyerFields,
    }));
  };
  const setWebinarFields = (webinarFields: any) => {
    setStore((store) => ({
      ...store,
      webinarFields: webinarFields,
    }));
  };
  const setPageState = (pageState: number) =>
    setStore((store) => ({
      ...store,
      pageState: pageState,
    }));

  const setTicketList = (ticketList: Array<ITicketData>) =>
    setStore((store) => ({
      ...store,
      ticketList,
    }));

  const setTicketStore = (list: Array<ITicketData>) =>
    setStore((store) => ({
      ...store,
      ticketStore: list,
    }));


  const setTotalAmount = (totalAmount: number) =>
    setStore((store) => ({
      ...store,
      totalAmount,
    }));

  const setTotalAmountSymbol = (totalAmountSymbol: string) =>
    setStore((store) => ({
      ...store,
      totalAmountSymbol,
    }));


  const setBuyerEmail = (buyerEmail: string) =>
    setStore((store) => ({ ...store, buyerEmail }));

  const setOrderToken = (orderToken: any) =>
    setStore((store) => ({ ...store, orderToken }));

  const setOrderNo = (orderNo: any) =>
    setStore((store) => ({ ...store, orderNo }));

  const setStartFromSummary = () =>
    setStore((store) => ({ ...store, startFromSummary: true }));

  const setRegistrationToggle = (toggle: any) =>
    setStore((store) => ({ ...store, registrationToggle: toggle }));

  const setHideFreeLabel = (toggle: any) =>
    setStore((store) => ({ ...store, hideFreeLabel: toggle }));

  const setBuyerRegisteredAsAttendee = (buyerRegisteredAsAttendee: any) => {
    setStore((store) => ({
      ...store,
      buyerRegisteredAsAttendee: buyerRegisteredAsAttendee,
    }));
  };

  const setIsBuyerFrom = (isBuyerFrom: any) => {
    setStore((store) => ({
      ...store,
      isBuyerFrom: isBuyerFrom,
    }));
  };

  const setPaymentDetails = (paymentDetails: IPaymentDetails) =>
    setStore((store) => ({ ...store, paymentDetails }));

  const setSupportedLanguages = (supportedLanguages: ILanguage[]) =>
    setStore((store) => ({ ...store, supportedLanguages }));

  const setToast = (toast: IToastComp) => {
    setStore((store) => ({
      ...store,
      toast: toast,
    }));
  };

  const setSelectedTickets = (selectedTicket: IAddTicket[]) => {
    setStore((store) => ({
      ...store,
      selectedTicket,
    }));
  };

  const setTaxationDetails = (taxationDetails: IPendingTaxation) =>
    setStore((store) => ({ ...store, taxationDetails }));

  const setCompanyDetails = (companyDetails: TCompanyDetails) =>
    setStore((store) => ({ ...store, companyDetails }));

  const setBuyerBasicDetail = (buyerBasicDetail: TBuyerBasicDetail) =>
    setStore((store) => ({ ...store, buyerBasicDetail }));

  const setWidgetData = (widgetData: IMainComponentStandaloneProps | undefined) =>
    setStore((store) => ({ ...store, widgetData }));

  const setStripeAccountId = (stripeAccountId: string | null) =>
    setStore((store) => ({ ...store, stripeAccountId }));

  const setOtherPaymentDetails = (otherPaymentDetails: IOtherPaymentDetail) =>
    setStore((store) => ({ ...store, otherPaymentDetails }));

  const setOtherPaymentModal = (otherPaymentModal: IOtherPaymentModal) =>
    setStore((store) => ({ ...store, otherPaymentModal }));

  const setTransactionId = (transactionId: any) => setStore(store => ({ ...store, transactionId }));

  const setPaymentStatusDetails = (paymentStatusDetails: IVerifyPaymentStatusResponse) =>
    setStore((store) => ({ ...store, paymentStatusDetails }));

  const setUserData = (userDataLandingPage: IAuthEmailResponse) =>
    setStore((store) => ({ ...store, userDataLandingPage }));
  const setLandingPageData = (landingPageData: any) =>
    setStore((store) => ({ ...store, landingPageData }));

  const setConfigData = (configData: any) =>
    setStore((store) => ({
      ...store,
      configData: {
        ...store.configData,
        ...configData
      }
    }));

  const setActiveModalData = (activeModalData: IActiveModalData | null) =>
    setStore((store) => ({ ...store, activeModalData }));

  const setWebinarModalOpen = (isWebinarModalOpen: boolean) =>
    setStore((store) => ({ ...store, isWebinarModalOpen }));

  return (
    <StoreContext.Provider
      value={{
        store,
        setPageState,
        setDiscount,
        setAttendeeFormRequest,
        setWebinarFields,
        setBuyerFields,
        setTicketList,
        setTicketStore,
        setTotalAmount,
        setTotalAmountSymbol,
        setBuyerEmail,
        setOrderNo,
        setOrderToken,
        setStartFromSummary,
        setBuyerRegisteredAsAttendee,
        setIsBuyerFrom,
        setPaymentDetails,
        setSupportedLanguages,
        setToast,
        setTaxationDetails,
        setCompanyDetails,
        setSelectedTickets,
        setRegistrationToggle,
        setHideFreeLabel,
        setBuyerBasicDetail,
        setWidgetData,
        setStripeAccountId,
        setOtherPaymentDetails,
        setOtherPaymentModal,
        setTransactionId,
        setPaymentStatusDetails,
        setUserData,
        setLandingPageData,
        setConfigData,
        setActiveModalData,
        setWebinarModalOpen
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

const useStore = () => useContext(StoreContext);

export { StoreProvider, useStore };
