import { useState, useCallback } from "react";
import { postApi } from "../Core/HttpClient";
import { IPendingFieldDetails } from "../Components/AttendeesDetails/types";
import { IDiscountItem } from "../Types/BuyerDetails";
import { CancelToken } from "axios";

interface TCustomHeader {
  authToken: string;
}

export interface TSuccess<T> {
  code?: string;
  message?: string;
  data?: T;
}

export interface IOptions {
  isProtected?: boolean;
  apiStore?: Map<string, () => void>;
  customHeaders?: TCustomHeader;
  token?: string;
}

export type NetworkResponse<T> = {
  readonly status: boolean;
  readonly error?: TSuccess<T>;
  readonly success?: TSuccess<T>;
};

export interface NetworkRequest<T> {
  url: string;
  data?: T;
  params?: Record<string, unknown>;
  cancelToken?: CancelToken;
  customHeaders?: TCustomHeader;
}

export interface IncompleteTransactionRequest {
  payload: {
    data: {
      token: string;
    };
  };
}

export interface IncompleteTransactionResponse {
  attendeeDetails?: IPendingFieldDetails[];
  buyerAlreadyRegisteredAsAttendee: boolean;
  buyerEmail: string;
  canBuyerRegisterAsAttendee: boolean;
  currency: { id: number; symbol: string };
  invalidTicket?: boolean;
  orderNo: string;
  orderToken: string;
  step: string; // this indicates user left step and also use to recheck the step: ["SUMMARY", "BUYERS", "ATTENDEE"]
  status: string; // this indicates transaction Status: ["PENDING", "COMPLETE", "CANCEL"]
  summary?: IDiscountItem[];
  taxation?: {
    isTaxationExists?: boolean;
    enableBuyerTaxId?: boolean;
    isCheckTaxation?: boolean;
    companyDetails?: {
      name: string;
      taxId?: string;
      address: string;
      country: string;
      state: string;
      city: string;
      zipCode: string;
    };
  };
}

const useGetIncompleteTransaction = () => {
  const [data, setData] = useState<IncompleteTransactionResponse>(
    {} as IncompleteTransactionResponse
  );
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  const InCompleteTransactionAsync = useCallback((headers, input?: any) => {
    let didCancel = false;
    // if (!getParameterByName<string>("token")) {
    //   return false;
    // }
    async function fetchApi() {
      try {
        const response = await postApi<
          IncompleteTransactionResponse,
          IncompleteTransactionRequest
        >("landing-page/ticket/incomplete-transaction", headers, input);
        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as IncompleteTransactionResponse);
          setCode(response?.success?.code as string);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, []);

  return { data, code, loading, InCompleteTransactionAsync, error, setLoading };
};

export default useGetIncompleteTransaction;
