// Language: Catalan

export default {
  "already-registered": "Ja registrat?",
  "add-to-calendar": "Afegeix al calendari",
  "event-starts-in": "L'esdeveniment comença a",
  "event-is-live": "L'ESDEVENIMENT ESTÀ EN DIRECTE",
  "event-has-ended": "L'ESDEVENIMENT HA ACABAT",
  "powered-by": "Impulsat per",
  "please-enter-your-email-to-continue":
    "Introduïu el vostre correu electrònic per continuar",
  add: "Afegeix",
  offers: "Ofertes",
  free: "Gratuït",
  "available-till": "Disponible fins {{DATE}}",
  "enter-email-address": "Introduïu l'adreça de correu",
  "sold-out": "Venut",
  "the-ticket-has-reached-the-maximum-number": "El bitllet ha arribat al màxim",
  "seat-left": "{{SEAT}} seient esquerre",
  "seats-left": "{{SEAT}} seients restants",
  proceed: "Continuar",
  summary: "Resum",
  "buyer-info": "Informació del comprador",
  "attendees-info": "Informació assistents",
  continue: "Continua",
  "attendees-information": "Informació dels assistents",
  submit: "Presentar",
  "register-to-join-the-events": "Registra't per unir-te als esdeveniments",
  "by-clicking-on-the-button":
    "En fer clic al botó, se us redirigirà a la plataforma de venda d'entrades on us podeu registrar per a aquest esdeveniment.",
  other: "Altres",
  "please-enter-a-value-for": "Introduïu un valor per a <FIELD_NAME>",
  "please-select-a-value-for": "Seleccioneu un valor per a <FIELD_NAME>",
  "enter-other-value-for": "Introduïu un altre valor per a <FIELD_NAME>",
  "enter-a-valid": "Introduïu un vàlid <FIELD_NAME>",
  "should-be-at-least":
    "<FIELD_NAME> hauria de ser almenys <FIELD_LENGTH> <FIELD_TYPE>",
  "cannot-be-more-than":
    "<FIELD_NAME> no pot ser més que <FIELD_LENGTH> <FIELD_TYPE>",
  "is-required": "<FIELD_NAME> es requereix",
  "at-least-required": "At least <FIELD_LENGTH> <FIELD_NAME> <VERB> required",
  "maximum-allowed": "Maximum <FIELD_LENGTH> <FIELD_NAME> <VERB> allowed",
  "user-with-same-email-id-already-exists":
    "L'usuari amb el mateix identificador de correu electrònic ja existeix. Si us plau, utilitzeu-ne un de diferent.",
  "user-with-same-email-id-has-already-registered-for-this-event":
    "L'usuari amb el mateix identificador de correu electrònic ja s'ha registrat per a aquest esdeveniment. Si us plau, utilitzeu-ne un de diferent.",
  "mail-successfully-sent": "Correu enviat correctament!",
  "we-are-unable-to-deliver-your-mail-at-this-moment":
    "No podem lliurar el vostre correu en aquest moment! Si us plau, prova-ho després d'un temps",
  "something-went-wrong":
    "Alguna cosa ha anat malament. Estem arreglant això. Lamentem les molèsties ocasionades.",
  "register-now": "Registra't ara",
  registrationSuccess: "Registre fet amb èxit!",
  "add-to-your-calendar": "Afegeix al calendari",
  "please-select-a-ticket-to-proceed": "Seleccioneu un bitllet per continuar",
  "resend-link": "Torna a enviar l'enllaç",
  "go-to-event": "Vés a Esdeveniment",
  "ticket-registration-done-by": "Inscripció d'entrades feta per",
  "you-have-already-purchased-the-ticket-for": "Ja has comprat l'entrada per",
  purchased: "Comprat",
  "event-date": "Data de l'esdeveniment",
  "ticket-quantity": "Quantitat de bitllets",
  payment: "Pagament",
  "unable-to-submit-data": "No es poden enviar dades",
  "unable-to-apply-promo-code": "No es pot aplicar el codi promocional",
  "invalid-promo-code": "Codi promocional no vàlid",
  "apply-now": "Aplicar",
  "enter-discount-coupon": "Introduïu el cupó de descompte",
  "same-as-buyer": "Igual que el comprador",
  "please-check-all-the-fields-are-correctly-filled":
    "Si us plau, comproveu que tots els camps estiguin emplenats correctament",
  "ticket-purchased": "Entrada comprada!",
  "ticket-purchase-failed": "La compra d'entrades ha fallat!",
  "you-have-successfully-purchased-the-ticket":
    "Heu comprat el bitllet correctament. Aquesta entrada us permetrà assistir a l'acte. Gràcies per la seva compra!",
  "there-was-an-error-while-booking-your-tickets":
    "S'ha produït un error en reservar les entrades. Siusplau torna-ho a provar.",
  "try-again": "Torna-ho a provar",
  "you-dont-have-access": "No tens accés per comprar algunes de les entrades",
  "please-enter-a-valid-email": "Introduïu un correu electrònic vàlid",
  "please-enter-email-to-continue":
    "Introduïu el correu electrònic per continuar",
  "payment-summary": "Resum de pagament",
  // [HUB-8587] Added multiple language support for Promo Code Success message
  "apply-discount":
    "{{CODE}} successfully applied. You saved additional {{AMOUNT}}.",
  // [HUB-8597] Added multiple language support for Buyer Taxation Support
  and: "i",
  "taxation-concent": "Vols generar una factura fiscal?",
  "taxation-tnc-pp": "En continuar en, acceptes el nostre",
  "taxation-tnc": "Termes i condicions",
  "taxation-pp": "Política de privacitat",
  "company-name-label": "Nom de la companyia",
  "company-name-place-holder": "Introduïu el nom de l'empresa",
  "taxId-label": "NIF fiscal de l'empresa",
  "taxId-place-holder": "Introduïu el NIF",
  "company-address-label": "adreça de l'empresa",
  "company-address-place-holder": "adreça",
  "country-place-holder": "Selecciona país",
  "state-place-holder": "Seleccioneu Estat",
  "city-place-holder": "Entra a Ciutat",
  "zipcode-label": "Codi ZIP",
  "zipcode-place-holder": "Introduïu el codi postal",
  "company-error-msg": "Introduïu el nom de l'empresa",
  "taxId-error-msg": "Introduïu el número d'identificació fiscal de l'empresa",
  "address-error-msg": "Introduïu l'adreça",
  "country-error-msg": "Seleccioneu el país",
  "state-error-msg": "Seleccioneu Estat",
  "city-error-msg": "Introduïu Ciutat",
  "zipcode-error-msg": "Introduïu el codi postal",
  // [HUB-8579] Added multiple language support for Buyer Default Fields
  firstName: "Nom",
  lastName: "Cognom",
  email: "Correu electrònic",
  phone: "Telèfon",
  dialerCode: "Codi de marcatge",
  // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
  "you-have-already-purchased-ticket-for": "Ja has comprat l'entrada per",
  "browse-a-file-to-upload": "Exploreu un fitxer per carregar",
  "upload-file": "Penja el document",
  "max-size": "Mida màxima",
  "file-supported-types": "Tipus de fitxers compatibles",
  "uploaded-successfully": "S'ha penjat correctament",
  "failed!-please-retry": "Fracassat! Si us plau, torna-ho a provar",
  "this-file-type-is-not-supported-supported-attachments-are":
    "Aquest tipus de fitxer no és compatible. Els fitxers adjunts admesos són",
  "the-file-size-is-too-high-please-upload-document-lesser-than":
    "La mida del fitxer és massa alta. Si us plau, carregueu el document menys de",
  person: "Persona ",
  select: "Seleccioneu",
  search: "Cerca",
  "uploaded-file": "Fitxer carregat",
  uploading: "S'està carregant",
  delete: "Suprimeix",
  "no-item": "Cap element",
  // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
  "read-more": "Llegeix més",
  "read-less": "Llegeix menys",
  "about-title": "Sobre",
  // [HUB-9217] On buyer form and attendee form for group field in validation message "is" text is coming
  "google-calendar": "Google Calendar",
  "outlook-calendar": "Calendari d'Outlook",
  "yahoo-calendar": "Calendari de Yahoo",
  "verb-are": "són",
  "verb-is": "és",
  character: "personatge",
  // [HUB-9250] Date format translation
  "date-format": "DD/MM/AAAA",
  "timer-hr": "hr",
  "timer-hrs": "hrs",
  "timer-min": "min",
  "timer-mins": "minuts",
  "timer-sec": "sec",
  "timer-secs": "segons",
  "timer-day": "dia",
  "timer-days": "dies",
  // [HUB-9352] On Hover of file upload button content shown in english language only
  "select-file-title": "No s'ha seleccionat cap fitxer",
  today: "Avui",
  yesterday: "Ahir",
  tomorrow: "Demà",
  last: "Darrer",
  "internet-down": "Internet sembla desconnectat!",
  "internet-up": "Internet ara està connectat",
  "network-issue":
    "S'ha produït algun problema de xarxa missatge d'error, si us plau, torna-ho a provar!",
  registrationSuccessDetail:
    "Us heu registrat correctament a l'esdeveniment. Gràcies!",
  "search-here": "Cerca aquí..",
  "did-you-mean": "Vols dir ",
  DISPOSABLE_EMAIL: "L'adreça de correu electrònic donada pertany a un servei de correu electrònic d'un sol ús",
  RISTRICTED_DOMAIN: "L'adreça de correu electrònic està dins d'un domini restringit. Si us plau, introdueix una adreça de correu electrònic vàlida",
  INVALID_EMAIL: "Introduïu un correu electrònic vàlid",

  // New Keys
  CHOOSE_PAYMENT_METHOD: "Tria la forma de pagament",
  PAY_NOW: "Pagar ara",
  INSTANT_TICKET_CONFIRMATION: "Confirmació immediata del bitllet",
  PAY_VIA_OTHER_METHODS: "Pagament mitjançant altres mètodes",
  FUNDS_TRANSFER: "Transferència De Fons",
  DIGITAL_WALLET: "Cartera Digital",
  CHEQUE: "Xec",
  PAY_AT_VENUE: "Pagament Al Lloc",
  TICKET_NOT_CONFIRMED_ORGANIZER: "Les entrades no es confirmaran fins que l'organitzador de l'esdeveniment aprovi que s'ha rebut el pagament i estiguin subjectes a cancel * lació.",
  TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Les entrades no es confirmaran fins que no s'efectuï el pagament al lloc de l'esdeveniment i estiguin subjectes a cancel * lació.",
  TICKET_CONFIRMED_BY_ORGANIZER: "Les entrades es confirmaran quan l'organitzador de l'esdeveniment aprovi que s'ha rebut el pagament.",
  COMPLETE_PAYMENT_OF: "Pagament complet de",
  COMPLETE_PAYMENT_TICKET: "Completa el pagament de la teva entrada(s).",
  PAYMENT_CONFIRMATION_TITLE: "Introduïu l'IDENTIFICADOR De Transacció únic per a l'organitzador de l'esdeveniment per verificar el pagament.",
  ENTER_TRANSACTION_ID: "Introduïu L'IDENTIFICADOR De Transacció",
  SHARE_DETAILS_TITLE: "L'organitzador de l'esdeveniment només pot aprovar el pagament si comparteix els detalls. El seu bitllet(s), incloent qualsevol bitllet(s) gratuït (s), serà confirmat després de la seva aprovació.",
  UPLOAD_SCREENSHOT: "Captura De Pantalla (opcional)",
  SHARE_PAYMENT_DETAILS: "Compartir Dades De Pagament",
  TICKET_STATUS: "Estat Del Bitllet", // not found
  ORGANIZER_YET_TO_CONFIRM: "L'organitzador de l'esdeveniment encara no ha confirmat que s'ha rebut el pagament.",
  TICKET_CONFIRMATION_ORGANIZER: "La seva entrada(s), incloent qualsevol entrada(s) gratuïta (s), i la factura seran confirmades i compartides amb vostè després de l'aprovació de l'organitzador de l'esdeveniment.",
  PAYMENT_DETAILS: "Detalls Del Pagament",
  FAQ_TITLE: "En cas de qualsevol pregunta(s), consulteu Les Preguntes Freqüents",
  MAKE_PAYMENT_TITLE: "Fes el pagament al lloc de l'esdeveniment per confirmar la teva compra d'entrades.",
  CONFIRM_TICKET_PRIOR_EVENT: "Si vols confirmar la teva entrada / es abans de l'esdeveniment, paga ara amb un mètode diferent.", // need to handle differently 
  CONFIRM_TICKETS_PRIOR_EVENT: "Si desitja confirmar la seva entrada(s) abans de l'esdeveniment,",
  PAY_NOW_2: "pagar ara",
  WITH_DIFFERENT_METHOD: "amb un mètode diferent",
  PAYMENT_NOT_RCVD: "L'organitzador de l'esdeveniment no ha rebut el pagament.",
  PAYMENT_COULD_NOT_BE_VERIFIED: "Les dades de pagament compartides no han pogut ser verificades per l'organitzador de l'esdeveniment.",
  TICKETS_NOT_AVAILABLE: "Les entrades ja no estan disponibles per a l'esdeveniment.",
  PAYMENT_DETAILS_YET_TO_SHARE: "Encara heu de compartir les dades de pagament del bitllet(s).", // not found in code
  WELCOME_BACK: "Benvingut de nou!",
  VIEW_OR_PROCEED_PURCHASE: "Pots consultar les teves entrades anteriors o procedir a la compra de les entrades que acabes de seleccionar.",
  VIEW_PREV_TICKETS: "Veure Entrades Anteriors",
  PROCEED_TO_PURCHASE: "Procedir A La Compra",
  PREVIOUS_TICKETS: "Entrades Anteriors",
  PENDING_TICKETS: "Entrades Pendents",
  CONFIRMED_TICKETS: "Entrades Confirmades",
  PAYMENT_PENDING: "Pagament pendent",
  AWAITING_ORG_APPROVAL: "Esperant l'aprovació de l'organitzador",
  CHECK_TICKET_STATUS: "Comprovar L'Estat De L'Entrada",
  PAYMENT_METHOD_NOT_AVAILABLE: "mètode de pagament no disponible, si us plau intenti de nou.", // not found in code
  HERE: "té.",
  // [HUB-25026] Multiple Ticket Purchase for Attendees
  RE_ENTER_EMAIL: "Si us plau, actualitzeu la informació única de l'assistent de totes les entrades comprades. Això permetrà a tots els assistents accedir a l'esdeveniment.",
  DUPLICATE_EMAIL: "Ja has comprat aquesta entrada. Pots optar per tornar a registrar-te. En aquest cas, es desaran les dades més recents introduïdes.",
  DEFAULT_DUPLICATE_EMAIL: "Aquest correu electrònic ja s'utilitza per a un altre bitllet. Si us plau, comproveu l'adreça electrònica que heu introduït o no dubteu a utilitzar el mateix correu electrònic per a diverses entrades.",
  "REGISTER_NOW": "Registra't ara",
  "REG_FAILED": "El registre ha fallat",
  "REG_FAILED_DESC": "El vostre registre ha fallat. Si us plau torna-ho a provar.",
  "REG_DONE_BY": "Inscripció feta per",
  "INVITE_AGAIN": "Vols la invitació de nou?",
  "REG_SUCCESS_DONE": "Registre fet correctament",
  "WATCH_REC_LINK": "S'ha enviat un correu electrònic amb un enllaç per veure la gravació",
  "WEBINAR_INV_LINK": "S'ha enviat un correu electrònic d'invitació amb l'enllaç del seminari web a",
  "MAIL_SENT_SUCCESS": "Correu enviat correctament",
  "ALREADY_REGISTERED": "Ja us heu inscrit a",
  "RESEND_INVITE": "Torna a enviar la invitació",
  "WATCH_RECORDING_REGISTER_DESC": "Podeu veure la gravació del seminari web registrant-vos a continuació.",

};
