/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";

import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import { usePreviousDistinct } from "react-use";
import TicketInfo from "./Components/TicketInfo";
import BuyerDetails from "./Components/BuyerDetails";
import AttendeesDetails from "./Components/AttendeesDetails";
import TicketSummary from "./Components/TicketSummary";
import { ITicketAndDiscountData, ITicketInfoProps } from ".";
import { useStore } from "./Context";
import { PageState } from "./Constants/AppConstants";
import useGetTickets from "./Hooks/useGetTickets";
import { IHeadersRequest, ITicketDetailsResponse } from "./Types/Ticket";
import { IApplyDiscount } from "./Types/ApplyDiscount";
import Toast from "./Components/Toast";
import isEmpty from "lodash/isEmpty";
import {
  addPreEventAnalytics,
  AppType,
  trackEventPixelsByApp,
} from "Utils/trackingPixels";
import Track from "./Lib/ThirdParty/amplitude";
import usePaymentVerify from "Hooks/usePaymentVerify";
import { IPaymentStatus, IPaymentStatusRequest } from "Types/VerifyPayment";
import {
  getParameterByName,
  getPathName,
  getUTMUrlPath,
  stripQueryParams,
} from "Utils/common";
import {
  IDiscountItem,
  IPendingTaxation,
  IPendingTransactionRequest,
  TCompanyDetails,
} from "Types/BuyerDetails";
import useGetIncompleteTransaction, {
  IncompleteTransactionResponse,
} from "Hooks/useGetIncompleteTransaction";
import get from "lodash/get";
import { ITicketData } from "Types/LandingPage";
import { updateArrayBySubArray } from "./Components/TicketInfo/helper";
// import { Typography } from "@mui/material";
// import Ticket404 from "Assets/Svg/Ticket404";
import { useTranslation } from "react-i18next";
import { isAndroidIPhoneIPad, isAndroidIPhone } from "Utils/navigator";
import CustomModal from "./Components/CustomModal";
import PaymentMethod from "./Components/PaymentMethod/index";
import OtherPaymentModal from "./Components/OffLinePayment/index";
import FAQModal from "./Components/OffLinePayment/FaqModal";
import { faqList } from "./Components/OffLinePayment/OrderStatus";
import WebinarRegistration from "./Components/WebinarRegistration";

type IAppProps = ITicketInfoProps &
  ITicketAndDiscountData & {
    onClose?: () => void;
    containerWidth?: number;
    setKeepMounted: () => void;
    onOpen?: () => void;
    purchase: boolean;
    microFeEventHandler?: () => void;
  };

type IAppWithTicketSummaryProps = Omit<
  IAppProps,
  "ticketData" | "discountData"
> & {
  ticketData: ITicketDetailsResponse;
  discountData: IApplyDiscount;
  containerWidth?: number;
  activeTicketId?: number;
  onClose?: () => void;
  setKeepMounted: () => void;
  userData: any;
  purchase: boolean;
  microFeEventHandler?: () => void;
};

type I_AppProps = ITicketInfoProps & {
  ticketData: ITicketDetailsResponse;
  containerWidth?: number;
  activeTicketId?: number;
  setKeepMounted: () => void;
  userData: any;
  onOpen?: () => void;
  onClose?: () => void;
  purchase: boolean;
  microFeEventHandler?: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  containerWidget: {
    margin: "0 auto",
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider.default}`,
    borderRadius: theme.spacing(1),
    background: theme.palette.background.lightGray,
    "@media(max-width:1024px)": {
      width: "80%",
    },
    "@media(max-width:768px)": {
      width: "90%",
    },
    "@media(max-width:640px)": {
      width: "100%",
      border: "none",
      borderRadius: 0,
    },
  },
  widgetWrapperShape: {
    position: "relative",
    "&::before": {
      content: "''",
      width: 12,
      height: 21.4,
      backgroundColor: theme.palette.background.cardColor,
      borderRadius: "50%",
      position: "absolute",
      left: -1,
      top: "9.6rem",
      border: `1px solid ${theme.palette.divider.default}`,
      borderTopRightRadius: 100,
      borderBottomRightRadius: 100,
      borderLeft: 0,
    },
    "&::after": {
      content: "''",
      width: 12,
      height: 21.4,
      backgroundColor: theme.palette.background.cardColor,
      borderRadius: "50%",
      position: "absolute",
      right: -1,
      top: "9.6rem",
      border: `1px solid ${theme.palette.divider.default}`,
      borderTopLeftRadius: 100,
      borderBottomLeftRadius: 100,
      borderRight: 0,
    },
  },
  regWrapperShape: {
    "&::before": {
      display: "none",
    },
    "&::after": {
      display: "none",
    },
  },
  singleViewContainerWidget: {
    padding: "20px 12px 20px 20px",
    "@media(max-width:420px)": {
      padding: "20px",
    },
    "&.singleViewTicketSummaryStart": {
      padding: 0,
      overflow: "hidden",
      border: "none",
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
      "& .singleBuyerWidgetFormButton": {
        "& .continueBtn": {
          "&>button": {
            marginBottom: 10,
          },
        },
      },
      "& .singleViewWidgetAttendeeButton": {
        "&>button": {
          marginBottom: 10,
        },
      },
    },

    "& .singleBuyerWidgetDetails": {
      backgroundColor: "transparent",
      padding: "0px !important",
      "& .singleBuyerWidgetFormButton": {
        "& .continueBtn": {
          bottom:"0.32rem",
          width: "calc(100% - 10px)",
          background: `linear-gradient(to top, ${theme.palette.background.cardColor}, ${theme.palette.background.lightGray})`,
          "&>button": {
            width: "100%",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
    },
    "& .ticketSummryWidgetList": {
      backgroundColor: "transparent",
      padding: "0px !important",
    },
    "& .widgetWithoutEventDetails": {
      marginTop: `${theme.spacing(2)} !important`,
    },
    "& .singleViewWidgetDetails": {
      backgroundColor: "transparent",
      padding: "0px !important",
      "& .singleViewWidgetAttendeeButton": {
        background: `linear-gradient(to top, ${theme.palette.background.cardColor}, ${theme.palette.background.lightGray})`,
        width: "calc(100% - 10px)",
        bottom: "0.32rem",

        "&>button": {
          width: "100%",
          "&:hover": {
            boxShadow: "none",
          },
        },
      },
    },
    // "&::before": {
    //   content: "''",
    //   width: 12,
    //   height: 21.4,
    //   backgroundColor: theme.palette.background.cardColor,
    //   borderRadius: "50%",
    //   position: "absolute",
    //   left: -1,
    //   top: "6rem",
    //   border: `1px solid ${theme.palette.divider.default}`,
    //   borderTopRightRadius: 100,
    //   borderBottomRightRadius: 100,
    //   borderLeft: 0,
    // },
    // "&::after": {
    //   content: "''",
    //   width: 12,
    //   height: 21.4,
    //   backgroundColor: theme.palette.background.cardColor,
    //   borderRadius: "50%",
    //   position: "absolute",
    //   right: -1,
    //   top: "6rem",
    //   border: `1px solid ${theme.palette.divider.default}`,
    //   borderTopLeftRadius: 100,
    //   borderBottomLeftRadius: 100,
    //   borderRight: 0,
    // },
    "& .singleViewWidgetInfo": {
      background: theme.palette.background.lightGray,
      overflowY: "auto",
      paddingRight: theme.spacing(1.3),
      paddingLeft: theme.spacing(0),
      "@media(max-width:640px)": {
        paddingRight: 0,
      },
      "& .widgetTicketInfoDesc": {
        width: "100%",
        top: 0,
        right: 0,
        position: "relative",
        marginBottom: theme.spacing(2),
        "&.dNone": {
          display: "none",
        },
      },
    },
    "& .singleViewWidgetTicketList": {
      padding: theme.spacing(0),
      height: "auto",
      borderRadius: theme.spacing(0),
    },
    "& .singleViewSummryWidget": {
      display: "block",
      width: "auto",
      padding: "0rem",
      paddingRight: "10px",
      height: "100%",
      position: "relative",
      overflowY: "auto",

      "& .ticketSummryWidgetList": {
        width: "100%",
        border: "none",
        padding: "1.6rem 0.8rem 1.6rem 1.6rem",
        borderRadius: 0,
      },
      "& .mobFooterStyle": {
        position: "relative",
        width: "100%",
        backgroundColor: theme.palette.background.cardColor,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: `1px solid ${theme.palette.divider.default}`,
      },
      "& .scrollForWidget": {
        minHeight: "100%",
        maxHeight: "100%",
        paddingRight: 0,
      },
    },
    "& .widgetRegCloseSingleView": {
      left: 0,
      top: 0,
      background: "transparent",
    },
    "& .singleViewWrapperWidget": {
      display: "block",
      width: "100%",
      "& .ticketWrapperInnerWidget": {
        width: "100%",
        maxHeight: "100%",
        minHeight: "0",
        background: "none",
        overflowY: "hidden",
        border: "none",
        padding: 0,
        "& .ticketPrice": {
          "&::before": {
            background: `${theme.palette.background.lightGray} !important`,
          },
          "&::after": {
            background: `${theme.palette.background.lightGray} !important`,
          },
        },
      },
      "& .inputEmailWrapperWidget": {
        width: "100%",
        height: "auto",
        position: "relative",
        marginTop: theme.spacing(2),
        background: theme.palette.background.cardColor,
        border: `1px solid ${theme.palette.divider.default}`,
        borderRadius: "0rem",
      },
    },
  },
}));

let initialLoad = false;

function _App(props: I_AppProps) {
  // Query Params
  const getPaymentId = getParameterByName<string>("orderid");
  const getPaymentStatus = getParameterByName<string>("payment");
  const {
    store,
    setSupportedLanguages,
    setPaymentDetails,
    setOrderNo,
    setOrderToken,
    setBuyerEmail,
    setTaxationDetails,
    setTicketList,
    setSelectedTickets,
    setCompanyDetails,
    setToast,
    setBuyerRegisteredAsAttendee,
    setPageState,
    setUserData,
  } = useStore();
  const [t] = useTranslation();
  const {
    data: paymentVerifyData,
    error: paymentVerifyError,
    paymentVerify,
    loading: paymentVerifyLoading,
  } = usePaymentVerify();
  const { data, code, error, loading, InCompleteTransactionAsync } =
    useGetIncompleteTransaction();
  const [getToken, setToken] = useState("");
  const [fetchStep, setStep] = useState<string>("");
  const { configData, eventInfo, ticketData, userData } = props;
  const { paymentDetails } = store;
  useEffect(() => {
    setUserData(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // refs
  const refOrderId = useRef<string>();
  const tickets = ticketData.tickets;
  useEffect(() => {
    setSupportedLanguages(eventInfo?.supportedLanguages || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo]);

  const getPaymentDetails = (id: string, status: string): void => {
    const isOperate = id && status;
    if (isOperate) {
      const payload: IPaymentStatus = {
        orderToken: id,
        paymentStatus: status,
      };

      paymentVerify(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: { ...payload },
          },
        } as IPaymentStatusRequest
      );
    }
  };

  const resetRoute = (isSuccess: boolean) => {
    let updateRoute = "";
    const utmSearchStr = getUTMUrlPath();
    if (props.eventInfo?.isCustomHosting) {
      const current = new URL(window.location.href);
      current.searchParams.delete("payment");
      current.searchParams.delete("token");
      current.searchParams.delete("orderid");
      updateRoute = current.toString();
      // updateRoute = `${window.location.protocol}//${window.location.host}/register`;
    }
    else if (store?.widgetData?.isWidgetExpand) updateRoute = stripQueryParams(props.configData?.communityUrl as string);
    else updateRoute = getPathName(props.configData?.communityUrl as string);
    if (updateRoute) {
      window.history.replaceState(
        null,
        "",
        `${updateRoute}${isSuccess ? `${utmSearchStr ? "&" : "?"}success=${getPaymentId}` : utmSearchStr}`
      );
    }
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const handlePendingSummary = (details: IncompleteTransactionResponse) => {
    const {
      orderToken,
      summary = [],
      buyerEmail = "",
      currency,
      taxation,
      orderNo,
    } = details;

    if (orderNo) {
      setOrderNo(orderNo);
    }

    if (orderToken) {
      setOrderToken(orderToken);
    }
    if (buyerEmail) {
      setBuyerEmail(buyerEmail);
    }

    const currencySymbol = get(currency, "symbol", "");
    if (currencySymbol) {
      setPaymentDetails({
        ...paymentDetails,
        paidTicketDtls: {
          ...paymentDetails.paidTicketDtls,
          currency: currencySymbol,
        },
      });
    }

    if (taxation) {
      const {
        isTaxationExists,
        enableBuyerTaxId,
        isCheckTaxation,
        companyDetails,
      }: any = taxation;
      let updateTaxation: IPendingTaxation = {};
      if (isTaxationExists)
        updateTaxation = { ...updateTaxation, isTaxationExists: true };
      if (enableBuyerTaxId)
        updateTaxation = { ...updateTaxation, enableBuyerTaxId: true };
      if (isCheckTaxation)
        updateTaxation = { ...updateTaxation, isCheckTaxation: true };
      if (Object.keys(updateTaxation).length > 0) {
        setTaxationDetails(updateTaxation);
      }
      if (
        companyDetails &&
        typeof companyDetails === "object" &&
        Object.keys(companyDetails).length
      ) {
        let generateCompanyDetails: TCompanyDetails = {};
        for (const id in companyDetails) {
          if (
            [
              "name",
              "taxId",
              "address",
              "country",
              "state",
              "city",
              "zipCode",
            ].includes(id)
          ) {
            generateCompanyDetails = {
              ...generateCompanyDetails,
              [id]: companyDetails[id],
            };
          }
        }
        // dispatch(updateIncompleteCompanyDetails(generateCompanyDetails));
        setCompanyDetails(generateCompanyDetails);
      }
    }
    if (summary && Array.isArray(summary) && summary.length) {
      const updateQtyTickets = updateArrayBySubArray<
        ITicketData,
        IDiscountItem
      >(tickets, summary, "id", "quantity", "selectedQuantity");

      const updatePromoTickets = updateArrayBySubArray<
        ITicketData,
        IDiscountItem
      >(updateQtyTickets, summary, "id", "promoCode", "promoCode");

      const fetchTickets = updateArrayBySubArray<ITicketData, IDiscountItem>(
        updatePromoTickets,
        summary,
        "id",
        "discount",
        "promoCodeAmount"
      );
      const paidTicketPresent =
        fetchTickets &&
        fetchTickets?.find(
          (data) => !!data.selectedQuantity && data.ticketFee > 0
        );
      if (fetchTickets && Array.isArray(fetchTickets) && fetchTickets.length) {
        setTicketList(fetchTickets);
        setSelectedTickets(
          summary.map((item) => {
            return {
              ids: item.id,
              quantity: item.quantity,
              isApplied: false,
              isComplete:
                !store?.registrationToggle && !paidTicketPresent ? true : false,
            };
          })
        );
      }
    } else {
      handleToastError("Something went wrong");
    }
  };

  const handlePendingAttendeeDetails = (
    details: IncompleteTransactionResponse
  ) => {
    handlePendingSummary(details);
    const { attendeeDetails = [] } = details;

    if (
      attendeeDetails &&
      Array.isArray(attendeeDetails) &&
      attendeeDetails.length
    ) {
      // dispatch(
      //     setAttendeeDetails(
      //         attendeeDetails.map((attendeeDetail) => ({
      //             ...attendeeDetail,
      //             formFields: addSomePropertiesInField(attendeeDetail.formFields),
      //         })),
      //     ),
      // );
    }
  };

  useEffect(() => {
    const isPaymentExists = getPaymentId && getPaymentStatus;
    if (isPaymentExists) {
      refOrderId.current = String(getPaymentId);

      setPaymentDetails({
        isPurchase: true,
        currPaymentStatus: getPaymentStatus,
      });
      getPaymentDetails(getPaymentId, getPaymentStatus);
    }
    return () => {
      // resetToken();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const captureFailedRegEvent = () => {
    const stepsToTrack = props.eventInfo?.stepsToTrack;
    const trackingPixelApps = props.eventInfo?.trackingPixelApps;
    // Step 3: 'Failed Registration'
    const failedEvent = stepsToTrack?.find((eachStep) => eachStep.id === 3);
    if (failedEvent?.isEnabled) {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(trackingPixelApps) &&
        trackingPixelApps
          ?.filter((eachApp) => eachApp.isEnabled)
          .forEach((eachItem: { app: AppType }) => {
            trackEventPixelsByApp(
              eachItem.app,
              failedEvent.eventName as unknown as string
            );
          });
    }
  };

  const trackPurchasedTicket = async (amplitude: any) => {
    Track.purchasedTicket({
      isWidget: store?.widgetData?.isWidgetExpand,
      ...amplitude,
    });
  };

  useEffect(() => {
    const stepsToTrack = props.eventInfo?.stepsToTrack;
    const trackingPixelApps = props.eventInfo?.trackingPixelApps;
    if (!isEmpty(paymentVerifyData)) {
      resetRoute(getPaymentStatus === "CONFIRM");
      if (getPaymentStatus === "CONFIRM") {
        // Amplitude
        trackPurchasedTicket(paymentVerifyData.amplitude);
        // Step 2: 'Successful Registration'
        const successEvent = stepsToTrack?.find(
          (eachStep) => eachStep.id === 2
        );
        if (successEvent?.isEnabled) {
          // eslint-disable-next-line no-unused-expressions
          !isEmpty(trackingPixelApps) &&
            trackingPixelApps
              ?.filter((eachApp: { isEnabled: boolean }) => eachApp.isEnabled)
              .filter((eachItem: { app: AppType }) => {
                return trackEventPixelsByApp(
                  eachItem.app,
                  successEvent.eventName as unknown as string
                );
              });
        }
        setPaymentDetails({
          ...paymentDetails,
          paidTicketDtls: { ...paymentVerifyData },
        });
      } else {
        resetRoute(false);
        captureFailedRegEvent();
        setPaymentDetails({
          ...paymentDetails,
          paidTicketDtls: { ...paymentVerifyData },
        });
      }
    } else if (paymentVerifyError) {
      resetRoute(false);
      captureFailedRegEvent();
      setPaymentDetails({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentVerifyData, paymentVerifyError]);

  useEffect(() => {
    const isToken = getParameterByName<string>("token");
    if (!isEmpty(isToken)) {
      setToken(isToken || "");
    }
  }, []);

  useEffect(() => {
    const isCustomLP = props?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage || false;
    if (getToken && userData && !isCustomLP) {
      if (Object.keys(userData).length > 0) {
        handlePendingSummary(userData);
        if (userData?.case !== "PAID") setStep("SUMMARY");
      } else {
        if (props?.onClose) props?.onClose();
      }
      resetRoute(false);
    }
    if (getToken && !userData) {
      const payload: IPendingTransactionRequest = {
        token: getToken,
      };
      InCompleteTransactionAsync(
        {
          authorization: getToken,
          language: configData?.language,
        },
        {
          payload: {
            data: payload as IPendingTransactionRequest,
          },
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, userData]);

  useEffect(() => {
    if (data && !loading) resetRoute(false);
    if (data && !loading) {
      const { status, step, buyerAlreadyRegisteredAsAttendee, invalidTicket } =
        data;
      if (status === "PENDING") {
        if (invalidTicket) {
          handleToastError(code);
        }
        if (step && step === "SUMMARY") {
          handlePendingSummary(data);
        } else if (step && step === "BUYERS") {
          handlePendingSummary(data);
        } else if (step && step === "ATTENDEE") {
          handlePendingAttendeeDetails(data);
        }
        if (buyerAlreadyRegisteredAsAttendee) {
          setBuyerRegisteredAsAttendee(buyerAlreadyRegisteredAsAttendee);
        }
        setStep("SUMMARY");
      }
    } else if (error) {
      if (error === "network-issue") {
        handleToastError(t("network-issue"));
      } else {
        handleToastError(
          error?.data?.error?.data?.message || t("something-went-wrong")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    if (fetchStep === "SUMMARY") {
      if (setPageState) setPageState(PageState.TicketSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchStep]);
  return (
    <>
      {isAndroidIPhoneIPad() ? (
        store.pageState === PageState.TicketSummary ? (
          <TicketSummary {...props} />
        ) : store.pageState === PageState.BuyDetails &&
          !store.registrationToggle &&
          ((!store?.widgetData?.isWidgetExpand && isAndroidIPhoneIPad()) ||
            (!store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ||
            (store?.widgetData?.isWidgetExpand && isAndroidIPhone())) ? (
          <CustomModal
            open
            isOnlyChildren
            // offsetFromTop={store.startFromSummary ? 0 : 1}
            closeIcon={false}
          >
            <BuyerDetails {...props} />
          </CustomModal>
        ) : (store.pageState === PageState.AttendeeDetails ||
          store.registrationToggle) &&
          ((!store?.widgetData?.isWidgetExpand && isAndroidIPhoneIPad()) ||
            (!store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ||
            (store?.widgetData?.isWidgetExpand && isAndroidIPhone())) ? (
          <CustomModal
            open
            isOnlyChildren
            // offsetFromTop={store.startFromSummary ? 0 : 1}
            closeIcon={false}
          >
            {/* @ts-ignore */}
            <AttendeesDetails {...props} />
          </CustomModal>
        ) : (store.pageState === PageState.PaymentMethod ||
          store.registrationToggle) &&
          ((!store?.widgetData?.isWidgetExpand && isAndroidIPhoneIPad()) ||
            (!store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ||
            (store?.widgetData?.isWidgetExpand && isAndroidIPhone())) ? (
          <CustomModal
            open
            isOnlyChildren
            // offsetFromTop={store.startFromSummary ? 0 : 1}
            closeIcon={false}
          >
            <PaymentMethod {...props} />
          </CustomModal>
        ) : null
      ) : store.pageState === PageState.TicketSummary ? (
        <TicketSummary {...props} />
      ) : store.pageState === PageState.BuyDetails &&
        !store.registrationToggle ? (
        <BuyerDetails {...props} />
      ) : store.pageState === PageState.AttendeeDetails ||
        store.registrationToggle ? (
        // @ts-ignore;
        <AttendeesDetails {...props} />
      ) : store.pageState === PageState.PaymentMethod ||
        store.registrationToggle ? (
        <PaymentMethod {...props} />
      ) : null}
      {/* <Box>
        {!isEmpty(paymentDetails) && (
          <TicketPaymentStatus
            {...props}
            isLoading={paymentVerifyLoading}
            getPaymentId={refOrderId?.current}
          />
        )}
      </Box> */}
      <OtherPaymentModal
        {...props}
        isLoading={false}
        paymentVerifyLoading={paymentVerifyLoading}
      />
    </>
  );
}

function AppWithTicketSummary(props: IAppWithTicketSummaryProps) {
  const {
    store,
    setTicketList,
    setDiscount,
    setStartFromSummary,
    setOrderNo,
    setOrderToken,
    setBuyerEmail,
    setBuyerRegisteredAsAttendee,
    setIsBuyerFrom,
    setRegistrationToggle,
    setStripeAccountId,
    setPaymentDetails,
  } = useStore();
  const { ticketData, discountData, containerWidth, onClose } = props;
  const previousPageState = usePreviousDistinct(store.pageState);
  const [isTicketingMfeLoaded, setTicketingMfeLoaded] = useState(false);

  useEffect(() => {
    if (!isTicketingMfeLoaded) {
      const hasPaymentStatus = getParameterByName<string>("payment");
      const getTransactionToken = getParameterByName<string>("token");
      const ticketDataWithQuantity = ticketData.tickets.map((ticket) => {
        const chosenTicket = discountData.ticketsInfo.find(
          (chosenTicket) => chosenTicket.id === ticket.id
        );
        if (chosenTicket) {
          return {
            ...ticket,
            selectedQuantity: chosenTicket.quantity,
          };
        }
        return ticket;
      });
      setTicketList(ticketDataWithQuantity);
      const paidTicketPresent =
        ticketDataWithQuantity &&
        ticketDataWithQuantity?.find(
          (data) => !!data.selectedQuantity && data.ticketFee > 0
        );

      const summaryStep =
        hasPaymentStatus || getTransactionToken
          ? PageState.TicketInfo :
          !ticketData?.isSimpleRegistrationEnabled && paidTicketPresent
            ? PageState.TicketSummary
            : !ticketData?.isBuyerForm
              ? PageState.AttendeeDetails
              : PageState.BuyDetails;

      setDiscount(summaryStep, discountData);
      setOrderNo(discountData.orderNo);
      setOrderToken(discountData.orderToken);
      setBuyerRegisteredAsAttendee(discountData.buyerRegisteredAsAttendee);
      setIsBuyerFrom(ticketData.isBuyerForm);
      if (ticketData?.stripeAccountId) {
        setStripeAccountId(ticketData?.stripeAccountId);
      }
      if (discountData.buyerEmail) {
        setBuyerEmail(discountData.buyerEmail);
      }
      if (summaryStep !== PageState.AttendeeDetails) {
        setStartFromSummary();
      }
      if (ticketData?.isSimpleRegistrationEnabled) {
        setRegistrationToggle(ticketData?.isSimpleRegistrationEnabled);
      }
      setTicketingMfeLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketData?.tickets, discountData]);

  // BUGFIX - HUB-25494
  useEffect(() => {
    if (
      previousPageState &&
      previousPageState === PageState.PaymentMethod &&
      store?.pageState === PageState.AttendeeDetails &&
      store?.paymentDetails?.isLoading &&
      Object.keys(store?.paymentDetails).length === 1
    ) {
      setPaymentDetails({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPageState, store.pageState]);

  useEffect(() => {
    if (
      previousPageState &&
      previousPageState !== PageState.TicketInfo &&
      store.pageState === PageState.TicketInfo &&
      isEmpty(store?.paymentDetails)
    ) {
      onClose?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, previousPageState, store.pageState]);

  const isSimpleRegistrationEnabled = store?.registrationToggle;
  const { isBuyerFrom } = store;

  const classes = useStyles();
  const isMobWidth = 640;
  return (
    <Container
      maxWidth={store?.widgetData?.isWidgetExpand ? "lg" : "sm"}
      sx={(theme: any) => ({
        py: store.startFromSummary
          ? "0px !important"
          : isSimpleRegistrationEnabled
            ? "0px !important"
            : !isBuyerFrom
              ? "0px !important"
              : 2,
        px: store.startFromSummary
          ? "0px !important"
          : isSimpleRegistrationEnabled
            ? "0px !important"
            : !isBuyerFrom
              ? "8px !important"
              : 1,
        backgroundColor:
          store?.widgetData?.isWidgetExpand || store.startFromSummary
            ? "transparent"
            : theme.palette.background.cardColor,
        height: isSimpleRegistrationEnabled
          ? "100%"
          : !isBuyerFrom
            ? "100%"
            : "auto",
      })}
    >
      <Box

        position="relative"
        className={`${store?.widgetData?.isWidgetExpand ? classes.containerWidget : ""
          }
       ${store?.widgetData?.isWidgetExpand
            ? containerWidth && containerWidth > 359 && containerWidth < 1025
              ? classes.singleViewContainerWidget
              : false
            : false
          } `}
        height={
          store?.widgetData?.isWidgetExpand ?
            containerWidth && containerWidth > isMobWidth ?
              store?.widgetData?.height : "100%" : "100%"
        }
      >
        <_App {...props} />
      </Box>
    </Container>
  );
}

function AppWithTicketList(props: ITicketInfoProps) {
  const { store, setTicketList, setHideFreeLabel, setStripeAccountId } =
    useStore();
  const [ticketId, setTicketId] = useState(0);
  const {
    eventInfo,
    configData,
    landingPageData,
    showAddToCalendar = false,
    containerWidth,
  } = props;
  const { data, loading: ticketDataLoading } = useGetTickets({
    authorization: configData?.accessToken,
    language: configData?.language,
  } as IHeadersRequest);

  useEffect(() => {
    setTicketList(data?.tickets);
    setHideFreeLabel(data?.hideFreeLabel);
    if (data?.stripeAccountId) {
      setStripeAccountId(data?.stripeAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (props.eventInfo && !initialLoad) {
      addPreEventAnalytics(props.eventInfo);
      initialLoad = true;
    }
  }, [props.eventInfo]);

  const classes = useStyles();
  const isMobWidth = 640;
  const isSimpleRegistrationEnabled = store?.registrationToggle;
  return (
    // <Container maxWidth="sm" className={`${store.pageState === PageState.TicketInfo ? classes.removePadding : false}`} >
    <Container
      maxWidth={store?.widgetData?.isWidgetExpand ? "lg" : "sm"}
      sx={(theme: any) => ({
        padding: "0px !important",
        backgroundColor:
          store?.widgetData?.isWidgetExpand || store.startFromSummary
            ? "transparent"
            : theme.palette.background.cardColor,
        height: store?.widgetData?.isWidgetExpand ? "100%":"auto"
      })}
    >
      {/* <Box  p={2} sx={(theme:any)=>({backgroundColor:theme.palette.background.lightGray})} display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="400px">
        <Ticket404/>
        <Typography color="textPrimary" sx={(theme:any)=>({marginBottom:theme.spacing(1),marginTop:theme.spacing(5)})} variant="body1" component="label">Hi There! looks like Something went wrong</Typography>
        <Typography color="textSecondary" sx={(theme:any)=>({fontWeight:400 ,marginBottom:theme.spacing(3)})} variant="body2" component="p">Try refreshing the page</Typography>
        <Button variant="contained" color="primary" size="medium">Refresh Page</Button>
      </Box> */}
      <Box
        position="relative"
        className={`${store?.widgetData?.isWidgetExpand ? classes.containerWidget : ""
          }
       ${store.pageState === PageState.TicketInfo
            ? store?.widgetData?.isWidgetExpand
              ? containerWidth && containerWidth > 359 && containerWidth < 1025
                ? classes.widgetWrapperShape
                : ""
              : false
            : false
          } ${isSimpleRegistrationEnabled ? classes.regWrapperShape : ""} ${store?.widgetData?.isWidgetExpand
            ? containerWidth && containerWidth > 359 && containerWidth < 1025
              ? classes.singleViewContainerWidget
              : false
            : false
          } ${store.startFromSummary ? "singleViewTicketSummaryStart" : false}`}
        height={
          store?.widgetData?.isWidgetExpand ?
            containerWidth && containerWidth > isMobWidth ?
              store?.widgetData?.height : "100%" : "100%"
        }
      >
        {store.pageState === PageState.TicketInfo && (
          <TicketInfo
            landingPageData={landingPageData}
            eventInfo={eventInfo}
            configData={configData}
            ticketData={data}
            ticketDataLoading={ticketDataLoading}
            showAddToCalendar={showAddToCalendar}
            startFromSummary={store.startFromSummary}
            setTicketId={setTicketId}
          />
        )}
        <_App {...props} ticketData={data} activeTicketId={ticketId} />
      </Box>
    </Container>
  );
}

function App(props: IAppProps) {
  const { store, setWidgetData, setLandingPageData, setConfigData } =
    useStore();
  const { toast, startFromSummary } = store;
  const [faqModal, setFaqModal] = useState(false);

  useEffect(() => {
    setWidgetData(props?.widgetDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.widgetDetails]);

  useEffect(() => {
    setLandingPageData(props.landingPageData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.landingPageData]);

  useEffect(() => {
    if (props.configData) {
      setConfigData({
        ...props.configData,
        ...props.eventInfo,
        isCustomLandingPage:
          !props?.widgetDetails?.isWidgetExpand && !props?.isDefaultLandingPage,
        widgetFixedHeight:
          props.configData?.widgetFixedHeight || props?.widgetDetails?.height,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.configData]);

  useEffect(() => {
    Track.onPage({
      page: "landing-page",
      lpType: props?.widgetDetails?.isWidgetExpand
        ? "External website(widget)"
        : startFromSummary
          ? "default landing page"
          : "custom landing page",
    });

    Track.widgetData({
      isWidget: !!props?.widgetDetails?.isWidgetExpand,
      clientUrl: window?.location?.href,
      eventInfo: !!props?.widgetDetails?.showEventInfo as boolean,
      language: props?.widgetDetails?.languageCode as string,
    });
  }, [
    props.eventInfo,
    props?.widgetDetails?.isWidgetExpand,
    props?.widgetDetails?.languageCode,
    props?.widgetDetails?.showEventInfo,
    startFromSummary,
  ]);

  useEffect(() => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  // FAQ MODAL OPEN USING QUERY PARAMS | BUGFIX - HUB-24529
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query) {
      const faqQuery = query.get("faqs");
      if (faqQuery) {
        setFaqModal(true);
      } else {
        setFaqModal(false);
      }
    }
  }, []);
  // END : FAQ MODAL OPEN USING QUERY PARAMS

  const onCloseFaqModal = (): void => {
    setFaqModal(false);
    props?.onOpen?.();
  };

  const currentMilli = Date.now();
  const allowSessionOnDemand = props.landingPageData?.type?.toLowerCase() === "webinar" ?
    // @ts-ignore
    store?.landingPageData?.sessions?.data[0]?.data[0]?.streamTypeId === 6
      ? // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.preRecordedIsReplayVideo
      : // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.allowSessionOnDemand : false;
  const allowSessionOnDemandValue = props.landingPageData?.type?.toLowerCase() === "webinar" && allowSessionOnDemand;
  if (props.landingPageData?.type?.toLowerCase() === "webinar") {
    if (
      (props.landingPageData?.eventEndtime && currentMilli > Number(props.landingPageData?.eventEndtime)) && allowSessionOnDemandValue !== 1
    ) {
      return <></>;
    }
    return store.configData?.isCustomLandingPage ? (
      <>
        <WebinarRegistration
          webinarData={props?.webinarData}
          configData={props?.configData}
          eventInfo={props?.eventInfo}
          isDefaultLandingPage={props?.isDefaultLandingPage}
        />
        <Toast open={!isEmpty(toast)} {...toast} />
      </>
    ) : isAndroidIPhoneIPad() ? (
      <>
        <CustomModal open isOnlyChildren onRequestClose={props?.onClose}>
          <WebinarRegistration
            webinarData={props?.webinarData}
            configData={props?.configData}
            eventInfo={props?.eventInfo}
            isDefaultLandingPage={props?.isDefaultLandingPage}
          />
        </CustomModal>
        <Toast open={!isEmpty(toast)} {...toast} />
      </>
    ) : (
      <>
        <WebinarRegistration
          webinarData={props?.webinarData}
          configData={props?.configData}
          eventInfo={props?.eventInfo}
          isDefaultLandingPage={props?.isDefaultLandingPage}
        />
        <Toast open={!isEmpty(toast)} {...toast} />
      </>
    );
  }
  if (props.ticketData && props.discountData) {
    return (
      <>
        <AppWithTicketSummary
          ticketData={props.ticketData}
          discountData={props.discountData}
          {...props}
        />
        <Toast open={!isEmpty(toast)} {...toast} />
      </>
    );
  }

  return (
    <>
      <AppWithTicketList {...props} />
      <Toast open={!isEmpty(toast)} {...toast} />
      <CustomModal
        onRequestClose={onCloseFaqModal}
        isOnlyChildren
        open={faqModal}
        isScrollModal
      >
        <FAQModal
          onClose={onCloseFaqModal}
          faqList={faqList}
          orderNo={store?.orderNo}
        />
      </CustomModal>
    </>
  );
}

export default App;
