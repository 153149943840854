import get from "lodash/get";
import { getFormFieldsAuthenticate } from "../../Utils/FieldsValidation";

const WEBINAR_DEFAULT_FIELDS: string[] = ["firstName", "lastName", "email"];

export const getFilterPhoneContact = (dialer: string, phone: string) => {
  if (!dialer && !phone) return "";
  return `${dialer}-${phone}`;
};

export const fetchGroupValues = (groupValues = []): Map<string, string> => {
  const fieldMap: Map<string, string> = new Map();
  groupValues.forEach((group) => {
    if (group) {
      for (const key in group) {
        if (group[key]) {
          const isExist = fieldMap.get(key);
          if (isExist) {
            fieldMap.set(key, `${isExist}, ${group[key]}`);
          } else {
            fieldMap.set(key, group[key]);
          }
        }
      }
    }
  });

  return fieldMap;
};

export const isFormAuthentic = (profileFields: any) => {
  const fetchFields = [...profileFields];
  const isFieldsEmpty =
    fetchFields && Array.isArray(fetchFields) && fetchFields.length;
  let isAuth = true;
  if (isFieldsEmpty) {
    const updateFields = fetchFields.map((field) => {
      let childFieldNotAvailable = false;
      if (field?.parent) {
        const parentField = fetchFields?.find((item: any) => item.id === field?.parent);
        const optionFieldName = parentField?.parentOptions?.find(
          (opt: any) => opt.optionId === field.optionId
        )?.name;
        if (parentField?.type === "CHECKBOX") {
          if (!(parentField?.valueOptions?.find((x: string) => x === optionFieldName))) {
            childFieldNotAvailable = true;
          }
        } else if (parentField?.type === "RADIO" || parentField?.type === "DROPDOWN") {
          if (parentField?.value !== optionFieldName) {
            childFieldNotAvailable = true;
          }
        }
      }
      const fieldType = get(field, "fieldType", "");
      const isDefaultField: boolean = WEBINAR_DEFAULT_FIELDS.includes(fieldType);
      const fetchAuth = getFormFieldsAuthenticate(
        isDefaultField ? { ...field, isDefaultField } : field
      );
      const hasError = get(fetchAuth, "hasError", false);
      const errMsg = get(fetchAuth, "errMsg", "");
      const errData = get(fetchAuth, "errData", []);
      if (hasError && !childFieldNotAvailable) {
        isAuth = false;
      }
      if (errData && Array.isArray(errData) && errData.length) {
        return { ...field, isError: hasError, labelHint: errMsg, errData };
      }
      return { ...field, isError: hasError, labelHint: errMsg };
    });
    return { isAuth, fields: updateFields };
  }

  return { isAuth, fields: fetchFields };
};
