import { FC } from "react";

type NoSSRProps = {
    children: ReturnType<FC>;
}

const NoSSR: FC<NoSSRProps>  = ({ children }) => {
    if (typeof window !== "object") {
        return null;
    }
  return children;
};

export default NoSSR; 