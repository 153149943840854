import Box from "@mui/material/Box";
import React from "react";
import SkeletonLoader from "../SkeletonLoader";

const BillLoader = ():JSX.Element => {
    return(
        <>
            {[1,2].map((i)=>( 
                <Box key={i} display="flex" alignItems="center" justifyContent="space-between">
                    <SkeletonLoader height="22px" width="120px" variant="text"/> 
                    <SkeletonLoader height="22px" width="120px" variant="text"/> 
                </Box>))}
                <SkeletonLoader height="40px" width="100%" mt="10px" variant="rect"/>
        </>
    );
};

export default function ModalLoader(){
    // const classes = useStyles();
    return(
        <Box p={2}>
            <Box display="flex" mt={3} justifyContent="center" flexDirection="column" alignItems="center">
                <SkeletonLoader height="80px" width="80px" variant="rect"/>
                <SkeletonLoader height="20px" width="220px" variant="rect" mt="20px"/>
                <SkeletonLoader height="10px" width="100%" variant="rect" mt="20px"/>
                <SkeletonLoader height="10px" width="220px" variant="rect" mt="10px"/>
            </Box>
            <SkeletonLoader height="200px" width="100%" variant="rect" mb="20px" mt="30px"/>
            <BillLoader/>
        </Box>
    );
}