export default {
        "already-registered": "Už ste registrovaný/á?",
        "add-to-calendar": "Pridať do Kalendára",
        "event-starts-in": "Udalosť začína za",
        "event-is-live": "UDALOSŤ IDE NAŽIVO",
        "event-has-ended": "UDALOSŤ SKONČILA",
        "powered-by": "Podporované",
        "please-enter-your-email-to-continue": "Aby ste mohli pokračovať, prosíme uveďte svoj e-mail",
        "add": "Pridať",
        "offers": "Ponuky",
        "free": "Zdarma",
        "available-till": "Dostupné do {{DATE}}",
        "enter-email-address": "Zadajte E-mailovú Adresu",
        "sold-out": "Vypredané",
        "the-ticket-has-reached-the-maximum-number": "Lístok dosiahol maximálneho počtu",
        "seat-left": "{{SEAT}} miest zostáva",
        "seats-left": "{{SEAT}} miest zostáva",
        "proceed": "Pokračovať",
        "summary": "Súhrn",
        "buyer-info": "Informácie o kupujúcom",
        "attendees-info": "Informácie o účastníkoch",
        "registration": "Registrácia",
        "continue": "Pokračovať",
        "attendees-information": "Informácie o účastníkovi",
        "submit": "Odoslať",
        "register-to-join-the-events": "Registrovať sa na udalosti",
        "by-clicking-on-the-button": "Kliknutím na tlačítko budete presmerovaní na platformu pre nákup lístkov, kde sa môžete zaregistrovať na tuto udalosť.",
        "other": "Iné",
        "please-enter-a-value-for": "Prosíme uveďte hodnotu pre <FIELD_NAME>",
        "please-select-a-value-for": "Prosíme vyberte hodnotu pre <FIELD_NAME>",
        "enter-other-value-for": "Uveďte inú hodnotu pre <FIELD_NAME>",
        "enter-a-valid": "Uveďte platné <FIELD_NAME>",
        "should-be-at-least": "<FIELD_NAME> by malo byť najmenej <FIELD_LENGTH> <FIELD_TYPE>",
        "cannot-be-more-than": "<FIELD_NAME> nesmie byť viac než <FIELD_LENGTH> <FIELD_TYPE>",
        "is-required": "<FIELD_NAME> je povinné",
        "at-least-required": "Je vyžadované aspoň <FIELD_LENGTH> <FIELD_NAME> <VERB>",
        "maximum-allowed": "Maximálne <FIELD_LENGTH> <FIELD_NAME> <VERB> povolené",
        "user-with-same-email-id-already-exists": "Užívateľ sa rovnakým e-mailovým id už existuje. Použite prosím iné.",
        "user-with-same-email-id-has-already-registered-for-this-event": "Užívateľ sa rovnakým e-mailovým id je už zaregistrovaný na túto udalosť. Použite prosím iné.",
        "mail-successfully-sent": "E-mail Úspešne Odoslaný!",
        "we-are-unable-to-deliver-your-mail-at-this-moment": "Nemôžeme doručiť váš e-mail v túto chvíľu! Skúste to neskoršie",
        "something-went-wrong": "Niečo sa Nepodarilo. Pracujeme na tom. Ospravedlňujeme sa za spôsobené nepríjemnosti.",
        "register-now": "Registrovať Teraz",
        "add-to-your-calendar": "Pridať do Kalendára",
        "please-select-a-ticket-to-proceed": "Vyberte lístok, aby ste mohli pokračovať",
        "resend-link": "Znovu Odoslať Odkaz",
        "go-to-event": "Ísť na udalosť",
        "ticket-registration-done-by": "Registrácie lístku uskutočnil",
        "you-have-already-purchased-the-ticket-for": "Už ste zakúpili lístok na",
        "purchased": "Zakúpené",
        "event-date": "Dátum Udalosti",
        "ticket-quantity": "Počet lístkov",
        "payment": "Platba",
        "unable-to-submit-data": "Nie je možné odoslať údaje",
        "unable-to-apply-promo-code": "Nie je možné použiť promo kód",
        "invalid-promo-code": "Neplatný promo kód",
        "apply-now": "Použiť",
        "enter-discount-coupon": "Zadať zľavový kód",
        "same-as-buyer": "Rovnaké ako kupujúci",
        "please-check-all-the-fields-are-correctly-filled": "Prosím, skontrolujte, či sú všetky polia správne vyplnené",
        "ticket-purchased": "Lístok zakúpený!",
        "ticket-purchase-failed": "Nákup vstupenky sa nepodaril!",
        "you-have-successfully-purchased-the-ticket": "Lístok ste úspešne zakúpili. Tento lístok vám umožní zúčastniť sa na udalosti. Ďakujeme za nákup!",
        "there-was-an-error-while-booking-your-tickets": "Pri rezervácii vstupeniek došlo k chybe. Prosím, skúste to znovu.",
        "try-again": "Skúste to prosím znovu.",
        "you-dont-have-access": "Nemáte prístup na nákup niektorých lístkov",
        "please-enter-a-valid-email": "Prosíme uveďte platný e-mail",
        "please-enter-email-to-continue": "Prosím uveďte svoj e-mail, aby ste mohli pokračovať",
        "payment-summary": "Prehľad platby",
        "apply-discount": "Kód {{CODE}} sa úspešne použil Ušetrili ste ďalších {{AMOUNT}}.",
        "and": "a",
        "taxation-concent": "Chcete vygenerovať daňový doklad?",
        "taxation-tnc-pp": "Pokračovaním súhlasíte s našimi",
        "taxation-tnc": "Obchodní Podmienky",
        "taxation-pp": "Zásady Ochrany Osobných Údajov",
        "company-name-label": "Meno Spoločnosti",
        "company-name-place-holder": "Uveďte Meno Spoločnosti",
        "taxId-label": "DIČ spoločnosti",
        "taxId-place-holder": "Uveďte DIČ spoločnosti",
        "company-address-label": "Adresa Spoločnosti",
        "company-address-place-holder": "Adresa",
        "country-place-holder": "Vyberte Zem",
        "state-place-holder": "Vyberte Štát",
        "city-place-holder": "Uveďte Mesto",
        "zipcode-label": "PSČ",
        "zipcode-place-holder": "Uveďte PSČ",
        "company-error-msg": "Prosíme uveďte Meno Spoločnosti",
        "taxId-error-msg": "Prosíme uveďte DIČ Spoločnosti",
        "address-error-msg": "Prosím uveďte Adresu",
        "country-error-msg": "Prosíme Vyberte Krajinu",
        "state-error-msg": "Prosíme Vyberte Štát",
        "city-error-msg": "Prosíme uveďte Mesto",
        "zipcode-error-msg": "Prosíme uveďte PSČ",
        "firstName": "Meno",
        "lastName": "Priezvisko",
        "email": "E-mail",
        "phone": "Telefón",
        "dialerCode": "Predvoľba",
        "you-have-already-purchased-ticket-for": "Už ste zakúpili lístok pre",
        "browse-a-file-to-upload": "Prechádzať súbor na nahranie",
        "upload-file": "Nahrať Súbor",
        "max-size": "Maximálna veľkosť",
        "file-supported-types": "Podporované typy súborov",
        "uploaded-successfully": "Úspešne Nahrané",
        "failed!-please-retry": "Zlyhalo! Skúste to Znovu",
        "this-file-type-is-not-supported-supported-attachments-are": "Tento typ súboru nie je podporovaný. Podporované prílohy sú",
        "the-file-size-is-too-high-please-upload-document-lesser-than": "Veľkosť súboru je príliš veľká. Prosíme nahrajte dokument menší než",
        "person": "Osoba",
        "select": "Vybrať",
        "search": "Hľadať",
        "uploaded-file": "Nahraný Súbor",
        "uploading": "Nahrávanie",
        "delete": "Zmazať",
        "no-item": "Žiadna položka",
        "read-more": "Prečítajte si viac",
        "read-less": "Prečítajte si menej",
        "about-title": "O",
        "google-calendar": "Google Kalendár",
        "outlook-calendar": "Outlook Kalendár",
        "yahoo-calendar": "Yahoo Kalendár",
        "verb-are": "sú",
        "verb-is": "je",
        "character": "znak",
        "date-format": "DD/MM/YYYY",
        "timer-hr": "h",
        "timer-hrs": "h",
        "timer-min": "min",
        "timer-mins": "min",
        "timer-sec": "sek",
        "timer-secs": "sek",
        "timer-day": "deň",
        "timer-days": "dni",
        "select-file-title": "Žiadny súbor nie je vybraný",
        "today": "Dnes",
        "yesterday": "Včera",
        "tomorrow": "Zajtra",
        "last": "Posledný",
        "internet-down": "Zdá sa, že je odpojený internet!",
        "internet-up": "Internet je teraz pripojený",
        "network-issue": "Nastala chyba siete, skúste to znovu!",
        "registrationSuccess": "Registrácia sa úspešne uskutočnila!",
        "registrationSuccessDetail": "Úspešne ste sa zaregistrovali na udalosť. Ďakujeme!",
        "search-here": "Hľadať tu...",
        "did-you-mean": "Myslíte",
        "DISPOSABLE_EMAIL": "Zadaná e-mailová adresa patrí k službe na jedno použitie",
        "RISTRICTED_DOMAIN": "Zadali ste e-mailovú adresu v obmedzenom doménovom priestore. vložte platnú e-mailovú adresu",
        "INVALID_EMAIL": "Prosíme uveďte platný e-mail",
        "ORDERED": "Objednané",
        "CHOOSE_PAYMENT_METHOD": "Zvolte metódu platby",
        "PAY_NOW": "Teraz zaplatiť",
        "INSTANT_TICKET_CONFIRMATION": "Okamžité potvrdenie lístku",
        "PAY_VIA_OTHER_METHODS": "Zaplatiť inými spôsobmi",
        "FUNDS_TRANSFER": "Prevod finančných prostriedkov",
        "DIGITAL_WALLET": "Digitálna peňaženka",
        "CHEQUE": "Šek",
        "PAY_AT_VENUE": "Platba na mieste",
        "TICKET_NOT_CONFIRMED_ORGANIZER": "Lístok(Lístky) nebude potvrdený až do chvíle, keď usporiadateľ udalosti nepotvrdí príjem platby a môže byť zrušený.",
        "TICKET_NOT_CONFIRMED_UNTIL_PAYMENT": "Lístok(Lístky) nebude potvrdený, pokiaľ neuskutočníte platbu na mieste konania udalosti a môže byť zrušený.",
        "TICKET_CONFIRMED_BY_ORGANIZER": "Lístok(Lístky) nebude potvrdený, pokiaľ usporiadateľ udalosti nepotvrdí príjem platby.",
        "COMPLETE_PAYMENT_OF": "Dokončite platbu vo výške",
        "COMPLETE_PAYMENT_TICKET": "Dokončiť platbu za vaše lístky.",
        "PAYMENT_CONFIRMATION_TITLE": "Uveďte jedinečný ID kód transakcie, aby usporiadateľ udalosti mohol overiť vašu platbu.",
        "ENTER_TRANSACTION_ID": "Uveďte ID Transakcie",
        "SHARE_DETAILS_TITLE": "Usporiadateľ udalosti môže schváliť vašu platbu iba vtedy, keď zdieľate podrobnosti. Váš lístok(lístky), vrátane akýchkoľvek voľných lístkov, bude potvrdený po schválení.",
        "UPLOAD_SCREENSHOT": "Nahrať snímok obrazovky (voliteľné)",
        "SHARE_PAYMENT_DETAILS": "Zdieľať údaje o platbe",
        "TICKET_STATUS": "Stav Vstupenky",
        "ORGANIZER_YET_TO_CONFIRM": "Usporiadateľ udalosti doposiaľ nepotvrdil príjem platby.",
        "TICKET_CONFIRMATION_ORGANIZER": "Váš lístok(lístky), vrátane akýchkoľvek voľných lístkov, a faktúra budú potvrdené a zdieľané po schválení usporiadateľom.",
        "PAYMENT_DETAILS": "Podrobnosti Platby",
        "FAQ_TITLE": "V prípade akýchkoľvek otázok sa obráťte na často kladené otázky (FAQ).",
        "MAKE_PAYMENT_TITLE": "Aby ste potvrdili zakúpenie lístku(lístkov), uskutočnite platbu v mieste konania udalosti.",
        "CONFIRM_TICKET_PRIOR_EVENT": "Pokiaľ chcete lístok(lístky) potvrdiť pred samotnou udalosťou, uskutočnite teraz platbu iným spôsobom.",
        "CONFIRM_TICKETS_PRIOR_EVENT": "Pokiaľ chcete lístok(lístky) potvrdiť pred samotnou udalosťou",
        "PAY_NOW_2": "Teraz zaplatiť",
        "WITH_DIFFERENT_METHOD": "iným spôsobom.",
        "PAYMENT_NOT_RCVD": "Organizátor udalosti doposiaľ neprijal platbu.",
        "PAYMENT_COULD_NOT_BE_VERIFIED": "Zadané platobné údaje nemohli byť overené organizátorom udalosti.",
        "TICKETS_NOT_AVAILABLE": "Vstupenky už nie sú k dispozícii pre túto udalosť.",
        "PAYMENT_DETAILS_YET_TO_SHARE": "Zatiaľ ste nezdieľali platobné údaje pre lístok(lístky).",
        "WELCOME_BACK": "Vítajte späť!",
        "VIEW_OR_PROCEED_PURCHASE": "Môžete si prezrieť svoje predchádzajúce lístky nebo pokračovať v nákupu lístkov, ktoré ste práve vybrali.",
        "VIEW_PREV_TICKETS": "Zobraziť predchádzajúce lístky",
        "PROCEED_TO_PURCHASE": "Pokračovať v nákupe",
        "PREVIOUS_TICKETS": "Predchádzajúce lístky",
        "PENDING_TICKETS": "Čakajúce lístky",
        "CONFIRMED_TICKETS": "Potvrdené lístky",
        "PAYMENT_PENDING": "Čaká na schválení platby",
        "AWAITING_ORG_APPROVAL": "Čaká sa na schválení usporiadateľom udalosti",
        "CHECK_TICKET_STATUS": "Skontrolovať Stav Lístku",
        "PAYMENT_METHOD_NOT_AVAILABLE": "Platobná metóda nie je k dispozícii, skúste to znovu.",
        "HERE": "tu.",
        "RE_ENTER_EMAIL": "Láskavo aktualizujte jedinečné informácie o účastníkoch pre všetky zakúpené lístky. Toto vám umožní prístup všetkých týchto účastníkov na udalosť.",
        "DUPLICATE_EMAIL": "Tento lístok ste už zakúpili. Môžete sa rozhodnúť pre novú registráciu. V takom prípade budú uložené najnovšie údaje.",
        "DEFAULT_DUPLICATE_EMAIL": "Tento e-mail je už používaný pre iný lístok. Láskavo aktualizujte jedinečné informácie o účastníkovi pre všetky zakúpené lístky. Toto vám umožní prístup všetkých týchto účastníkov na udalosť.",
        "REGISTER_NOW": "Registrovať Teraz",
        "REG_FAILED": "Registrácia zlyhala",
        "REG_FAILED_DESC": "Vaša registrácia zlyhala.  Skúste to znova.",
        "REG_DONE_BY": "Registráciu vykonal",
        "INVITE_AGAIN": "Chcete pozvánku znova?",
        "REG_SUCCESS_DONE": "Registrácia bola úspešne vykonaná",
        "WATCH_REC_LINK": "E-mail s prepojením na sledovanie nahrávky bol odoslaný na adresu",
        "WEBINAR_INV_LINK": "E-mail s pozvánkou s odkazom na webinár bol odoslaný na adresu",
        "MAIL_SENT_SUCCESS": "Pošta bola úspešne odoslaná",
        "ALREADY_REGISTERED": "Už ste sa zaregistrovali na",
        "RESEND_INVITE": "Opätovné odoslanie pozvánky",
        "WATCH_RECORDING_REGISTER_DESC": "Záznam webinára si môžete pozrieť registráciou nižšie.",
        
    };