import React from "react";
import Box from "@mui/material/Box";
import SkeletonLoader from "../SkeletonLoader";
import CircleLoader from "./CircleLoader";

export const ListLoader = (): JSX.Element => {
  return (
    <>
      {[1, 2, 3].map((i) => (
        <Box
          key={i}
          p={2}
          mt={2}
          className="list"
          sx={(theme: any) => ({
            border: `1px solid ${
              theme.palette.divider.default || theme.palette.divider
            }`,
            borderRadius: "7px",
          })}
        >
          <Box mt={1} display="flex" justifyContent="space-between">
            <Box>
              <SkeletonLoader height="17px" width="120px" variant="text" />
              <SkeletonLoader height="17px" width="120px" variant="text" />
            </Box>
            <SkeletonLoader height="20px" width="20px" variant="rect" />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            pt={1}
            mt={2}
            sx={(theme: any) => ({
              borderTop: `1px solid ${
                theme.palette.divider.default || theme.palette.divider
              }`,
            })}
            justifyContent="space-between"
          >
            <SkeletonLoader height="20px" width="60px" variant="text" />
            <Box
              display="flex"
              sx={{ "&>span": { "&:last-child": { marginLeft: 1 } } }}
            >
              <SkeletonLoader height="32px" width="80px" variant="rect" />
              <SkeletonLoader height="32px" width="80px" variant="rect" />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
};

export default function TicketInfoAndListLoader() {
  return (
    <Box
      sx={(theme: any) => ({
        backgroundColor: theme.palette.background.white,
        height: "100%",
        width: "100%",
        display: "block",
        position:"relative",
      })}
    >
          <CircleLoader />


    
        {/* <Box>
          <Box
            sx={(theme) => ({
              display: "block",
              color: theme.palette.text.primary,
            })}
          >
            <Box
              sx={(theme: any) => ({
                p: 2.5,
                borderRadius: 2.5,
                overflowY: "auto",
                border: `1px solid ${
                  theme.palette.divider.default || theme.palette.divider
                }`,
              })}
            >
              <Box sx={{ "& .list": { "&:first-of-type": { marginTop: 0 } } }}>
                <TicketInfoLoader />
                <ListLoader />
              </Box>
            </Box>
          </Box>
        </Box> */}
    </Box>
  );
}
