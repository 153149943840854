import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import WebinarRegistrationForm from "./WebinarRegistrationForm";
import WebinarConfirmationModal from "./WebinarConfirmationModal";
import useWebinarForm from "Hooks/useWebinarForm";
import { IHeadersRequest } from "Types/Ticket";
import { IWebinarSubmits } from "Types/WebinarForm";
import { debounce } from "lodash";
import { trackWebinarRegistrationView } from "Api/tracking";
import {
  addPreEventAnalytics
} from "Utils/trackingPixels";
import TicketInfoAndListLoader from "../../Components/Loaders/TicketInfoAndListLoader";

interface IWebinarProps {
  configData: any;
  webinarData: any;
  eventInfo?: any;
  isDefaultLandingPage?: any;
}

const debouncedTrackWebinarRegistrationView = debounce(trackWebinarRegistrationView, 1000);
let initialLoad = false;
const WebinarRegistration = (props: IWebinarProps): JSX.Element | null => {
  const [formData, setFormData] = useState<IWebinarSubmits[] | null>(null);
  const { configData, webinarData } = props;
  const { getWebinarForm, data, formLoading  } = useWebinarForm({
    authorization: configData?.accessToken,
    language: configData?.language,
    trackingPixelApps: props?.eventInfo?.trackingPixelApps,
  } as IHeadersRequest, webinarData);

   useEffect(() => {
    if (props.eventInfo && !initialLoad && !props?.isDefaultLandingPage) {
      addPreEventAnalytics(props.eventInfo);
      initialLoad = true;
    }
  }, [props.eventInfo, props?.isDefaultLandingPage]);

  useEffect(()=>{
    getWebinarForm();
    debouncedTrackWebinarRegistrationView({
      authorization: props?.configData?.accessToken || "",
      language: props?.configData?.language || 34
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  if (formLoading) return <TicketInfoAndListLoader />;

  return data.attendeeFormFields ? (
    <Box
      sx={(theme: any) => ({
        backgroundColor: theme.palette.background.cardColor,
        padding: "3.2rem 2.4rem",
        "@media(max-width:768px)": {
          padding: "1.6rem 0rem 1.6rem 1.6rem",
          height:"100%"
        },
      })}
    >
      <WebinarRegistrationForm
        apiData={data}
        configData={configData}
        setFormData={setFormData}
        trackingPixelApps={props?.eventInfo?.trackingPixelApps}
      />
       <WebinarConfirmationModal
        configData={configData}
        formData={formData}
        trackingPixelApps={props?.eventInfo?.trackingPixelApps}
      />
    </Box>
  ) : null;
};

export default WebinarRegistration;
