import React from "react";
import { IIconProps } from "./types";

const TicketPurchaseSuccess = ({
  width = "75",
  height = "78",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 75 78"
      fill="none"
    >
      <path
        d="M35.3155 0H38.1036C40.3248 1.15143 42.4902 2.41428 44.7764 3.41714C46.7653 4.28071 48.9493 5.62715 50.9196 5.43215C58.243 4.68001 63.0014 9.20214 65.0553 15.9621C65.4464 17.3011 66.2739 18.4711 67.4066 19.2864C74.1816 23.4557 75.8545 28.0986 73.4753 35.7129C72.983 37.5329 73.0872 39.4627 73.7727 41.2193C76.44 47.7843 74.7579 53.2257 68.8099 57.1257C67.4418 58.0818 66.4546 59.4889 66.0218 61.1C64.4419 68.1571 59.9344 71.7878 52.4995 71.6671C51.1612 71.6671 49.5999 71.5371 48.5032 72.1407C45.2504 73.9143 42.1835 76.0221 39.0422 78H36.2541C31.9512 75.0193 28.0943 71.565 22.0162 72.2707C17.1742 72.8371 12.1556 68.64 11.2913 63.8486C11.1518 62.5823 10.6887 61.3732 9.94658 60.3373C9.20442 59.3015 8.20812 58.4737 7.05339 57.9336C1.53295 54.8971 -0.706828 48.8707 1.78388 43.355C3.46603 39.6407 2.6389 36.9571 0.00878906 34.3571V30.6429C1.86753 27.6343 3.27087 23.9757 5.88239 21.8029C8.01205 20.2264 9.54241 17.9738 10.2225 15.4143C11.9326 8.91429 17.137 5.51571 23.9121 6.27714C24.9353 6.46985 25.9931 6.34342 26.9419 5.915C29.7857 4.06714 32.5274 1.98714 35.3155 0ZM52.4158 24.5979C45.6686 31.6457 39.4047 38.1736 32.4251 45.5093C30.0088 42.6586 28.1965 40.0864 25.9196 38.0807C25.1389 37.4029 22.5924 37.6257 21.7839 38.4057C20.9753 39.1857 20.5478 41.8136 21.1891 42.5471C24.5009 46.2229 28.0131 49.7134 31.7095 53.0029C32.1834 53.2557 32.7144 53.3826 33.2515 53.3712C33.7886 53.3598 34.3137 53.2106 34.7764 52.9379C42.2857 45.2029 49.6463 37.3379 57.3973 29.1479L52.4158 24.5979Z"
        fill="#01DB4E"
      />
    </svg>
  );
};

export default TicketPurchaseSuccess;
