import { useState, useEffect } from "react";
import { ITicketInfoProps } from "..";
import { AppConstants } from "../Constants/AppConstants";
import { oldPostApi } from "../Core/HttpClient";
import { IEventInfo, ILandingPage } from "../Types/LandingPage";

export type IWebState = ITicketInfoProps & { theme: any };

const useWebState = ({
  communityUrl,
  languageCode,
}: {
  communityUrl: string;
  languageCode: string;
}) => {
  const [data, setData] = useState<IWebState>({} as IWebState);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>(null);

  useEffect(() => {
    let didCancel = false;
    async function fetchApi() {
      setLoading(true);
      setError(null);
      try {
        const configResponse = await oldPostApi<
          IEventInfo & { previewData: IWebState["theme"] }
        >(AppConstants.landingPageConfiguration, null, {
          payload: {
            data: {
              source: "COMMUNITY",
              url: communityUrl.replace("https://", ""),
              app_version: "1.0.0",
              device_type: "WEB",
            },
          },
        });
        if (!configResponse.status) {
          setError(configResponse.error);
          return;
        }
        const accessToken = configResponse.success?.data?.accessToken as string;
        const languageId = configResponse.success?.data?.supportedLanguages
        ?.find(language => language.shortCode.toLowerCase() === languageCode.toLowerCase())
        ?.id;
        const headers: { authorization: string, language?: number } = { authorization: accessToken };
        if (languageId) {
          headers.language = languageId;
        }
        const landingPageResponse = await oldPostApi<ILandingPage>(
          AppConstants.landingPage,
          headers,
          {
            payload: {
              data: {
                source: "COMMUNITY",
                url: communityUrl.replace("https://", ""),
                app_version: "1.0.0",
                device_type: "WEB",
              },
            },
          }
        );
        if (!landingPageResponse.status) {
          setError(landingPageResponse.error);
          return;
        }
        if (!didCancel) {
         //@ts-ignore
          const data: IWebState = {
            landingPageData: landingPageResponse.success?.data,
            eventInfo: configResponse.success?.data,
            configData: {
              accessToken,
              communityUrl: configResponse.success?.data.communityUrl,
              language: landingPageResponse.success?.data?.type === "WEBINAR" ? configResponse.success?.data?.supportedLanguages?.[0]?.id || 34  : languageId || 34,
            },
            theme: configResponse.success?.data?.previewData,
          };
          setData(data);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, [communityUrl, languageCode]);

  return { data, loading, error };
};

export default useWebState;
