import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IHeadersRequest } from "../Types/Ticket";
import {
  IValidateEmailRequest,
  IValidateEmailResponse,
} from "../Types/ValidateEmail";
import { useStore } from "../Context";

const useValidateEmail = () => {
  const { setBuyerRegisteredAsAttendee, setToast } = useStore();
  const [data, setData] = useState<IValidateEmailResponse>(
    {} as IValidateEmailResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();

  const validateEmail = useCallback(
    (headers?: IHeadersRequest, input?: IValidateEmailRequest) => {
      let didCancel = false;
      async function fetchApi() {
        try {
          const response = await postApi<
            IValidateEmailResponse,
            IValidateEmailRequest
          >(AppConstants.validateEmail, headers, input);

          if (!response.status) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.success?.data as IValidateEmailResponse);
            setBuyerRegisteredAsAttendee(
              response?.success?.data?.buyerAlreadyRegisteredAsAttendee
            );
            if(response?.error?.message){
              setToast({
              message: response?.error?.message,
              type: "error",
              });
            }
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { data, loading, error, validateEmail };
};

export default useValidateEmail;
