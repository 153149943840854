import { useState, useCallback } from "react";
import { patchApi } from "../Core/HttpClient";

export interface IPaymentShareRequest {
  payload: {
    data: {
      transactionId: string;
      paymentProof: string;
    };
  };
}

export interface IPaymentShareResponse {
  message?: string;
}

const usePaymentShare = () => {
  const [data, setData] = useState<IPaymentShareResponse>(
    {} as IPaymentShareResponse
  );
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const paymentShare = useCallback((headers, input?: IPaymentShareRequest, token?: string) => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await patchApi<IPaymentShareResponse, IPaymentShareRequest>(
          `orders/${token}/payment-proof`,
          headers,
          input
        );

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setDataFetched(true);
          setData(response?.success?.data as IPaymentShareResponse);
        }
      } catch (err) {
        setError(err);
      } finally {
        setSubmitLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, []);

  return { data, isSubmitLoading, setSubmitLoading, error, paymentShare, dataFetched };
};

export default usePaymentShare;
