// libraries
import get from "lodash/get";

// Field Helpers
import { getFormFieldsAuthenticate } from "./FieldsValidation";

// Inerfaces
import { IGroupValue } from "../Types/BuyerDetails";

export const getCustomGroupValueArray = (
  groupValueArray: IGroupValue[],
  minLength: number
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customGroupValueArray: any = [undefined]; // just 0 index ignore

  if (groupValueArray && groupValueArray.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    groupValueArray.forEach((childField: any) => {
      if (!customGroupValueArray[childField.team]) {
        customGroupValueArray[childField.team] = {};
      }
      customGroupValueArray[childField.team] = {
        ...customGroupValueArray[childField.team],
        [childField.id]: childField.fieldValue,
      };
    });
    if (minLength) {
      let totalNumberOfTeamAvailable = 0;
      customGroupValueArray.forEach((ele: string | null, index: number) => {
        if (index !== 0) totalNumberOfTeamAvailable += 1;
      });
      if (totalNumberOfTeamAvailable < minLength) {
        [...Array(minLength - totalNumberOfTeamAvailable)].forEach(() =>
          customGroupValueArray.push({})
        );
      }
    }
  } else {
    if (minLength) {
      [...Array(minLength)].forEach(() => customGroupValueArray.push({}));
    }
  }
  return customGroupValueArray;
};

export function addSomePropertiesInField<T>(list: T[]): T[] {
  // for onboarding and edit profile
  if (list && Array.isArray(list) && list.length) {
    return list.map((item) => {
      let field = { ...item };
      const type = get(field, "type", "");
      const value = get(field, "value", "");
      const fieldType = get(field, "fieldType", "");
      const isEdited = get(field, "isEdited", false);
      const choices = get(field, "choices", []);
      const minLength = Math.max(get(field, "minLength", 0), 0);
      const valueOptions = get(field, "valueOptions", []);
      const showOtherOption = get(field, "showOtherOption", false);
      const groupValueArray = get(field, "groupValueArray", []);

      // [HUB-9459] Console Error under Buyer forms on the landing page.
      if (
        fieldType?.toUpperCase() === "CUSTOM" &&
        ["DROPDOWN", "RADIO", "CHECKBOX"].includes(type)
      ) {
        if (isEdited) {
          if (type === "CHECKBOX") {
            let otherValue = "";
            const fieldValueArray = [...valueOptions];
            valueOptions.forEach((option: string) => {
              const isOptionExist = choices.findIndex(
                ({ label = "" }) =>
                  label &&
                  String(label).toLowerCase().trim() ===
                    String(option).toLowerCase().trim()
              );
              if (isOptionExist < 0) {
                if (showOtherOption) otherValue = option;
                const index = fieldValueArray.indexOf(option);
                if (index > -1) {
                  fieldValueArray.splice(index, 1);
                }
              }
            });
            if (showOtherOption && otherValue !== "") {
              fieldValueArray.push("Other");
              field = { ...field, otherValue };
            }
            field = { ...field, valueOptions: fieldValueArray };
          } else if (["DROPDOWN", "RADIO"].includes(type)) {
            let otherValue = "";
            const isOptionExist = choices.findIndex(
              ({ label = "" }) =>
                label &&
                String(label).toLowerCase().trim() ===
                  String(value).toLowerCase().trim()
            );
            if (value && value !== "" && isOptionExist < 0) {
              otherValue = value;
              if (showOtherOption) field = { ...field, value: "Other" };
              else field = { ...field, value: "" };
            }
            if (showOtherOption) field = { ...field, otherValue };
          }
        } else {
          if (showOtherOption) field = { ...field, otherValue: "" };
        }
      } else if (type === "GROUP") {
        field = {
          ...field,
          customGroupValueArray: getCustomGroupValueArray(
            groupValueArray,
            minLength
          ),
        };
      }
      return field;
    });
  }
  return [];
}

const checkValidation = (field: any) => {
  const fetchAuth = getFormFieldsAuthenticate(field);
  const hasError = get(fetchAuth, "hasError", false);
  const errMsg = get(fetchAuth, "errMsg", "");
  const errData = get(fetchAuth, "errData", []);

  if (errData && Array.isArray(errData) && errData.length) {
    return { ...field, isError: hasError, labelHint: errMsg, errData };
  }
  return { ...field, isError: hasError, labelHint: errMsg };
};

export const updateFieldValue = <T, K>(
  fieldsData: T[],
  id: string,
  value: K
): T[] | boolean => {
  const index = fieldsData.findIndex((field: T) => {
    const getId = get(field, "id", "");
    return getId && String(getId) === String(id);
  });
  if (index !== -1) {
    let newUserProfileData;
    const currentField = fieldsData[index];
    const type = get(currentField, "type", "");
    if (type === "CHECKBOX") {
      const loadField = {
        ...fieldsData[index],
        valueOptions: value || [],
        frontendData: {},
      };
      const hasError = get(loadField, "isError", false);
      const fetchField = hasError ? checkValidation(loadField) : loadField;
      const updateFields = [...fieldsData];
      if (updateFields && updateFields[index]) {
        updateFields[index] = {
          ...updateFields[index],
          ...fetchField,
        };
      }
      newUserProfileData = [...updateFields];
    } else if (type === "GROUP") {
      const loadField = {
        ...fieldsData[index],
        customGroupValueArray: value || [],
        frontendData: {},
      };
      const hasError = get(loadField, "isError", false);
      const fetchField = hasError ? checkValidation(loadField) : loadField;
      const updateFields = [...fieldsData];
      if (updateFields && updateFields[index]) {
        updateFields[index] = {
          ...updateFields[index],
          ...fetchField,
        };
      }
      newUserProfileData = [...updateFields];
    } else {
      const loadField = {
        ...fieldsData[index],
        value: value || "",
        frontendData: {},
      };
      const hasError = get(loadField, "isError", false);
      const fetchField = hasError ? checkValidation(loadField) : loadField;
      const updateFields = [...fieldsData];
      if (updateFields && updateFields[index]) {
        updateFields[index] = {
          ...updateFields[index],
          ...fetchField,
        };
      }
      newUserProfileData = [...updateFields];
    }
    if (["RADIO", "CHECKBOX", "DROPDOWN"].includes(type)) {
      const fetchField = { ...newUserProfileData[index], isEdited: true };
      const updateFields = [...newUserProfileData];
      if (updateFields && updateFields[index]) {
        updateFields[index] = {
          ...updateFields[index],
          ...fetchField,
        };
      }
      newUserProfileData = [...updateFields];
    }
    return newUserProfileData;
  } else return false;
};
