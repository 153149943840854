const EMAIL_ALREADY_EXISTS_LOCAL_ERROR_MESSAGE =
  "user-with-same-email-id-already-exists";
const EMAIL_ALREADY_EXISTS_REMOTE_ERROR_MESSAGE =
  "user-with-same-email-id-has-already-registered-for-this-event";
const CONFIRMATION_MAIL_SUCCESS_MESSAGE = "mail-successfully-sent";
const CONFIRMATION_MAIL_ERROR_MESSAGE =
"we-are-unable-to-deliver-your-mail-at-this-moment";
const GENERIC_ERROR_MESSAGE = "something-went-wrong";
const DEFAULT_DUPLICATE_EMAIL_ERROR_MESSAGE = "DEFAULT_DUPLICATE_EMAIL";
const DUPLICATE_EMAIL_ERROR_MESSAGE = "DUPLICATE_EMAIL";
const RE_ENTER_EMAIL_ERROR_MESSAGE = "RE_ENTER_EMAIL";

export {
  EMAIL_ALREADY_EXISTS_LOCAL_ERROR_MESSAGE,
  EMAIL_ALREADY_EXISTS_REMOTE_ERROR_MESSAGE,
  CONFIRMATION_MAIL_SUCCESS_MESSAGE,
  CONFIRMATION_MAIL_ERROR_MESSAGE,
  GENERIC_ERROR_MESSAGE,
  DUPLICATE_EMAIL_ERROR_MESSAGE,
  DEFAULT_DUPLICATE_EMAIL_ERROR_MESSAGE,
  RE_ENTER_EMAIL_ERROR_MESSAGE,
};
