import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IHeadersRequest } from "../Types/Ticket";
import {
  IValidateAttendeeEmailRequest,
  IValidateAttendeeEmailResponse,
} from "../Types/ValidateAttendeeEmail";

const useValidateAttendeeEmail = () => {
  const [data, setData] = useState<IValidateAttendeeEmailResponse>(
    {} as IValidateAttendeeEmailResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();

  const validateAttendeeEmail = useCallback(
    (headers?: IHeadersRequest, input?: IValidateAttendeeEmailRequest) => {
      let didCancel = false;
      async function fetchApi() {
        try {
          const response = await postApi<
            IValidateAttendeeEmailResponse,
            IValidateAttendeeEmailRequest
          >(AppConstants.validateAttendeeEmail, headers, input);

          if (!response.status) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData({
              ...response?.success?.data,
              emailAlreadyRegistered: Boolean(!response?.success?.data.emailAllowedToRegister),
            } as IValidateAttendeeEmailResponse);
            // setData(response?.success?.data as IValidateAttendeeEmailResponse);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, loading, error, validateAttendeeEmail };
};

export default useValidateAttendeeEmail;
