// Language: hindi

export default {
    "already-registered": "पहले से पंजीकृत हैं",
    "add-to-calendar": "कैलेंडर में जोड़ें",
    "event-starts-in": "इवेंट शुरू हो रहा है",
    "event-is-live": "इवेंट लाइव है",
    "event-has-ended": "इवेंट समाप्त हो गई है",
    "powered-by": "द्वारा संचालित",
    "please-enter-your-email-to-continue": "जारी रखने के लिए कृपया अपना ईमेल दर्ज करें",
    add: "जोड़ें",
    offers: "ऑफर",
    free: "मुफ़्त",
    "available-till": "{{DATE}} तक उपलब्ध है।",
    "enter-email-address": "ईमेल पता दर्ज करें",
    "sold-out": "बिक गया",
    "the-ticket-has-reached-the-maximum-number": "टिकट अधिकतम संख्या में पहुंच गया है",
    "seat-left": "{{SEAT}} सीट बाकी",
    "seats-left": "{{SEAT}} सीटें बाकी",
    proceed: "आगे बड़े",
    summary: "सारांश",
    "buyer-info": "खरीदार जानकारी",
    continue: "जारी रखें",
    "attendees-info": "उपस्थित लोगों की जानकारी",
    "registration": "पंजीकरण",
    "attendees-information": "उपस्थित लोगों की जानकारी",
    submit: "प्रस्तुत करना",
    "register-to-join-events": "आयोजनों में शामिल होने के लिए रजिस्टर करें",
    "by-clicking-on-the-button":
        "बटन पर क्लिक करने पर, आपको टिकटिंग प्लेटफॉर्म पर रीडायरेक्ट कर दिया जाएगा जहां आप इस इवेंट के लिए पंजीकरण करा सकते हैं।",
    other: "अन्य",
    "please-enter-a-value-for": "कृपया <FIELD_NAME> के लिए एक मान दर्ज करें",
    "please-select-a-value-for": "कृपया <FIELD_NAME> के लिए एक मान का चयन करें",
    "enter-other-value-for": "<FIELD_NAME> के लिए अन्य मान दर्ज करें",
    "enter-a-valid": "एक मान्य <FIELD_NAME> दर्ज करें",
    "should-be-at-least": "<FIELD_NAME> कम से कम <FIELD_LENGTH> <FIELD_TYPE> होना चाहिए",
    "cannot-be-more-than": "<FIELD_NAME> के <FIELD_TYPE> <FIELD_LENGTH> से अधिक नहीं हो सकते",
    "is-required": "<FIELD_NAME> आवश्यक है।",
    "at-least-required": "कम से कम <FIELD_LENGTH> <FIELD_NAME> आवश्यक <VERB>",
    "maximum-allowed": "अधिकतम <FIELD_LENGTH> <FIELD_NAME> की अनुमति <VERB>",
    "user-with-same-email-id-already-exists":
        "समान ईमेल id वाला उपयोगकर्ता पहले से मौजूद है। कृपया किसी दूसरा ईमेल का उपयोग करें।",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "समान ईमेल id वाले उपयोगकर्ता ने इस ईवेंट के लिए पहले ही पंजीकरण कर लिया है। कृपया किसी दूसरा ईमेल का उपयोग करें।",
    "mail-successfully-sent": "मेल सफलतापूर्वक भेजा गया!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "हम इस समय आपका मेल डिलीवर करने में समर्थ नहीं हैं! कृपया कुछ देर बाद कोशिश करें",
    "something-went-wrong": "कुछ गलत हो गया! बाद में पुन: प्रयास करें",
    "please-select-a-ticket-to-proceed": "आगे बढ़ने के लिए कृपया एक टिकट का चयन करे",
    "register-now": "अभी पंजीकरण करें",
    "add-to-your-calendar": "अपने कैलेंडर में जोड़ें",
    calendar: "कैलेंडर",
    "resend-link": "लिंक दोबारा भेजें",
    "go-to-event": "इवेंट पर जाएं",
    "ticket-registration-done-by": "द्वारा टिकट पंजीकरण किया गया",
    "you-have-already-purchased-the-ticket-for": "के लिए आपने पहले ही टिकट खरीद लिया है",
    purchased: "खरीदी",
    "event-date": "इवेंट दिनांक",
    "ticket-quantity": "टिकट मात्रा",
    payment: "भुगतान",
    "unable-to-submit-data": "डेटा सबमिट करने में असमर्थ",
    "unable-to-apply-promo-code": "प्रोमो कोड लागू करने में असमर्थ",
    "invalid-promo-code": "अमान्य प्रोमो कोड",
    "apply-now": "अप्लाई करें",
    "enter-discount-coupon": "डिस्काउंट कूपन डाले",
    "same-as-buyer": "खरीदार के बराबर",
    "please-check-all-the-fields-are-correctly-filled": "कृपया जांचें कि सभी फ़ील्ड सही ढंग से भरे गए हैं",
    "ticket-purchased": "टिकट खरीदा",
    "ticket-purchase-failed": "टिकट खरीद विफल हुआ",
    "you-have-successfully-purchased-the-ticket":
        "आपने सफलतापूर्वक टिकट खरीद लिया है। यह टिकट आपको कार्यक्रम में शामिल होने की अनुमति देगा। आपकी खरीदारी के लिए धन्यवाद!",
    "there-was-an-error-while-booking-your-tickets": "आपकी टिकट बुक करते समय एक त्रुटि हुई थी। कृपया पुन: कोशिश करें।",
    "try-again": "पुनः प्रयास करें",
    "you-dont-have-access": "आपके पास कुछ टिकट खरीदने की एक्सेस नहीं है",
    "please-enter-a-valid-email": "कृपया एक मान्य ईमेल पता दर्ज करें",
    "payment-summary": "भुगतान सारांश",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} सफलतापूर्वक लागू किया गया। आपने अतिरिक्त {{AMOUNT}} सहेजे हैं।",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "टैक्स चालान बनाना चाहते हैं?",
    "taxation-tnc-pp": "जारी रखकर आप हमारी सहमति देते हैं",
    "taxation-tnc": "नियम और शर्तें",
    and: "तथा",
    "taxation-pp": "गोपनीयता नीति",
    "company-name-label": "कंपनी का नाम",
    "company-name-place-holder": "कंपनी का नाम दर्ज करें",
    "taxId-label": "कंपनी कर आईडी",
    "taxId-place-holder": "कंपनी कर आईडी दर्ज करें",
    "company-address-label": "कम्पनी का पता",
    "company-address-place-holder": "पता",
    "country-place-holder": "देश चुनें",
    "state-place-holder": "राज्य चुनें",
    "city-place-holder": "शहर दर्ज करें",
    "zipcode-label": "ज़िप कोड",
    "zipcode-place-holder": "ज़िप कोड दर्ज करें",
    "company-error-msg": "कृपया कंपनी का नाम दर्ज करें",
    "taxId-error-msg": "कृपया कंपनी कर आईडी दर्ज करें",
    "address-error-msg": "कृपया पता दर्ज करें",
    "country-error-msg": "कृपया देश चुनें",
    "state-error-msg": "कृपया राज्य का चयन करें",
    "city-error-msg": "कृपया शहर दर्ज करें",
    "zipcode-error-msg": "कृपया ज़िप कोड दर्ज करें",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "नाम",
    lastName: "उपनाम",
    email: "ईमेल",
    phone: "फ़ोन",
    dialerCode: "डायलर कोड",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "आपने पहले ही टिकट खरीद लिया है",
    "upload-file": "फ़ाइल अपलोड करें",
    "browse-a-file-to-upload": "अपलोड करने के लिए फ़ाइल ब्राउज़ करें",
    "max-size": "अधिकतम आकार",
    "file-supported-types": "फ़ाइल समर्थित प्रकार",
    "uploaded-successfully": "सफलतापूर्वक अपलोड किया गया",
    "failed!-please-retry": "अनुत्तीर्ण होना! कृपया फिर से कोशिश करें",
    "this-file-type-is-not-supported-supported-attachments-are":
        "यह फ़ाइल प्रकार समर्थित नहीं है। समर्थित अनुलग्नक हैं",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "फ़ाइल का आकार बहुत अधिक है। कृपया से कम का दस्तावेज़ अपलोड करें",
    person: "आदमी",
    select: "चुनते हैं",
    search: "खोज",
    "uploaded-file": "अपलोड की गई फ़ाइल",
    uploading: "अपलोडिंग",
    delete: "हटाएं",
    "no-item": "कोई सामान नहीं",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "गूगल कैलेंडर",
    "outlook-calendar": "Outlook कैलेंडर",
    "yahoo-calendar": "याहू कैलेंडर",
    "read-more": "और पढ़ें",
    "read-less": "कम पढ़ें",
    "about-title": "के बारे में",
    "verb-are": "हैं",
    "verb-is": "है",
    character: "अक्षर",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "घंटा ",
    "timer-hrs": "घंटे",
    "timer-min": "मिनट ",
    "timer-mins": "मिनटों",
    "timer-sec": "सेकण्ड",
    "timer-secs": "सेकंडो ",
    "timer-day": "दिन",
    "timer-days": "दिने",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "कोई फ़ाइल चुना नही",
    today: "आज",
    yesterday: "बीता हुआ कल",
    tomorrow: "आने वाला कल",
    last: "अंतिम",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "पंजीकरण सफलतापूर्वक किया!",
    registrationSuccessDetail: "आपने घटना के लिए सफलतापूर्वक पंजीकरण किया है । शुक्रिया!",
    "search-here": "यहां तलाश करो..",
    "did-you-mean": "क्या आपका मतलब है ",
    DISPOSABLE_EMAIL: "दिया गया ईमेल पता एक डिस्पोजेबल ईमेल सेवा से संबंधित है",
    RISTRICTED_DOMAIN: "दिया गया ईमेल पता एक प्रतिबंधित डोमेन के भीतर है । कृपया एक मान्य ईमेल पता दर्ज करें",
    INVALID_EMAIL: "कृपया एक मान्य ईमेल पता दर्ज करें",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "भुगतान विधि चुनें",
    PAY_NOW: "अब भुगतान करें",
    INSTANT_TICKET_CONFIRMATION: "तत्काल टिकट की पुष्टि",
    PAY_VIA_OTHER_METHODS: "अन्य तरीकों से भुगतान करें",
    FUNDS_TRANSFER: "फंड ट्रांसफर",
    DIGITAL_WALLET: "डिजिटल वॉलेट",
    CHEQUE: "चेक",
    PAY_AT_VENUE: "स्थल पर भुगतान करें",
    TICKET_NOT_CONFIRMED_ORGANIZER: "टिकट (ओं) की पुष्टि तब तक नहीं की जाएगी जब तक कि इवेंट आयोजक यह स्वीकार नहीं कर लेता कि भुगतान प्राप्त हो गया है और रद्द करने के अधीन है ।",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "टिकट (ओं) की पुष्टि तब तक नहीं की जाएगी जब तक आप घटना स्थल पर भुगतान नहीं करते हैं और रद्द करने के अधीन हैं ।",
    TICKET_CONFIRMED_BY_ORGANIZER: "टिकट (ओं) की पुष्टि तब की जाएगी जब इवेंट आयोजक यह स्वीकार करेगा कि भुगतान प्राप्त हो गया है ।",
    COMPLETE_PAYMENT_OF: "का पूरा भुगतान",
    COMPLETE_PAYMENT_TICKET: "अपने टिकट के लिए भुगतान पूरा करें ।",
    PAYMENT_CONFIRMATION_TITLE: "अपना भुगतान सत्यापित करने के लिए ईवेंट आयोजक के लिए अद्वितीय लेनदेन आईडी दर्ज करें ।",
    ENTER_TRANSACTION_ID: "लेनदेन आईडी दर्ज करें",
    SHARE_DETAILS_TITLE: "यदि आप विवरण साझा करते हैं तो ईवेंट आयोजक केवल आपके भुगतान को अनुमोदित कर सकता है ।  किसी भी मुफ्त टिकट सहित आपके टिकट की पुष्टि अनुमोदन के बाद की जाएगी ।",
    UPLOAD_SCREENSHOT: "स्क्रीनशॉट अपलोड करें (वैकल्पिक)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "भुगतान विवरण साझा करें",
    TICKET_STATUS: "टिकट की स्थिति", // not found
    ORGANIZER_YET_TO_CONFIRM: "घटना आयोजक को अभी तक पुष्टि नहीं हुई है कि भुगतान प्राप्त हुआ है ।",
    TICKET_CONFIRMATION_ORGANIZER: "आपका टिकट(ओं), किसी भी मुफ्त टिकट(ओं) सहित, और चालान की पुष्टि की जाएगी और घटना आयोजक के अनुमोदन के बाद आपके साथ साझा किया जाएगा ।",
    PAYMENT_DETAILS: "भुगतान विवरण",
    FAQ_TITLE: "किसी भी प्रश्न के मामले में, अक्सर पूछे जाने वाले प्रश्न देखें",
    MAKE_PAYMENT_TITLE: "अपने टिकट की खरीद की पुष्टि करने के लिए घटना स्थल पर भुगतान करें ।",
    CONFIRM_TICKET_PRIOR_EVENT: "यदि आप घटना से पहले अपने टिकट की पुष्टि करना चाहते हैं, अब एक अलग विधि से भुगतान करें । ", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "यदि आप घटना से पहले अपने टिकट की पुष्टि करना चाहते हैं,",
    PAY_NOW_2: "अब भुगतान करें",
    WITH_DIFFERENT_METHOD: "एक अलग विधि के साथ",
    PAYMENT_NOT_RCVD: "कार्यक्रम आयोजक को भुगतान नहीं मिला है ।",
    PAYMENT_COULD_NOT_BE_VERIFIED: "साझा किए गए भुगतान विवरण को ईवेंट आयोजक द्वारा सत्यापित नहीं किया जा सका ।",
    TICKETS_NOT_AVAILABLE: "टिकट अब घटना के लिए उपलब्ध नहीं हैं ।",
    PAYMENT_DETAILS_YET_TO_SHARE: "आपको टिकट के लिए भुगतान विवरण साझा करना बाकी है ।", // not found in code
    WELCOME_BACK: "वापस स्वागत है!",
    VIEW_OR_PROCEED_PURCHASE: "आप अपने पिछले टिकट देख सकते हैं या आपके द्वारा चुने गए टिकटों की खरीद के साथ आगे बढ़ सकते हैं ।",
    VIEW_PREV_TICKETS: "पिछले टिकट देखें",
    PROCEED_TO_PURCHASE: "खरीद के लिए आगे बढ़ें",
    PREVIOUS_TICKETS: "पिछला टिकट",
    PENDING_TICKETS: "लंबित टिकट",
    CONFIRMED_TICKETS: "टिकट की पुष्टि की",
    PAYMENT_PENDING: "भुगतान लंबित",
    AWAITING_ORG_APPROVAL: "आयोजक अनुमोदन की प्रतीक्षा कर रहा है",
    CHECK_TICKET_STATUS: "टिकट की स्थिति की जाँच करें",
    PAYMENT_METHOD_NOT_AVAILABLE: "भुगतान विधि उपलब्ध नहीं है, कृपया पुनः प्रयास करें ।", // not found in code
    HERE: "यहाँ.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "कृपया खरीदे गए सभी टिकटों के लिए अद्वितीय सहभागी जानकारी अपडेट करें ।  यह उन सभी उपस्थित लोगों को घटना तक पहुंचने में सक्षम करेगा ।",
    DUPLICATE_EMAIL: "आपने यह टिकट पहले ही खरीद लिया है। आप फिर से पंजीकरण करना चुन सकते हैं। इस स्थिति में दर्ज किया गया नवीनतम डेटा सहेजा जाएगा ।",
    DEFAULT_DUPLICATE_EMAIL: "यह ईमेल पहले से किसी अन्य टिकट के लिए उपयोग किया जा चुका है। कृपया आपके द्वारा दर्ज किए गए ईमेल पते की दोबारा जांच करें या एकाधिक टिकटों के लिए एक ही ईमेल का बेझिझक उपयोग करें ।",
    "REGISTER_NOW": "अभी पंजीकरण करें",
    "REG_FAILED": "पंजीकरण विफल हो गया",
    "REG_FAILED_DESC": "आपका पंजीकरण विफल हो गया। कृपया दोबारा प्रयास करें।",
    "REG_DONE_BY": "इनके द्वारा पंजीकरण किया गया",
    "INVITE_AGAIN": "क्या पुन: आमंत्रित करना चाहते हैं?",
    "REG_SUCCESS_DONE": "पंजीकरण सफलतापूर्वक पूरा हुआ",
    "WATCH_REC_LINK": "रिकॉर्डिंग देखने के लिए लिंक के साथ एक ईमेल भेजा गया",
    "WEBINAR_INV_LINK": "वेविबनार लिंक के साथ एक आमंत्रण ईमेल भेजा गया",
    "MAIL_SENT_SUCCESS": "मेल सफलतापूर्वक भेजा गया",
    "ALREADY_REGISTERED": "आपने इसके लिए पहले ही पंजीकरण कर रखा है",
    "RESEND_INVITE": "आमंत्रण पुन: भेजें",
    "WATCH_RECORDING_REGISTER_DESC": "आप नीचे पंजीकरण कर वेबिनार की रिकॉर्डिंग देख सकते हैं।",
     
};

