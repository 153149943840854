import { IToastComp, useStore } from "Context";
import { t } from "i18next";
import { useState, useCallback, useEffect } from "react";
import { AppConstants, TOAST_TIMEOUT } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IResendEmailRequest, IResendEmailResponse } from "../Types/BuyerForm";
import { IHeadersRequest } from "../Types/Ticket";

const useResendLink = () => {
  const [data, setData] = useState<IResendEmailResponse>(
    false as IResendEmailResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();
  const { setToast } = useStore();

  useEffect(() => {
    if (data) {
      setToast({
        type: "success",
        message: t("mail-successfully-sent"),
      });
      setTimeout(() => {
        setData(false as IResendEmailResponse);
        setToast({} as IToastComp);
      }, TOAST_TIMEOUT - 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (error) {
      setToast({
        type: "error",
        message: t("we-are-unable-to-deliver-your-mail-at-this-moment"),
      });
      setTimeout(() => {
        setError(null);
        setToast({} as IToastComp);
      }, TOAST_TIMEOUT - 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const resendLink = useCallback(
    (headers?: IHeadersRequest, input?: IResendEmailRequest) => {
      setLoading(true);
      let didCancel = false;
      async function fetchApi() {
        try {
          const response = await postApi<
            IResendEmailResponse,
            IResendEmailRequest
          >(AppConstants.resendEmail, headers, input);

          if (!response.success) {
            setError("error");
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.status as IResendEmailResponse);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, loading, error, resendLink, setData };
};

export default useResendLink;
