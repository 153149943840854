import React from "react";
import { IIconProps } from "./types";

const UploadIcon = ({
  width = "5.3rem",
  height = "5.3rem",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 22L16.5 16.5L11 22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 16.5V28.875"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0363 25.2863C29.3773 24.5552 30.4368 23.3983 31.0473 21.9981C31.6579 20.598 31.7848 19.0345 31.408 17.5542C31.0313 16.074 30.1723 14.7614 28.9667 13.8235C27.7611 12.8857 26.2774 12.376 24.75 12.375H23.0175C22.6013 10.7652 21.8256 9.27075 20.7487 8.0039C19.6718 6.73706 18.3217 5.73083 16.7999 5.06087C15.2781 4.39092 13.6243 4.07466 11.9627 4.13588C10.3011 4.1971 8.67497 4.6342 7.20662 5.41433C5.73828 6.19446 4.46588 7.29731 3.48511 8.63996C2.50434 9.98262 1.84071 11.5302 1.5441 13.1662C1.2475 14.8023 1.32565 16.4843 1.77267 18.0858C2.2197 19.6873 3.02396 21.1666 4.12501 22.4125"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 22L16.5 16.5L11 22"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
