const IS_REQUIRED = "please-enter-a-value-for";
const IS_REQUIRED_SELECT = "please-select-a-value-for";
const IS_REQUIRE_OTHER = "enter-other-value-for";
const IS_INVALID_DATA = "enter-a-valid";
const MIN_DATA = "should-be-at-least";
const MAX_DATA = "cannot-be-more-than";
const GROUP_IS_REQUIRE = "is-required";
const GROUP_MIN_DATA = "at-least-required";
const GROUP_MAX_DATA = "maximum-allowed";

export {
  IS_REQUIRED,
  IS_REQUIRE_OTHER,
  IS_REQUIRED_SELECT,
  IS_INVALID_DATA,
  MIN_DATA,
  MAX_DATA,
  GROUP_IS_REQUIRE,
  GROUP_MIN_DATA,
  GROUP_MAX_DATA,
};
