// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import CustomModal from "../CustomModal";
import SharePaymentDetails from "./SharePaymentDetails";
import ChoosePaymentMethod from "./ChoosePaymentMethod";
import FAQModal from "./FaqModal";
import OrderStatus, { faqList } from "./OrderStatus";
import PaymentDetailModal from "./PaymentDetailModal";
import ReturningUserModal from "./ReturningUserModal";
import TicketConfirmStatus from "./TicketConfirmStatus";
import { useStore } from "Context";
import usePaymentMethod from "Hooks/usePaymentMethod";
import usePaymentShare from "Hooks/usePaymentShare";
import { OFFLINE_PAYMENT_LIST, ONLINE_PAYMENT_LIST, PAYMENT_METHOD_DTLS, PAYMENT_METHOD_DTLS_NAME, TS_OTHER_PAYMENT_TYPE } from "../PaymentMethod/index";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import envConfig from "config";
import PaymentStatusModal from "./PaymentStatusModal";
import TicketPaymentStatus from "../../Components/TicketPaymentStatus";
import Track from "Lib/ThirdParty/amplitude";
import usePaymentStatus from "Hooks/usePaymentStatus";
import { IVerifyPaymentStatus, IVerifyPaymentStatusRequest } from "Types/VerifyStatus";
import { IHeadersRequest } from "Types/Ticket";

const PRF_PND = "PRF_PND";
const MULTIPLE_TICKETS_ALLOWED = "MULTIPLE_TICKETS_ALLOWED";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OtherPaymentModal = (props: any): JSX.Element => {
  const { configData, handleClose, landingPageData,
    userData, onOpen, onClose, setKeepMounted } = props;
  const [radioStateSelected, setRadioStateSelected] = useState("");
  const [apiCalled, setApiCalled] = useState(0);
  const [paymentDetailShare, setPaymentDetails] = React.useState({
    transactionId: "",
    screenShot: "",
  });
  const [, setPaymentVerifyType] = useState("");
  const { store, setOtherPaymentModal, setToast, setPaymentStatusDetails, setTransactionId, setUserData, setPaymentDetails: setPaymentDetailsStore, setOtherPaymentDetails, setOrderNo, setOrderToken } = useStore();
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: paymentMethodData, dataFetched: paymentMethodDataFetched, isSubmitLoading: paymentMethodLoading, setSubmitLoading: setPaymentMethodLoading, error: paymentMethodError, paymentMethod } =
    usePaymentMethod();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: paymentShareData, isSubmitLoading: paymentShareLoading, setSubmitLoading: setPaymentShareLoading, paymentShare, dataFetched: paymentShareDataFetched, error: paymentShareError } = usePaymentShare();
  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();
  const [faqModal, setFaqModal] = useState(false);

  const {
    data: paymentStatusVerifyData,
    // error: paymentStatusVerifyError,
    // loading: paymentStatusVerifyLoading,
    paymentStatus,
  } = usePaymentStatus();

  const onCloseFaqModal = (): void => {
    setFaqModal(false);
    onOpen?.();
  };

  const onRequestClose = () => {
    setOtherPaymentModal({
      choosePaymentMethod: false,
      faqModal: false,
      orderStatus: false,
      paymentDetailModal: false,
      returningUserModal: false,
      sharePaymentDetails: false,
      ticketConfirmStatus: false,
      paymentStatusModal: false,
    });
    onClose?.();
    onOpen?.();
  };

  useEffect(() => {
    setOrderToken(userData?.orderToken);
    setOrderNo(userData?.orderNo);
    setUserData(userData);
    if (userData?.case === MULTIPLE_TICKETS_ALLOWED && !props?.isPrimaryMicroFe) {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: true,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      });
    } else if (userData?.case === PRF_PND) {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: true,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      });
    } else if (userData?.case === "APRVL_PND") {

      const payload: IVerifyPaymentStatus = {
        orderToken: userData.orderToken
      };
      paymentStatus(
        {
          authorization: userData?.newToken || configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: { ...payload },
          },
        } as IVerifyPaymentStatusRequest
      );

      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: true,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      });
    } else if (userData?.case === "APRVL_DND" || userData?.case === "PAID") {

      const payload: IVerifyPaymentStatus = {
        orderToken: userData?.orderToken,
      };
      paymentStatus(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: { ...payload },
          },
        } as IVerifyPaymentStatusRequest
      );

      setPaymentDetailsStore({
        isPurchase: true,
        currPaymentStatus:
          userData?.case === "APRVL_DND"
            ? "FAIL"
            : userData?.case === "PAID"
              ? "CONFIRM"
              : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (!isEmpty(paymentStatusVerifyData)) {
      setPaymentStatusDetails(paymentStatusVerifyData);
      if (paymentStatusVerifyData?.paymentMethod && userData?.case === "APRVL_PND") {

        let tempPaymentMethodTypesAvailable: string[] = [];
        if (paymentStatusVerifyData?.paymentMethods && Array.isArray(paymentStatusVerifyData?.paymentMethods)) {
          tempPaymentMethodTypesAvailable = paymentStatusVerifyData?.paymentMethods.map(item => item.type)
            .filter((value, index, self) => self.indexOf(value) === index);
        }

        setOtherPaymentDetails({
          ...store.otherPaymentDetails,
          paymentMethodTypesAvailable: tempPaymentMethodTypesAvailable,
          paymentMethods: paymentStatusVerifyData.paymentMethods,
          emailForOfflinePaymentMethods: paymentStatusVerifyData.emailForOfflinePaymentMethods,
          selectedPaymentMethod: NEW_PAYMENT_METHOD_DTLS[paymentStatusVerifyData?.paymentMethod]
        });
      }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusVerifyData]);

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const handleStripeCheckout = async (token: string) => {
    if (!token) return;
    let stripe: Stripe | null;
    if (store?.stripeAccountId) {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "", {
        stripeAccount: store?.stripeAccountId,
      });
    } else {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "");
    }
    const result = await stripe?.redirectToCheckout({
      sessionId: token,
    });
    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    return result;
  };

  const handlePaymentMethodSubmit = (radioStateSelected: string) => {
    const availableOtherPaymentMethod = store?.otherPaymentDetails?.paymentMethods
      ?.sort((a: any, b: any) => a.position - b.position)
      .reduce(
        (previous: any, current: any) => (
          current.type === TS_OTHER_PAYMENT_TYPE.OFL &&
          current.isActive === true &&
          previous.push({
            ...NEW_PAYMENT_METHOD_DTLS[current.name],
            details: current.details,
          }),
          previous
        ),
        []
      );
    const paymentDetail = availableOtherPaymentMethod.find(
      (data: any) => data.name === radioStateSelected
    );
    if (OFFLINE_PAYMENT_LIST.includes(radioStateSelected)) {
      Track.pendingApprovalOrderCreated(PAYMENT_METHOD_DTLS_NAME[radioStateSelected], paymentDetail?.details, String(store.orderNo), String(store?.totalAmount));
    }
    if (radioStateSelected === "") {
      handleToastError("Please select any option");
    } else {

      setRadioStateSelected(radioStateSelected);
      paymentMethod(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        },
        {
          payload: {
            data: {
              type: OFFLINE_PAYMENT_LIST.includes(radioStateSelected)
                ? "OFL"
                : "ONL",
              paymentMethod: radioStateSelected,
              ticketingLink: ONLINE_PAYMENT_LIST.includes(radioStateSelected)
                ? store.widgetData?.isWidgetExpand
                  ? configData?.communityUrl
                  : `${configData?.communityUrl}/register`
                : "",
            },
          },
        },
        store.orderToken
      );
      setApiCalled(apiCalled + 1);
    }
  };

  const handlePaymentShareSubmit = (paymentDetailShare: any) => {
    setPaymentDetails(paymentDetailShare);
    if (paymentDetailShare.transactionId) {
      setPaymentStatusDetails({});
      setTransactionId(paymentDetailShare.transactionId);
      paymentShare(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        },
        {
          payload: {
            data: {
              transactionId: paymentDetailShare?.transactionId,
              paymentProof: encodeURI(paymentDetailShare?.screenShot),
            },
          },
        },
        store.orderToken
      );
    } else {
      handleToastError("Please enter Transaction ID");
    }
  };

  const handlePaymentStatusVerify = (data: string) => {
    if (data) {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      });
    }
    setPaymentVerifyType(data);
  };




  // const ApiPolling = () => {

  // };

  useEffect(() => {
    if (paymentMethodDataFetched) {
      if (paymentMethodData && !isEmpty(paymentMethodData)) {
        if (radioStateSelected === "STP" && paymentMethodData?.stripeSessionId) {
          handleStripeCheckout(paymentMethodData?.stripeSessionId);
        }
      }
      if (radioStateSelected !== "STP") {
        if (radioStateSelected === "PAV") {
          setOtherPaymentModal({
            choosePaymentMethod: false,
            faqModal: false,
            orderStatus: true,
            paymentDetailModal: false,
            returningUserModal: false,
            sharePaymentDetails: false,
            ticketConfirmStatus: false,
          });
        } else {
          setOtherPaymentModal({
            choosePaymentMethod: false,
            faqModal: false,
            orderStatus: false,
            paymentDetailModal: true,
            returningUserModal: false,
            sharePaymentDetails: false,
            ticketConfirmStatus: false,
          });
        }
      }
      if (paymentMethodError) {
        handleToastError(
          typeof paymentMethodError === "string" ? paymentMethodError : t("something-went-wrong")
        );
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodData, radioStateSelected, apiCalled]);

  useEffect(() => {
    if (paymentShareDataFetched) {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: true,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
      });
      if (paymentShareError) {
        handleToastError(
          typeof paymentShareError === "string" ? paymentShareError : t("something-went-wrong")
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentShareData, paymentDetailShare]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query) {
      const faqQuery = query.get("faqs");
      if (faqQuery) {
        setFaqModal(true);
      } else {
        setFaqModal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomModal
        isOnlyChildren
        restrictFE
          open={
          store?.otherPaymentModal?.choosePaymentMethod ||
          store?.otherPaymentModal?.faqModal ||
          store?.otherPaymentModal?.orderStatus ||
          store?.otherPaymentModal?.paymentDetailModal ||
          store?.otherPaymentModal?.returningUserModal ||
          store?.otherPaymentModal?.ticketConfirmStatus ||
          store?.otherPaymentModal?.sharePaymentDetails ||
          store?.otherPaymentModal?.paymentStatusModal
        }
        onRequestClose={onRequestClose}
        handleClose={handleClose}
        scrollnotRequiredMob={
          store?.otherPaymentModal?.paymentDetailModal
            ? true
            : store?.otherPaymentModal?.choosePaymentMethod
              ? true
              : store?.otherPaymentModal?.ticketConfirmStatus
                ? true
                : false
        }
        autoHeigthModalContent={
          store?.otherPaymentModal?.returningUserModal
            ? true
            : store?.otherPaymentModal?.sharePaymentDetails
              ? true
              : false
        }
        isScrollModal={
          store?.otherPaymentModal?.paymentDetailModal
            ? true
            : store?.otherPaymentModal?.orderStatus
              ? true
              : store?.otherPaymentModal?.faqModal
                ? true
                : false
        }
        modalWidth={
          store?.otherPaymentModal?.ticketConfirmStatus
            ? 380
            : store?.otherPaymentModal?.returningUserModal
              ? 380
              : store?.otherPaymentModal?.sharePaymentDetails
                ? 380
                : 550
        }
      >
        {/* autoHeigthModalContent apply only if more than one ticket */}
        {store?.otherPaymentModal?.choosePaymentMethod ? (
          <ChoosePaymentMethod configData={configData} handlePaymentMethodSubmit={handlePaymentMethodSubmit} />
        ) : null}
        {store?.otherPaymentModal?.faqModal ? (
          <FAQModal faqList={faqList} orderNo={store.orderNo || ""} />
        ) : null}
        {store?.otherPaymentModal?.orderStatus ? <OrderStatus configData={configData} handlePaymentStatusVerify={handlePaymentStatusVerify} isLoading={paymentShareLoading} userData={userData} /> : null}
        {store?.otherPaymentModal?.paymentDetailModal ? (
          <PaymentDetailModal isLoading={paymentMethodLoading} configData={configData} handlePaymentShareSubmit={handlePaymentShareSubmit} />
        ) : null}
        {store?.otherPaymentModal?.returningUserModal ? (
          <ReturningUserModal configData={configData} onOpen={onOpen} setKeepMounted={setKeepMounted} />
        ) : null}
        {store?.otherPaymentModal?.sharePaymentDetails ? (
          <SharePaymentDetails landingPageData={landingPageData} configData={configData} userData={userData} />
        ) : null}
        {store?.otherPaymentModal?.ticketConfirmStatus ? (
          <TicketConfirmStatus configData={configData} />
        ) : null}
        {store?.otherPaymentModal?.paymentStatusModal ? (
          <PaymentStatusModal />
        ) : null}


      </CustomModal>
      {!isEmpty(store?.paymentDetails) && (
        <TicketPaymentStatus
          {...props}
          isLoading={false}
          getPaymentId={store?.orderToken}
          onClose={onClose}
        />
      )}
      <CustomModal
        onRequestClose={onCloseFaqModal}
        isOnlyChildren
        open={faqModal}
        isScrollModal
      >
        <FAQModal
          onClose={onCloseFaqModal}
          faqList={faqList}
          orderNo={store?.orderNo}
        />
      </CustomModal>
    </>
  );
};

export default OtherPaymentModal;
