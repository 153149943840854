import React from "react";
import { IIconProps } from "./types";

const YahooCalIcon = ({
  width = "17.2rem",
  height = "16rem",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 108 100"
      fill="none"
    >
      <path
        d="M15.0952 99.9999C15.2883 99.4914 15.4668 98.9774 15.6763 98.4759C16.4763 96.5583 17.2837 94.6431 18.0984 92.7304C18.5589 91.6454 19.0414 90.5694 19.4985 89.4804C20.1415 87.9474 20.758 86.4034 21.4056 84.8724C22.0453 83.3614 22.7001 81.8568 23.3702 80.3584C23.4837 80.1034 23.4702 79.9024 23.3652 79.6549C22.7501 78.2349 22.1491 76.8089 21.5456 75.3839C19.9122 71.5259 18.2787 67.6678 16.6453 63.8094C14.5892 58.9561 12.5311 54.1033 10.471 49.2509C9.93098 47.9814 9.35595 46.7269 8.82093 45.4559C6.62415 40.2486 4.43187 35.0394 2.24409 29.8284C1.85857 28.9134 1.43355 28.0149 1.04853 27.0994C0.689512 26.2454 0.359995 25.3789 0.000976562 24.4774H21.1061C25.2973 35.1924 29.5086 45.9591 33.7402 56.7774C33.9562 56.2404 34.1322 55.8104 34.3032 55.3774C34.6626 54.4691 35.0211 53.5601 35.3788 52.6504C36.0175 51.0334 36.6558 49.4168 37.2939 47.8004C38.3529 45.1141 39.4117 42.4276 40.47 39.7409C40.9071 38.6344 41.3481 37.5284 41.7856 36.4214C42.3523 34.9881 42.9165 33.5548 43.4782 32.1214C44.4417 29.6814 45.4113 27.2409 46.3648 24.7949C46.4648 24.5374 46.5854 24.4249 46.8679 24.4349C47.4179 24.4539 47.9679 24.4404 48.518 24.4404H67.0764C66.9184 24.8404 66.7964 25.1589 66.6654 25.4754C65.4013 28.5264 64.1364 31.5773 62.8707 34.6279C61.7556 37.3139 60.6389 39.9996 59.5205 42.6849C58.5538 45.0126 57.5861 47.3403 56.6174 49.6679C56.1028 50.9084 55.5968 52.1529 55.0823 53.3939C53.8849 56.2799 52.6862 59.1654 51.4861 62.0504C50.9961 63.2299 50.5086 64.4109 50.012 65.5874C49.5485 66.6849 49.062 67.7734 48.608 68.8749C48.1449 69.9954 47.7199 71.1319 47.2554 72.2524C46.7629 73.4464 46.2283 74.6234 45.7358 75.8179C44.8468 77.9679 43.9812 80.1304 43.0932 82.2824C42.6812 83.2824 42.2281 84.2619 41.8156 85.2599C41.1416 86.8909 40.493 88.5269 39.8205 90.1554C39.429 91.1019 38.997 92.0319 38.6074 92.9794C37.7294 95.1129 36.8659 97.2524 35.9998 99.3904C35.9193 99.5904 35.8633 99.7964 35.7963 99.9999H15.0952Z"
        fill="#634A9E"
      />
      <path
        d="M64.0713 49.869C64.3033 49.282 64.5333 48.6945 64.7678 48.1085C65.2789 46.8325 65.7784 45.551 66.3069 44.282C67.815 40.659 69.3371 37.041 70.8481 33.421C71.2827 32.378 71.6777 31.321 72.1087 30.275C72.4587 29.4305 72.8427 28.6015 73.1913 27.7575C73.6463 26.6575 74.0683 25.54 74.5258 24.4395C75.2589 22.678 76.0124 20.9255 76.7514 19.1665C77.296 17.8705 77.8315 16.5715 78.3695 15.273C79.1896 13.294 80.0136 11.3165 80.8256 9.3345C81.2962 8.1845 81.7322 7.022 82.2102 5.876C83.0288 3.913 83.8683 1.958 84.6988 0H107.8L103.526 10.3295C102.779 12.1345 102.032 13.9397 101.285 15.745C100.466 17.723 99.6461 19.7008 98.8261 21.6785C97.8375 24.0675 96.8555 26.4595 95.8614 28.8465C95.3644 30.041 94.8384 31.2195 94.3408 32.411C94.0523 33.0995 93.7988 33.8025 93.5138 34.492C91.9544 38.2717 90.3921 42.0498 88.8271 45.8265C88.401 46.8565 87.974 47.8862 87.546 48.9155C87.401 49.2625 87.249 49.607 87.096 49.9685C86.974 49.9745 86.8605 49.9855 86.7459 49.9855C79.3326 49.9855 71.9192 49.9855 64.5058 49.9855C64.3728 49.9855 64.2403 49.9745 64.1058 49.9685L64.0713 49.869Z"
        fill="#634A9E"
      />
      <path
        d="M56.9644 67.8805C56.9616 66.7643 57.1034 65.6523 57.3864 64.5725C58.0265 62.111 59.294 60.0225 61.1401 58.2895C62.9717 56.5685 65.1448 55.499 67.6064 55.0335C68.603 54.845 69.6185 54.748 70.6346 54.823C72.0087 54.923 73.3717 55.1145 74.6638 55.628C78.3845 57.1055 80.8796 59.738 82.1142 63.554C82.6232 65.1275 82.8247 66.7495 82.6692 68.385C82.1772 73.5635 79.6061 77.314 74.8513 79.5065C73.8688 79.96 72.8242 80.234 71.7552 80.399C69.7381 80.71 67.743 80.612 65.7904 80.0015C62.3012 78.9105 59.771 76.6945 58.1765 73.414C57.3404 71.691 56.9519 69.8495 56.9644 67.8805Z"
        fill="#634A9E"
      />
    </svg>
  );
};

export default YahooCalIcon;
