import { useState, useCallback } from "react";
import { postFielUpload } from "../Core/HttpClient";
import { IFileUpload, IFileUploadResponse } from "../Types/FileUpload";

const useFileUpload = () => {
  const [data, setData] = useState<IFileUploadResponse>(
    {} as IFileUploadResponse
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>();
  const [uploadingPer, setUploadingPer] = useState<number>(0);
  const uploadFile = useCallback((input?: IFileUpload, file?: File) => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await postFielUpload(input as IFileUpload, file);

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as IFileUploadResponse);
          setUploadingPer(0);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, []);

  return { data, loading, error, uploadFile, uploadingPer, setUploadingPer };
};

export default useFileUpload;
