import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IHeadersRequest } from "../Types/Ticket";

interface IGetNewStripeSession {
  orderToken: string;
  ticketingLink: string;
}
export interface IGetNewStripeSessionRequest {
  payload: {
    data: IGetNewStripeSession;
  };
}
export interface IGetNewStripeSessionResponse {
  stripeSessionId?: string;
}

const useGetNewStripeSession = () => {
  const [data, setData] = useState<IGetNewStripeSessionResponse>(
    {} as IGetNewStripeSessionResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();

  const getNewStripeSessionId = useCallback(
    (headers?: IHeadersRequest, input?: IGetNewStripeSessionRequest) => {
      let didCancel = false;
      async function fetchApi() {
        try {
          const response: any = await postApi<
            IGetNewStripeSessionResponse,
            IGetNewStripeSessionRequest
          >(AppConstants.rePaymentSession, headers, input);

          if (response?.error && response?.status === 422) {
            setError(response?.error?.data?.message || response.error.message);
          }

          if (!response.status) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.success?.data as IGetNewStripeSessionResponse);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, loading, error, getNewStripeSessionId };
};

export default useGetNewStripeSession;
