/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import CustomModal from "../CustomModal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AddCalendarIcon from "../../Assets/Svg/AddCalendarIcon";
import Link from "@mui/material/Link";
import GoogleCalIcon from "../../Assets/Svg/GoogleCalIcon";
import OutlookCalIcon from "../../Assets/Svg/OutlookCalIcon";
import YahooCalIcon from "../../Assets/Svg/YahooCalIcon";
import Grid from "@mui/material/Grid";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import Track from "../../Lib/ThirdParty/amplitude";
import { useStyles } from "./styles";
import { ICalendarTypes } from "./types";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles((theme: any) => ({
  btnStyle: {
    border: `1px solid ${theme.palette.divider.default} !important`,
    color: theme.palette.text.primary,
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomButton = (props: any) => {
  const { children, onClick } = props;
  const classes = useStyle();
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      startIcon={<AddCalendarIcon />}
      size="small"
      className={classes.btnStyle}
    >
      {children}
    </Button>
  );
};

const AddToCalendarModal = (props: {
  children: any;
  onRequestClose: () => void;
  startFromSummary: boolean;
}) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { onRequestClose ,startFromSummary} = props;

  const handelLinkClickWrapper = (type: ICalendarTypes) => {
    // Amplitude
    Track.addToCalendar({ type, lpType: startFromSummary ? "default landing page" : "custom landing page" });
  };

  return (
    <>
      <CustomModal open={true} onRequestClose={onRequestClose} isOnlyChildren>
        <Box p={2}>
          <Box
            sx={(theme: any) => ({
              borderBottom: `1px solid ${theme.palette.divider.default}`,
            })}
          >
            <Typography
              sx={{ mb: 2.5 }}
              variant="h2"
              component="h2"
              className={classes.mobileTitle}
            >
              {t("add-to-your-calendar")}
            </Typography>
          </Box>
          <Box>
            <Grid
              container
              className={classes.calendarWrapper}
              columnSpacing={1.5}
            >
              {props.children.map((c: any) => (
                <Grid item xs={12} md={6} key={c.props.href}>
                  <Link
                    href={c.props.href}
                    underline="none"
                    target="_blank"
                    onClick={() => {
                      handelLinkClickWrapper(c.key);
                    }}
                  >
                    {c.key === "Google" ? (
                      <>
                        <Box className={classes.calendarImgWrapper}>
                          <GoogleCalIcon />
                        </Box>
                        <Typography
                          component="p"
                          variant="subtitle2"
                          sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontWeight: 400,
                            mt: 1.25,
                            textAlign: "center",
                          })}
                        >
                          {t("google-calendar")}
                        </Typography>
                      </>
                    ) : c.key === "Outlook" ? (
                      <>
                        <Box className={classes.calendarImgWrapper}>
                          <OutlookCalIcon />
                        </Box>
                        <Typography
                          component="p"
                          variant="subtitle2"
                          sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontWeight: 400,
                            mt: 1.25,
                            textAlign: "center",
                          })}
                        >
                          {t("outlook-calendar")}
                        </Typography>
                      </>
                    ) : c.key === "Yahoo" ? (
                      <>
                        <Box className={classes.calendarImgWrapper}>
                          <YahooCalIcon />
                        </Box>
                        <Typography
                          component="p"
                          variant="subtitle2"
                          sx={(theme) => ({
                            color: theme.palette.text.primary,
                            fontWeight: 400,
                            mt: 1.25,
                            textAlign: "center",
                          })}
                        >
                          {t("yahoo-calendar")}
                        </Typography>
                      </>
                    ) : null}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default AddToCalendarHOC(CustomButton, AddToCalendarModal);
