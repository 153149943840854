/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import CustomModal from "./CustomModal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import { ITicketInfoProps } from "..";
import { getEventDateRange } from "../Utils/date";
import { IValidateEmailResponse } from "../Types/ValidateEmail";
import {
  ALREADY_BOUGHT_ERROR,
  APRVL_DND,
  APRVL_PND,
  IHeadersRequest,
  ITicketDetailsResponse,
} from "../Types/Ticket";
import { ILandingPage } from "../Types/LandingPage";
import useResendLink from "../Hooks/useResendLink";
import { IResendEmailRequest, IResendEmailResponse } from "../Types/BuyerForm";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import Toast from "../Components/Toast";
import isEmpty from "lodash/isEmpty";
import { TOAST_TIMEOUT } from "../Constants/AppConstants";
import { useStore } from "Context";
import usePaymentStatus from "Hooks/usePaymentStatus";
import {
  IVerifyPaymentStatus,
  IVerifyPaymentStatusRequest,
} from "Types/VerifyStatus";
import TicketPaymentStatus from "./TicketPaymentStatus";
import { PAYMENT_METHOD_DTLS } from "./PaymentMethod/index";
import { isAndroidIPhoneIPad } from "Utils/navigator";
import StringParser from "Components/StringParse";

const useStyles = makeStyles((theme: any) => ({
  ticketListCommon: {
    borderRadius: 7,
    border: `1px solid ${theme.palette.divider.default}`,
    position: "relative",
    fontWeight: 400,
    paddingBottom: 0,
    boxShadow: "none",
    overflow: "visible",
    background: theme.palette.background.cardColor,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      "& .ticketPrice": {
        "&::before": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderLeft: 0,
        },
        "&::after": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRight: 0,
        },
      },
    },
    "&.active": {
      border: `1px solid ${theme.palette.primary.main}`,
      "& .ticketPrice": {
        "&::before": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderLeft: 0,
        },
        "&::after": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRight: 0,
        },
      },
    },
    "& .roundIcon": {
      border: `1px solid ${theme.palette.divider.default}`,
      height: 20,
      width: 20,
      padding: theme.spacing(1.5),
      color: theme.palette.text.primary,
      "&.moreInfo": {
        border: "none",
      },
      "&>svg": {
        width: "20px",
        height: "20px",
        maxWidth: "20px",
      },
    },
    "& .MuiCardContent-root": {
      "&:last-child": {
        paddingBottom: 12,
      },
    },
    "& .ticketPrice": {
      borderTop: `1px dashed ${theme.palette.divider.default}`,
      paddingTop: "20px !important",
      paddingBottom: "8px !important",

      position: "relative",

      "&::before": {
        content: "''",
        width: 12,
        height: 21.4,
        backgroundColor: theme.palette.background.cardColor,
        borderRadius: "50%",
        position: "absolute",
        left: -21,
        top: -11.8,
        border: `1px solid ${theme.palette.divider.default}`,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        borderLeft: 0,
      },
      "&::after": {
        content: "''",
        width: 12,
        height: 21.4,
        backgroundColor: theme.palette.background.cardColor,
        borderRadius: "50%",
        position: "absolute",
        right: -21,
        top: -11.8,
        border: `1px solid ${theme.palette.divider.default}`,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
        borderRight: 0,
      },
    },
  },
  widgetTicketPurchasedLink: {
    "@media(max-width:480px)": {
      position: "absolute",
      bottom: 0,
      width: "calc(100% - 32px)",
    },
  },
}));
export interface TicketPurchasedProps extends ITicketInfoProps {
  open?: boolean;
  handleClose?: () => void;
  email?: string;
  validateEmailResponse?: IValidateEmailResponse;
  landingPageData?: ILandingPage;
  ticketData: ITicketDetailsResponse;
  ticketCase?: string;
}

dayjs.extend(relativeTime);

const TicketsPurchasedList = (props: TicketPurchasedProps) => {
  const isMobile = isAndroidIPhoneIPad();
  const {
    configData,
    email,
    validateEmailResponse,
    landingPageData,
    handleClose = null,
    eventInfo,
    ticketData,
    ticketCase,
  } = props;
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    store,
    setPaymentDetails,
    setPaymentStatusDetails,
    setOtherPaymentModal,
    setOtherPaymentDetails,
    setTransactionId,
  } = useStore();
  const [toast, setToast] = useState({});
  const {
    data: paymentStatusVerifyData,
    error: paymentStatusVerifyError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    paymentStatus,
    loading: paymentStatusVerifyLoading,
  } = usePaymentStatus();
  const {
    data: resendLinkData,
    resendLink,
    loading: resentLinkLoading,
    setData: resetResentData,
  } = useResendLink();

  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCheckTicketStatus = () => {
    const payload: IVerifyPaymentStatus = {
      orderToken: store?.orderToken || "",
    };
    paymentStatus(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: { ...payload },
        },
      } as IVerifyPaymentStatusRequest
    );
  };
  const handleResendLink = () => {
    resendLink(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: {
            buyerEmail: email,
          },
        },
      } as IResendEmailRequest
    );
  };

  const handleGoToEvent = () => {
    window.open(configData?.originalCommunityUrl, "_blank");
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  useEffect(() => {
    if (resendLinkData) {
      setToast({
        type: "success",
        message: t("mail-successfully-sent"),
      });
      setTimeout(() => {
        resetResentData(false as IResendEmailResponse);
        setToast({});
        if (handleClose) handleClose();
      }, TOAST_TIMEOUT - 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendLinkData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    if (
      !isEmpty(paymentStatusVerifyData) &&
      paymentStatusVerifyData?.paymentMethod
    ) {
      setPaymentDetails({
        isPurchase: true,
        currPaymentStatus:
          paymentStatusVerifyData?.type === APRVL_DND
            ? "FAIL"
            : paymentStatusVerifyData?.type === "PAID"
              ? "CONFIRM"
              : "",
      });
      setPaymentStatusDetails(paymentStatusVerifyData);
      if (paymentStatusVerifyData?.type === APRVL_PND) {
        setTransactionId(paymentStatusVerifyData?.paymentId);
        setOtherPaymentDetails({
          ...store.otherPaymentDetails,
          selectedPaymentMethod:
            NEW_PAYMENT_METHOD_DTLS[paymentStatusVerifyData?.paymentMethod],
        });
        setOtherPaymentModal({
          choosePaymentMethod: false,
          faqModal: false,
          orderStatus: true,
          paymentDetailModal: false,
          returningUserModal: false,
          sharePaymentDetails: false,
          ticketConfirmStatus: false,
        });
      }
    }
    if (paymentStatusVerifyError) {
      handleToastError(
        typeof paymentStatusVerifyError === "string"
          ? paymentStatusVerifyError
          : t("something-went-wrong")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusVerifyData]);

  const onRequestCloseHandler = () => {
    if (props.handleClose) {
      props.handleClose();
      if (isMobile) {
        window.location.reload(); // If we close on Mobile Mode if will be reload so it will reset the state
      }
    }
  };

  return (
    <>
      <CustomModal
        open={props.open}
        onRequestClose={onRequestCloseHandler}
        isOnlyChildren
        modalWidth={400}
      >
        <Box
          px={2}
          display="block"
          sx={{ paddingTop: { xs: 6, sm: 9, md: 2, lg: 3 } }}
        >
          <Typography
            sx={(theme: any) => ({
              marginBottom: "5px !important",
              mt: "0px !important",
              color: theme.palette.text.secondary,
              letterSpacing: 0,
              fontWeight: 700,
            })}
            variant="caption"
            component="p"
          >
            {t("ticket-registration-done-by")} {email}
          </Typography>
          {ticketCase === ALREADY_BOUGHT_ERROR ? (
            <Typography
              sx={{ mb: 2.5, fontWeight: 700 }}
              variant="body1"
              component="h3"
              color="textPrimary"
            >
              {t("you-have-already-purchased-ticket-for")}{" "}
              {landingPageData?.eventName}
            </Typography>
          ) : (
            false
          )}
          {ticketCase === APRVL_PND ? (
            <Typography
              sx={{ mb: 2.5, fontWeight: 700 }}
              variant="body1"
              component="h3"
              color="textPrimary"
            >
              The event organizer is yet to approve your ticket(s).
            </Typography>
          ) : (
            false
          )}

          <Box
            sx={{
              overflowY: "auto",
              maxHeight: {
                xs: "calc((var(--vh, 1vh) * 100) - 30rem)",
                md: "calc((var(--vh, 1vh) * 100) - 30rem)",
              },
            }}
          >
            {validateEmailResponse?.tickets?.map((eachTicket) => (
              <Card
                variant="outlined"
                sx={{ borderRadius: 2.5, mb: 2.5 }}
                className={`${classes.ticketListCommon}`}
                key={`purchased-ticket-${eachTicket.id}`}
              >
                <CardContent>
                  <Box>
                    <Typography
                      component="h6"
                      variant="body2"
                      sx={(theme: any) => ({
                        fontWeight: 700,
                        mb: 1,
                        color: `${theme.palette.text.primary} !important`,
                      })}
                    >
                      {eachTicket.title}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      sx={(theme) => ({
                        mb: "8px !important",
                        mt: "0px !important",
                        fontWeight: 400,
                        color: theme.palette.text.secondary,
                      })}
                    >
                      {`${t("purchased")} ${dayjs(
                        Number(eachTicket.purchasedTime)
                      )?.fromNow()}`}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      sx={(theme) => ({ mb: "12px !important", mt: "0px !important", color: theme.palette.text.secondary })}
                    >
                      <StringParser color="textSecondary" htmlStr={eachTicket.desc} />
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      sx={(theme) => ({
                        mb: "16px !important",
                        mt: "0px !important",
                        fontWeight: 400,
                        color: theme.palette.text.secondary,
                      })}
                    >
                      {`${t("event-date")} ${getEventDateRange(
                        landingPageData?.eventStartime as string,
                        landingPageData?.eventEndtime as string
                      )}`}
                    </Typography>
                  </Box>
                  <Box
                    className="ticketPrice"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      component="p"
                      variant="caption"
                      sx={{
                        fontWeight: 400,
                        mt: "0px !important",
                        mb: "0px !important",
                      }}
                    >
                      {t("ticket-quantity")}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      sx={(theme) => ({ color: theme.palette.primary.main })}
                    >
                      {eachTicket.qty}
                    </Typography>
                    {/* <Button variant="contained" sx={{width:"100%"}} color="primary">Share Payment Details</Button> */}
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Box>
          <Box
            sx={{ mt: 1, mb: 2.5 }}
            className={
              store?.widgetData?.isWidgetExpand
                ? classes.widgetTicketPurchasedLink
                : ""
            }
          >
            {validateEmailResponse?.canShowResendLink &&
              !(validateEmailResponse?.case === APRVL_PND) && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => handleResendLink()}
                >
                  {resentLinkLoading ? (
                    <CircularProgress
                      sx={(theme: any) => ({
                        width: "20px !important",
                        height: "20px !important",
                        color: theme.palette.text.secondaryFontColor,
                      })}
                    />
                  ) : (
                    `${t("resend-link")}`
                  )}
                </Button>
              )}
            {(!validateEmailResponse?.canShowResendLink ||
              validateEmailResponse?.case === APRVL_PND) && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    mt:
                      !validateEmailResponse?.canShowResendLink ||
                        validateEmailResponse?.case === APRVL_PND
                        ? 1
                        : 0,
                  }}
                  onClick={() => handleCheckTicketStatus()}
                >
                  {resentLinkLoading ? (
                    <CircularProgress
                      sx={(theme: any) => ({
                        width: "20px !important",
                        height: "20px !important",
                        color: theme.palette.text.secondaryFontColor,
                      })}
                    />
                  ) : (
                    t("CHECK_TICKET_STATUS")
                  )}
                </Button>
              )}
            {ticketData?.showGoToEventCTA &&
              eventInfo?.communityPageShow &&
              ticketCase !== APRVL_PND && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ mt: 1 }}
                  onClick={handleGoToEvent}
                >
                  {t("go-to-event")}
                </Button>
              )}
          </Box>
        </Box>
        {!isEmpty(toast) ? <Toast open={!isEmpty(toast)} {...toast} /> : null}
      </CustomModal>
      <Box>
        {paymentStatusVerifyData &&
          !isEmpty(paymentStatusVerifyData) &&
          !paymentStatusVerifyLoading && (
            <TicketPaymentStatus
              // eslint-disable-next-line no-console
              onOpen={() => console.log("Function not required")}
              {...props}
              isLoading={paymentStatusVerifyLoading}
              getPaymentId={store?.orderToken}
              isShowGoToEventCTA={
                ticketData?.showGoToEventCTA &&
                eventInfo?.communityPageShow &&
                ticketCase !== APRVL_PND
              }
            />
          )}
      </Box>
    </>
  );
};

export default TicketsPurchasedList;
