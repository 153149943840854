import { useStore } from "Context";
import { useState, useCallback, useEffect } from "react";
import { IHeadersRequest } from "Types/Ticket";
import {
  ActiveModalTypes,
  IFieldProps,
  IWebinarFormRequest,
  IWebinarFormResponse,
  PaymentStatus,
} from "Types/WebinarForm";
import { AppConstants } from "../Constants/AppConstants";
import { getWebinarApi, postApi } from "../Core/HttpClient";
import { getUTM } from "Utils/common";
import isEmpty from "lodash/isEmpty";
import {
  AppType,
  trackEventPixelsByApp,
} from "Utils/trackingPixels";
import { useTranslation } from "react-i18next";

const useWebinarForm = (
  headers: IHeadersRequest,
  webinarData?: IWebinarFormResponse | null,
  resetProfileFields?: () => void
) => {
  const [data, setData] = useState<IWebinarFormResponse>(
    {} as IWebinarFormResponse
  );
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
    null
  );
  const [t] = useTranslation();

  const [isSubmitLoader, setSubmitLoader] = useState<boolean>(false);
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();
  const {
    store: { landingPageData, webinarFields, configData },
    setConfigData,
    setActiveModalData,
    setWebinarModalOpen: setOpen,
  } = useStore();
const allowSessionOnDemandValue =
    // @ts-ignore
    landingPageData?.sessions?.data[0]?.data[0]?.streamTypeId === 6
      ? // @ts-ignore
      landingPageData?.sessions?.data[0]?.data[0]?.preRecordedIsReplayVideo
      : // @ts-ignore
      landingPageData?.sessions?.data[0]?.data[0]?.allowSessionOnDemand;

  const eventEnd = landingPageData?.eventEndtime && (landingPageData?.eventEndtime && Date.now() > Number(landingPageData?.eventEndtime));

  useEffect(() => {
    // eslint-disable-next-line no-console
    if (paymentStatus === PaymentStatus.SUCCESS) {
      const currentEmail = webinarFields.find(
        (field: IFieldProps) => field.fieldType === "email"
      )?.value;
      setActiveModalData({
        title: `${t("REG_SUCCESS_DONE")}!`,
        subTitle: (allowSessionOnDemandValue === 1 && eventEnd) ? `${t("WATCH_REC_LINK")} ${currentEmail}` : `${t("WEBINAR_INV_LINK")} ${currentEmail}`,
        type: ActiveModalTypes.SUCCESS,
      });
      setOpen(true);
     
      if (resetProfileFields) {
        resetProfileFields();
      }
       // this is done for webinar
      // eslint-disable-next-line no-unused-expressions
              !isEmpty(headers?.trackingPixelApps) &&
              headers?.trackingPixelApps
                ?.filter((eachApp: { isEnabled: boolean }) => eachApp.isEnabled)
                .filter((eachItem: { app: AppType }) => {
                   trackEventPixelsByApp(
                    eachItem.app,
                    "successRegistration" as string
                  );
                });
    } else if (paymentStatus === PaymentStatus.ERROR) {
      setActiveModalData({
        title: `${t("REG_FAILED")}`,
        subTitle: `${t("REG_FAILED_DESC")}`,
        type: ActiveModalTypes.ERROR,
      });
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  useEffect(() => {
    if (emailAlreadyRegistered) {
      const currentEmail = webinarFields.find(
        (field: IFieldProps) => field.fieldType === "email"
      )?.value;
      setActiveModalData({
        title: `${t("ALREADY_REGISTERED")} ${landingPageData.eventName}.`,
        subTitle: `${t("REG_DONE_BY")} ${currentEmail}. ${t("INVITE_AGAIN")}`,
        type: ActiveModalTypes.RESEND,
      });
      setOpen(true);
    } else {
      setActiveModalData(null);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAlreadyRegistered]);

  const getWebinarForm = useCallback(() => {
    let didCancel = false;
    if (webinarData) {
      setData(webinarData);
      return () => {
        didCancel = true;
      };
    }
    async function fetchApi() {
      setFormLoading(true);
      try {
        const response = await getWebinarApi<
          IWebinarFormResponse,
          IWebinarFormRequest
        >(
          AppConstants.getWebinarRegistrationForm, //todo please change sourav
          headers
        );

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as IWebinarFormResponse);
          if (configData) {
            setConfigData({
              ...configData,
              webinarData: {
                ...configData?.webinarData,
                ...response?.success?.data,
              },
            });
          } else {
            setConfigData({
              webinarData: response?.success?.data,
            });
          }
        }
      } catch (err) {
        setError(err);
      } finally {
        setSubmitLoading(false);
        setFormLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitWebinarForm = useCallback(
    (input) => {
      let didCancel = false;
      async function submitApi() {
        try {
          setPaymentStatus(null);
          setEmailAlreadyRegistered(false);
          const email = input.payload?.data?.values?.find(
            (field: IFieldProps) => field.fieldType === "email"
          )?.value;
          const hasTicket = input.payload?.ticketId || "";
          const validateEmailApiResponse = await postApi<any, any>(
            AppConstants.validateAttendeeEmail, //todo please change sourav
            headers,
            {
              payload: {
                data: {
                  email,
                  ticketId: hasTicket,
                  allowSessionOnDemand: allowSessionOnDemandValue === 1 && eventEnd ? true : false
                },
              },
            }
          );
          if (
            !validateEmailApiResponse.success?.data?.emailAllowedToRegister ||
            validateEmailApiResponse.success?.data
              ?.hasTicketBeenPurchasedForThisAttendee
          ) {
            if (
              validateEmailApiResponse.success?.data?.communityLoginUrl?.length &&
              (configData?.isODSessionEmailRegEnabled || validateEmailApiResponse.success?.data?.isODSessionEmailRegEnabled) && 
              allowSessionOnDemandValue === 1 && eventEnd
            ) {
              setSubmitLoader(true);
              // window.open(validateEmailApiResponse.success?.data?.communityLoginUrl, "_self");
              if(window?.top) window.top.location.href = validateEmailApiResponse.success?.data?.communityLoginUrl;
            } else {
              setEmailAlreadyRegistered(true);
            }
          } else if (
            validateEmailApiResponse.success?.data?.isRestrictedDomain
          ) {
            setError(validateEmailApiResponse.success.data?.message);
          } else {
            const utm = getUTM();
            const response = await postApi<
              IWebinarFormResponse,
              IWebinarFormRequest
            >(
              AppConstants.attendeeForm, //todo please change sourav
              headers,
              {
                payload: {
                  data: {
                    ...input.payload.data,
                    values: [input.payload.data.values],
                    utm,
                  },
                },
              }
            );
            // eslint-disable-next-line no-console
            if (!response.success) {
              setError(response.error.message);
              setPaymentStatus(PaymentStatus.ERROR);
              setEmailAlreadyRegistered(false);
              throw new Error(response.error.message);
            }
            if (!didCancel) {
              if(response?.success?.data?.communityLoginUrl?.length && 
                (configData?.isODSessionEmailRegEnabled || response?.success?.data?.isODSessionEmailRegEnabled) && 
                allowSessionOnDemandValue === 1 && eventEnd) {
                // window.open(response?.success?.data?.communityLoginUrl, "_self");
                setSubmitLoader(true);
               if(window?.top) window.top.location.href = response?.success?.data?.communityLoginUrl;
              } else {
                setData(response?.success?.data as IWebinarFormResponse);
                setPaymentStatus(PaymentStatus.SUCCESS);
                setEmailAlreadyRegistered(false);
              }
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("::::: err", err);
          setError(err);
          setPaymentStatus(PaymentStatus.ERROR);
        } finally {
          setSubmitLoading(false);
        }
      }
      submitApi();
      return () => {
        didCancel = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentStatus]
  );

  return {
    data,
    setData,
    paymentStatus,
    formLoading,
    setPaymentStatus,
    emailAlreadyRegistered,
    setEmailAlreadyRegistered,
    isSubmitLoading,
    error,
    getWebinarForm,
    submitWebinarForm,
    setSubmitLoading,
    isSubmitLoader
  };
};

export default useWebinarForm;
