import Box from "@mui/material/Box";
import React from "react";

export default function CircularLoader(){
    // const classes = useStyles();
    return(
        <Box p={2} sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor:"rgba(0,0,0,0.1)",
                // position: "absolute",
                // top:0,
                // left:0,
                minHeight: 540,
                "& > svg":{
                    animation:"spin 1.5s linear infinite",
                },
                "@keyframes spin": { 
                    "100%": {
                      transform: "rotate(360deg)",
                    },
                  },
         }}>  
            <svg width="64" height="64" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_2" maskUnits="userSpaceOnUse" x="-95" y="-95" width="445" height="445">
                <path d="M349.722 -94.5898H-94.5898V349.722H349.722V-94.5898Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1_2)">
                    <path d="M225.015 116.864C227.454 138.216 222.815 158.758 212.963 176.142" stroke="#1856B1" strokeWidth="24" strokeLinecap="round"/>
                    <path d="M138.879 225.223C117.527 227.662 96.9849 223.023 79.6008 213.171" stroke="#86B6FC" strokeWidth="24" strokeLinecap="round"/>
                    <path d="M30.5081 139.083C28.0688 117.731 32.7081 97.1888 42.5596 79.8047" stroke="#1856B1" strokeWidth="24" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M116.653 30.7149C138.005 28.2756 158.548 32.9149 175.932 42.7664" stroke="#86B6FC" strokeWidth="24" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
            </svg>
        </Box>
    );
}

