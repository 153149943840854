/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import { Button, Typography, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Slider from "react-slick";
import { ITicketInfoProps } from "../..";
import get from "lodash/get";
import BuyerAttendeeDetailLoader from "../Loaders/BuyerAttendeeDetailLoader";
import CircularProgress from "@mui/material/CircularProgress";
import StepperWithBackButton from "../StepperWithBackButton";
import { useStyles } from "./styles";
import { IPaymentDetails, useStore } from "../../Context";
// import useAttendeeFormSet from "../../Hooks/useAttendeeFormSet";
import {
  IAttendeeFormSetRequest,
  IAttendeeFormSetRequestPayload,
  IAttendeeFormSetResponse,
} from "../../Types/AttendeeFormSet";
import { IHeadersRequest, ITicketDetailsResponse } from "../../Types/Ticket";
import AttendeeFields, { IFieldProps } from "./AttendeeFields";
import Grid from "@mui/material/Grid";
import { addSomePropertiesInField } from "../../Utils/FieldsPropertiesHelpers";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { isAndroidIPhoneIPad } from "../../navigator";
import {
  getFormFieldsAuthenticate,
  getNumberValidate,
  getTextValidate,
} from "../../Utils/FieldsValidation";
import { IRegion, IRegistrationField } from "../../Types/BuyerDetails";

import {
  getPhoneCode,
  populateBuyerDataForAttendee,
  updateGroupField,
  updateErrorFieldHelper,
  updateUserField,
  updateAttendeeUsers,
} from "./helper";
import {
  EMAIL_ALREADY_EXISTS_LOCAL_ERROR_MESSAGE,
  EMAIL_ALREADY_EXISTS_REMOTE_ERROR_MESSAGE,
} from "../../Constants/ERROR_MESSAGES";
import useValidateAttendeeEmail from "../../Hooks/useValidateAttendeeEmail";
import { IValidateAttendeeEmailRequest } from "../../Types/ValidateAttendeeEmail";
import debounce from "lodash/debounce";
import useAttendeeForm from "../../Hooks/useAttendeeForm";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { AppConstants, PageState } from "../../Constants/AppConstants";
import Track from "../../Lib/ThirdParty/amplitude";
import { IEventInfo } from "Types/LandingPage";
import { AppType, trackEventPixelsByApp } from "Utils/trackingPixels";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IApplyDiscount, ITicketsInfo } from "../../Types/ApplyDiscount";
import { ITicketData } from "../../Types/LandingPage";
import useApplyDiscount from "Hooks/useApplyDiscount";
import { postApi } from "../../Core/HttpClient";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import envConfig from "config";
import customRegex from "../../Utils/RegEx";
import { PAYMENT_METHOD_DTLS } from "../PaymentMethod/index";
import { getUTM } from "Utils/common";

interface SliderArrowProps {
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

const NextArrow = ({
  className = "",
  onClick,
}: SliderArrowProps): JSX.Element => {
  return (
    <Box className={className} onClick={onClick}>
      <NavigateNextIcon fontSize="small" />
    </Box>
  );
};

const PrevArrow = ({
  className = "",
  onClick,
}: SliderArrowProps): JSX.Element => {
  return (
    <Box className={className} onClick={onClick}>
      <NavigateBeforeIcon fontSize="small" />
    </Box>
  );
};

const settings = {
  className: "oneSideArrow",
  dots: false,
  centerMode: false,
  infinite: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  variableWidth: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  autoPlay: false,
  pauseOnHover: true,
};

const BUYERS_DEFAULT_FIELDS: string[] = ["email"];

export type AttendeesDetailsForm = {
  className?: string;
  apiData?: any;
  configData?: any;
  eventInfo?: IEventInfo;
  ticketData?: ITicketDetailsResponse;
  isDudaIntegrate?: boolean;
  onCompleteFreePurchase?: (details: IPaymentDetails) => void;
  setKeepMounted: () => void;
  onOpen: () => void;
  microFeEventHandler?: () => void;
  onClose?: () => void;
};

const AttendeesDetailsForm = ({
  className = "",
  apiData,
  configData,
  eventInfo,
  ticketData,
  isDudaIntegrate,
  onCompleteFreePurchase,
  setKeepMounted,
  onOpen,
  microFeEventHandler,
  onClose,
}: AttendeesDetailsForm): JSX.Element => {
  const { data: validateEmailResponse, validateAttendeeEmail } =
    useValidateAttendeeEmail();
  const [t] = useTranslation();
  const classes = useStyles();
  const {
    store,
    setPaymentDetails,
    setPageState,
    setRegistrationToggle,
    setToast,
    setOtherPaymentDetails,
    setOtherPaymentModal,
  } = useStore();
  const { discountData, totalAmount, stripeAccountId } = store;
  // const { data: validateEmailDomainResponse, validateEmailDomain } = useValidateEmailDomain();

  const {
    data: attendeeFormData,
    setData: setAttendeeFormData,
    error: attendeeFormError,
    attendeeFormSave,
    setSubmitLoading,
    setError: setAttendeeFormError,
  } = useAttendeeForm();
  const [showArrow, setShowArrow] = useState(false);
  const [skipUseEffect, setSkipUseEffect] = useState(true);
  const TabSlideContainerRef = useRef<HTMLDivElement>(null);
  // const [emailErrorCodeFor,setEmailErrorCodeFor]= useState("");

  const STRUCTURED_ATTENDEE_FIELDS: any = {};
  apiData?.attendeeFormFields?.forEach((ticket: any) => {
    STRUCTURED_ATTENDEE_FIELDS[ticket.id] = {
      fields: addSomePropertiesInField(ticket.formFields),
      orderTicketId: ticket.orderTicketId,
    };
  });

    const formFields = STRUCTURED_ATTENDEE_FIELDS;
  const summary: any = [];
  const attendeeDetails: any = [];
  const [attendees, setAttendees] = React.useState<any[]>([]);
  const [isLoadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const tickets: any = store?.ticketList;
  const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(false);
  const [activeTicketId, setActiveTicketId] = useState<any>(null);
  const [activeAttendeePos, setActiveAttendeePos] = useState<string>("");
  const isBuyerFrom = store?.isBuyerFrom;
  const buyerRegisteredAsAttendee = store?.buyerRegisteredAsAttendee;
  const buyerEmail = store?.buyerEmail;
  const orderToken = discountData?.orderToken;
  const buyerFields = store?.buyerFields;
  const [selectedTickets, setSelectedTickets] = useState([]); // selected tickets
  const [uniqueEmails, setUniqueEmails] = useState<any>([]);
  const [ticketsWithError, setTicketsWithError] = React.useState<any>([]);
  const [isVisible, setIsVisible] = useState(true);
  const [acceptCompliance, setAcceptCompliance] = useState<boolean>(false);
  const isSimpleRegistrationEnabled = ticketData?.isSimpleRegistrationEnabled;

  const [sameAsBuyer, setSameAsBuyer] = useState({
    ticketId: null,
    status: false,
  });

  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();

  const loading = true;

  const updateErrorField = useCallback(
    (data: any, users: any) => {
      const attendeesData = updateErrorFieldHelper(data, users);
      setAttendees({ ...attendees, ...attendeesData });
    },
    [attendees]
  );

  const handleScroll = () => {
    setIsVisible(false);
    if (store?.widgetData?.isWidgetExpand) {
      setTimeout(() => {
        setIsVisible(true);
      }, 300);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      setAttendeeFormData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsContinueDisabled(true);
  }, []);

  useEffect(() => {
    setIsContinueDisabled(false);
    isSimpleRegistrationEnabled ||
      (formFields &&
        summary &&
        attendeeDetails &&
        summary.forEach((ticket: any, index: any) => {
          const fields = formFields[String(ticket.id)]?.fields;
          if (Array.isArray(fields) && fields.length === 0) {
            setIsContinueDisabled(true);
          }
          const count = ticket.quantity;
          let _users: any = [];
          for (let i = 0; i < count; i++) {
            _users = [..._users, fields];
          }
          const obj: any = {
            ticketId: ticket.id,
            users: _users,
          };
          const userData = updateAttendeeUsers(obj);
          setAttendees({ ...attendees, ...userData });
          if (index === 0) {
            setActiveTicketId(ticket.id);
          }
          attendeeDetails
            .filter(
              (attendee: any) =>
                attendee.formFields?.length && ticket.id === attendee.id
            )
            .forEach((attendee: any, attendeeIndex: any) => {
              const currentEmail = attendee.formFields.find(
                (field: any) => field.fieldType === "email"
              )?.value;
              if (currentEmail === buyerEmail) {
                setSameAsBuyer({
                  ticketId: ticket.id,
                  status: true,
                });
              }
              attendee.formFields.forEach((field: any) => {
                updateUserField(
                  {
                    ticketId: ticket.id,
                    // orderTicketId: ticket.orderTicketId,
                    attendeeIndex: attendeeIndex,
                    fieldId: field.id,
                    fieldKey: field.fieldType,
                    fieldValue: field.value,
                    fieldCustomGroupValueArray: field.customGroupValueArray,
                  },
                  attendees
                );
              });
            });
        }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendeeDetails, summary, buyerEmail]);

  useEffect(() => {
    if (tickets.length) {
      setSelectedTickets(
        tickets.filter((ticket: any) => ticket.selectedQuantity > 0)
      );
    }
  }, [tickets]);

  useEffect(() => {
    if (selectedTickets && attendees) {
      selectedTickets.forEach((ticket) => {
        attendees[ticket["id"]] &&
          attendees[ticket["id"]].forEach(
            (attendee: any[], attendeeIndex: any) => {
              let email = attendee.find(
                (field) => field.fieldType === "email"
              )?.value;
              email = email?.trim()?.toLowerCase();
              if (email) {
                if (
                  !uniqueEmails.find(
                    (x: any) =>
                      x["ticketId"] === ticket["id"] &&
                      x["attendeeIndex"] === attendeeIndex &&
                      x["value"] === email
                  )
                ) {
                  const currentRowAlreadyExists = uniqueEmails.find(
                    (x: any) =>
                      x["ticketId"] === ticket["id"] &&
                      x["attendeeIndex"] === attendeeIndex
                  );
                  if (currentRowAlreadyExists) {
                    setUniqueEmails([
                      ...uniqueEmails.map((row: any) => {
                        if (
                          row.ticketId === ticket["id"] &&
                          row.attendeeIndex === attendeeIndex
                        ) {
                          return {
                            ...row,
                            value: email,
                          };
                        }
                        return row;
                      }),
                    ]);
                  } else {
                    const obj = {
                      ticketId: ticket["id"],
                      attendeeIndex,
                      value: email,
                    };
                    setUniqueEmails([...uniqueEmails, obj]);
                  }
                }
              }
            }
          );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees, selectedTickets]);

  useEffect(() => {
    const isTicketCheck = selectedTickets && Array.isArray(selectedTickets);
    if (Object.keys(formFields).length > 0 && isTicketCheck) {
      let tempUserData = {}; // added
      setIsContinueDisabled(false);
      !attendeeDetails?.length &&
        selectedTickets &&
        selectedTickets.forEach((_ticket: any, index) => {
          try {
            const fields = formFields[_ticket.id]
              ? formFields[_ticket.id].fields
              : [];
            if (Array.isArray(fields) && fields.length === 0) {
              setIsContinueDisabled(true);
            }
            const count = _ticket?.selectedQuantity;
            let _users: any = [];
            for (let i = 0; i < count; i++) {
              _users = [..._users, fields];
            }
            const obj: any = {
              ticketId: _ticket.id,
              users: _users,
            };
            const userData = updateAttendeeUsers(obj);
            tempUserData = { ...tempUserData, ...userData };
            if (selectedTickets.length === index + 1) {
              setAttendees({ ...attendees, ...tempUserData });
            } // added
            if (index === 0) {
              setActiveTicketId(_ticket?.id);
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
          return 0;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTickets]);

  useEffect(() => {
    try {
      if (isSimpleRegistrationEnabled) {
        let tempUserData = {};
        const fields = formFields[Object.keys(formFields)[0]]
          ? formFields[Object.keys(formFields)[0]].fields
          : [];
        setActiveTicketId(Object.keys(formFields)[0]);
        let _users: any = [];
        _users = [..._users, fields];
        const obj: any = {
          ticketId: Object.keys(formFields)[0],
          users: _users,
        };
        const userData = updateAttendeeUsers(obj);
        tempUserData = { ...tempUserData, ...userData };
        setAttendees({ ...attendees, ...tempUserData });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("e", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handelReInitSlider = () => {
    const container = TabSlideContainerRef.current as HTMLDivElement;
    const trackContainer = container.querySelectorAll(
      "[data-slide~='slide-select-items']"
    ) as NodeListOf<HTMLDivElement>;
    let trackContainerWidth = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of trackContainer as any) {
      trackContainerWidth += item.offsetWidth;
    }
    if (
      container &&
      trackContainer &&
      container.offsetWidth < trackContainerWidth
    )
      setShowArrow(true);
    else setShowArrow(false);
  };

  const validateAttendeeEmailApi = useMemo(
    () =>
      debounce(async (email: string) => {
        try {
          if (customRegex.emailRegEx.test(email)) {
            await validateAttendeeEmail(
              {
                authorization: configData?.accessToken,
                language: configData?.language,
              } as IHeadersRequest,
              {
                payload: {
                  data: {
                    email,
                    ticketId: Number(activeTicketId),
                  },
                },
              } as IValidateAttendeeEmailRequest
            );
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }, 300),
    [
      configData?.accessToken,
      configData?.language,
      validateAttendeeEmail,
      activeTicketId,
    ]
  );

  const handleSameAsBuyer = useCallback(() => {
    // fieldtype
    setSameAsBuyer((state) =>
      state.status
        ? {
            ticketId: null,
            status: false,
          }
        : {
            ticketId: activeTicketId,
            status: true,
          }
    );

    populateBuyerDataForAttendee(
      {
        ticketId: activeTicketId,
        buyerFields,
        reset: sameAsBuyer.status,
      },
      attendees
    );

    const attendeeIndex = 0;
    const attendee = attendees[activeTicketId][attendeeIndex];
    attendee.forEach((field: any) => {
      if (field.fieldType === "email") {
        const uniqueEmailsExcludingThis = uniqueEmails
          .filter(
            (_field: any) =>
              !(
                _field.ticketId === activeTicketId &&
                _field.attendeeIndex === attendeeIndex
              )
          )
          .map((emailField: any) => emailField.value);

        const buyerEmail = buyerFields
          .find((field: any) => field.fieldType === "email")
          ?.value?.trim()
          ?.toLowerCase();
        if (sameAsBuyer.status) setActiveAttendeePos(String(attendeeIndex));
        else {
          setActiveAttendeePos("0");
          validateAttendeeEmailApi(buyerEmail);
        }

        if (
          !sameAsBuyer.status &&
          uniqueEmailsExcludingThis.includes(buyerEmail)
        ) {
          if (!sameAsBuyer.status) validateAttendeeEmailApi(buyerEmail);

          if (apiData?.areDuplicateAttendeeEmailsAllowed) {
            updateErrorField(
              {
                ticketId: activeTicketId,
                attendeeIndex,
                fieldId: field.id,
                hasError: false,
                errMsg: "",
                isListed: 2,
              },
              attendees
            );
          } else {
            updateErrorField(
              {
                ticketId: activeTicketId,
                attendeeIndex,
                fieldId: field.id,
                hasError: true,
                errMsg: t(EMAIL_ALREADY_EXISTS_LOCAL_ERROR_MESSAGE),
              },
              attendees
            );
          }
        } else {
          if (!sameAsBuyer.status) validateAttendeeEmailApi(buyerEmail);
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId: field.id,
              hasError: false,
              errMsg: "",
            },
            attendees
          );
        }
      } else {
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: field.id,
            hasError: false,
            errMsg: "",
          },
          attendees
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTicketId,
    buyerFields,
    sameAsBuyer.status,
    attendees,
    uniqueEmails,
  ]);

  const SameAsBuyerCheckbox = (index: number) => {
    if (index === 0 && !buyerRegisteredAsAttendee && isBuyerFrom) {
      if (sameAsBuyer.status === false) {
        return (
          <Box width="100%">
            <FormControlLabel
              className={classes.checkboxWrapper}
              control={
                <Checkbox
                  size="small"
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                  checked={sameAsBuyer.status}
                  onChange={handleSameAsBuyer}
                />
              }
              label={
                <Typography
                  variant="caption"
                  component="p"
                  color="textPrimary"
                  sx={{ fontWeight: 400 }}
                >
                  {t("same-as-buyer")}
                </Typography>
              }
            />
          </Box>
        );
      } else if (
        sameAsBuyer.status &&
        sameAsBuyer.ticketId === activeTicketId
      ) {
        return (
          <Box width="100%">
            <FormControlLabel
              className={classes.checkboxWrapper}
              control={
                <Checkbox
                  size="small"
                  sx={{ paddingTop: 0, paddingBottom: 0 }}
                  checked={sameAsBuyer.status}
                  onChange={handleSameAsBuyer}
                />
              }
              label={
                <Typography
                  variant="caption"
                  component="p"
                  sx={{ fontWeight: 400 }}
                  color="textPrimary"
                >
                  {t("same-as-buyer")}
                </Typography>
              }
            />
          </Box>
        );
      }
    }
  };

  const updateErrorFieldSingle = useCallback(
    (
      fieldId: number | string,
      attendeeIndex: number,
      valueKey: string,
      value: any
    ): void => {
      const tempField = attendees[activeTicketId][attendeeIndex].find(
        (_field: any) => _field?.id === fieldId
      );
      if (tempField) {
        const field = {
          ...tempField,
          [valueKey]: value,
        };
        const { hasError, errMsg } = getFormFieldsAuthenticate(field);
        if (hasError) {
          let reallyHasError = false;

          if (Array.isArray(value)) {
            const indexVal = value.find(
              (el) => el && String(el).toLowerCase() === "other"
            );
            if (!indexVal) {
              reallyHasError = true;
            }
          } else if (value?.toLowerCase() !== "other") {
            reallyHasError = true;
          }
          reallyHasError &&
            updateErrorField(
              {
                ticketId: activeTicketId,
                attendeeIndex,
                fieldId,
                hasError: true,
                errMsg,
              },
              attendees
            );
        } else {
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId,
              hasError: false,
              errMsg: "",
            },
            attendees
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attendees, activeTicketId]
  );

  const onChangeTextHandler = useCallback(
    (
      event,
      fieldId: number | string,
      fieldKey: string,
      attendeeIndex: number
    ): void => {
      let fieldValue = "";
      if (fieldKey === "phone") {
        fieldValue = event;
      } else {
        if (fieldKey === "email") setActiveAttendeePos(String(attendeeIndex));
        event.persist();
        fieldValue = event.target.value;
      }

      const updatedData = updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId,
          fieldKey,
          fieldValue,
        },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
      updateErrorFieldSingle(fieldId, attendeeIndex, "value", fieldValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTicketId, updateErrorFieldSingle]
  );

  useEffect(() => {
    if (!isEmpty(validateEmailResponse) && !isEmpty(attendees)) {
      let fieldId = "";
      let attendeeIndex = 0;
      let email = "";

      attendees[activeTicketId].map((item: any, index: number) => {
        const emailField = item.find((x: any) => x.fieldType === "email");
        if (String(activeAttendeePos) === String(index)) {
          fieldId = emailField.id;
          attendeeIndex = index;
          email = emailField.value;
        }
      });

      if (
        Object.keys(validateEmailResponse).length > 0 &&
        activeAttendeePos &&
        String(activeAttendeePos) === String(attendeeIndex) &&
        validateEmailResponse?.isRestrictedDomain
      ) {
        // if the email entered by the user is filtered then the error message should be visible to the user that comes in the response
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId,
            hasError: true,
            errMsg: validateEmailResponse?.message,
          },
          attendees
        );
      } else if (
        Object.keys(validateEmailResponse).length > 0 &&
        activeAttendeePos &&
        String(activeAttendeePos) === String(attendeeIndex) &&
        validateEmailResponse?.emailAlreadyRegistered
      ) {
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId,
            hasError: true,
            errMsg: t(EMAIL_ALREADY_EXISTS_REMOTE_ERROR_MESSAGE),
          },
          attendees
        );
      } else if (
        Object.keys(validateEmailResponse).length > 0 &&
        activeAttendeePos &&
        String(activeAttendeePos) === String(attendeeIndex) &&
        validateEmailResponse?.hasTicketBeenPurchasedForThisAttendee
      ) {
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId,
            hasError: false,
            errMsg: "",
            isListed: 1,
          },
          attendees
        );
      } else {
        const uniqueEmailsExcludingThis = uniqueEmails
          .filter(
            (_field: any) =>
              !(
                _field.ticketId === activeTicketId &&
                _field.attendeeIndex === attendeeIndex
              )
          )
          .map((emailField: any) => emailField?.value);
        if (uniqueEmailsExcludingThis.includes(email?.trim()?.toLowerCase())) {
          const params: any = {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId,
            hasError: true,
            errMsg: t(EMAIL_ALREADY_EXISTS_LOCAL_ERROR_MESSAGE),
          };
          if (apiData?.areDuplicateAttendeeEmailsAllowed) {
            params["hasError"] = false;
            params["errMsg"] = "";
            params["isListed"] = 2;
          }
          // locally same email exists
          updateErrorField(params, attendees);
        } else {
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId,
              hasError: false,
              errMsg: "",
            },
            attendees
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailResponse, activeAttendeePos]);

  // useEffect(()=>{
  //   setEmailErrorCodeFor("");
  //   if(validateEmailDomainResponse){
  //     if(Object.keys(validateEmailDomainResponse).length>0){
  //     let fieldId = "";
  //     let attendeeIndex = 0;
  //     let email = "";
  //     attendees[activeTicketId].map((item: any, index: number) => {
  //       const emailField = item.find((x: any) => x.fieldType === "email");
  //       fieldId = emailField.id;
  //       attendeeIndex = index;
  //       email = emailField.value;
  //       if(validateEmailDomainResponse.suggestion){
  //           updateErrorField(
  //             {
  //               ticketId: activeTicketId,
  //               attendeeIndex,
  //               fieldId,
  //               hasError: false,
  //               errMsg: "",
  //               suggestion:t("did-you-mean") + validateEmailDomainResponse.suggestion + "?",
  //             },
  //             attendees
  //           );
  //           setEmailErrorCodeFor(validateEmailDomainResponse?.code);
  //       }else{
  //            if(validateEmailDomainResponse.code){
  //             setEmailErrorCodeFor(validateEmailDomainResponse.code);
  //             updateErrorField(
  //               {
  //                 ticketId: activeTicketId,
  //                 attendeeIndex,
  //                 fieldId,
  //                 hasError: true,
  //                 errMsg: validateEmailDomainResponse?.code ? t(validateEmailDomainResponse.code) : "",
  //               },
  //               attendees
  //             );
  //            }
  //       }
  //       if(validateEmailDomainResponse?.status.toString()==="200"){
  //         validateAttendeeEmailApi(email);
  //       }
  //     });

  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[validateEmailDomainResponse, t]);

  // const validateAttendeeEmailDomainApi = useMemo(() => debounce(async (email: string) => {
  //   try {
  //     if (customRegex.emailRegEx.test(email)){
  //       setEmailErrorCodeFor("");
  //       await validateEmailDomain(
  //         {
  //           authorization: configData?.accessToken,
  //           language: configData?.language,
  //         } as IHeadersRequest,
  //         {
  //           // payload: {
  //             data: {
  //               email: email,
  //             },
  //           // },
  //         } as IValidateEmailRequestDataObj
  //       );
  //     }
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.error(error);
  //   }
  // }, 500), [configData?.accessToken, configData?.language,validateEmailDomain]);

  const onKeyupTextHandler = async (
    event: any,
    fieldId: number | string,
    fieldKey: string,
    attendeeIndex: number
  ) => {
    const value = event.target.value;
    if (fieldKey === "email") {
      updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId,
          fieldValue: value,
        },
        attendees
      );

      if (sameAsBuyer.ticketId === activeTicketId && attendeeIndex === 0) {
        if (buyerEmail !== value) {
          setSameAsBuyer({
            status: false,
            ticketId: null,
          });
        }
      }
      // validateAttendeeEmailDomainApi(value);
      validateAttendeeEmailApi(value);
    }
  };

  const onBlurTextHandler = useCallback(
    async (
      event,
      fieldId: number | string,
      fieldKey: string,
      attendeeIndex: number
    ) => {
      event.persist();
      const value = event.target.value;
      const field = attendees[activeTicketId][attendeeIndex].find(
        (_field: any) => _field.id === fieldId
      );
      const fieldCopy = {
        ...field,
        value,
      };
      const updatedData = updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId,
          fieldKey,
          fieldValue: value,
        },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
      const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(
        fieldCopy.fieldType
      );
      const { hasError, errMsg } = getTextValidate(
        isDefaultField ? { ...fieldCopy, isDefaultField } : fieldCopy
      );
      if (hasError) {
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId,
            hasError,
            errMsg,
          },
          attendees
        );
        return;
      }

      updateErrorField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId,
          hasError: false,
          errMsg: "",
        },
        attendees
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTicketId, attendees]
  );

  const onRadioChangeHandler = useCallback(
    (fieldId: string, value, attendeeIndex: number): void => {
      const updatedData = updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId,
          fieldValue: value,
        },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
      updateErrorFieldSingle(fieldId, attendeeIndex, "value", value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTicketId, updateErrorFieldSingle]
  );

  const onCheckboxChangeHandler = useCallback(
    (fieldId: string, valueOptions: string[], attendeeIndex: number): void => {
      const updatedData = updateUserField(
        { ticketId: activeTicketId, attendeeIndex, fieldId, valueOptions },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
      updateErrorFieldSingle(
        fieldId,
        attendeeIndex,
        "valueOptions",
        valueOptions
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTicketId, updateErrorFieldSingle]
  );

  const onChangeFile = (
    fieldId: string,
    value: string,
    attendeeIndex: number
  ): void => {
    const updatedData = updateUserField(
      {
        ticketId: activeTicketId,
        attendeeIndex,
        fieldId,
        fieldValue: value,
      },
      attendees
    );
    setAttendees({ ...attendees, ...updatedData });
    updateErrorFieldSingle(fieldId, attendeeIndex, "value", value);
  };

  const onChangeSelect = (
    fieldId: string,
    value: string,
    attendeeIndex: number,
    key: string
  ) => {
    const updateValue = typeof value === "object" && key ? value[key] : value;
    const updatedData = updateUserField(
      {
        ticketId: activeTicketId,
        attendeeIndex,
        fieldId,
        fieldValue: updateValue,
      },
      attendees
    );
    setAttendees({ ...attendees, ...updatedData });
    updateErrorFieldSingle(fieldId, attendeeIndex, "value", updateValue);
  };

  const onChangeCountry = (
    id: string,
    value: string,
    attendeeIndex: number,
    key: string
  ) => {
    const profileFields = attendees[activeTicketId][attendeeIndex];
    const isStateIndex = profileFields.findIndex(
      ({ fieldType = "" }: IRegistrationField) =>
        fieldType && fieldType.toUpperCase() === "STATE"
    );
    let stateId;
    if (isStateIndex > -1) {
      const stateField = profileFields[isStateIndex];
      stateId = stateField.id;

      const updatedData = updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId: stateId,
          fieldValue: "",
        },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
    }
    const updateValue = typeof value === "object" && key ? value[key] : value;
    const updatedData = updateUserField(
      {
        ticketId: activeTicketId,
        attendeeIndex,
        fieldId: id,
        fieldValue: updateValue,
      },
      attendees
    );
    setAttendees({ ...attendees, ...updatedData });
    updateErrorFieldSingle(id, attendeeIndex, "value", value);
    // handleUpdateDetails(id, updateValue);
  };

  const onChangeGroupHandler = (id: any, value: any, attendeeIndex: number) => {
    updateGroupField(
      {
        ticketId: activeTicketId,
        attendeeIndex,
        fieldId: id,
        fieldValue: value,
      },
      attendees
    );
    updateErrorFieldSingle(id, attendeeIndex, "value", value);
  };

  const onDateChange = (
    fieldId: string,
    value: string,
    attendeeIndex: number
  ): void => {
    const updatedData = updateUserField(
      {
        ticketId: activeTicketId,
        attendeeIndex,
        fieldId,
        fieldValue: value,
      },
      attendees
    );
    setAttendees({ ...attendees, ...updatedData });
    updateErrorFieldSingle(fieldId, attendeeIndex, "value", value);
  };

  const onBlurPhone = (
    event: any,
    id: string | number,
    attendeeIndex: number
  ): void => {
    const profileFields = attendees[activeTicketId][attendeeIndex];
    const currVal = event.target.value;
    const fetchFields: IRegistrationField[] = [...profileFields];
    const isFieldsEmpty =
      fetchFields && Array.isArray(fetchFields) && fetchFields.length;
    const fieldIndex = isFieldsEmpty
      ? fetchFields.findIndex(
          (field) => field.id && String(id) === String(field.id)
        )
      : -1;

    if (fieldIndex > -1) {
      const initValue = get(profileFields[fieldIndex], "value", "");
      const [prevDialer] = getPhoneCode(initValue);
      const field = {
        ...fetchFields[fieldIndex],
        value: `${prevDialer}-${currVal}`,
      };
      if (field) {
        const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(
          field.fieldType
        );
        const { hasError, errMsg } = getNumberValidate(
          isDefaultField ? { ...field, isDefaultField } : field
        );

        const updatedData = updateUserField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            fieldValue: `${prevDialer}-${currVal}`,
          },
          attendees
        );
        setAttendees({ ...attendees, ...updatedData });
        if (hasError) {
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId: id,
              hasError,
              errMsg,
            },
            attendees
          );

          return;
        }

        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            hasError: false,
            errMsg: "",
          },
          attendees
        );
      }
    }
  };

  const onRegionSelect = (
    region: IRegion,
    id: string | number,
    attendeeIndex: number
  ) => {
    const profileFields = attendees[activeTicketId][attendeeIndex];
    const initVal = get(region, "dial_code", "");
    const index = profileFields.findIndex(
      (ele: any) => String(ele.id) === String(id)
    );
    if (index > -1 && initVal) {
      const initValue = get(profileFields[index], "value", "");
      const [, prevNumber] = getPhoneCode(initValue);
      // [HUB-9447] Phone number field of Attendee form is not shown as per language.
      const fieldType = get(profileFields[index], "fieldType", "");
      const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(fieldType);
      const fetchField = {
        ...profileFields[index],
        isDefaultField,
        value: `${initVal}-${prevNumber}`,
      };

      const updatedData = updateUserField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId: id,
          fieldValue: `${initVal}-${prevNumber}`,
        },
        attendees
      );
      setAttendees({ ...attendees, ...updatedData });
      const { hasError, errMsg } = getNumberValidate(fetchField);
      if (hasError) {
        updateErrorField(
          {
            ticketId: activeTicketId,
            attendeeIndex,
            fieldId: id,
            hasError,
            errMsg,
          },
          attendees
        );
        return;
      }

      updateErrorField(
        {
          ticketId: activeTicketId,
          attendeeIndex,
          fieldId: id,
          hasError: false,
          errMsg: "",
        },
        attendees
      );
    }
  };

  const isFormAuthentic = useCallback(() => {
    let isAuthentic = true,
      hasError,
      errMsg;
    const errorFields: IFieldProps[] = [];
    attendees[activeTicketId].forEach((attendee: any, attendeeIndex: any) => {
      attendee.forEach((field: IFieldProps) => {
        let childFieldNotAvailable = true;
        if (field?.parent) {
          const parentField = attendee?.find(
            (item: any) => item.id === field?.parent
          );
          const optionFieldName = parentField?.parentOptions?.find(
            (opt: any) => opt.optionId === field.optionId
          )?.name;
          if (parentField?.type === "CHECKBOX") {
            if (
              !parentField?.valueOptions?.find(
                (x: string) => x === optionFieldName
              )
            ) {
              childFieldNotAvailable = true;
            }
          } else if (
            parentField?.type === "RADIO" ||
            parentField?.type === "DROPDOWN"
          ) {
            if (parentField?.value !== optionFieldName) {
              childFieldNotAvailable = true;
            }
          }
        }
        const { hasError: _hasError, errMsg: _errMsg } =
          getFormFieldsAuthenticate(field);
        if (!_hasError && field.fieldType === "email") {
          hasError = field.isError;
          errMsg = field.labelHint;
        } else {
          hasError = _hasError;
          errMsg = _errMsg;
        }
        if (hasError && !childFieldNotAvailable) {
          isAuthentic = false;
          errorFields.push(field);
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId: field.id,
              hasError,
              errMsg,
            },
            attendees
          );
        } else {
          updateErrorField(
            {
              ticketId: activeTicketId,
              attendeeIndex,
              fieldId: field.id,
              hasError: false,
              errMsg: "",
            },
            attendees
          );
        }
      });
    });
    return { isAuthentic, field: [...errorFields] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTicketId, attendees]);
  const isFullFormAuthentic = useCallback(() => {
    const tempTickets: any[] = [];
    try {
      let isAuthentic = true,
        hasError,
        errMsg;
      const errorFields: IFieldProps[] = [];
      const ticketIds = selectedTickets.map((ticket: any) => ticket.id);
      const values: any[][] = [];
      try {
        ticketIds.forEach((ticketId) => {
          const orderTicketId = formFields[ticketId].orderTicketId;
          attendees[ticketId].forEach(
            (attendeeData: any, attendeeIndex: number) => {
              let attendee = attendeeData;
              const showCountry = attendee.find(
                (eachAttendee: any) => eachAttendee?.fieldType === "country"
              );
              const showState = attendee.find(
                (eachAttendee: any) => eachAttendee?.fieldType === "state"
              );
              if (!showCountry && showState) {
                attendee = attendeeData.filter(
                  (eachAttendee: any) => eachAttendee?.fieldType !== "state"
                );
              }
              const individualAttendee: any[] = [];
              attendee.forEach((field: IFieldProps) => {
                const fieldType = get(field, "fieldType", "");
                const isDefaultField: boolean =
                  BUYERS_DEFAULT_FIELDS.includes(fieldType);
                let childFieldNotAvailable = false;
                const parentField = attendee?.find(
                  (item: any) => item.id === field?.parent
                );
                if (field?.parent) {
                  const optionFieldName = parentField?.parentOptions?.find(
                    (opt: any) => opt.optionId === field.optionId
                  )?.name;
                  if (parentField?.type === "CHECKBOX") {
                    if (
                      !parentField?.valueOptions?.find(
                        (x: string) => x === optionFieldName
                      )
                    ) {
                      childFieldNotAvailable = true;
                    }
                  } else if (
                    parentField?.type === "RADIO" ||
                    parentField?.type === "DROPDOWN"
                  ) {
                    if (parentField?.value !== optionFieldName) {
                      childFieldNotAvailable = true;
                    }
                  }
                }
                const fetchAuth = getFormFieldsAuthenticate(
                  isDefaultField ? { ...field, isDefaultField } : field
                );
                // This is done to ignore the field authentication for the field
                // which have parent id but that parent field is not present in this form
                if (field?.parent && !parentField && fetchAuth.hasError) {
                  fetchAuth.hasError = false;
                  fetchAuth.errMsg = "";
                }
                const errData = get(fetchAuth, "errData", []);
                const { hasError: _hasError, errMsg: _errMsg } = fetchAuth;

                if (!_hasError && field.fieldType === "email") {
                  hasError = field.isError;
                  errMsg = field.labelHint;
                  // if(!field.isError && emailErrorCodeFor!==""){
                  //   hasError = true;
                  //   errMsg = t(emailErrorCodeFor);
                  // }
                } else {
                  hasError = _hasError;
                  errMsg = _errMsg;
                }
                if (hasError && !childFieldNotAvailable) {
                  isAuthentic = false;
                  errorFields.push(field);
                  tempTickets.push(ticketId);
                } else {
                  try {
                    if (!childFieldNotAvailable) {
                      if (field.value) {
                        let finalValue;
                        if (Array.isArray(field.value)) {
                          if (field.value.includes("Other")) {
                            finalValue = field.value
                              .map((eachValue) =>
                                eachValue === "Other"
                                  ? field.otherValue
                                  : eachValue
                              )
                              .join(",");
                          } else {
                            finalValue = field.value.join(",");
                          }
                        } else if (
                          ["DROPDOWN", "RADIO"].includes(
                            field.type.toUpperCase()
                          ) &&
                          field.value === t("other") &&
                          field.otherValue
                        ) {
                          finalValue = field.otherValue;
                        } else {
                          finalValue = field.value;
                        }
                        finalValue &&
                          individualAttendee.push({
                            ticketId,
                            orderTicketId: orderTicketId,
                            id: field.id,
                            label: field.label,
                            value: finalValue,
                            fieldType: field.fieldType,
                          });
                      } else if (
                        field.valueOptions &&
                        Array.isArray(field.valueOptions) &&
                        field.valueOptions.length
                      ) {
                        individualAttendee.push({
                          ticketId,
                          orderTicketId: orderTicketId,
                          id: field.id,
                          label: field.label,
                          value: field.valueOptions
                            .map((eachValue) =>
                              eachValue === t("other")
                                ? field.otherValue
                                : eachValue
                            )
                            .join(","),
                          fieldType: field.fieldType,
                        });
                      } else if (field.customGroupValueArray) {
                        const data: { id: any; value: any }[] = [];
                        field.children.forEach((child: any) => {
                          data.push({
                            id: child.id,
                            value: field.customGroupValueArray
                              .filter((x: any) => x)
                              .map((childField: any) => childField[child.id])
                              .join(","),
                          });
                        });
                        individualAttendee.push({
                          ticketId,
                          orderTicketId: orderTicketId,
                          id: field.id,
                          label: field.label,
                          value: JSON.stringify(data),
                          fieldType: field.fieldType,
                        });
                      }
                    }
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error("Something went wrong", error);
                  }
                }
                updateErrorField(
                  {
                    ticketId,
                    attendeeIndex,
                    fieldId: field.id,
                    hasError,
                    errMsg,
                    errData,
                  },
                  attendees
                );
              });
              values.push(individualAttendee);
            }
          );
        });
        // eslint-disable-next-line no-empty
      } catch (error) {}
      setTicketsWithError(tempTickets);
      return {
        isAuthentic,
        field: [...errorFields],
        values: values.filter((value) => value.length),
      };
    } catch (e) {
      return {
        isAuthentic: false,
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees, selectedTickets]);

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const onChangeHandler = (value: any) => {
    const { isAuthentic } = isFormAuthentic();
    if (isAuthentic) {
      setActiveTicketId(value);
    } else {
      handleToastError(t("please-check-all-the-fields-are-correctly-filled"));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStripeCheckout = async (token: string) => {
    if (!token) return;
    let stripe: Stripe | null;
    if (stripeAccountId) {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "", {
        stripeAccount: stripeAccountId,
      });
    } else {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "");
    }
    const result = await stripe?.redirectToCheckout({
      sessionId: token,
    });
    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    return result;
  };

  const trackAddAttendeeDetails = async () => {
    const amplitudePayload = {
      numberOfFieldsEncountered: 0,
      numberOfFieldsEntered: 0,
      numberOfMandatoryFields: 0,
      ticketCount: 0,
    };

    for (const ticketId in attendees) {
      attendees[ticketId].forEach(
        (fields: {
          length: number;
          filter: (arg0: { (field: any): any; (field: any): any }) => {
            (): any;
            new (): any;
            length: number;
          };
        }) => {
          if (amplitudePayload.numberOfFieldsEncountered === 0) {
            amplitudePayload.numberOfFieldsEncountered = fields.length;
            amplitudePayload.numberOfMandatoryFields = fields.filter(
              (field) => field.isMandatory
            ).length;
          }
          amplitudePayload.ticketCount += 1;
          amplitudePayload.numberOfFieldsEntered += fields.filter(
            (field) => field.value || field.valueOptions
          ).length;
        }
      );
    }

    Track.addAttendeeDetails(amplitudePayload);
  };

  useEffect(() => {
    if (!attendeeFormData?.isFree) {
      setOtherPaymentDetails({
        paymentMethodTypesAvailable:
          attendeeFormData.paymentMethodTypesAvailable,
        paymentMethods: attendeeFormData.paymentMethods,
        emailForOfflinePaymentMethods:
          attendeeFormData.emailForOfflinePaymentMethods,
      });
    }

    if (skipUseEffect) setSkipUseEffect(false);
    if (!skipUseEffect && (!isEmpty(attendeeFormData) || attendeeFormError)) {
      if (isEmpty(attendeeFormError) && !isEmpty(attendeeFormData)) {
        // Amplitude Tracking Events
        trackAddAttendeeDetails();
        if (
          attendeeFormData?.type === "FAIL" &&
          attendeeFormData?.code === "DUPLICATE_ATTENDEE_EMAIL_SUBMITTED"
        ) {
          handleToastError(
            typeof attendeeFormError === "string"
              ? attendeeFormError
              : t(EMAIL_ALREADY_EXISTS_REMOTE_ERROR_MESSAGE)
          );
          setAttendeeFormError(false);
          setLoadingSubmit(false);
        } else {
          const isFree = attendeeFormData?.isFree;
          const paymentStatus = attendeeFormData?.paymentStatus;
          const stripeSessionId = attendeeFormData?.stripeSessionId;
          const showGoToEventCTA = attendeeFormData?.showGoToEventCTA;
          const goToEventCtaLabel = attendeeFormData?.goToEventCtaLabel;
          const hasDuplicateAttendee = Boolean(
            attendeeFormData?.haveMultipleTicketsBeenPurchasedForSingleAttendee
          );

          if (isFree && paymentStatus === "CONFIRM") {
            // Step 2: 'Successful Registration'
            const trackingPixelApps = eventInfo?.trackingPixelApps;
            const successEvent = eventInfo?.stepsToTrack?.find(
              (eachStep) => eachStep.id === 2
            );
            if (successEvent?.isEnabled) {
              // eslint-disable-next-line no-unused-expressions
              !isEmpty(trackingPixelApps) &&
                trackingPixelApps
                  ?.filter(
                    (eachApp: { isEnabled: boolean }) => eachApp.isEnabled
                  )
                  .filter((eachItem: { app: AppType }) => {
                    return trackEventPixelsByApp(
                      eachItem.app,
                      successEvent.eventName as unknown as string
                    );
                  });
            }
            if (onClose) {
              onClose();
              (setKeepMounted as any)?.(true);
            }
            const paymentDetails = {
              isPurchase: true,
              currPaymentStatus: "CONFIRM",
              isPaymentExists: true,
              isLoading: false,
              showGoToEventCTA,
              goToEventCtaLabel,
              hasDuplicateAttendee,
            };
            setPaymentDetails(paymentDetails);
            onCompleteFreePurchase?.(paymentDetails);
            setPageState && setPageState(PageState.TicketInfo);
            setSubmitLoading(false);
            setLoadingSubmit(false);
          } else if (!isFree) {
            if (stripeSessionId) {
              handleStripeCheckout(stripeSessionId);
              if (onClose) onClose();
            } else {
              if (!attendeeFormData?.paymentMethodTypesAvailable?.length) {
                handleToastError(t("PAYMENT_METHOD_NOT_AVAILABLE"));
              }
              if (
                attendeeFormData?.paymentMethodTypesAvailable &&
                attendeeFormData?.paymentMethodTypesAvailable[0] === "ONL" &&
                attendeeFormData?.paymentMethods?.length === 1 &&
                attendeeFormData?.paymentMethods[0].name === "STP" &&
                attendeeFormData?.paymentMethods[0].isActive === true &&
                stripeSessionId
              ) {
                handleStripeCheckout(stripeSessionId);
              } else if (
                attendeeFormData?.paymentMethodTypesAvailable &&
                attendeeFormData?.paymentMethodTypesAvailable[0] === "OFL" &&
                attendeeFormData?.paymentMethods?.length === 1 &&
                attendeeFormData?.paymentMethods[0].name === "PAV" &&
                attendeeFormData?.paymentMethods[0].isActive === true
              ) {
                setOtherPaymentDetails({
                  ...store.otherPaymentDetails,
                  selectedPaymentMethod: {
                    ...attendeeFormData.paymentMethods[0],
                    ...NEW_PAYMENT_METHOD_DTLS[
                      attendeeFormData.paymentMethods[0].name
                    ],
                  },
                  paymentMethodTypesAvailable:
                    attendeeFormData.paymentMethodTypesAvailable,
                  paymentMethods: attendeeFormData.paymentMethods,
                  emailForOfflinePaymentMethods:
                    attendeeFormData.emailForOfflinePaymentMethods,
                });

                setOtherPaymentModal({
                  choosePaymentMethod: false,
                  faqModal: false,
                  orderStatus: true,
                  paymentDetailModal: false,
                  returningUserModal: false,
                  sharePaymentDetails: false,
                  ticketConfirmStatus: false,
                });
              } else if (
                attendeeFormData?.paymentMethodTypesAvailable &&
                attendeeFormData?.paymentMethodTypesAvailable[0] === "OFL" &&
                attendeeFormData?.paymentMethods?.length === 1 &&
                attendeeFormData?.paymentMethods[0].isActive === true
              ) {
                setOtherPaymentDetails({
                  ...store.otherPaymentDetails,
                  selectedPaymentMethod: {
                    ...attendeeFormData.paymentMethods[0],
                    ...NEW_PAYMENT_METHOD_DTLS[
                      attendeeFormData.paymentMethods[0].name
                    ],
                  },
                  paymentMethodTypesAvailable:
                    attendeeFormData.paymentMethodTypesAvailable,
                  paymentMethods: attendeeFormData.paymentMethods,
                  emailForOfflinePaymentMethods:
                    attendeeFormData.emailForOfflinePaymentMethods,
                });

                setOtherPaymentModal({
                  choosePaymentMethod: false,
                  faqModal: false,
                  orderStatus: false,
                  paymentDetailModal: true,
                  returningUserModal: false,
                  sharePaymentDetails: false,
                  ticketConfirmStatus: false,
                });
              } else {
                setPageState && setPageState(PageState.PaymentMethod);
              }
            }
          }
        }
      } else if (attendeeFormError) {
        handleToastError(
          typeof attendeeFormError === "string"
            ? attendeeFormError
            : t("something-went-wrong")
        );
        setAttendeeFormError(false);
        setAttendeeFormData({});
        setLoadingSubmit(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendeeFormData, skipUseEffect, attendeeFormError]);

  const handleContinue = () => {
    setLoadingSubmit(true);
    onOpen?.();
    if (!isSimpleRegistrationEnabled) {
      const { isAuthentic, values } = isFullFormAuthentic();
      const utm = getUTM();
      const requestData = {
        payload: {
          data: {
            ticketingLink: store.widgetData?.isWidgetExpand
              ? configData?.communityUrl
              : `${configData?.communityUrl}/register`,
            orderToken,
            values,
            isSimpleRegistrationEnabled: isSimpleRegistrationEnabled,
            utm,
          },
        },
      };
      if (isAuthentic) {
        setPaymentDetails({
          isLoading: true,
        });
        try {
          setSubmitLoading(true);
          //
          attendeeFormSave(
            {
              authorization: configData?.accessToken,
              language: configData?.language,
            },
            requestData
          );
        } catch (error: any) {
          if (error === "Network Error") {
            handleToastError(t("network-issue"));
          } else {
            handleToastError(
              error?.data?.error?.data?.message || t("something-went-wrong")
            );
          }
          setLoadingSubmit(false);
        }
      } else {
        handleToastError(t("please-check-all-the-fields-are-correctly-filled"));
        setLoadingSubmit(false);
      }
    } else {
      const ticketId: any = Object.keys(formFields)[0];
      const values: any[][] = [];
      const orderTicketId = formFields[ticketId].orderTicketId;
      let isAuthentic = true,
        hasError,
        errMsg: string | undefined;
      const errorFields: IFieldProps[] = [];
      attendees[ticketId].forEach((attendee: any, attendeeIndex: number) => {
        const individualAttendee: any[] = [];
        attendee.forEach((field: IFieldProps) => {
          let childFieldNotAvailable = false;
          const parentField = attendee?.find(
            (item: any) => item.id === field?.parent
          );
          if (field?.parent) {
            const optionFieldName = parentField?.parentOptions?.find(
              (opt: any) => opt.optionId === field.optionId
            )?.name;
            if (parentField?.type === "CHECKBOX") {
              if (
                !parentField?.valueOptions?.find(
                  (x: string) => x === optionFieldName
                )
              ) {
                childFieldNotAvailable = true;
              }
            } else if (
              parentField?.type === "RADIO" ||
              parentField?.type === "DROPDOWN"
            ) {
              if (parentField?.value !== optionFieldName) {
                childFieldNotAvailable = true;
              }
            }
          }
          const fetchAuth = getFormFieldsAuthenticate(field);
          const errData = get(fetchAuth, "errData", []);

          if (field?.parent && !parentField && fetchAuth.hasError) {
            fetchAuth.hasError = false;
            fetchAuth.errMsg = "";
          }
          
          // @ts-ignore
          const { hasError: _hasError, errMsg: _errMsg } = fetchAuth;
          if (!_hasError && field.fieldType === "email") {
            hasError = validateEmailResponse?.emailAlreadyRegistered
              ? true
              : field.isError;
            errMsg = field.labelHint;
          } else {
            hasError = _hasError;
            errMsg = _errMsg;
          }

          if (hasError  && !childFieldNotAvailable) {
            isAuthentic = false;
            errorFields.push(field);
          } else {
            try {
              if (field.value) {
                let finalValue;
                if (Array.isArray(field.value)) {
                  if (field.value.includes("Other")) {
                    finalValue = field.value
                      .map((eachValue) =>
                        eachValue === "Other" ? field.otherValue : eachValue
                      )
                      .join(",");
                  } else {
                    finalValue = field.value.join(",");
                  }
                } else if (
                  ["DROPDOWN", "RADIO"].includes(field.type.toUpperCase()) &&
                  field.value === t("other") &&
                  field.otherValue
                ) {
                  finalValue = field.otherValue;
                } else {
                  finalValue = field.value;
                }
                finalValue &&
                  individualAttendee.push({
                    ticketId,
                    orderTicketId: orderTicketId,
                    id: field.id,
                    label: field.label,
                    value: finalValue,
                    fieldType: field.fieldType,
                  });
              } else if (
                field.valueOptions &&
                Array.isArray(field.valueOptions) &&
                field.valueOptions.length
              ) {
                individualAttendee.push({
                  ticketId,
                  orderTicketId: orderTicketId,
                  id: field.id,
                  label: field.label,
                  value: field.valueOptions
                    .map((eachValue) =>
                      eachValue === t("other") ? field.otherValue : eachValue
                    )
                    .join(","),
                  fieldType: field.fieldType,
                });
              } else if (field.customGroupValueArray) {
                const data: { id: any; value: any }[] = [];
                field.children.forEach((child: any) => {
                  data.push({
                    id: child.id,
                    value: field.customGroupValueArray
                      .filter((x: any) => x)
                      .map((childField: any) => childField[child.id])
                      .join(","),
                  });
                });
                individualAttendee.push({
                  ticketId,
                  orderTicketId: 0,
                  id: field.id,
                  label: field.label,
                  value: JSON.stringify(data),
                  fieldType: field.fieldType,
                });
              }
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error("Something went wrong", error);
            }
          }

          updateErrorField(
            {
              ticketId,
              attendeeIndex,
              fieldId: field.id,
              errMsg: errMsg,
              hasError,
              errData,
            },
            attendees
          );
        });
        values.push(individualAttendee);
      });
      const utm = getUTM();
      const requestData = {
        payload: {
          data: {
            ticketingLink: `${configData?.communityUrl}/register`,
            values,
            isSimpleRegistrationEnabled: isSimpleRegistrationEnabled,
            orderToken: "",
            utm,
          },
        },
      };

      if (isAuthentic) {
        try {
          setSubmitLoading(true);
          attendeeFormSave(
            {
              authorization: configData?.accessToken,
              language: configData?.language,
            },
            requestData
          );
        } catch (error: any) {
          handleToastError(
            error?.data?.error?.data?.message || t("something-went-wrong")
          );
        }
      } else {
        handleToastError(t("please-check-all-the-fields-are-correctly-filled"));
        setLoadingSubmit(false);
      }
    }
  };


// the below function check was added as a part of QA automation convinience HUB-34058 
// and commented because it is causing function issue in case of Radio Subfield and dropdown subfield
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function hasRequiredFieldsWithValues(arrayOfArrays : any) {
  if(arrayOfArrays){
    for (const innerArray of arrayOfArrays) {
      for (const obj of innerArray) {
        if (Object.prototype.hasOwnProperty.call(obj, "isMandatory") && obj["isMandatory"] === true) {
          if (!Object.prototype.hasOwnProperty.call(obj, "value")) {
            return false;
          }
          const value = obj["value"];
          if (String(value).length === 0 || obj["isError"]) {
            return false; 
          }
        }
      }
    }
  }
  
  return true;
}

  return (
    <Box
      className={`${className} ${
        isSimpleRegistrationEnabled
          ? classes.simpleRegistrationwrapper
          : className
      }`}
      height="100%"
    >
      {isSimpleRegistrationEnabled ? (
        <Box
          sx={(theme: any) => ({
            position: "absolute",
            left: 15,
            top: store.startFromSummary
              ? 15
              : !isBuyerFrom
              ? 15
              : store.widgetData?.isWidgetExpand
              ? 15
              : 0,
            zIndex: 1,
            textAlign: "left",
            padding: 0,
            backgroundColor: theme.palette.background.cardColor,
            width: "calc(100% - 15px)",
          })}
          className="widgetRegCloseSingleView"
        >
          <IconButton
            size={
              store.startFromSummary && !isSimpleRegistrationEnabled
                ? "medium"
                : "small"
            }
            sx={(theme: any) => ({
              border: `1px solid ${theme.palette.divider.default}`,
              color: theme.palette.text.primary,
            })}
            onClick={() => {
              if (setPageState) {
                setPageState(0);
                setRegistrationToggle(false);
                setKeepMounted?.();
              }
            }}
          >
            {store.widgetData?.isWidgetExpand || isDudaIntegrate ? (
              <ArrowBackIosRoundedIcon fontSize="small" />
            ) : (
              <CloseRoundedIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      ) : null}
      <Box
        className={`${classes.attendeesDetailsMain} ${
          store?.widgetData?.isWidgetExpand
            ? classes.widgetAttendeeDetails
            : false
        } ${
          store.startFromSummary
            ? isSimpleRegistrationEnabled
              ? classes.landingWithSimple
              : classes.landingAttendeeWrapper
            : false
        } singleViewWidgetDetails ${
          !store.startFromSummary &&
          isSimpleRegistrationEnabled &&
          store?.widgetData?.isWidgetExpand
            ? classes.widgetWithoutStartFromSummary
            : !store.startFromSummary && isSimpleRegistrationEnabled
            ? classes.simpleWithoutStartFromSummary
            : false
        } ${
          isSimpleRegistrationEnabled
            ? store.widgetData?.isWidgetExpand
              ? classes.widgetWithSimRegFlow
              : classes.simpleRegistrationMain
            : ""
        } ${
          !isBuyerFrom && !isSimpleRegistrationEnabled
            ? store.widgetData?.isWidgetExpand
              ? ""
              : classes.landingWithoutBuyer
            : false
        } ${isDudaIntegrate ? !store.widgetData?.isWidgetExpand ? "dudaWrapperAttendee":"":"" }`}
      >
        {!isSimpleRegistrationEnabled ? (
          <StepperWithBackButton
            ticketData={ticketData}
            className="attendeesDetailsStepper"
            setKeepMounted={!isBuyerFrom ? setKeepMounted : undefined}
            microFeEventHandler={microFeEventHandler}
          />
        ) : (
          false
        )}
        <Box
          onScroll={handleScroll}
          className={`${
            classes.attendeeScrollingWrapper
          } widgetAttendeeScrolling landingAttendeeScrolling ${
            isSimpleRegistrationEnabled
              ? store.widgetData?.isWidgetExpand
                ? "widgetWithSimRegFlowWrapper"
                : store.startFromSummary
                ? classes.simpleRegForLanding
                : classes.simpleRegistrationScrollingWrapper
              : false
          } landingWithoutBuyerScroll ${isDudaIntegrate ? !store.widgetData?.isWidgetExpand ? "dudaWrapperScroll":"":""}`}
        >
          <Typography
            component="h2"
            variant="h2"
            sx={(theme: any) => ({
              fontWeight: "600 !important",
              pt: isSimpleRegistrationEnabled
                ? store.widgetData?.isWidgetExpand
                  ? "10px !important"
                  : store.startFromSummary
                  ? !isBuyerFrom
                    ? "50px !important"
                    : "30px !important"
                  : "10px !important"
                : "0px !important",
              pb: isSimpleRegistrationEnabled ? "12px" : "0px",
              mt: isSimpleRegistrationEnabled
                ? store.widgetData?.isWidgetExpand
                  ? "12px !important"
                  : "8px !important"
                : "8px !important",
              mb: isSimpleRegistrationEnabled
                ? store.widgetData?.isWidgetExpand
                  ? "8px !important"
                  : "20px !important"
                : "20px !important",
              textAlign: "left",
              borderBottom: isSimpleRegistrationEnabled
                ? `1px solid ${theme.palette.divider.default}`
                : 0,
            })}
            color="textPrimary"
          >
            {!isSimpleRegistrationEnabled
              ? t("attendees-info")
              : t("registration")}
          </Typography>
          {!isSimpleRegistrationEnabled ? (
            <>
              <Box
                mr={10}
                ref={TabSlideContainerRef}
                mb={2}
                className={classes.tabsSlider}
              >
                <Slider
                  {...settings}
                  onReInit={handelReInitSlider}
                  arrows={showArrow}
                >
                  {selectedTickets &&
                    selectedTickets?.map((x: any) => {
                      return (
                        <Box
                          key={x.id}
                          className={`${classes.sliderBox} ${
                            store.widgetData?.isWidgetExpand
                              ? "sliderBoxResize"
                              : false
                          } ${activeTicketId === x.id ? "active" : ""} ${
                            ticketsWithError.includes(x.id) ? "error" : ""
                          }`}
                          data-slide="slide-select-items"
                          onClick={() => onChangeHandler(x.id)}
                        >
                          {x.title}
                        </Box>
                      );
                    })}
                </Slider>
              </Box>{" "}
            </>
          ) : null}

          <Box
            className={
              store.widgetData?.isWidgetExpand
                ? isSimpleRegistrationEnabled
                  ? classes.widgetWithSimpleRegFlow
                  : ""
                : ""
            }
          >
            {attendees && activeTicketId ? (
              attendees &&
              attendees?.[activeTicketId] &&
              attendees?.[activeTicketId].map(
                (attendee: any, index: number) => {
                  return (
                    <Box
                      key={index}
                      px={2}
                      pt={2}
                      pb={3}
                      className={`${
                        isSimpleRegistrationEnabled
                          ? classes.simpleRegistration
                          : classes.registration
                      }`}
                      sx={(theme: any) => ({
                        border: `1px solid ${theme.palette.divider.default}`,
                        borderRadius: "8px",
                        "&:nth-of-type(n+2)": { marginTop: theme.spacing(4) },
                        "&:last-child": {
                          marginBottom: store.widgetData?.isWidgetExpand
                            ? theme.spacing(1)
                            : theme.spacing(3.5),
                        },
                      })}
                    >
                      {!isSimpleRegistrationEnabled ? (
                        <Typography
                          component="h3"
                          variant="h2"
                          sx={{
                            fontWeight: "600 !important",
                            mt: store.startFromSummary
                              ? "8px !important"
                              : "8px !important",
                            mb: "16px !important",
                            textAlign: "left",
                            fontSize: "16px !important",
                          }}
                          color="textPrimary"
                        >
                          {t("attendees-information")}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {!isSimpleRegistrationEnabled && (
                        <Box mt={1.3} mb={1.5} sx={{ textAlign: "left" }}>
                          {SameAsBuyerCheckbox(index)}
                        </Box>
                      )}
                      <Grid container spacing={2}>
                        <AttendeeFields
                          attendees={attendees}
                          attendee={attendee}
                          activeTicketId={activeTicketId}
                          attendeeIndex={index}
                          onRegionSelect={onRegionSelect}
                          onBlurPhone={onBlurPhone}
                          onKeyupTextHandler={onKeyupTextHandler}
                          onBlurTextHandler={onBlurTextHandler}
                          onChangeTextHandler={onChangeTextHandler}
                          onRadioChangeHandler={onRadioChangeHandler}
                          onCheckboxChangeHandler={onCheckboxChangeHandler}
                          onChangeFile={onChangeFile}
                          onChangeCountry={onChangeCountry}
                          onChangeSelect={onChangeSelect}
                          onDateChange={onDateChange}
                          onChangeGroupHandler={onChangeGroupHandler}
                          accessToken={configData?.accessToken}
                        />
                      </Grid>
                    </Box>
                  );
                }
              )
            ) : (
              <BuyerAttendeeDetailLoader />
            )}
            {ticketData?.isGdprComplianceEnabled &&
              ticketData?.isSimpleRegistrationEnabled && (
                <Box
                  sx={{ textAlign: "left" }}
                  mt={1}
                  className={classes.termsStringBlock}
                >
                  <FormControlLabel
                    className={classes.checkboxWrapper}
                    sx={{
                      mb: 2,
                      "&>span": {
                        "&>svg": {
                          height: store.widgetData?.isWidgetExpand
                            ? "0.8em"
                            : "20px",
                          width: store.widgetData?.isWidgetExpand
                            ? "0.8em"
                            : "20px",
                        },
                      },
                    }}
                    control={
                      <Checkbox
                        disableRipple
                        onChange={() => setAcceptCompliance((state) => !state)}
                        value="test"
                        checked={acceptCompliance}
                        size="medium"
                      />
                    }
                    label={
                      <span
                        className={`${classes.termandpolicy} policyForReg`}
                        dangerouslySetInnerHTML={{
                          __html:
                            ticketData?.gdprComplianceConsentText as string,
                        }}
                      ></span>
                    }
                  />
                </Box>
              )}
          </Box>
        </Box>
        <Box
          className={`continueBtn ${
            isVisible ? "scrollstop" : "scrollContinue"
          } singleViewWidgetAttendeeButton ${
            isSimpleRegistrationEnabled
              ? store.widgetData?.isWidgetExpand
                ? classes.widgetWithFlowBtn
                : classes.simpleRegistrationContinueBtn
              : ""
          } ${
            !isBuyerFrom
              ? store.widgetData?.isWidgetExpand
                ? ""
                : classes.continueWithOutBuyer
              : false
          }`}
        >
          <Button
            variant="contained"
            size="large"
            sx={{ width: "100%" }}
            disabled={
              isContinueDisabled ||
              isLoadingSubmit ||
              (!acceptCompliance &&
                isSimpleRegistrationEnabled &&
                ticketData?.isGdprComplianceEnabled)
            }
            onClick={() => handleContinue()}
          >
            {loading ? (
              `${totalAmount === 0 ? t("submit") : t("continue")}`
            ) : (
              <CircularProgress
                sx={(theme: any) => ({
                  width: "20px !important",
                  height: "20px !important",
                  color: theme.palette.text.secondaryFontColor,
                })}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

type IAttendeeDetailsProps = ITicketInfoProps & {
  ticketData: ITicketDetailsResponse;
  activeTicketId?: number;
  setKeepMounted: () => void;
  onOpen: () => void;
  microFeEventHandler?: () => void;
};

export default function AttendeesDetails(props: IAttendeeDetailsProps) {
  const {
    configData,
    eventInfo,
    ticketData,
    onCompleteFreePurchase,
    setKeepMounted,
    onOpen,
    microFeEventHandler,
    onClose,
  } = props;
  const classes = useStyles();
  const { store } = useStore();
  const { isBuyerFrom, orderToken, ticketList = [], pageState } = store;
  const ticketIds = ticketList?.filter(
    (ticket: any) => ticket.selectedQuantity > 0
  );
  const ids = ticketIds?.map((ticket: any) => ticket.id);

  const paidTicketPresent =
    ticketList &&
    ticketList?.find((data) => !!data.selectedQuantity && data.ticketFee > 0);
  const { applyDiscount, status } = useApplyDiscount();
  const [data, setData] = useState<IAttendeeFormSetResponse>(
    {} as IAttendeeFormSetResponse
  );
  const [loading, setLoading] = useState<boolean>(true);
  const getTicketInfoPayload = (
    items: ITicketData[],
    isComplete = false
  ): ITicketsInfo[] => {
    const { registrationToggle } = store;
    return items
      .filter(
        (eachTicket: ITicketData) => (eachTicket?.selectedQuantity || 0) > 0
      )
      .map((ticket: ITicketData) => {
        const { selectedQuantity = 0, id, promoCode = "" } = ticket;
        return {
          id,
          quantity: selectedQuantity,
          coupon: promoCode,
          isApplied: !!promoCode,
          isComplete:
            !registrationToggle && !paidTicketPresent ? true : isComplete,
        };
      });
  };

  const registrationAttendeeData = {
    orderToken: !isBuyerFrom && orderToken ? orderToken : "",
    isSimpleRegistrationEnabled: store?.registrationToggle,
    ticketId: store?.registrationToggle ? [props.activeTicketId] : ids,
  };

  const fetchApi = async () => {
    try {
      const response = await postApi<
        IAttendeeFormSetResponse,
        IAttendeeFormSetRequestPayload
      >(
        AppConstants.attendeeFormSet,
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data:
              (store?.attendeeData as IAttendeeFormSetRequest) ||
              registrationAttendeeData,
          },
        }
      );
      if (!response.status) {
        throw new Error(response.error.message);
      }
      setData(response?.success?.data as IAttendeeFormSetResponse);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      pageState === PageState.AttendeeDetails &&
      !isBuyerFrom &&
      !paidTicketPresent &&
      !store?.registrationToggle
    ) {
      (async () => {
        try {
          const ticketsInfo = getTicketInfoPayload(ticketList);
          const payload = {
            payload: {
              data: {
                orderToken,
                ticketsInfo,
              } as IApplyDiscount,
            },
          };
          await applyDiscount(
            {
              authorization: configData?.accessToken,
              language: configData?.language,
            } as IHeadersRequest,
            { ...payload }
          );
        } catch (e) {
          // console.log(e);
        }
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  useEffect(() => {
    if (
      status ||
      store.registrationToggle ||
      isBuyerFrom ||
      paidTicketPresent
    ) {
      fetchApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  // Attendee Details Form Propss
  const attendeesDetailsFormObj = {
    apiData: data,
    configData,
    eventInfo,
    ticketData,
    isDudaIntegrate:  Boolean(props?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage),
    onCompleteFreePurchase,
    setKeepMounted,
    onOpen,
    microFeEventHandler,
    onClose,
  };

  return (
    <>
      {loading ? (
        <Box px={2} pt={5}>
          <BuyerAttendeeDetailLoader />
        </Box>
      ) : isAndroidIPhoneIPad() ? (
        <Box
          height="100%"
          sx={() => ({
            borderRadius: "8px",
          })}
        >
          <AttendeesDetailsForm
            className={`${
              store?.widgetData?.isWidgetExpand ? "" : classes.mobFooterStyle
            } ${
              store.startFromSummary ? classes.scrollingBlockAttendee : false
            }`}
            {...attendeesDetailsFormObj}
          />
        </Box>
      ) : (
        <AttendeesDetailsForm {...attendeesDetailsFormObj} />
      )}
    </>
  );
}
