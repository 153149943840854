export const isAndroidIPhone = (): boolean => {
    if (typeof navigator !== "undefined") {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1 || ua.indexOf("iphone") > -1;
    } else return false;
    
};

export const isIPhone = (): boolean => {
    if (typeof navigator !== "undefined") {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("iphone") > -1;
    } else return false;
};

export const isIPad = (): boolean => {
    if (typeof navigator !== "undefined") {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("ipad") > -1;
    } else return false;
};

export const isAndroidIPhoneIPad = (): boolean => {
    if (typeof navigator !== "undefined") {
        return isAndroidIPhone() || isIPad();
    } else return false;
};

export function isSafari(): boolean {
    if (typeof navigator !== "undefined") {
        const ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf("safari") !== -1) {
            if (ua.indexOf("chrome") > -1) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    } else return false;
}

export function isFirefox(): boolean {
    if (typeof navigator !== "undefined") {
        return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    } else return false;
}

export function isChromium(): boolean {
    if (typeof navigator !== "undefined") {
        return navigator.userAgent.includes("Chrome");
    } else return false;
}
