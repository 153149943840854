export class AppConstants {
  static validateEmail = "landing-page/ticket/email-validation";
  static applyDiscount = "landing-page/ticket/apply-discount";
  static buyerFormSet = "landing-page/ticket/buyer-form-set";
  static buyerForm = "landing-page/ticket/buyer-form";
  static attendeeFormSet = "landing-page/ticket/attendee-form-set";
  static attendeeForm = "landing-page/ticket/attendee-form";
  static validateAttendeeEmail = "landing-page/ticket/validate-attendee-email";

  static getTickets = "landing-page/ticket";
  static getWebinarRegistrationForm = "landing-page/ticket/webinar-attendee-form-set";
  static webinarRegistrationView = "landing-page/ticket/view";
  static landingPage = "landing-page";
  static landingPageConfiguration = "landing-page/configuration";
  static fileUploadUtils = "landing-page/util/upload-file";
  static resendEmail = "landing-page/ticket/resend-link";
  // Payment Details
  static paymentVerify = "landing-page/ticket/payment-verify";
  static paymentStatus = "landing-page/ticket/payment-status";
  static rePaymentSession = "landing-page/ticket/get-new-stripe-session";

  static returningUserData: "landing-page/ticket/incomplete-transaction";
  static paymentMethodDetail :"ticketsetting/payment-methods";
  static validateEmailDomain= "notifications/people/validate-email";
  // static paymentMethodDetail :"ticketsetting/payment-methods";
}

export enum PageState {
  TicketInfo = 0,
  TicketSummary = 1,
  BuyDetails = 2,
  AttendeeDetails = 3,
  PaymentMethod = 4,
}


export const TOAST_TIMEOUT = 5000;
export const protectedTrue = { isProtected: true };

export const MINIMUM_THRESHOLD_TO_SHOW_TICKET_COUNT = 10;
