export default {
    "already-registered": "已經注册了嗎？",
    "add-to-calendar": "添加入日曆",
    "event-starts-in": "活動開始於",
    "event-is-live": "活動正直播",
    "event-has-ended": "活動已結束",
    "powered-by": "由 提供",
    "please-enter-your-email-to-continue": "請輸入你的電子郵件以繼續",
    "add": "添加",
    "offers": "優惠",
    "free": "免費",
    "available-till": "有效期至 {{DATE}}",
    "enter-email-address": "輸入電子郵件",
    "sold-out": "已售罄",
    "the-ticket-has-reached-the-maximum-number": "票證已達到最大數",
    "seat-left": "剩 {{SEAT}} 座位",
    "seats-left": "剩 {{SEAT}} 座位",
    "proceed": "繼續",
    "summary": "總結",
    "buyer-info": "買方信息",
    "attendees-info": "參與者信息",
    "registration": "登記",
    "continue": "繼續",
    "attendees-information": "參與者信息",
    "submit": "提交",
    "register-to-join-the-events": "注册以加入活動",
    "by-clicking-on-the-button": "點擊按鈕，你將被轉移到票務平臺。在那裡，你可以注册此活動。",
    "other": "其他",
    "please-enter-a-value-for": "請輸入<FIELD_NAME>值",
    "please-select-a-value-for": "請為<FIELD_NAME>選擇一個值",
    "enter-other-value-for": "輸入<FIELD_NAME>的其他值",
    "enter-a-valid": "輸入有效的<FIELD_NAME>",
    "should-be-at-least": "<FIELD_NAME>應至少<FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME>不能大於<FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME>是必填項",
    "at-least-required": "至少需要<FIELD_LENGTH><FIELD_NAME><VERB>",
    "maximum-allowed": "允許的最大值為<FIELD_LENGTH><FIELD_NAME><VERB>",
    "user-with-same-email-id-already-exists": "具有相同電子郵件名稱的用戶已存在。 請選擇另一種郵件。",
    "user-with-same-email-id-has-already-registered-for-this-event": "具有相同電子郵件名稱的用戶已注册參加此活動。 請使用另一個郵件。",
    "mail-successfully-sent": "郵件發送成功",
    "we-are-unable-to-deliver-your-mail-at-this-moment": "我們現在無法發送你的郵件！ 請稍後再試",
    "something-went-wrong": "突發了某種情況。我們正在處理它。對由此造成的不便，我們深表歉意。",
    "register-now": "立即注册",
    "add-to-your-calendar": "添加入日曆",
    "please-select-a-ticket-to-proceed": "請選擇票證以繼續",
    "resend-link": "重新發送鏈接",
    "go-to-event": "去活動選項",
    "ticket-registration-done-by": "票務登記人",
    "you-have-already-purchased-the-ticket-for": "你已經購買此票給",
    "purchased": "已購買",
    "event-date": "活動日期",
    "ticket-quantity": "票證數量",
    "payment": "付款",
    "unable-to-submit-data": "無法提交數據",
    "unable-to-apply-promo-code": "無法應用優惠碼",
    "invalid-promo-code": "優惠碼無效",
    "apply-now": "申請",
    "enter-discount-coupon": "輸入折扣券",
    "same-as-buyer": "與買方相同",
    "please-check-all-the-fields-are-correctly-filled": "請檢查所有欄位是否已填寫",
    "ticket-purchased": "已購票！",
    "ticket-purchase-failed": "購票失敗！",
    "you-have-successfully-purchased-the-ticket": "您已成功購買票。 這張票可以讓你參加該活動。 感謝您的購買！",
    "there-was-an-error-while-booking-your-tickets": "訂票時出現了錯誤。 請再次嘗試。",
    "try-again": "再試一次",
    "you-dont-have-access": "你沒有購買部分門票的許可權",
    "please-enter-a-valid-email": "請輸入有效的電子郵件",
    "please-enter-email-to-continue": "請輸入電子郵件繼續",
    "payment-summary": "付款總結",
    "apply-discount": "{{CODE}} 已成功應用。 您存了額外的 {{AMOUNT}}。",
    "and": "和",
    "taxation-concent": "想要生成稅務發票嗎？",
    "taxation-tnc-pp": "點擊繼續后，你將同意我們",
    "taxation-tnc": "條款和條件",
    "taxation-pp": "隱私政策",
    "company-name-label": "公司名稱",
    "company-name-place-holder": "輸入公司名稱",
    "taxId-label": "公司稅务ID",
    "taxId-place-holder": "輸入公司稅务ID",
    "company-address-label": "公司地址",
    "company-address-place-holder": "地址",
    "country-place-holder": "選擇國家/地區",
    "state-place-holder": "選擇州屬",
    "city-place-holder": "選擇城市",
    "zipcode-label": "郵政編碼",
    "zipcode-place-holder": "輸入郵政編碼",
    "company-error-msg": "請輸入公司名稱",
    "taxId-error-msg": "請輸入公司稅务ID",
    "address-error-msg": "請輸入地址",
    "country-error-msg": "請選擇國家",
    "state-error-msg": "請選擇州屬",
    "city-error-msg": "請選擇城市",
    "zipcode-error-msg": "請輸入郵政編碼",
    "firstName": "名字",
    "lastName": "姓氏",
    "email": "電子郵件",
    "phone": "電話號碼",
    "dialerCode": "電話區號",
    "you-have-already-purchased-ticket-for": "您已經購買此票給",
    "browse-a-file-to-upload": "選擇一個需要上載的文件",
    "upload-file": "上載文件",
    "max-size": "最大格式",
    "file-supported-types": "適用文件的類型",
    "uploaded-successfully": "上傳成功",
    "failed!-please-retry": "失敗！ 請重試",
    "this-file-type-is-not-supported-supported-attachments-are": "不適用於此文件的類型。 所適用的附件包括",
    "the-file-size-is-too-high-please-upload-document-lesser-than": "文件太大。 請上傳小於",
    "person": "人",
    "select": "選擇",
    "search": "搜索",
    "uploaded-file": "已上載的文件",
    "uploading": "正在上載",
    "delete": "刪除",
    "no-item": "無",
    "read-more": "閱讀更多",
    "read-less": "減少閲讀",
    "about-title": "關於",
    "google-calendar": "Google日曆",
    "outlook-calendar": "Outlook日曆",
    "yahoo-calendar": "Yahoo日曆",
    "verb-are": "是",
    "verb-is": "是",
    "character": "字數",
    "date-format": "年/月/日",
    "timer-hr": "時",
    "timer-hrs": "時",
    "timer-min": "分",
    "timer-mins": "分",
    "timer-sec": "秒",
    "timer-secs": "秒",
    "timer-day": "天",
    "timer-days": "天",
    "select-file-title": "沒選擇什麽文件",
    "today": "今天",
    "yesterday": "昨天",
    "tomorrow": "明天",
    "last": "上次",
    "internet-down": "網絡斷開了！",
    "internet-up": "網絡已連接",
    "network-issue": "網絡出現某種錯誤，請重試！",
    "registrationSuccess": "注册成功！",
    "registrationSuccessDetail": "您已成功注册該活動。 非常感謝！",
    "search-here": "在此處搜索。。",
    "did-you-mean": "你是不是要找這個",
    "DISPOSABLE_EMAIL": "所給予的電子郵件屬於一次性服務",
    "RISTRICTED_DOMAIN": "給給予的電子郵件受限域內。 請輸入有效的電子郵件",
    "INVALID_EMAIL": "請輸入有效的電子郵件",
    "CHOOSE_PAYMENT_METHOD": "選擇付款方式",
    "PAY_NOW": "立即付款",
    "INSTANT_TICKET_CONFIRMATION": "即時確認票務",
    "PAY_VIA_OTHER_METHODS": "通過其他方式支付",
    "FUNDS_TRANSFER": "資金轉移",
    "DIGITAL_WALLET": "電子錢包",
    "CHEQUE": "支票",
    "PAY_AT_VENUE": "在場館付款",
    "TICKET_NOT_CONFIRMED_ORGANIZER": "在活動組織者未確認付款已收到之前，門票將不被認可，並可能被取消。",
    "TICKET_NOT_CONFIRMED_UNTIL_PAYMENT": "在您付款於活動場地之前，門票將不會被認可，並可能被取消。",
    "TICKET_CONFIRMED_BY_ORGANIZER": "當活動組織者確認已收到付款時，門票將被認可。",
    "COMPLETE_PAYMENT_OF": "完整支付",
    "COMPLETE_PAYMENT_TICKET": "票已付款。",
    "PAYMENT_CONFIRMATION_TITLE": "輸入活動組織者的特殊交易ID以驗證您的款項。",
    "ENTER_TRANSACTION_ID": "輸入交易ID",
    "SHARE_DETAILS_TITLE": "只有當您共享信息時，活動組織者才能確認您的付款。 您的票（包括任何免費票）將在批准後得到確認。",
    "UPLOAD_SCREENSHOT": "上傳截圖（可選）",
    "SHARE_PAYMENT_DETAILS": "股份支付詳細資訊",
    "TICKET_STATUS": "票證狀態",
    "ORGANIZER_YET_TO_CONFIRM": "活動組織者尚未確認已收到付款。",
    "TICKET_CONFIRMATION_ORGANIZER": "您的票（包括任何免費票）和發票將在活動組織者確認后與你共享。",
    "PAYMENT_DETAILS": "付款信息",
    "FAQ_TITLE": "如果有任何問題，請參閱常見問題與解答",
    "MAKE_PAYMENT_TITLE": "在活動場地付款以確認您是否購買了門票。",
    "CONFIRM_TICKET_PRIOR_EVENT": "如果您想在活動前確認您的門票，請立即使用其他方式付款。",
    "CONFIRM_TICKETS_PRIOR_EVENT": "如果您希望在活動前確認您的門票，",
    "PAY_NOW_2": "立即付款",
    "WITH_DIFFERENT_METHOD": "用其他方法",
    "PAYMENT_NOT_RCVD": "活動組織者尚未收到付款。",
    "PAYMENT_COULD_NOT_BE_VERIFIED": "活動組織者無法驗證共享的付款信息。",
    "TICKETS_NOT_AVAILABLE": "該活動的門票已不再生效。",
    "PAYMENT_DETAILS_YET_TO_SHARE": "您尚未共享該票的付款信息。",
    "WELCOME_BACK": "歡迎回來！",
    "VIEW_OR_PROCEED_PURCHASE": "您可以查看以前的票或繼續購買您剛剛所選擇的票。",
    "VIEW_PREV_TICKETS": "查看以前的票",
    "PROCEED_TO_PURCHASE": "繼續購買",
    "PREVIOUS_TICKETS": "以前的票",
    "PENDING_TICKETS": "待收到的票",
    "CONFIRMED_TICKETS": "已確認的票",
    "PAYMENT_PENDING": "待付款",
    "AWAITING_ORG_APPROVAL": "等待組織者的確認",
    "CHECK_TICKET_STATUS": "檢查票的狀態",
    "PAYMENT_METHOD_NOT_AVAILABLE": "付款方式不可用，請重試。",
    "HERE": "這裡",
    "RE_ENTER_EMAIL": "請更新所有已購買的票的參與者信息。 這將使所有參與者能夠訪問該活動。",
    "DUPLICATE_EMAIL": "你已經買了此票。 您可以選擇重新注册。 在這種情況下，我們將保存最新數據。",
    "DEFAULT_DUPLICATE_EMAIL": "此電子郵件已用於另一個票。 請仔細檢查您所輸入的電子郵件，或可以給多張票使用同一封電子郵件。",
    "ORDERED": "已訂購",
    "REGISTER_NOW": "立即注册",
    "REG_FAILED": "註冊失敗",
    "REG_FAILED_DESC": "您的註冊失敗了。請再試一次。",
    "REG_DONE_BY": "被完成註冊",
    "INVITE_AGAIN": "需要再次邀請您嗎？",
    "REG_SUCCESS_DONE": "註冊成功完成",
    "WATCH_REC_LINK": "帶有觀看錄製鏈接的電子郵件已發送至",
    "WEBINAR_INV_LINK": "帶有網絡研討會鏈接的邀請電子郵件已發送至",
    "MAIL_SENT_SUCCESS": "郵件成功發送",
    "ALREADY_REGISTERED": "已經註冊",
    "RESEND_INVITE": "重新發送邀請",
    "WATCH_RECORDING_REGISTER_DESC": "您可以通過以下註冊來觀看網絡研討會的錄製。",

};