import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputField from "./InputField";
import React from "react";
import UploadIcon from "../Assets/Svg/UploadIcon";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyle = makeStyles(() => ({
  uploadUi: {
    "&>.MuiInputBase-root": {
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
  },
}));

export type InputUploadBoxProps = {
  className?: string;
  placeHolder?: string;
  id?: string;
  label?: string;
  hintMsg?: string | JSX.Element;
  errorMsg?: string;
  acceptFile?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  tabIndex?: number;
  value?: string;
  name?: string;
  errorType?: "error" | "success" | "";
  disabled?: boolean;
  hintText?: string;
  required: boolean;
  withoutInput?:boolean;
  customUploadIcon?:React.ReactNode;
  customUploadText?:string;
  
};

const InputUploadBox = ({
  id = "",
  errorMsg = "",
  label,
  onChange,
  acceptFile = "",
  hintMsg = "",
  className,
  tabIndex = 1,
  placeHolder = "",
  withoutInput,
  customUploadText,
  customUploadIcon,
  required,
}: InputUploadBoxProps): JSX.Element => {
  const [t] = useTranslation();
  const classes = useStyle();
  return (
    <Box className={className}>
      {label ? (
        <Typography
          variant="caption"
          component="label"
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
          })}
        >
          {label || " "}
          {required ? (
            <Typography
              component="span"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                lineHeight: 0,
              })}
            >
              *
            </Typography>
          ) : (
            ""
          )}{" "}
        </Typography>
      ) : (
        false
      )}
      <Box display="flex" alignItems="center" overflow="hidden">
        {!withoutInput ?
        <InputField
          placeHolder={placeHolder}
          type="text"
          className={classes.uploadUi}
        /> :false}
        <Box
          display="flex"
          alignItems="center"
          position="relative"
          sx={(theme: any) => ({
            background:withoutInput ? "transpartent":  theme.palette.primary.main,
            color:withoutInput ? theme.palette.primary.main: theme.palette.text.secondaryFontColor,
            borderRadius: "3px",
            borderTopLeftRadius:withoutInput? "3px": "0px",
            border:withoutInput ? `1px solid ${theme.palette.divider.default}` : "none",
            borderBottomLeftRadius:withoutInput? "3px": "0px",
            padding: "9.8px 12px",
            width:withoutInput ? "100%":"auto",
            cursor: "pointer",
            "&>input": {
              height: "6.4rem",
              width: "100%",
              left: 0,
              opacity: 0,
              position: "absolute",
              cursor: "pointer",
            },
          })}
        >
          <input
            id={id || "upload"}
            type="file"
            name="img"
            onChange={onChange}
            accept={acceptFile || ""}
            tabIndex={tabIndex + 1}
          />
          <Box display="flex" justifyContent={withoutInput ? "center":""} alignItems="center" sx={(theme)=>({width:withoutInput?"100%":"auto", cursor: "pointer","& .svgIconTheme":{color:theme.palette.primary.main}})}>
            {customUploadIcon ? customUploadIcon : <UploadIcon width="20px" height="20px" />}
            <Typography
              variant="caption"
              component="span"
              sx={{ marginLeft: 1, whiteSpace: "nowrap", cursor: "pointer"}}
            >
             {customUploadText ? customUploadText: t("upload-file")}
            </Typography>
          </Box>
        </Box>
      </Box>
      {hintMsg && (
        <Typography
          variant="caption"
          sx={(theme) => ({
            fontWeight: 400,
            marginTop: "4px",
            color: theme.palette.text.secondary,
          })}
          component="p"
        >
          {hintMsg}{" "}
        </Typography>
      )}
      {errorMsg && (
        <Typography
          variant="caption"
          component="p"
          sx={(theme: any) => ({
            fontWeight: 400,
            marginTop: "4px",
            color: theme.palette.error.main,
          })}
        >
          {errorMsg}
        </Typography>
      )}
    </Box>
  );
};

export default InputUploadBox;
