export default {
    "already-registered": "Sudah Terdaftar?",
    "add-to-calendar": "Tambahkan ke Kalender",
    "event-starts-in": "Acara dimulai pada",
    "event-is-live": "ACARA DISIARKAN LANGSUNG",
    "event-has-ended": "ACARA TELAH BERAKHIR",
    "powered-by": "Didukung oleh",
    "please-enter-your-email-to-continue": "Harap masukkan surel Anda untuk melanjutkan",
    add: "Tambahkan",
    offers: "Tawaran",
    free: "Gratis",
    "available-till": "Tersedia hingga {{DATE}}",
    "enter-email-address": "Masukkan Alamat Surel",
    "sold-out": "Habis terjual",
    "the-ticket-has-reached-the-maximum-number": "Tiket telah mencapai jumlah maksimum",
    "seat-left": "{{SEAT}} kursi yang tersisa",
    "seats-left": "{{SEAT}} kursi yang tersisa",
    proceed: "Lanjutkan",
    summary: "Ikhtisar",
    "buyer-info": "Info Pembeli",
    "attendees-info": "Info Para Peserta",
    "registration": "Pendaftaran",
    continue: "Lanjutkan",
    "attendees-information": "Informasi Para Peserta",
    submit: "Sampaikan",
    "register-to-join-the-events": "Daftar untuk bergabung dalam acara",
    "by-clicking-on-the-button":
        "Dengan mengklik tombol tersebut, Anda akan diarahkan ke platform tiket dimana Anda dapat mendaftar untuk acara ini.",
    other: "Lainnya",
    "please-enter-a-value-for": "Harap masukkan nilai untuk <FIELD_NAME>",
    "please-select-a-value-for": "Harap pilih nilai untuk <FIELD_NAME>",
    "enter-other-value-for": "Masukkan nilai lain untuk <FIELD_NAME>",
    "enter-a-valid": "Masukkan satu <FIELD_NAME> yang sah",
    "should-be-at-least": "<FIELD_NAME> setidaknya harus <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> tidak boleh lebih dari <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> wajib diisi.",
    "at-least-required": "Setidaknya <FIELD_LENGTH> <FIELD_NAME> <VERB> yang diperbolehkan",
    "maximum-allowed": "Maksimum <FIELD_LENGTH> <FIELD_NAME> <VERB> yang diizinkan",
    "user-with-same-email-id-already-exists":
        "Pengguna dengan id surel yang sama sudah ada. Silakan gunakan id surel yang lain.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Pengguna dengan id surel yang sama sudah terdaftar. Silakan gunakan id surel yang lain.",
    "mail-successfully-sent": "Surel berhasil dikirimkan!",
    "we-are-unable-to-deliver-your-mail-at-this-moment @id":
        "Kami tidak dapat menghantarkan surel Anda saat ini! Silakan coba setelah beberapa saat lagi",
    "something-went-wrong": "Ada yang Salah! Silakan coba lagi nanti",
    "register-now": "Daftar Sekarang",
    "add-to-your-calendar": "Tambahkan ke Kalender Anda",
    calendar: "Kalender",
    "please-select-a-ticket-to-proceed": "Silakan pilih tiket untuk melanjutkan",
    "resend-link": "Tautan Mengirim Ulang",
    "go-to-event": "Menuju ke Acara",
    "ticket-registration-done-by": "Pendaftaran tiket dilakukan oleh",
    "you-have-already-purchased-the-ticket-for": "Anda telah membeli tiket untuk",
    purchased: "Terbeli",
    "event-date": "Tanggal Acara",
    "ticket-quantity": "Jumlah Tiket",
    payment: "Pembayaran",
    "unable-to-submit-data": "Tidak bisa menyampaikan data",
    "unable-to-apply-promo-code": "Tidak bisa menerapkan kode promo",
    "invalid-promo-code": "Kode promo tidak sah",
    "apply-now": "Menerapkan",
    "enter-discount-coupon": "Masukkan kupon diskon",
    "same-as-buyer": "Sama seperti pembeli",
    "please-check-all-the-fields-are-correctly-filled": "Mohon periksa semua ruas diisi dengan benar",
    "ticket-purchased": "Tiket Terbeli",
    "ticket-purchase-failed": "Pembelian Tiket Gagal",
    "you-have-successfully-purchased-the-ticket":
        "Anda telah berhasil membeli tiket. Tiket ini akan memungkinkan Anda untuk menghadiri acara tersebut. Terima kasih atas pembelian Anda!",
    "there-was-an-error-while-booking-your-tickets": "Terjadi kesalahan saat memesan tiket Anda. Silakan coba lagi.",
    "try-again": "Coba Lagi",
    "you-dont-have-access": "Anda tidak memiliki akses untuk membeli sejumlah tiket",
    "please-enter-a-valid-email": "Silakan masukkan surel yang sah",
    "please-enter-email-to-continue": "Harap masukkan surel Anda untuk melanjutkan",
    "payment-summary": "Ikhtisar Pembayaran",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} berhasil diterapkan. Anda menghemat {{AMOUNT}} tambahan.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Ingin membuat Faktur Pajak?",
    "taxation-tnc-pp": "Dengan melanjutkan, Anda menyetujui",
    "taxation-tnc": "Syarat dan Ketentuan",
    and: "dan",
    "taxation-pp": "Rahasia pribadi",
    "company-name-label": "Nama perusahaan",
    "company-name-place-holder": "Masukkan nama perusahaan",
    "taxId-label": "Nomor pajak perusahaan",
    "taxId-place-holder": "Masukkan nomor pajak perusahaan",
    "company-address-label": "Alamat perusahaan",
    "company-address-place-holder": "Alamat",
    "country-place-holder": "Pilih negara",
    "state-place-holder": "Pilih negara bagian",
    "city-place-holder": "Masukkan Kota",
    "zipcode-label": "kode pos",
    "zipcode-place-holder": "Masukkan kode pos",
    "company-error-msg": "Silakan masukkan Nama Perusahaan",
    "taxId-error-msg": "Silakan masukkan Nomor pajak perusahaan",
    "address-error-msg": "Silakan masukkan Alamat",
    "country-error-msg": "Silakan Pilih Negara",
    "state-error-msg": "Silakan Pilih Negara",
    "city-error-msg": "Silakan masukkan Kota",
    "zipcode-error-msg": "Silakan masukkan Kode Pos",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Nama Depan",
    lastName: "Nama Belakang",
    email: "Surel",
    phone: "Telepon",
    dialerCode: "Kode Telepon",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Anda telah membeli tiket untuk",
    "upload-file": "Unggah data",
    "browse-a-file-to-upload": "Jelajahi file untuk diunggah",
    "max-size": "Ukuran maksimal",
    "file-supported-types": "Jenis file yang didukung",
    "uploaded-successfully": "Berhasil Diunggah",
    "failed!-please-retry": "Gagal! Silakan Coba Lagi",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Jenis file ini tidak didukung. Lampiran yang didukung adalah",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "Ukuran file terlalu tinggi. Harap unggah dokumen kurang dari",
    person: "Orang",
    select: "Pilih",
    search: "Mencari",
    "uploaded-file": "File yang Diunggah",
    uploading: "Mengunggah",
    delete: "Menghapus",
    "no-item": "Tidak ada barang",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Kalender Google",
    "outlook-calendar": "Kalender Outlook",
    "yahoo-calendar": "Kalender Yahoo",
    "read-more": "Baca lebih lanjut",
    "read-less": "Baca kurang",
    "about-title": "Tentang",
    "verb-are": "adalah",
    "verb-is": "adalah",
    character: "Karakter",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "Jam",
    "timer-hrs": "Jam",
    "timer-min": "Menit",
    "timer-mins": "Menit",
    "timer-sec": "Detik",
    "timer-secs": "Detik",
    "timer-day": "Hari",
    "timer-days": "Hari",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Tidak ada file yang dipilih",
    today: "Hari ini",
    yesterday: "Kemarin",
    tomorrow: "Besok",
    last: "Terakhir",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Pendaftaran Berhasil Dilakukan!",
    registrationSuccessDetail: "Anda telah berhasil mendaftar untuk acara tersebut. Terima kasih!",
    "search-here": "Cari di sini..",
    "did-you-mean": "Maksud kamu ",
    DISPOSABLE_EMAIL: "Alamat email yang diberikan milik layanan email sekali pakai",
    RISTRICTED_DOMAIN: "Alamat email yang diberikan berada dalam domain terbatas. silakan isi alamat email",
    INVALID_EMAIL: "Silakan masukkan surel yang sah",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Pilih metode pembayaran",
    PAY_NOW: "Bayar sekarang",
    INSTANT_TICKET_CONFIRMATION: "Konfirmasi tiket instan",
    PAY_VIA_OTHER_METHODS: "Bayar melalui metode lain",
    FUNDS_TRANSFER: "Transfer Dana",
    DIGITAL_WALLET: "Dompet Digital",
    CHEQUE: "Cek",
    PAY_AT_VENUE: "Bayar di Tempat",
    TICKET_NOT_CONFIRMED_ORGANIZER: "Tiket tidak akan dikonfirmasi sampai penyelenggara acara menyetujui bahwa pembayaran telah diterima dan dapat dibatalkan.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Tiket tidak akan dikonfirmasi sampai Anda melakukan pembayaran di tempat acara dan dapat dibatalkan.",
    TICKET_CONFIRMED_BY_ORGANIZER: "Tiket akan dikonfirmasi ketika penyelenggara acara menyetujui bahwa pembayaran telah diterima.",
    COMPLETE_PAYMENT_OF: "Selesaikan pembayaran",
    COMPLETE_PAYMENT_TICKET: "Selesaikan pembayaran untuk tiket Anda.",
    PAYMENT_CONFIRMATION_TITLE: "Masukkan ID Transaksi unik untuk penyelenggara acara untuk memverifikasi pembayaran Anda.",
    ENTER_TRANSACTION_ID: "Masukkan ID Transaksi",
    SHARE_DETAILS_TITLE: "Penyelenggara acara hanya dapat menyetujui pembayaran Anda jika Anda membagikan rinciannya. Tiket Anda, termasuk tiket gratis, akan dikonfirmasi setelah persetujuan.",
    UPLOAD_SCREENSHOT: "Unggah Tangkapan Layar (opsional)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Bagikan Rincian Pembayaran",
    TICKET_STATUS: "Status Tiket", // not found
    ORGANIZER_YET_TO_CONFIRM: "Penyelenggara acara belum mengkonfirmasi bahwa pembayaran telah diterima.",
    TICKET_CONFIRMATION_ORGANIZER: "Tiket Anda, termasuk tiket gratis, dan faktur akan dikonfirmasi dan dibagikan kepada Anda setelah persetujuan dari penyelenggara acara.",
    PAYMENT_DETAILS: "Rincian Pembayaran",
    FAQ_TITLE: "Jika ada pertanyaan,lihat FAQ",
    MAKE_PAYMENT_TITLE: "Lakukan pembayaran di tempat acara untuk mengkonfirmasi pembelian tiket Anda.",
    CONFIRM_TICKET_PRIOR_EVENT: "Jika Anda ingin mengkonfirmasi tiket Anda sebelum acara, bayar sekarang dengan metode yang berbeda.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Jika Anda ingin mengkonfirmasi tiket Anda sebelum acara,",
    PAY_NOW_2: "bayar sekarang",
    WITH_DIFFERENT_METHOD: "dengan metode yang berbeda",
    PAYMENT_NOT_RCVD: "Penyelenggara acara belum menerima pembayaran.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Detail pembayaran yang dibagikan tidak dapat diverifikasi oleh penyelenggara acara.",
    TICKETS_NOT_AVAILABLE: "Tiket tidak lagi tersedia untuk acara tersebut.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Anda belum membagikan detail pembayaran untuk tiket.", // not found in code
    WELCOME_BACK: "Selamat datang kembali!",
    VIEW_OR_PROCEED_PURCHASE: "Anda dapat melihat tiket Anda sebelumnya atau melanjutkan pembelian tiket yang baru saja Anda pilih.",
    VIEW_PREV_TICKETS: "Lihat Tiket Sebelumnya",
    PROCEED_TO_PURCHASE: "Lanjutkan ke Pembelian",
    PREVIOUS_TICKETS: "Tiket Sebelumnya",
    PENDING_TICKETS: "Tiket Tertunda",
    CONFIRMED_TICKETS: "Tiket yang Dikonfirmasi",
    PAYMENT_PENDING: "Pembayaran tertunda",
    AWAITING_ORG_APPROVAL: "Menunggu persetujuan penyelenggara",
    CHECK_TICKET_STATUS: "Periksa Status Tiket",
    PAYMENT_METHOD_NOT_AVAILABLE: "metode pembayaran tidak tersedia, silakan coba lagi.", // not found in code
    HERE: "di sini",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Mohon perbarui informasi peserta unik untuk semua tiket yang dibeli. Hal ini akan memungkinkan semua peserta tersebut untuk mengakses acara.",
    DUPLICATE_EMAIL: "Anda telah membeli tiket ini. Anda dapat memilih untuk mendaftar ulang. Dalam hal ini data terbaru yang dimasukkan akan disimpan.",
    DEFAULT_DUPLICATE_EMAIL: "Email ini sudah digunakan untuk tiket lain. Harap periksa kembali alamat email yang Anda masukkan atau gunakan email yang sama untuk beberapa tiket.",
    "REGISTER_NOW": "Daftar Sekarang",
    "REG_FAILED": "Pendaftaran Gagal",
    "REG_FAILED_DESC": "Pendaftaran gagal. Silakan coba lagi.",
    "REG_DONE_BY": "Pendaftaran diselesaikan oleh",
    "INVITE_AGAIN": "Ingin undangan kembali?",
    "REG_SUCCESS_DONE": "Pendaftaran telah berhasil",
    "WATCH_REC_LINK": "Email dengan tautan untuk menonton rekaman telah dikirim ke",
    "WEBINAR_INV_LINK": "Email udangan dengan tautan webinar telah dikirim ke",
    "MAIL_SENT_SUCCESS": "Pesan berhasil dikirim",
    "ALREADY_REGISTERED": "Anda telah terdaftar",
    "RESEND_INVITE": "Kirim ulang Undangan",
    "WATCH_RECORDING_REGISTER_DESC": "Anda dapat menonton rekaman webinar dengan mendaftara di bawah.",

};
