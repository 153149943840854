export const convertHex = (hex: string, opacity: number): string => {
  const x = hex.replace("#", "");
  const r = parseInt(x.substring(0, 2), 16);
  const g = parseInt(x.substring(2, 4), 16);
  const b = parseInt(x.substring(4, 6), 16);

  const result = `rgba(${r},${g},${b},${opacity / 100})`;
  return result;
};

export const LightenDarkenColor = (col: string, amt: number): string => {
  let usePound = false;
  // eslint-disable-next-line
  /* eslint-disable no-param-reassign */
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }
  /* eslint-enable no-param-reassign */
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt; //	eslint-disable-line no-bitwise
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt; //	eslint-disable-line no-bitwise

  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt; //	eslint-disable-line no-bitwise
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16); //	eslint-disable-line no-bitwise
};

export const convertDarkMode = (color: string): number => {
  const x = color.replace("#", "");
  const r = parseInt(x.substring(0, 2), 16);
  const g = parseInt(x.substring(2, 4), 16);
  const b = parseInt(x.substring(4, 6), 16);
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return hsp;
};

const theme = (props: any) => {
  const accentLight = convertHex(props?.accentColor.color1, 20);

  const scLight = convertHex(props.fontColor.color1, 65);
  const borderLight = convertHex(props.fontColor.color1, 15);
  const secondaryLight  = convertHex(props.fontColor.color1, 40);

  const darkMode = convertDarkMode(props.primaryColor.color1);
  let cardColorDark: any;
  if (darkMode < 127.5) {
    cardColorDark = LightenDarkenColor(props.primaryColor.color1, 40);
  } else {
    cardColorDark = LightenDarkenColor(props.primaryColor.color1, -40);
  }

  let cardLightGray: any;
  if (darkMode < 127.5) {
    cardLightGray = LightenDarkenColor(props.primaryColor.color1, 10);
  } else {
    cardLightGray = LightenDarkenColor(props.primaryColor.color1, -10);
  }
  const primaryColors = {
    main: props.accentColor.color1 || "#4E59B8", //themeColor
    lighter: accentLight || "rgba(78, 89, 184, 0.2)", // themecolorlight
  };

  const secondaryColors = {
    main: "#43425D",
  };

  const textColor = {
    primary: props.fontColor.color1 || "#202020", // #353535    // primaryTextColor
    secondary: scLight || "#808080", //secondarytextcolor
    secondaryFontColor: props.secondaryFontColor.color1 || "#fff", //secondaryfontcolor
    secondaryLight : secondaryLight || "#b3b3b3",
  };

  const bg = {
    default: "#F6F6F9",
    gray: cardColorDark || "#e2e2e2",
    lightGray: cardLightGray || "#f7f7f7",
    cardColor: props.primaryColor.color1 || "#fff",
    white: props.mainBackgroundColor.color1 || "#ffffff", //backgroundColor
  };

  const borderColors = {
    default: borderLight || "#dbdbdb",
  };

  const fontFamily = "Helvetica Neue New";
  const zIndex = {
    tooltip: 2000000000
  };
  const fontWeight = {
    lighter: "200",
    light: "300",
    regular: "500",
    semiBold: "600",
    bold: "700",
  };

  // const borderRadius = 4;

  return {
    palette: {
      type: "light",
      primary: primaryColors,
      secondary: secondaryColors,
      background: bg,
      divider: borderColors,
      error: {
          main: "#f00",
          light:"#FDF4F2",
      },
      warning: {
        main: "#ffa826",
      },
      text: {
        primary: textColor.primary,
        secondary: textColor.secondary,
        secondaryFontColor: textColor.secondaryFontColor,
        secondaryLight:textColor.secondaryLight,
      },
    },
    shape: {
      borderRadius: 4,
    },
    transitions: {
      duration: {
        shortest: 150,
        shorter: 200,
        short: 250,
        // most basic recommended timing
        standard: 300,
        // this is to be used in complex animations
        complex: 375,
        // recommended when something is entering screen
        enteringScreen: 225,
        // recommended when something is leaving screen
        leavingScreen: 195,
      },
    },
    spacing: 8,
    zIndex,
    typography: {
      h1: {
        fontSize: "2.6rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.bold,
        lineHeight: 1.5,
        color: `${textColor.primary} !important`,
      },
      h2: {
        fontSize: "2.4rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.semiBold,
        lineHeight: 1.5,
        color: `${textColor.primary} !important`,

      },
      h3: {
        fontSize: "2.2rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.semiBold,
        lineHeight: 1.5,
        color: `${textColor.primary} !important`,
      },
      h4: {
        fontSize: "2.0rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.bold,
        lineHeight: 1.5,
      },
      h5: {
        fontSize: "1.8rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.bold,
        lineHeight: 1.5,
      },
      h6: {
        fontSize: "1.6rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.bold,
        lineHeight: 1.5,
      },
      body1: {
        fontSize: "1.4rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.regular,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "1.4rem !important",
        fontFamily: `${fontFamily} !important`,
        fontWeight: fontWeight.regular,
        letterSpacing: "0.1px",
        lineHeight: 1.5,
      },
      caption: {
        fontFamily: `${fontFamily} !important`,
        fontSize: "1.2rem !important",
        fontWeight: fontWeight.regular,
        lineHeight: 1.5,
      },
      subtitle1: {
        fontFamily: `${fontFamily} !important`,
        fontSize: "1.4rem !important",
        fontWeight: fontWeight.regular,
        lineHeight: 1.5,
      },
      subtitle2: {
        fontFamily: `${fontFamily} !important`,
        fontSize: "1.2rem !important",
        fontWeight: fontWeight.regular,
        lineHeight: 1.5,
      },
      overline: {
        fontFamily: `${fontFamily} !important`,
      },
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            "& label": {
              marginBottom: "0px",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              cursor: "not-allowed !important",
              pointerEvents: "all !important",
              transition: "none",
              backgroundColor: bg.gray,
              color: textColor.primary,
            },
            "&.MuiIconButton-sizeSmall": {
              height: "3rem",
              width: "3rem",
            },
            "&.MuiIconButton-sizeLarge": {
              height: "3.6rem",
              width: "3.6rem",
            },
          },
        },
      },
      MuiSnackbar:{
        styleOverrides:{
            root:{
              zIndex:1400000000
            }
        }
      },  
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: fontWeight.regular,
            paddingLeft: 12,
            paddingRight: 12,
            boxShadow: "none",
            borderRadius: 4,
            fontSize: 14,
            textTransform: "initial",
            whiteSpace: "noWrap",
            "&.MuiButton-sizeLarge": {
              height: "40px",
            },
            "&.MuiButton-outlined": {
              border: `1px solid ${primaryColors.main}`,
            },
            "&.MuiButton-contained": {
              color: textColor.secondaryFontColor,
            },
            "&.Mui-disabled": {
              cursor: "not-allowed !important",
              pointerEvents: "all !important",
              transition: "none",
              backgroundColor: bg.gray,
              color: textColor.primary,
            },
            "&.uppercase": {
              textTransform: "uppercase",
            },
            "&.rounded": {
              borderRadius: 50,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: textColor.secondary,
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 14,
          },
        },
      },
      MuiPopover:{
        styleOverrides: {
          root: {
            zIndex:1300000000,
          }
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            background: bg.cardColor,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
        },
      },

      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: 14,
            transform: "translate(0,0) scale(1)",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            borderRadius: 2,
            fontWeight: fontWeight.regular,
          },
        },
      },

      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 20,
            borderRadius: 10,
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          root: {
            input:{
              "&:-webkit-autofill": {
                WebkitBoxShadow: "0 0 0 30px transparent inset !important",
                "&:active":{
                   WebkitBoxShadow: "0 0 0 30px transparent inset !important",
                },
                "&:hover":{
                  WebkitBoxShadow: "0 0 0 30px transparent inset !important",
                },
                "&:focus":{
                  WebkitBoxShadow: "0 0 0 30px transparent inset !important",
                }
              },
            },
            "& .MuiInputBase-input": {
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 0,
              minHeight: 22,
              fontWeight: fontWeight.light,
              fontSize: 14,
             
              "&::-webkit-input-placeholder":{
                color:textColor.primary,
                opacity:0.65,
              },
            },
            "&.Mui-disabled": {
              backgroundColor:bg.lightGray,

              "& input":{
                cursor: "not-allowed",
                WebkitTextFillColor:textColor.secondary,
              },
              "& .MuiOutlinedInput-notchedOutline":{
                border: `1px solid ${borderColors.default} !important`,

              }
            },
          },
        },
      },
    },
  };
};

export default theme;
