import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";
import { PageState } from "Constants/AppConstants";
import { IApplyDiscount } from "Types/ApplyDiscount";
import useGetTickets from "Hooks/useGetTickets";
import { IHeadersRequest } from "Types/Ticket";
import { CircularProgress } from "@mui/material";

const ReturningUserModal = ({ configData, onOpen, setKeepMounted }: any): JSX.Element => {
  const { store, setOtherPaymentModal, setDiscount } = useStore();
  const { buyerEmail, orderToken } = store;
  const [t] = useTranslation();
  const [loadingState, setLoadingState] = React.useState<boolean>(false);
  const { data, loading: loadingTickets } = useGetTickets({
    authorization: configData?.accessToken,
    language: configData?.language,
  } as IHeadersRequest);

  const handlePreviousTickets = () => {
    const configPayload = {
      choosePaymentMethod: false,
      faqModal: false,
      orderStatus: false,
      paymentDetailModal: false,
      returningUserModal: false,
      sharePaymentDetails: false,
      ticketConfirmStatus: true,
      paymentStatusModal: false,
    };
    setOtherPaymentModal(configPayload);

  };

  const actionPurchase = React.useCallback(() => {
    setOtherPaymentModal({
      choosePaymentMethod: false,
      faqModal: false,
      orderStatus: false,
      paymentDetailModal: false,
      returningUserModal: false,
      sharePaymentDetails: false,
      ticketConfirmStatus: false,
      paymentStatusModal: false,
    });
    onOpen?.();
    setKeepMounted?.(true);
    const paidTicketPresent = data.tickets && data.tickets?.find((item) => item.ticketFee > 0);
    const summaryStep =
      paidTicketPresent
        ? PageState.TicketSummary
        : !data.isbuyerForm
          ? PageState.AttendeeDetails
          : PageState.BuyDetails;
    setDiscount(summaryStep, {
      buyerEmail: buyerEmail,
      orderToken,
      ticketsInfo: data?.tickets,
    } as unknown as IApplyDiscount);
    setLoadingState(false);
  }, [buyerEmail, data.isbuyerForm, data.tickets, onOpen, orderToken, setDiscount, setKeepMounted, setOtherPaymentModal]);
  
  const handleProceedToPurchase = () => {
    if (loadingTickets) setLoadingState(true);
    else actionPurchase();
  };

  React.useEffect(() => {
    if (!loadingTickets && loadingState) {
      actionPurchase();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingTickets, loadingState]);

  return (
    <Box p={2}>
      <Typography
        color="textSecondary"
        variant="caption"
        sx={{ fontWeight: 700, mt: "0px !important", mb: "0px !important" }}
        component="p"
      >
        {buyerEmail}
      </Typography>
      <Typography
        color="textPrimary"
        component="label"
        sx={{ fontWeight: 700, mt: 0.5, mb: "0px !important" }}
        variant="body1"
      >
        {t("WELCOME_BACK")}
      </Typography>
      <Typography
        color="textPrimary"
        sx={{ fontWeight: 400, mt: 2, mb: "0px !important" }}
        component="p"
        variant="body2"
      >
        {t("VIEW_OR_PROCEED_PURCHASE")}
      </Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          "&>button": { width: "48.6%", whiteSpace: "normal", wordBreak: "break-word", fontSize: 13 },
          "@media(max-width:375px)": {
            display: "block",
            "&>button": { width: "100%", mt: 1 },
          },
        }}
      >
        <Button variant="outlined" color="primary" size="small" onClick={handlePreviousTickets}>
          {t("VIEW_PREV_TICKETS")}
        </Button>
        <Button disabled={loadingState} variant="contained" color="primary" size="small" onClick={handleProceedToPurchase}>
          {loadingState ? (
            <CircularProgress
              sx={(theme: any) => ({
                width: "20px !important",
                height: "20px !important",
                color: theme.palette.text.secondaryFontColor,
              })}
            />
          ) :
            t("PROCEED_TO_PURCHASE")
          }
        </Button>
      </Box>
    </Box>
  );
};

export default ReturningUserModal;
