import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";

import InfoBulbIcon from "../../Assets/Svg/InfoBulbIcon";
import { OrderStatusLoader } from "../Loaders/PaymentMethodLoader";

import { useTranslation } from "react-i18next";
import { useStore } from "Context";
import FAQModal from "./FaqModal";
import CustomModal from "../CustomModal";
import { isEmpty } from "lodash";
import usePaymentStatus from "Hooks/usePaymentStatus";
import {
  IVerifyPaymentStatus,
  IVerifyPaymentStatusRequest,
} from "Types/VerifyStatus";
import { IHeadersRequest } from "Types/Ticket";

export const faqList = [
  {
    question: "How do I change the payment method selected?",
    answer:
      "You can always change the payment method selected before you make the payment. You can edit and choose any other payment method available from the screen where you have to share the payment details. We will share the details for the new payment method selected by you.",
  },
  {
    question: "What to do if I've paid the wrong amount?",
    answer:
      "Please reach out to the event organizer on the email ID provided if you have paid less or more than the total amount payable for your ticket purchase.",
  },
  {
    question: "How will I know if my payment was received?",
    answer:
      "After making the payment, ensure you share the payment details with the event organizer. They will verify the details against your ticket purchase and approve it. You will receive a confirmation email with your tickets and invoice after approval.",
  },
  {
    question: "Why is my payment approval taking so much time?",
    answer:
      "The event organizer will verify the payment details shared by you against your ticket purchase and then approve it. This process can take a few days. Please be patient with us.",
  },
  {
    question: "Why was my ticket purchase disapproved?",
    answer: `There could be several reasons why the event organizer disapproved of your ticket purchase.
  You have not made the payment for your tickets.
  You paid for your tickets but did not share the payment details with the organizer.
  The event organizer could not verify the payment details you shared with them.
  The tickets for the event have run out, and you did not make a payment for them on time.
  `,
  },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OrderStatus = ({
  configData,
  isLoading,
  handlePaymentStatusVerify,
  userData
}: any) => {
  const {
    store,
    setOtherPaymentModal,
    setPaymentDetails,
    setPaymentStatusDetails,
    setTotalAmountSymbol,
  } = useStore();
  const { ticketList, totalAmount, transactionId, paymentStatusDetails } =
    store;
  const [t] = useTranslation();

  const {
    data: paymentStatusVerifyData,
    error: paymentStatusVerifyError,
    // loading: paymentStatusVerifyLoading,
    paymentStatus,
  } = usePaymentStatus();

  const finalTicketList =
    paymentStatusDetails?.tickets && !isEmpty(paymentStatusDetails?.tickets)
      ? paymentStatusDetails?.tickets.map((data: any) => {
        return {
          id: data.id,
          title: data.title,
          ticketFee: data.total,
          selectedQuantity: data.quantity,
          currency: { symbol: data.currency },
        };
      })
      : !isEmpty(ticketList)
        ? ticketList
        : false;
  const [faqModal, setFaqModal] = React.useState(false);

  const openModal = () => {
    if (store.otherPaymentDetails?.selectedPaymentMethod?.name === "PAV") {
      setOtherPaymentModal({
        choosePaymentMethod: true,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      });
    }
  };

  const openFaq = () => {
    setFaqModal(true);
  };

  const onClose = () => {
    setFaqModal(false);
  };

  const netAmount = (array: any) => {
    const getNet = array.find((data: any) => data.type === "NET");
    if (getNet) {
      const netAmount = getNet.amount;
      return netAmount;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        (isEmpty(paymentStatusVerifyData) && !paymentStatusVerifyError) ||
        (paymentStatusVerifyData?.type === "APRVL_PND" &&
          !paymentStatusVerifyError)
      ) {
        if (paymentStatusVerifyData?.currency && !store?.totalAmountSymbol)
          setTotalAmountSymbol(paymentStatusVerifyData?.currency);
        const payload: IVerifyPaymentStatus = {
          orderToken: store?.orderToken || "",
        };
        paymentStatus(
          {
            authorization: userData?.newToken || configData?.accessToken,
            language: configData?.language,
          } as IHeadersRequest,
          {
            payload: {
              data: { ...payload },
            },
          } as IVerifyPaymentStatusRequest
        );
      }
    }, 4000);

    if (
      !isEmpty(paymentStatusVerifyData) &&
      paymentStatusVerifyData?.type !== "APRVL_PND"
    ) {
      setPaymentDetails({
        isPurchase: true,
        currPaymentStatus:
          paymentStatusVerifyData?.type === "APRVL_DND"
            ? "FAIL"
            : paymentStatusVerifyData?.type === "PAID"
              ? "CONFIRM"
              : "",
      });
      setPaymentStatusDetails(paymentStatusVerifyData);
      handlePaymentStatusVerify(
        paymentStatusVerifyData?.type === "APRVL_DND"
          ? "FAIL"
          : paymentStatusVerifyData?.type === "PAID"
            ? "CONFIRM"
            : ""
      );
      clearInterval(interval);
    }

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusVerifyData]);

  return (
    <>
      {isLoading ? (
        <OrderStatusLoader />
      ) : (
        <Box
          p={2.5}
          sx={{
            minHeight: "calc((var(--vh, 1vh) * 100) - 80px)",
            "@media(max-width:640px)": { minHeight: "auto", p: 2, pt: 7 },
          }}
        >
          <Typography
            color="textPrimary"
            component="h3"
            sx={{
              fontWeight: 700,
              "@media(max-width:640px)": { fontSize: "22px !important" },
            }}
            variant="h2"
          >
            Order Status
          </Typography>

          <Typography
            sx={{ mt: 0.4, mb: "0px !important" }}
            component="p"
            color="textSecondary"
            variant="body2"
          >
            Order ID : {store.orderNo}
          </Typography>
          {["PAV"].includes(
            store?.otherPaymentDetails.selectedPaymentMethod?.name
          ) ? (
            <Typography
              component="p"
              variant="body2"
              sx={{ mt: 2, mb: "0px !important", fontWeight: 400 }}
              color="textPrimary"
            >
              {t("MAKE_PAYMENT_TITLE")}
            </Typography>
          ) : (
            false
          )}
          {["FT", "DW", "CHQ"].includes(
            store?.otherPaymentDetails.selectedPaymentMethod?.name
          ) ? (
            <>
              <Box
                sx={(theme: any) => ({
                  "&>svg": {
                    height: "20px",
                    width: "20px",
                    color: theme.palette.primary.main,
                  },
                  display: "flex",
                  alignItems: "center",
                  mt: 4,
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "8px",
                  p: 2,
                  background: theme.palette.primary.lighter,
                })}
              >
                <InfoBulbIcon />
                <Typography
                  sx={{
                    fontWeight: 400,
                    ml: 2,
                    mt: "0px !important",
                    mb: "0px !important",
                  }}
                  component="p"
                  variant="body2"
                  color="textPrimary"
                >
                  {t("ORGANIZER_YET_TO_CONFIRM")}
                </Typography>
              </Box>

              <Typography
                component="p"
                variant="body2"
                sx={{ mt: 2, mb: "0px !important", fontWeight: 400 }}
                color="textPrimary"
              >
                {t("TICKET_CONFIRMATION_ORGANIZER")}
              </Typography>
            </>
          ) : (
            false
          )}
          <Box
            sx={(theme: any) => ({
              mt: 2,
              borderRadius: "12px",
              border: `1px solid ${theme.palette.divider.default}`,
            })}
          >
            <Typography
              color="textPrimary"
              sx={(theme: any) => ({
                p: 2,
                borderBottom: `1px solid ${theme.palette.divider.default}`,
              })}
              component="h4"
              variant="h5"
            >
              Ticket Details
            </Typography>
            {finalTicketList &&
              finalTicketList?.map((_item, i) => {
                const { selectedQuantity = 0 } = _item;
                if (selectedQuantity > 0) {
                  return (
                    _item.selectedQuantity && (
                      <Box sx={{ px: 2, pt: 2, pb: 1 }} key={i}>
                        <Box
                          sx={{
                            mb: 1.5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                          key={i}
                        >
                          <Typography
                            color="textPrimary"
                            variant="body2"
                            component="label"
                            sx={{
                              fontWeight: 700,
                              width: "calc(100% - 132px)",
                            }}
                          >
                            {_item.title}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              width: "120px",
                              ml: 2,
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                color="textPrimary"
                                variant="body2"
                                sx={{
                                  fontWeight: 400,
                                  mt: "0px !important",
                                  mb: "0px !important",
                                }}
                                component="p"
                              >
                                {selectedQuantity}
                              </Typography>
                              <Typography
                                color="textPrimary"
                                variant="body2"
                                sx={{
                                  fontWeight: 400,
                                  mt: "0px !important",
                                  mb: "0px !important",
                                }}
                                component="p"
                              >
                                {_item.currency?.symbol}
                                {_item.ticketFee}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )
                  );
                }
              })}
            <Box
              sx={(theme: any) => ({
                display: "flex",
                alignItems: "cetner",
                justifyContent: "space-between",
                p: 2,
                borderTop: `1px solid ${theme.palette.divider.default}`,
              })}
            >
              <Typography
                component="p"
                color="textPrimary"
                variant="body1"
                sx={{
                  fontWeight: 700,
                  mt: "0px !important",
                  mb: "0px !important",
                }}
              >
                Total
              </Typography>
              <Typography
                component="p"
                variant="body1"
                sx={(theme: any) => ({
                  color: theme.palette.primary.main,
                  mt: "0px !important",
                  mb: "0px !important",
                })}
              >
                {store?.totalAmountSymbol}{" "}
                {paymentStatusDetails?.tickets
                  ? netAmount(paymentStatusDetails?.details)
                  : totalAmount}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={(theme: any) => ({
              mt: 2,
              borderRadius: "12px",
              border: `1px solid ${theme.palette.divider.default}`,
            })}
          >
            <Typography
              color="textPrimary"
              sx={(theme: any) => ({
                p: 2,
                borderBottom: `1px solid ${theme.palette.divider.default}`,
              })}
              component="h4"
              variant="h5"
            >
              {t("PAYMENT_DETAILS")}
            </Typography>
            <Box sx={{ p: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={(theme: any) => ({
                    "&>svg": { color: theme.palette.text.primary },
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 35,
                    width: 35,
                    minWidth: 35,
                    border: `1px solid ${theme.palette.divider.default}`,
                    borderRadius: "50%",
                  })}
                >
                  {/* <BankIcon height="15px" width="15px"/> */}
                  {/* <VenueLocationIcon height="15px" width="15px" /> */}
                  {store?.otherPaymentDetails.selectedPaymentMethod?.svg}
                </Box>
                {["FT", "DW", "CHQ"].includes(
                  store?.otherPaymentDetails.selectedPaymentMethod?.name
                ) ? (
                  <Box sx={{ ml: 1.5 }}>
                    <Typography
                      variant="body1"
                      sx={{ lineHeight: 1.3 }}
                      color="textPrimary"
                      component="label"
                    >
                      {store?.otherPaymentDetails.selectedPaymentMethod?.label}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ lineHeight: 1.3, fontWeight: 400 }}
                      component="p"
                    >
                      Transaction ID: {transactionId || store?.paymentStatusDetails?.paymentId}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ ml: 1.5 }}>
                    <Typography
                      variant="body1"
                      sx={{ lineHeight: 1.3 }}
                      color="textPrimary"
                      component="label"
                    >
                      {store?.otherPaymentDetails.selectedPaymentMethod?.label}
                    </Typography>
                  </Box>
                )}
              </Box>
              {["PAV"].includes(
                store?.otherPaymentDetails.selectedPaymentMethod?.name
              ) &&
                !(
                  store?.otherPaymentDetails?.paymentMethods?.length === 1 &&
                  store?.otherPaymentDetails?.paymentMethods &&
                  store?.otherPaymentDetails?.paymentMethods[0]?.name === "PAV"
                ) ? (
                <Typography
                  component="p"
                  variant="body2"
                  color="textPrimary"
                  sx={(theme: any) => ({
                    mt: 2,
                    borderTop: `1px solid ${theme.palette.divider.default}`,
                    pt: 2,
                    fontWeight: 400,
                    mb: "0px !important",
                  })}
                >
                  {t("CONFIRM_TICKETS_PRIOR_EVENT")}{" "}
                  <Typography
                    component="span"
                    onClick={openModal}
                    sx={(theme: any) => ({
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: theme.palette.primary.main,
                    })}
                    variant="body2"
                  >
                    {t("PAY_NOW_2")}
                  </Typography>{" "}
                  {t("WITH_DIFFERENT_METHOD")}
                </Typography>
              ) : (
                false
              )}
            </Box>
          </Box>

          <Typography
            sx={{ mt: 1, mb: "0px !important" }}
            color="textPrimary"
            component="p"
            variant="caption"
          >
            {t("FAQ_TITLE")}
            <Typography
              component="span"
              variant="caption"
              onClick={openFaq}
              sx={(theme: any) => ({
                display: "inline",
                cursor: "pointer",
                ml: 0.5,
                color: theme.palette.primary.main,
              })}
            >
              {t("HERE")}
            </Typography>
          </Typography>
        </Box>
      )}
      <CustomModal
        onRequestClose={onClose}
        isOnlyChildren
        open={faqModal}
        isScrollModal
      >
        <FAQModal
          onClose={onClose}
          faqList={faqList}
          orderNo={store?.orderNo || ""}
        />
      </CustomModal>
    </>
  );
};

export default OrderStatus;
