import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ClockIcon from "../../Assets/Svg/ClockIcon";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AddToCalendarModal from "../AddToCalendarModal";
import Slider from "react-slick";
import { getOrganiserId } from "../../Utils/getIds";
import {
  getEventLogoImage,

} from "../../Utils/common";
import { useStore } from "../../Context";

import { getEventDateRange } from "../../Utils/date";
import TicketList from "../TicketList";
import { ITicketDetailsResponse } from "../../Types/Ticket";
import {
  IConfigData,
  IEventInfo,
  ILandingPage,
  IEventLogo
} from "../../Types/LandingPage";
import dayjs from "dayjs";
import sanitize from "sanitize-html";
import { SHARE_SITES } from "react-add-to-calendar-hoc";
import { useStyles } from "./styles";
import { ListLoader } from "../Loaders/TicketInfoAndListLoader";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import Track from "Lib/ThirdParty/amplitude";


const settings = {
  centerMode: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  dots: false,
  autoplaySpeed: 5000,
  autoplay: true,
  pauseOnHover: true,
  className: "bannerSlider",
  swipeToSlide: true,
};

const fullySanitizeText = (htmlText: string): string => {
  return sanitize(htmlText, {
    allowedTags: [],
    allowedAttributes: {},
  });
};

export interface TicketProps {
  landingPageData?: ILandingPage;
  eventInfo?: IEventInfo;
  configData?: IConfigData;
  ticketData?: ITicketDetailsResponse;
  ticketDataLoading: boolean;
  showAddToCalendar: boolean;
  startFromSummary?:boolean;
  setTicketId?: (activeTicketId: number) => void;
}
export default function TicketInfo(props: TicketProps) {
  const {
    eventInfo,
    configData,
    landingPageData,
    ticketData,
    ticketDataLoading,
    showAddToCalendar = false,
    startFromSummary,
    setTicketId,
  }: any = props;
  const [organiserId, setOrganiserId] = useState(0);
  const [t] = useTranslation();

  const classes = useStyles();

  const {
    eventStartime = "",
    eventEndtime = "",
    eventName = "",
    description: { data: descriptionData },
  } = landingPageData as ILandingPage;
  const {
    store,
  } = useStore();
  const { communityUrl } = configData as IConfigData;
  const { isShowEventDate = 1 } = eventInfo as IEventInfo;
  const startTimeMilli = eventStartime as string;
  const endTimeMilli = eventEndtime as string;
  const title = eventName as string;
  // const timezone = landingPageData?.timezone;
  const location = communityUrl as string;
  const startDt = dayjs(+startTimeMilli).utc();
  const endDt = dayjs(+endTimeMilli).utc();
  const tempDuration = endDt
    .diff(startDt, "hours", true)
    .toFixed(2)
    .replace(".50", ".30")
    .replace(".", "");
  const duration = (tempDuration.length > 3 ? "" : "0") + tempDuration;
  const sanitizeDesc = fullySanitizeText(descriptionData);


  // refs
  const event = {
    description: sanitizeDesc?.slice(0, 2000),
    duration: duration,
    endDatetime: endDt.format("YYYYMMDDTHHmmssZ"),
    location: location,
    startDatetime: startDt.format("YYYYMMDDTHHmmssZ"),
    title: title,
  };

  useEffect(() => {
    if (configData?.accessToken) {
      setOrganiserId(getOrganiserId(configData?.accessToken));
    }
  }, [configData?.accessToken]);

  const isEnded = eventInfo?.eventStatus && eventInfo?.eventStatus === "ENDED";
  return (
    <>
        <Box
          p={store?.widgetData?.isWidgetExpand?0:store?.registrationToggle ? 0: 2.5}
          sx={(theme: any) => ({
            // p: 2.5,
            borderRadius:store?.widgetData?.isWidgetExpand? 0: 2.5,
            overflowY: "auto",
            border:store?.widgetData?.isWidgetExpand? "none":store?.registrationToggle ? "none": `1px solid ${theme.palette.divider.default}`,
            "@media(max-width:640px)": { mt:`${store.startFromSummary || store.widgetData?.isWidgetExpand ? "0px" : "40px"}`},
          })}
          className={`${store?.widgetData?.isWidgetExpand? `${classes.widgetForTicketInfo} singleViewWidgetInfo ${store?.registrationToggle ? "simpleFlowRegInfo":""}`:false}`}
        >
          { (store.widgetData?.isWidgetExpand && store?.widgetData?.showEventInfo && !store?.registrationToggle) || (!store.widgetData?.isWidgetExpand && !store?.registrationToggle) ? 
          <Box className="widgetTicketInfoDesc">
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-center",
              justifyContent: "space-between",
            }}
          >
            {store?.widgetData?.isWidgetExpand?false:
            <>
            {eventInfo?.eventLogo &&
              Array.isArray(eventInfo?.eventLogo) &&
              eventInfo?.eventLogo.length > 0 && (
                <Box sx={{ display: "block", maxHeight: "11.2rem", marginBottom:"1.1rem", width: "70px" }}>
                  <Slider {...settings}>
                    {eventInfo?.eventLogo?.map((logo: IEventLogo) => (
                      <Box key={logo.position} className={classes.eventLogo}>
                        <img
                          src={getEventLogoImage(logo?.imgFileName, organiserId)}
                          alt="no-logo"
                        />
                      </Box>
                    ))}
                  </Slider>
                </Box>
              )}
            {showAddToCalendar && !isEnded && eventInfo?.timezone ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <AddToCalendarModal
                  startFromSummary={startFromSummary}
                  event={event}
                  filename={title}
                  buttonText={t("add-to-calendar")}
                  items={
                    duration.length > 4
                      ? [SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]
                      : [
                          SHARE_SITES.GOOGLE,
                          SHARE_SITES.OUTLOOK,
                          SHARE_SITES.YAHOO,
                        ]
                  }
                />
              </Box>
            ) : undefined}
            </>}
          </Box>
          {/* <Box sx={{ mt: 1.5 }}> */}

            <>
            <Typography
              component="h2"
              color="textPrimary"
              variant="h4"
              title={landingPageData?.eventName}
              className={store?.widgetData?.isWidgetExpand? classes.truncateText:""}
              sx={(theme:any)=>({ mb: "5px !important", mt:"0px !important", textAlign: "left",fontWeight:"600 !important", color : `${theme.palette.text.primary} !important`})}
            >
              {landingPageData?.eventName}
            </Typography>
            {isShowEventDate ? 
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {!store?.widgetData?.isWidgetExpand ? <ClockIcon className={classes.clockIcon} /> : false}
              <Box sx={{ ml:store?.widgetData?.isWidgetExpand ? 0 : 1 }}>
                <Typography
                  component="p"
                  variant="subtitle2"
                  sx={(theme) => ({
                    marginTop:"0px !important",
                    marginBottom:"0px !important",
                    color:store?.widgetData?.isWidgetExpand ? theme.palette.primary.main : theme.palette.text.secondary,
                    fontWeight:store?.widgetData?.isWidgetExpand ? 600: 400,
                  })}
                >
                  {getEventDateRange(
                    landingPageData?.eventStartime as string,
                    landingPageData?.eventEndtime as string
                  )}
                </Typography>
              </Box>
            </Box> : null }
            </>
            {store?.widgetData?.isWidgetExpand?
            false:
            <>
            {landingPageData?.socialLinks &&
            Object.keys(landingPageData?.socialLinks).length > 0 ? (
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                {landingPageData.socialLinks?.website && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "website",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const website = landingPageData?.socialLinks
                        ?.website as string;
                      window.open(website, "_blank")?.focus();
                    }}
                    sx={(theme) => ({
                      "& svg": { color: theme.palette.primary.main },
                    })}
                  >
                    <LanguageIcon />
                  </IconButton>
                )}
                {landingPageData.socialLinks?.facebook && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "fb",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const facebook = landingPageData?.socialLinks
                        ?.facebook as string;
                      window.open(facebook, "_blank")?.focus();
                    }}
                    sx={{ "& svg": { color: "#4267B2" } }}
                  >
                    <FacebookIcon />
                  </IconButton>
                )}

                {landingPageData.socialLinks?.twitter && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "twitter",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const twitter = landingPageData?.socialLinks
                        ?.twitter as string;
                      window.open(twitter, "_blank")?.focus();
                    }}
                    sx={{ "& svg": { color: "#1DA1F2" } }}
                  >
                    <TwitterIcon />
                  </IconButton>
                )}
                {landingPageData.socialLinks?.linkedin && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "linkedin",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const linkedin = landingPageData?.socialLinks
                        ?.linkedin as string;
                      window.open(linkedin, "_blank")?.focus();
                    }}
                    sx={{ "& svg": { color: "#0072B1" } }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                )}

                {landingPageData.socialLinks?.instagram && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "instagram",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const instagram = landingPageData?.socialLinks
                        ?.instagram as string;
                      window.open(instagram, "_blank")?.focus();
                    }}
                    sx={{ "& svg": { color: "#A82DAF" } }}
                  >
                    <InstagramIcon />
                  </IconButton>
                )}
                {landingPageData.socialLinks?.youtube && (
                  <IconButton
                    className={classes.socialIcon}
                    onClick={() => {
                      Track.clickSocialLinks({
                        source: "landing page",
                        channel: "yt",
                        lpType: startFromSummary ? "default landing page": "custom landing page"
                      });
                      const youtube = landingPageData?.socialLinks
                        ?.youtube as string;
                      window.open(youtube, "_blank")?.focus();
                    }}
                    sx={(theme) => ({
                      "& svg": { color: theme.palette.error.main },
                    })}
                  >
                    <YouTubeIcon />
                  </IconButton>
                )}
              </Box>
            ) : undefined}
            </>}
            </Box>:false}
            {ticketDataLoading && <ListLoader />}
            {!eventInfo?.isNewTicketing || isEmpty(ticketData?.tickets)  ? (
              <></>
            ) : (
              <>
              {!store.registrationToggle ? <TicketList
                  ticketData={ticketData as ITicketDetailsResponse}
                  landingPageData={landingPageData}
                  configData={configData}
                  eventInfo={eventInfo}
                  setTicketId={setTicketId}
                /> : null
                }
              </>
            )}
        </Box>
    </>
  );
}
