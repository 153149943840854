/* eslint-disable no-console */
import React, {
  FC,
  ReactElement,
  useMemo,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@mui/material/styles";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import "./index.css";
import App from "./App";
import "./Assets/slickStyle.css";
import theme from "./theme";
import Box from "@mui/material/Box";
import createTheme from "@mui/material/styles/createTheme";
// import { createTheme } from "@hubilotechnologies/web-design-system/dist/theming/theme";
import { IConfigData, IEventInfo, ILandingPage } from "./Types/LandingPage";
import { IPaymentDetails, StoreProvider, useStore } from "./Context";
import useWebState, { IWebState } from "./Hooks/useWebstate";
import TicketInfoAndListLoader from "./Components/Loaders/TicketInfoAndListLoader";
import { IApplyDiscount } from "./Types/ApplyDiscount";
import { ITicketDetailsResponse } from "./Types/Ticket";
import i18n from "i18next";
import "./Internationalization";
import { I18nextProvider } from "react-i18next";
import NoSSR from "./Components/NoSSR";
import { initializeConfig } from "config";
import { initAmplitude } from "Lib/ThirdParty/amplitude/init";
import ErrorPage from "./Components/ErrorPage";
import OtherPaymentModal from "./Components/OffLinePayment";
import { isEmpty } from "lodash";
import { IWebinarFormResponse } from "Types/WebinarForm";

export interface IAuthEmailResponse {
  case?: string;
  ticketIds?: Array<number>;
  tickets?: ITicketDetailsResponse;
  buyerEmail?: string;
  buyerAlreadyRegisteredAsAttendee?: boolean;
  canShowResendLink?: boolean;
  code?: string;
}

export interface IMainComponentStandaloneProps {
  communityUrl: string;
  languageCode: string;
  isWidgetExpand: boolean;
  height?: string;
  width?: string;
  showEventInfo?: string;
  environment: string;
  userData: IAuthEmailResponse
}
export interface ITicketInfoProps {
  isDefaultLandingPage?: boolean;
  landingPageData?: ILandingPage;
  webinarData?: IWebinarFormResponse;
  eventInfo?: IEventInfo;
  containerWidth?: number;
  configData?: IConfigData;
  showAddToCalendar?: boolean;
  onCompleteFreePurchase?: (details: IPaymentDetails) => void;
  widgetDetails?: IMainComponentStandaloneProps;
  setKeepMounted: () => void;
  userData: IAuthEmailResponse
  onClose: () => void;
  purchase: boolean;
  microFeEventHandler?: () => void;
}

export interface ITicketAndDiscountData {
  ticketData?: ITicketDetailsResponse;
  discountData?: IApplyDiscount;
  widgetDetails?: IMainComponentStandaloneProps;
  registrationToggle?: boolean;
}

type IMainComponentProps = IWebState &
  ITicketAndDiscountData & {
    onClose?: () => void;
    environment: string;
    setKeepMounted: () => void;
    microFeEventHandler?: () => void;
  };


const generateClassName = createGenerateClassName({ productionPrefix: "tmf" });

const MainComponent: FC<IMainComponentProps> = (
  props: IMainComponentProps
): JSX.Element => {
  const themeData = useMemo(() => {
    // @ts-ignore
    const themeConfig = createTheme(theme(props.theme));
    return themeConfig;
  }, [props.theme]);

  const { store } = useStore();

  const setupLocale = useCallback(() => {
    let languageCode: string | undefined;

    if (props.configData?.language) {
      languageCode = props?.eventInfo?.supportedLanguages
        ?.find(
          (language: IEventInfo["supportedLanguages"][0]) =>
            language.id === props.configData?.language
        )
        ?.shortCode?.toLowerCase();
    }

    if (languageCode) {
      if (languageCode === "zh-hk") i18n.changeLanguage("zk");
      else i18n.changeLanguage(languageCode);
      // dayjs.locale(languageCode);
    }
  }, [props.configData?.language, props.eventInfo?.supportedLanguages]);

  useLayoutEffect(() => {
    initializeConfig(props.environment);
    initAmplitude();
  }, [props.environment]);

  useEffect(() => {
    setupLocale();
  }, [setupLocale]);

  useEffect(() => {
 
    const resizeListener = () => {
       const vh = window.innerHeight * 0.01;
       document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", () => {
      setTimeout(() => {
        resizeListener();
      }, 500);
    });
    return () => {
      window.removeEventListener("resize", resizeListener);
    };

  },[]);
  const [containerWidth, setContainerWidth] = React.useState(0);

  const thisComponent = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    const content = thisComponent?.current as HTMLDivElement;
    setContainerWidth(content?.offsetWidth);
  }, []);


  return (
    <NoSSR>

      <I18nextProvider i18n={i18n}>
        <StoreProvider>
          <StylesProvider generateClassName={generateClassName}>
          
            <ThemeProvider theme={themeData}>
            <style>
            {`
              html {
                font-size: 10px;
              }
              @media (min-width: 4320px) {
                html {
                  font-size: 25px;
                }
              }
              @media (max-width: 4320px) {
                html {
                  font-size: 20px;
                }
              }
              @media (max-width: 3840px) {
                html {
                  font-size: 18px;
                }
              }
              @media (max-width: 2560px) {
                html {
                  font-size: 15px;
                }
              }
              @media (max-width: 2160px) {
                html {
                  font-size: 12px;
                }
              }
              @media (max-width: 1680px) {
                html {
                  font-size: 10px;
                }
              }
              @media (max-width: 1440px) {
                html {
                  font-size: 10px; 
                }
              }
              @media (max-width: 1024px) {
                html {
                  font-size: 9px;  
                }
              }
              @media (max-width: 768px) {
                html {
                  font-size: 10px !important; 
                }
              }
            `}
          </style>
              <Box
                ref={thisComponent}
                id={props?.widgetDetails?.isWidgetExpand ? "wrapperForWidget" : ""}
                sx={(theme: any) => ({
                  backgroundColor: props?.widgetDetails?.isWidgetExpand ? theme.palette.background.cardColor : "transparent",
                  display: "block",
                  height:props?.widgetDetails?.isWidgetExpand ? props?.widgetDetails?.height: "100%",
                  width: props?.widgetDetails?.isWidgetExpand ? props?.widgetDetails?.width : "100%",
                  "@media(max-width:1024px)": {
                    paddingRight: 0,
                    width: "100%",
                  }
                })}
              >
                {props.ticketData && props.discountData ? props?.userData?.case === "NEW_USER" || props?.ticketData.isSimpleRegistrationEnabled || isEmpty(store?.paymentDetails) ?
                  <App {...props} containerWidth={containerWidth} widgetDetails={props.widgetDetails} /> :
                  <OtherPaymentModal  {...props} isLoading={false} /> :
                  <App {...props} containerWidth={containerWidth} widgetDetails={props.widgetDetails} />
                }
                {/* <App {...props} containerWidth={containerWidth} widgetDetails={props.widgetDetails} /> */}
              </Box>
            </ThemeProvider>
          </StylesProvider>
        </StoreProvider>
      </I18nextProvider>
    </NoSSR>
  );
};

// const ticketData = {
//   tickets: [
//     {
//       "id": 3592,
//       "title": "Balaji Test Ticket",
//       "description": "Balaji Test Ticket",
//       "closeAt": 1648665000000,
//       "ticketFee": 10,
//       "maxLimit": 16,
//       "currency": {
//         "id": 1,
//         "symbol": "$"
//       },
//       "seatLeft": 16,
//       "canShowCouponBox": false,
//       "discount": [
//         {
//           "id": 775,
//           "desc": "First ticket discount",
//           "isPercent": false,
//           "currency": "$",
//           "value": 2
//         },
//         {
//           "id": 776,
//           "desc": "Second ticket",
//           "isPercent": false,
//           "currency": "$",
//           "value": 1
//         }
//       ]
//     },
//     {
//       "id": 3641,
//       "title": "Testing two 2 ",
//       "description": "Testing two 2 ticketing is here",
//       "closeAt": 1648665000000,
//       "ticketFee": 20,
//       "maxLimit": 30,
//       "currency": {
//         "id": 1,
//         "symbol": "$"
//       },
//       "seatLeft": 30,
//       "canShowCouponBox": false,
//       "discount": [
//         {
//           "id": 779,
//           "desc": "Tick01",
//           "isPercent": false,
//           "currency": "$",
//           "value": 2
//         }
//       ]
//     },
//     {
//       "id": 3805,
//       "title": "ticket 3",
//       "description": null,
//       "closeAt": 1648665000000,
//       "ticketFee": 0,
//       "maxLimit": 111,
//       "currency": null,
//       "seatLeft": 111,
//       "canShowCouponBox": false,
//       "discount": []
//     }
//   ],
//   "isbuyerForm": true,
//   "isBuyerForm": true,
//   "goToEventCtaLabel": "Go to Event",
//   "showGoToEventCTA": true,
//   "isGdprComplianceEnabled": true,
//   "gdprComplianceConsentText": "<p>I agree to the <a href=\"https://legal.demohubilo.com/terms/38521\" target=\"_blank\">Terms and Conditions</a> and <a href=\"https://legal.demohubilo.com/privacy/38521\" target=\"_blank\">Privacy Policy</a>.</p>",
//   "hideFreeLabel": false
// } as unknown as ITicketDetailsResponse;

// const discountData = {
//   orderId: 23823,
//   ticketsInfo: [
//     {
//         "id": 3592,
//         "isApplied": false,
//         "isComplete": false,
//         "quantity": 1
//     },
//     {
//         "id": 3641,
//         "isApplied": false,
//         "isComplete": false,
//         "quantity": 1
//     },
//     {
//         "id": 3805,
//         "isApplied": false,
//         "isComplete": false,
//         "quantity": 0
//     }
// ]};

// When used as a standalone widget.
const MainStandaloneComponent = (
  props: IMainComponentStandaloneProps
): ReactElement => {
  //@ts-ignore
  const { data, loading, error, userData } = useWebState(props);
  const newData: any = { ...data, configData: { ...data.configData, originalCommunityUrl: data.configData?.communityUrl, communityUrl: props.isWidgetExpand ? window.location.origin + window.location.pathname : data.configData?.communityUrl }, userData };
  // const {setUserData} = useStore();

  useLayoutEffect(() => {
    initializeConfig(props.environment);
  }, [props.environment]);


  if (loading) {
    return <TicketInfoAndListLoader />;
  }
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return <ErrorPage />;
  }
  return (
    <MainComponent
      widgetDetails={props}
      environment={props.environment}
      {...newData}
    />
  );
};

let loadContainer: Element | DocumentFragment;

// Used by Duda.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function init({ container, props }: any = {}) {
  loadContainer = container;
  ReactDOM.render(<MainStandaloneComponent {...props} />, container);
}

// Used by Duda.
function clean() {
  if (loadContainer) {
    ReactDOM.unmountComponentAtNode(loadContainer);
  }
}

// Used when run locally.
if (process.env.NODE_ENV === "development" && typeof window === "object") {
  init({
    container: document.getElementById("root"),
    props: {
      communityUrl: "events.perfoneeks.demohubilo.com/landing-page-issue/register",
      languageCode: "en",
      isWidgetExpand: true,
      height: "700px",
      width: "600px",
      showEventInfo: true,
      environment: "perfoneeks"
    },
  });
}

// Used when imported as an npm package inside default landing page.
export { MainComponent, MainStandaloneComponent, init, clean };
