import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(calendar);

function getEventDateRange(
  startEpoch: string | number,
  endEpoch: string | number
) {
  const start = dayjs(Number(startEpoch));
  const end = dayjs(Number(endEpoch));
  if (start.format("YYYY-MM-DD") === end.format("YYYY-MM-DD")) {
    return start.format("MMM DD, YYYY");
  }
  if (start.format("YYYY-MM") === end.format("YYYY-MM")) {
    return `${start.format("MMM DD")} - ${end.format("DD")}, ${start.format(
      "YYYY"
    )}`;
  }
  if (start.format("YYYY") === end.format("YYYYY")) {
    return `${start.format("MMM DD")} - ${end.format("MMM DD")}, ${start.format(
      "YYYY"
    )}`;
  }
  return `${start.format("MMM DD, YYYY")} - ${end.format("MMM DD, YYYY")}`;
}

function formatDate(
  dateTimeString: string | number,
  targetFormat: string,
  currentFormat = null
) {
  let result;
  if (currentFormat) {
    result = dayjs(dateTimeString, currentFormat).format(targetFormat);
  } else {
    result = dayjs(dateTimeString).format(targetFormat);
  }
  if (result.toLowerCase() === "invalid date") {
    return dayjs().format(targetFormat);
  }
  return result;
}

export { getEventDateRange, formatDate };