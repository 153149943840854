import React from "react";
import { IIconProps } from "./types";

const NewTabIcon = ({
  width = "3.2rem",
  height = "3.2rem",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 9.0165C14.779 9.0165 14.567 9.1043 14.4108 9.26058C14.2545 9.41686 14.1667 9.62882 14.1667 9.84984V15.8332C14.1667 16.0542 14.0789 16.2661 13.9226 16.4224C13.7663 16.5787 13.5544 16.6665 13.3334 16.6665H4.16669C3.94567 16.6665 3.73371 16.5787 3.57743 16.4224C3.42115 16.2661 3.33335 16.0542 3.33335 15.8332V6.6665C3.33335 6.44549 3.42115 6.23353 3.57743 6.07725C3.73371 5.92097 3.94567 5.83317 4.16669 5.83317H10.15C10.371 5.83317 10.583 5.74537 10.7393 5.58909C10.8956 5.43281 10.9834 5.22085 10.9834 4.99984C10.9834 4.77882 10.8956 4.56686 10.7393 4.41058C10.583 4.2543 10.371 4.1665 10.15 4.1665H4.16669C3.50365 4.1665 2.86776 4.4299 2.39892 4.89874C1.93008 5.36758 1.66669 6.00346 1.66669 6.6665V15.8332C1.66669 16.4962 1.93008 17.1321 2.39892 17.6009C2.86776 18.0698 3.50365 18.3332 4.16669 18.3332H13.3334C13.9964 18.3332 14.6323 18.0698 15.1011 17.6009C15.57 17.1321 15.8334 16.4962 15.8334 15.8332V9.84984C15.8334 9.62882 15.7456 9.41686 15.5893 9.26058C15.433 9.1043 15.221 9.0165 15 9.0165ZM18.2667 2.18317C18.1821 1.97955 18.0203 1.81773 17.8167 1.73317C17.7165 1.69047 17.6089 1.66782 17.5 1.6665H12.5C12.279 1.6665 12.067 1.7543 11.9108 1.91058C11.7545 2.06686 11.6667 2.27882 11.6667 2.49984C11.6667 2.72085 11.7545 2.93281 11.9108 3.08909C12.067 3.24537 12.279 3.33317 12.5 3.33317H15.4917L6.90835 11.9082C6.83025 11.9856 6.76825 12.0778 6.72594 12.1794C6.68364 12.2809 6.66186 12.3898 6.66186 12.4998C6.66186 12.6098 6.68364 12.7188 6.72594 12.8203C6.76825 12.9219 6.83025 13.014 6.90835 13.0915C6.98582 13.1696 7.07799 13.2316 7.17954 13.2739C7.28109 13.3162 7.39001 13.338 7.50002 13.338C7.61003 13.338 7.71895 13.3162 7.8205 13.2739C7.92205 13.2316 8.01422 13.1696 8.09169 13.0915L16.6667 4.50817V7.49984C16.6667 7.72085 16.7545 7.93281 16.9108 8.08909C17.067 8.24537 17.279 8.33317 17.5 8.33317C17.721 8.33317 17.933 8.24537 18.0893 8.08909C18.2456 7.93281 18.3334 7.72085 18.3334 7.49984V2.49984C18.332 2.39094 18.3094 2.28336 18.2667 2.18317V2.18317Z"
        fill="#909090"
      />
    </svg>
  );
};

export default NewTabIcon;
