import Grid from "@mui/material/Grid";
import SkeletonLoader from "../SkeletonLoader";
import React from "react";


export default function BuyerAttendeeDetailLoader(){
    // const classes = useStyles();
    return(
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <SkeletonLoader height="16px" width="120px" variant="text"/>
                    <SkeletonLoader height="40px" width="100%" variant="rect" mt="3px"/>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SkeletonLoader height="16px" width="120px" variant="text"/>
                    <SkeletonLoader height="40px" width="100%" variant="rect" mt="3px"/>
                </Grid>
                {[1,2].map((i)=>(
                <Grid key={i} item xs={12}>
                    <SkeletonLoader height="16px" width="120px" variant="text"/>
                    <SkeletonLoader height="40px" width="100%" variant="rect" mt="3px"/>
                </Grid>))}
            </Grid>
    );
}