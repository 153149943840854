import Box from "@mui/material/Box";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import RadioGroupWithIcon from "../RadioGroupNewUI/RadioGroupWithIcon";
import Button from "@mui/material/Button";
import { ChoosePaymentMethodLoader } from "../Loaders/PaymentMethodLoader";
import usePaymentMethod from "Hooks/usePaymentMethod";
import { useTranslation } from "react-i18next";
import { useStore } from "Context";
import {
  OFFLINE_PAYMENT_LIST,
  PAYMENT_METHOD_DTLS,
  TS_OTHER_PAYMENT_TYPE,
} from "../../Components/PaymentMethod/index";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import envConfig from "config";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";



// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChoosePaymentMethod = ({ configData, isLoading, handlePaymentMethodSubmit }: any) => {
  const { store, setOtherPaymentDetails } =
    useStore();

  const [radioStateSelected, setRadioStateSelected] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isSubmitLoading, setSubmitLoading, error, paymentMethod } =
    usePaymentMethod();
  const [t] = useTranslation();

  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();

  const availableOnlinePaymentMethod =
    store?.otherPaymentDetails?.paymentMethods
      ?.sort((a, b) => a.position - b.position)
      .reduce(
        (previous: any, current) => (
          current.type === TS_OTHER_PAYMENT_TYPE.ONL &&
          current.isActive === true &&
          previous.push({
            ...NEW_PAYMENT_METHOD_DTLS[current.name],
            details: current.details,
          }),
          previous
        ),
        []
      );

  const availableOtherPaymentMethod = store?.otherPaymentDetails?.paymentMethods
    ?.sort((a, b) => a.position - b.position)
    .reduce(
      (previous: any, current) => (
        current.type === TS_OTHER_PAYMENT_TYPE.OFL &&
        current.isActive === true &&
        previous.push({
          ...NEW_PAYMENT_METHOD_DTLS[current.name],
          details: current.details,
        }),
        previous
      ),
      []
    );
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherPaymentDetails({
      ...store.otherPaymentDetails,
      selectedPaymentMethod: availableOtherPaymentMethod.find(
        (data: any) => data.name === (event.target as HTMLInputElement).value
      ),
    });
    setRadioStateSelected((event.target as HTMLInputElement).value);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStripeCheckout = async (token: string) => {
    if (!token) return;
    let stripe: Stripe | null;
    if (store?.stripeAccountId) {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "", {
        stripeAccount: store?.stripeAccountId,
      });
    } else {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "");
    }
    const result = await stripe?.redirectToCheckout({
      sessionId: token,
    });
    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    return result;
  };

  useEffect(() => {
    setRadioStateSelected(
      store?.otherPaymentDetails?.selectedPaymentMethod?.name
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <ChoosePaymentMethodLoader />
      ) : (
        <Box
          py={2.5}
          pl={2.5}
          pr={1}
          sx={{
            minHeight: "calc((var(--vh, 1vh) * 100) - 50px)",
            position: "relative",
            "@media(max-width:640px)": {
              minHeight: "auto",
              height: "100%",
              pb: 2,
              pl: 2,
              pr: 0.5,
              pt: 7,
            },
          }}
        >
          <Box
            sx={{
              maxHeight: "calc((var(--vh, 1vh) * 100) - 310px)",
              pr: 1.5,
              overflowY: "auto",
              "@media(max-width:640px)": { maxHeight: "calc(100% - 70px)" },
            }}
          >
            <Typography
              color="textPrimary"
              variant="h2"
              component="h3"
              sx={{ fontWeight: 700 }}
            >
              {t("CHOOSE_PAYMENT_METHOD")}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mt: 0.5 }}
              component="p"
              color="textSecondary"
            >
              Order ID : {store.orderNo}
            </Typography>
            <Typography
              sx={{ mt: 3, fontWeight: 400, mb: "0px !important" }}
              variant="body2"
              component="p"
              color="textPrimary"
            >
              {t("TICKET_CONFIRMED_BY_ORGANIZER")}
            </Typography>

            {store?.otherPaymentDetails?.paymentMethodTypesAvailable?.includes(
              TS_OTHER_PAYMENT_TYPE.ONL
            ) ? (
              <Box
                sx={(theme: any) => ({
                  mt: 3,
                  border: `1px solid ${theme.palette.divider.default}`,
                  borderRadius: "6px",
                })}
              >
                <Typography
                  variant="body1"
                  sx={(theme: any) => ({
                    borderBottom: `1px solid ${theme.palette.divider.default}`,
                    fontWeight: 700,
                    p: 2,
                    mb: 2,
                  })}
                  color="textPrimary"
                  component="h4"
                >
                  {t("PAY_NOW")}
                </Typography>
                {availableOnlinePaymentMethod.length ? (
                  <RadioGroupWithIcon
                    value={radioStateSelected}
                    handleChange={handleChangeRadio}
                    options={availableOnlinePaymentMethod}
                  />
                ) : (
                  false
                )}
              </Box>
            ) : (
              false
            )}
            {store?.otherPaymentDetails?.paymentMethodTypesAvailable?.includes(
              TS_OTHER_PAYMENT_TYPE.OFL
            ) ? (
              <Box
                sx={(theme: any) => ({
                  mt: 2.5,
                  border: `1px solid ${theme.palette.divider.default}`,
                  borderRadius: "6px",
                })}
              >
                <Typography
                  variant="body1"
                  sx={(theme: any) => ({
                    borderBottom: `1px solid ${theme.palette.divider.default}`,
                    fontWeight: 700,
                    p: 2,
                    mb: 2,
                  })}
                  color="textPrimary"
                  component="h4"
                >
                  {t("PAY_VIA_OTHER_METHODS")}
                </Typography>
                {availableOtherPaymentMethod.length ? (
                  <RadioGroupWithIcon
                    value={radioStateSelected}
                    handleChange={handleChangeRadio}
                    options={availableOtherPaymentMethod}
                  />
                ) : (
                  false
                )}
              </Box>
            ) : (
              false
            )}
          </Box>
          <Box
            sx={(theme: any) => ({
              borderTop: `1px solid ${theme.palette.divider.default}`,
              mt: 2,
              position: "absolute",
              width: "100%",
              left: 0,
              bottom: 0,
              p: 2.5,
              "@media(max-width:640px)": { p: 2 },
            })}
            className="builderAction"
          >
            {OFFLINE_PAYMENT_LIST.indexOf(radioStateSelected) !== -1 ? (
              <Box
                mb={2}
                sx={(theme: any) => ({
                  background: theme.palette.error.light,
                  "&>svg": {
                    height: "20px",
                    width: "20px",
                    color: theme.palette.error.main,
                  },
                  border: `1px solid ${theme.palette.error.main}`,
                  borderRadius: "10px",
                  p: 1.5,
                  display: "flex",
                  alignItems: "center",
                })}
              >
                <ErrorOutlineIcon />
                <Typography
                  component="p"
                  sx={{
                    ml: 2,
                    fontWeight: 400,
                    color: "#202020",
                    mt: "0px !important",
                    mb: "0px !important",
                  }}
                  variant="body2"
                > {radioStateSelected === "PAV" ? `${t("TICKET_NOT_CONFIRMED_UNTIL_PAYMENT")}` : `${t("TICKET_NOT_CONFIRMED_ORGANIZER")}`}

                </Typography>
              </Box>
            ) : (
              false
            )}

            <Box
              sx={(theme: any) => ({
                borderRadius: "4px",
                background: theme.palette.primary.lighter,
                mb: 2,
                px: 2,
                py: 1,
                color: theme.palette.primary.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              })}
            >
              <Typography variant="body1" component="label">
                Amount Payable
              </Typography>
              <Typography variant="body1" component="span">
                ${store?.totalAmount}
              </Typography>
            </Box>
            <Button
              disabled={isSubmitLoading}
              onClick={() => handlePaymentMethodSubmit(radioStateSelected)}
              variant="contained"
              color="primary"
              sx={{ width: "100%" }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ChoosePaymentMethod;
