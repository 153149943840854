import { isEmpty } from "lodash";
import { IEventInfo } from "Types/LandingPage";

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum AppType {
  GOOGLE = "Google",
  FB = "Facebook",
  OUT_BRAIN = "Outbrain",
}

export const trackEventPixelsByApp = (app: AppType, action?: string) => {
  if (typeof window === "object" && typeof window !== "undefined") {
    switch (app) {
      case "Google": {
        (window as any)?.gtag("event", action);
        break;
      }
      case "Facebook": {
        (window as any)?.fbq("trackCustom", action);
        break;
      }
      case "Outbrain": {
        (window as any)?.obApi("track", action);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log("No apps chosen to track");
        break;
      }
    }
  } else {
    trackEventPixelsByApp(app, action);
  }
};

export const trackPageViewsByApp = (
  app: string,
  pageTitle?: string,
  pageLocation?: string,
  pagePath?: string,
  trackId?: string
) => {
  switch (app) {
    case "Google": {
      (window as any)?.gtag("event", "page_view", {
        page_title: pageTitle,
        page_location: pageLocation,
        page_path: pagePath,
        send_to: trackId,
      });
      break;
    }

    case "Facebook": {
      (window as any)?.fbq("track", pageTitle);
      break;
    }

    case "Outbrain": {
      (window as any)?.obApi("track", pageTitle);
      break;
    }

    default: {
      // eslint-disable-next-line no-console
      console.log("No apps chosen to track");
      break;
    }
  }
};

export const getPixelTrackingScriptByApp = (
  trackingId: string,
  app: AppType
): string => {
  switch (app) {
    case AppType.GOOGLE:
      return `<!-- Global site tag (gtag.js) - Google Analytics -->
          <script async src="https://www.googletagmanager.com/gtag/js?id=${trackingId}"></script>
          <script>
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${trackingId}', { cookie_flags: 'SameSite=None;Secure' });
          </script>`;
    case AppType.FB:
      return `<script>
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
        n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
        document,'script','https://connect.facebook.net/en_US/fbevents.js');
        
        fbq.disablePushState = true; //not recommended, but can be done
        
        fbq('init', '${trackingId}');
        </script>`;
    case AppType.OUT_BRAIN:
      return `<script data-obct type = "text/javascript">
        /** DO NOT MODIFY THIS CODE**/
        !function(_window, _document) {
          var OB_ADV_ID = '${trackingId}';
          if (_window.obApi) {
            var toArray = function(object) {
              return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
            };
            _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
            return;
          }
          var api = _window.obApi = function() {
            api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
          };
          api.version = '1.1';
          api.loaded = true;
          api.marketerId = OB_ADV_ID;
          api.queue = [];
          var tag = _document.createElement('script');
          tag.async = true;
          tag.src = '//amplify.outbrain.com/cp/obtp.js';
          tag.type = 'text/javascript';
          var script = _document.getElementsByTagName('script')[0];
          script.parentNode.insertBefore(tag, script);
        }(window, document);
      </script>`;
    default:
      return "";
  }
};

export const addPreEventAnalytics = (eventInfo: IEventInfo) => {
  if (eventInfo?.trackingPixelApps?.some(
    (eachItem: { isEnabled: boolean }) => eachItem.isEnabled
  )) {
    if (!isEmpty(eventInfo?.trackingPixelApps)) {
      eventInfo?.trackingPixelApps
        ?.filter((eachApp) => eachApp.isEnabled)
        .map((eachItem) => {
          switch (eachItem.app) {
            case AppType.GOOGLE: {
              const trackingElement = document
                .createRange()
                .createContextualFragment(
                  getPixelTrackingScriptByApp(
                    eachItem.trackingId,
                    AppType.GOOGLE
                  )
                );
              document.head.appendChild(trackingElement);
              break;
            }
            case AppType.FB: {
              const trackingElement = document
                .createRange()
                .createContextualFragment(
                  getPixelTrackingScriptByApp(eachItem.trackingId, AppType.FB)
                );
              document.head.appendChild(trackingElement);
              break;
            }
            case AppType.OUT_BRAIN: {
              const trackingElement = document
                .createRange()
                .createContextualFragment(
                  getPixelTrackingScriptByApp(
                    eachItem.trackingId,
                    AppType.OUT_BRAIN
                  )
                );
              document.head.appendChild(trackingElement);
              break;
            }
            default: {
              // console.log("No apps chosen to track");
              break;
            }
          }
        });
    }
  }
};