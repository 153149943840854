// eslint-disable-next-line @typescript-eslint/no-explicit-any
// libraries
import get from "lodash/get";
import pluralize from "pluralize";

// Constants
import {
  IS_REQUIRED,
  IS_REQUIRE_OTHER,
  IS_INVALID_DATA,
  MIN_DATA,
  MAX_DATA,
  GROUP_IS_REQUIRE,
  GROUP_MIN_DATA,
  GROUP_MAX_DATA,
  IS_REQUIRED_SELECT,
} from "../Constants/FormFieldsValidation";

// Utils
import customRegex from "../Utils//RegEx";
import { fixRegExp, getPhoneCode } from "../Utils/common";
import { t } from "i18next";

const getIsRequireCheck = (name: string) => {
  return t(IS_REQUIRED).replace("<FIELD_NAME>", name);
};
const getIsSelectCheck = (name: string) => {
  return t(IS_REQUIRED_SELECT).replace("<FIELD_NAME>", name);
};
const getInvalidCheck = (name: string) => {
  return t(IS_INVALID_DATA).replace("<FIELD_NAME>", name);
};
const getInvalidLink = (name: string) => {
  return t(IS_INVALID_DATA).replace("<FIELD_NAME>", name);
};
const getInvalidDate = (name: string) => {
  return t(IS_INVALID_DATA).replace("<FIELD_NAME>", name);
};
const getInvalidNumber = (name: string) => {
  return t(IS_INVALID_DATA).replace("<FIELD_NAME>", name);
};
const getInvalidGroup = (name: string) => {
  return t(GROUP_IS_REQUIRE).replace("<FIELD_NAME>", name);
};
const getIsRequireOther = (name: string) => {
  return t(IS_REQUIRE_OTHER).replace("<FIELD_NAME>", name);
};

const getMinLengthCheck = (name: string, len: string, type: string) => {
  let returnData = t(MIN_DATA).replace("<FIELD_NAME>", name);
  returnData = returnData.replace("<FIELD_LENGTH>", len);
  returnData = returnData.replace(
    "<FIELD_TYPE>",
    Number(len) === 1 ? pluralize.singular(type) : pluralize.plural(type)
  );
  return returnData;
};

const getMaxLengthCheck = (name: string, len: string, type: string) => {
  let returnData = t(MAX_DATA).replace("<FIELD_NAME>", name);
  returnData = returnData.replace("<FIELD_LENGTH>", len);
  returnData = returnData.replace(
    "<FIELD_TYPE>",
    Number(len) === 1 ? pluralize.singular(type) : pluralize.plural(type)
  );
  return returnData;
};

const getMinGroupLengthCheck = (name: any, len: string) => {
  let returnData = t(GROUP_MIN_DATA).replace("<FIELD_NAME>", name);
  returnData = returnData.replace("<FIELD_LENGTH>", len);
  returnData = returnData.replace(
    "<VERB>",
    !Number.isNaN(len) && Number(len) > 1 ? t("verb-are") : t("verb-is")
  );
  return returnData;
};
const getMaxGroupLengthCheck = (name: any, len: string) => {
  let returnData = t(GROUP_MAX_DATA).replace("<FIELD_NAME>", name);
  returnData = returnData.replace("<FIELD_LENGTH>", len);
  returnData = returnData.replace(
    "<VERB>",
    !Number.isNaN(len) && Number(len) > 1 ? t("verb-are") : t("verb-is")
  );
  return returnData;
};

const getTextValidate = (field: any) => {
  const fieldValue = get(field, "value", "");
  const value = fieldValue || "";
  const fieldType = get(field, "fieldType", "");
  const fetchRegex = get(field, "regex", "");
  const minLength = get(field, "minLength", 0);
  const maxLength = get(field, "maxLength", 0);
  const fieldName = get(field, "label", "this");
  const isMandatory: boolean = get(field, "isMandatory", false);
  // [HUB-8579] Added multiple language support for Buyer Default Fields
  const isDefaultField: boolean = get(field, "isDefaultField", false);
  const multiLangLabel = isDefaultField ? t(fieldType) : fieldName;
  // const isVisible: boolean = get(field, 'isVisible', false);
  const isEmpty = !value || value.toString().trim() === "";
  const [appRegExp, Opts] = fixRegExp(
    fetchRegex?.replace(/\\\\/g, "\\")?.replace(/\/\//g, "/")
  );
  const exeRegex =
    fieldType === "email"
      ? customRegex.emailRegEx
      : appRegExp
      ? new RegExp(appRegExp, Opts)
      : "";

  if (!isMandatory && isEmpty) return { hasError: false, errMsg: "" };
  else if (isMandatory && isEmpty) {
    return {
      hasError: true,
      errMsg: getIsRequireCheck(multiLangLabel),
    };
  } else if (value && exeRegex && !exeRegex.test(value))
    return { hasError: true, errMsg: getInvalidCheck(multiLangLabel) };
  else if (value.toString().trim().length < minLength)
    return {
      hasError: true,
      errMsg: getMinLengthCheck(multiLangLabel, minLength, t("character")),
    };
  else if (
    maxLength &&
    value
      .toString()
      .replace(/(\r\n|\n|\r)/gm, "")
      .trim().length > maxLength
  )
    return {
      hasError: true,
      errMsg: getMaxLengthCheck(multiLangLabel, maxLength, t("character")),
    };
  return { hasError: false, errMsg: "" };
};

const getNumberValidate = (field: any) => {
  const fieldType = get(field, "fieldType", "");
  const fieldName = get(field, "label", "this");
  const fieldValue = get(field, "value", "");
  const isMandatory: boolean = get(field, "isMandatory", false);
  const fetchRegex = get(field, "regex", "");
  const minLength = get(field, "minLength", 0);
  const maxLength = get(field, "maxLength", 0);
  // [HUB-8579] Added multiple language support for Buyer Default Fields
  const isDefaultField: boolean = get(field, "isDefaultField", false);
  const multiLangLabel = fieldType === "phone" ? fieldName : isDefaultField ? fieldType : fieldName;

  const [appRegExp, Opts] = fixRegExp(
    fetchRegex?.replace(/\\\\/g, "\\")?.replace(/\/\//g, "/")
  );
  const exeRegex = appRegExp ? new RegExp(appRegExp, Opts) : "";

  const value = fieldValue || "";

  if (fieldType === "phone") {
    const [dialer = "", phone = ""] = getPhoneCode(value);
    const isEmpty = !dialer && !phone;
    if (isMandatory) {
      if (!dialer)
        return { hasError: true, errMsg: getIsSelectCheck(t("dialerCode")) };
      if (!phone)
        return { hasError: true, errMsg: getIsRequireCheck(multiLangLabel) };
    }
    if (!dialer && phone)
      return { hasError: true, errMsg: getIsSelectCheck(t("dialerCode")) };
    else if (dialer && !phone)
      return { hasError: true, errMsg: getIsRequireCheck(multiLangLabel) };
    else if (!isMandatory && isEmpty) return { hasError: false, errMsg: "" };
    else if (isMandatory && isEmpty) {
      return {
        hasError: true,
        errMsg: getIsRequireCheck(multiLangLabel),
      };
    } else if (value && exeRegex && !exeRegex.test(value))
      return { hasError: true, errMsg: getInvalidCheck(multiLangLabel) };
    else if (phone.toString().trim().length < minLength)
      return {
        hasError: true,
        errMsg: getMinLengthCheck(multiLangLabel, minLength, t("character")),
      };
    else if (
      maxLength &&
      phone
        .toString()
        .replace(/(\r\n|\n|\r)/gm, "")
        .trim().length > maxLength
    ) {
      return {
        hasError: true,
        errMsg: getMaxLengthCheck(fieldName, maxLength, t("character")),
      };
    } else {
      return { hasError: false, errMsg: "" };
    }
  }
  return getTextValidate(field);
};

const getRadioValidate = <T>(field: T) => {
  const fieldName = get(field, "label", "this");
  const value = get(field, "value", "");
  const otherValue = get(field, "otherValue", "");
  const showOtherOption = get(field, "showOtherOption", false);
  const isMandatory: boolean = get(field, "isMandatory", false);
  const isEmpty = !value || value.toString().trim() === "";
  const hasOther = showOtherOption ? value === "Other" : false;

  if (!isMandatory && isEmpty) return { hasError: false, errMsg: "" };
  else if (isMandatory && isEmpty) {
    return {
      hasError: true,
      errMsg: getIsRequireCheck(fieldName),
    };
  } else if (isMandatory && hasOther && !otherValue) {
    return {
      hasError: true,
      errMsg: getIsRequireOther(fieldName),
    };
  } else if (!isMandatory && hasOther && !otherValue) {
    return {
      hasError: true,
      errMsg: getIsRequireOther(fieldName),
    };
  }

  return { hasError: false, errMsg: "" };
};

const getCheckboxValidate = <T>(field: T) => {
  const fieldName = get(field, "label", "this");
  const value = get(field, "valueOptions", []);
  const otherValue = get(field, "otherValue", "");
  const showOtherOption = get(field, "showOtherOption", false);
  const isMandatory: boolean = get(field, "isMandatory", false);
  // const isVisible: boolean = get(field, 'isVisible', false);
  const isEmpty =
    (Array.isArray(value) && value.length === 0) ||
    !value ||
    value.toString().trim() === "";
  const hasOther = showOtherOption ? value.includes("Other") : false;

  if (!isMandatory && isEmpty) return { hasError: false, errMsg: "" };
  else if (isMandatory && isEmpty) {
    return {
      hasError: true,
      errMsg: getIsRequireCheck(fieldName),
    };
  } else if (isMandatory && hasOther && !otherValue) {
    return {
      hasError: true,
      errMsg: getIsRequireOther(fieldName),
    };
  } else if (!isMandatory && hasOther && !otherValue) {
    return {
      hasError: true,
      errMsg: getIsRequireOther(fieldName),
    };
  }

  return { hasError: false, errMsg: "" };
};

function groupFieldValidation<T, K>(
  groupValue: T[],
  groupField: K[],
  min?: null | number,
  max?: null | number,
  fieldTypeName?: string
) {
  let totalGroupNumber = 0;
  let hasError = false;
  let errMsg = "";

  const errData = groupValue.map((group: any, index: number) => {
    let hasGroupData = false;
    if (index === 0) return undefined;
    else {
      const err: { [key: string]: string } = {};
      groupField.forEach((field: K) => {
        const id = get(field, "id", "");
        const type = get(field, "type", "");
        const fieldName = get(field, "label", "");
        const fetchRegex = get(field, "regex", "");
        const isMandatory = get(field, "isMandatory", false);
        const isDisabledField = get(field, "isDisabledField", "");

        const [appRegExp, Opts] = fixRegExp(
          fetchRegex?.replace(/\\\\/g, "\\")?.replace(/\/\//g, "/")
        );
        const exeRegex = appRegExp ? new RegExp(appRegExp, Opts) : "";

        const value = group[id];

        if (value) {
          if (!hasGroupData) {
            totalGroupNumber += 1;
            hasGroupData = true;
          }
          const parts = typeof value === "string" ? value.split("/") : [];
          switch (type) {
            case "TEXT":
              if (
                (!isDisabledField || isDisabledField === "NO") &&
                exeRegex &&
                !exeRegex.test(value)
              )
                err[id] = getInvalidCheck(fieldName);
              break;
            case "DATE":
              if (
                (!isDisabledField || isDisabledField === "NO") &&
                Number.isNaN(Date.parse(`${parts[2]}-${parts[1]}-${parts[0]}`))
              )
                err[id] = getInvalidDate(fieldName);
              break;
            case "LINK":
              if (
                (!isDisabledField || isDisabledField === "NO") &&
                exeRegex &&
                !exeRegex.test(value)
              )
                err[id] = getInvalidLink(fieldName);
              break;
            case "EMAIL":
              if (
                (!isDisabledField || isDisabledField === "NO") &&
                exeRegex &&
                !exeRegex.test(value)
              )
                err[id] = getInvalidCheck(fieldName);
              break;
            case "NUMBER":
              // [HUB-8857] Not getting validation message for Group number field
              if (
                (!isDisabledField || isDisabledField === "NO") &&
                exeRegex &&
                !exeRegex.test(value)
              )
                err[id] = getInvalidNumber(fieldName);
              break;
            default:
              break;
          }
        }
        if (isMandatory && !value) err[id] = getInvalidGroup(fieldName);
        if (!hasError && Object.keys(err).length > 0) hasError = true;
      });
      return err;
    }
  });

  if (min && totalGroupNumber < min)
    errMsg = getMinGroupLengthCheck(fieldTypeName, String(min));
  else if (max && totalGroupNumber > max)
    errMsg = getMaxGroupLengthCheck(fieldTypeName, String(max));
  if (!hasError && errMsg) hasError = true;

  return { hasError, errMsg, errData };
}

const getGroupValidate = <T>(field: T) => {
  let min = 0;
  let max = 0;

  const minLength = get(field, "minLength", -1);
  const maxLength = get(field, "maxLength", -1);
  const fieldTypeName = get(field, "fieldTypeName", "Person");

  const children = get(field, "children", []);
  const values = get(field, "customGroupValueArray", []);

  if (minLength > -1) min = minLength;
  if (maxLength > 0) max = maxLength;
  return groupFieldValidation(values, children, min, max, fieldTypeName);
};

const getMandatoryValidate = (field: any) => {
  const value = get(field, "value", "");
  const fieldName = get(field, "label", "this");
  const isMandatory: boolean = get(field, "isMandatory", false);
  const isEmpty = !value || value.toString().trim() === "";
  if (isMandatory && isEmpty) {
    return {
      hasError: true,
      errMsg: getIsRequireCheck(fieldName),
    };
  } else {
    return {
      hasError: false,
      error: "",
    };
  }
};

const getLinkValidate = <T>(field: T) => {
  const value = get(field, "value", "");
  const fetchRegex = get(field, "regex", "");
  const fieldName = get(field, "label", "this");
  const isMandatory: boolean = get(field, "isMandatory", false);
  // const isVisible: boolean = get(field, 'isVisible', false);
  const isEmpty = !value || value.toString().trim() === "";

  const [appRegExp, Opts] = fixRegExp(
    fetchRegex?.replace(/\\\\/g, "\\")?.replace(/\/\//g, "/")
  );
  const exeRegex = appRegExp ? new RegExp(appRegExp, Opts) : "";

  if (!isMandatory && isEmpty) return { hasError: false, errMsg: "" };
  else if (isMandatory && isEmpty)
    return {
      hasError: true,
      errMsg: getIsRequireCheck(fieldName),
    };
  else if (value && exeRegex && !exeRegex.test(value))
    return { hasError: true, errMsg: getInvalidCheck(fieldName) };
  return { hasError: false, errMsg: "" };
};

const getFormFieldsAuthenticate = (field: any) => {
  const fieldType = get(field, "type", "").replace(/ /g, "");
  const isMandatory = get(field, "isMandatory", "");
  switch (fieldType) {
    case "TEXT":
      return getTextValidate(field);
    case "NUMBER":
      return getNumberValidate(field);
    case "TEXTAREA":
      return getTextValidate(field);
    case "DROPDOWN":
      return getRadioValidate(field);
    case "RADIO":
      return getRadioValidate(field);
    case "CHECKBOX":
      return getCheckboxValidate(field);
    case "GROUP":
      return getGroupValidate(field);
    case "LINK":
      return getLinkValidate(field);

    default:
      if (isMandatory) {
        return getMandatoryValidate(field);
      }
      return { hasError: false, errMsg: "" };
  }
};

export {
  getTextValidate,
  getNumberValidate,
  getFormFieldsAuthenticate,
  getInvalidCheck,
};
