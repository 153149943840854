// libraries
import React from "react";
// Components
import { Box, Typography, Grid } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";

import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
// Constants
import {
  linkPrivacyPolicy,
  linkTermsCondition,
} from "../../Constants/Ticketing";
import { useStore } from "Context";
import { useStyles } from "./styles";

export type TicketTaxationProps = {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  isChecked?: boolean;
  languageId?: number;
  complianceLink?: any;
};

const TicketTaxation = ({ onChange, isChecked, languageId, complianceLink }: TicketTaxationProps) => {
  const [t] = useTranslation();
  const {store} = useStore();
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Box mt={1} mb={1} sx={{ textAlign: "left !important" }} className={store.startFromSummary? classes.ticketGenerateLanding :""}>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              sx={{ paddingTop: 0, paddingBottom: 0 }}
              checked={isChecked}
              onChange={onChange}
            />
          }
          label={
            <Typography variant="caption" component="p">
              {t("taxation-concent")}
            </Typography>
          }
        />
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            marginTop: "5px",
            fontWeight: 400,
            color: theme.palette.text.secondary,
          })}
        >
          {t("taxation-tnc-pp")} &nbsp;
          <Link href={complianceLink?.termsAndConditions?.link || linkTermsCondition} target="blank" underline="none">
            {t("taxation-tnc")}
          </Link>
          <Typography
            component="span"
            variant="caption"
            sx={(theme: any) => ({
              paddingLeft: 0.625,
              paddingRight: 0.625,
              fontWeight: 400,
              color: theme.palette.text.secondary,
            })}
          >
            {t("and")}
          </Typography>
          <Link href={complianceLink?.privacyPolicy?.link || linkPrivacyPolicy} target="blank" underline="none">
            {t("taxation-pp")}
          </Link>
          {languageId === 43 ? " zu" : ""}.
        </Typography>
      </Box>
    </Grid>
  );
};

export default TicketTaxation;
