import { get } from "lodash";


export interface IDiscount {
    id: number;
    desc: string;
    couponCode: string;
}
export interface TicketData {
    id: number;
    title: string;
    symbol?: string;
    description: string;
    closeAt: string;
    ticketFee: number;
    processingFee: number;
    seatLeft: number;
    discount: IDiscount[];
    selectedQuantity?: number;
    promoCode?: string;
    promoCodeAmount?: string | number;
    maxLimit?: number;
    canShowCouponBox?: boolean;
    currency?: {
        id: number;
        symbol: string;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function updateArrayBySubArray<T, K>(main: T[], sub: K[], search: string, searchSub: string, update: any): T[] {
    const isMainExists = main && Array.isArray(main) && main.length;
    const isSubExists = sub && Array.isArray(sub) && sub.length;
    if (isMainExists && isSubExists) {
        let returnData: T[] = [];
        main.forEach((mainItem: T) => {
            const searchMainItem = get(mainItem, search);
            const getSubItemIndex = sub.findIndex((subItem: K) => {
                const searchSubItem = get(subItem, search);
                return searchMainItem === searchSubItem;
            });
            if (getSubItemIndex > -1) {
                const getSubItem = sub[getSubItemIndex];
                const getUpdateSub = get(getSubItem, searchSub);
                returnData = [...returnData, { ...mainItem, [update]: getUpdateSub }];
            } else returnData = [...returnData, { ...mainItem }];
        });
        return [...returnData];
    }
    return main;
}