import React from "react";
import { IIconProps } from "./types";

const GoogleCalIcon = ({
  width = "16.1rem",
  height = "16rem",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 101 100"
      fill="none"
    >
      <path
        d="M0 77.6769V7.1402C0.0858019 7.12288 0.172935 7.11298 0.260433 7.1106C0.260433 7.40662 0.298907 7.70264 0.298907 7.99867C0.298907 28.1283 0.298907 48.2649 0.298907 68.4084C0.298907 71.5018 0.272272 74.5953 0.257475 77.6887C0.172051 77.6786 0.0859974 77.6746 0 77.6769V77.6769Z"
        fill="#A4BCE2"
      />
      <path
        d="M0 77.677C0.0847342 77.6711 0.169778 77.6711 0.254513 77.677H0.372893C0.346258 78.2129 0.298907 78.7487 0.298907 79.2815C0.279177 83.7712 0.264377 88.2619 0.254513 92.7536C0.168785 92.7541 0.0833123 92.7442 0 92.724V77.677Z"
        fill="#98AAD7"
      />
      <path
        d="M0.260432 7.10468C0.929267 3.18235 3.40632 0.701669 7.36311 9.13076e-05C8.98192 0.0148925 10.6007 0.0385714 12.2196 0.0385714C33.4802 0.0385714 54.7409 0.0385714 76.0016 0.0385714C76.6408 0.0385714 77.2771 0.00601216 77.9163 -0.00878906V0.112578C77.9163 6.85009 77.943 13.5876 77.8927 20.3251C77.8927 21.5299 78.1886 21.9325 79.4493 21.9207C86.3301 21.8704 93.2137 21.9207 100.097 21.9207C100.123 21.9616 100.136 22.0087 100.136 22.0569C100.136 22.105 100.123 22.1522 100.097 22.193L77.9489 22.2226C77.653 22.2019 77.357 22.1605 77.0611 22.1605C59.2689 22.1605 41.4767 22.1605 23.6845 22.1605C23.3057 22.1605 22.9239 22.2049 22.4622 22.2345C22.4356 22.7999 22.3941 23.2794 22.3941 23.756C22.3941 41.3063 22.3941 58.8576 22.3941 76.4099C22.3941 76.851 22.4326 77.298 22.4533 77.7331C22.4533 78.6212 22.4178 79.5093 22.4149 80.3974C22.3932 86.8803 22.3744 93.3632 22.3586 99.8462C22.2518 99.3306 22.1797 98.8084 22.1426 98.2831C22.1426 91.9985 22.0982 85.7169 22.1603 79.4323C22.1603 78.0647 21.7401 77.6562 20.3847 77.6562C13.7111 77.7154 7.03757 77.6858 0.361052 77.6858H0.242676C0.242676 74.5923 0.28115 71.4989 0.28115 68.4054C0.28115 48.2757 0.28115 28.1392 0.28115 7.99571C0.298907 7.69376 0.260432 7.39774 0.260432 7.10468Z"
        fill="#538ECB"
      />
      <path
        d="M77.949 22.2198L100.098 22.1902C100.13 22.6253 100.195 23.0783 100.195 23.5016C100.195 41.1012 100.195 58.6999 100.195 76.2975C100.164 76.7757 100.102 77.2513 100.009 77.7214H77.9668L77.9194 77.6681C77.9194 77.0287 77.9372 76.3893 77.9372 75.7499C77.9411 57.9095 77.9451 40.0661 77.949 22.2198Z"
        fill="#65B854"
      />
      <path
        d="M22.3677 99.8314C22.3854 93.3484 22.4042 86.8655 22.4239 80.3826C22.4239 79.4945 22.4476 78.6064 22.4624 77.7184L55.2028 77.7006C62.777 77.7006 70.3482 77.6878 77.9165 77.6621L77.9639 77.7154C77.9639 85.0726 77.956 92.4278 77.9402 99.781C77.473 99.8807 76.9992 99.9469 76.5226 99.9794C58.9632 99.9794 41.4117 99.9794 23.8681 99.9794C23.3828 99.9794 22.8974 99.8965 22.4121 99.8551L22.3677 99.8314Z"
        fill="#F7C817"
      />
      <path
        d="M22.3676 99.8312L22.412 99.8697C17.2212 99.8431 12.0184 100.027 6.84533 99.7217C3.46564 99.5234 0.819893 96.3618 0.254639 92.7592C0.270422 88.2695 0.285222 83.7788 0.299033 79.2871C0.299033 78.7543 0.346384 78.2185 0.373019 77.6827C7.04953 77.6827 13.7231 77.7123 20.3966 77.6531C21.7491 77.6531 22.1723 78.0497 22.1723 79.4292C22.1102 85.7138 22.1398 91.9955 22.1546 98.2801C22.1912 98.8013 22.2624 99.3195 22.3676 99.8312V99.8312Z"
        fill="#3D70B7"
      />
      <path
        d="M100.085 21.9179C93.2017 21.9179 86.3181 21.8794 79.4374 21.9179C78.1885 21.9179 77.8688 21.5271 77.8807 20.3223C77.931 13.5848 77.9044 6.84726 77.9044 0.109743L92.9739 0.00317383C96.981 0.595223 99.97 3.64723 100.077 7.61988C100.21 12.3918 100.094 17.1548 100.085 21.9179Z"
        fill="#3D70B7"
      />
      <path
        d="M77.9399 99.7959C77.9399 92.4387 77.9478 85.0835 77.9636 77.7302H100.006C99.8093 78.0071 99.5978 78.2729 99.3723 78.5265C92.4965 85.4042 85.6167 92.2828 78.7331 99.1624C78.4812 99.3888 78.2164 99.6003 77.9399 99.7959V99.7959Z"
        fill="#DF4437"
      />
      <path
        d="M37.8454 51.5971V46.9791C38.5883 46.9791 39.263 47.0028 39.9348 46.9791C40.5709 46.9738 41.2051 46.9084 41.8289 46.7838C44.0603 46.242 45.321 44.7353 45.2441 42.7756C45.1642 40.9728 43.5365 39.3062 41.4649 39.0516C39.3163 38.7882 37.4992 39.3802 36.2207 41.2925C36.0743 41.4794 35.8868 41.63 35.6728 41.7327C35.4588 41.8354 35.224 41.8874 34.9866 41.8846C33.8028 41.6833 32.6191 41.2925 31.4353 40.9965C32.0272 37.7402 34.9866 34.9961 38.5676 34.3478C42.6575 33.5988 46.8392 35.3632 48.7954 38.6579C50.8345 42.077 50.3698 45.4665 47.3453 49.1934C51.086 53.4858 51.7845 57.1624 49.5323 60.8864C47.3482 64.5186 42.6575 66.4339 38.4018 65.4244C34.232 64.4387 31.1837 61.3422 30.8789 57.7041L35.1612 57.0025C37.1381 60.3831 39.5619 61.5554 42.5095 60.6081C44.8238 59.8651 46.1467 58.1866 46.0609 56.1174C45.9573 54.2643 44.3237 52.4319 42.181 52.0234C40.8049 51.757 39.3725 51.7363 37.8454 51.5971Z"
        fill="#548FCC"
      />
      <path
        d="M66.8837 65.2676H62.0746V42.1777L57.6354 45.3452L54.8535 41.3755C57.4371 39.3033 59.852 37.1216 62.5481 35.3455C63.4981 34.7149 65.0962 35.0139 66.3954 35.0346C66.567 35.0346 66.8659 35.8961 66.8718 36.3638C66.9133 39.8539 66.8955 43.344 66.8955 46.8342V65.2676H66.8837Z"
        fill="#548FCC"
      />
    </svg>
  );
};

export default GoogleCalIcon;
