/* eslint-disable quotes */
import React from "react";
import { IIconProps } from "./types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    e4uvjrh97zL2_tr: {
        animation: `$e4uvjrh97zL2_tr__tr 2900ms linear infinite normal forwards`,
       },
       
       "@keyframes e4uvjrh97zL2_tr__tr": {
        "0%" :{
            transform: "translate(36px, 35.75px) rotate(0deg)",
        },
       
       "6.896552%": {
            transform:"translate(36px, 35.75px) rotate(0deg)",
            animationTimingFunction: "cubic-bezier(0.25, 1, 0.25, 1)",
        },
       
        "37.931034%": {
            transform: "translate(36px, 35.75px) rotate(180deg)",
            animationTimingFunction: "cubic-bezier(0.25, 1, 0.25, 1)",
        },
       
        "62.068966%": {
            transform: "translate(36px, 35.75px) rotate(180deg)",
            animationTimingFunction: "cubic-bezier(0.25, 1, 0.25, 1)",
        },
       
        "93.103448%": {
            transform: "translate(36px, 35.75px) rotate(360.000001deg)"
        },
       
        "100%": {
            transform: "translate(36px, 35.75px) rotate(360.000001deg)"
        },
       },
       
        e4uvjrh97zL6_tr: {
        animation: `$e4uvjrh97zL6_tr__tr 2900ms linear infinite normal forwards`,
       },
       
       "@keyframes e4uvjrh97zL6_tr__tr": {
        "0%": {
            transform: "translate(35.99998px, 36.000025px) rotate(-120.715803deg)"
        },
       
        "51.724138%": {
            transform: "translate(35.99998px, 36.000025px) rotate(239.284197deg)"
        },
       
        "100%": {
            transform: "translate(35.99998px, 36.000025px) rotate(599.284197deg)"
        },
       },
}));


const LoaderSvg = ({height,width,className}:IIconProps):JSX.Element => {
    const classes = useStyles();
    return(

<svg xmlns="http://www.w3.org/2000/svg" height={height} className={className} width={width} id="e4uvjrh97zL1" viewBox="0 0 72 72" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
   <g className={classes.e4uvjrh97zL2_tr} transform="translate(36,35.75) rotate(0)">
      <g transform="translate(-36,-35.75)">
         <path d="M44.75,47h-1.25v-2.0838c-.0039-.7153-.1306-1.4247-.3745-2.0971-.0136-.0367-.028-.0708-.0448-.1057-.2025-.552-.4822-1.0725-.8307-1.5459l-1.7499-2.334c-.4855-.6502-.7486-1.4395-.7501-2.251v-1.2623c.0029-.9938.3974-1.9465,1.098-2.6513l.8215-.8216c.9546-.9596,1.5677-2.2061,1.7454-3.5479.0007-.0174.01-.0319.01-.0495l-.0035-.0171c.0435-.2662.0697-.5349.0786-.8044v-2.9284h1.25c.3315,0,.6495-.1317.8839-.3661s.3661-.5524.3661-.8839-.1317-.6495-.3661-.8839-.5524-.3661-.8839-.3661h-17.5c-.3315,0-.6495.1317-.8839.3661s-.3661.5524-.3661.8839.1317.6495.3661.8839.5524.3661.8839.3661h1.25v2.9284c.0089.2695.0351.5382.0786.8044l-.0035.0172c0,.0175.0093.032.01.0494.1777,1.3419.7908,2.5883,1.7453,3.548l.8216.8215c.7006.7049,1.0951,1.6575,1.098,2.6513v1.2623c-.0016.8113-.2644,1.6006-.7495,2.251l-1.7511,2.3339c-.3485.4737-.6281.9944-.8305,1.5465-.0166.0346-.0309.0683-.0444.1046-.2439.6727-.3706,1.3822-.3745,2.0977v2.0838h-1.25c-.3315,0-.6495.1317-.8839.3661s-.3661.5524-.3661.8839.1317.6494.3661.8839.5524.3661.8839.3661h17.5c.3315,0,.6494-.1317.8839-.3661s.3661-.5524.3661-.8839-.1317-.6495-.3661-.8839-.5524-.3661-.8839-.3661v0ZM31,27v-2.5h10v2.5h-10Zm1.098,3.0798c-.1761-.1769-.3339-.3712-.4711-.5798h8.7462c-.1372.2086-.295.4029-.4711.5798l-.8216.8216c-.975.9693-1.6002,2.2352-1.7773,3.5986h-2.6063c-.177-1.3635-.8022-2.6294-1.7773-3.5988l-.8215-.8214Zm1.4026,10.2527c.7233-.9692,1.1522-2.1261,1.2354-3.3325h2.528c.0832,1.2065.5123,2.3635,1.236,3.3325L39.7501,42h-7.5006l1.2511-1.6675ZM41,47h-10v-2.0838c.0035-.1393.0148-.2782.034-.4162h9.932c.0192.138.0305.2769.034.4162L41,47Z" fill="currentColor"/>
      </g>
   </g>
   <g className={classes.e4uvjrh97zL6_tr} transform="translate(35.99998,36.000025) rotate(-120.715803)">
      <path d="M72,36c0-7.8272-2.551-15.4412-7.2666-21.6885s-11.3388-10.78753-18.8662-12.93287-15.5491-1.77892-22.8497,1.04377C15.717,5.24508,9.5353,10.3702,5.40893,17.0214s-5.972608,14.4661-5.259004,22.2607s3.948154,15.1443,9.213654,20.9357s12.27502,9.7088,19.96682,11.159c7.6917,1.4501,15.6465.3539,22.6593-3.1226l-3.2935-6.6435c-5.5683,2.7604-11.8846,3.6309-17.9921,2.4794s-11.6732-4.262-15.8541-8.8605-6.74931-10.4344-7.31593-16.6235.89933-12.3944,4.17583-17.6756s8.1848-9.3507,13.9817-11.59203c5.7968-2.24129,12.1662-2.53224,18.1432-.82878s11.236,5.30851,14.9803,10.26901s5.7699,11.0063,5.7699,17.2213L72,36Z" transform="translate(-35.99998,-36.000025)" fill="currentColor"/>
   </g>
</svg>
    );
};

export default LoaderSvg;