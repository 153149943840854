import axios from "axios";
import envConfig from "config";
import { AppConstants } from "../Constants/AppConstants";
import { IFileUpload, IFileUploadResponse } from "../Types/FileUpload";

export interface IResponse<T> {
  status: boolean;
  success?: {
    code: string;
    message: string;
    data: T;
  };
  error: Error;
}

export const oldClient = () => {
  return axios.create({
    baseURL: envConfig.REACT_APP_TFE_API_URL,
    headers:{
      "Content-Type":"application/json"
    }
  });
};

export const clientWithCustomEndpoint = (endPoint="") => {
  return axios.create({
    baseURL: endPoint!==""?endPoint:envConfig.REACT_APP_TFE_API_URL,
    headers:{
      "Content-Type":"application/json"
    }
  });
};

export const client = () => {
  return axios.create({
    baseURL: envConfig.NEW_REACT_APP_TFE_API_URL,
    headers:{
      "Content-Type":"application/json"
    }
  });
};

const postApi = async <T, U = Record<string, unknown>>(
  url: string,
  headers?: any,
  data?: U
) => {

  try {
    const response = await client().request({
      url,
      headers: headers,
      method: "post",
      data: data,
    });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response?.data?.error?.data?.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

const oldPostApi = async <T, U = Record<string, unknown>>(
  url: string,
  headers?: any,
  data?: U
) => {

  try {
    const response = await oldClient().request({
      url,
      headers: headers,
      method: "post",
      data: data,
    });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response.data.error.data.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

const getApi = async <T>(
  url: string,
  headers?: any,
) => {

  try {
    const response = await client().request({
      url,
      headers: headers,
      method: "get",
    });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response.data.error.data.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

const patchApi = async <T, U = Record<string, unknown>>(
  url: string,
  headers?: any,
  data?: U
) => {

  try {
    const response = await client().request({
      url,
      headers: headers,
      method: "patch",
      data: data,
    });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response.data.error.data.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

// const getApi = async <T>(url: string, body?: U) => {
//   try {
//     const response = (await body)
//       ? await client().get<IResponse<T>>(url, {
//           params: body,
//         })
//       : await client().get<IResponse<T>>(url);
//     return response.data as IResponse<T>;
//   } catch (error: any) {
//     const errorMessage = error.response?.data?.message || error.message;
//     return {
//       error: new Error(errorMessage),
//       status: error.response?.status,
//     } as IResponse<T>;
//   }
// };

const getWebinarApi = async <T, U>(url: string, headers?: any, body?: U) => {
  try {
    const response = (await body)
      ? await client().get<IResponse<T>>(url,
        {
          headers,
          params: body,
        })
      : await client().get<IResponse<T>>(url,{
        headers
      });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

export const postFielUpload = async (
  payload: IFileUpload,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  file: any
): Promise<IResponse<IFileUploadResponse>> => {
  const { appendData, token = "", progressEmmiter } = payload;
  const bodyFormData = new FormData();
  bodyFormData.append("Content-Disposition", "form-data");
  if (
    appendData &&
    typeof appendData === "object" &&
    Object.keys(appendData).length
  ) {
    for (const KEY in appendData) {
      bodyFormData.append(KEY, appendData[KEY]);
    }
  }

  if (file) bodyFormData.append("uploadFile", file);

  const { data } = await client().post(
    AppConstants.fileUploadUtils,
    bodyFormData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        boundary: "webkitFormBoundry",
        authorization: token,
      },
      onUploadProgress: (progressEvent:any): void => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        progressEmmiter(percentCompleted);
      },
    }
  );
  return data;
};

const postApiWithCustomEndpoint = async <T, U = Record<string, unknown>>(
  endPoint:string,
  url: string,
  headers?: any,
  data?: U
) => {
  try {
    const response = await clientWithCustomEndpoint(endPoint).request({
      url,
      headers: headers,
      method: "post",
      data: data,
    });
    return response.data as IResponse<T>;
  } catch (error: any) {
    const errorMessage = error.response.data.error.data.message || error.message;
    return {
      error: new Error(errorMessage),
      status: error.response?.status,
    } as IResponse<T>;
  }
};

export { postApi, oldPostApi, patchApi, getApi,postApiWithCustomEndpoint, getWebinarApi };
