import React from "react";
import { IIconProps } from "./types";

const BankIcon = ({height="20",width="20",className=""}:IIconProps): JSX.Element => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 20 21" fill="none">
<path d="M19 8C19.1313 8.00003 19.2614 7.97419 19.3827 7.92395C19.5041 7.8737 19.6143 7.80005 19.7072 7.70718C19.8 7.61432 19.8737 7.50406 19.9239 7.38272C19.9742 7.26138 20 7.13133 20 7V4C20.0001 3.79017 19.9341 3.58565 19.8114 3.41544C19.6887 3.24524 19.5155 3.11799 19.3164 3.05176L10.3164 0.0517577C10.111 -0.0166015 9.88899 -0.0166015 9.68359 0.0517577L0.68359 3.05176C0.484493 3.11799 0.311309 3.24524 0.188606 3.41544C0.0659032 3.58565 -8.61316e-05 3.79017 8.43742e-08 4V7C-3.40751e-05 7.13133 0.0258085 7.26138 0.0760511 7.38272C0.126294 7.50406 0.199952 7.61432 0.292817 7.70718C0.385683 7.80005 0.495935 7.8737 0.617276 7.92395C0.738617 7.97419 0.868669 8.00003 1 8H2V15.1843C1.41674 15.3897 0.911365 15.7707 0.553267 16.2748C0.195169 16.779 0.00190247 17.3816 8.43742e-08 18V20C-3.40751e-05 20.1313 0.0258085 20.2614 0.0760511 20.3827C0.126294 20.5041 0.199952 20.6143 0.292817 20.7072C0.385683 20.8 0.495935 20.8737 0.617276 20.9239C0.738617 20.9742 0.868669 21 1 21H19C19.1313 21 19.2614 20.9742 19.3827 20.9239C19.5041 20.8737 19.6143 20.8 19.7072 20.7072C19.8 20.6143 19.8737 20.5041 19.9239 20.3827C19.9742 20.2614 20 20.1313 20 20V18C19.9981 17.3816 19.8048 16.779 19.4467 16.2748C19.0886 15.7707 18.5833 15.3897 18 15.1843V8H19ZM18 19H2V18C2.00026 17.7349 2.10571 17.4807 2.29319 17.2932C2.48066 17.1057 2.73486 17.0003 3 17H17C17.2651 17.0003 17.5193 17.1057 17.7068 17.2932C17.8943 17.4807 17.9997 17.7349 18 18V19ZM4 15V8H6V15H4ZM8 15V8H12V15H8ZM14 15V8H16V15H14ZM2 6V4.7207L10 2.0537L18 4.7207V6H2Z" fill="currentColor"/>
</svg>
  );
};

export default BankIcon;