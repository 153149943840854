import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import {
  IApplyDiscountRequest,
  IApplyDiscountResponse,
} from "../Types/ApplyDiscount";
import { IHeadersRequest } from "../Types/Ticket";

const useApplyDiscount = () => {
  const [data, setData] = useState<IApplyDiscountResponse>(
    {} as IApplyDiscountResponse
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();
  const [status, setStatus] = useState(false);

  const applyDiscount = useCallback(
    (headers?: IHeadersRequest, input?: IApplyDiscountRequest) => {
      let didCancel = false;
      setLoading(true);
      async function fetchApi() {
        try {
          const response: any = await postApi<
            IApplyDiscountResponse,
            IApplyDiscountRequest
          >(AppConstants.applyDiscount, headers, input);

          if (response?.error && response?.status === 422) {
            setError(response.error.message);
          }
          if (!response.status) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.success?.data as IApplyDiscountResponse);
            setStatus(true);
          }
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, loading, error, applyDiscount, setError, status };
};

export default useApplyDiscount;
