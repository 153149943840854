// Language: Russian 2

export default {
    "already-registered": "Уже зарегистрировано",
    "add-to-calendar": "Добавить в календарь",
    "event-starts-in": "Мероприятие начнется через",
    "event-is-live": "МЕРОПРИЯТИЕ В ПРЯМОМ ЭФИРЕ",
    "event-has-ended": "МЕРОПРИЯТИЕ ЗАВЕРШЕНО",
    "powered-by": "На платформе",
    "please-enter-your-email-to-continue": "Чтобы продолжить, введите адрес своей электронной почты",
    add: "Добавить",
    offers: "Предложения",
    free: "Свободно",
    "available-till": "Доступно до {{DATE}}",
    "enter-email-address": "Введите адрес электронной почты",
    "sold-out": "Распродано",
    "the-ticket-has-reached-the-maximum-number": "Достигнуто максимальное количество билетов",
    "seat-left": "Осталось {{SEAT}} место",
    "seats-left": "Осталось {{SEAT}} места",
    proceed: "Продолжить",
    summary: "Резюме",
    "buyer-info": "Информация о покупателе",
    continue: "Продолжить",
    "attendees-info": "Информация об участниках",
    "registration": "Регистрация",
    "attendees-information": "Информация об участниках",
    submit: "Подать",
    "register-to-join-events": "Зарегистрируйтесь, чтобы присоединиться к мероприятиям",
    "by-clicking-on-the-button":
        "Нажав на кнопку, вы будете перенаправлены на платформу продажи билетов, где сможете зарегистрироваться на это мероприятие.",
    other: "Прочее",
    "please-enter-a-value-for": "Введите значение для <FIELD_NAME>",
    "please-select-a-value-for": "Выберите значение для <FIELD_NAME>",
    "enter-other-value-for": "Введите другое значение для <FIELD_NAME>",
    "enter-a-valid": "Введите действительное <FIELD_NAME>",
    "should-be-at-least": "<FIELD_NAME> должно состоять из мин. <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> не может состоять из более чем <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "Требуется <FIELD_NAME>.",
    "at-least-required": "Требуется мин. <FIELD_LENGTH> <FIELD_NAME> <VERB>",
    "maximum-allowed": "Допускается макс. <FIELD_LENGTH> <FIELD_NAME> <VERB>",
    "user-with-same-email-id-already-exists":
        "Пользователь с таким адресом электронной почты уже существует. Используйте другой адрес электронной почты.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Пользователь с таким адресом электронной почты уже зарегистрирован для участия в этом мероприятии. Используйте другой адрес электронной почты.",
    "mail-successfully-sent": "Письмо успешно отправлено!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "В данный момент мы не можем доставить ваше письмо! Попробуйте еще раз через некоторое время",
    "something-went-wrong": "Что-то пошло не так! Повторите попытку позже",
    "please-select-a-ticket-to-proceed": "Выберите билет, чтобы продолжить",
    "register-now": "Зарегистрироваться сейчас",
    "add-to-your-calendar": "Добавить в ваш календарь",
    "resend-link": "Отправить ссылку повторно",
    "go-to-event": "Перейти к мероприятию",
    "ticket-registration-done-by": "Регистрация билета выполнена",
    "you-have-already-purchased-the-ticket-for": "Вы уже приобрели билет на",
    purchased: "Приобретено",
    "event-date": "Дата мероприятия",
    "ticket-quantity": "Количество билетов",
    payment: "Оплата",
    "unable-to-submit-data": "Не удалось отправить данные",
    "unable-to-apply-promo-code": "Не удалось применить промокод",
    "invalid-promo-code": "Недействительный промокод",
    "apply-now": "Применять",
    "enter-discount-coupon": "Введите купон на скидку",
    "same-as-buyer": "То же, что и покупатель",
    "please-check-all-the-fields-are-correctly-filled": "Проверьте правильность заполнения всех полей",
    "ticket-purchased": "Билет приобретен",
    "ticket-purchase-failed": "Не удалось купить билет",
    "you-have-successfully-purchased-the-ticket":
        "Вы успешно приобрели билет. Этот билет позволит вам посетить мероприятие. Спасибо за покупку!",
    "there-was-an-error-while-booking-your-tickets": "При бронировании билетов произошла ошибка. Попробуйте снова.",
    "try-again": "Попробуйте снова",
    "you-dont-have-access": "У вас нет доступа к покупке некоторых билетов",
    "please-enter-a-valid-email": "Введите действительный адрес электронной почты",
    "payment-summary": "Резюме по оплате",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} успешно применен. Вы сэкономили дополнительно {{AMOUNT}}.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Хотите создать налоговую накладную?",
    "taxation-tnc-pp": "Продолжая, вы соглашаетесь с нашими",
    "taxation-tnc": "Условия и положения",
    and: "и",
    "taxation-pp": "Политика конфиденциальности",
    "company-name-label": "Название компании",
    "company-name-place-holder": "Введите название компании",
    "taxId-label": "ИНН компании",
    "taxId-place-holder": "Введите налоговый идентификатор компании",
    "company-address-label": "Адрес компании",
    "company-address-place-holder": "Адрес",
    "country-place-holder": "Выберите страну",
    "state-place-holder": "Выберите штат",
    "city-place-holder": "Введите город",
    "zipcode-label": "почтовый индекс",
    "zipcode-place-holder": "Введите почтовый индекс",
    "company-error-msg": "Пожалуйста, введите название компании",
    "taxId-error-msg": "пожалуйста, введите действительный налоговый идентификатор компании",
    "address-error-msg": "Пожалуйста, введите адрес",
    "country-error-msg": "Пожалуйста, выберите страну",
    "state-error-msg": "Пожалуйста, выберите штат",
    "city-error-msg": "Пожалуйста, введите город",
    "zipcode-error-msg": "Пожалуйста, введите почтовый индекс",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Имя",
    lastName: "Фамилия",
    email: "Эл. адрес",
    phone: "Телефон",
    dialerCode: "Код дозвона",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Вы уже приобрели билет на",
    calendar: "Календарь",
    "upload-file": "Загрузить файл",
    "browse-a-file-to-upload": "Просмотр файла для загрузки",
    "max-size": "Максимальный размер",
    "file-supported-types": "Поддерживаемые типы файлов",
    "uploaded-successfully": "Загружен успешно",
    "failed!-please-retry": "Не удалось! Пожалуйста, повторите попытку",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Этот тип файла не поддерживается. Поддерживаются следующие вложения",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "Размер файла слишком велик. Пожалуйста, загрузите документ меньшего размера, чем",
    person: "Персона",
    select: "Выберите",
    search: "Поиск",
    "uploaded-file": "Загруженный файл",
    uploading: "Загрузка",
    delete: "Удалить",
    "no-item": "Нет элемента",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Календарь Google",
    "outlook-calendar": "Календарь Outlook",
    "yahoo-calendar": "Календарь Yahoo",
    "read-more": "Подробнее",
    "read-less": "Читать меньше",
    "about-title": "О сайте",
    "verb-are": "являются",
    "verb-is": "является",
    character: "символов",
    // [HUB-9250] Date format translation
    "date-format": "ДД/ММ/ГГГГ",
    "timer-hr": "Час",
    "timer-hrs": "Часов",
    "timer-min": "Минута",
    "timer-mins": "Минут",
    "timer-sec": "Секунда",
    "timer-secs": "Секунд",
    "timer-day": "День",
    "timer-days": "Дней",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Файл не выбран",
    today: "сегодня",
    yesterday: "вчера",
    tomorrow: "завтра",
    last: "прошлой",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Регистрация Успешно Завершена!",
    registrationSuccessDetail: "Вы успешно зарегистрировались для участия в мероприятии. Спасибо!",
    "search-here":"Search Here..",
    "did-you-mean": "Вы имеете в виду ",
    DISPOSABLE_EMAIL: "Указанный адрес электронной почты принадлежит службе одноразовой электронной почты.",
    RISTRICTED_DOMAIN: "Указанный адрес электронной почты находится в ограниченном домене. Пожалуйста, введите действительный адрес электронной почты",
    INVALID_EMAIL: "Введите действительный адрес электронной почты",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Пожалуйста, обновите уникальную информацию о посетителях для всех приобретенных билетов. Это позволит всем участникам получить доступ к мероприятию.",
    DUPLICATE_EMAIL: "Вы уже купили этот билет. Вы можете выбрать перерегистрацию. В этом случае будут сохранены самые последние введенные данные.",
    DEFAULT_DUPLICATE_EMAIL: "Этот адрес электронной почты уже используется для другого билета. Пожалуйста, дважды проверьте адрес электронной почты, который вы ввели, или не стесняйтесь использовать один и тот же адрес электронной почты для нескольких билетов.",
    "REGISTER_NOW": "Зарегистрироваться сейчас",
    "ALREADY_REGISTERED": "You have already registered for",
    "RESEND_INVITE": "Resend Invite",
    "REG_FAILED": "Registration Failed",
    "REG_FAILED_DESC": "Your registration has failed. Please try again.",
    "REG_DONE_BY": "Registration done by",
    "INVITE_AGAIN": "Want the invite again?",
    "REG_SUCCESS_DONE": "Registration successfully done",
    "WATCH_REC_LINK": "An email with a link to watch the recording has been send to",
    "WEBINAR_INV_LINK": "An invitation email with the webinar link has been sent to",
    "MAIL_SENT_SUCCESS": "Mail successfully sent",
    "WATCH_RECORDING_REGISTER_DESC": "You can watch the recording of the webinar by registering below",

};
