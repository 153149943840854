import React from "react";
import { IIconProps } from "./types";

const TicketPurchaseFailedIcon = ({
  width = "78",
  height = "78",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 78 78"
      fill="none"
    >
      <g>
        <path
          d="M36.0533 0H41.6C42.64 0.204533 43.6523 0.454131 44.6923 0.610131C60.4517 2.99866 72.8347 14.2099 76.6515 29.6019C77.1784 31.7339 77.5563 33.9005 78 36.0533V42.2933C77.7572 43.081 77.5547 43.8805 77.3933 44.6888C75.0048 60.4448 63.7901 72.8381 48.3981 76.6445C46.1587 77.1992 43.8672 77.5528 41.6 78H35.7067C35.4342 77.8521 35.1458 77.7357 34.8469 77.6533C17.9227 75.1053 6.74267 65.7731 1.60507 49.3827C0.883999 47.0773 0.526933 44.6541 0 42.2864V35.6997C0.243942 34.9121 0.447582 34.1126 0.610131 33.3043C2.99866 17.5552 14.2099 5.16533 29.5984 1.34853C31.7339 0.8216 33.9005 0.4472 36.0533 0ZM39.0624 71.8675C57.3317 71.7288 71.916 57.0925 71.8709 38.9827C71.8673 30.2708 68.4053 21.9166 62.2457 15.7557C56.0861 9.59482 47.7327 6.13111 39.0208 6.1256C20.8139 6.1256 5.84133 21.1501 6.136 39.104C6.4376 57.4947 21.0184 72.0027 39.0624 71.8675Z"
          fill="#EF574C"
        />
        <path
          d="M32.9961 25.2928C33.0897 23.608 33.0273 21.9232 33.3046 20.2939C33.835 17.1739 36.2548 15.1493 39.1113 15.1805C40.5076 15.217 41.8429 15.7608 42.8675 16.7101C43.8922 17.6594 44.536 18.9494 44.6788 20.3389C44.9821 22.7376 44.9821 25.1648 44.6788 27.5635C43.9265 33.1795 42.9177 38.7608 41.9505 44.3491C41.8084 45.1707 41.2745 45.9195 41.0076 46.7307C40.6609 47.7707 39.926 47.9024 38.9796 47.9128C38.0332 47.9232 37.267 47.7083 36.9828 46.6891C36.0953 43.4893 34.979 40.3693 34.3932 37.1072C33.6894 33.2141 33.4398 29.2379 32.9961 25.2928Z"
          fill="#EF574C"
        />
        <path
          d="M41.9606 58.4965C41.9606 59.0685 42.0057 59.6474 41.9606 60.2298C41.8081 61.8626 40.6329 62.8541 38.9342 62.8264C37.3291 62.8021 36.1851 61.821 36.0638 60.2541C35.9841 59.2141 36.0257 58.1741 36.043 57.1584C36.0811 55.1234 37.1766 54.0141 39.0798 54.0384C40.8859 54.0661 41.9225 55.172 41.9745 57.1272C41.9745 57.5882 41.9745 58.0458 41.9745 58.5138L41.9606 58.4965Z"
          fill="#EF574C"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="78" height="78" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TicketPurchaseFailedIcon;
