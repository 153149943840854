export default {
        "already-registered": "Již registrován/a?",
        "add-to-calendar": "Přidat do Kalendáře",
        "event-starts-in": "Událost začíná za",
        "event-is-live": "UDÁLOST JDE ŽIVĚ",
        "event-has-ended": "UDÁLOST SKONČILA",
        "powered-by": "Poháněno",
        "please-enter-your-email-to-continue": "Prosím zadejte svůj e-mail pro pokračování",
        "add": "Přidat",
        "offers": "Nabídky",
        "free": "Zdarma",
        "available-till": "Dostupné do {{DATE}}",
        "enter-email-address": "Zadejte E-mailovou Adresu",
        "sold-out": "Vyprodáno",
        "the-ticket-has-reached-the-maximum-number": "Lístek dosáhl maximálního počtu",
        "seat-left": "{{SEAT}} místo zbývá",
        "seats-left": "{{SEAT}} míst zbývá",
        "proceed": "Pokračovat",
        "summary": "Souhrn",
        "buyer-info": "Informace o kupujícím",
        "attendees-info": "Informace o účastnících",
        "registration": "Registrace",
        "continue": "Pokračovat",
        "attendees-information": "Informace o účastníkovi",
        "submit": "Odeslat",
        "register-to-join-the-events": "Registrovat se na události",
        "by-clicking-on-the-button": "Kliknutím na tlačítko budete přesměrováni na platformu pro nákup lístků, kde se můžete zaregistrovat na tuto událost.",
        "other": "Jiný",
        "please-enter-a-value-for": "Prosíme zadejte hodnotu pro <FIELD_NAME>",
        "please-select-a-value-for": "Prosíme vyberte hodnotu pro <FIELD_NAME>",
        "enter-other-value-for": "Zadejte jinou hodnotu pro <FIELD_NAME>",
        "enter-a-valid": "Zadejte platnou <FIELD_NAME>",
        "should-be-at-least": "<FIELD_NAME> by mělo být nejméně <FIELD_LENGTH> <FIELD_TYPE>",
        "cannot-be-more-than": "<FIELD_NAME> nesmí být víc než <FIELD_LENGTH> <FIELD_TYPE>",
        "is-required": "<FIELD_NAME> je povinné",
        "at-least-required": "Je vyžadováno alespoň <FIELD_LENGTH> <FIELD_NAME> <VERB>",
        "maximum-allowed": "Maximálně <FIELD_LENGTH> <FIELD_NAME> <VERB> povoleno",
        "user-with-same-email-id-already-exists": "Uživatel se stejným e-mailovým ID již existuje. Použijte prosím jiné.",
        "user-with-same-email-id-has-already-registered-for-this-event": "Uživatel se stejným e-mailovým ID je již zaregistrován na tuto událost. Použijte prosím jiné.",
        "mail-successfully-sent": "E-mail Úspěšně Odeslán!",
        "we-are-unable-to-deliver-your-mail-at-this-moment": "Nemůžeme doručit váš e-mail v tuto chvíli! Zkuste to později",
        "something-went-wrong": "Něco se Nepovedlo. Pracujeme na tom. Omlouváme se za způsobené nepříjemnosti.",
        "register-now": "Registrovat Nyní",
        "add-to-your-calendar": "Přidat do Kalendáře",
        "please-select-a-ticket-to-proceed": "Vyberte lístek k pokračování",
        "resend-link": "Znovu Odeslat Odkaz",
        "go-to-event": "Jít na událost",
        "ticket-registration-done-by": "Registrace lístku provedena",
        "you-have-already-purchased-the-ticket-for": "Již jste zakoupili lístek na",
        "purchased": "Zakoupeno",
        "event-date": "Datum Události",
        "ticket-quantity": "Množství lístků",
        "payment": "Platba",
        "unable-to-submit-data": "Nelze odeslat data",
        "unable-to-apply-promo-code": "Nelze použít promo kód",
        "invalid-promo-code": "Neplatný promo kód",
        "apply-now": "Použít",
        "enter-discount-coupon": "Zadat slevový kód",
        "same-as-buyer": "Stejné jako kupující",
        "please-check-all-the-fields-are-correctly-filled": "Prosím, zkontrolujte, zda jsou všechna pole správně vyplněna",
        "ticket-purchased": "Vstupenka zakoupena!",
        "ticket-purchase-failed": "Nákup vstupenky se nezdařil!",
        "you-have-successfully-purchased-the-ticket": "Lístek jste úspěšně zakoupili. Tato vstupenka vám umožní účast na události. Děkujeme za nákup!",
        "there-was-an-error-while-booking-your-tickets": "Při rezervaci vstupenek došlo k chybě. Prosím, zkuste to znovu.",
        "try-again": "Zkuste to prosím znovu.",
        "you-dont-have-access": "Nemáte přístup k nákupu některých lístků",
        "please-enter-a-valid-email": "Prosíme zadejte platný e-mail",
        "please-enter-email-to-continue": "Prosím zadejte svůj e-mail pro pokračování",
        "payment-summary": "Souhrn platby",
        "apply-discount": "Kód {{CODE}} byl úspěšně použit. Ušetřili jste další {{AMOUNT}}.",
        "and": "a",
        "taxation-concent": "Chcete vygenerovat daňový doklad?",
        "taxation-tnc-pp": "Pokračováním souhlasíte s našimi",
        "taxation-tnc": "Obchodní Podmínky",
        "taxation-pp": "Zásady Ochrany Osobních Údajů",
        "company-name-label": "Jméno Společnosti",
        "company-name-place-holder": "Zadejte Jméno Společnosti",
        "taxId-label": "ID společnosti",
        "taxId-place-holder": "Zadejte ID společnosti",
        "company-address-label": "Adresa Společnosti",
        "company-address-place-holder": "Adresa",
        "country-place-holder": "Vyberte Zemi",
        "state-place-holder": "Vyberte Stát",
        "city-place-holder": "Zadejte Město",
        "zipcode-label": "PSČ",
        "zipcode-place-holder": "Zadejte PSČ",
        "company-error-msg": "Prosíme zadejte Jméno Společnosti",
        "taxId-error-msg": "Prosíme zadejte ID Společnosti",
        "address-error-msg": "Prosím zadejte Adresu",
        "country-error-msg": "Prosíme Vyberte Krajinu",
        "state-error-msg": "Prosíme Vyberte Stát",
        "city-error-msg": "Prosíme zadejte Město",
        "zipcode-error-msg": "Prosíme zadejte PSČ",
        "firstName": "Jméno",
        "lastName": "Příjmení",
        "email": "E-mail",
        "phone": "Telefon",
        "dialerCode": "Předvolba",
        "you-have-already-purchased-ticket-for": "Již jste zakoupili lístek pro",
        "browse-a-file-to-upload": "Procházet soubor pro nahrání",
        "upload-file": "Nahrát Soubor",
        "max-size": "Maximální velikost",
        "file-supported-types": "Podporované typy souborů",
        "uploaded-successfully": "Úspěšně Nahráno",
        "failed!-please-retry": "Selhalo! Zkuste to Znovu",
        "this-file-type-is-not-supported-supported-attachments-are": "Tento typ souboru není podporován. Podporované přílohy jsou",
        "the-file-size-is-too-high-please-upload-document-lesser-than": "Velikost souboru je příliš velká. Prosíme nahrajte dokument menší než",
        "person": "Osoba",
        "select": "Vybrat",
        "search": "Hledat",
        "uploaded-file": "Nahraný Soubor",
        "uploading": "Nahrávání",
        "delete": "Smazat",
        "no-item": "Žádná položka",
        "read-more": "Přečtěte si více",
        "read-less": "Přečtěte si méně",
        "about-title": "O",
        "google-calendar": "Google Kalendář",
        "outlook-calendar": "Outlook Kalendář",
        "yahoo-calendar": "Yahoo Kalendář",
        "verb-are": "jsou",
        "verb-is": "je",
        "character": "znak",
        "date-format": "DD/MM/YYYY",
        "timer-hr": "h",
        "timer-hrs": "h",
        "timer-min": "min",
        "timer-mins": "min",
        "timer-sec": "sek",
        "timer-secs": "sek",
        "timer-day": "den",
        "timer-days": "dni",
        "select-file-title": "Žádný soubor není vybrán",
        "today": "Dnes",
        "yesterday": "Včera",
        "tomorrow": "Zítra",
        "last": "Poslední",
        "internet-down": "Zdá se, že je odpojen internet!",
        "internet-up": "Internet je nyní připojen",
        "network-issue": "Nastala chyba sítě, zkuste to znovu!",
        "registrationSuccess": "Registrace úspěšně provedena!",
        "registrationSuccessDetail": "Úspěšně jste se zaregistrovali na událost. Děkujeme!",
        "search-here": "Hledat zde...",
        "did-you-mean": "Myslíte",
        "DISPOSABLE_EMAIL": "Zadali jste e-mailovou adresu patřící do služby pro jedno použití",
        "RISTRICTED_DOMAIN": "Zadali jste e-mailovou adresu v omezeném doménovém prostoru. Zadejte platnou e-mailovou adresu",
        "INVALID_EMAIL": "Prosíme zadejte platný e-mail",
        "ORDERED": "Objednáno",
        "CHOOSE_PAYMENT_METHOD": "Zvolte metoda platby",
        "PAY_NOW": "Zaplatit nyní",
        "INSTANT_TICKET_CONFIRMATION": "Okamžité potvrzení lístku",
        "PAY_VIA_OTHER_METHODS": "Zaplatit jinými způsoby",
        "FUNDS_TRANSFER": "Převod finančních prostředků",
        "DIGITAL_WALLET": "Digitální peněženka",
        "CHEQUE": "Šek",
        "PAY_AT_VENUE": "Platba na místě",
        "TICKET_NOT_CONFIRMED_ORGANIZER": "Lístek(Lístky) nebude potvrzen, dokud pořadatel události nepotvrdí příjem platby a může být zrušen.",
        "TICKET_NOT_CONFIRMED_UNTIL_PAYMENT": "Lístek(Lístky) nebude potvrzen, dokud neprovedete platbu na místě konání události a může být zrušen.",
        "TICKET_CONFIRMED_BY_ORGANIZER": "Lístek(Lístky) nebude potvrzen, dokud pořadatel události nepotvrdí příjem platby.",
        "COMPLETE_PAYMENT_OF": "Dokončete platbu ve výši",
        "COMPLETE_PAYMENT_TICKET": "Dokončit platbu za vaše lístky.",
        "PAYMENT_CONFIRMATION_TITLE": "Zadejte jedinečný ID kód transakce, aby pořadatel události mohl ověřit vaši platbu.",
        "ENTER_TRANSACTION_ID": "Zadejte ID Transakce.",
        "SHARE_DETAILS_TITLE": "Pořadatel události může schválit vaši platbu pouze tehdy, pokud sdílíte podrobnosti. Váš lístek(lístky), včetně jakýchkoliv volných lístků, bude potvrzen po schválení.",
        "UPLOAD_SCREENSHOT": "Nahrát snímek obrazovky (volitelné)",
        "SHARE_PAYMENT_DETAILS": "Sdílet údaje o platbě",
        "TICKET_STATUS": "Stav Vstupenky",
        "ORGANIZER_YET_TO_CONFIRM": "Pořadatel události dosud nepotvrdil příjem platby.",
        "TICKET_CONFIRMATION_ORGANIZER": "Váš lístek(lístky), včetně jakýchkoliv volných lístků, a faktura budou potvrzeny a sdíleny po schválení pořadatelem.",
        "PAYMENT_DETAILS": "Podrobnosti Platby",
        "FAQ_TITLE": "V případě jakýchkoliv dotazů se obraťte na často kladené otázky (FAQ).",
        "MAKE_PAYMENT_TITLE": "Abyste potvrdili zakoupení lístku(lístků), proveďte platbu v místě konání události.",
        "CONFIRM_TICKET_PRIOR_EVENT": "Pokud chcete lístek(lístky) potvrdit před samotnou událostí, proveďte nyní platbu jiným způsobem.",
        "CONFIRM_TICKETS_PRIOR_EVENT": "Pokud chcete lístek(lístky) potvrdit před samotnou událostí",
        "PAY_NOW_2": "Zaplatit nyní",
        "WITH_DIFFERENT_METHOD": "jiným způsobem.",
        "PAYMENT_NOT_RCVD": "Organizátor události dosud nepřijal platbu.",
        "PAYMENT_COULD_NOT_BE_VERIFIED": "Zadané platební údaje nemohly být ověřeny organizátorem události.",
        "TICKETS_NOT_AVAILABLE": "Vstupenky již nejsou k dispozici pro tuto událost.",
        "PAYMENT_DETAILS_YET_TO_SHARE": "Zatím jste nesdíleli platební údaje pro vstupenky.",
        "WELCOME_BACK": "Vítejte zpět!",
        "VIEW_OR_PROCEED_PURCHASE": "Můžete si prohlédnout své předchozí lístky nebo pokračovat v nákupu lístků, které jste právě vybrali.",
        "VIEW_PREV_TICKETS": "Zobrazit předchozí lístky",
        "PROCEED_TO_PURCHASE": "Pokračovat v nákupu",
        "PREVIOUS_TICKETS": "Předchozí lístky",
        "PENDING_TICKETS": "Čekající lístky",
        "CONFIRMED_TICKETS": "Potvrzené lístky",
        "PAYMENT_PENDING": "Čeká na schválení platby",
        "AWAITING_ORG_APPROVAL": "Čeká se na schválení pořadatelem události",
        "CHECK_TICKET_STATUS": "Zkontrolovat Stav Lístku",
        "PAYMENT_METHOD_NOT_AVAILABLE": "Platební metoda není k dispozici, zkuste to znovu.",
        "HERE": "tu.",
        "RE_ENTER_EMAIL": "Laskavě aktualizujte jedinečné informace o účastníkovi pro všechny zakoupené lístky. Toto vám umožní přístup všech těchto účastníků na událost.",
        "DUPLICATE_EMAIL": "Tento lístek jste již zakoupili. Můžete se rozhodnout pro novou registraci. V takovém případě budou uloženy nejnovější údaje.",
        "DEFAULT_DUPLICATE_EMAIL": "Tento e-mail je již používán pro jinej lístek. Laskavě aktualizujte jedinečné informace o účastníkovi pro všechny zakoupené lístky.",
        "REGISTER_NOW": "Registrovat Nyní",
        "REG_FAILED": "Registrace se nezdařila",
        "REG_FAILED_DESC": "Vaše registrace se nezdařila. Prosím zkuste to znovu.",
        "REG_DONE_BY": "Registraci provedl",
        "INVITE_AGAIN": "Chcete pozvánku znovu?",
        "REG_SUCCESS_DONE": "Registrace byla úspěšně dokončena",
        "WATCH_REC_LINK": "Na adresu byl odeslán e-mail s odkazem na přehrání záznamu",
        "WEBINAR_INV_LINK": "E-mail s pozvánkou s odkazem na webinář byl odeslán na adresu",
        "MAIL_SENT_SUCCESS": "Mail úspěšně odeslán",
        "ALREADY_REGISTERED": "Již jste se zaregistrovali na",
        "RESEND_INVITE": "Znovu odeslat pozvánku",
        "WATCH_RECORDING_REGISTER_DESC": "Záznam webináře můžete sledovat po registraci níže.",

    };