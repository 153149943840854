import React, {useEffect,useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import makeStyles from "@mui/styles/makeStyles";
// import { isAndroidIPhoneIPad } from "../Utils/navigator";
import { useStore } from "../Context";
import isEmpty from "lodash/isEmpty";
import { PageState } from "../Constants/AppConstants";
import { IApplyDiscount } from "../Types/ApplyDiscount";
import { useTranslation } from "react-i18next";
import { ITicketDetailsResponse } from "../Types/Ticket";

const useStyles = makeStyles((theme: any) => ({
  stepperBackArrow:{
    height:"36px !important",
    width:"36px !important",
    "&>svg":{
      height:"20px",
      width:"20px",
      fontSize:"20px",
    },
  },  
  widgetArrow:{
    height:"34px !important",
    width:"34px !important",
    "&>svg":{
      height:"1em",
      width:"1em"
    },
  },
  stepper: {
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: theme.spacing(0.5),
      fontSize: "12px !important",
      color: theme.palette.text.primary,
      fontWeight: 400, 
      "& .MuiStepLabel-label": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "10rem !important",
        whiteSpace: "nowrap",
        margin: "4px auto 0",
        "@media (max-width: 600px)": {
          fontSize: "10px !important",
          maxWidth: "90% !important",
          minWidth: "90% !important",
        },
        "@media (max-width: 375px)": {
          minWidth:theme.spacing(7),
          fontSize: "10px !important",
          maxWidth:theme.spacing(7),
          width:theme.spacing(7),
        },
        "@media (max-width: 320px)": {
          minWidth:theme.spacing(5),
          maxWidth:theme.spacing(5),
          width:theme.spacing(5),
        },
      },
      "&.Mui-completed":{
        color: theme.palette.primary.main,
      },
      "&.Mui-active": {
        color: theme.palette.primary.main,
      },
    },

    "&.singleViewStepperWidget":{
      "&>div":{
        paddingLeft:"4px",
        paddingRight:"4px",
      },
      "& .MuiStepLabel-labelContainer ": {
          width:"55px"
      }
    },
    "&>div":{
      "&:first-child":{
        "& .MuiStepLabel-iconContainer": {
          position: "relative",
          "&:first-child":{
            "&::before": {
              content: "''",
              position: "absolute",
              display: "block",
              borderRadius: "50%",
              background: theme.palette.primary.lighter,
              height: 25,
              width: 25,
              top: -5,
              left: -5,
            },
          },
        },
      },
    },
    "&>.Mui-completed":{
      "&:first-child":{
        "& .MuiStepLabel-iconContainer": {
          "&:first-child":{
            "&::before": {
              display: "none"
            },
          },
        },
      },
    },
    "& .MuiStepIcon-root": {
      height: 15,
      width: 15,
      borderRadius: "50%",
      background: theme.palette.text.secondary,
      position: "relative",
      "&.Mui-active": {
        background: theme.palette.primary.main,
      },
      "&.Mui-completed": {
        background: theme.palette.primary.main,
      },
      "&>circle": {
        display: "none",
      },
      "&>path": {
        display: "none",
      },
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepConnector-root": {
      top: 14,
      left: "calc(-50% + 13px)",
      right: "calc(50% + 13px)",
      "&>.MuiStepConnector-line": {
        borderTop: `1px solid ${theme.palette.text.secondaryLight}`,
      },
      "&.Mui-active": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
        "&~.MuiStepLabel-alternativeLabel": {
          "& .MuiStepLabel-iconContainer": {
            position: "relative",
            "&::before": {
              content: "''",
              position: "absolute",
              display: "block",
              borderRadius: "50%",
              background: theme.palette.primary.lighter,
              height: 25,
              width: 25,
              top: -5,
              left: -5,
            },
          },
        },
      },
      "&.Mui-completed": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },

  steppeForLanding:{
    "& .MuiStepLabel-labelContainer":{
      width:"70px",
      "&>span":{
        minWidth:"70px !important"
      }, 
      "@media(max-width:420px)":{
        width:"60px",
        "&>span":{
          minWidth:"60px !important"
        }
      },
      "@media(max-width:325px)":{
        width:"50px",
        "&>span":{
          minWidth:"50px !important"
        }
      },
    },
  },
}));
const PAYMENT_FLOW_STEPS = [
  "summary",
  "buyer-info",
  "attendees-info",
  "payment",
];
export type StepperWithBackButtonProps = {
  className?: string;
  ticketData?: ITicketDetailsResponse;
  setKeepMounted?: () => void;
	microFeEventHandler?: () => void;
};

const StepperWithBackButton = ({
  className = "",
  ticketData,
  setKeepMounted,
}: StepperWithBackButtonProps): JSX.Element => {
  const classes = useStyles();
  const [t] = useTranslation();
  const [isContentWidth,setContentWidth] = useState(0);
  const { store, setTicketList, setTotalAmount, setDiscount, setPageState } =
    useStore();
  const {
    pageState = 1,
    ticketList = [],
    startFromSummary,
    totalAmount,
  } = store;
  const paidTicketPresent =
    ticketList &&
    ticketList?.find((data) => !!data.selectedQuantity && data.ticketFee > 0);

  const [showBackButton, setShowBackButton] = useState(false);

  // const showBackButton = useMemo(() => {
  //   if (startFromSummary) {
  //     return pageState > PageState.TicketSummary;
  //   }
  //   return pageState > PageState.TicketInfo;
  // }, [startFromSummary, pageState]);

  useEffect(() => {
    if(startFromSummary) {
      setShowBackButton(pageState > PageState.TicketSummary);
    } else if(pageState === PageState.AttendeeDetails && 
              !ticketData?.isbuyerForm && 
              paidTicketPresent === undefined) {
                setShowBackButton(false);
       } else {
         setShowBackButton(pageState > PageState.TicketInfo);
       }
  }, [pageState, paidTicketPresent, startFromSummary, ticketData?.isbuyerForm]);

  const updatePageState = (closeCall: boolean) => {
    const pageStateToApply =
      pageState -
      (!ticketData?.isSimpleRegistrationEnabled &&
      !paidTicketPresent &&
      pageState === PageState.BuyDetails
        ? pageState
        : pageState === PageState.AttendeeDetails && !ticketData?.isBuyerForm
        ? 2
        : 1);
    if (pageStateToApply === 0 && !isEmpty(ticketList)) {
      const updTicketList = ticketList.map((eachTicket) => {
        return { ...eachTicket, selectedQuantity: 0 };
      });
      setDiscount(pageStateToApply, {} as IApplyDiscount);
      setTicketList(updTicketList);
      setTotalAmount(0);
    } else if (setPageState) {
      // eslint-disable-next-line no-unused-expressions
      closeCall ? setPageState(pageStateToApply) : setPageState(0);
    }
  };

  useEffect(()=>{
    const elem = document.getElementById("wrapperForWidget")?.clientWidth;
    if(elem){
      setContentWidth(elem);
    }
  },[isContentWidth]);

  return (
    <Box className={className}>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          pt:ticketData?.isSimpleRegistrationEnabled ? 1.5 : 0.5,
          px:ticketData?.isSimpleRegistrationEnabled ? 2.5 : store.startFromSummary ? 2.5:0,
          "@media (max-width: 600px)": { 
             paddingTop:0,
             px:0,
            } ,
          }}
      >
        {/* {isAndroidIPhoneIPad() ? (
          ""
        ) : ( */}
        <>
          <Box
            sx={{
              width: "10%",
              display: "flex",
              // "@media (max-width: 600px)": { width: "90%", margin: "0 auto" },
            }}
          >
            <IconButton
              size={store.startFromSummary ? "medium":"small"}
              sx={(theme: any) => ({
                border: `1px solid ${theme.palette.divider.default}`,
                color: theme.palette.text.primary,
              })}
              onClick={() => {updatePageState(showBackButton); if(pageState == PageState.TicketSummary || !paidTicketPresent) {setKeepMounted?.();} }}
              className={`${classes.stepperBackArrow} ${store.widgetData?.isWidgetExpand ? classes.widgetArrow:false}`}
            >
              {showBackButton ? (
                <ArrowBackIosRoundedIcon fontSize={store.startFromSummary  ? "medium":"small"} />
              ) : (
                <CloseRoundedIcon fontSize={store.startFromSummary  ? "medium":"small"} onClick={() => {if(pageState == PageState.TicketSummary|| !paidTicketPresent) setKeepMounted?.();}}/>
              )}
            </IconButton>
          </Box>
        </>
        {/* )} */}
        <Box
          pt={store.widgetData?.isWidgetExpand ? 0: 1}
          sx={(theme: any) => ({
            width: store.startFromSummary ? "80%" : "85%",
            paddingLeft:store.startFromSummary ? "24px" : "0px",
            "@media (min-width: 768px) and (max-width: 1024px)": {
              paddingLeft:store.widgetData?.isWidgetExpand ? "1%": "5%",
            },
            "@media (max-width: 600px)": {
              width: "100%",
              paddingLeft: 0,
              paddingTop: theme.spacing(0.5),
            },
          })}
        >
          <Stepper
            activeStep={
              !ticketData?.isSimpleRegistrationEnabled && !paidTicketPresent || 
              !ticketData?.isBuyerForm && pageState !== PageState.TicketSummary
                ? pageState - 2
                : pageState - 1
            }
            alternativeLabel
            className={`${classes.stepper} ${store.widgetData?.isWidgetExpand ? (isContentWidth > 359 && isContentWidth < 450) ? "singleViewStepperWidget":false:false} ${store.startFromSummary  ? classes.steppeForLanding:false}`}
          >
            {PAYMENT_FLOW_STEPS.map((eachStep) => {
              if (
                (!ticketData?.isBuyerForm && eachStep === "buyer-info") ||
                (totalAmount === 0 && eachStep === "payment")
              )
                return false;
              return !ticketData?.isSimpleRegistrationEnabled &&
                !paidTicketPresent &&
                eachStep === "summary" ? null : (
                <Step key={`stepper-${eachStep}`}>
                  <StepLabel title={t(eachStep)}>{t(eachStep)}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        {/* <Box
          sx={{
            width: "30%",
            "@media (max-width: 600px)": { width: "90%", margin: "0 auto" },
          }}
        >
          <SelectBox placeHolder="Select" />
        </Box> */}
      </Box>
    </Box>
  );
};

export default StepperWithBackButton;