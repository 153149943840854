// libraries
import React, { useState } from "react";
// Components
import { Box, Typography, Grid } from "@mui/material";

// Helper Component
import TextField from "../InputField";
import SelectDropDown from "../SelectDropDown";
import TextAreaComponent from "../TextArea";

// Utils
import CountryList from "../../Utils/regions";
import { useTranslation } from "react-i18next";

interface ICompanyInfo {
  hasError?: boolean;
  msg?: string;
  val?: string;
}

interface TCompanyDetails {
  name?: ICompanyInfo;
  taxId?: ICompanyInfo;
  address?: ICompanyInfo;
  country?: ICompanyInfo;
  state?: ICompanyInfo;
  city?: ICompanyInfo;
  zipCode?: ICompanyInfo;
}
// Custom Interface
type TCountryList = {
  countryName?: string;
  countryShortCode?: string;
};

// Constants
const COUNTRIES: TCountryList[] = [...CountryList];

const TaxationFields = (props: any) => {
  const { classes, enableBuyerTaxId, companyDetails, setCompanyDetails } = props;
  const [t] = useTranslation();
  const [regions, setRegions] = useState<string[]>([]);

  const updateState = (id: string, value: string) => {
    const updateDetails: TCompanyDetails = {
      [id]: { val: value, hasError: false, msg: "" },
    };
    if (id === "country") {
      updateDetails["state"] = {
        val: "",
        hasError: updateDetails?.state?.hasError,
        msg: updateDetails?.state?.msg,
      };
    }
    setCompanyDetails({ ...companyDetails, ...updateDetails });
  };

  const onSelectCountry = (id: any) => {
    if (id) {
      updateState("country", id.countryName);
    }

    const fetchRegions = CountryList.filter(
      (x) => x.countryName === id.countryName
    );
    if (fetchRegions && Array.isArray(fetchRegions)) {
      const updateRegions = fetchRegions[0].regions.map((item: any) => {
        return item;
      });
      setRegions(updateRegions);
    }
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eleId = e.target.name;
    const fetchValue = e.target.value;
    updateState(eleId, fetchValue);
  };


  const onSelectState = (id: any) => {
    updateState("state", id.name);
  };

  return (
    <>
      <Grid item lg={6} xs={12}>
        <TextField
          label={t("company-name-label")}
          placeHolder={t("company-name-place-holder")}
          name="name"
          value={companyDetails.name?.val || ""}
          errorMsg={companyDetails.name?.msg || ""}
          onChange={(e) => {
            handleTextChange(e);
          }}
        />
      </Grid>

      {enableBuyerTaxId && (
              <Grid item lg={6} xs={12}>
                    <TextField
                        id="taxId"
                        key="taxId"
                        name="taxId"
                        label={t("taxId-label")}
                        placeHolder={t("taxId-place-holder")}
                        value={companyDetails.taxId?.val || ""}
                        errorMsg={companyDetails.taxId?.msg || ""}
                        onChange={(e) => {
                          handleTextChange(e);
                        }}
                        maxLength={23}
                    />
              </Grid>
            )}

      <Grid item xs={12} sx={{ textAlign: "left" }}>
        <Typography
          variant="caption"
          component="label"
          sx={(theme: any) => ({
            fontWeight: 400,
            color: theme.palette.primary.main,
            textAlign: "left",
          })}
        >
          {t("company-address-label")}
        </Typography>
        <Box
          pl={3}
          mt={1}
          sx={(theme: any) => ({
            borderLeft: `1px solid ${theme.palette.primary.main}`,
          })}
        >
          <Box sx={{ mb: 1.125, pt: 0 }}>
            <TextAreaComponent
              name={"address"}
              placeholder={t("company-address-place-holder")}
              value={companyDetails.address?.val || ""}
              onChange={handleTextChange}
              errorMsg={companyDetails.address?.msg || ""}
            />
          </Box>

          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <SelectDropDown
                placeHolder={t("country-place-holder")}
                className={classes.fullWidth}
                id="country"
                key="country"
                required={true}
                value={companyDetails.country?.val || ""}
                name="countryName"
                valueKey="countryName"
                position="bottom"
                labelKey="countryName"
                options={[...COUNTRIES]}
                errorMsg={companyDetails.country?.msg || ""}
                onChange={onSelectCountry}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <SelectDropDown
                placeHolder={t("state-place-holder")}
                className={classes.fullWidth}
                id="state"
                key="state"
                position="bottom"
                required={true}
                value={companyDetails.state?.val || ""}
                name="countryName"
                valueKey="name"
                labelKey="name"
                options={[...regions]}
                errorMsg={companyDetails.state?.msg || ""}
                onChange={onSelectState}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                placeHolder={t("city-place-holder")}
                name="city"
                value={companyDetails.city?.val || ""}
                errorMsg={companyDetails.city?.msg || ""}
                onChange={(e) => {
                  handleTextChange(e);
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <TextField
                placeHolder={t("zipcode-place-holder")}
                name="zipCode"
                value={companyDetails.zipCode?.val || ""}
                errorMsg={companyDetails.zipCode?.msg || ""}
                onChange={(e) => {
                  handleTextChange(e);
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default TaxationFields;
