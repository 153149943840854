// Language: portuguese

export default {
    "already-registered": "Já Está Registado",
    "add-to-calendar": "Adicionar ao Calendário",
    "event-starts-in": "Evento começa em",
    "event-is-live": "EVENTO EM DIRETO",
    "event-has-ended": "EVENTO TERMINOU",
    "powered-by": "Desenvolvido por",
    "please-enter-your-email-to-continue": "Introduzir o seu e-mail para continuar",
    add: "Adicionar",
    offers: "Ofertas",
    free: "Gratuito",
    "available-till": "Disponível até {{DATE}}",
    "enter-email-address": "Introduzir Endereço de E-mail",
    "sold-out": "Esgotado",
    "the-ticket-has-reached-the-maximum-number": "O bilhete atingiu o número máximo",
    "seat-left": "{{SEAT}} lugar restante",
    "seats-left": "{{SEAT}} lugares restantes",
    proceed: "Avançar",
    summary: "Resumo",
    "buyer-info": "Info do Cliente",
    continue: "Continuar",
    "attendees-info": "Info dos Participantes",
    "registration": "Inscrição",
    "attendees-information": "Informação sobre Participantes",
    submit: "Submeter",
    "register-to-join-events": "Registe-se para participar nos eventos",
    "by-clicking-on-the-button":
        "Ao clicar no botão, será redirecionado para a plataforma de venda de bilhetes onde se poderá inscrever para este evento.",
    other: "Outro",
    "please-enter-a-value-for": "Introduzir um valor para <FIELD_NAME>",
    "please-select-a-value-for": "Selecionar um valor para <FIELD_NAME>",
    "enter-other-value-for": "Introduzir outro valor para <FIELD_NAME>",
    "enter-a-valid": "Introduzir um <FIELD_NAME> válido",
    "should-be-at-least": "<FIELD_NAME> deve ser pelo menos <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> não pode ser maior que <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> é obrigatório.",
    "at-least-required": "Pelo menos <FIELD_LENGTH> <FIELD_NAME> <VERB> obrigatório",
    "maximum-allowed": "Máximo <FIELD_LENGTH> <FIELD_NAME> <VERB> permitido",
    "user-with-same-email-id-already-exists":
        "Já existe um utilizador com o mesmo id de e-mail. Por favor, use um diferente.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Utilizador com o mesmo id de e-mail já se registou para este evento. Por favor, use um diferente.",
    "mail-successfully-sent": "E-mail enviado com sucesso!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "Neste momento, não podemos entregar o seu e-mail! Tente depois de algum tempo",
    "something-went-wrong": "Algo Correu Mal! Tente novamente mais tarde",
    "please-select-a-ticket-to-proceed": "Selecionar um bilhete para continuar",
    "register-now": "Registe-se Já",
    "add-to-your-calendar": "Adicionar ao seu Calendário",
    calendar: "Calendário",
    "resend-link": "Reenviar Link",
    "go-to-event": "Ir para Evento",
    "ticket-registration-done-by": "Registo de bilhetes feito por",
    "you-have-already-purchased-the-ticket-for": "Já comprou o bilhete para",
    purchased: "Comprado",
    "event-date": "Data do Evento",
    "ticket-quantity": "Número de Bilhetes",
    payment: "Pagamento",
    "unable-to-submit-data": "Não foi possível enviar os dados",
    "unable-to-apply-promo-code": "Não foi possível aplicar o código promocional",
    "invalid-promo-code": "Código promocional inválido",
    "apply-now": "Aplicar",
    "enter-discount-coupon": "Introduzir cupão de desconto",
    "same-as-buyer": "Igual ao cliente",
    "please-check-all-the-fields-are-correctly-filled": "Verificar se todos os campos estão corretamente preenchidos",
    "ticket-purchased": "Bilhete Comprado",
    "ticket-purchase-failed": "Falha na Compra de Bilhetes",
    "you-have-successfully-purchased-the-ticket":
        "O bilhete foi adquirido com sucesso. Este bilhete permitir-lhe-á assistir ao evento. Obrigado pela sua compra!",
    "there-was-an-error-while-booking-your-tickets":
        "Ocorreu um erro ao reservar os seus bilhetes. Por favor, tente de novo.",
    "try-again": "Tente de Novo.",
    "you-dont-have-access": "Não tem acesso à compra de alguns bilhetes",
    "please-enter-a-valid-email": "Introduzir um e-mail válido",
    "payment-summary": "Resumo do Pagamento",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} aplicado com sucesso. Guardou {{AMOUNT}} adicionais.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Quer gerar uma factura fiscal?",
    "taxation-tnc-pp": "Ao continuar você concorda com nossos",
    "taxation-tnc": "Termos e Condições",
    and: "e",
    "taxation-pp": "Política de Privacidade",
    "company-name-label": "Nome da empresa",
    "company-name-place-holder": "Insira o nome",
    "taxId-label": "Identificação Fiscal da Empresa",
    "taxId-place-holder": "Insira o CPF",
    "company-address-label": "Endereço da empresa",
    "company-address-place-holder": "Endereço",
    "country-place-holder": "Seleccionar País",
    "state-place-holder": "Seleccionar Estado",
    "city-place-holder": "Entrar na cidade",
    "zipcode-label": "Código postal",
    "zipcode-place-holder": "Introduza o código postal",
    "company-error-msg": "Por favor introduza o nome da empresa",
    "taxId-error-msg": "Por favor introduza o Identificação Fiscal da Empresa",
    "address-error-msg": "Por favor introduza Morada",
    "country-error-msg": "Por favor seleccione País",
    "state-error-msg": "Por favor seleccione Estado",
    "city-error-msg": "Por favor, introduza Cidade",
    "zipcode-error-msg": "Por favor introduza o Código Postal",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Nome",
    lastName: "Sobrenome",
    email: "E-mail",
    phone: "Telefone",
    dialerCode: "Código de discagem",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Já comprou bilhete para",
    "upload-file": "Carregar ficheiro",
    "browse-a-file-to-upload": "Procurar um ficheiro para carregar",
    "max-size": "Tamanho máximo",
    "file-supported-types": "Tipos de ficheiros suportados",
    "uploaded-successfully": "Carregado com sucesso",
    "failed!-please-retry": "Falha! Por favor, Tente novamente",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Este tipo de ficheiro não é suportado. Os anexos suportados são",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "O tamanho do ficheiro é demasiado elevado. Por favor, carregue um documento inferior a",
    person: "Pessoa",
    select: "Seleccione",
    search: "Pesquisa",
    "uploaded-file": "Ficheiro carregado",
    uploading: "Carregamento",
    delete: "Eliminar",
    "no-item": "Nenhum artigo",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Calendário Google",
    "outlook-calendar": "Calendário de Perspectivas",
    "yahoo-calendar": "Calendário Yahoo",
    "read-more": "Leia mais",
    "read-less": "Ler menos",
    "about-title": "Sobre",
    "verb-are": "são",
    "verb-is": "é",
    character: "caracteres",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "Hora",
    "timer-hrs": "Horas",
    "timer-min": "Minuto",
    "timer-mins": "Minutos",
    "timer-sec": "Segundo",
    "timer-secs": "Segundos",
    "timer-day": "Dia",
    "timer-days": "Dias",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Nenhum ficheiro escolhido",
    today: "Hoje",
    yesterday: "Ontem",
    tomorrow: "Amanhã",
    last: "Durar",
    "internet-down": "Internet parece estar desconectada!",
    "internet-up": "A Internet agora está conectada",
    "network-issue": "correu algum problema de rede mensagem de erro, tente novamente!'",
    registrationSuccess: "Inscrição bem sucedida!",
    registrationSuccessDetail: "Inscreveu-se com sucesso no evento. Obrigado!",
    "search-here": "Procure aqui..",
    "did-you-mean": "Quer dizer ",
    DISPOSABLE_EMAIL: "O endereço de correio electrónico dado pertence a um serviço de correio electrónico descartável",
    RISTRICTED_DOMAIN: "O endereço de e-mail dado está dentro de um domínio restrito. Por favor, introduza um endereço de correio electrónico válido",
    INVALID_EMAIL: "Introduzir um e-mail válido",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Escolha o método de pagamento",
    PAY_NOW: "Pagar agora",
    INSTANT_TICKET_CONFIRMATION: "Confirmação instantânea de bilhetes",
    PAY_VIA_OTHER_METHODS: "Pagar através de outros métodos",
    FUNDS_TRANSFER: "Transferência de fundos",
    DIGITAL_WALLET: "Carteira Digital",
    CHEQUE: "Cheque",
    PAY_AT_VENUE: "Pagar no local",
    TICKET_NOT_CONFIRMED_ORGANIZER: "O(s) bilhete(s) não serão confirmados até o organizador do evento aprovar que o pagamento foi recebido e está sujeito a cancelamento.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "O(s) bilhete(s) não será(ão) confirmado(s) até que o pagamento seja efectuado no local do evento e esteja(ão) sujeito(s) a cancelamento.",
    TICKET_CONFIRMED_BY_ORGANIZER: "O(s) bilhete(s) será(ão) confirmado(s) quando o organizador do evento aprovar que o pagamento foi recebido.",
    COMPLETE_PAYMENT_OF: "Pagamento completo de",
    COMPLETE_PAYMENT_TICKET: "Complete o pagamento do(s) seu(s) bilhete(s).",
    PAYMENT_CONFIRMATION_TITLE: "Introduza o ID da transacção única para o organizador do evento para verificar o seu pagamento.",
    ENTER_TRANSACTION_ID: "Introduzir o ID de Transacção",
    SHARE_DETAILS_TITLE: "O organizador do evento só pode aprovar o seu pagamento se partilhar os detalhes. O(s) seu(s) bilhete(s), incluindo qualquer bilhete(s) gratuito(s), será(ão) confirmado(s) após a aprovação.",
    UPLOAD_SCREENSHOT: "Upload Screenshot (opcional)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Detalhes de Pagamento de Acções",
    TICKET_STATUS: "Estado do bilhete", // not found
    ORGANIZER_YET_TO_CONFIRM: "O organizador do evento ainda não confirmou que o pagamento foi recebido.",
    TICKET_CONFIRMATION_ORGANIZER: "O(s) seu(s) bilhete(s), incluindo qualquer bilhete(s) gratuito(s), e a factura serão confirmados e partilhados consigo após a aprovação do organizador do evento.",
    PAYMENT_DETAILS: "Detalhes de pagamento",
    FAQ_TITLE: "Em caso de qualquer questão(s), consultar as FAQs",
    MAKE_PAYMENT_TITLE: "Faça o pagamento no local do evento para confirmar a(s) compra(s) do(s) bilhete(s).",
    CONFIRM_TICKET_PRIOR_EVENT: "Se desejar confirmar o(s) seu(s) bilhete(s) antes do evento, pagar agora com um método diferente.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Se desejar confirmar o(s) seu(s) bilhete(s) antes do evento,",
    PAY_NOW_2: "pagar agora",
    WITH_DIFFERENT_METHOD: "com um método diferente",
    PAYMENT_NOT_RCVD: "O organizador do evento ainda não recebeu o pagamento.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Os detalhes do pagamento partilhados não puderam ser verificados pelo organizador do evento.",
    TICKETS_NOT_AVAILABLE: "Os bilhetes já não estão disponíveis para o evento.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Ainda não foram partilhados os detalhes de pagamento do(s) bilhete(s).", // not found in code
    WELCOME_BACK: "Bem-vindo de volta!",
    VIEW_OR_PROCEED_PURCHASE: "Pode visualizar os seus bilhetes anteriores ou prosseguir com a compra dos bilhetes que acabou de seleccionar.",
    VIEW_PREV_TICKETS: "Ver Bilhetes Anteriores",
    PROCEED_TO_PURCHASE: "Proceder à compra",
    PREVIOUS_TICKETS: "Bilhetes anteriores",
    PENDING_TICKETS: "Bilhetes Pendentes",
    CONFIRMED_TICKETS: "Bilhetes confirmados",
    PAYMENT_PENDING: "Pagamento pendente",
    AWAITING_ORG_APPROVAL: "A aguardar aprovação do organizador",
    CHECK_TICKET_STATUS: "Verificar o estado do bilhete",
    PAYMENT_METHOD_NOT_AVAILABLE: "método de pagamento não disponível, por favor tente novamente.", // not found in code
    HERE: "aqui.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Por favor, actualize a informação única do participante para todos os bilhetes adquiridos. Isto permitirá que todos os participantes tenham acesso ao evento.",
    DUPLICATE_EMAIL: "Você já comprou este bilhete. Você pode optar por se registrar novamente. Neste caso, os dados mais recentes inseridos serão salvos.",
    DEFAULT_DUPLICATE_EMAIL: "Este e-mail já está sendo usado para outro ticket. Por favor, verifique novamente o endereço de e-mail que você digitou ou sinta-se à vontade para usar o mesmo e-mail para vários tickets.",
    "REGISTER_NOW": "Registe-se Já",
    "REG_FAILED": "Falha no registo",
    "REG_FAILED_DESC": "O seu registo falhou. Tente novamente.",
    "REG_DONE_BY": "Registo feito por",
    "INVITE_AGAIN": "Quer o convite novamente?",
    "REG_SUCCESS_DONE": "Registo realizado com sucesso",
    "WATCH_REC_LINK": "Um correio eletrónico com um sítio para assistir à gravação foi enviado para",
    "WEBINAR_INV_LINK": "Um correio eletrónico de convite com o sítio do webinário foi enviado para",
    "MAIL_SENT_SUCCESS": "Correio eletrónico enviado com sucesso",
    "ALREADY_REGISTERED": "Já se registou para",
    "RESEND_INVITE": "Reenviar convite",
    "WATCH_RECORDING_REGISTER_DESC": "Pode assistir à gravação do webinário registando-se abaixo.",

};
