// Language: Italian

export default {
    "already-registered": "Già registrato",
    "add-to-calendar": "Aggiungi a calendario",
    "event-starts-in": "L’evento inizia",
    "event-is-live": "L’EVENTO È LIVE",
    "event-has-ended": "L’EVENTO È TERMINATO",
    "powered-by": "Presentato da",
    "please-enter-your-email-to-continue": "Inserisci l’email per continuare",
    add: "Aggiungi",
    offers: "Offerte",
    free: "Libero",
    "available-till": "Disponibile fino {{DATE}}",
    "enter-email-address": "Inserisci indirizzo email",
    "sold-out": "Esaurito",
    "the-ticket-has-reached-the-maximum-number": "Il biglietto ha raggiunto il numero massimo",
    "seat-left": "{{SEAT}} posto rimasto",
    "seats-left": "{{SEAT}} posti rimasti",
    proceed: "Procedi",
    summary: "Riassunto",
    "buyer-info": "Info acquirente",
    continue: "Continua",
    "attendees-info": "Info partecipanti",
    "registration": "Registrazione",
    "attendees-information": "Informazioni partecipanti",
    submit: "Invia",
    "register-to-join-events": "Registrati per unirti agli eventi",
    "by-clicking-on-the-button":
        "Cliccando sul bottone, verrai reindirizzato alla piattaforma di vendita biglietti dove puoi registrati per l’evento.",
    other: "Altro",
    "please-enter-a-value-for": "Inserisci un valore per <FIELD_NAME>",
    "please-select-a-value-for": "Seleziona un valore per <FIELD_NAME>",
    "enter-other-value-for": "Inserisci altro valore per <FIELD_NAME>",
    "enter-a-valid": "Inserisci un <FIELD_NAME> valido",
    "should-be-at-least": "<FIELD_NAME> deve essere di almeno <FIELD_LENGTH> <FIELD_TYPE> ",
    "cannot-be-more-than": "<FIELD_NAME> non può essere più di <FIELD_LENGTH> <FIELD_TYPE> ",
    "is-required": "<FIELD_NAME> è necessario.",
    "at-least-required": "Almeno <FIELD_LENGTH> <FIELD_NAME> <VERB> richiesto",
    "maximum-allowed": "Massimo <FIELD_LENGTH> <FIELD_NAME> <VERB> permesso",
    "user-with-same-email-id-already-exists": "Un utente con la stessa id email già esiste. Usane una diversa.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Un utente con la stessa id email è già registrato per l’evento. Usane una diversa.",
    "mail-successfully-sent": "Email inviata con successo!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "Non possiamo consegnare l’email in questo momento! Prova più tardi",
    "something-went-wrong": "Si è verificato un errore, riprova più tardi",
    "please-select-a-ticket-to-proceed": "Seleziona un biglietto per procedere",
    "register-now": "Registrati ora",
    "add-to-your-calendar": "Aggiungi al calendario",
    calendar: "Calendario",
    "resend-link": "Invia di nuovo il link",
    "go-to-event": "Vai all’evento",
    "ticket-registration-done-by": "Registrazione biglietto fatta da",
    "you-have-already-purchased-the-ticket-for": "Hai già acquistato il biglietto per",
    purchased: "Acquistato",
    "event-date": "Data evento",
    "ticket-quantity": "Quantità biglietto",
    payment: "Pagamento",
    "unable-to-submit-data": "Non si può inviare i dati",
    "unable-to-apply-promo-code": "Non si può applicare il codice promozionale ",
    "invalid-promo-code": "Codice promozionale non valido",
    "apply-now": "Applicare",
    "enter-discount-coupon": "Inserisci coupon sconto",
    "same-as-buyer": "Uguale all’acquirente",
    "please-check-all-the-fields-are-correctly-filled": "Controlla che tutti i campi siano correttamente completati",
    "ticket-purchased": "Biglietto acquistato",
    "ticket-purchase-failed": "Acquisto biglietto non riuscito",
    "you-have-successfully-purchased-the-ticket":
        "Hai acquistato con successo il biglietto. Ti permetterà di seguire l’evento. Grazie!",
    "there-was-an-error-while-booking-your-tickets":
        "Si è verificato un errore nella prenotazione dei biglietti. Riprova.",
    "try-again": "Riprova",
    "you-dont-have-access": "Non hai l’accesso all’acquisto di alcuni biglietti",
    "please-enter-a-valid-email": "Inserisci un’email valida",
    "payment-summary": "Riassunto pagamento",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} applicato con successo. Hai salvato ulteriori {{AMOUNT}}.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Vuoi generare una fattura fiscale?",
    "taxation-tnc-pp": "Continuando accetti le",
    "taxation-tnc": "Termini e Condizioni",
    and: "e",
    "taxation-pp": "politica sulla riservatezza",
    "company-name-label": "Nome dell'azienda",
    "company-name-place-holder": "Inserisci il nome dell'azienda",
    "taxId-label": "Codice fiscale della società",
    "taxId-place-holder": "Inserisci il codice fiscale",
    "company-address-label": "Indirizzo dell'azienda",
    "company-address-place-holder": "Indirizzo",
    "country-place-holder": "Selezionare il paese",
    "state-place-holder": "Seleziona lo Stato",
    "city-place-holder": "Inserisci la città",
    "zipcode-label": "Codice postale",
    "zipcode-place-holder": "Inserisci il codice postale",
    "company-error-msg": "Inserire il nome dell'azienda",
    "taxId-error-msg": "Si prega di inserire il codice fiscale della società",
    "address-error-msg": "Si prega di inserire l'indirizzo",
    "country-error-msg": "Seleziona il tuo paese",
    "state-error-msg": "Per favore, selezioni lo Stato",
    "city-error-msg": "Per favore, inserisci la città",
    "zipcode-error-msg": "Si prega di inserire il codice postale",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Nome",
    lastName: "Cognome",
    email: "E-mail",
    phone: "Telefono",
    dialerCode: "Codice telefonico",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Hai già acquistato il biglietto per",
    "upload-file": "Caricare un file",
    "browse-a-file-to-upload": "Sfoglia un file da caricare",
    "max-size": "Dimensione massima",
    "file-supported-types": "Tipi di file supportati",
    "uploaded-successfully": "Caricato con successo",
    "failed!-please-retry": "Fallito! Si prega di riprovare",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Questo tipo di file non è supportato. Gli allegati supportati sono",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "La dimensione del file è troppo alta. Si prega di caricare un documento di dimensioni inferiori a",
    person: "Persona",
    select: "Seleziona",
    search: "Cerca",
    "uploaded-file": "File caricato",
    uploading: "Caricamento di",
    delete: "Cancella",
    "no-item": "Nessun elemento",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Calendario Google",
    "outlook-calendar": "Calendario di Outlook",
    "yahoo-calendar": "Calendario Yahoo",
    "read-more": "Per saperne di più",
    "read-less": "Leggere di meno",
    "about-title": "Informazioni su",
    "verb-are": "sono",
    "verb-is": "è",
    character: "carattere",
    // [HUB-9250] Date format translation
    "date-format": "GG/MM/AAAA",
    "timer-hr": "Ora",
    "timer-hrs": "Ore",
    "timer-min": "Minuto",
    "timer-mins": "Minuti",
    "timer-sec": "Sec",
    "timer-secs": "Secs",
    "timer-day": "Giorno",
    "timer-days": "Giorni",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Nessun file scelto",
    today: "In data odierna",
    yesterday: "Ieri",
    tomorrow: "Domani",
    last: "Scorso",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Registrazione eseguita con successo!",
    registrationSuccessDetail: "Ti sei registrato con successo per l evento. Grazie!",
    "search-here": "Cerca qui..",
    "did-you-mean": "Vuoi dire ",
    DISPOSABLE_EMAIL: "L'indirizzo email specificato appartiene a un servizio di posta elettronica usa e getta",
    RISTRICTED_DOMAIN: "L'indirizzo email specificato è all'interno di un dominio limitato. Inserisci un indirizzo email valido",
    INVALID_EMAIL: "Inserisci un’email valida",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Scegliere il metodo di pagamento",
    PAY_NOW: "Paga ora",
    INSTANT_TICKET_CONFIRMATION: "Conferma immediata del biglietto",
    PAY_VIA_OTHER_METHODS: "Paga con altri metodi",
    FUNDS_TRANSFER: "Trasferimento di fondi",
    DIGITAL_WALLET: "Portafoglio digitale",
    CHEQUE: "Assegno",
    PAY_AT_VENUE: "Pagamento al luogo dell'evento",
    TICKET_NOT_CONFIRMED_ORGANIZER: "I biglietti non saranno confermati finché l'organizzatore dell'evento non approverà che il pagamento è stato ricevuto e sono soggetti a cancellazione.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "I biglietti non saranno confermati finché non si effettua il pagamento presso la sede dell'evento e sono soggetti a cancellazione.",
    TICKET_CONFIRMED_BY_ORGANIZER: "I biglietti saranno confermati quando l'organizzatore dell'evento avrà approvato la ricezione del pagamento.",
    COMPLETE_PAYMENT_OF: "Completare il pagamento di",
    COMPLETE_PAYMENT_TICKET: "Completare il pagamento dei biglietti.",
    PAYMENT_CONFIRMATION_TITLE: "Immettere l'ID di transazione unico per l'organizzatore dell'evento per verificare il pagamento.",
    ENTER_TRANSACTION_ID: "Inserire l'ID della transazione",
    SHARE_DETAILS_TITLE: "L'organizzatore dell'evento può approvare il pagamento solo se si condividono i dettagli. I biglietti, compresi quelli gratuiti, saranno confermati dopo l'approvazione.",
    UPLOAD_SCREENSHOT: "Caricare lo screenshot (facoltativo)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Condividi i dettagli del pagamento",
    TICKET_STATUS: "Stato del biglietto", // not found
    ORGANIZER_YET_TO_CONFIRM: "L'organizzatore dell'evento non ha ancora confermato la ricezione del pagamento.",
    TICKET_CONFIRMATION_ORGANIZER: "I biglietti, compresi quelli gratuiti, e la fattura saranno confermati e condivisi con voi dopo l'approvazione dell'organizzatore dell'evento.",
    PAYMENT_DETAILS: "Dettagli sul pagamento",
    FAQ_TITLE: "In caso di domande, consultare le Domande frequenti (FAQ)",
    MAKE_PAYMENT_TITLE: "Effettuare il pagamento presso la sede dell'evento per confermare l'acquisto dei biglietti.",
    CONFIRM_TICKET_PRIOR_EVENT: "Se si desidera confermare i biglietti prima dell'evento, pagare ora con un altro metodo.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Se desideri confermare i tuoi biglietti prima dell'evento,",
    PAY_NOW_2: "paga ora",
    WITH_DIFFERENT_METHOD: "con un metodo diverso",
    PAYMENT_NOT_RCVD: "L'organizzatore dell'evento non ha ricevuto il pagamento.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "I dati di pagamento condivisi non hanno potuto essere verificati dall'organizzatore dell'evento.",
    TICKETS_NOT_AVAILABLE: "I biglietti non sono più disponibili per l'evento.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Non hai ancora condiviso i dettagli del pagamento per i biglietti.", // not found in code
    WELCOME_BACK: "Bentornato!",
    VIEW_OR_PROCEED_PURCHASE: "È possibile visualizzare i biglietti precedenti o procedere all'acquisto dei biglietti appena selezionati.",
    VIEW_PREV_TICKETS: "Visualizza i biglietti precedenti",
    PROCEED_TO_PURCHASE: "Procedere all'acquisto",
    PREVIOUS_TICKETS: "Biglietti precedenti",
    PENDING_TICKETS: "Biglietti in sospeso",
    CONFIRMED_TICKETS: "Biglietti confermati",
    PAYMENT_PENDING: "Pagamento in attesa",
    AWAITING_ORG_APPROVAL: "In attesa dell'approvazione dell'organizzatore",
    CHECK_TICKET_STATUS: "Controlla lo stato del biglietto",
    PAYMENT_METHOD_NOT_AVAILABLE: "metodo di pagamento non disponibile, riprovare.", // not found in code
    HERE: "qui.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Si prega di aggiornare le informazioni sui partecipanti unici per tutti i biglietti acquistati. Ciò consentirà a tutti i partecipanti di accedere all'evento.",
    DUPLICATE_EMAIL: "Hai già acquistato questo biglietto. Puoi scegliere di registrarti nuovamente. In questo caso verranno salvati gli ultimi dati inseriti.",
    DEFAULT_DUPLICATE_EMAIL: "Questa email è già utilizzata per un altro biglietto. Per favore ricontrolla l'indirizzo email che hai inserito o sentiti libero di usare la stessa email per più biglietti.",
    "REGISTER_NOW": "Registrati ora",
    "REG_FAILED": "Registrazione non riuscita",
    "REG_FAILED_DESC": "La registrazione non è riuscita. Prova di nuovo.",
    "REG_DONE_BY": "Registrazione effettuata da",
    "INVITE_AGAIN": "Vuoi di nuovo l'invito?",
    "REG_SUCCESS_DONE": "Registrazione effettuata con successo",
    "WATCH_REC_LINK": "Una e-mail con un link per guardare la registrazione è stata inviata a",
    "WEBINAR_INV_LINK": "Un'e-mail di invito con il link del webinar è stata inviata a",
    "MAIL_SENT_SUCCESS": "Mail inviata con successo",
    "ALREADY_REGISTERED": "Sei già registrato",
    "RESEND_INVITE": "Rinvia invito",
    "WATCH_RECORDING_REGISTER_DESC": "Puoi guardare la registrazione del webinar registrandoti qui sotto.",

};
