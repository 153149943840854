// Language: English

export default {
    "already-registered": "Already Registered?",
    "add-to-calendar": "Add To Calendar",
    "event-starts-in": "Event starts in",
    "event-is-live": "EVENT IS LIVE",
    "event-has-ended": "EVENT HAS ENDED",
    "powered-by": "Powered By",
    "please-enter-your-email-to-continue": "Please enter your email to continue",
    add: "Add",
    offers: "Offers",
    free: "Free",
    "available-till": "Available till {{DATE}}",
    "enter-email-address": "Enter Email Address",
    "sold-out": "Sold out",
    "the-ticket-has-reached-the-maximum-number": "The ticket has reached the maximum number",
    "seat-left": "{{SEAT}} seat left",
    "seats-left": "{{SEAT}} seats left",
    proceed: "Proceed",
    summary: "Summary",
    "buyer-info": "Buyer Info",
    "attendees-info": "Attendees Info",
    "registration": "Registration",
    "continue": "Continue",
    "attendees-information": "Attendee Information",
    submit: "Submit",
    "register-to-join-the-events": "Register to join the events",
    "by-clicking-on-the-button":
        "By clicking on the button, you'll be redirected to the ticketing platform where you can register for this event.",
    other: "Other",
    "please-enter-a-value-for": "Please enter a value for <FIELD_NAME>",
    "please-select-a-value-for": "Please select a value for <FIELD_NAME>",
    "enter-other-value-for": "Enter other value for <FIELD_NAME>",
    "enter-a-valid": "Enter a valid <FIELD_NAME>",
    "should-be-at-least": "<FIELD_NAME> should be at least <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> cannot be more than <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> is required",
    "at-least-required": "At least <FIELD_LENGTH> <FIELD_NAME> <VERB> required",
    "maximum-allowed": "Maximum <FIELD_LENGTH> <FIELD_NAME> <VERB> allowed",
    "user-with-same-email-id-already-exists": "User with same email id already exists. Please use a different one.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "User with same email id has already registered for this event. Please use a different one.",
    "mail-successfully-sent": "Mail successfully sent!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "We are unable to deliver your mail at this moment! Please try after sometime",
    "something-went-wrong": "Something Went Wrong. We are fixing this. We regret for the inconvenience caused.",
    "register-now": "Register Now",
    "add-to-your-calendar": "Add to Calendar",
    "please-select-a-ticket-to-proceed": "Please select a ticket to proceed",
    "resend-link": "Resend Link",
    "go-to-event": "Go to Event",
    "ticket-registration-done-by": "Ticket registration done by",
    "you-have-already-purchased-the-ticket-for": "You have already purchased the ticket for",
    purchased: "Purchased",
    "event-date": "Event Date",
    "ticket-quantity": "Ticket quantity",
    payment: "Payment",
    "unable-to-submit-data": "Unable to submit data",
    "unable-to-apply-promo-code": "Unable to apply promo code",
    "invalid-promo-code": "Invalid promo code",
    "apply-now": "Apply",
    "enter-discount-coupon": "Enter discount coupon",
    "same-as-buyer": "Same as buyer",
    "please-check-all-the-fields-are-correctly-filled": "Please check all the fields are correctly filled",
    "ticket-purchased": "Ticket Purchased!",
    "ticket-purchase-failed": "Ticket Purchase Failed!",
    "you-have-successfully-purchased-the-ticket":
        "You have successfully purchased the ticket. This ticket will allow you to attend the event. Thank you for your purchase!",
    "there-was-an-error-while-booking-your-tickets": "There was an error while booking your tickets. Please try again.",
    "try-again": "Try Again",
    "you-dont-have-access": "You don't have access to buy some of the tickets",
    "please-enter-a-valid-email": "Please enter a valid email",
    "please-enter-email-to-continue": "Please enter email to continue",
    "payment-summary": "Payment Summary",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} successfully applied. You saved additional {{AMOUNT}}.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    and: "and",
    "taxation-concent": "Want to generate a Tax Invoice?",
    "taxation-tnc-pp": "By Continuing in you agree to our",
    "taxation-tnc": "Terms and Conditions",
    "taxation-pp": "Privacy Policy",
    "company-name-label": "Company Name",
    "company-name-place-holder": "Enter Company Name",
    "taxId-label": "Company Tax ID",
    "taxId-place-holder": "Enter Company Tax ID",
    "company-address-label": "Company Address",
    "company-address-place-holder": "Address",
    "country-place-holder": "Select Country",
    "state-place-holder": "Select State",
    "city-place-holder": "Enter City",
    "zipcode-label": "Zipcode",
    "zipcode-place-holder": "Enter Zipcode",
    "company-error-msg": "Please enter Company Name",
    "taxId-error-msg": "Please enter Company Tax ID",
    "address-error-msg": "Please enter Address",
    "country-error-msg": "Please Select Country",
    "state-error-msg": "Please Select State",
    "city-error-msg": "Please enter City",
    "zipcode-error-msg": "Please enter Zip Code",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    dialerCode: "Dialer Code",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "You have already purchased ticket for",
    "browse-a-file-to-upload": "Browse a file to upload",
    "upload-file": "Upload File",
    "max-size": "Max size",
    "file-supported-types": "File supported types",
    "uploaded-successfully": "Uploaded Successfully",
    "failed!-please-retry": "Failed! Please Retry",
    "this-file-type-is-not-supported-supported-attachments-are":
        "This file type is not supported. Supported attachments are",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "The file size is too high. Please upload document lesser than",
    person: "Person ",
    select: "Select",
    search: "Search",
    "uploaded-file": "Uploaded File",
    uploading: "Uploading",
    delete: "Delete",
    "no-item": "No item",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "read-more": "Read more",
    "read-less": "Read less",
    "about-title": "About",
    // [HUB-9217] On buyer form and attendee form for group field in validation message "is" text is coming
    "google-calendar": "Google Calendar",
    "outlook-calendar": "Outlook Calendar",
    "yahoo-calendar": "Yahoo Calendar",
    "verb-are": "are",
    "verb-is": "is",
    character: "character",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "hr",
    "timer-hrs": "hrs",
    "timer-min": "min",
    "timer-mins": "mins",
    "timer-sec": "sec",
    "timer-secs": "secs",
    "timer-day": "day",
    "timer-days": "days",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "No file chosen",
    today: "Today",
    yesterday: "Yesterday",
    tomorrow: "Tomorrow",
    last: "Last",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Registration Successfully Done!",
    registrationSuccessDetail: "You have successfully registered for the event. Thank you!",
    "search-here": "Search Here..",
    "did-you-mean": "Did you mean ",
    DISPOSABLE_EMAIL: "The given email address belongs to a disposable email service",
    RISTRICTED_DOMAIN: "The given email address is within a restricted domain. Please enter a valid email address",
    INVALID_EMAIL: "Please enter a valid email",
    // [HUB-21217]
    ORDERED: "Ordered",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Choose payment method",
    PAY_NOW: "Pay now",
    INSTANT_TICKET_CONFIRMATION: "Instant ticket confirmation",
    PAY_VIA_OTHER_METHODS: "Pay via other methods",
    FUNDS_TRANSFER: "Funds Transfer",
    DIGITAL_WALLET: "Digital Wallet",
    CHEQUE: "Cheque",
    PAY_AT_VENUE: "Pay at Venue",
    TICKET_NOT_CONFIRMED_ORGANIZER: "Ticket(s) will not be confirmed until the event organizer approves that payment has been received and are subject to cancellation.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Ticket(s) will not be confirmed until you make the payment at the event venue and are subject to cancellation.",
    TICKET_CONFIRMED_BY_ORGANIZER: "Ticket(s) will be confirmed when the event organizer approves that payment has been received.",
    COMPLETE_PAYMENT_OF: "Complete payment of",
    COMPLETE_PAYMENT_TICKET: "Complete the payment for your ticket(s).",
    PAYMENT_CONFIRMATION_TITLE: "Enter the unique Transaction ID for the event organizer to verify your payment.",
    ENTER_TRANSACTION_ID: "Enter the Transaction ID",
    SHARE_DETAILS_TITLE: "The event organizer can only approve your payment if you share the details. Your ticket(s), including any free ticket(s), will be confirmed post-approval.",
    UPLOAD_SCREENSHOT: "Upload Screenshot (optional)",
    SHARE_PAYMENT_DETAILS: "Share Payment Details",
    TICKET_STATUS: "Ticket Status", // not found
    ORGANIZER_YET_TO_CONFIRM: "The event organizer is yet to confirm that the payment has been received.",
    TICKET_CONFIRMATION_ORGANIZER: "Your ticket(s), including any free ticket(s), and invoice will be confirmed and shared with you after the approval of the event organizer.",
    PAYMENT_DETAILS: "Payment Details",
    FAQ_TITLE: "In case of any question(s), refer to the FAQs",
    MAKE_PAYMENT_TITLE: "Make the payment at the event venue to confirm your ticket(s) purchase.",
    CONFIRM_TICKET_PRIOR_EVENT: "If you wish to confirm your ticket(s) prior to the event,pay now with a different method.", // need to handle differently
    CONFIRM_TICKETS_PRIOR_EVENT: "If you wish to confirm your ticket(s) prior to the event,",
    PAY_NOW_2: "pay now",
    WITH_DIFFERENT_METHOD: "with a different method",
    PAYMENT_NOT_RCVD: "The event organizer has not received the payment.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "The payment details shared could not be verified by the event organizer.",
    TICKETS_NOT_AVAILABLE: "The tickets are no longer available for the event.",
    PAYMENT_DETAILS_YET_TO_SHARE: "You are yet to share payment details for the ticket(s).",
    WELCOME_BACK: "Welcome back!",
    VIEW_OR_PROCEED_PURCHASE: "You can view your previous tickets or proceed with the purchase of the tickets you just selected.",
    VIEW_PREV_TICKETS: "View Previous Tickets",
    PROCEED_TO_PURCHASE: "Proceed to Purchase",
    PREVIOUS_TICKETS: "Previous Tickets",
    PENDING_TICKETS: "Pending Tickets",
    CONFIRMED_TICKETS: "Confirmed Tickets",
    PAYMENT_PENDING: "Payment pending",
    AWAITING_ORG_APPROVAL: "Awaiting organizer approval",
    CHECK_TICKET_STATUS: "Check Ticket Status",
    PAYMENT_METHOD_NOT_AVAILABLE: "payment method not available, please try again.", // not found in code,
    HERE: "here.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Kindly update the unique attendee information for all the tickets purchased. This will enable all those attendees to access the event.",
    DUPLICATE_EMAIL: "You have already bought this ticket. You may choose to re-register. In this case the most recent data entered will be saved.",
    DEFAULT_DUPLICATE_EMAIL: "This email is already used for another ticket. Please double-check the email address you entered or feel free to use the same email for multiple tickets.",
    "REGISTER_NOW": "Register Now",
    "ALREADY_REGISTERED": "You have already registered for",
    "RESEND_INVITE": "Resend Invite",
    "REG_FAILED": "Registration Failed",
    "REG_FAILED_DESC": "Your registration has failed. Please try again.",
    "REG_DONE_BY": "Registration done by",
    "INVITE_AGAIN": "Want the invite again?",
    "REG_SUCCESS_DONE": "Registration successfully done",
    "WATCH_REC_LINK": "An email with a link to watch the recording has been send to",
    "WEBINAR_INV_LINK": "An invitation email with the webinar link has been sent to",
    "MAIL_SENT_SUCCESS": "Mail successfully sent",
    "WATCH_RECORDING_REGISTER_DESC": "You can watch the recording of the webinar by registering below",

};
