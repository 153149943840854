import envConfig from "config";

let amplitude = typeof window !== "undefined" ? require("amplitude-js") : null;
amplitude = amplitude?.default;


export const shouldApplied = true;

export const initAmplitude = () => {
    if (shouldApplied && typeof window !== "undefined" && !window.isAmplitudeApplied) {
        window.isAmplitudeApplied = true;
        //This condition is added to grab deviceId which is coming from hubilo.com for non login user
        const deviceIdAmplitude = decodeURIComponent(
            window.location.search.replace(
                new RegExp(
                    // eslint-disable-next-line no-useless-escape
                    "^(?:.*[&\\?]" + encodeURIComponent("deviceId").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$",
                    "i",
                ),
                "$1",
            ),
        );
        if (deviceIdAmplitude) {
            amplitude?.getInstance().init(envConfig.AMPLITUDE_API_KEY, null, {
                deviceId: deviceIdAmplitude,
                includeUtm: true,
                includeGclid: true,
                includeReferrer: true,
            });
        } else {
            amplitude?.getInstance().init(envConfig.AMPLITUDE_API_KEY);
        }
    }
};



