import { ITicketData } from "./LandingPage";
import { IAuthDetails } from "./BuyerDetails";

export interface ITicketDetailsResponse {
  enableSingleTicketTypePurchase?: boolean;
  hideFreeLabel?: boolean;
  tickets: ITicketData[];
  isbuyerForm: boolean;
  showGoToEventCTA: boolean;
  goToEventCtaLabel: string;
  isGdprComplianceEnabled: boolean;
  gdprComplianceConsentText: string | null;
  isBuyerForm?: boolean;
  isSimpleRegistrationEnabled?: boolean;
  gdprLinks?: any;
  stripeAccountId: string | null;
}

export interface IHeadersRequest {
  authorization: string;
  language: number;
  trackingPixelApps?: any;
}

export const ALREADY_BOUGHT_ERROR = "ALREADY_BOUGHT_ERROR";
export const NEW_USER = "NEW_USER";
export const MULTIPLE_TICKETS_ALLOWED = "MULTIPLE_TICKETS_ALLOWED";
export const LIMITED_ACCESS_ERROR = "LIMITED_ACCESS_ERROR";
export const PRF_PND = "PRF_PND";
export const APRVL_PND = "APRVL_PND";
export const APRVL_DND = "APRVL_DND";

// TICKET ALREADY PURCHASED

export interface CheckTicketPurchasedLandingPageRequest extends IAuthDetails {
  email: string;
  ticketId: string;
}

export interface CheckTicketPurchasedLandingPageResponse {
  status?: boolean;
  success?: {
    code?: string;
    message?: string;
    data?: {
      alreadyPurchased?: boolean;
      resend?: string;
    };
  };
  error?: Error;
}
export interface CheckTicketPurchasedLandingPageResponseData {
  alreadyPurchased?: boolean;
  resend?: string;
}
