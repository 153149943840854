// libraries
import axios, { Cancel, Method } from "axios";

// helpers
// import { END_POINTS, protectedTrue } from "Constants/API";
import { iFileType } from "../Types/BuyerDetails";
import { GlobalEventEmitter } from "./EventEmitter";
import { client } from "../Core/HttpClient";
import envConfig from "config";
// constants
// const { getSignInLink } = END_POINTS;

// interfaces
export interface TUrlItem {
  uploadURL: string;
  fileName: string;
  updateProgress?: (value: number) => void;
  height?: number;
  width?: number;
}

export interface TS3UploadOption {
  fileConfig?: TUrlItem;
  contentType?: string;
  progressCB?: (val: number) => void;
}
export interface TSignInOptions {
  size?: number;
  skipUpload?: boolean;
  progressEmmiter?: (val: number) => void;
}

interface TFilePayload {
  name: string;
  type: string;
  size: number;
  lastModified: number;
}

interface TFilePayloadReturn {
  extension: string;
  contentType: string;
  fileName: string;
}

export interface IFileUploadResponse {
  fileURL: string;
}

interface TS3Config {
  method: Method;
  url: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUploadProgress?: (event: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  headers?: { "Content-Type": string };
}

export interface TFileSelect {
  name: string;
  type: string;
  lastModified: number;
  size: number;
}

export interface TFileCallBack {
  [size: string]: string;
}

export const fileDownloadHandler = (
  filename: string,
  organisationId: string | number
): void => {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
  if (organisationId)
    window.open(
      `${envConfig.BASE_CDN_URL}brochure/${organisationId}/${filename}`,
      "_blank"
    );
};

export const fileDownloadHandlerWithLink = (link: string): void => {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
  if (link !== "") window.open(`${link}`, "_blank");
};

type TFileOption = {
  width: number | string;
  height: number | string;
};
export interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;
  throwIfRequested(): void;
}
interface TCustomHeader {
  authToken: string;
}

export interface IOptions {
  isProtected?: boolean;
  apiStore?: Map<string, () => void>;
  customHeaders?: TCustomHeader;
  token?: string;
}

export interface TSuccess<T> {
  code?: string;
  message?: string;
  data?: T;
}

export type NetworkResponse<T> = {
  readonly status: boolean;
  readonly error?: TSuccess<T>;
  readonly success?: TSuccess<T>;
};
interface TCustomHeader {
  authToken: string;
}
export interface NetworkRequest<T> {
  url: string;
  method: Method;
  data?: T;
  params?: Record<string, unknown>;
  cancelToken?: CancelToken;
  customHeaders?: TCustomHeader;
}

interface TSelfNetworkRequest<T> {
  url: string;
  method: Method;
  data?: {
      payload: { data: T };
  };
  params?: Record<string, unknown>;
}

export interface NetworkAuthRequest<T> extends TSelfNetworkRequest<T> {
  headers?: { Authorization: string; source: string; language?: number };
}
export const EXTRACT_REGEX = /(?:\.([^.]+))?$/;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function blobToFile(theBlob: any, fileName: string, options?: TFileOption) {
  const { width, height } = options || {};
  // eslint-disable-next-line no-param-reassign
  theBlob.lastModifiedDate = new Date();
  // eslint-disable-next-line no-param-reassign
  theBlob.name = fileName;
  // eslint-disable-next-line no-param-reassign
  if (width) theBlob.width = width;
  // eslint-disable-next-line no-param-reassign
  if (height) theBlob.height = height;
  return theBlob;
}

const getMimeType = (imageAsBase64: string): string => {
  const arr = imageAsBase64.split(",");
  const mimeEle = arr && Array.isArray(arr) && arr.length ? arr[0] : "";
  const mimeArr = mimeEle ? mimeEle.match(/:(.*?);/) : [];
  const mime =
    mimeArr && Array.isArray(mimeArr) && mimeArr.length && mimeArr[1]
      ? mimeArr[1]
      : "";
  return mime;
};

export const dataURLtoFile = (
  dataurl: string,
  filename: string,
  options?: TFileOption
): Blob => {
  const arr = dataurl.split(",");
  const mime = getMimeType(dataurl);
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return blobToFile(new Blob([u8arr], { type: mime }), filename, options);
};

export const uploadFileS3 = (
  fileConfig: TUrlItem,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  file: any,
  contentType: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  cb: any
): void => {
  const {
    uploadURL = "",
    fileName = "",
    width = "",
    updateProgress,
  } = fileConfig;

  const newFile = new File([file], fileName, { type: contentType });
  let config: TS3Config = {
    method: "PUT",
    url: uploadURL,
    data: new Blob([newFile], { type: contentType }),
  };

  if (updateProgress) {
    config = {
      ...config,
      onUploadProgress: (progressEvent): void => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        updateProgress(percentCompleted);
      },
    };
  }

  if (contentType === "video/mp4" || contentType === "image/png") {
    config = {
      ...config,
      headers: {
        "Content-Type": contentType,
      },
    };
  }
  axios(config)
    .then(() => {
      if (cb) {
        if (width) cb({ [width]: fileName });
        else cb(fileName);
      }
    })
    .catch((error) => {
      if (cb) cb("FAIL");
      // eslint-disable-next-line no-console
      console.log(error);
    });
};

export const getFileUploadDetails = (
  file: TFilePayload
): TFilePayloadReturn => {
  const { name = "", type = "" } = file;
  const returnData: TFilePayloadReturn = {
    extension: "",
    contentType: "",
    fileName: "",
  };
  if (name) returnData.fileName = name;
  if (name)
    returnData.extension =
      name.substring(name.lastIndexOf(".") + 1, name.length) || name;
  if (type) returnData.contentType = type;
  return returnData;
};

/**
 * @T : Request Body Type
 * @R : Response Body type
 */
 export async function protectedRequest<T, R>(
  request: NetworkRequest<T>,
  accessToken?: string,
  language?: number,
  // token: string
  // dispatch: Dispatch
): Promise<NetworkResponse<R>> {
  // eslint-disable-next-line no-useless-catch
  try {
      const copyRequest = {
          ...request,
          data: { payload: { data: request.data } },
      } as TSelfNetworkRequest<T>;
      if (accessToken) {
          (copyRequest as NetworkAuthRequest<T>).headers = {
              Authorization: accessToken,
              source: "COMMUNITY",
              ...(language && { language }),
          };
      }
      const { data } = await client().request<NetworkResponse<R>>(copyRequest);
      return data;
  } catch (e: any) {
      if (e && e.status === 401) {
          GlobalEventEmitter.dispatch("hubilo-401", true);

          throw e.data;
      } else throw e;
  }
}

export interface IFileUpload {
  appendData: {
    extension: string;
    contentType: string;
    fileName: string;
  };
  token: string;
  progressEmmiter: (val: number) => void;
}

export const generateAllowTypes = (fileTypes: iFileType[]): string[] => {
  let allowedType: string[] = [];
  fileTypes.forEach((ele) => {
    if (ele.value) {
      switch (ele.name) {
        case "PDF":
          allowedType = [...allowedType, ".pdf"];
          break;
        case "IMAGE":
          allowedType = [...allowedType, ".png"];
          allowedType = [...allowedType, ".jpg"];
          allowedType = [...allowedType, ".jpeg"];
          break;
        case "DOC":
          allowedType = [...allowedType, ".doc"];
          allowedType = [...allowedType, ".docx"];

          break;
        case "PPT":
          allowedType = [...allowedType, ".ppt"];
          allowedType = [...allowedType, ".pptx"];
          break;
        case "EXCEL":
          allowedType = [...allowedType, ".xlsx"];
          allowedType = [...allowedType, ".xls"];
          allowedType = [...allowedType, ".csv"];
          break;

        default:
          break;
      }
    }
  });
  return allowedType;
};
