import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

// Interfaces
import { IPaymentDescription } from "../Types/BuyerDetails";
import { isTicketAmountFloat } from "Utils/common";

// Custom Interfaces
interface ICompProps {
  isSummary?: boolean;
  currencySymbol?: string;
  payments: IPaymentDescription[];
}

const TicketPaymentSummary = ({
  isSummary,
  payments = [],
  currencySymbol = "",
}: ICompProps) => {
  const [t] = useTranslation();
  if (!isSummary) return <></>;

  const isPaymentAvailable =
    payments && Array.isArray(payments) && payments.length;

  const getPaymentGrossAmount = (amounts: IPaymentDescription[]) => {
    if (isPaymentAvailable) {
      const returnData = [] as any;
      amounts.forEach((element: IPaymentDescription) => {
        if (element.type && element.type !== "NET") {
          const isGrossEle = element.type && element.type === "GROSS";
          const isDiscEle = element.type && element.type === "DISCOUNT";

          if (isGrossEle) {
            returnData.push(
              <Box display="flex" justifyContent="space-between" mt={1.4}>
                <Typography
                  component="label"
                  sx={(theme: any) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    fontSize:"14px !important"
                  })}
                >
                  {element?.label}
                </Typography>
                <Typography
                  sx={(theme: any) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    fontSize:"14px !important"
                  })}
                  component="label"
                >
                  {element.amount < 0
                    ? `-${currencySymbol}${isTicketAmountFloat(Math.abs(element.amount))}`
                    : `${currencySymbol}${isTicketAmountFloat(element.amount)}`}
                </Typography>
              </Box>
            );
          } else if (isDiscEle) {
            returnData.push(
              <Box display="flex" justifyContent="space-between" mt={1.4}>
                <Typography
            
                  component="label"
                  sx={(theme: any) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    fontSize:"14px !important"
                  })}
                >
                  {element?.label}
                </Typography>
                <Typography
                  component="label"
                  sx={(theme: any) => ({fontSize:"14px", color: theme.palette.success.main })}
                >
                  {element.amount < 0
                    ? `-${currencySymbol}${isTicketAmountFloat(Math.abs(element.amount))}`
                    : `${currencySymbol}${isTicketAmountFloat(element.amount)}`}
                </Typography>
              </Box>
            );
          } else {
            returnData.push(
              <Box display="flex" justifyContent="space-between" mt={1.4}>
                <Typography
                  component="label"
                  sx={(theme: any) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    fontSize:"14px !important"
                  })}
                >
                  {element?.label}
                </Typography>
                <Typography
                  sx={(theme: any) => ({
                    color: theme.palette.text.secondary,
                    fontWeight: 400,
                    fontSize:"14px !important"
                  })}
                  component="label"
                >
                  {element.amount < 0
                    ? `-${currencySymbol}${isTicketAmountFloat(Math.abs(element.amount))}`
                    : `${currencySymbol}${isTicketAmountFloat(element.amount)}`}
                </Typography>
              </Box>
            );
          }
        }
      });

      return [true, <>{returnData}</>];
    }
    return [false, <></>];
  };

  const getPaymentNetAmount = (amounts: IPaymentDescription[]) => {
    const getNetIndex = amounts.findIndex(({ type = "" }) => type === "NET");
    if (getNetIndex > -1) {
      const netAmount = amounts[getNetIndex];
      const component = (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1.4}
          sx={(theme: any) => ({
            borderTop: `1px solid ${theme.palette.divider.default}`,
            paddingTop: 1.5,
          })}
        >
          <Typography
            component="label"
            sx={(theme) => ({
              fontWeight: 600,
              color: theme.palette.text.secondary,
              fontSize:"14px !important"
            })}
          >
            {netAmount.label}{" "}
          </Typography>
          <Typography
            component="label"
            sx={(theme: any) => ({
              fontWeight: 600,
              color: theme.palette.text.secondary,
              fontSize:"14px !important"
            })}
          >
            {`${currencySymbol}${isTicketAmountFloat(netAmount.amount)}`}
          </Typography>
        </Box>
      );
      return [true, component];
    }
    return [false, <></>];
  };

  const [isPaymentGrossAmount, fetchPaymentGrossAmount] =
    getPaymentGrossAmount(payments);
  const [isPaymentNetAmount, fetchPaymentNetAmount] =
    getPaymentNetAmount(payments);

  return (
    <Box margin={2}>
      {isPaymentGrossAmount ? (
        <Box>
          <Typography
            component="h5"
            color="textPrimary"
            sx={(theme: any) => ({
              borderTop: `1px solid ${theme.palette.divider.default}`,
              paddingTop: 2,
              marginTop: 2,
              fontSize:"18px !important"
            })}
          >
            {t("payment-summary")}
          </Typography>
          <Box className="mt10">{fetchPaymentGrossAmount}</Box>
        </Box>
      ) : (
        false
      )}

      {isPaymentAvailable && isPaymentNetAmount ? (
        <>{fetchPaymentNetAmount}</>
      ) : (
        false
      )}
    </Box>
  );
};

export default TicketPaymentSummary;
