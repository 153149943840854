import { Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Button from "@mui/material/Button";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import useOrderTickets from "Hooks/useOrders";
import { IHeadersRequest } from "Types/Ticket";
import { useStore } from "Context";
import useResendLink from "Hooks/useResendLink";
import { IResendEmailRequest, IResendEmailResponse } from "Types/BuyerForm";
import { TOAST_TIMEOUT } from "Constants/AppConstants";
import { IVerifyPaymentStatus, IVerifyPaymentStatusRequest } from "Types/VerifyStatus";
import usePaymentStatus from "Hooks/usePaymentStatus";
import isEmpty from "lodash/isEmpty";
import { PAYMENT_METHOD_DTLS } from "Components/PaymentMethod";
import { ICurrentOrder } from "Types/SubmitPayment";
import StringParser from "Components/StringParse";

dayjs.extend(relativeTime);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ height: "100%" }}
    >
      {value === index && (
        children
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const TicketConfirmStatus = ({ configData }: any): JSX.Element => {
  const [value, setValue] = React.useState(0);
  const [clickedItemsStatus, setClickedItemsStatus] = React.useState("");
  const { store, setToast, setOrderNo, setOrderToken, setOtherPaymentModal, setPaymentStatusDetails, setOtherPaymentDetails } = useStore();
  const { buyerEmail } = store;
  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();
  const {
    data: resendLinkData,
    resendLink,
    // loading: resentLinkLoading,
    setData: resetResentData,
  } = useResendLink();
  const [t] = useTranslation();

  const { data } = useOrderTickets({
    authorization: configData?.accessToken,
    language: configData?.language,
  } as IHeadersRequest,
      store?.buyerEmail || "");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleResendLink = () => {
    resendLink(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: {
            buyerEmail: buyerEmail,
          },
        },
      } as IResendEmailRequest
    );
  };
  useEffect(() => {
    if (resendLinkData) {
      setToast({
        type: "success",
        message: t("mail-successfully-sent"),
      });
      setTimeout(() => {
        resetResentData(false as IResendEmailResponse);
      }, TOAST_TIMEOUT - 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendLinkData]);
  const handleGoToEvent = () => {
    window.open(configData?.originalCommunityUrl || configData?.communityUrl, "_blank");
};

const {
  data: paymentStatusVerifyData,
  error: paymentStatusVerifyError,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  paymentStatus,
  // loading: paymentStatusVerifyLoading,
} = usePaymentStatus();

const sharePaymentDetailsCB = ({ orderNo, orderToken }: ICurrentOrder) => {

  const payload: IVerifyPaymentStatus = {
    orderToken,
  };

  if (orderNo) setOrderNo(orderNo);
  if (orderToken) setOrderToken(orderToken);

   paymentStatus(
     {
       authorization: configData?.accessToken,
       language: configData?.language,
     } as IHeadersRequest,
     {
       payload: {
         data: { ...payload },
       },
     } as IVerifyPaymentStatusRequest
   );
};

const handleToastError = (error: string) => {
  setToast({
    message: error,
    type: "error",
  });
};

useEffect(() => {
  if (!isEmpty(paymentStatusVerifyData) && paymentStatusVerifyData?.paymentMethod) {
    setPaymentStatusDetails(paymentStatusVerifyData);
    setOtherPaymentDetails({...store.otherPaymentDetails, 
      selectedPaymentMethod: NEW_PAYMENT_METHOD_DTLS[paymentStatusVerifyData?.paymentMethod]
    });

    if (paymentStatusVerifyData?.type === "APRVL_PND"){
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: true,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
      });
    } else {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: true,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
      });
    }

    setClickedItemsStatus("");
  }
  if (paymentStatusVerifyError) {
    setClickedItemsStatus("");
    handleToastError(
      typeof paymentStatusVerifyError === "string"
        ? paymentStatusVerifyError
        : t("something-went-wrong")
    );
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [paymentStatusVerifyData, paymentStatusVerifyError, clickedItemsStatus]);

  return (

    <Box sx={{ pt: 2.5, height: "calc((var(--vh, 1vh) * 100) - 7rem)", "@media(max-width:640px)": { pt: 7, height: "100%" } }}>
      <Typography sx={{ px: 2.5, fontWeight: 700, "@media(max-width:640px)": { px: 2 } }} component="h3" variant="h2">{t("PREVIOUS_TICKETS")}</Typography>
      <Typography sx={{ px: 2.5, "@media(max-width:640px)": { px: 2 } }} component="p" variant="body2" color="textSecondary">{buyerEmail}</Typography>
      <Box mt={3}>
        <Tabs value={value} onChange={handleChange} sx={{ "& .MuiTab-root": { width: "50%", textTransform: "initial" } }} aria-label="basic tabs example">
          <Tab label={t("PENDING_TICKETS")} {...a11yProps(0)} />
          <Tab label={t("CONFIRMED_TICKETS")} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ px: 2.5, pb: 2.5, overflowY: "auto", height: "calc(100% - 150px)", overflowX: "hidden", "@media(max-width:640px)": { height: "calc(100% - 140px)", px: 2 } }}>
          {/* <PendingTicketLoader/> */}
          {data.pendingOrders.map((item, index) => (
            <Card key={index} sx={(theme: any) => ({ background: theme.palette.background.cardColor, boxShadow: "none", mt: 2.5, borderRadius: "6px", border: `1px solid ${theme.palette.divider.default}` })}>
              {/* @ts-ignore; */}
              {item?.tickets.map((tickets: any, i: number) => <CardContent sx={{ p: 0, "&:last-child": { pb: 0 } }} key={tickets.id + i}>
                <Box sx={(theme: any) => ({ p: 2, borderBottom: `1px solid ${theme.palette.divider.default}` })}>
                  <Typography color="textPrimary" variant="body1" component="label" sx={{ fontWeight: 700 }}>{tickets.title}</Typography>
                  <Typography color="textSecondary" variant="caption" component="p" sx={{ fontWeight: 700, mt: "0px !important", mb: "0px !important" }} >Order id : {item.orderNo}</Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                  <Typography component="p" color="textSecondary" variant="body2" sx={{ fontWeight: 400, mt: "0px !important", mb: "0px !important" }}>{`${tickets.quantity}x tickets`}</Typography>
                  {/* <Typography component="p" color="textSecondary" variant="body2" sx={{fontWeight:400,mt:"0px !important",mb:"0px !important"}}>1x Premium Tickets</Typography>
                    <Typography component="p" color="textSecondary" variant="body2" sx={{fontWeight:400,mt:"0px !important",mb:"0px !important"}}>3x Free Tickets</Typography> */}
                  {item.status === "PRF_PND" && <Box mt={1.7} sx={(theme: any) => ({ "&>svg": { height: "20px", width: "20px", color: theme.palette.error.main }, display: "flex", alignItems: "center", borderRadius: "12px", background: theme.palette.error.light, p: 1.7, border: `1px solid ${theme.palette.error.main}` })}>
                    <ErrorOutlineOutlinedIcon />
                    <Typography variant="body2" component="span" sx={{ color: "#202020", ml: 1, fontWeight: 400 }}>{t("PAYMENT_PENDING")}</Typography>
                  </Box>}
                  {item.status === "APRVL_PND" && <Box mt={1.7} sx={(theme: any) => ({ "&>svg": { height: "20px", width: "20px", color: theme.palette.warning.main }, display: "flex", alignItems: "center", borderRadius: "12px", background: theme.palette.error.light, p: 1.7, border: `1px solid ${theme.palette.warning.main}` })}>
                    <ErrorOutlineOutlinedIcon />
                    <Typography variant="body2" component="span" sx={{ color: "#202020", ml: 1, fontWeight: 400 }}>{t("AWAITING_ORG_APPROVAL")}</Typography>
                  </Box>}
                  <Button variant="contained" sx={{ mt: 2, width: "100%" }} color="primary" onClick={()=>{
                    sharePaymentDetailsCB(item as ICurrentOrder);
                  }}>{item?.status === "APRVL_PND" ? t("CHECK_TICKET_STATUS") : t("SHARE_PAYMENT_DETAILS")}</Button>
                </Box>
              </CardContent>)}
            </Card>))}
        </Box>

      </TabPanel>

      <TabPanel value={value} index={1}>
        <Box sx={{ px: 2.5, pb: 2.5, overflowY: "auto", height: "calc(100% - 190px)", overflowX: "hidden", "@media(max-width:640px)": { height: "calc(100% - 220px)", px: 2 } }}>
          {/* <ConfirmTicketLoader/> */}
          {data.confirmedTickets.map((item, index) => (

            <Card key={index}
              variant="outlined"
              sx={(theme: any) => ({ background: theme.palette.background.cardColor, overflow: "visible", borderRadius: 2.5, mt: 2.5, boxShadow: "none", border: `1px solid ${theme.palette.divider.default}` })}
            >
              <CardContent sx={{ "&:last-child": { pb: 2.5 } }}>

                <Typography component="label" variant="body2" sx={(theme: any) => ({ fontWeight: 700, display: "block", mb: 1, color: `${theme.palette.text.primary} !important` })}>
                  {item.title}
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  sx={(theme) => ({
                    mb: "14px !important",
                    mt: "0px !important",
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                  })}
                >
                  {`${t("ORDERED")} ${dayjs(
                    item.purchasedAt
                  )?.fromNow()}`}
                </Typography>
                <Typography component="p" variant="caption" color="textPrimary" sx={{ fontWeight: 400, mb: "16px !important", mt: "0px !important" }}>
                  <StringParser color="textSecondary" htmlStr={item.description} />
                </Typography>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={(theme: any) => ({
                    pt: 2, borderTop: `1px dashed ${theme.palette.divider.default}`,
                    "&::before": {
                      content: "''",
                      width: "14px",
                      height: "24px",
                      backgroundColor: theme.palette.background.cardColor,
                      borderRadius: "50%",
                      position: "absolute",
                      left: "-21px",
                      top: "-13px",
                      borderTopRightRadius: "100px",
                      borderBottomRightRadius: "100px",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderLeft: 0,
                    },
                    "&::after": {
                      content: "''",
                      width: "14px",
                      height: "24px",
                      backgroundColor: theme.palette.background.cardColor,
                      borderRadius: "50%",
                      position: "absolute",
                      right: "-21px",
                      top: "-13px",
                      borderTopLeftRadius: "100px",
                      borderBottomLeftRadius: "100px",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderRight: 0,
                    }
                  })}
                >
                  <Typography
                    component="p"
                    variant="caption"
                    color="textPrimary"
                    sx={{ fontWeight: 400, mt: "0px !important", mb: "0px !important", }}
                  >
                    Ticket Quantity
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.primary.main, mt: "0px !important", mb: "0px !important" })}
                  >
                    {item.quantity}
                  </Typography>
                </Box>
              </CardContent>
            </Card>))}
          <Box sx={(theme: any) => ({
            mt: 2.5, display: "flex",
            alignItems: "center",
            justifyContent: "space-between", "&>button": { width: "48.5%" },
            position: "absolute",
            bottom: 0,
            left: 0,
            p: 2,
            width: "100%",

            "@media(max-width:640px)": {
              "&>button": { width: "100%", mb: 1, "&:last-child": { mb: 0 } },
              background: theme.palette.background.cardColor,

              display: "block",
              mt: 0,
            }
          })}>

            {data?.confirmedTickets?.length ? <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleResendLink()}
            >
              Resend Link
            </Button> : null}
            <Button
              variant="contained"
              color="primary"
              size="small"
              sx={!data.confirmedTickets?.length ? { textTransfrom: "initial" , width : "100% !important"} : {textTransfrom: "initial"}}
              onClick={() =>handleGoToEvent()}
            >
              Go to Event
            </Button>

          </Box>
        </Box>

      </TabPanel>
    </Box>
  );
};

export default TicketConfirmStatus;