import jwt_decode from "jwt-decode";

interface ITokenData {
  // eslint-disable-next-line @typescript-eslint/ban-types
  user?: {};
  event: {
    organiser_id: number;
    event_id: number;
    api_key: string;
  };
}

export function getOrganiserId(accessToken: string) {
  try {
    const tokenData: ITokenData = jwt_decode(accessToken);
    return tokenData?.event?.organiser_id;
  } catch {
    return 0;
  }
}

export function getEventId(accessToken: string) {
  try {
    const tokenData: ITokenData = jwt_decode(accessToken);
    return tokenData?.event?.event_id;
  } catch {
    return null;
  }
}