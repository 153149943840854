const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//  min 8 letter password, with at least a symbol, upper and lower case letters and a number
// const passwordRegEx = new RegExp(
//   "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
// );

// // eslint-disable-next-line no-useless-escape
// export const urlRegex =
//   /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gi;
// export const mailRegex =
//   /mailto:([\w-]+(.[\w-]+)@([a-z0-9-]+(.[a-z0-9-]+)?.[a-z]{2,6}|(\d{1,3}.){3}\d{1,3})(:\d{4})?)/;
// // eslint-disable-next-line no-useless-escape
// export const stringParserUrlRegex =
//   /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

// export const urlLinkRegex = /(https?:\/\/[^ ]*)/;

// // navigation tabs regex
// export const hideMobileMainHeaderRegex =
//   /^\/on-boarding|\/edit-profile|\/mybookmarks|\/mybriefcase|\/ticket-invoices|\/settings|\/exhibitor-center|\/engage\/.+|\/session\/.+|\/room\/.+|\/booth\/.+|\/lounge\/.+$/;
// export const sponsorAdsBlockRegex =
//   /^\/sessions|\/lounge|\/rooms|\/booths|\/engage|\/people$/;
// export const hideTabletNavigationRegex =
//   /^\/exhibitor-center|\/session\/.+|\/booth\/.+|\/lounge\/.+$/;
// export const hideMobileNavigationRegex =
//   /^\/exhibitor-center|\/edit-profile|\/mybookmarks|\/mybriefcase|\/ticket-invoices|\/ticket-view|\/settings|\/entry-contest|\/quiz-contest|\/topic-contest$/;

// export const hasVideoScreenRegex = /^\/session\/.+|\/lounge\/.+|\/room\/.+$/;
// export const isLoungeModule = /^\/lounge|\/lounge\/.+$/;

// // route filter regex
// export const filterRouteRegex = new RegExp("/([^/]+)$", "gm");

export default { emailRegEx };
