import * as React from "react";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";

import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

interface IChoices {
    label: string;
    selected?: boolean;
    value?: string;
    svg?:React.ReactNode;
    name?: string;
}
  

interface IWrapperProps {
    options?: IChoices[];
    value?: string;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }


const RadioGroupWithIcon = (props:IWrapperProps):JSX.Element => {
    const {
        options = [],
        value = "",// this value will match the string and mark it checked
        handleChange
    } = props;

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   console.log("(event.target as HTMLInputElement).value",(event.target as HTMLInputElement).value);
  //   setValue((event.target as HTMLInputElement).value);
  // };

  return (
    <FormControl sx={{width:"100%"}}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        sx={{width:"100%"}}
        onChange={handleChange}
      >
        {options?.map((option,i)=>(
        <Box key={i} sx={{width:"100%",px:2,pb:2,display:"flex",alignItems:"center"}}>
            <Box sx={(theme:any)=>({"&>svg":{color:theme.palette.text.primary},display:"flex",alignItems:"center",justifyContent:"center", height:35,width:35,minWidth:35,border:`1px solid ${theme.palette.divider.default}`,borderRadius:"50%"})}>
                {option.svg}
            </Box>
            <Box sx={(theme:any)=>({width:"calc(100% - 43px)"
            ,"&>.MuiFormControlLabel-root":{width:"100%",ml:2,justifyContent:"space-between","& .MuiTypography-root":{color:theme.palette.text.primary,fontWeight:700,fontSize:"14px !important"}}})}>
                <FormControlLabel value={option.name} sx={{"&>.MuiRadio-root":{ padding:"0px 8px","& .MuiSvgIcon-root":{height:"25px",width:"25px"}}}} labelPlacement="start" control={<Radio  />} label={option.label} />
            </Box>
        </Box>))}
       
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupWithIcon;
