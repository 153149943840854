import React, { useEffect,useState } from "react";
import get from "lodash/get";
import Box from "@mui/material/Box";
import { Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { ITicketInfoProps } from "../..";
import TicketTaxation from "./TicketTaxation";
import TaxationFields from "./TaxationFields";
import FileUploadWithError from "../FileUploadWithError";
import DropDown from "../DropdownWithError";
import Track from "../../Lib/ThirdParty/amplitude";
import {
  ITaxationDetails,
  ISetBuyerRequest,
  IBuyersSubmits,
  IRegistrationField,
  IRegion,
  TCountryRegion,
  TCompanyAuth,
  TCountryList,
} from "../../Types/BuyerDetails";
import TextField from "../InputField";
import TextAreaComponent from "../TextArea";
import produce from "immer";
import { NAME_FIELDS, BUYERS_DEFAULT_FIELDS } from "../../Constants/Ticketing";
import CheckBoxGroup from "../CheckBoxGroup";
import TextBoxAutoSelect from "../TextBoxAutoSelect";
import RadioButtonsGroup from "../RadioButtonsGroup";
import {
  getFormFieldsAuthenticate,
  getInvalidCheck,
  getNumberValidate,
  getTextValidate,
} from "../../Utils/FieldsValidation";
import {
  addSomePropertiesInField,
  updateFieldValue,
} from "../../Utils/FieldsPropertiesHelpers";
import CountryList from "../../Utils/regions";
import FlatPicker from "../FlatPicker";
import GroupComponent from "../GroupComponent";
import {
  getObjectElement,
  getParameterByName,
  getPhoneCode,
  isTrueValueArray,
} from "../../Utils/common";
import useBuyerFormSet from "../../Hooks/useBuyerFormSet";
import { IHeadersRequest, ITicketDetailsResponse } from "../../Types/Ticket";
import { isAndroidIPhoneIPad } from "../../Utils/navigator";
import useBuyerForm from "../../Hooks/useBuyerForm";
import BuyerAttendeeDetailLoader from "../Loaders/BuyerAttendeeDetailLoader";
import {
  fetchFieldStore,
  getDataAuthenticate,
  getFilterPhoneContact,
  getTaxationLabel,
  isFormAuthentic,
} from "./Helper";
import { useStore } from "../../Context";
import { IApplyDiscount, ITicketsInfo } from "../../Types/ApplyDiscount";
import { PageState } from "../../Constants/AppConstants";
import { IAttendeeFormSetRequest } from "../../Types/AttendeeFormSet";
import { useTranslation } from "react-i18next";
import StepperWithBackButton from "../StepperWithBackButton";
import { useStyles } from "./styles";
import useApplyDiscount from "Hooks/useApplyDiscount";
import { ITicketData } from "../../Types/LandingPage";


// Constants
const COUNTRIES: TCountryList[] = [...CountryList];

export type BuyerDetailFormProps = {
  className?: string;
  apiData?: any;
  accessToken?: any;
  configData?: any;
  isDudaIntegrate?:boolean;
  isCustomLP?: boolean;
  ticketData?: ITicketDetailsResponse;
  setKeepMounted: () => void;
  microFeEventHandler?: () => void;
};

const BuyerDetailsForm = ({
  className = "",
  apiData,
  accessToken,
  configData,
  isDudaIntegrate,
  ticketData,
  setKeepMounted,
  microFeEventHandler,
  isCustomLP = false,
}: BuyerDetailFormProps): JSX.Element => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { store, setToast, setAttendeeFormRequest, setBuyerFields, setBuyerBasicDetail } =
    useStore();
  const results = apiData?.fields && addSomePropertiesInField(apiData?.fields);
  const [profileFields, setProfileFields] = React.useState(results);
  const [isVisible, setIsVisible] = useState(true);
  const { buyerForm, setData, setSubmitLoading, isSubmitLoading } =
    useBuyerForm();
  
  const getToken = getParameterByName<number>("orderid");
  const getPaymentStatus = getParameterByName<string>("payment");
  const isSuccess = getParameterByName<string>("success");
  const isPaymentExists = isCustomLP ? false : Boolean(Boolean(isSuccess) || Boolean(getToken && getPaymentStatus));
  const [isCheckTaxation, setCheckTaxation] = React.useState<boolean>(false);
  const enableBuyerTaxId = apiData?.enableBuyerTaxId || false;

  const isTaxationExists = apiData?.isTaxationExists || false;
  const [companyDetails, setCompanyDetails] = React.useState<any>({
    address: { val: "", hasError: false, msg: "" },
    city: { val: "", hasError: false, msg: "" },
    country: { val: "", hasError: false, msg: "" },
    taxId: { val: "", hasError: false, msg: "" },
    name: { val: "", hasError: false, msg: "" },
    state: { val: "", hasError: false, msg: "" },
    zipCode: { val: "", hasError: false, msg: "" },
  });

  const fetchOrderToken = store?.discountData?.orderToken;
  const buyerEmail = store?.discountData?.buyerEmail;
  const { ticketList = [], pageState, orderToken } = store;
  const {
    applyDiscount,
  } = useApplyDiscount();

  const handleScroll = () => {
    
     setIsVisible(false);
     if(store?.widgetData?.isWidgetExpand){
        setTimeout(() => {
          setIsVisible(true);
        }, 300);
      } 
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  


  const onchangeHandler = (e: any, field: any) => {
    const updatedItems = profileFields.map((pField: any) =>
      pField?.id === field?.id
        ? {
            ...pField,
            value: field.fieldType === "phone" ? e : e.target.value,
            isError: false,
            labelHint: "",
      }
        : pField,
    );
    setProfileFields(updatedItems);
  };

  const onBlurTextHandler = (event: any, fieldData: any) => {
    const currVal = event.target.value;
    const fetchFields = [...profileFields];
    const isFieldsEmpty =
      fetchFields && Array.isArray(fetchFields) && fetchFields.length;
    const fieldIndex = isFieldsEmpty
      ? fetchFields.findIndex((field) => field.id && fieldData.id === field.id)
      : -1;
    if (fieldIndex > -1) {
      const field = { ...fetchFields[fieldIndex], value: currVal };
      if (field) {
        const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(
          field.fieldType
        );
        const { hasError, errMsg } = getTextValidate(
          isDefaultField ? { ...field, isDefaultField } : field
        );

        const updateFields = produce(fetchFields, (draft) => {
          draft[fieldIndex] = {
            ...draft[fieldIndex],
            value: currVal,
            isError: hasError,
            labelHint: errMsg,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };
  const handleUpdateDetails = (id: string, value: unknown) => {
    const getFields = updateFieldValue(profileFields, id, value);
    const isFieldExists =
      getFields && Array.isArray(getFields) && getFields.length;
    if (isFieldExists && typeof getFields !== "boolean") {
      setProfileFields([...getFields]);
    }
  };

  const onChangeFile = (id: string, value: string) => {
    handleUpdateDetails(id, value);
  };

  const onCheckboxChangeHandler = (id: string, value: string[]) => {
    handleUpdateDetails(id, value);
  };
  const onRadioChangeHandler = (id: string, value: string) => {
    handleUpdateDetails(id, value);
  };

  const handleOtherChange = (id: string, event: any) => {
    const initVal = event.target.value;
    const index = profileFields.findIndex(
      (ele: any) => String(ele.id) === String(id)
    );
    if (index > -1 && (initVal || initVal === "")) {
      const fetchType = get(profileFields[index], "type", "");
      if (["DROPDOWN", "RADIO", "CHECKBOX"].includes(fetchType)) {
        const loadField = {
          ...profileFields[index],
          otherValue: initVal || "",
          frontendData: {},
        };
        const authField = getFormFieldsAuthenticate(loadField);
        const hasError = get(authField, "hasError", false);
        const errMsg = get(authField, "errMsg", "");
        const errData = get(authField, "errData", []);

        const hasErrorData =
          errData && Array.isArray(errData) && errData.length;

        const fetchField = hasErrorData
          ? { ...loadField, isError: hasError, labelHint: errMsg, errData }
          : { ...loadField, isError: hasError, labelHint: errMsg };
        const updateFields = produce(profileFields, (draft) => {
          draft[index] = { ...fetchField };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onChangeCountry = (id: string, value: any, key: string) => {
    const isStateIndex = profileFields.findIndex(
      (fieldType: any) => fieldType && fieldType === "state"
    );
    let stateId = "";
    if (isStateIndex > -1) {
      const stateField = profileFields[isStateIndex];
      stateId = stateField.id;
    }
    const updateValue = typeof value === "object" && key ? value[key] : value;
    let getFields = updateFieldValue(profileFields, id, updateValue);
    if (stateId && typeof getFields !== "boolean")
      getFields = updateFieldValue(getFields, stateId, "");
    const isFieldExists =
      getFields && Array.isArray(getFields) && getFields.length;
    if (isFieldExists && typeof getFields !== "boolean") {
      setProfileFields([...getFields]);
    }
  };

  const onDateChange = (id: string, value: any) => {
    handleUpdateDetails(id, value);
  };

  const onChangeGroupHandler = (id: string, value: any): void => {
    handleUpdateDetails(id, value);
  };

  const generatePayload = (fields: IRegistrationField[]): ISetBuyerRequest => {
    const fieldsValue: IBuyersSubmits[] = [];
    fields.forEach((field) => {
      const pushDetails = fetchFieldStore(field);
      if (
        pushDetails &&
        typeof pushDetails === "object" &&
        !Array.isArray(pushDetails)
      ) {
        if (pushDetails.value) fieldsValue.push(pushDetails);
      }
      if (pushDetails && Array.isArray(pushDetails) && pushDetails.length) {
        fieldsValue.push(...pushDetails);
      }
    });

    const requestPayload: ISetBuyerRequest = {
      orderToken: fetchOrderToken || "",
      values: fieldsValue,
    };
    if (isTaxationExists && isCheckTaxation) {
      const taxation: ITaxationDetails = {
        companyName: companyDetails.name.val,
        companyAddress: companyDetails.address.val,
        companyCountry: companyDetails.country.val,
        companyState: companyDetails.state.val,
        companyCity: companyDetails.city.val,
        companyZipCode: companyDetails.zipCode.val,
      };
      if (enableBuyerTaxId) {
        taxation["companyTaxId"] = companyDetails.taxId.val;
      }
      requestPayload.taxationDetails = taxation;
    }
    return requestPayload;
  };

  const handleSubmitFields = () => {
    const fetchFields = [...profileFields];
    const payload = generatePayload(fetchFields);
    setBuyerBasicDetail({
      buyerFirstName: payload?.values.find((X) => X?.form_field_id === "1")?.value,
      buyerLastName: payload?.values.find((X) => X?.form_field_id === "2")?.value,
      buyerEmail: payload?.values.find((X) => X?.form_field_id === "3")?.value,
  });
    buyerForm(
      { authorization: accessToken, language: configData?.language },
      {
        payload: {
          data: {
            ...payload,
          },
        },
      }
    );
    const ids = store?.discountData?.ticketsInfo.map((item) => item.id);
    setAttendeeFormRequest(PageState.AttendeeDetails, {
      ticketingLink: store.widgetData?.isWidgetExpand ? configData?.communityUrl:`${configData?.communityUrl}/register`,
      orderToken: store?.discountData && store?.discountData?.orderToken,
      ticketId: ids,
      isSimpleRegistrationEnabled: store?.registrationToggle
    } as IAttendeeFormSetRequest);
    setBuyerFields(profileFields);
  };

  const handleTaxationAuthentication = (): TCompanyAuth => {
    let isAuthSuccess = true;
    let updateCompany = { ...companyDetails };
    if (isTaxationExists && isCheckTaxation) {
      for (const key in updateCompany) {
        if (updateCompany[key]) {
          const { val: value } = updateCompany[key];
          const val = value?.trim();
          let isFailed = !val;
          if (key === "taxId" && !enableBuyerTaxId) isFailed = false;
          if (isFailed) {
            isAuthSuccess = false;
            updateCompany = {
              ...updateCompany,
              [key]: {
                ...updateCompany[key],
                hasError: true,
                msg: t(getTaxationLabel(key)),
              },
            };
          }
          if (val) {
            const { isAuthField, fieldType = "" } = getDataAuthenticate(
              key,
              val
            );
            if (!isAuthField) {
              isAuthSuccess = false;
              updateCompany = {
                ...updateCompany,
                [key]: {
                  ...updateCompany[key],
                  hasError: true,
                  msg: getInvalidCheck(fieldType),
                },
              };
            }
          }
        }
      }
    }
    setCompanyDetails({ ...companyDetails, ...updateCompany });
    return { isTaxAuth: isAuthSuccess, updateCompany };
  };

  const trackAddBuyerDetails = async () => {
    const amplitudePayload = {
      email: buyerEmail || "",
      numberOfFieldsEncountered: profileFields.length,
      numberOfFieldsEntered: profileFields.filter(
        (field: { value: number }) => field.value
      ).length,
      numberOfMandatoryFields: profileFields.filter(
        (field: { isMandatory: number }) => field.isMandatory
      ).length,
    };
    Track.addBuyerDetails(amplitudePayload);
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const handleContinueClick = () => {
    const { isAuth, fields = [] } = isFormAuthentic(profileFields);
    const { isTaxAuth }: TCompanyAuth = handleTaxationAuthentication();
    trackAddBuyerDetails();
    setSubmitLoading(true);
    // eslint-disable-next-line no-constant-condition
    if (isAuth && isTaxAuth) {
      handleSubmitFields();
    } else {
      handleToastError(t("please-check-all-the-fields-are-correctly-filled"));
      setSubmitLoading(false);
      if (fields && Array.isArray(fields) && fields.length) {
        setProfileFields([...fields]);
      }
      setSubmitLoading(false);
    }
  };

  const onBlurField = (id: any, currField: any, pId: any) => {
    const fIndex = profileFields.findIndex(
      (field: any) => String(field.id) === String(id)
    );
    if (fIndex > -1) {
      const isArray = isTrueValueArray(currField);
      if (isArray) {
        const fetchField = { ...profileFields[fIndex] };
        const errData = getObjectElement(fetchField, "errData", []);
        const updateErrData = produce(errData, (draft) => {
          currField.forEach((item: any) => {
            if (item.isError && item.labelHint) {
              draft[pId] = { [id]: item.labelHint };
            } else {
              if (errData[pId] && errData[pId][id]) draft[pId] = undefined;
            }
          });
        });

        const updateFields = produce(profileFields, (draft) => {
          draft[fIndex] = {
            ...fetchField,
            children: [...currField],
            errData: updateErrData,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onChangeSelect = (id: string, value: any, key: string) => {
    const updateValue = typeof value === "object" && key ? value[key] : value;
    handleUpdateDetails(id, updateValue);
  };

  const onBlurPhone = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string | number
  ): void => {
    const currVal = event.target.value;
    const fetchFields: IRegistrationField[] = [...profileFields];
    const isFieldsEmpty =
      fetchFields && Array.isArray(fetchFields) && fetchFields.length;
    const fieldIndex = isFieldsEmpty
      ? fetchFields.findIndex(
          (field) => field.id && String(id) === String(field.id)
        )
      : -1;

    if (fieldIndex > -1) {
      const initValue = get(profileFields[fieldIndex], "value", "");
      const [prevDialer] = getPhoneCode(initValue);
      const finalContactNo = getFilterPhoneContact(
        String(prevDialer),
        String(currVal)
      );
      const field = {
        ...fetchFields[fieldIndex],
        value: finalContactNo,
      };
      if (field) {
        const { hasError, errMsg } = getNumberValidate(field);
        const updateFields = produce(fetchFields, (draft) => {
          draft[fieldIndex] = {
            ...draft[fieldIndex],
            value: finalContactNo,
            isError: hasError,
            labelHint: errMsg,
          };
        });
        setProfileFields([...updateFields]);
      }
    }
  };

  const onRegionSelect = (region: IRegion, id: string | number) => {
    const initVal = get(region, "dial_code", "");
    const index = profileFields.findIndex(
      (ele: any) => String(ele.id) === String(id)
    );
    if (index > -1 && initVal) {
      const initValue = get(profileFields[index], "value", "");
      const [, prevNumber] = getPhoneCode(initValue);

      const fetchField = {
        ...profileFields[index],
        isDefaultField: true,
        value: `${initVal}-${prevNumber}`,
      };
      const { hasError, errMsg } = getNumberValidate(fetchField);
      const updateFields = produce(profileFields, (draft) => {
        draft[index] = { ...fetchField, isError: hasError, labelHint: errMsg };
      });
      setProfileFields([...updateFields]);
    }
  };

  const generateFieldUI = () => {
    const isCountryExists = profileFields?.findIndex(
      ({ fieldType = "" }) => fieldType && fieldType === "country"
    );

    return profileFields?.map((field: any, index: number) => {
      const {
        id = -1,
        label = "",
        type = "",
        fieldType = "",
        isMandatory,
        isVisible,
        minLength,
        maxLength,
        otherValue = "",
        isError,
        labelHint = "",
        regex = "",
      } = field;

      const isNameFields: string | boolean =
        fieldType && NAME_FIELDS.includes(fieldType);
      const fieldValue = get(field, "value");
      const value = fieldValue || "";

      const WrapperClassName = isNameFields ? "" : "";

      const choices = get(field, "choices", []);
      const showOtherOption = get(field, "showOtherOption", false);
      const showBlankOption = get(field, "showBlankOption", false);
      let updateOptions = [...choices];
      if (choices && Array.isArray(choices)) {
        if (showOtherOption) {
          updateOptions = produce(updateOptions, (draft: any) => {
            draft.push({ label: "Other", selectedByDefault: false });
          });
        }

        if (showBlankOption) {
          updateOptions = produce(updateOptions, (draft: any) => {
            draft.unshift({ label: "None", selectedByDefault: true });
          });
        }
      }

      if (["TEXT", "NUMBER"].includes(type)) {
        const isEmail: string | boolean = fieldType === "email";
        const isDisable: boolean = isEmail && value ? true : false;
        const isNumber: boolean = type === "NUMBER";
        const isMobile: boolean = isNumber && fieldType === "phone";

        const isDefaultField: boolean =
          BUYERS_DEFAULT_FIELDS.includes(fieldType);
        const multiLangLabel = isDefaultField ? t(fieldType) : label;

        if (isMobile) {
          const [phoneCode, phoneNumber] = getPhoneCode(value);
          return (
            <Grid item xs={12} key={index}>
              <TextBoxAutoSelect
                id={String(id)}
                key={index}
                placeHolder={multiLangLabel}
                textLabel={`${multiLangLabel}`}
                withoutRadius="mobileTextField"
                name={multiLangLabel}
                countryCode={String(phoneCode)}
                value={String(phoneNumber)}
                textType={"text"}
                position="top"
                className="inputText mobiInput"
                iconColor="themecolor"
                iconAlign="start"
                bgColor="bgColor"
                errorMsg={labelHint}
                testId="mobileNumber"
                onSelect={(region: any) => onRegionSelect(region, id)}
                onBlur={(e: any) => onBlurPhone(e, id)}
                hintText={labelHint}
                errorType={isError ? "error" : ""}
                otherLabelKey={"dial_code"}
                maxLength={maxLength}
                isCharLimit={maxLength && maxLength > 0}
                charLimit={maxLength - String(phoneNumber).length}
                isMandatory={isMandatory}
                onChange={(e) => {
                  const val = `${phoneCode}-${e.target.value}`;
                  onchangeHandler(val, field);
                }}
              />
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} lg={isEmail ? 12 : multiLangLabel == "Text" || multiLangLabel == "Number"  ? 12 : 6} key={index}>
              <Box key={index} className={WrapperClassName}>
                <TextField
                  key={index}
                  regex={regex}
                  id={String(id)}
                  type={isEmail ? "email" : isNumber ? "number" : "text"}
                  name={multiLangLabel}
                  required={isMandatory}
                  label={multiLangLabel}
                  placeHolder={multiLangLabel}
                  disabled={isEmail || isDisable}
                  value={value.toString()}
                  errorMsg={labelHint}
                  onChange={(e) => {
                    onchangeHandler(e, field);
                  }}
                  onBlur={(e: any) => onBlurTextHandler(e, field)}
                  endIcon={
                    maxLength
                      ? (value.length > 0
                          ? maxLength - value.length
                          : maxLength
                        ).toString()
                      : null
                  }
                  maxLength={maxLength}
                />
              </Box>
            </Grid>
          );
        }
      }

      if (type === "DATE") {
        return (
          <Grid item xs={12} key={index}>
            <Box key={index} className={WrapperClassName}>
              <TextField
                key={id}
                id={String(id)}
                value={value}
                required={isMandatory}
                label={label}
                name={label}
                placeHolder={t("date-format")}
                type="text"
                icon
                endIcon={
                  <FlatPicker
                    options={{ dateFormat: "d/m/Y" }}
                    onChange={(date, val) => onDateChange(String(id), val)}
                    btnClass
                  />
                }
                errorMsg={labelHint}
              />
            </Box>
          </Grid>
        );
      }

      if (type === "GROUP") {
        const customGroupValueArray = get(field, "customGroupValueArray", []);
        const isShowAddButton = get(field, "isShowAddButton", false);
        const buttonLabel = get(field, "buttonLabel", "");
        const fieldTypeName = get(field, "fieldTypeName", "");
        const errData = get(field, "errData", []);

        const fieldComp = (
          <Grid item xs={12} key={index}>
            <Box key={index} className={`${WrapperClassName}`}>
              <GroupComponent
                key={index}
                id={String(id)}
                value={customGroupValueArray}
                label={`${label}`}
                isMandatory={isMandatory}
                onChange={(id, val) => onChangeGroupHandler(id, val)}
                onBlur={(id: any, field: any, pId: any) =>
                  onBlurField(id, field, pId)
                }
                isVisible={isVisible}
                required={isMandatory}
                fields={field.children}
                errData={errData}
                hasError={isError}
                errMsg={labelHint}
                minLength={minLength}
                maxLength={maxLength}
                isShowAddButton={isShowAddButton}
                buttonLabel={buttonLabel}
                fieldTypeName={fieldTypeName}
              />
            </Box>
          </Grid>
        );
        return fieldComp;
      }

      if (type === "TEXTAREA") {
        return (
          <Grid item xs={12} key={index}>
            <Box key={index} className={WrapperClassName}>
              <TextAreaComponent
                key={index}
                id={String(id)}
                name={label}
                required={isMandatory}
                label={label}
                placeholder={label}
                value={value.toString()}
                onChange={(e: any) => {
                  onchangeHandler(e, field);
                }}
                errorMsg={labelHint}
                onBlur={(e: any) => onBlurTextHandler(e, field)}
                isCharLimit={!!(maxLength && maxLength > 0)}
                charLimit={maxLength - value.toString().length}
                maxLength={maxLength}
              />
            </Box>
          </Grid>
        );
      }

      if (type === "RADIO") {
        const isVisible = get(field, "isVisible", false);
        const showOtherOption = get(field, "showOtherOption", false);
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onRadioChangeHandler(String(id), initValue);
        }

        return (
          <Grid item xs={12} key={index}>
            <Box className={`${WrapperClassName}`}>
              <RadioButtonsGroup
                key={String(id)}
                id={String(id)}
                label={label}
                name={label}
                isVisible={isVisible}
                options={[...updateOptions]}
                value={initValue}
                handleChange={(id, value) => onRadioChangeHandler(id, value)}
                hasError={isError}
                errMsg={labelHint}
                otherValue={otherValue}
                isMandatory={isMandatory}
                hasOther={showOtherOption}
                handleOtherChange={(id, e) => handleOtherChange(id, e)}
              />
            </Box>
          </Grid>
        );
      }

      if (type === "CHECKBOX") {
        const isVisible = get(field, "isVisible", false);
        const valueOptions = get(field, "valueOptions", []);

        const defaultSelectedChoices = Array.isArray(choices)
          ? choices
              .filter((choice) => choice.selectedByDefault)
              .map((choice) => choice.label)
          : [];

        let initValue = valueOptions;
        const hasValue =
          initValue && Array.isArray(initValue) && initValue.length;
        if (defaultSelectedChoices.length && !hasValue) {
          initValue = defaultSelectedChoices.filter(
            (value, index, self) => self.indexOf(value?.trim()) === index
          );
          onCheckboxChangeHandler(String(id), initValue);
        }

        return (
          <Grid item xs={12} key={index}>
            <Box className={`${WrapperClassName}`}>
              <CheckBoxGroup
                key={String(id)}
                id={String(id)}
                label={label}
                isVisible={isVisible}
                value={initValue}
                handleChange={(id, value) => onCheckboxChangeHandler(id, value)}
                options={[...updateOptions]}
                hasError={isError}
                errMsg={labelHint}
                otherValue={otherValue}
                handleOtherChange={(id, e) => handleOtherChange(id, e)}
                isMandatory={isMandatory}
              />
            </Box>
          </Grid>
        );
      }

      if (type === "FILE") {
        const maxFileSize = get(field, "maxFileSize", 0);
        const fileTypes = get(field, "fileTypes", []).map((fileType:any) =>{
          return {...fileType,value:true};
        });
        const isDisabledField = get(field, "isDisabledField", false);

        return (
          <Grid item xs={12} key={index}>
            <Box key={index} className={`${WrapperClassName}`}>
              <FileUploadWithError
                key={index}
                id={String(id)}
                accessToken={accessToken}
                value={value}
                label={label}
                onChange={(val: string) => onChangeFile(String(id), val)}
                maxFileSize={maxFileSize}
                fileTypes={fileTypes}
                isVisible={isVisible}
                isRequired={isMandatory}
                hasError={isError}
                errMsg={labelHint}
                disabled={isDisabledField}
              />
            </Box>
          </Grid>
        );
      }

      if (type === "DROPDOWN" && fieldType === "country") {
        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              placeHolder={t("country-place-holder")}
              className={classes.fullWidth}
              isMandatory={isMandatory}
              value={value}
              otherValue={otherValue}
              label={label}
              name="countryName"
              valueKey="countryName"
              labelKey="countryName"
              options={[...COUNTRIES]}
              errorMsg={labelHint}
              onChange={onChangeCountry}
              onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
            />
          </Grid>
        );
      }

      if (type === "DROPDOWN" && fieldType === "state") {
        const fetchCountry = get(profileFields[isCountryExists], "value", "");
        const fetchRegions = COUNTRIES.find(
          ({ countryName = "" }) => countryName && countryName === fetchCountry
        );
        const regions: TCountryRegion[] = get(fetchRegions, "regions", []);
        if (regions && Array.isArray(regions) && isCountryExists !== -1) {
          return (
            <Grid item xs={12} key={index}>
              <DropDown
                key={id}
                id={String(id)}
                label={label}
                isMandatory={isMandatory}
                value={value}
                otherValue={otherValue}
                name="name"
                placeHolder={t("state-place-holder")}
                valueKey="name"
                labelKey="name"
                options={[...regions]}
                onChange={onChangeSelect}
                onChangeOther={(id: any, e: any) => handleOtherChange(id, e)}
              />
            </Grid>
          );
        }
      }

      if (type === "DROPDOWN" && fieldType === "custom") {
        const defaultOptIndex = Array.isArray(choices)
          ? choices.findIndex((item) => item && item.selectedByDefault)
          : -1;

        let initValue = value;
        if (defaultOptIndex > -1 && !initValue) {
          initValue = get(choices[defaultOptIndex], "label", "");
          onChangeSelect(String(id), initValue, "label");
        }

        return (
          <Grid item xs={12} key={index}>
            <DropDown
              key={id}
              id={String(id)}
              label={label}
              name="label"
              isMandatory={isMandatory}
              value={initValue}
              otherValue={otherValue}
              labelKey="label"
              valueKey="label"
              options={updateOptions}
              errorMsg={labelHint}
              isError={isError}
              labelHint={labelHint}
              placeHolder={"Select"}
              position={index < 4 ? "top" : "bottom"}
              onChange={(val) => {
                onChangeSelect(String(id), val, "label");
              }}
              // eslint-disable-next-line no-console
              onChangeOther={(e: any) => {
                handleOtherChange(String(id), e);
              }}
            />
          </Grid>
        );
      }

      if (type === "LINK") {
        return (
          <Grid item xs={12} key={index}>
            <Box key={index} className={WrapperClassName}>
              <TextField
                key={id}
                id={String(id)}
                name={label}
                value={value}
                label={label}
                required={isMandatory}
                placeHolder={label}
                errorMsg={labelHint}
                onChange={(e) => {
                  onchangeHandler(e, field);
                }}
                onBlur={(e: any) => onBlurTextHandler(e, field)}
              />
            </Box>
          </Grid>
        );
      }
    });
  };

  const handleTaxationField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckTaxation(e.target.checked);
  };

  useEffect(() => {
    return () => {
      setData({ message: "" });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTicketInfoPayload = (
    items: ITicketData[],
    isComplete = false
  ): ITicketsInfo[] => {
    const paidTicketPresent = items && items?.find((data) => !!data.selectedQuantity && data.ticketFee > 0);
    const { registrationToggle } = store;
    return items
      .filter(
        (eachTicket: ITicketData) => (eachTicket?.selectedQuantity || 0) > 0
      )
      .map((ticket: ITicketData) => {
        const { selectedQuantity = 0, id, promoCode = "" } = ticket;
        return {
          id,
          quantity: selectedQuantity,
          coupon: promoCode,
          isApplied: !!promoCode,
          isComplete: !registrationToggle && !paidTicketPresent  ? true : isComplete,
        };
      });
  };

  useEffect(() => {
    if (pageState === PageState.BuyDetails) {
      (async () => {
        try {
          const ticketsInfo = getTicketInfoPayload(ticketList);
          const payload = {
            payload: {
              data: {
                orderToken,
                ticketsInfo,
              } as IApplyDiscount,
            },
          };
          if (!isPaymentExists) {
            applyDiscount(
              {
                authorization: configData?.accessToken,
                language: configData?.language,
              } as IHeadersRequest,
              { ...payload }
            );
          }
        } catch (e) {
          // console.log(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState]);

  return (
    <>
      <Box className={`${classes.buyerDetailsWrapper} ${className} ${store?.widgetData?.isWidgetExpand ? classes.widgetBuyerDetails:false} ${store.startFromSummary ? classes.landingBuyerDetails :false} singleBuyerWidgetDetails`}>
        <StepperWithBackButton ticketData={ticketData} setKeepMounted={setKeepMounted} microFeEventHandler={microFeEventHandler}/>
        <Typography
          component="h2"
          variant="h2"
          color="textPrimary"
          sx={{ mt:"24px !important", mb: "20px !important", textAlign: "left", fontWeight:"600 !important",
          px:store.startFromSummary? "20px !important":0,
          "@media(max-width:640px)":{
            px:"0px !important",
            mt:"16px !important",
          }  
         }}
        >
          {t("buyer-info")}
        </Typography>
        <Box onScroll={handleScroll} className={`${classes.scrollingWrapper} widgetBuyerScrolling landingScrollingWrapper ${isDudaIntegrate ? !store.widgetData?.isWidgetExpand ? "dudaBuyerScroll":"":""}`}>
          <Grid container spacing={2}>
            {generateFieldUI()}
            {isTaxationExists ? (
              <>
                <TicketTaxation
                  isChecked={isCheckTaxation}
                  onChange={handleTaxationField}
                  languageId={configData?.language}
                  complianceLink={ticketData?.gdprLinks}
                />
                {isCheckTaxation ? (
                  <TaxationFields
                    classes={classes}
                    enableBuyerTaxId={enableBuyerTaxId}
                    companyDetails={companyDetails}
                    setCompanyDetails={setCompanyDetails}
                  />
                ) : (
                  false
                )}
              </>
            ) : (
              false
            )}
          </Grid>
        </Box>
        <Box className="singleBuyerWidgetFormButton">
          <Box className={`continueBtn ${isVisible ? "scrollstop":"scrollContinue"} ${isDudaIntegrate ? !store.widgetData?.isWidgetExpand ? "dudaBuyerContinueBtn":"":""}`}>
            <Button
              variant="contained"
              size="large"
              sx={{ width: "100%" }}
              fullWidth
              onClick={handleContinueClick}
              disabled={isSubmitLoading}
            >
              {t("continue")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

type IBuyerDetailsProps = ITicketInfoProps & {
  ticketData: ITicketDetailsResponse;
  setKeepMounted: () => void;
  microFeEventHandler?: () => void;
};

export default function BuyerDetails(props: IBuyerDetailsProps) {
  const classes = useStyles();
  const { configData, ticketData, setKeepMounted, microFeEventHandler } = props;
  const { store } = useStore();
  const { data, loading = true } = useBuyerFormSet(
    {
      authorization: configData?.accessToken,
      language: configData?.language,
    } as IHeadersRequest,
    {
      payload: {
        data: store?.discountData as IApplyDiscount,
      },
    }
  );

  const buyerDetailsFormProps = {
    apiData: data,
    accessToken: configData?.accessToken,
    configData,
    ticketData,
    isDudaIntegrate:  Boolean(props?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage),
    setKeepMounted,
    microFeEventHandler
  };

  return (
    <>
      {loading ? (
        <Box px={2} pt={15}><BuyerAttendeeDetailLoader /></Box>
      ) : isAndroidIPhoneIPad() ? (
          <Box
            height="100%"
            sx={() => ({
              borderRadius: "8px",
            })}
          >
            <BuyerDetailsForm
              className={classes.mobFooterStyle}
              {...buyerDetailsFormProps}
              isCustomLP={Boolean(props?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage)}
            />
          </Box>
      ) : (
        <BuyerDetailsForm {...buyerDetailsFormProps} />
      )}
    </>
  );
}