import Modal from "@mui/material/Modal";
import React, { useEffect,useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import makeStyles from "@mui/styles/makeStyles";
import { isAndroidIPhone, isAndroidIPhoneIPad, isIPad, isIPhone } from "../navigator";
import { useStore } from "Context";

const useStyles = makeStyles((theme: any) => ({

 
  modal: {
    "& .MuiBackdrop-root": {
      zIndex: -1,
    },
    "& .modalContent": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      background: theme.palette.background.cardColor,
      borderRadius: "6px",
      zIndex: 1000000999,
      "&:focus-visible":{
        outline:"none",
      },
      "&:focus":{
        outline:"none",
      },
      "@media (max-width: 1024px)": {
        top: 0,
        right: 0,
        height: "100%",
        width: "500px",
        transform: "translate(0%, 0%)",
        left: "auto",
        borderRadius: 0,
      },
      "&.autoHeigthModalContent":{
        "@media (max-width: 640px)": {
          height: "auto",
          top:"50%",
          transform:"translateY(-50%)",
          width:"95%",
          margin:"0 auto",
          borderRadius:"6px",
          boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.25)",
        },
        "& .autoHeigthModalClose":{
          "@media (max-width: 640px)": {
            bottom:"-40px",
            boxShadow:"0px 4px 20px rgba(0, 0, 0, 0.25)",

            left:"50%",
            top:"auto",
            transform:"translateX(-50%)",
          }, 
        },
      },
      "@media (max-width: 640px)": {
        position: "relative",
        top: "auto",
        right:"auto",
        left: "auto",
        height: "100%",
        width: "100%",
        transform: "none",
        borderRadius: 0,
      },
    },
  },
  modalScroll: {
    overflow: "auto",
    "& .modalContent": {
      overflow:"initial",
      top:"40px",
      left:"50%",
      transform:"translate(-50%,0px)",
      "@media (max-width: 1024px)": {
        top: 0,
      },
      "@media (max-width: 600px)": {
        top: 0,
        height: "auto",
        minHeight:"100%",
      },
    },
  },
  mobScrollOff:{
    "@media(max-width:640px)":{
      overflow:"hidden",
      "& .modalContent": {
        height: "100%",
        minHeight:"100%",
      }
    }
    
  },
  closeButtonWrapper: {
    right: "auto",
    top: 15,
    left: 15,
    "@media (min-width: 1025px)": {
      left: "auto",
      right: -35,
      top: -1,
    },
  },
  closeTab: {
    "@media(max-width:1024px)": {
      right: "auto",
      left: "10px",
      top: "15px",
    },
  },
}));
interface IModalContainerProp {
  children?: React.ReactNode;
  handleClose?: (event: React.SyntheticEvent) => void;
  open?: boolean;
  isOnlyChildren?: boolean;
  restrictFE?: boolean;
  onRequestClose?: (event: React.SyntheticEvent) => void;
  isScrollModal?: boolean;
  className?: string;
  closeIcon?: boolean;
  onChange?: (open: boolean) => void;
  offsetFromTop?: number;
  modalWidth?:number;
  scrollnotRequiredMob?:boolean;
  autoHeigthModalContent?:boolean;
  customWidth?:number;
}
const CustomModal = ({
  open,
  isOnlyChildren,
  restrictFE = false,
  isScrollModal,
  closeIcon = true,
  className,
  handleClose,
  onRequestClose,
  // autoHeigthModalContent,
  children,
  // modalWidth,
  scrollnotRequiredMob,
  customWidth
}: IModalContainerProp): JSX.Element => {
  const { store } = useStore();
  const isMobile = isAndroidIPhoneIPad();
  const classes = useStyles();
  const [height, setHeight] = useState<number>(window.outerHeight);
  const [safariHeight, setSafariHeight] = useState<number>(window.innerHeight);
  const isCustomStyle = Boolean(store?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage && restrictFE);

  
  useEffect(() => {
    setHeight(window.outerHeight);
    setSafariHeight(window.innerHeight);
    const handleResize = () => {
      setHeight(window.outerHeight);
      setSafariHeight(window.innerHeight);
    };
    window.addEventListener("resize",handleResize,true);
    return () => {
      window.removeEventListener("resize",handleResize,true);
    };
  },[]);

  useEffect(() => {
    const handleScroll = () => {
      setHeight(window.outerHeight);
      setSafariHeight(window.innerHeight);
    };
    window.addEventListener("scroll",handleScroll,true);
    return () => {
      window.removeEventListener("scroll",handleScroll,true);
    };
  },[]);
 
  useEffect(() => {
    // Closing the Ticketing MFE Popup
    if (open) {
      if (isCustomStyle) {
        // DO NOTHING
      } else {
        const ticketingMFECloseIcon: HTMLElement = document.querySelector(
          ".dmPopupClose"
        ) as HTMLElement;
        if (ticketingMFECloseIcon instanceof HTMLElement) {
          ticketingMFECloseIcon.click();
        }
      }
    }
  }, [isCustomStyle, open]);

  const getCustomModalStyle = {
      height:isIPhone() || isIPad() ? safariHeight : isAndroidIPhoneIPad() || isAndroidIPhone() ? height: "100%",
  };
  // @ts-ignore
  if (isCustomStyle) getCustomModalStyle["zIndex"] = "1111111111";

  return (
    <Modal
      open={open as boolean}
      className={`${className} ${classes.modal} ${
        isScrollModal ? classes.modalScroll : false
      } ${scrollnotRequiredMob ? classes.mobScrollOff:false} `}
      style={getCustomModalStyle}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box className="modalContent" sx={{ width :customWidth ? customWidth : "550px" }}>
        {closeIcon ? (
          <Box
            className={`${isMobile ? `${classes.closeButtonWrapper}` : ""} ${
              classes.closeTab
            } autoHeigthModalClose`}
            sx={(theme: any) => ({
              position: "absolute",
              right: -35,
              top: -1,
              background: theme.palette.background.cardColor,
              borderRadius: "50%",
              zIndex: 1,
              "&>button": {
                "&>svg": {
                  color: theme.palette.text.primary,
                },
              },
            })}
          >
            <IconButton
              color="primary"
              size="small"
              sx={(theme: any) => ({
                border: `1px solid ${theme.palette.divider.default}`,
                height:"30px !important",
                width:"30px !important",
              })}
              onClick={onRequestClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          false
        )}
        {isOnlyChildren ? children : false}
      </Box>
    </Modal>
  );
};

export default CustomModal;
