export const upperCase = (string: string) => {
  return string.toUpperCase().replace(" ", "").trim();
};

export const getPhoneCode = (val: string): string[] => {
  if (val) {
    const numberSplits = val.split("-");
    if (numberSplits && Array.isArray(numberSplits) && numberSplits.length) {
      return [numberSplits[0], numberSplits[numberSplits.length - 1]];
    }
  }

  return ["", ""];
};

export const updateAttendeeUsers = (data: any) => {
  let obj: any = {};
  const { ticketId, users } = data;
  obj = {
    [ticketId]: users,
  };
  return obj;
};

export const updateUserField = (data: any, users: any) => {
  const {
    ticketId,
    attendeeIndex,
    fieldId,
    fieldValue,
    valueOptions,
    otherValue,
    fieldCustomGroupValueArray,
  } = data;

  users[ticketId][attendeeIndex] = users[ticketId][attendeeIndex].map(
    (field: any) => {
      if (field.id === fieldId) {
        return {
          ...field,
          value: fieldValue,
          customGroupValueArray: fieldCustomGroupValueArray,
          otherValue: otherValue || field.otherValue,
          valueOptions,
        };
      }
      return field;
    }
  );
  return users;
};

export const updateErrorFieldHelper = (data: any, users: any) => {
  const {
    ticketId,
    attendeeIndex,
    fieldId,
    hasError: isError,
    errMsg: labelHint,
    suggestion:suggestion,
    errData = [],
    isListed = 0,
  } = data;

  users[ticketId][attendeeIndex] = users[ticketId][attendeeIndex].map(
    (field: any) => {
      if (field.id === fieldId) {
        return {
          ...field,
          isError,
          labelHint,
          errData,
          suggestion,
          isListed,
        };
      }
      return field;
    }
  );
  return users;
};

export const updateGroupField = (data: any, users: any) => {
  const {
    ticketId,
    attendeeIndex,
    fieldId,
    fieldValue,
    otherValue,
    orderTicketId,
  } = data;

  const updateState = users[ticketId][attendeeIndex].map((field: any) => {
    if (field.id === fieldId) {
      return {
        ...field,
        customGroupValueArray: fieldValue,
        orderTicketId,
        otherValue,
      };
    }
    return field;
  });

  users[ticketId][attendeeIndex] = updateState;
};  

export const populateBuyerDataForAttendee = (data: any, users: any) => {
  const { ticketId, buyerFields, reset } = data;
  if (reset) {
    users[ticketId][0] = users[ticketId][0].map((field: any) => {
      return {
        ...field,
        value: "",
      };
    });
  } else {
    users[ticketId][0] = users[ticketId][0].map((field: any) => {
      const buyerFieldValue = buyerFields.find(
        ({ fieldType }: any) => fieldType === field.fieldType
      )?.value;
      return {
        ...field,
        value: buyerFieldValue,
      };
    }); //will not work if buyerfields and attendee fields do not match
  }
};
