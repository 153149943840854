import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Flatpickr from "react-flatpickr";
import CalendarIcons from "../Assets/Svg/CalendarIcons";
import "../Assets/flatPicker.css";
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme: any) => ({
  datePicker: {
    "& .flatpickr-calendar.hasTime .flatpickr-time": {
      border: `1px solid ${theme.palette.divider.default}`,
    },
    "& .flatpickr-calendar.arrowTop:before": {
      borderBottomColor: theme.palette.divider.default,
    },
    "& .flatpickr-calendar.arrowTop:after": {
      borderBottomColor: theme.palette.divider.default,
    },
    "& .flatpickr-calendar.arrowBottom:before": {
      borderTopColor: theme.palette.divider.default,
    },
    "& .flatpickr-calendar.arrowBottom:after": {
      borderTopColor: theme.palette.divider.default,
    },
    "& .flatpickr-months": {
      background: theme.palette.background.cardColor,
    },
    "& .flatpickr-months .flatpickr-month": {
      background: theme.palette.background.cardColor,
      color: theme.palette.text.primary,
      fill: theme.palette.text.primary,
    },
    "& .flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month":
      {
        color: theme.palette.text.primary,
        fill: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider.default}`,
      },
    "& .flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg":
      {
        color: theme.palette.text.primary,
      },
    "& .flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover":
      {
        color: theme.palette.text.secondary,
      },
    "& .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg":
      {
        fill: theme.palette.primary.main,
      },
    "& .flatpickr-current-month .numInputWrapper span.arrowUp:after": {
      borderBottomColor: theme.palette.text.primary,
    },
    "& .flatpickr-current-month .numInputWrapper span.arrowDown:after": {
      borderTopColor: theme.palette.text.primary,
    },
    "& .flatpickr-current-month .flatpickr-monthDropdown-months": {
      background: theme.palette.background.cardColor,
    },
    "& .flatpickr-current-month .flatpickr-monthDropdown-months:hover": {
      background: theme.palette.background.cardColor,
    },
    "& .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month":
      {
        backgroundColor: theme.palette.background.cardColor,
      },
    "& .flatpickr-weekdays": {
      background: theme.palette.background.cardColor,
    },
    "& span.flatpickr-weekday": {
      background: theme.palette.background.cardColor,
      color: theme.palette.text.secondary,
    },
    "& .dayContainer + .dayContainer": {
      boxShadow: `-1px 0 0 ${theme.palette.background.gray}`,
    },
    "& .flatpickr-day": {
      color: theme.palette.text.primary,
    },
    "& .flatpickr-day.today": {
      color: theme.palette.text.secondaryFontColor,
      backgroundColor: theme.palette.primary.main,
    },
    "& .flatpickr-day.today:hover, .flatpickr-day.today:focus": {
      borderColor: theme.palette.text.primary,
      background: theme.palette.background.cardColor,
      color: theme.palette.text.primary,
    },

    "& .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus":
      {
        background: theme.palette.background.cardColor,
        borderColor: theme.palette.background.cardColor,
      },

    "& .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1))":
      {
        boxShadow: `-10px 0 0 ${theme.palette.primary.main}`,
      },

    "& .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay":
      {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.text.secondaryFontColor,
      },

    "& .flatpickr-weekwrapper .flatpickr-weeks": {
      padding: `${theme.spacing(0)}  ${theme.spacing(1.5)}`,
      borderLeft: `1px solid ${theme.palette.divider.default}`,
    },
    "& .flatpickr-time": {
      background: theme.palette.background.cardColor,
    },
    "& .flatpickr-time input": {
      color: theme.palette.text.secondaryColor,
    },

    "& .flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus":
      {
        background: theme.palette.background.cardColor,
      },

    "& .flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm":
      {
        color: theme.palette.text.secondaryColor,
      },

    "& .flatpickr-time .numInputWrapper span.arrowUp:after": {
      borderBottomColor: theme.palette.divider.default,
    },
    "& .flatpickr-time .numInputWrapper span.arrowDown:after": {
      borderTopColor: theme.palette.divider.default,
    },

    "& .flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover":
      {
        color: theme.palette.text.primary,
      },

    "& .flatpickr-innerContainer": {
      background: theme.palette.background.cardColor,
    },

    "& .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) ":
      {
        boxShadow: `-10px 0 0 ${theme.palette.primary.main}`,
      },

    "& .flatpickr-day.week.selected": {
      borderRadius: 0,
      boxShadow: `-5px 0 0 ${theme.palette.primary.main}, 5px 0 0 ${theme.palette.primary.main}`,
    },

    "& .flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay":
      {
        color: theme.palette.text.secondaryLight,
      },
  },

  calendarInput: {
    height: "100%",
    width: 40,
    opacity: 0,
  },
  calenderWithIcon: {
    color: theme.palette.primary.main,
    height: 36,
    width: 36,
    top: "50%",
    left: "65%",
    transform: "translate(-50%, -50%)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>div": {
      // border: 2px solid var(--strokeColor);
      // border-radius: 50%;
      height: 30,
      width: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));

interface Helper {
  (selectedDates?: Date[], dateStr?: string): void;
}

type TValue = string | number;

export interface FlatPickerProps {
  defaultValue?: string;
  value?: TValue;
  onOpen?: Helper;
  onClose?: Helper;
  onMonthChange?: Helper;
  onYearChange?: Helper;
  onReady?: Helper;
  onValueUpdate?: Helper;
  onDayCreate?: Helper;
  onChange?: Helper;
  placeholder?: string;
  className?: string;
  // eslint-disable-next-line no-unused-vars
  inputRef?: any;
  btnClass?: boolean;
  flatPickerClassName?: string;
  options?: any;
  size?: string;
  tabIndex?: number;
}

interface TCustomInput {
  value?: string | number;
  defaultValue?: string;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  inputRef?: any;
  btnClass?: boolean;
  onClose: () => void;
  tabIndex?: number;
}

const CustomInput = ({
  value,
  defaultValue,
  placeholder,
  inputRef,
  btnClass,
  onClose,
  tabIndex,
}: TCustomInput): JSX.Element => {
  const classes = useStyle();
  return (
    <Box position="relative">
      <Box position="absolute" className={classes.calenderWithIcon}>
        <Box>
          <CalendarIcons width="20px" height="16px" />
        </Box>
      </Box>
      <input
        placeholder={placeholder}
        value={value}
        className={`${btnClass ? classes.calendarInput : false}`}
        data-input
        defaultValue={defaultValue}
        // onChange={() => {}}
        tabIndex={tabIndex}
        onClick={onClose}
        ref={inputRef}
      />
    </Box>
  );
};
const FlatPicker = ({
  defaultValue,
  value,
  onOpen,
  onClose,
  btnClass,
  onMonthChange,
  onYearChange,
  onReady,
  onValueUpdate,
  onDayCreate,
  onChange,
  placeholder,
  options,
  tabIndex,
}: FlatPickerProps): JSX.Element => {
  const flatRef = useRef<Flatpickr>(null);
  const handleOnOpen = (selectedDates?: Date[], dateStr?: string) => {
    // toggleActiveStatus(true);
    if (onOpen) onOpen(selectedDates, dateStr);
  };

  const handleOnClose = (dates: Date[], currentDateString: string) => {
    // toggleActiveStatus(false);
    if (onClose) onClose(dates, currentDateString);
  };
  const flatOptions: any = {
    ...options,
    static: true,
    position: "below",
    altInputClass: "ssssss",
    clickOpens: false,
  };
  const classes = useStyle();
  return (
    <Box className={classes.datePicker}>
      <Flatpickr
        ref={flatRef}
        data-input
        defaultValue={defaultValue}
        value={value}
        // className={'custom data'}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        onMonthChange={onMonthChange}
        onYearChange={onYearChange}
        onReady={onReady}
        onDayCreate={onDayCreate}
        onValueUpdate={onValueUpdate}
        onChange={onChange}
        options={flatOptions}
        placeholder={placeholder}
        render={(props, ref: any) => {
          return (
            <CustomInput
              value={props.value as TValue}
              btnClass={btnClass}
              defaultValue={props.defaultValue}
              placeholder={props.placeholder}
              inputRef={ref}
              tabIndex={tabIndex}
              onClose={() => {
                flatRef.current?.flatpickr.toggle();
              }}
            />
          );
        }}
      />
    </Box>
  );
};

export default FlatPicker;
