/* eslint-disable no-unused-expressions */
// libraries
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// MUI
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

// CONTEXTS
import { useStore } from "Context";
import { getEventDateRange } from "Utils/date";
import usePaymentStatus from "Hooks/usePaymentStatus";
import { APRVL_DND, APRVL_PND, IHeadersRequest } from "Types/Ticket";
import { IVerifyPaymentStatus, IVerifyPaymentStatusRequest } from "Types/VerifyStatus";
import isEmpty from "lodash/isEmpty";
import { PAYMENT_METHOD_DTLS } from "Components/PaymentMethod";
import StringParser from "Components/StringParse";

// CONFIG
dayjs.extend(relativeTime);

const SharePaymentDetails = ({ landingPageData, userData, configData }: any): JSX.Element => {
  // HOOKS
  const [t] = useTranslation();

  const {
    data: paymentStatusVerifyData,
    error: paymentStatusVerifyError,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    paymentStatus,
    // loading: paymentStatusVerifyLoading,
  } = usePaymentStatus();

  // STORE
  const { store, setOtherPaymentModal, setPaymentDetails, setPaymentStatusDetails,
    setOtherPaymentDetails, setTransactionId, setToast } = useStore();
  const orderEmail = store?.userDataLandingPage?.buyerEmail || store?.discountData?.buyerEmail || "";
  const pendingOrders = store?.userDataLandingPage?.tickets || store?.ticketStore;
  const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  const openTransactionDetail = () => {
    if (userData?.case === "APRVL_PND") {
      const payload: IVerifyPaymentStatus = {
        orderToken: store?.orderToken || "",
      };
      paymentStatus(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: { ...payload },
          },
        } as IVerifyPaymentStatusRequest
      );
    } else {
      setOtherPaymentModal({
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: true,
        returningUserModal: false,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(paymentStatusVerifyData) && paymentStatusVerifyData?.paymentMethod) {
      setPaymentDetails({
        isPurchase: true,
        currPaymentStatus: paymentStatusVerifyData?.type === APRVL_DND ? "FAIL" :
          paymentStatusVerifyData?.type === "PAID" ? "CONFIRM" : "",
      });
      setPaymentStatusDetails(paymentStatusVerifyData);
      if (paymentStatusVerifyData?.type === APRVL_PND) {
        setTransactionId(paymentStatusVerifyData?.paymentId);
        setOtherPaymentDetails({
          ...store.otherPaymentDetails,
          selectedPaymentMethod: NEW_PAYMENT_METHOD_DTLS[paymentStatusVerifyData?.paymentMethod]
        });
        setOtherPaymentModal({
          choosePaymentMethod: false,
          faqModal: false,
          orderStatus: true,
          paymentDetailModal: false,
          returningUserModal: false,
          sharePaymentDetails: false,
          ticketConfirmStatus: false,
        });
      }
    }
    if (paymentStatusVerifyError) {
      handleToastError(
        typeof paymentStatusVerifyError === "string"
          ? paymentStatusVerifyError
          : t("something-went-wrong")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatusVerifyData]);

  const generateUX = () => {
    //@ts-ignore;
    if (pendingOrders?.length) {
      //@ts-ignore;
      return pendingOrders?.map((current) => {
        return (
          <Card
            key={current.id}
            variant="outlined"
            sx={(theme: any) => ({
              background: theme.palette.background.cardColor,
              overflow: "visible",
              borderRadius: 2.5,
              mt: 2.5,
              boxShadow: "none",
              border: `1px solid ${theme.palette.divider.default}`,
            })}
          >
            <CardContent sx={{ "&:last-child": { pb: 2.5 } }}>
              <Typography
                component="label"
                variant="body2"
                sx={(theme: any) => ({
                  fontWeight: 700,
                  display: "block",
                  mb: 1,
                  color: `${theme.palette.text.primary} !important`,
                })}
              >
                {current.title}
              </Typography>
              <Typography
                component="p"
                variant="caption"
                sx={(theme) => ({
                  mb: "10px !important",
                  mt: "0px !important",
                  fontWeight: 400,
                  color: theme.palette.text.secondary,
                })}
              >
                {`${t("ORDERED")} ${dayjs(
                  Number(current?.purchasedTime)
                )?.fromNow()}`}
              </Typography>
              <Typography
                component="p"
                variant="caption"
                color="textPrimary"
                sx={{
                  lineHeight: 1.3,
                  fontWeight: 400,
                  mb: "16px !important",
                  mt: "0px !important",
                }}
              >
                <StringParser color="textSecondary" htmlStr={current?.description || ""} />
              </Typography>
              <Typography
                component="p"
                variant="caption"
                sx={(theme) => ({
                  mb: "16px !important",
                  mt: "0px !important",
                  fontWeight: 400,
                  color: theme.palette.text.secondary,
                })}
              >
                {`${t("event-date")} ${getEventDateRange(
                  landingPageData?.eventStartime as string,
                  landingPageData?.eventEndtime as string
                )}`}
              </Typography>
              {current?.quantity && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  position="relative"
                  sx={(theme: any) => ({
                    pt: 2,
                    borderTop: `1px dashed ${theme.palette.divider.default}`,
                    "&::before": {
                      content: "''",
                      width: "14px",
                      height: "24px",
                      backgroundColor: theme.palette.background.cardColor,
                      borderRadius: "50%",
                      position: "absolute",
                      left: "-21px",
                      top: "-13px",
                      borderTopRightRadius: "100px",
                      borderBottomRightRadius: "100px",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderLeft: 0,
                    },
                    "&::after": {
                      content: "''",
                      width: "14px",
                      height: "24px",
                      backgroundColor: theme.palette.background.cardColor,
                      borderRadius: "50%",
                      position: "absolute",
                      right: "-21px",
                      top: "-13px",
                      borderTopLeftRadius: "100px",
                      borderBottomLeftRadius: "100px",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderRight: 0,
                    },
                  })}
                >
                  <Typography
                    component="p"
                    variant="caption"
                    color="textPrimary"
                    sx={{
                      fontWeight: 400,
                      mt: "0px !important",
                      mb: "0px !important",
                    }}
                  >
                    {t("ticket-quantity")}
                  </Typography>
                  <Typography
                    component="p"
                    variant="body2"
                    sx={(theme) => ({
                      mt: "0px !important",
                      mb: "0px !important",
                      color: theme.palette.primary.main,
                    })}
                  >
                    {current?.quantity || ""}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>
        );
      });
    }
  };

  // RENDERERS

  // count variable apply only if more than one tickets
  //@ts-ignore
  const count = Boolean(pendingOrders?.length > 1);

  return (
    <Box
      sx={{
        height: count ? "auto" : "calc((var(--vh, 1vh) * 100) - 10rem)",
        pt: 2,
        overflow: "hidden",
        "@media(max-width:640px)": { pt: count ? 2 : 6, height: "auto" },
      }}
    >
      <Typography
        sx={{
          px: 2.5,
          fontWeight: 700,
          mt: "0px !important",
          mb: "0px !important",
          "@media(max-width:640px)": { px: 2 },
        }}
        component="p"
        variant="caption"
        color="textSecondary"
      >
        {orderEmail}
      </Typography>
      <Typography
        color="textPrimary"
        sx={{ px: 2.5, fontWeight: 700, "@media(max-width:640px)": { px: 2 } }}
        component="h3"
        variant="body1"
      >
        {userData?.case === "APRVL_PND" ? "The event organizer is yet to approve your ticket(s)." : t("PAYMENT_DETAILS_YET_TO_SHARE")}
      </Typography>

      <Box
        sx={{
          px: 2.5,
          pb: 2.5,
          overflowY: "auto",
          maxHeight: "calc(100% - 130px)",
          overflowX: "hidden",
          "@media(max-width:640px)": {
            height: count ? "auto" : "calc((var(--vh, 1vh) * 100) - 180px)",
            px: 2,
          },
        }}
      >

        {generateUX()}

        <Box
          sx={(theme: any) => ({
            mt: 2.5,

            width: "100%",
            p: count ? 0 : 2.5,
            "@media(max-width:640px)": {
              p: count ? 0 : 2,
              borderTop: count
                ? "none"
                : `1px solid ${theme.palette.divider.default}`,
            },
            background: theme.palette.background.cardColor,
            bottom: 0,
            position: count ? "relative" : "absolute",

            left: 0,
            display: "block",
          })}
        >
          <Button fullWidth variant="contained" color="primary" size="small" onClick={openTransactionDetail}>
            {userData?.case === "APRVL_PND" ? t("CHECK_TICKET_STATUS") : t("SHARE_PAYMENT_DETAILS")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SharePaymentDetails;