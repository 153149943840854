import get from "lodash/get";
import { BUYERS_DEFAULT_FIELDS } from "../../Constants/Ticketing";
import { IBuyersSubmits, IRegistrationField } from "../../Types/BuyerDetails";
import { getFormFieldsAuthenticate } from "../../Utils/FieldsValidation";

const validationRegex = /^[a-z0-9]+$/i;

type IDataAuthentication = {
  isAuthField: boolean;
  fieldType: string;
};

export const getDataAuthenticate = (
  id: string,
  value: string
): IDataAuthentication => {
  if (["taxId"].includes(id)) {
    const fetchField = id === "taxId" ? "taxId-label" : "zipcode-label";
    if (value)
      return {
        isAuthField: validationRegex.test(value),
        fieldType: fetchField,
      };
  }
  return { isAuthField: true, fieldType: "" };
};

export const getTaxationLabel = (key: string) => {
  switch (key) {
    case "name":
      return "company-error-msg";
    case "taxId":
      return "taxId-error-msg";
    case "address":
      return "address-error-msg";
    case "country":
      return "country-error-msg";
    case "state":
      return "state-error-msg";
    case "city":
      return "city-error-msg";
    case "zipCode":
      return "zipcode-error-msg";

    default:
      return "";
  }
};

export const getFilterPhoneContact = (dialer: string, phone: string) => {
  if (!dialer && !phone) return "";
  return `${dialer}-${phone}`;
};

export const fetchGroupValues = (groupValues = []): Map<string, string> => {
  const fieldMap: Map<string, string> = new Map();
  groupValues.forEach((group) => {
    if (group) {
      for (const key in group) {
        if (group[key]) {
          const isExist = fieldMap.get(key);
          if (isExist) {
            fieldMap.set(key, `${isExist}, ${group[key]}`);
          } else {
            fieldMap.set(key, group[key]);
          }
        }
      }
    }
  });

  return fieldMap;
};

export const mapFieldsWithPayload = (
  curMap: Map<string, string>,
  fields: IRegistrationField[]
): IBuyersSubmits[] => {
  const returnData: IBuyersSubmits[] = [];
  if (fields && Array.isArray(fields) && fields.length) {
    fields.forEach((field: IRegistrationField) => {
      const isExist = curMap.get(String(field.id));
      if (isExist) {
        returnData.push({
          form_field_id: String(field.id),
          label: field.label,
          value: isExist,
        });
      }
    });
  }
  return returnData;
};

export const fetchFieldStore = (field: any) => {
  const { id = "", label, type = "", value } = field;
  if (type === "GROUP") {
    const groupValues: [] = get(field, "customGroupValueArray", []);
    const subFields: IRegistrationField[] = get(field, "children", []);
    let valuesArr: IBuyersSubmits[] = [];
    if (groupValues && Array.isArray(groupValues) && groupValues.length) {
      const fieldMap = fetchGroupValues(groupValues);
      valuesArr = mapFieldsWithPayload(fieldMap, subFields);
    }
    if (valuesArr && Array.isArray(valuesArr) && valuesArr.length) {
      return valuesArr;
    }
  } else if (type === "CHECKBOX") {
    let values = get(field, "valueOptions", []);
    const isArrayOfValues: boolean = values && Array.isArray(values);
    const otherValue = get(field, "otherValue", "");
    if (isArrayOfValues && otherValue) values = [...values, otherValue];
    if (values && Array.isArray(values) && values.length) {
      return {
        form_field_id: String(id),
        label,
        value: values.join(",").replace("Other,", ""),
      };
    }
  } else if (["RADIO", "DROPDOWN"].includes(type)) {
    const otherValue = get(field, "otherValue", "");
    if (value || otherValue) {
      return {
        form_field_id: String(id),
        label,
        value: value && value !== "Other" ? value : otherValue,
      };
    }
  } else {
    if (value) {
      return {
        form_field_id: String(id),
        label,
        value,
      };
    }
    return { form_field_id: "", label: "", value: "" };
  }
};

export const isFormAuthentic = (profileFields: any) => {
  const fetchFields = [...profileFields];
  const isFieldsEmpty =
    fetchFields && Array.isArray(fetchFields) && fetchFields.length;
  let isAuth = true;
  if (isFieldsEmpty) {
    const updateFields = fetchFields.map((field) => {
      const fieldType = get(field, "fieldType", "");
      const isDefaultField: boolean = BUYERS_DEFAULT_FIELDS.includes(fieldType);
      const fetchAuth = getFormFieldsAuthenticate(
        isDefaultField ? { ...field, isDefaultField } : field
      );
      const hasError = get(fetchAuth, "hasError", false);
      const errMsg = get(fetchAuth, "errMsg", "");
      const errData = get(fetchAuth, "errData", []);
      if (hasError) {
        isAuth = false;
      }
      if (errData && Array.isArray(errData) && errData.length) {
        return { ...field, isError: hasError, labelHint: errMsg, errData };
      }
      return { ...field, isError: hasError, labelHint: errMsg };
    });
    return { isAuth, fields: updateFields };
  }

  return { isAuth, fields: fetchFields };
};
