import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme:any) => ({
    paymentWrapper:{
        "@media(max-width:640px)":{
            padding:"16px 12px 16px 16px",
        }
    },
    ticketingWrapperMethod:{
        height:"100%",
         "& .ticketWidgetMethodWithoutError":{
            maxHeight:"calc(100% - 280px)",
            paddingRight:theme.spacing(1),
            "@media(max-width:640px)":{
                paddingRight:theme.spacing(1),
                
                maxHeight:"calc(100% - 340px)",
    
            }
         },
        "& .ticketWidgetMethod":{
            maxHeight:"calc(100% - 200px)",
            paddingRight:theme.spacing(1),
            "@media(max-width:640px)":{
                paddingRight:theme.spacing(1),
                
                maxHeight:"calc(100% - 220px)",
    
            }
      },
    },
    wrapperBlock:{
        marginTop:theme.spacing(3),
        overflowX:"hidden",
        overflowY:"auto",
        "@media(max-width:640px)":{
            paddingRight:theme.spacing(1),
            maxHeight:"calc(100% - 300px)",

        }
    },
    landingWrapperMethod:{
        height:"calc((var(--vh, 1vh) * 100) - 0rem)",
        "& .landingMethodWithoutError":{
            maxHeight:"calc(100% - 300px)",
            padding:"20px 16px 20px 20px",
            marginTop:theme.spacing(2),
            "@media(max-width:640px)":{
                padding:0,
                paddingRight:theme.spacing(1),
                maxHeight:"calc(100% - 300px)",
            },
        },
        "& .landingMethod":{
            padding:"20px 16px 20px 20px",
            marginTop:theme.spacing(2),
            maxHeight:"calc(100% - 220px)",
            "@media(max-width:640px)":{
                padding:0,
                paddingRight:theme.spacing(1),
                maxHeight:"calc(100% - 220px)",
    
            }
        },
    },
    builderWrapperMethod:{
     "& .builderMethod":{
        maxHeight:"calc((var(--vh, 1vh) * 100) - 38.4rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 38.4rem)",
        "&~.builderAction":{
            position:"relative",
            padding:0,
            paddingTop:theme.spacing(2),
            "@media(max-width:640px)":{
                position: "absolute",
                padding:theme.spacing(2),
            }
        },
        "@media(max-width:640px)":{
            maxHeight:"calc((var(--vh, 1vh) * 100) - 35.2rem)",
        minHeight:"calc((var(--vh, 1vh) * 100) - 35.2rem)",
        }
      }
    }
}));