import React, { Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: any) => ({
  termandpolicy: {
    color: theme.palette.text.primary,
    fontSize: "12px !important",
    lineHeight: 1.8,
    "& a": {
      color: `${theme.palette.primary.main} !important `,
      textDecoration: "none !important",
      fontSize: "14px !important",
    },
    "& p": {
      fontSize: "12px !important",
      marginTop: "0px !important",
      marginBottom: "0px !important",
      margin: 0,
      lineHeight: "20px",
    },
  },
}));

interface ITnC {
  checked: boolean;
  setChecked: Dispatch<SetStateAction<boolean>>;
  consent: string;
}

const WebinarTermsAndConditions = (props: ITnC): React.ReactElement => {
  const classes = useStyles();
  const { checked, setChecked, consent } = props;
  return (
    <Box sx={{ textAlign: "left" }} mb={0.5} mt={2}>
        <FormControlLabel
        sx={{
            textAlign: "left",
            alignItems: "start",
            "& .MuiCheckbox-root": {
            padding: "1px 10px !important",
            textAlign: "left !important",
            "&>input": {
                minWidth: 0,
                width: 0,
            },
            "&>svg": {
                height: "20px",
                width: "20px",
            },
            },
            "& .MuiTypography-root": {
            fontSize: 12,
            fontWeight: 400,
            "&>span": {
                display: "block",
            },
            "& a": {
                fontSize: "12px !important",
            },
            },
        }}
        control={<Checkbox checked={checked} onChange={()=> setChecked(!checked)} disableRipple value="iAgreeTnC" size="small" />}
        label={
            <span className={classes.termandpolicy} dangerouslySetInnerHTML={{ __html: consent }} />
        }
        />
    </Box>
  );
};

export default WebinarTermsAndConditions;
