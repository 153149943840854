// Language: German

export default {
    "already-registered": "Bereits registriert",
    "add-to-calendar": "Zum Kalender hinzufügen",
    "event-starts-in": "Veranstaltung beginnt in",
    "event-is-live": "VERANSTALTUNG IST LIVE",
    "event-has-ended": "VERANSTALTUNG IST BEENDET",
    "powered-by": "Bereitgestellt von",
    "please-enter-your-email-to-continue": "Bitte geben Sie Ihre E-Mail ein, um fortzufahren",
    add: "Hinzufügen",
    offers: "Bietet an",
    free: "Kostenlos",
    "available-till": "Verfügbar bis {{DATE}}",
    "enter-email-address": "E-Mail Adresse eingeben",
    "sold-out": "Ausverkauft",
    "the-ticket-has-reached-the-maximum-number": "Das Ticket hat die maximale Anzahl erreicht",
    "seat-left": "{{SEAT}} Sitzplatz frei",
    "seats-left": "{{SEAT}} Sitzplatze frei",
    proceed: "Vorgehen",
    summary: "Zusammenfassung",
    "buyer-info": "Käuferinformationen",
    continue: "Fortsetzen",
    "attendees-info": "Teilnehmerinfo",
    "registration": "Registrierung",
    "attendees-information": "Teilnehmerinformationen",
    submit: "Einreichen",
    "register-to-join-events": "Registrieren Sie sich, um an den Veranstaltungen teilzunehmen",
    "by-clicking-on-the-button":
        "Durch Anklicken des Buttons werden Sie auf die Ticketing-Plattform weitergeleitet, auf der Sie sich für diese Veranstaltung anmelden können.",
    other: "Andere",
    "please-enter-a-value-for": "Bitte geben Sie einen Wert für <FIELD_NAME> ein",
    "please-select-a-value-for": "Bitte wählen Sie einen Wert für <FIELD_NAME> ein",
    "enter-other-value-for": "Geben Sie einen anderen Wert für <FIELD_NAME> ein",
    "enter-a-valid": "Geben Sie ein gültiges <FIELD_NAME> ein",
    "should-be-at-least": "<FIELD_NAME> sollte mindestens <FIELD_LENGTH> <FIELD_TYPE> sein",
    "cannot-be-more-than": "<FIELD_NAME> darf nicht größer sein als <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> ist erforderlich.",
    "at-least-required": "Mindestens <FIELD_LENGTH> <FIELD_NAME> <VERB> erforderlich",
    "maximum-allowed": "Maximal <FIELD_LENGTH> <FIELD_NAME> <VERB> erlaubt",
    "user-with-same-email-id-already-exists":
        "Benutzer mit derselben E-Mail-ID existiert bereits. Bitte verwenden Sie einen anderen.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Ein Benutzer mit derselben E-Mail-ID hat sich bereits für diese Veranstaltung registriert. Bitte verwenden Sie einen anderen.",
    "mail-successfully-sent": "Mail erfolgreich gesendet!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "Wir können Ihre Post derzeit nicht zustellen! Bitte versuchen Sie es nach einiger Zeit",
    "something-went-wrong": "Etwas ist schief gelaufen! Bitte versuchen Sie es später noch einmal",
    "please-select-a-ticket-to-proceed": "Bitte wählen Sie ein Ticket aus, um fortzufahren",
    "register-now": "Jetzt registrieren",
    "add-to-your-calendar": "Zu Ihrem Kalender hinzufügen",
    calendar: "Kalender",
    "resend-link": "Link erneut senden",
    "go-to-event": "Gehe zu Veranstaltung",
    "ticket-registration-done-by": "Ticketregistrierung erledigt von",
    "you-have-already-purchased-the-ticket-for": "Sie haben das Ticket bereits gekauft für",
    purchased: "Gekauft",
    "event-date": "Veranstaltungsdatum",
    "ticket-quantity": "Ticketanzahl",
    payment: "Zahlung",
    "unable-to-submit-data": "Daten können nicht übermittelt werden",
    "unable-to-apply-promo-code": "Gutscheincode kann nicht angewendet werden",
    "invalid-promo-code": "Ungültiger Gutscheincode",
    "apply-now": "Sich bewerben",
    "enter-discount-coupon": "Rabattgutschein eingeben",
    "same-as-buyer": "Gleich wie Käufer",
    "please-check-all-the-fields-are-correctly-filled": "Bitte überprüfen Sie, ob alle Felder richtig ausgefüllt sind",
    "ticket-purchased": "Ticket gekauft",
    "ticket-purchase-failed": "Ticketkauf fehlgeschlagen",
    "you-have-successfully-purchased-the-ticket":
        "Sie haben das Ticket erfolgreich gekauft. Mit diesem Ticket können Sie an der Veranstaltung teilnehmen. Danke für Ihren Einkauf!",
    "there-was-an-error-while-booking-your-tickets":
        "Beim Buchen Ihrer Tickets ist ein Fehler aufgetreten. Bitte versuche es erneut.",
    "try-again": "Versuchen Sie es erneut",
    "you-dont-have-access": "Sie haben keinen Zugang, um einige der Tickets zu kaufen",
    "please-enter-a-valid-email": "Bitte geben Sie eine gültige Email-Adresse ein",
    "payment-summary": "Zahlungsübersicht",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}} erfolgreich angewendet. Sie haben zusätzliche {{AMOUNT}} gespeichert.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Sie möchten eine Steuerrechnung erstellen?",
    "taxation-tnc-pp": "Indem Sie fortfahren, stimmen Sie unseren",
    "taxation-tnc": "Geschäftsbedingungen",
    and: "und",
    "taxation-pp": "Datenschutz-Bestimmungen",
    "company-name-label": "Name des Unternehmens",
    "company-name-place-holder": "Firmenname eingeben",
    "taxId-label": "Unternehmen Steuer-ID",
    "taxId-place-holder": "Steuernummer eingeben",
    "company-address-label": "Adresse des Unternehmens",
    "company-address-place-holder": "Adresse",
    "country-place-holder": "Land auswählen",
    "state-place-holder": "Staat auswählen",
    "city-place-holder": "Stadt eingeben",
    "zipcode-label": "Postleitzahl",
    "zipcode-place-holder": "Postleitzahl eingeben",
    "company-error-msg": "Bitte Firmennamen eingeben",
    "taxId-error-msg": "Bitte unternehmen steuer id eingeben",
    "address-error-msg": "Bitte Adresse eingeben",
    "country-error-msg": "Bitte Land auswählen",
    "state-error-msg": "Bitte Staat auswählen",
    "city-error-msg": "Bitte Stadt eingeben",
    "zipcode-error-msg": "Bitte Postleitzahl eingeben",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail",
    phone: "Telefon",
    dialerCode: "Dialer-Code",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Sie haben bereits ein Ticket gekauft für",
    "upload-file": "Datei hochladen",
    "browse-a-file-to-upload": "Eine Datei zum Hochladen durchsuchen",
    "max-size": "Maximale Größe",
    "file-supported-types": "Unterstützte Dateitypen",
    "uploaded-successfully": "Erfolgreich hochgeladen",
    "failed!-please-retry": "Fehlgeschlagen! Bitte erneut versuchen",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Dieser Dateityp wird nicht unterstützt. Unterstützte Anhänge sind",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "Die Dateigröße ist zu hoch. Bitte laden Sie ein Dokument hoch, das kleiner ist als",
    person: "Person",
    select: "Wählen Sie",
    search: "Suche",
    "uploaded-file": "Hochgeladene Datei",
    uploading: "Hochladen",
    delete: "Löschen",
    "no-item": "Kein Element",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Google-Kalender",
    "outlook-calendar": "Outlook-Kalender",
    "yahoo-calendar": "Yahoo-Kalender",
    "read-more": "Mehr lesen",
    "read-less": "Weniger lesen",
    "about-title": "Über",
    "verb-are": "sind",
    "verb-is": "ist",
    character: "Charakter",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/YYYY",
    "timer-hr": "Std",
    "timer-hrs": "Stdn",
    "timer-min": "Min",
    "timer-mins": "Min",
    "timer-sec": "Sekn",
    "timer-secs": "Sek",
    "timer-day": "Tag",
    "timer-days": "Tage",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Keine Datei ausgewählt",
    today: "Heute",
    yesterday: "Gestern",
    tomorrow: "Morgen",
    last: "Zuletzt",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Registrierung erfolgreich abgeschlossen!",
    registrationSuccessDetail: "Sie haben sich erfolgreich für die Veranstaltung angemeldet. Vielen Dank!",
    "search-here": "Suche hier..",
    "did-you-mean": "Meinen Sie ",
    DISPOSABLE_EMAIL: "Die angegebene E-Mail-Adresse gehört zu einem Wegwerf-E-Mail-Dienst",
    RISTRICTED_DOMAIN: "Die angegebene E-Mail-Adresse gehört zu einer eingeschränkten Domäne. Bitte geben Sie eine gültige E-Mail-Adresse ein",
    INVALID_EMAIL: "Bitte geben Sie eine gültige Email-Adresse ein",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Zahlungsmethode wählen",
    PAY_NOW: "Jetzt bezahlen",
    INSTANT_TICKET_CONFIRMATION: "Sofortige Ticketbestätigung",
    PAY_VIA_OTHER_METHODS: "Bezahlen mit anderen Methoden",
    FUNDS_TRANSFER: "Geldtransfer",
    DIGITAL_WALLET: "Digitale Brieftasche",
    CHEQUE: "Scheck",
    PAY_AT_VENUE: "Bezahlung am Veranstaltungsort",
    TICKET_NOT_CONFIRMED_ORGANIZER: "Die Eintrittskarte(n) wird/werden erst bestätigt, wenn der Veranstalter den Zahlungseingang bestätigt hat und kann/können storniert werden.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "Das/die Ticket(s) wird/werden erst bestätigt, wenn Sie die Zahlung am Veranstaltungsort vorgenommen haben und können storniert werden.",
    TICKET_CONFIRMED_BY_ORGANIZER: "Die Eintrittskarte(n) wird/werden bestätigt, wenn der Veranstalter den Zahlungseingang bestätigt.",
    COMPLETE_PAYMENT_OF: "Vollständige Bezahlung von",
    COMPLETE_PAYMENT_TICKET: "Schließen Sie die Zahlung für Ihr(e) Ticket(s) ab.",
    PAYMENT_CONFIRMATION_TITLE: "Geben Sie die eindeutige Transaktions-ID für den Veranstalter ein, um Ihre Zahlung zu überprüfen.",
    ENTER_TRANSACTION_ID: "Geben Sie die Transaktions-ID ein",
    SHARE_DETAILS_TITLE: "Der Veranstalter kann Ihre Zahlung nur genehmigen, wenn Sie die Details mitteilen. Ihr(e) Ticket(s), einschließlich etwaiger Freikarten, werden nach der Genehmigung bestätigt.",
    UPLOAD_SCREENSHOT: "Screenshot hochladen (optional)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Zahlungsdetails freigeben",
    TICKET_STATUS: "Ticket-Status", // not found
    ORGANIZER_YET_TO_CONFIRM: "Der Veranstalter muss noch bestätigen, dass die Zahlung eingegangen ist.",
    TICKET_CONFIRMATION_ORGANIZER: "Ihr(e) Ticket(s), einschließlich eines oder mehrerer kostenloser Tickets, und die Rechnung werden nach der Genehmigung durch den Veranstalter bestätigt und an Sie weitergeleitet.",
    PAYMENT_DETAILS: "Einzelheiten zur Zahlung",
    FAQ_TITLE: "Falls Sie Fragen haben, lesen Sie bitte die FAQs",
    MAKE_PAYMENT_TITLE: "Bezahlen Sie am Veranstaltungsort, um den Kauf Ihrer Eintrittskarte(n) zu bestätigen.",
    CONFIRM_TICKET_PRIOR_EVENT: "Wenn Sie Ihr(e) Ticket(s) vor der Veranstaltung bestätigen möchten, zahlen Sie jetzt mit einer anderen Methode.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Wenn Sie Ihr(e) Ticket(s) vor der Veranstaltung bestätigen möchten,",
    PAY_NOW_2: "jetzt bezahlen",
    WITH_DIFFERENT_METHOD: "mit einer anderen Methode",
    PAYMENT_NOT_RCVD: "Der Organisator der Veranstaltung hat die Zahlung nicht erhalten.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Die angegebenen Zahlungsdaten konnten vom Veranstalter nicht überprüft werden.",
    TICKETS_NOT_AVAILABLE: "Die Tickets sind für die Veranstaltung nicht mehr verfügbar.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Sie müssen noch die Zahlungsdetails für das/die Ticket(s) mitteilen.", // not found in code
    WELCOME_BACK: "Willkommen zurück!",
    VIEW_OR_PROCEED_PURCHASE: "Sie können Ihre vorherigen Tickets ansehen oder mit dem Kauf der Tickets, die Sie gerade ausgewählt haben, fortfahren.",
    VIEW_PREV_TICKETS: "Vorherige Tickets anzeigen",
    PROCEED_TO_PURCHASE: "Zum Kauf fortfahren",
    PREVIOUS_TICKETS: "Vorherige Tickets",
    PENDING_TICKETS: "Ausstehende Tickets",
    CONFIRMED_TICKETS: "Bestätigte Tickets",
    PAYMENT_PENDING: "Zahlung ausstehend",
    AWAITING_ORG_APPROVAL: "Genehmigung des Veranstalters steht noch aus",
    CHECK_TICKET_STATUS: "Ticket-Status prüfen",
    PAYMENT_METHOD_NOT_AVAILABLE: "Zahlungsmethode nicht verfügbar, bitte versuchen Sie es erneut.", // not found in code
    HERE: "hier.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Bitte aktualisieren Sie die eindeutigen Teilnehmerinformationen für alle gekauften Eintrittskarten. Dies ermöglicht allen Teilnehmern den Zugang zur Veranstaltung.",
    DUPLICATE_EMAIL: "Sie haben dieses Ticket bereits gekauft. Sie können sich für eine erneute Registrierung entscheiden. In diesem Fall werden die zuletzt eingegebenen Daten gespeichert.",
    DEFAULT_DUPLICATE_EMAIL: "Diese E-Mail wird bereits für ein anderes Ticket verwendet. Bitte überprüfen Sie die eingegebene E-Mail-Adresse noch einmal oder verwenden Sie dieselbe E-Mail-Adresse für mehrere Tickets.",
    "REGISTER_NOW": "Jetzt registrieren",
    "REG_FAILED": "Anmeldung fehlgeschlagen",
    "REG_FAILED_DESC": "Ihre Anmeldung ist fehlgeschlagen. Bitte versuchen Sie es erneut.",
    "REG_DONE_BY": "Die Anmeldung erfolgt durch",
    "INVITE_AGAIN": "Möchten Sie die Einladung erneut erhalten?",
    "REG_SUCCESS_DONE": "Anmeldung erfolgreich abgeschlossen",
    "WATCH_REC_LINK": "Eine E-Mail mit einem Link zum Anschauen der Aufzeichnung wurde versandt an",
    "WEBINAR_INV_LINK": "Eine Einladungs-E-Mail mit dem Webinar-Link wurde verschickt an",
    "MAIL_SENT_SUCCESS": "Mail erfolgreich gesendet",
    "ALREADY_REGISTERED": "Sie haben sich bereits angemeldet für",
    "RESEND_INVITE": "Einladung erneut senden",
    "WATCH_RECORDING_REGISTER_DESC": "Sie können die Aufzeichnung des Webinars ansehen, indem Sie sich unten registrieren.",

};
