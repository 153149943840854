/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import envConfig from "config";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import OfferIcon from "../../Assets/Svg/OfferIcon";
import IconButton from "@mui/material/IconButton";
import PopoverToolTip from "../PopoverToolTip";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import InputField from "../InputField";
import CustomToolTip from "../ToolTip";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  IHeadersRequest,
  ITicketDetailsResponse,
  ALREADY_BOUGHT_ERROR,
  NEW_USER,
  MULTIPLE_TICKETS_ALLOWED,
  LIMITED_ACCESS_ERROR,
  APRVL_PND,
  PRF_PND,
} from "../../Types/Ticket";
import {
  IConfigData,
  IDiscount,
  ILandingPage,
  ITicketData,
  IEventInfo,
} from "../../Types/LandingPage";
import { formatDate } from "../../Utils/date";
import useValidateEmail from "../../Hooks/useValidateEmail";
// import useValidateEmailDomain from "../../Hooks/useValidateEmailDomain";
import { IAddTicket, IValidateEmailRequest } from "../../Types/ValidateEmail";
// import { IAddTicket, IValidateEmailRequest,IValidateEmailRequestDataObj } from "../../Types/ValidateEmail";
import { isAndroidIPhone, isAndroidIPhoneIPad } from "../../navigator";
import CustomModal from "../CustomModal";
import { IApplyDiscount, ITicketsInfo } from "../../Types/ApplyDiscount";
import { useStore } from "../../Context";
import {
  PageState,
  MINIMUM_THRESHOLD_TO_SHOW_TICKET_COUNT,
} from "../../Constants/AppConstants";
import TicketsPurchasedList from "../TicketsPurchasedList";
// import TicketListLoader from "../Loaders/TicketListLoader";
import Track from "../../Lib/ThirdParty/amplitude";
import { useStyles } from "./styles";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { getUTM, isTicketAmountFloat } from "Utils/common";
import StringParser from "Components/StringParse";
import { CircularProgress } from "@mui/material";

const isMobile = isAndroidIPhoneIPad();

export interface TicketListProps {
  ticketData: ITicketDetailsResponse;
  landingPageData?: ILandingPage;
  configData?: IConfigData;
  tickets?: ITicketData[];
  eventInfo?: IEventInfo;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setTicketId: Function;
}
export interface ExpandState {
  id: number;
  isExpand: boolean;
}

function TicketListForm(
  classes: any,
  loading: boolean,
  ticketData: ITicketDetailsResponse,
  selectedTicket: IAddTicket[],
  setExpandState: React.Dispatch<React.SetStateAction<ExpandState>>,
  expandState: ExpandState,
  landingPageData: ILandingPage | undefined,
  setSelectedTicket: React.Dispatch<React.SetStateAction<IAddTicket[]>>,
  errorMessage: string,
  email: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  setEmail: React.Dispatch<React.SetStateAction<string>>,
  setAcceptCompliance: React.Dispatch<React.SetStateAction<boolean>>,
  acceptCompliance: boolean,
  handleOnProceed: (e: React.MouseEvent<HTMLButtonElement>) => void,
  // handleOnBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
  // eslint-disable-next-line @typescript-eslint/ban-types
  setTicketId: Function,
  suggestionMessage: string,
  setSuggestionMessage: React.Dispatch<React.SetStateAction<string>>
) {
  const [t] = useTranslation();
  // const isEventInfo = false;
  const { store, setRegistrationToggle } = useStore();
  const isHideAvailableMillie = envConfig?.HIDE_TICKET_TIME_EVENT_ID?.includes(store?.configData?.previewData?.eventId || "");

  return (
    <Box
      className={`${isMobile ? `${classes.ticketListMain}` : ""}  ${store?.widgetData?.isWidgetExpand
        ? `${classes.ticketWrapperWidget} singleViewWrapperWidget`
        : false
        }`}
    >
      <Box
        className={`${isMobile ? `${classes.ticketListInner} ${ticketData.isSimpleRegistrationEnabled ? classes.simpleRegForMob:""}` : ""
          } ticketWrapperInnerWidget`}
      >
        {ticketData?.tickets &&
          ticketData.tickets.map((ticket: ITicketData) => {
            const quantity = selectedTicket.find(
              (item) => item.ids === ticket?.id
            )?.quantity as number;
            return (
              <Card
                variant="outlined"
                sx={{ borderRadius: 2.5, mb: 2.5 }}
                className={`${classes.ticketListCommon} ticketListingWidget`}
                key={ticket.id}
              >
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      component="h6"
                      variant="body2"
                      sx={(theme: any) => ({
                        fontWeight: "600 !important",
                        color: `${theme.palette.text.primary} !important`,
                      })}
                    >
                      {ticket.title}
                    </Typography>
                    {ticket.description ? (
                      <IconButton
                        onClick={() =>
                          setExpandState({
                            id: ticket.id,
                            isExpand: !expandState.isExpand,
                          } as ExpandState)
                        }
                        className="roundIcon moreInfo"
                      >
                        {expandState?.id === ticket.id &&
                          expandState.isExpand ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownRoundedIcon />
                        )}
                      </IconButton>
                    ) : (
                      false
                    )}
                  </Box>
                  <Typography
                    component="p"
                    variant="caption"
                    sx={(theme: any) => ({
                      mb: "15px !important",
                      mt: "2px !important",
                      fontWeight: 400,
                      color: theme.palette.text.secondary,
                      textAlign: "left !important",
                    })}
                  >
                    {isHideAvailableMillie ? "" : t("available-till", {
                      DATE: formatDate(
                        +ticket.closeAt <
                          parseInt(landingPageData?.eventEndtime as string)
                          ? +ticket.closeAt
                          : parseInt(landingPageData?.eventEndtime as string),
                        "MMM DD, YYYY (hh:mm A)"
                      ),
                    })}
                  </Typography>
                  {expandState?.id === ticket.id && expandState.isExpand ? (
                    // <Typography
                    //   component="p"
                    //   variant="caption"
                    //   sx={{
                    //     mb: "15px !important",
                    //     mt: "0px !important",
                    //     fontWeight: 400,
                    //   }}
                    // >
                    //   {ticket.description}
                    // </Typography>
                    <Box
                      sx={{
                        mb: "15px !important",
                        mt: "0px !important",
                        textAlign: "left",
                      }}
                    >
                      <StringParser color="textSecondary" htmlStr={ticket.description} />
                    </Box>
                  ) : (
                    false
                  )}
                  <Box
                    className={`${isAndroidIPhoneIPad() ? "ticketPriceForMobile" : false
                      } ticketPrice`}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        component="h6"
                        variant="caption"
                        sx={(theme) => ({
                          fontWeight: "700 !important",
                          textAlign: "left",
                          color: `${theme.palette.primary.main} !important`,
                        })}
                      >
                        {ticket?.ticketFee ? (
                          <>
                            {ticket?.currency?.symbol}
                            {isTicketAmountFloat(ticket?.ticketFee)}
                          </>
                        ) : store?.hideFreeLabel ? (
                          ""
                        ) : (
                          t("free")
                        )}
                        {ticket.seatLeft > 0 &&
                          ticket.seatLeft <
                          MINIMUM_THRESHOLD_TO_SHOW_TICKET_COUNT ? (
                          <Typography
                            component="h5"
                            variant="caption"
                            color="secondaryColor"
                            sx={(theme) => ({
                              fontWeight: 400,
                              color: theme.palette.text.secondary,
                            })}
                          >
                            {ticket.seatLeft > 1
                              ? t("seats-left", {
                                SEAT: ticket.seatLeft,
                              })
                              : t("seat-left", {
                                SEAT: ticket.seatLeft,
                              })}
                            !
                          </Typography>
                        ) : null}
                      </Typography>
                    </Box>

                    <Box display="flex">
                      {ticket?.discount?.length > 0 && (
                        <PopoverToolTip
                          variant="outlined"
                          btnLabel={t("offers")}
                        >
                          {ticket?.discount.map((discount: IDiscount) => (
                            <Box
                              key={discount.id}
                              sx={(theme) => ({
                                py: 0.25,
                                px: 0,
                                maxWidth: 280,
                                "&>p": { fontWeight: 400, lineHeight: 1.3 },
                                "& svg": { color: theme.palette.primary.main },
                              })}
                              display="flex"
                            >
                              <Box display="flex" mt={0.1}>
                                <OfferIcon />
                              </Box>
                              <Typography
                                variant="caption"
                                component="p"
                                sx={(theme: any) => ({
                                  width: "140px",
                                  marginTop: "0px !important",
                                  marginBottom: "0px !important",
                                  marginLeft: theme.spacing(1),
                                })}
                              >
                                {discount.desc}
                              </Typography>
                            </Box>
                          ))}
                        </PopoverToolTip>
                      )}

                      {ticket.seatLeft > 0 ? (
                        <>
                          {quantity === undefined || quantity === 0 ? (
                            <>
                              {/* Registration button is here */}
                              {!ticket.ticketFee &&
                                ticketData.isSimpleRegistrationEnabled ? <Button
                                  onClick={() => {
                                    setRegistrationToggle(ticketData.isSimpleRegistrationEnabled);
                                    setTicketId(ticket.id);
                                  }}
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  sx={{ marginLeft: 1, fontSize: 12 }}
                                >
                                {t("Register Now")}
                              </Button> :
                                <Button
                                  onClick={() => {
                                    if (ticketData?.enableSingleTicketTypePurchase) {
                                      setSelectedTicket([
                                        {
                                          ids: ticket.id,
                                          quantity: 1,
                                          isApplied: false,
                                          isComplete: false,
                                        },
                                      ],
                                      );
                                    } else {
                                      setSelectedTicket([
                                        ...selectedTicket,
                                        ...[
                                          {
                                            ids: ticket.id,
                                            quantity: 1,
                                            isApplied: false,
                                            isComplete: false,
                                          },
                                        ],
                                      ]);
                                    }
                                  }}
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  sx={{ marginLeft: 1, fontSize: 12 }}
                                  startIcon={<AddIcon />}
                                >
                                  {t("add")}
                                </Button>
                              }
                              {/* Ends here */}
                            </>
                          ) : (
                            <Box display="flex" alignItems="center" ml={1.5}>
                              <IconButton
                                onClick={() => {
                                  const index = selectedTicket.findIndex(
                                    (x) => x.ids === ticket.id
                                  );
                                  const items = [...selectedTicket];
                                  const item = { ...items[index] };
                                  item.quantity = quantity - 1;
                                  if (item.quantity === 0) {
                                    items[index] = {} as IAddTicket;
                                    setSelectedTicket(items);
                                  } else {
                                    items[index] = item;
                                    setSelectedTicket(items);
                                  }
                                }}
                                className="roundIcon"
                                sx={{ "&>svg": { fontSize: "16px" } }}
                              >
                                <RemoveIcon fontSize="small" />
                              </IconButton>
                              <Typography
                                variant="caption"
                                component="label"
                                sx={{ minWidth: "30px", textAlign: "center" }}
                              >
                                {quantity}
                              </Typography>
                              <CustomToolTip
                                title={
                                  quantity >= ticket.seatLeft ||
                                    (ticket.maxLimit &&
                                      quantity >= ticket.maxLimit) ? (
                                    <Typography
                                      component="p"
                                      variant="caption"
                                      sx={{ fontWeight: 400 }}
                                    >
                                      {t(
                                        "the-ticket-has-reached-the-maximum-number"
                                      )}
                                    </Typography>
                                  ) : (
                                    ""
                                  )
                                }
                              >
                                <IconButton
                                  disabled={
                                    quantity >= ticket.seatLeft ||
                                    (ticket.maxLimit
                                      ? quantity >= ticket.maxLimit
                                      : false)
                                  }
                                  onClick={() => {
                                    const index = selectedTicket.findIndex(
                                      (x) => x.ids === ticket.id
                                    );
                                    const items = [...selectedTicket];
                                    const item = { ...items[index] };
                                    item.quantity = quantity + 1;
                                    items[index] = item;
                                    setSelectedTicket(items);
                                  }}
                                  className="roundIcon"
                                  sx={{ "&>svg": { fontSize: "16px" } }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </CustomToolTip>
                            </Box>
                          )}
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ marginLeft: 1, fontSize: 12 }}
                          disabled
                        >
                          {t("sold-out")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            );
          })}
      </Box>
      {!ticketData.isSimpleRegistrationEnabled ? (
        <Box
          className={`${isMobile ? `${classes.emailBoxWrapper}` : ""
            } inputEmailWrapperWidget isWidgetEventName ${store?.widgetData?.showEventInfo ? "" : "widgetWithoutEventDetails"
            }`}
        >
          <Box sx={{ mb: 1 }}>
            <InputField
              errorMsg={errorMessage}
              startIcon
              icon={
                <MailOutlineIcon
                  fontSize="small"
                  className={classes.mailIconColor}
                />
              }
              label={t("please-enter-your-email-to-continue")}
              placeHolder={t("enter-email-address")}
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setErrorMessage("");
                setEmail(e.target.value);
                setSuggestionMessage("");
              }}
            // onBlur={handleOnBlur}
            />
          </Box>
          {suggestionMessage && (
            <Typography className={classes.suggestionBox} component="p">
              {suggestionMessage}
            </Typography>
          )}
          {ticketData?.isGdprComplianceEnabled && (
            <Box sx={{ textAlign: "left" }} mb={0.5}>
              <FormControlLabel
                className={classes.checkboxWrapper}
                sx={{ mb: store?.widgetData?.isWidgetExpand ? 1 : 2 }}
                control={
                  <Checkbox
                    disableRipple
                    onChange={() => setAcceptCompliance((state) => !state)}
                    value="test"
                    checked={acceptCompliance}
                    size="small"
                  />
                }
                label={
                  <span
                    className={classes.termandpolicy}
                    dangerouslySetInnerHTML={{
                      __html: ticketData?.gdprComplianceConsentText as string,
                    }}
                  ></span>
                }
              />
            </Box>
          )}
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={loading ||
                (ticketData?.isGdprComplianceEnabled && !acceptCompliance) ||
                selectedTicket.filter(
                  (obj) => !(obj && Object.keys(obj).length === 0)
                ).length === 0
              }
              onClick={handleOnProceed}
            >
              {loading ? <CircularProgress size={15} /> : t("proceed")}
            </Button>
          </Box>
        </Box>
      ) : (
        false
      )}
    </Box>
  );
}

export default function TicketList(props: TicketListProps) {
  const [t] = useTranslation();
  const [open, toggleModal] = useState(false);
  const classes = useStyles();
  const [expandState, setExpandState] = useState({} as ExpandState);
  const [isAlreadyPurchase, setPurchase] = useState(false);
  const [ticketCase, setTicketCase] = useState("");
  const { ticketData, landingPageData, configData, eventInfo, setTicketId } = props;
  const [acceptCompliance, setAcceptCompliance] = useState<boolean>(false);
  const [selectedTicket, setSelectedTicket] = useState<Array<IAddTicket>>([]);
  const { data: validateEmailResponse, validateEmail } = useValidateEmail();
  // const { data: validateEmailDomainResponse, validateEmailDomain } =
  //   useValidateEmailDomain();

  const {
    setDiscount,
    setTicketList,
    setBuyerEmail,
    setSelectedTickets,
    setOrderNo,
    setOrderToken,
    store,
    setOtherPaymentModal,
    setTicketStore,
    setUserData,
  } = useStore();
  const { ticketList, startFromSummary } = store;

  const handleClosePopUp = () => {
    setPurchase(false);
  };
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestionMessage, setSuggestionMessage] = useState("");

  useEffect(() => {
    return () => {
      setPurchase(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (validateEmailResponse?.orderToken)
      setOrderToken(validateEmailResponse?.orderToken);
    if (validateEmailResponse?.orderNo) {
      setOrderNo(validateEmailResponse?.orderNo);
    }
    if (validateEmailResponse?.case === NEW_USER) {
      setBuyerEmail(email);
      if (validateEmailResponse?.orderToken)
        setOrderToken(validateEmailResponse?.orderToken);
      setSelectedTickets(
        selectedTicket.filter((value) => Object.keys(value).length !== 0)
      );
      handleClosePopUp();
    } else if (validateEmailResponse?.case === APRVL_PND) {
      setTicketCase(APRVL_PND);
      setBuyerEmail(email);
      setSelectedTickets(
        selectedTicket.filter((value) => Object.keys(value).length !== 0)
      );
      setPurchase(true);
    } else if (validateEmailResponse?.case === ALREADY_BOUGHT_ERROR) {
      setTicketCase(ALREADY_BOUGHT_ERROR);
      setBuyerEmail(email);
      setSelectedTickets(
        selectedTicket.filter((value) => Object.keys(value).length !== 0)
      );
      setPurchase(true);
    } else if (validateEmailResponse?.case === MULTIPLE_TICKETS_ALLOWED) {
      // handleClosePopUp();
      const configPayload = {
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: true,
        sharePaymentDetails: false,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      };
      setOtherPaymentModal(configPayload);

    } else if (validateEmailResponse?.case === LIMITED_ACCESS_ERROR) {
      setSelectedTickets(
        selectedTicket.filter((value) => Object.keys(value).length !== 0)
      );
      setErrorMessage(t("you-dont-have-access"));
    } else if ([PRF_PND, APRVL_PND].includes(validateEmailResponse?.case)) {
      const configPayload = {
        choosePaymentMethod: false,
        faqModal: false,
        orderStatus: false,
        paymentDetailModal: false,
        returningUserModal: false,
        sharePaymentDetails: true,
        ticketConfirmStatus: false,
        paymentStatusModal: false,
      };

      if (validateEmailResponse?.case === APRVL_PND) {
        configPayload["sharePaymentDetails"] = false;
        configPayload["paymentStatusModal"] = true;
      }

      const currentPayload: Array<ITicketData> = validateEmailResponse?.tickets?.map(({ purchasedTime, qty, desc, ...rest }) => ({
        description: desc,
        closeAt: purchasedTime,
        selectedQuantity: qty,
        ...rest
      } as ITicketData));
      setTicketStore(currentPayload);
      setOtherPaymentModal(configPayload);
    }
    const userResponseToEmail = {
      case: validateEmailResponse?.case,
      ticketIds: String(validateEmailResponse?.ticketIds),
      tickets: validateEmailResponse?.tickets,
      buyerEmail: email,
      buyerAlreadyRegisteredAsAttendee: validateEmailResponse?.buyerAlreadyRegisteredAsAttendee,
      canShowResendLink: validateEmailResponse?.canShowResendLink,
      code: validateEmailResponse?.code,
    };
    //@ts-ignore;
    setUserData(userResponseToEmail);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateEmailResponse]);

  useEffect(() => {
    if (store.selectedTicket && Array.isArray(store.selectedTicket) && store.selectedTicket.length) {
      setSelectedTicket(store.selectedTicket);
    }
    if (store.buyerEmail) {
      setEmail(store.buyerEmail);
    }
  }, [store.buyerEmail, store.selectedTicket]);

  useEffect(() => {
    if (validateEmailResponse.orderToken) {
      setLoading(false);
      if ([APRVL_PND, PRF_PND].includes(validateEmailResponse?.case)) {
        setBuyerEmail(email);
      } else {
        const updTicketList = !isEmpty(ticketList)
          ? ticketList?.map((eachTicket: ITicketData) => {
            const obj = selectedTicket.find((ele) => ele.ids === eachTicket.id);
            return { ...eachTicket, selectedQuantity: obj?.quantity || 0 };
          })
          : [];
        setTicketList(updTicketList || []);
        const paidTicketPresent =
          updTicketList &&
          updTicketList?.find(
            (data) => !!data.selectedQuantity && data.ticketFee > 0
          );
        const tickets = [] as ITicketsInfo[];
        selectedTicket.forEach((item: IAddTicket) => {
          tickets.push({
            id: item.ids,
            isApplied: item.isApplied,
            isComplete: item.isComplete,
            quantity: item.quantity,
          } as ITicketsInfo);
        });
        const summaryStep =
          !ticketData?.isSimpleRegistrationEnabled && paidTicketPresent
            ? PageState.TicketSummary
            : !ticketData?.isBuyerForm
              ? PageState.AttendeeDetails
              : PageState.BuyDetails;
        if (validateEmailResponse?.case === NEW_USER) {
          setDiscount(summaryStep, {
            buyerEmail: email,
            orderToken: validateEmailResponse.orderToken,
            ticketsInfo: tickets,
          } as IApplyDiscount);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTicket, validateEmailResponse?.orderToken]);

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   if (validateEmailResponse?.code === "DISPOSABLE_EMAIL") {
  //     setErrorMessage(t("DISPOSABLE_EMAIL"));
  // } else if (validateEmailResponse?.code === "RISTRICTED_DOMAIN") {
  //     setErrorMessage(t("RISTRICTED_DOMAIN"));
  // }
  // }, [ validateEmailResponse,t]);
  //track
  const trackSelectTicket = async (currentEmail: string) => {
    selectedTicket
      .map((eachTicket) =>
        ticketData.tickets.find((ticket) => ticket.id === eachTicket.ids)
      )
      .filter(Boolean)
      .forEach((selectedTicketDetails?: ITicketData) => {
        selectedTicketDetails = selectedTicketDetails as ITicketData;
        Track.selectTicket({
          email: currentEmail,
          ticketId: selectedTicketDetails.id,
          ticketName: selectedTicketDetails.title,
          quantity: selectedTicketDetails.selectedQuantity || 0,
          currency: selectedTicketDetails.currency?.symbol || "",
          amount: selectedTicketDetails.ticketFee,
          lpType: startFromSummary
            ? "default landing page"
            : "custom landing page",
        });
      });
  };

  const handleOnProceed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLoading(true);
    setBuyerEmail(email);
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
      const utm = getUTM();
      setErrorMessage("");
      validateEmail(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: {
              email: email,
              tickets: selectedTicket.filter(
                (value) => Object.keys(value).length !== 0
              ),
              utm,
            },
          },
        } as IValidateEmailRequest
      );
      // eslint-disable-next-line no-console
      trackSelectTicket(email).catch((err) => console.log(err));
    } else {
      setErrorMessage(t("please-enter-a-valid-email"));
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (validateEmailDomainResponse) {
  //     if (Object.keys(validateEmailDomainResponse).length > 0) {
  //       if (validateEmailDomainResponse.suggestion) {
  //         setSuggestionMessage(
  //           t("did-you-mean") + validateEmailDomainResponse.suggestion + "?"
  //         );
  //       } else if (validateEmailDomainResponse.code) {
  //         setErrorMessage(t(validateEmailDomainResponse.code));
  //       }
  //     }
  //   }
  // }, [validateEmailDomainResponse, t]);

  // const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
  //   e.preventDefault();
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (re.test(String(email).toLowerCase())) {
  //     setErrorMessage("");
  //     validateEmailDomain(
  //       {
  //         authorization: configData?.accessToken,
  //         language: configData?.language,
  //       } as IHeadersRequest,
  //       {
  //         // payload: {
  //         data: {
  //           email: email,
  //         },
  //         // },
  //       } as IValidateEmailRequestDataObj
  //     );
  //     // eslint-disable-next-line no-console
  //     trackSelectTicket(email).catch((err) => console.log(err));
  //   } else {
  //     setErrorMessage(t("please-enter-a-valid-email"));
  //   }
  // };

  return (
    <>
      <Box
        className={`${classes.ticketListWrapper} ${store?.widgetData?.isWidgetExpand
          ? `${classes.widgetTicketList} singleViewWidgetTicketList`
          : false
          }`}
      >
        <>
          {(!store?.widgetData?.isWidgetExpand && isAndroidIPhoneIPad()) ||
            (!store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ||
            (store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ? (
            <Box>
              <Button
                onClick={() => toggleModal(!open)}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t("register-now")}
              </Button>
              <CustomModal
                isOnlyChildren
                open={open}
                onRequestClose={() => toggleModal(!open)}
                handleClose={() => toggleModal(!open)}
              // offsetFromTop={store.startFromSummary ? 0 : 1}
              >
                {TicketListForm(
                  classes,
                  loading,
                  ticketData,
                  selectedTicket,
                  setExpandState,
                  expandState,
                  landingPageData,
                  setSelectedTicket,
                  errorMessage,
                  email,
                  setErrorMessage,
                  setEmail,
                  setAcceptCompliance,
                  acceptCompliance,
                  handleOnProceed,
                  // handleOnBlur,
                  setTicketId,
                  suggestionMessage,
                  setSuggestionMessage
                )}
              </CustomModal>
            </Box>
          ) : (
            <>
              {TicketListForm(
                classes,
                loading,
                ticketData,
                selectedTicket,
                setExpandState,
                expandState,
                landingPageData,
                setSelectedTicket,
                errorMessage,
                email,
                setErrorMessage,
                setEmail,
                setAcceptCompliance,
                acceptCompliance,
                handleOnProceed,
                // handleOnBlur,
                setTicketId,
                suggestionMessage,
                setSuggestionMessage
              )}
            </>
          )}
        </>
        {/* @ts-ignore */}
        <TicketsPurchasedList
          open={isAlreadyPurchase}
          ticketCase={ticketCase}
          handleClose={handleClosePopUp}
          landingPageData={landingPageData}
          configData={configData}
          email={email}
          validateEmailResponse={validateEmailResponse}
          ticketData={ticketData}
          eventInfo={eventInfo} setKeepMounted={() => console.log("Function not required")}
          onClose={() => console.log("Function not required")}
        />
      </Box>
    </>
  );
}
