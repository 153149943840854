// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useRef, useState } from "react";
import StepperWithBackButton from "../StepperWithBackButton";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RadioGroupWithIcon from "../RadioGroupNewUI/RadioGroupWithIcon";
import VenueLocationIcon from "../../Assets/Svg/VenueLocationIcon";
// import InfoBulbIcon from "../../Assets/Svg/InfoBulbIcon";
import BankIcon from "../../Assets/Svg/BankIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import ChequeIcon from "../../Assets/Svg/ChequeIcon";
import ChequeTransferIcon from "../../Assets/Svg/ChequeTransferIcon";
import { useStyles } from "./PaymentMethod";
import { useStore } from "Context";
import StripeLogo from "../../Assets/Svg/StripeLogo";
import usePaymentMethod from "Hooks/usePaymentMethod";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import envConfig from "config";
import isEmpty from "lodash/isEmpty";
import Track from "Lib/ThirdParty/amplitude";

export const TS_OTHER_PAYMENT_METHODS = {
  FT: "FT", //fund transfer
  DW: "DW", //digital wallet
  CHQ: "CHQ", //cheque
  PAV: "PAV", //pay at venue
  STP: "STP", //stripe
};
export const OFFLINE_PAYMENT_LIST = ["FT", "DW", "CHQ", "PAV"];
export const ONLINE_PAYMENT_LIST = ["STP"];
export const TS_OTHER_PAYMENT_TYPE = {
  OFL: "OFL", //offline
  ONL: "ONL", //online
};
export const PAYMENT_METHOD_DTLS_NAME: any = {
  FT: "Funds Transfer",

  DW: "Digital Wallet",

  CHQ: "Cheque",

  PAV: "Pay at Venue",

  STP: "INSTANT_TICKET_CONFIRMATION",
};

export const PAYMENT_METHOD_DTLS = () : any =>{
  const [t] = useTranslation();
  return {
    FT: {
      label:  t("FUNDS_TRANSFER"),
      svg: <BankIcon height="15px" width="15px" />,
      selected: false,
      name: "FT",
    },
  
    DW: {
      label:  t("DIGITAL_WALLET"),
      svg: <ChequeTransferIcon height="15px" width="15px" />,
      selected: false,
      name: "DW",
    },
  
    CHQ: {
      label:  t("CHEQUE"),
      svg: <ChequeIcon height="15px" width="15px" />,
      selected: false,
      name: "CHQ",
    },
  
    PAV: {
      label:  t("PAY_AT_VENUE"),
      svg: <VenueLocationIcon height="15px" width="15px" />,
      selected: false,
      name: "PAV",
    },
  
    STP: {
      label: t("INSTANT_TICKET_CONFIRMATION"),
      svg: <StripeLogo height="20px" width="33px" />,
      selected: false,
      name: "STP",
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PaymentMethod = ({ ticketData, configData, onOpen }: any) => {
  const classes = useStyles();
  const { store, setOtherPaymentDetails, setToast, setOtherPaymentModal } =
    useStore();
  const [radioStateSelected, setRadioStateSelected] = useState("");
  const [t] = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, isSubmitLoading, setSubmitLoading, error, paymentMethod } =
    usePaymentMethod();

  const wrapperCondition = store.widgetData?.isWidgetExpand
    ? classes.ticketingWrapperMethod
    : store.startFromSummary
      ? classes.landingWrapperMethod
      : classes.builderWrapperMethod;
    const NEW_PAYMENT_METHOD_DTLS = PAYMENT_METHOD_DTLS();
  const availableOnlinePaymentMethod =
    store?.otherPaymentDetails?.paymentMethods
      ?.sort((a: any, b: any) => a.position - b.position)
      .reduce(
        (previous: any, current: any) => (
          current.type === TS_OTHER_PAYMENT_TYPE.ONL &&
          current.isActive === true &&
          previous.push({
            ...NEW_PAYMENT_METHOD_DTLS[current.name],
            details: current.details,
          }),
          previous
        ),
        []
      );

  const availableOtherPaymentMethod = store?.otherPaymentDetails?.paymentMethods
    ?.sort((a: any, b: any) => a.position - b.position)
    .reduce(
      (previous: any, current: any) => (
        current.type === TS_OTHER_PAYMENT_TYPE.OFL &&
        current.isActive === true &&
        previous.push({
          ...NEW_PAYMENT_METHOD_DTLS[current.name],
          details: current.details,
        }),
        previous
      ),
      []
    );
  const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOtherPaymentDetails({
      ...store.otherPaymentDetails,
      selectedPaymentMethod: availableOtherPaymentMethod.find(
        (data: any) => data.name === (event.target as HTMLInputElement).value
      ),
    });

    setRadioStateSelected((event.target as HTMLInputElement).value);
  };
  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleStripeCheckout = async (token: string) => {
    if (!token) return;
    let stripe: Stripe | null;
    if (store?.stripeAccountId) {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "", {
        stripeAccount: store?.stripeAccountId,
      });
    } else {
      stripe = await loadStripe(envConfig?.STRIPE_KEY || "");
    }
    const result = await stripe?.redirectToCheckout({
      sessionId: token,
    });
    if (result?.error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    }
    return result;
  };

  const handleSubmit = () => {
    if (radioStateSelected) {
      const paymentDetail = availableOtherPaymentMethod.find(
        (data: any) => data.name === radioStateSelected
      );
      if(OFFLINE_PAYMENT_LIST.includes(radioStateSelected)) {
        Track.pendingApprovalOrderCreated(PAYMENT_METHOD_DTLS_NAME[radioStateSelected], paymentDetail?.details, String(store.orderNo), String(store?.totalAmount));
      }
        setSubmitLoading(true);
      paymentMethod(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        },
        {
          payload: {
            data: {
              type: OFFLINE_PAYMENT_LIST.includes(radioStateSelected)
                ? "OFL"
                : "ONL",
              paymentMethod: radioStateSelected,
              ticketingLink: ONLINE_PAYMENT_LIST.includes(radioStateSelected)
                ? store.widgetData?.isWidgetExpand
                  ? configData?.communityUrl
                  : `${configData?.communityUrl}/register`
                : "",
            },
          },
        },
        store.orderToken
      );
      if (radioStateSelected !== "STP") {
        if (radioStateSelected === "PAV") {
          setOtherPaymentModal({
            choosePaymentMethod: false,
            faqModal: false,
            orderStatus: true,
            paymentDetailModal: false,
            returningUserModal: false,
            sharePaymentDetails: false,
            ticketConfirmStatus: false,
          });
        } else {
          setOtherPaymentModal({
            choosePaymentMethod: false,
            faqModal: false,
            orderStatus: false,
            paymentDetailModal: true,
            returningUserModal: false,
            sharePaymentDetails: false,
            ticketConfirmStatus: false,
          });
        }
      }

    } else {
      handleToastError("Please select any option");
    }
  };

  useEffect(() => {
    if (data && !isEmpty(data)) {
      if (radioStateSelected === "STP" && data?.stripeSessionId) {
        handleStripeCheckout(data?.stripeSessionId);
      }
    }
    if (error) {
      handleToastError(typeof error === "string"
        ? error
        : t("something-went-wrong"));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Box className={`${classes.paymentWrapper} ${wrapperCondition}`}>
      <StepperWithBackButton
        ticketData={ticketData}
        className="attendeesDetailsStepper" />
      <Box
        className={`${classes.wrapperBlock} ${OFFLINE_PAYMENT_LIST.indexOf(radioStateSelected) !== -1 ? "landingMethodWithoutError ticketWidgetMethodWithoutError": "landingMethod ticketWidgetMethod"}   builderMethod`}
      >
        <Typography variant="h3" component="h3" color="textPrimary">
          {t("CHOOSE_PAYMENT_METHOD")}
        </Typography>
        <Typography
          component="p"
          variant="body2"
          color="textPrimary"
          sx={{ fontWeight: 400, mt: 2, mb: "0px !important" }}
        >
          {t("TICKET_CONFIRMED_BY_ORGANIZER")}
        </Typography>
        {store?.otherPaymentDetails?.paymentMethodTypesAvailable?.includes(
          TS_OTHER_PAYMENT_TYPE.ONL
        ) ? (
          <Box
            sx={(theme: any) => ({
              mt: 3,
              border: `1px solid ${theme.palette.divider.default}`,
              borderRadius: "6px",
            })}
          >
            <Typography
              variant="body1"
              sx={(theme: any) => ({
                borderBottom: `1px solid ${theme.palette.divider.default}`,
                fontWeight: 700,
                p: 2,
                mb: 2,
              })}
              color="textPrimary"
              component="h4"
            >
              {t("PAY_NOW")}
            </Typography>
            {availableOnlinePaymentMethod.length ? (
              <RadioGroupWithIcon
                value={radioStateSelected}
                handleChange={handleChangeRadio}
                options={availableOnlinePaymentMethod}
              />
            ) : (
              false
            )}
          </Box>
        ) : (
          false
        )}
        {store?.otherPaymentDetails?.paymentMethodTypesAvailable?.includes(
          TS_OTHER_PAYMENT_TYPE.OFL
        ) ? (
          <Box
            sx={(theme: any) => ({
              mt: 2.5,
              border: `1px solid ${theme.palette.divider.default}`,
              borderRadius: "6px",
            })}
          >
            <Typography
              variant="body1"
              sx={(theme: any) => ({
                borderBottom: `1px solid ${theme.palette.divider.default}`,
                fontWeight: 700,
                p: 2,
                mb: 2,
              })}
              color="textPrimary"
              component="h4"
            >
              {t("PAY_VIA_OTHER_METHODS")}
            </Typography>
            {availableOtherPaymentMethod.length ? (
              <RadioGroupWithIcon
                value={radioStateSelected}
                handleChange={handleChangeRadio}
                options={availableOtherPaymentMethod}
              />
            ) : (
              false
            )}
          </Box>
        ) : (
          false
        )}
      </Box>
      <Box
        sx={(theme: any) => ({
          borderTop: `1px solid ${theme.palette.divider.default}`,
          mt: 2,
          position: "absolute",
          width: "100%",
          left: 0,
          bottom: 0,
          p: 2.5,
          "@media(max-width:640px)": { p: 2 },
        })}
        className="builderAction"
      >
        {OFFLINE_PAYMENT_LIST.indexOf(radioStateSelected) !== -1 ? (
          <Box
            mb={2}
            sx={(theme: any) => ({
              background: theme.palette.error.light,
              "&>svg": {
                height: "20px",
                width: "20px",
                color: theme.palette.error.main,
              },
              border: `1px solid ${theme.palette.error.main}`,
              borderRadius: "10px",
              p: 1.5,
              display: "flex",
              alignItems: "center",
            })}
          >
            <ErrorOutlineIcon />
            <Typography
              component="p"
              sx={{
                ml: 2,
                fontWeight: 400,
                color: "#202020",
                mt: "0px !important",
                mb: "0px !important",
              }}
              variant="body2"
            > {radioStateSelected === "PAV" ? `${t("TICKET_NOT_CONFIRMED_UNTIL_PAYMENT")}` : `${t("TICKET_NOT_CONFIRMED_ORGANIZER")}`}

            </Typography>
          </Box>
        ) : (
          false
        )}

        <Box
          sx={(theme: any) => ({
            borderRadius: "4px",
            background: theme.palette.primary.lighter,
            mb: 2,
            px: 2,
            py: 1,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <Typography variant="body1" component="label">
            Amount Payable
          </Typography>
          <Typography variant="body1" component="span">
            {store?.totalAmountSymbol}{store?.totalAmount}
          </Typography>
        </Box>
        <Button
          disabled={isSubmitLoading}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          sx={{ width: "100%" }}
        >
          {t("continue")}
        </Button>
      </Box>
      {/* <OtherPaymentModal configData={configData} isLoading={isSubmitLoading} /> */}
    </Box>
  );
};

export default PaymentMethod;
