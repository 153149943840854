import Typography from "@mui/material/Typography";
import sanitize from "sanitize-html";
import { Theme } from "@mui/material/styles";

interface IStringParser {
    sanitizeConfig?: sanitize.IOptions;
    isStringOnly?: boolean;
    isHashTag?: boolean;
    htmlStr: string;
    className?: string;
    color?: string;
}

const stringParserUrlRegex = "/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi";
const mailRegex = new RegExp("mailto:([w-]+(.[w-]+)@([a-z0-9-]+(.[a-z0-9-]+)?.[a-z]{2,6}|(d{1,3}.){3}d{1,3})(:d{4})?)");

const CONFIG = {
    allowedTags: ["p", "div", "h4", "h2", "h5", "h6", "ul", "ol", "li", "a", "em", "strong", "br", "span", "b", "i", "u"],
    allowedAttributesByTags: {
        a: ["href", "title", "target"],
        img: ["src", "alt", "title"],
        p: ["style"],
        span: ["style"],
    },
    allowedSchemas: ["http", "https", "mailto"],
    allowedClasses: { span: ["hash_tag"] },
};

const addHashTag = (str: string) => {
    return str.replace(/(^| |\s)(#[a-z\d]+)/gi, "$1<span class='hash_tag'>$2</span>");
};

const checkExtraDots = (str: string): boolean => {
    if (str.includes("..")) {
        return false;
    } else {
        return true;
    }
};

const linkableStr = (str: string): string => {
    return str.replace(stringParserUrlRegex, (url: string) => {
        if (checkExtraDots(url)) return `<a href="${""}${window.btoa(url)}" target="_blank">${url}</a>`;
        else return url;
    });
};

const sanitizeHtmlWithConfig = (str: string, sanitizeConfig?: sanitize.IOptions) => {
    let defaultSettings = {
        allowedTags: CONFIG.allowedTags,
        allowedAttributes: CONFIG.allowedAttributesByTags,
        allowedSchemes: CONFIG.allowedSchemas,
        allowedClasses: CONFIG.allowedClasses,
        transformTags: {
            a: (tagName: string, attribs: sanitize.Attributes) => {
                if (mailRegex.test(attribs.href)) {
                    return {
                        tagName,
                        attribs,
                    };
                }
                return {
                    tagName,
                    attribs: {
                        ...attribs,
                        // href: hubiloUrl + window.btoa(attribs.href),
                    },
                };
            },
        },
    };
    if (sanitizeConfig) {
        defaultSettings = Object.assign(defaultSettings, sanitizeConfig);
    }
    const sanitizeHtmlStr = sanitize(str, defaultSettings);
    return addHashTag(sanitizeHtmlStr);
};


const parserStyle = {
    readMoreDesc: (theme: Theme) => ({
        fontWeight: 400,
        "*,p,ul li,ol li": {
            lineHeight: 1.4,
            color: "inherit",
            marginTop: 0,
            wordBreak: "break-word",
            marginBottom: theme.spacing(0),
        },
        "&>.MuiTypography-root>p": {
            marginBottom: theme.spacing(2),
        },
        "p:last-child": {
            display: "inline",
        },
        ul: {
            paddingLeft: theme.spacing(2.2),
            listStyle: "disc",
        },
        ol: {
            paddingLeft: theme.spacing(2.2),
            listStyle: "decimal",
        },
        a: {
            fontSize: "0.875rem",
            lineHeight: 1,
            color: theme.palette.primary.main,
        },
    })
};

const StringParser = ({ sanitizeConfig, isStringOnly, isHashTag, htmlStr, color }: IStringParser): JSX.Element => {
    const generateHtml = () => {
        let finalStr;
        if (isStringOnly) {
            finalStr = linkableStr(htmlStr);
        } else {
            finalStr = sanitizeHtmlWithConfig(htmlStr, sanitizeConfig);
        }
        if (isHashTag) {
            finalStr = addHashTag(finalStr);
        }

        return finalStr;
    };

    return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <Typography
            variant="body1"
            component="div"
            color={color || "textPrimary"}
            sx={parserStyle.readMoreDesc}
            dangerouslySetInnerHTML={{ __html: generateHtml() }}
        />
    );
};

export default StringParser;
