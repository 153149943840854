import React from "react";
import { Typography, Box } from "@mui/material";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";
const WebinarTitle = () => {
  const { store } = useStore();
  const [t] = useTranslation();
  const isCustomLandingPage = store?.configData?.isCustomLandingPage;
  const allowSessionOnDemandValue =
    // @ts-ignore
    store?.landingPageData?.sessions?.data[0]?.data[0]?.streamTypeId === 6
      ? // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.preRecordedIsReplayVideo
      : // @ts-ignore
      store?.landingPageData?.sessions?.data[0]?.data[0]?.allowSessionOnDemand;
      
  const eventEnd = store?.landingPageData?.eventEndtime && (store?.landingPageData?.eventEndtime && Date.now() > Number(store?.landingPageData?.eventEndtime));
  return (
    <Box sx={{
        marginBottom: isCustomLandingPage ? "16px" : "32px",
    }}>
      <Typography
        variant="h2"
        component="h2"
        color="textPrimary"
        sx={{
          fontSize: "32px !important",
          "@media(max-width:768px)": {
            marginBottom: isCustomLandingPage ? "14px" : "20px",
            lineHeight: 1,
            paddingTop: "4rem"
          },
        }}
      >
        {t("REGISTER_NOW")}
      </Typography>
      {allowSessionOnDemandValue === 1 && eventEnd ?
      <Typography variant="body2" component="p" sx={(theme) => ({ mt: 1, color: theme.palette.text.secondary })}>{t("WATCH_RECORDING_REGISTER_DESC")}</Typography> : null}
    </Box>
  );
};

export default WebinarTitle;
