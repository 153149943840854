// Language: Korean

export default {
    "already-registered": "이미 등록되었습니다",
    "add-to-calendar": "일정에 추가하십시오.",
    "event-starts-in": "이벤트 시작",
    "event-is-live": "이벤트는 라이브입니다",
    "event-has-ended": "이벤트는 종료되었습니다",
    "powered-by": "전원 공급자",
    "please-enter-your-email-to-continue": "계속하려면 이메일을 입력하십시오",
    add: "추가",
    offers: "제안",
    free: "무료",
    "available-till": "{{DATE}}까지 사용 가능",
    "enter-email-address": "이메일 주소를 입력하십시오",
    "sold-out": "매진",
    "the-ticket-has-reached-the-maximum-number": "티켓은 최대수에 도달했습니다",
    "seat-left": "{{SEAT}} 자리 남음",
    "seats-left": "{{SEAT}} 자리 남음",
    proceed: "진행",
    summary: "요약",
    "buyer-info": "구매자 정보",
    continue: "계속",
    "attendees-info": "참석자 정보",
    "registration": "등록",
    "attendees-information": "참석자 정보",
    submit: "제출",
    "register-to-join-events": "이벤트에 참여하려면 등록하십시오",
    "by-clicking-on-the-button": "버튼을 누르면 이 이벤트에 등록할 수 있는 매표 플랫폼으로 리디렉션됩니다.",
    other: "기타",
    "please-enter-a-value-for": "<FIELD_NAME>에 값을 입력하십시오",
    "please-select-a-value-for": "<FIELD_NAME>의 값을 선택하십시오",
    "enter-other-value-for": "<FIELD_NAME>에 다른 값을 입력하십시오",
    "enter-a-valid": "유효한<FIELD_NAME>을 입력하십시오",
    "should-be-at-least": "<FIELD_NAME>은 적어도<FIELD_LENGTH> <FIELD_TYPE>이어야 합니다 ",
    "cannot-be-more-than": "<FIELD_NAME>은<FIELD_LENGTH> <FIELD_TYPE>보다 더 길 수 없습니다",
    "is-required": "<FIELD_NAME>이 필요합니다.",
    "at-least-required": "적어도<FIELD_LENGTH> <FIELD_NAME> <VERB>가 필요합니다",
    "maximum-allowed": "최대<FIELD_LENGTH> <FIELD_NAME> <VERB>를 허용합니다",
    "user-with-same-email-id-already-exists":
        "동일한 이메일 ID를 가진 사용자가 이미 존재합니다. 다른 것을 사용하십시오.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "동일한 이메일 ID를 가진 사용자가 이번 이벤트에 이미 등록되었습니다. 다른 것을 사용하십시오.",
    "mail-successfully-sent": "메일이 성공적으로 전송되었습니다!",
    "we-are-unable-to-deliver-your-mail-at-this-moment": "지금은 메일을 보낼 수 없습니다! 잠시 후 다시 시도하십시오",
    "something-went-wrong": "문제가 생겼습니다! 나중에 다시 시도하십시오",
    "please-select-a-ticket-to-proceed": "계속하려면 티켓을 선택하십시오",
    "register-now": "지금 등록하십시오",
    "add-to-your-calendar": "일정에 추가하십시오.",
    calendar: "달력",
    "resend-link": "링크를 다시 보내십시오",
    "go-to-event": "이벤트로 이동하십시오",
    "ticket-registration-done-by": "티켓 등록 완료자",
    "you-have-already-purchased-the-ticket-for": "이미 다음에 대한 티켓을 구매했습니다",
    purchased: "구매함",
    "event-date": "이벤트 날짜",
    "ticket-quantity": "티켓 수량",
    payment: "지급",
    "unable-to-submit-data": "데이터를 제출할 수 없습니다",
    "unable-to-apply-promo-code": "프로모션 코드를 적용할 수 없습니다",
    "invalid-promo-code": "무효한 프로모션 코드",
    "apply-now": "적용하다",
    "enter-discount-coupon": "할인 쿠폰을 입력하십시오",
    "same-as-buyer": "구매자와 동일",
    "please-check-all-the-fields-are-correctly-filled": "모든 필드가 올바르게 기입되었는지 확인하십시오",
    "ticket-purchased": "티켓 구매함",
    "ticket-purchase-failed": "티켓 구매 실패함",
    "you-have-successfully-purchased-the-ticket":
        "티켓을 성공적으로 구매했습니다 이 티켓으로 이벤트에 참석할 수 있습니다. 구매해 주셔서 감사합니다!",
    "there-was-an-error-while-booking-your-tickets": "티켓 예약 중 오류가 발생했습니다. 다시 시도하십시오",
    "try-again": "다시 시도하십시오",
    "you-dont-have-access": "티켓 중 일부를 구매할 수 있는 권한이 없습니다",
    "please-enter-a-valid-email": "유효한 이메일을 입력하십시오",
    "payment-summary": "지급 요약",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}}이(가) 성공적으로 적용되었습니다. 추가로 {{AMOUNT}}을(를) 절약했습니다.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "세금계산서를 생성하고 싶으신가요?",
    "taxation-tnc-pp": "계속하면 귀하는 당사의",
    "taxation-tnc": "이용약관",
    and: "그리고",
    "taxation-pp": "개인 정보 정책",
    "company-name-label": "회사 이름",
    "company-name-place-holder": "회사 이름 입력",
    "taxId-label": "회사 세금 ID",
    "taxId-place-holder": "회사 세금 ID 입력",
    "company-address-label": "회사 주소",
    "company-address-place-holder": "주소",
    "country-place-holder": "국가 선택",
    "state-place-holder": "상태 선택",
    "city-place-holder": "도시 입력",
    "zipcode-label": "우편번호를",
    "zipcode-place-holder": "우편 번호를 입력하세요",
    "company-error-msg": "회사 이름을 입력하십시오",
    "taxId-error-msg": "유효한 법인세 ID를 입력하세요.",
    "address-error-msg": "주소를 입력하세요",
    "country-error-msg": "국가를 선택하십시오",
    "state-error-msg": "주를 선택하십시오",
    "city-error-msg": "도시를 입력하세요",
    "zipcode-error-msg": "우편번호를 입력하세요",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "이름",
    lastName: "성",
    email: "이메일",
    phone: "핸드폰",
    dialerCode: "다이얼러 코드",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "에 대한 티켓을 이미 구매했습니다.",
    "upload-file": "파일 업로드",
    "browse-a-file-to-upload": "업로드할 파일 찾아보기",
    "max-size": "최대 크기",
    "file-supported-types": "지원되는 파일 형식",
    "uploaded-successfully": "성공적으로 업로드됨",
    "failed!-please-retry": "실패한! 다시 시도하십시오",
    "this-file-type-is-not-supported-supported-attachments-are":
        "이 파일 형식은 지원되지 않습니다. 지원되는 첨부 파일은",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "파일 크기가 너무 큽니다. 다음보다 작은 문서를 업로드하십시오.",
    person: "사람",
    select: "선택하다",
    search: "찾다",
    "uploaded-file": "업로드된 파일",
    uploading: "업로드 중",
    delete: "삭제",
    "no-item": "항목 없음",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "구글 캘린더",
    "outlook-calendar": "아웃룩 캘린더",
    "yahoo-calendar": "야후 캘린더",
    "read-more": "더 읽기",
    "read-less": "덜 읽기",
    "about-title": "약",
    "verb-are": "~이다",
    "verb-is": "~이다",
    character: "캐릭터",
    // [HUB-9250] Date format translation
    "date-format": "일/월/년",
    "timer-hr": "시간",
    "timer-hrs": "시간",
    "timer-min": "분",
    "timer-mins": "분",
    "timer-sec": "초",
    "timer-secs": "초",
    "timer-day": "일",
    "timer-days": "일",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "선택한 파일이 없습니다.",
    today: "오늘",
    yesterday: "어제",
    tomorrow: "내일",
    last: "마지막",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "등록이 성공적으로 완료되었습니다!",
    registrationSuccessDetail: "이벤트에 성공적으로 등록했습니다. 감사합니다!",
    "search-here": "여기에서 검색..",
    "did-you-mean": "Apakah maksud Anda ",
    DISPOSABLE_EMAIL: "주어진 이메일 주소는 일회용 이메일 서비스에 속합니다",
    RISTRICTED_DOMAIN: "지정된 이메일 주소는 제한된 도메인 내에 있습니다. 유효한 이메일 주소를 입력하십시오",
    INVALID_EMAIL: "유효한 이메일을 입력하십시오",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "결제 방법 선택",
    PAY_NOW: "지금 지불",
    INSTANT_TICKET_CONFIRMATION: "즉시 티켓 확인",
    PAY_VIA_OTHER_METHODS: "다른 방법을 통해 지불",
    FUNDS_TRANSFER: "자금 이체",
    DIGITAL_WALLET: "디지털 지갑",
    CHEQUE: "수표",
    PAY_AT_VENUE: "장소에서 지불",
    TICKET_NOT_CONFIRMED_ORGANIZER: "티켓(들)이벤트 주최자가 지불이 접수 된 것을 승인하고 취소 될 수 있습니다 때까지 확인되지 않습니다.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "티켓(들)은 이벤트 장소에서 지불을 할 때까지 확인되지 않습니다 및 취소 될 수 있습니다.",
    TICKET_CONFIRMED_BY_ORGANIZER: "티켓(들)이벤트 주최자가 지불을받은 것을 승인 할 때 확인됩니다.",
    COMPLETE_PAYMENT_OF: "완전한 지불",
    COMPLETE_PAYMENT_TICKET: "티켓(들)에 대한 지불을 완료합니다.",
    PAYMENT_CONFIRMATION_TITLE: "이벤트 주최자의 고유 거래 아이디를 입력하여 결제를 확인합니다.",
    ENTER_TRANSACTION_ID: "트랜잭션 아이디 입력",
    SHARE_DETAILS_TITLE: "당신이 세부 사항을 공유하는 경우 이벤트 주최자는 지불을 승인 할 수 있습니다. 무료 티켓을 포함한 항공권은 승인 후 확인됩니다.",
    UPLOAD_SCREENSHOT: "스크린샷 업로드(선택 사항)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "지불 세부 정보 공유",
    TICKET_STATUS: "티켓 상태", // not found
    ORGANIZER_YET_TO_CONFIRM: "이벤트 주최자는 아직 지불이 접수되었는지 확인합니다.",
    TICKET_CONFIRMATION_ORGANIZER: "무료 티켓을 포함한 티켓(들)및 송장은 이벤트 주최자의 승인 후 확인 및 공유됩니다.",
    PAYMENT_DETAILS: "지불 세부 사항",
    FAQ_TITLE: "질문(들)의 경우,자주 묻는 질문을 참조하십시오",
    MAKE_PAYMENT_TITLE: "티켓(들)구매를 확인하기 위해 이벤트 장소에서 지불합니다.",
    CONFIRM_TICKET_PRIOR_EVENT: "이벤트 전에 티켓을 확인하려는 경우, 다른 방법으로 지금 지불.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "행사 전에 티켓을 확인하고자 하는 경우,",
    PAY_NOW_2: "지금 지불",
    WITH_DIFFERENT_METHOD: "다른 방법으로",
    PAYMENT_NOT_RCVD: "이벤트 주최자가 지불을 받지 못했습니다.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "이벤트 주최자가 공유한 결제 세부 정보를 확인할 수 없습니다.",
    TICKETS_NOT_AVAILABLE: "티켓은 더 이상 이벤트에 사용할 수 없습니다.",
    PAYMENT_DETAILS_YET_TO_SHARE: "당신은 티켓(들)에 대한 지불 세부 사항을 공유 아직.", // not found in code
    WELCOME_BACK: "다시 오신 것을 환영합니다!",
    VIEW_OR_PROCEED_PURCHASE: "당신은 당신의 이전 티켓을 보거나 방금 선택한 티켓의 구입을 진행할 수 있습니다.",
    VIEW_PREV_TICKETS: "이전 티켓 보기",
    PROCEED_TO_PURCHASE: "구매 진행",
    PREVIOUS_TICKETS: "이전 티켓",
    PENDING_TICKETS: "보류 중인 티켓",
    CONFIRMED_TICKETS: "확인 된 티켓",
    PAYMENT_PENDING: "지불 보류 중",
    AWAITING_ORG_APPROVAL: "주최자 승인 대기 중",
    CHECK_TICKET_STATUS: "티켓 상태 확인",
    PAYMENT_METHOD_NOT_AVAILABLE: "", // not found in code
    HERE: "여기.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "구매 한 모든 티켓에 대한 고유 한 참석자 정보를 친절하게 업데이트하십시오. 이 이벤트에 액세스 할 수있는 모든 참석자를 가능하게 할 것이다.",
    DUPLICATE_EMAIL: "이미 이 티켓을 구매하셨습니다. 재등록을 선택할 수 있습니다. 이 경우 가장 최근에 입력한 데이터가 저장됩니다.",
    DEFAULT_DUPLICATE_EMAIL: "이 이메일은 이미 다른 티켓에 사용되었습니다. 입력한 이메일 주소를 다시 확인하거나 여러 티켓에 동일한 이메일을 사용해도 됩니다.",
    "REGISTER_NOW": "지금 등록하십시오",
    "REG_FAILED": "등록 실패",
    "REG_FAILED_DESC": "등록에 실패했습니다. 다시 시도해 주십시오.",
    "REG_DONE_BY": "등록 완료자",
    "INVITE_AGAIN": "다시 초대하시겠습니까?",
    "REG_SUCCESS_DONE": "등록이 성공적으로 완료되었습니다",
    "WATCH_REC_LINK": "녹화를 시청할 수 있는 링크가 포함된 이메일이 다음 주소로 전송되었습니다",
    "WEBINAR_INV_LINK": "웨비나 링크가 포함된 초대 이메일이 다음 주소로 전송되었습니다",
    "MAIL_SENT_SUCCESS": "메일이 성공적으로 전송되었습니다",
    "ALREADY_REGISTERED": "이미 등록했습니다",
    "RESEND_INVITE": "초대 재전송",
    "WATCH_RECORDING_REGISTER_DESC": "아래 등록하시면 웨비나 녹화를 시청하실 수 있습니다.",

};
