/* eslint-disable  */
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme:any) => ({
  '@keyframes skeleton': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.7,
    },
    '100%': {
      opacity: 1,
    },
  },
  skeleton: {
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    borderRadius: 4,
    animation: '$skeleton 1.5s ease-in-out 0.5s infinite',
    '&[data-variant=text]': {
      transform: 'scale(1, 0.60)',
      display: 'block',
      transformOrigin: '0 60%',
    },
    '&[data-variant=round]': {
      borderRadius: '50%',
      display: 'block',
    },
    '&[data-variant=square]': {
      display: 'block',
    },
    '&[data-variant=rect]': {
      display: 'block',
    },
  },
}));
export type SkeletonProps = {
    className?:string;
    variant?:string;
    height?:string;
    width?:string;
    mt?:string;
    mb?:string;
    ml?:string;
}

const  SkeletonLoader = ({
  className = "",
  variant = "text",
  width,
  height,
  mt,
  ml,
  mb
}:SkeletonProps):JSX.Element => {
  const { skeleton } = useStyles();
  return (
    <Box
      mt={mt}
      ml={ml}
      mb={mb}
      className={`${skeleton} ${className}`}
      component="span"
      height={height}
      width={width}
      data-variant={variant}
    />
  );
};
export default SkeletonLoader;
