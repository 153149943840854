import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  inputEmailWrapperWidget:{
    width: "100%",
    height: "auto",
    position: "relative",
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider.default}`,
    color: theme.palette.primary.main,
    backgroundColor:theme.palette.primary.lighter,
    borderRadius: "0.8rem",
    padding:"1.6rem",
  },
  infoViewIcon: {
    color: theme.palette.primary.main,
  },
  sliderBox: {
    border: `1px solid ${theme.palette.divider.default}`,
    color: theme.palette.text.primary,
    borderRadius: "5px",
    padding: "10px 15px",
    marginRight: theme.spacing(1),
    width: "auto !important",
    cursor: "pointer",
    marginLeft: "1px",
    lineHeight:"1.4 !important",
    fontFamily: "Helvetica Neue New",
    fontSize: "14px",
    "&>label": {
      cursor: "pointer",
    },
    "&.active": {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },
  stepper: {
    "& .MuiStepLabel-alternativeLabel": {
      marginTop: theme.spacing(0.5),
      fontSize: "12px",
      color: theme.palette.text.secondary,
      fontWeight: 400,
      "&.Mui-active": {
        color: theme.palette.primary.main,
      },
      "&.Mui-completed": {
        color: theme.palette.primary.main,
      },
    },
    "& .MuiStepIcon-root": {
      height: 20,
      width: 20,
      borderRadius: "50%",
      background: theme.palette.divider.default,
      position: "relative",
      "&.Mui-active": {
        background: theme.palette.primary.main,
      },
      "&.Mui-completed": {
        background: theme.palette.primary.main,
      },
      "&>circle": {
        display: "none",
      },
      "&>path": {
        display: "none",
      },
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepConnector-root": {
      top: 17,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
      "&>.MuiStepConnector-line": {
        border: `1.5px solid ${theme.palette.divider.default}`,
      },
      "&.Mui-active": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
        "&~.MuiStepLabel-alternativeLabel": {
          "& .MuiStepLabel-iconContainer": {
            position: "relative",
            "&::before": {
              content: "",
              position: "absolute",
              display: "block",
              borderRadius: "50%",
              background: theme.palette.primary.lighter,
              height: 28,
              width: 28,
              top: -4,
              left: -4,
            },
          },
        },
      },
      "&.Mui-completed": {
        "&>span": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
  attendeesDetailsWrapper: {
    marginTop: 20,
    "& .MuiCardContent-root": {
      backgroundColor: theme.palette.background.cardColor,
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider.default}`,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: 12,
    },
    "& .MuiOutlinedInput-root": {
      marginBottom: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      fontSize: 12,
    },
  },
  checkBoxGroupWrapper: {
    "& .MuiFormControlLabel-root": {
      border: `1px solid ${theme.palette.divider.default}`,
      paddingRight: 16,
      marginLeft: 0,
      borderRadius: 6,
      width: "30%",
      marginBottom: 12,
    },
  },
  countryCodeWrapper: {
    "& .countryCode": {
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
        },
      },
      "&:hover": {
        "& fieldset": {
          borderColor: `${theme.palette.divider.default} !important`,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRight: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    "& .phoneNumberTextBox": {
      "&:hover": {
        "& fieldset": {
          borderColor: `${theme.palette.divider.default} !important`,
        },
      },
      "& input": {
        paddingLeft: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderLeft: 0,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
  },
  tabsSlider: {
    "& .oneSideArrow": {
      "&>.slick-prev, .slick-next": {
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.divider.default}`,
      },
      "&>.slick-disabled": {
        backgroundColor: theme.palette.background.gray,
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  mobFooterStyle: {
    marginTop: "0px",
    padding: theme.spacing(1.5),
    overflowY: "auto",
    // maxHeight: "calc((var(--vh, 1vh) * 100) - 4rem)",
    "& .continueBtn": {
      position: "absolute",
      bottom: 0,
      width: "100%",
      left: 0,
      padding: theme.spacing(2),
    },
    "& .title": {
      marginTop: theme.spacing(5),
    },
  },
  suggestionBox: {
    fontSize: "12px!important",
    fontWeight: "500",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    marginTop: "0rem",
    marginLeft: "0",
    display: "block",
    position: "relative",
    top: "0px",
    color: "rgba(53,53,53,0.65)",
}
}));