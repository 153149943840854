/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import TextField from "./InputField";
import Box from "@mui/material/Box";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: any) => ({
  checkBoxLandingWrapper:{
    "& .MuiFormControlLabel-root": {
      minHeight:"40px",
      "& .MuiSvgIcon-root":{
        width:"1.5em",
        height:"1.5em",
        fontSize:"14px",
      }
    }
  },
  checkBoxGroupWrapper: {
    "& .MuiFormControlLabel-root": {
      border: `1px solid ${theme.palette.divider.default}`,
      paddingRight: 16,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 10,
      borderRadius: 4,
      width: "100%",
      "& svg":{
        height:"20px",
        width:"20px",
        fontSize:"14px",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
    "& .active": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .MuiTypography-root": {
      fontWeight: 400,
      marginTop:"0px !important",
      marginBottom:"0px !important",
    },
  },
  checkListBox:{
    textAlign:"left",
  },
  otherField: {
    marginTop: 5,
  },
}));

interface ITOptions {
  label?: string;
  value?: string;
  isChecked?: boolean;
}

interface IWrapperProps {
  id?: string;
  isVisible?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  hasError?: boolean;
  label?: string;
  isRequired?: boolean;
  disabled?: boolean;
  errMsg?: string;
  handleChange?: (arg1: string, arg2: string[]) => void;
  handleOtherChange?: (arg1: string, arg2: any) => void;
  otherValue?: string;
  options?: ITOptions[];
  initTabIndex?: number;
  isMandatory?: any;
}

const CheckBoxGroup = (props: IWrapperProps): JSX.Element => {
  const {
    options = [],
    label,
    value = [],
    otherValue = "",
    disabled,
    id = "",
    errMsg = "",
    handleChange,
    handleOtherChange,

    initTabIndex = 0,
    isMandatory,
  } = props;

  const classes = useStyles();
  const [t] = useTranslation();
  const [getOther, setOther] = useState<string>("");
  useEffect(() => {
    setOther(otherValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any, select: any): void => {
    let newValue = [...value];
    if (e.target?.checked) {
      newValue.push(select);
    } else {
      const index = newValue.indexOf(select);
      if (index > -1) {
        newValue.splice(index, 1);
      }
    }
    newValue = newValue.filter(
      (value, index, self) => self.indexOf(value.trim()) === index
    ); //only unique values
    const pureId = id?.split("-").length > 1 ? id.split("-")[0] : id;
    if (handleChange && pureId) handleChange(pureId, newValue);
  };

  const handleOtherOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const pureId = id?.split("-").length > 1 ? id.split("-")[0] : id;
    if (handleOtherChange) handleOtherChange(pureId, e);
  };

  const onChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOther(e.target.value);
  };
  const {
    store,
  } = useStore();
  return (
    <Box className={classes.checkListBox}>
      {label ? (
        <Typography
          component="label"
          variant="caption"
          htmlFor={id}
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
            textAlign:"left",
            marginBottom:"2px",
            display:"block"
         })}
        >
          {label}{" "}
          {isMandatory ? (
            <Typography
              component="span"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                lineHeight: 0,
              })}
            >
              *
            </Typography>
          ) : (
            ""
          )}{" "}
        </Typography>
      ) : null}

      <FormGroup
        sx={{ display: "flex", flexDirection: "row" }}
        className={`${classes.checkBoxGroupWrapper} ${store.startFromSummary ? classes.checkBoxLandingWrapper:""}`}
      >
        {options.map((option: ITOptions, index: number) => {
          // const isChecked = value.includes(option.label);
          const isChecked: boolean = value && value?.includes(option?.label); // String(value).toLowerCase() === String(option.label).toLowerCase();
          return (
            <FormControlLabel
              key={index}
              className={isChecked ? "active" : ""}
              control={
                <Checkbox
                  size="small"
                  key={`${id}-${option.label}`}
                  onChange={(e) => handleOnChange(e, option.label)}
                  disabled={disabled}
                  name={label}
                  id={`${id}-${option.label}`}
                  value={option.label}
                  checked={isChecked}
                  tabIndex={initTabIndex + index}
                />
              }
              label={
                <Typography
                  variant="caption"
                  component="p"
                  color="textPrimary"
                  sx={{ fontSize: 14, fontWeight: 700 }}
                >
                  {option.label}
                </Typography>
              }
            />
          );
        })}
      </FormGroup>

      {value.includes(t("other")) ? (
        <TextField
          key={id}
          id={id}
          placeHolder={t("other")}
          onChange={onChangeOther}
          onBlur={(e: any) => handleOtherOnChange(e)}
          type="text"
          maxLength={40}
          value={getOther}
          // errorMsg={errMsg}
          disabled={disabled}
          tabIndex={options.length + initTabIndex}
          className={classes.otherField}
        />
      ) : (
        false
      )}
      {errMsg ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            color: theme.palette.error.main,
            fontWeight: 400,
            marginTop: "2px",
          })}
        >
          {errMsg}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default CheckBoxGroup;
