import React from "react";
import { IIconProps } from "./types";

const InfoBulbIcon = ({height="23",width="16",className=""}:IIconProps): JSX.Element => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 16 23" fill="none">
<path d="M13.09 2.82001C12.1703 2.06359 11.0933 1.52204 9.93766 1.23485C8.782 0.947666 7.5768 0.922096 6.41 1.16001C4.85638 1.47383 3.43099 2.24208 2.31469 3.36729C1.19838 4.49249 0.441475 5.92394 0.140003 7.48001C-0.0801505 8.64673 -0.0401452 9.84766 0.257165 10.9971C0.554475 12.1466 1.10177 13.2163 1.86 14.13C2.56369 14.9241 2.96704 15.9395 3 17V20C3 20.7957 3.31607 21.5587 3.87868 22.1213C4.44129 22.6839 5.20435 23 6 23H10C10.7957 23 11.5587 22.6839 12.1213 22.1213C12.6839 21.5587 13 20.7957 13 20V17.19C13.0336 16.0192 13.4637 14.8944 14.22 14C15.5451 12.3608 16.1698 10.2648 15.9582 8.16763C15.7466 6.07044 14.7159 4.14149 13.09 2.80001V2.82001ZM11 20C11 20.2652 10.8946 20.5196 10.7071 20.7071C10.5196 20.8947 10.2652 21 10 21H6C5.73479 21 5.48043 20.8947 5.2929 20.7071C5.10536 20.5196 5 20.2652 5 20V19H11V20ZM12.67 12.76C11.6645 13.9526 11.0779 15.442 11 17H9V14C9 13.7348 8.89465 13.4804 8.70711 13.2929C8.51957 13.1054 8.26522 13 8 13C7.73479 13 7.48043 13.1054 7.2929 13.2929C7.10536 13.4804 7 13.7348 7 14V17H5C4.97362 15.4681 4.40695 13.9948 3.4 12.84C2.73564 12.044 2.28889 11.0894 2.10331 10.0694C1.91773 9.04928 1.99965 7.99852 2.34109 7.01954C2.68253 6.04055 3.27182 5.16675 4.05153 4.48333C4.83123 3.79992 5.77472 3.33021 6.79 3.12001C7.66256 2.94035 8.56416 2.95725 9.42937 3.16948C10.2946 3.3817 11.1017 3.78393 11.7921 4.34696C12.4824 4.90999 13.0388 5.61969 13.4207 6.42453C13.8026 7.22937 14.0005 8.10915 14 9.00001C14.0074 10.3699 13.5371 11.6995 12.67 12.76Z" fill="currentColor"/>
</svg>);
};

export default InfoBulbIcon;