import React from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";

interface ToolTipProps {
  children?: React.ReactNode;
  title?: React.ReactChild;
  open?: boolean;
  onClose?: ()=>void;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start";
}

const CustomTip = withStyles((theme: any) => ({
  tooltip: {
    backgroundColor: theme.palette.background.cardColor,
    color: theme.palette.text.primary,
    maxWidth: 280,
    fontSize: 12,
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.25)",
    padding: theme.spacing(1),
    lineHeight: 1.4,
  },
  arrow: {
    color: theme.palette.background.cardColor,
  },
}))(Tooltip);

const CustomToolTip = ({
  children,
  title,
  placement,
  open,
  onClose,
}: ToolTipProps): JSX.Element => {
  return (
    <>
      <CustomTip
        title={(title as React.ReactChild)}
        placement={placement || "bottom-end"}
        arrow
        open={open}
        onClose={onClose}
      >
        <Box display="inline-block">{children}</Box>
      </CustomTip>
    </>
  );
};

export default CustomToolTip;
