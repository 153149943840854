// Language: Vietnamese

export default {
    "already-registered": "Đã đăng ký",
    "add-to-calendar": "Thêm vào lịch",
    "event-starts-in": "Sự kiện bắt đầu sau",
    "event-is-live": "SỰ KIỆN LÀ TRỰC TIẾP",
    "event-has-ended": "SỰ KIỆN ĐÃ KẾT THÚC",
    "powered-by": "Được cung cấp bởi",
    "please-enter-your-email-to-continue": "Vui lòng nhập email của bạn để tiếp tục",
    add: "cộng",
    offers: "Ưu đãi",
    free: "Rảnh rỗi",
    "available-till": "Có sẵn cho đến ngày {{DATE}}",
    "enter-email-address": "Nhập địa chỉ email",
    "sold-out": "Bán hết",
    "the-ticket-has-reached-the-maximum-number": "Vé đã đạt đến số lượng tối đa",
    "seat-left": "Còn {{SEAT}} chỗ",
    "seats-left": "Còn {{SEAT}} ghế",
    proceed: "Tiếp tục",
    summary: "Bản tóm tắt",
    "buyer-info": "Thông tin người mua",
    continue: "Tiếp tục",
    "attendees-info": "Thông tin người tham dự",
    "attendees-information": "Thông tin người tham dự",
    submit: "Nộp",
    "register-to-join-events": "Đăng ký tham gia các sự kiện",
    "by-clicking-on-the-button":
        "Bằng cách nhấp vào nút, bạn sẽ được chuyển hướng đến nền tảng bán vé nơi bạn có thể đăng ký sự kiện này.",
    other: "Khác",
    "please-enter-a-value-for": "Vui lòng nhập giá trị cho <FIELD_NAME>",
    "please-select-a-value-for": "Vui lòng chọn một giá trị cho <FIELD_NAME>",
    "enter-other-value-for": "Nhập giá trị khác cho <FIELD_NAME>",
    "enter-a-valid": "Nhập một <FIELD_NAME> hợp lệ",
    "should-be-at-least": "<FIELD_NAME> ít nhất phải là <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> không được nhiều hơn <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> là bắt buộc.",
    "at-least-required": "Yêu cầu ít nhất <FIELD_LENGTH> <FIELD_NAME> <VERB>",
    "maximum-allowed": "Cho phép <FIELD_LENGTH> <FIELD_NAME> <VERB> tối đa",
    "user-with-same-email-id-already-exists": "Người dùng có cùng id email đã tồn tại. Vui lòng sử dụng một cái khác.",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "Người dùng có cùng id email đã đăng ký cho sự kiện này. Vui lòng sử dụng một cái khác.",
    "mail-successfully-sent": "Đã gửi thư thành công!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "Chúng tôi không thể gửi thư của bạn vào lúc này! Xin hãy thử lại sau một vài giây",
    "something-went-wrong": "Có gì đó sai! Vui lòng thử lại sau",
    "please-select-a-ticket-to-proceed": "Vui lòng chọn một vé để tiếp tục",
    "register-now": "Đăng ký ngay",
    "add-to-your-calendar": "Thêm vào Lịch của bạn",
    calendar: "lịch",
    "resend-link": "Gửi lại liên kết",
    "go-to-event": "Đi tới sự kiện",
    "ticket-registration-done-by": "Đăng ký vé được thực hiện bởi",
    "registration": "Đăng ký",
    "you-have-already-purchased-the-ticket-for": "Bạn đã mua vé cho",
    purchased: "Đã mua",
    "event-date": "Ngày sự kiện",
    "ticket-quantity": "Số lượng vé",
    payment: "Sự chi trả",
    "unable-to-submit-data": "Không thể gửi dữ liệu",
    "unable-to-apply-promo-code": "Không thể áp dụng mã khuyến mãi",
    "invalid-promo-code": "Mã khuyến mại không hợp lệ",
    "apply-now": "Nộp đơn",
    "enter-discount-coupon": "Nhập phiếu giảm giá",
    "same-as-buyer": "Giống như người mua",
    "please-check-all-the-fields-are-correctly-filled":
        "Vui lòng kiểm tra tất cả các trường đã được điền chính xác chưa",
    "ticket-purchased": "Vé đã mua",
    "ticket-purchase-failed": "Mua vé không thành công",
    "you-have-successfully-purchased-the-ticket":
        "Bạn đã mua vé thành công. Vé này sẽ cho phép bạn tham dự sự kiện. Cám ơn vì đã mua hàng!",
    "there-was-an-error-while-booking-your-tickets": "Đã xảy ra lỗi khi đặt vé của bạn. Vui lòng thử lại.",
    "try-again": "Thử lại",
    "you-dont-have-access": "Bạn không có quyền truy cập để mua một số vé",
    "please-enter-a-valid-email": "Vui lòng nhập email hợp lệ",
    "payment-summary": "Tóm tắt Thanh toán",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "Đã áp dụng thành công {{CODE}}. Bạn đã tiết kiệm thêm {{AMOUNT}}.",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "Bạn muốn tạo Hóa đơn thuế?",
    "taxation-tnc-pp": "Bằng cách tiếp tục, bạn đồng ý với",
    "taxation-tnc": "Các điều khoản và điều kiện",
    and: "và",
    "taxation-pp": "Chính sách bảo mật",
    "company-name-label": "Tên công ty",
    "company-name-place-holder": "Nhập tên công ty",
    "taxId-label": "Mã số thuế công ty",
    "taxId-place-holder": "Nhập mã số thuế công ty",
    "company-address-label": "địa chỉ công ty",
    "company-address-place-holder": "Địa chỉ nhà",
    "country-place-holder": "Chọn quốc gia",
    "state-place-holder": "Chọn tiểu bang",
    "city-place-holder": "Nhập vào thành phố",
    "zipcode-label": "mã Zip",
    "zipcode-place-holder": "Nhập mã zip",
    "company-error-msg": "Vui lòng nhập Tên công ty",
    "taxId-error-msg": "Vui lòng nhập ID thuế công ty hợp lệ",
    "address-error-msg": "Vui lòng nhập địa chỉ",
    "country-error-msg": "Vui lòng chọn quốc gia",
    "state-error-msg": "Vui lòng chọn tiểu bang",
    "city-error-msg": "Vui lòng nhập Thành phố",
    "zipcode-error-msg": "Vui lòng nhập mã Zip",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "Họ",
    lastName: "Họ",
    email: "E-mail",
    phone: "Điện thoại",
    dialerCode: "Mã trình quay số",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "Bạn đã mua vé cho",
    "upload-file": "Cập nhật dử liệu",
    "browse-a-file-to-upload": "Duyệt một tệp để tải lên",
    "max-size": "Kích thước tối đa",
    "file-supported-types": "Các loại tệp được hỗ trợ",
    "uploaded-successfully": "Tải lên thành công",
    "failed!-please-retry": "Thất bại! Xin hãy thử lại",
    "this-file-type-is-not-supported-supported-attachments-are":
        "Loại tệp này không được hỗ trợ. Các tệp đính kèm được hỗ trợ là",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "Kích thước tệp quá cao. Vui lòng tải lên tài liệu ít hơn",
    person: "Người",
    select: "Lựa chọn",
    search: "Tìm kiếm",
    "uploaded-file": "Tệp đã tải lên",
    uploading: "Đang tải lên",
    delete: "Xóa bỏ",
    "no-item": "Không có mặt hàng",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Lịch Google",
    "outlook-calendar": "Vọng Lịch",
    "yahoo-calendar": "Yahoo Lịch",
    "read-more": "Đọc nhiều",
    "read-less": "Đọc ít",
    "about-title": "Về",
    "verb-are": "Chúng tôi",
    "verb-is": "Là",
    character: "nhân vật",
    // [HUB-9250] Date format translation
    "date-format": "DD/MM/NĂM",
    "timer-hr": "Giờ",
    "timer-hrs": "Giờ",
    "timer-min": "Phút",
    "timer-mins": "Phút",
    "timer-sec": "Giây",
    "timer-secs": "Giây",
    "timer-day": "Ngày",
    "timer-days": "Ngày",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "Không có tệp nào được chọn",
    today: "hôm nay",
    yesterday: "hôm qua",
    tomorrow: "ngày mai",
    last: "cuối cùng",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "Đăng Ký Thành Công Xong!",
    registrationSuccessDetail: "Bạn đã thành công đăng ký cho các sự kiện. Cảm ơn bạn!",
    "search-here": "Tìm kiếm ở đây..",
    "did-you-mean": "Ý bạn là ",
    DISPOSABLE_EMAIL: "Địa chỉ email đã cho thuộc về dịch vụ email dùng một lần",
    RISTRICTED_DOMAIN: "Địa chỉ email đã cho nằm trong một miền hạn chế. Vui lòng nhập một địa chỉ email hợp lệ",
    INVALID_EMAIL: "Vui lòng nhập email hợp lệ",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "Chọn phương thức thanh toán",
    PAY_NOW: "Thanh toán ngay",
    INSTANT_TICKET_CONFIRMATION: "Xác nhận vé ngay lập tức",
    PAY_VIA_OTHER_METHODS: "Thanh toán qua các phương thức khác",
    FUNDS_TRANSFER: "Chuyển Tiền",
    DIGITAL_WALLET: "Ví Kỹ Thuật Số",
    CHEQUE: "Chi phiếu",
    PAY_AT_VENUE: "Thanh toán Tại Địa điểm",
    TICKET_NOT_CONFIRMED_ORGANIZER: "(Các) vé sẽ không được xác nhận cho đến khi người tổ chức sự kiện chấp thuận rằng khoản thanh toán đã được nhận và có thể bị hủy.",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "(Các) vé sẽ không được xác nhận cho đến khi bạn thực hiện thanh toán tại địa điểm tổ chức sự kiện và có thể bị hủy bỏ.",
    TICKET_CONFIRMED_BY_ORGANIZER: "(Các) vé sẽ được xác nhận khi người tổ chức sự kiện chấp thuận rằng khoản thanh toán đã được nhận.",
    COMPLETE_PAYMENT_OF: "Hoàn thành thanh toán",
    COMPLETE_PAYMENT_TICKET: "Hoàn tất thanh toán cho(các) vé của bạn.",
    PAYMENT_CONFIRMATION_TITLE: "Nhập Id Giao dịch duy nhất cho người tổ chức sự kiện để xác minh thanh toán của bạn.",
    ENTER_TRANSACTION_ID: "Nhập Id Giao DỊCH",
    SHARE_DETAILS_TITLE: "Người tổ chức sự kiện chỉ có thể chấp thuận thanh toán của bạn nếu bạn chia sẻ chi tiết. (Các) vé của bạn, bao gồm bất kỳ(các) vé miễn phí nào, sẽ được xác nhận sau khi phê duyệt.",
    UPLOAD_SCREENSHOT: "Tải Lên Ảnh Chụp màn hình (tùy chọn)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "Chia Sẻ Chi Tiết Thanh Toán",
    TICKET_STATUS: "Tình Trạng Vé", // not found
    ORGANIZER_YET_TO_CONFIRM: "Người tổ chức sự kiện vẫn chưa xác nhận rằng khoản thanh toán đã được nhận.",
    TICKET_CONFIRMATION_ORGANIZER: "(Các) vé của bạn, bao gồm bất kỳ(các) vé miễn phí nào và hóa đơn sẽ được xác nhận và chia sẻ với bạn sau khi người tổ chức sự kiện chấp thuận.",
    PAYMENT_DETAILS: "Chi Tiết Thanh Toán",
    FAQ_TITLE: "Trong trường hợp của bất kỳ câu hỏi(s), tham khảo Các Câu Hỏi Thường Gặp",
    MAKE_PAYMENT_TITLE: "Thực hiện thanh toán tại địa điểm tổ chức sự kiện để xác nhận(các) vé của bạn mua.",
    CONFIRM_TICKET_PRIOR_EVENT: "Nếu bạn muốn xác nhận vé của bạn(s) trước khi sự kiện này, trả tiền ngay bây giờ với một phương pháp khác nhau.", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "Nếu bạn muốn xác nhận(các) vé của mình trước sự kiện,",
    PAY_NOW_2: "thanh toán ngay",
    WITH_DIFFERENT_METHOD: "với một phương pháp khác",
    PAYMENT_NOT_RCVD: "Người tổ chức sự kiện đã không nhận được thanh toán.",
    PAYMENT_COULD_NOT_BE_VERIFIED: "Các chi tiết thanh toán được chia sẻ không thể được xác minh bởi người tổ chức sự kiện.",
    TICKETS_NOT_AVAILABLE: "Các vé không còn có sẵn cho sự kiện này.",
    PAYMENT_DETAILS_YET_TO_SHARE: "Bạn vẫn chưa chia sẻ chi tiết thanh toán cho(các) vé.", // not found in code
    WELCOME_BACK: "Chào mừng trở lại!",
    VIEW_OR_PROCEED_PURCHASE: "Bạn có thể xem vé trước đó của bạn hoặc tiến hành mua vé bạn chỉ cần chọn.",
    VIEW_PREV_TICKETS: "Xem Vé Trước",
    PROCEED_TO_PURCHASE: "Tiến Hành Mua Hàng",
    PREVIOUS_TICKETS: "Vé Trước",
    PENDING_TICKETS: "Vé Chờ",
    CONFIRMED_TICKETS: "Xác Nhận Vé",
    PAYMENT_PENDING: "Thanh toán đang chờ xử lý",
    AWAITING_ORG_APPROVAL: "Đang chờ phê duyệt tổ chức",
    CHECK_TICKET_STATUS: "Kiểm Tra Tình Trạng Vé",
    PAYMENT_METHOD_NOT_AVAILABLE: "phương thức thanh toán không có sẵn, hãy thử lại.", // not found in code
    HERE: "đây.",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "Vui lòng cập nhật thông tin người tham dự duy nhất cho tất cả các vé đã mua. Điều này sẽ cho phép tất cả những người tham dự truy cập vào sự kiện.",
    DUPLICATE_EMAIL: "Bạn đã mua vé này. Bạn có thể chọn đăng ký lại. Trong trường hợp này, dữ liệu được nhập gần đây nhất sẽ được lưu.",
    DEFAULT_DUPLICATE_EMAIL: "Email này đã được sử dụng cho một vé khác. Vui lòng kiểm tra kỹ địa chỉ email bạn đã nhập hoặc thoải mái sử dụng cùng một email cho nhiều vé.",
    "REGISTER_NOW": "Đăng ký ngay",
    "REG_FAILED": "Đăng ký thất bại",
    "REG_FAILED_DESC": "Đăng ký của bạn thất bại Vui lòng thử lại",
    "REG_DONE_BY": "Đăng ký đã hoàn thành bởi",
    "INVITE_AGAIN": "Bạn muốn được mời lại?",
    "REG_SUCCESS_DONE": "Đã thực hiện đăng ký thành công",
    "WATCH_REC_LINK": "Email có kèm theo đường dẫn để xem bản ghi đã được gửi tới",
    "WEBINAR_INV_LINK": "Thư mời có kèm theo đường dẫn hội thảo trực tuyến đã được gửi tới",
    "MAIL_SENT_SUCCESS": "Đã gửi thư thành công",
    "ALREADY_REGISTERED": "Bạn đã đăng ký để",
    "RESEND_INVITE": "Gửi lại Lời mời",
    "WATCH_RECORDING_REGISTER_DESC": "Bạn có thể xem bản ghi hội thảo trực tuyến bằng cách đăng ký dưới đây.",

};
