/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import TextField from "./InputField";
import { IChoices } from "../Types/BuyerDetails";
import Box from "@mui/material/Box";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme: any) => ({
  radioWithLandingWrapper:{
    "& .MuiFormControlLabel-root": {
        minHeight:"40px",
    }
  },
  radioButtonsGroupWrapper: {
    "& .MuiFormControlLabel-root": {
      border: `1px solid ${theme.palette.divider.default}`,
      paddingRight: 16,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 10,
      borderRadius: 4,
      width: "100%",
      "&:last-child": {
        marginBottom: 0,
      },
      "& .MuiRadio-root":{
        padding:theme.spacing(1),
        "& .MuiSvgIcon-root":{
          height:"20px",
          width:"20px",
        }
      }
    },
    "& .active": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .MuiTypography-root": {
      fontWeight: 400,
      marginTop:"0px !important",
      marginBottom:"0px !important",
    },
  },
  otherField: {
    marginTop: 5,
  },
}));

interface IWrapperProps {
  id?: string;
  isVisible?: boolean;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  hasError?: boolean;
  label?: string;
  name?: string;
  isRequired?: boolean;
  disabled?: boolean;
  errMsg?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleChange?: (arg1: string, arg2: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOtherChange?: (arg1: string, arg2: any) => void;
  hasOther?: boolean;
  otherValue?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: IChoices[];
  initTabIndex?: number;
  isMandatory?: any;
}

const RadioButtonsGroup = (props: IWrapperProps): JSX.Element => {
  const {
    store,
  } = useStore();
  const {
    options = [],
    label,
    value,
    otherValue = "",
    disabled,
    id = "",
    errMsg = "",
    handleChange,
    handleOtherChange,
    hasOther = true,
    initTabIndex = 1,
    isMandatory,
  } = props;

  const classes = useStyles();
  const [t] = useTranslation();
  const [getOther, setOther] = useState<string>("");

  useEffect(() => {
    setOther(otherValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherValue]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnChange = (e: any): void => {
    const initVal = e.target?.value;
    const pureId = id?.split("-").length > 1 ? id.split("-")[0] : id;
    if (handleChange && pureId) handleChange(pureId, initVal);
  };

  const handleOtherOnChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const pureId = id?.split("-").length > 1 ? id.split("-")[0] : id;
    if (handleOtherChange) handleOtherChange(pureId, e);
  };

  const onChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOther(e.target.value);
  };
  return (
    <Box sx={{ textAlign: "left !important" }}>
      {label ? (
        <Typography
          component="label"
          variant="caption"
          htmlFor={id}
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
            marginBottom:"2px",
            textAlign: "left",
          })}
        >
          {label}{" "}
          {isMandatory ? (
            <Typography
              component="span"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                lineHeight: 0,
              })}
            >
              *
            </Typography>
          ) : (
            ""
          )}{" "}
        </Typography>
      ) : null}
      <FormGroup
        sx={{ display: "flex", flexDirection: "row", marginTop: "2px" }}
        className={`${classes.radioButtonsGroupWrapper} ${store.startFromSummary ? classes.radioWithLandingWrapper : ""}`}
      >
        {options.map((option: IChoices, index: number) => {
          const isChecked: boolean = String(value).toLowerCase() === String(option.label).toLowerCase();
          return (
            <FormControlLabel
              className={isChecked ? "active" : ""}
              key={index}
              control={
                <Radio
                  sx={{ display: "flex", flexDirection: "row" }}
                  className={`${classes.radioButtonsGroupWrapper}`}
                  key={`${id}-${option.label}`}
                  onClick={handleOnChange}
                  disabled={disabled}
                  name={label}
                  id={`${id}-${option.label}`}
                  value={option.label}
                  checked={isChecked}
                  tabIndex={initTabIndex + index}
                />
              }
              label={
                <Typography
                  variant="caption"
                  color="textPrimary"
                  component="p"
                  sx={{ fontSize: 14, fontWeight: 700 }}
                >
                  {option.label}
                </Typography>
              }
            />
          );
        })}
      </FormGroup>

      {hasOther && value === t("other") ? (
        <TextField
          key={id}
          id={id}
          placeHolder={t("other")}
          onChange={onChangeOther}
          onBlur={(e: any) => handleOtherOnChange(e)}
          type="text"
          maxLength={40}
          value={getOther}
          // errorMsg={errMsg}
          disabled={disabled}
          tabIndex={options.length + initTabIndex}
          className={classes.otherField}
        />
      ) : (
        false
      )}
      {errMsg ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            color: theme.palette.error.main,
            fontWeight: 400,
            marginTop: "2px",
          })}
        >
          {errMsg}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default RadioButtonsGroup;
