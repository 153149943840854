import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useStore } from "Context";

// Interfaces
import { IPaymentDescription } from "../../Types/BuyerDetails";
import { isTicketAmountFloat } from "Utils/common";

// Custom Interfaces
interface ICompProps {
  payments: IPaymentDescription[];
  isMonetaryTransaction?: boolean;
  children?: React.ReactNode;
}

const TicketPaymentInfo = ({
  payments = [],
  isMonetaryTransaction,
  children,
}: ICompProps) => {
   const {store} = useStore();

  
  const isPaymentAvailable =
    payments && Array.isArray(payments) && payments.length;

  const getPaymentGrossAmount = (amounts: IPaymentDescription[]) => {
    if (isPaymentAvailable) {
      const returnData = [] as any;
      amounts.forEach((element: IPaymentDescription) => {
        if (element.type && element.type !== "NET") {
          const isGrossEle = element.type && element.type === "GROSS";
          const isDiscEle = element.type && element.type === "DISCOUNT";

          if (isGrossEle) {
            returnData.push(
              <Box
                key={1}
                display="flex"
                justifyContent="space-between"
                px={store.startFromSummary ? 2.5:2}
              >
                <Typography
                  variant="body2"
                  component="label"
                  sx={{ fontWeight: 400 }}
                  color="textPrimary"
                >
                  {element?.label}
                </Typography>
                {element.amount < 0 ? (
                  <Typography color="textPrimary" variant="body2" sx={{ fontWeight: 400 }}>
                    -{element?.symbol}
                    {Math.abs(element.amount)}
                  </Typography>
                ) : (
                  <Typography color="textPrimary" variant="body2" sx={{ fontWeight: 400 }}>
                    {element?.symbol}
                    {isTicketAmountFloat(element.amount)}
                  </Typography>
                )}
              </Box>
            );
          } else if (isDiscEle) {
            returnData.push(
              <Box
                key={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={0.3}
                px={store.startFromSummary ? 2.5:2}
              >
                <Typography
                  variant="body2"
                  component="label"
                  sx={(theme: any) => ({
                    fontWeight: 400,
                    color: theme.palette.primary.main,
                  })}
                >
                  {element?.label}
                </Typography>
                {element.amount < 0 ? (
                  <Typography
                    variant="body2"
                    component="label"
                    sx={(theme: any) => ({
                      fontWeight: 400,
                      color: theme.palette.primary.main,
                    })}
                  >
                    -{element?.symbol}
                    {isTicketAmountFloat(Math.abs(element.amount))}
                  </Typography>
                ) : (
                  <Typography
                    variant="body2"
                    component="label"
                    sx={(theme: any) => ({
                      fontWeight: 400,
                      color: theme.palette.primary.main,
                    })}
                  >
                    {element?.symbol}
                    {isTicketAmountFloat(element.amount)}
                  </Typography>
                )}
              </Box>
            );
          } else {
            returnData.push(
              <Box
                key={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={0.3}
                px={store.startFromSummary ? 2.5:2}
              >
                <Typography
                  variant="caption"
                  component="label"
                  sx={(theme: any) => ({
                    fontWeight: 400,
                    color: theme.palette.text.secondary,
                  })}
                >
                  {element?.label}
                </Typography>
                {element.amount < 0 ? (
                  <Typography
                    variant="caption"
                    component="label"
                    sx={(theme: any) => ({
                      fontWeight: 400,
                      color: theme.palette.text.secondary,
                    })}
                  >
                    -{element?.symbol}
                    {isTicketAmountFloat(Math.abs(element.amount))}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    component="label"
                    sx={(theme: any) => ({
                      fontWeight: 400,  
                      color: theme.palette.text.secondary,
                    })}
                  >
                    {element?.symbol}
                    {isTicketAmountFloat(element.amount)}
                  </Typography>
                )}
              </Box>
            );
          }
        }
      });

      return [true, <>{returnData}</>];
    }
    return [false, <></>];
  };

  const getPaymentNetAmount = (amounts: IPaymentDescription[]) => {
    const getNetIndex = amounts.findIndex(({ type = "" }) => type === "NET");
    if (getNetIndex > -1) {
      const netAmount = amounts[getNetIndex];
      const component = (
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={(theme: any) => ({ background: theme.palette.primary.lighter, "@media(max-width:640px)":{marginTop:theme.spacing(0.5),paddingTop:theme.spacing(0.8),paddingBottom:theme.spacing(0.8)}})}
            px={store.startFromSummary ? 2.5:2}
            py={1.3}
            mt={1.2}
          >
            <Typography
              variant="body1"
              component="label"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                "@media(max-width:640px)":{
                  fontSize:"14px !important"
                }
              })}
            >
              {netAmount.label}
            </Typography>
            <Typography
              variant="body1"
              component="label"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontWeight: 600,
                "@media(max-width:640px)":{
                  fontSize:"14px !important"
                }
              })}
            >
              {netAmount?.symbol}
              {isTicketAmountFloat(netAmount.amount)}
            </Typography>
          </Box>
        </Box>
      );
      return [true, component];
    }
    return [false, <></>];
  };

  const [isPaymentGrossAmount, fetchPaymentGrossAmount] =
    getPaymentGrossAmount(payments);
  const [isPaymentNetAmount, fetchPaymentNetAmount] =
    getPaymentNetAmount(payments);
  return (
    <>
      {isMonetaryTransaction && isPaymentAvailable && isPaymentGrossAmount ? (
        <Box mt={store?.widgetData?.isWidgetExpand?0: 1}>{fetchPaymentGrossAmount}</Box>
      ) : (
        false
      )}
      {isMonetaryTransaction && isPaymentAvailable && isPaymentNetAmount ? (
        <>{fetchPaymentNetAmount}</>
      ) : (
        false
      )}
      {children}
    </>
  );
};

export default TicketPaymentInfo;
