import InputField from "../../Components/InputField";
import { Typography, Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import EditIcon from "../../Assets/Svg/EditIcon";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import UploadNewUI from "../../Components/UploadNewUI";
import { useTranslation } from "react-i18next";
// import ChequeTransferIcon from "../../Assets/Svg/ChequeTransferIcon";
import BankIcon from "../../Assets/Svg/BankIcon";
import LoaderSvg from "../../Assets/Svg/LoaderSvg";
import { PaymentStatusLoader } from "../Loaders/PaymentMethodLoader";

const PaymentStatusModal = (): JSX.Element => {
  const loading = false;
  const [t] = useTranslation();
  return (
    <>
      {loading ? (
        <PaymentStatusLoader />
      ) : (
        <Box
          p={3}
          sx={{
            position: "relative",
            "@media(max-width:640px)": {
              height: "100%",
              pb: 2,
              pr: 0.5,
              pl: 2,
              pt: 8,
            },
          }}
        >
          <Box
            sx={{
              height: "100%",
              "@media(max-width:640px)": {
                maxHeight: "calc(100% - 200px)",
                pr: 1.5,
                overflowY: "auto",
              },
            }}
          >
            <Typography
              sx={{ mt: 0, mb: 0 }}
              component="label"
              color="textSecondary"
              variant="body2"
            >
              Order ID : #567898765
            </Typography>
            <Box
              sx={(theme: any) => ({
                mt: 3,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                "& .loaderSvg": { color: theme.palette.primary.main },
              })}
            >
              <LoaderSvg height="72px" width="72px" className="loaderSvg" />
              <Typography
                color="textPrimary"
                sx={{ fontWeight: 700, mt: 3 }}
                component="h3"
                variant="h3"
              >
                {t("COMPLETE_PAYMENT_OF")}
              </Typography>
              <Typography
                component="h3"
                variant="h2"
                sx={(theme: any) => ({
                  fontWeight: 700,
                  color: `${theme.palette.primary.main} !important`,
                  mt: 0.5,
                  fontSize: "30px !important",
                })}
              >
                $ 4,0001
              </Typography>
            </Box>
            <Typography
              color="textPrimary"
              sx={{ mt: 3, display: "block", fontWeight: 700 }}
              component="label"
              variant="h5"
            >
              Step 1{" "}
            </Typography>
            <Typography
              component="p"
              color="textPrimary"
              sx={{
                fontWeight: 400,
                mt: "0px !important",
                mb: "0px !important",
              }}
              variant="body2"
            >
              {t("COMPLETE_PAYMENT_TICKET")}
            </Typography>
            <Box
              mt={2}
              sx={(theme: any) => ({
                border: `1px solid ${theme.palette.divider.default}`,
                borderRadius: "12px",
              })}
            >
              <Box
                sx={(theme: any) => ({
                  p: 2,
                  borderBottom: `1px solid ${theme.palette.divider.default}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                })}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={(theme: any) => ({
                      "&>svg": { color: theme.palette.text.primary },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${theme.palette.divider.default}`,
                      borderRadius: "50%",
                      height: 36,
                      width: 36,
                      minWidth: 36,
                    })}
                  >
                    {/* <ChequeTransferIcon/> */}
                    <BankIcon height="15px" width="15px" />
                  </Box>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ ml: 1.5 }}
                    component="label"
                  >
                    Funds Transfer
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  sx={(theme: any) => ({
                    "&>svg>path": { fill: theme.palette.primary.main },
                  })}
                >
                  <EditIcon height="18px" width="18px" />
                </IconButton>
              </Box>
              <Box sx={{ p: 2 }}>
                <Typography
                  component="p"
                  color="textPrimary"
                  sx={{
                    fontWeight: 400,
                    mt: "0px !important",
                    mb: "0px !important",
                  }}
                  variant="body2"
                >
                  Account Holder Name : Hubilo Softech
                </Typography>
                <Typography
                  component="p"
                  color="textPrimary"
                  sx={{
                    fontWeight: 400,
                    mt: "0px !important",
                    mb: "0px !important",
                  }}
                  variant="body2"
                >
                  Account No. : 78908978687783
                </Typography>
                <Typography
                  component="p"
                  color="textPrimary"
                  sx={{
                    fontWeight: 400,
                    mt: "0px !important",
                    mb: "0px !important",
                  }}
                  variant="body2"
                >
                  Bank ID : ABCD132
                </Typography>
              </Box>
            </Box>
            <Typography
              color="textPrimary"
              sx={{ mt: 2, display: "block", fontWeight: 700 }}
              component="label"
              variant="h5"
            >
              Step 2
            </Typography>
            <Typography
              color="textPrimary"
              sx={{
                fontWeight: 400,
                mt: "0px !important",
                mb: "0px !important",
              }}
              component="p"
              variant="body2"
            >
              {t("PAYMENT_CONFIRMATION_TITLE")}
            </Typography>
            <Box mt={2}>
              <InputField
                placeHolder={t("ENTER_TRANSACTION_ID")}
                label="Transaction ID"
                required
              />
            </Box>
            <Box mt={1}>
              <UploadNewUI />
            </Box>
          </Box>
          <Box
            mt={2}
            sx={(theme: any) => ({
              "@media(max-width:640px)": {
                position: "absolute",
                bottom: 0,
                left: 0,
                background: theme.palette.background.cardColor,
                p: 2,
                borderTop: `1px solid ${theme.palette.divider.default}`,
              },
            })}
          >
            <Box
              sx={(theme: any) => ({
                background: theme.palette.error.light,
                "&>svg": {
                  height: "20px",
                  width: "20px",
                  color: theme.palette.error.main,
                },
                border: `1px solid ${theme.palette.error.main}`,
                borderRadius: "10px",
                p: 2,
                display: "flex",
                alignItems: "center",
              })}
            >
              <ErrorOutlineIcon />
              <Typography
                component="p"
                sx={{ color: "#202020", ml: 2, fontWeight: 400 }}
                variant="body2"
              >
                {t("SHARE_DETAILS_TITLE")}
              </Typography>
            </Box>
            <Button
              sx={{ mt: 2 }}
              fullWidth
              color="primary"
              variant="contained"
            >
              {t("SHARE_PAYMENT_DETAILS")}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default PaymentStatusModal;
