import React, { useEffect, useState } from "react";
import { ITicketInfoProps } from "../..";
import useApplyDiscount from "../../Hooks/useApplyDiscount";
import { IHeadersRequest, ITicketDetailsResponse } from "../../Types/Ticket";
import {
  IApplyDiscount,
  IApplyDiscountResponse,
  IApplyDiscountResult,
  ITicketsInfo,
} from "../../Types/ApplyDiscount";
import { PageState, TOAST_TIMEOUT } from "../../Constants/AppConstants";
import ticketSummaryStyle from "./TicketSummaryStyle";
import TicketSummaryForm from "./TicketSummaryForm";
import CustomModal from "../CustomModal";
import { isAndroidIPhoneIPad, isAndroidIPhone } from "../../Utils/navigator";
import { roundOffTo2 } from "../../Utils/common";
import Box from "@mui/material/Box";
import { useStore } from "../../Context";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ITicketData } from "../../Types/LandingPage";
import Track from "../../Lib/ThirdParty/amplitude";
import { useTranslation } from "react-i18next";
import { IAttendeeFormSetRequest } from "../../Types/AttendeeFormSet";

interface ITicketSummaryProps extends ITicketInfoProps {
  ticketData: ITicketDetailsResponse;
  setKeepMounted: () => void;
  microFeEventHandler?: () => void;
}

interface IError {
  ticketId: number | null;
  message: string;
}

interface ISelectedTicketData {
  id: null | number;
  promoCode: string;
}

let selectedTicketData: ISelectedTicketData = {
  id: null,
  promoCode: "",
};

const TicketSummary = (props: ITicketSummaryProps): React.ReactElement => {
  const [t] = useTranslation();
  const classes = ticketSummaryStyle();
  const { configData, ticketData, setKeepMounted, microFeEventHandler, userData } = props;
  const {
    store,
    setTicketList,
    setTotalAmount,
    setTotalAmountSymbol,
    setPageState,
    setDiscount,
    setOrderNo,
    setAttendeeFormRequest,
    setToast,
  } = useStore();
  const { ticketList = [], discountData, pageState, orderToken } = store;

  const isCustomLP = Boolean(store?.landingPageData?.customLandingPageData?.isUsingCustomLandingPage);

  const [isMonetaryTransaction, setMonetaryTransaction] =
    useState<boolean>(true);
  const [paymentDescription, setPaymentDescription] = useState<any>([]);

  const [isPaymentLoading, setPaymentLoading] = useState<boolean>(true);
  const [isApplyLoading, setApplyLoading] = useState<boolean>(true);
  const [isContinueLoading, setContinueLoading] = useState<boolean>(false);
  const {
    data: applyDiscountData,
    error: discountError,
    setError: setApplyDiscountError,
    applyDiscount,
    loading: applyDiscountLoading,
  } = useApplyDiscount();

  const [error, setError] = useState<IError>({
    ticketId: null,
    message: "",
  });

  const [applyDiscountCall, setApplyDiscountCall] = useState(false);
  const getTicketInfoPayload = (
    ticketId: number | null,
    items: ITicketData[],
    isComplete = false
  ): ITicketsInfo[] => {
    return items
      .filter(
        (eachTicket: ITicketData) => (eachTicket?.selectedQuantity || 0) > 0
      )
      .map((ticket: ITicketData) => {
        // const sameTicketId = ticketId
        //   ? ticketId === ticket.id
        //   : isComplete
        //   ? true
        //   : false;
        const { selectedQuantity = 0, id, promoCode = "" } = ticket;
        return {
          id,
          quantity: selectedQuantity,
          isApplied: !!promoCode,
          isComplete,
          ...(promoCode.length && { coupon: promoCode }),
        };
      });
  };

  const updatePaymentDetails = (
    responseData: IApplyDiscountResponse,
    selectedTicketDataInput: ISelectedTicketData
  ) => {
    const { couponValid = true } = responseData;
    if (couponValid) {
      try {
        const symbol = responseData?.currency?.symbol || "$";
        setTotalAmountSymbol(symbol);
        const canShowSummary = get(responseData, "canShowSummary");
        if (typeof canShowSummary === "boolean")
          setMonetaryTransaction(canShowSummary);

        if (responseData?.results) {
          const netAmount =
            responseData?.results?.find(
              (data: IApplyDiscountResult) => data.type === "NET"
            )?.amount || 0;
          setTotalAmount(netAmount);
          setPaymentDescription(
            responseData?.results?.map((item: IApplyDiscountResult) => ({
              ...item,
              amount: roundOffTo2(item.amount),
              symbol,
            }))
          );
        }

        if (selectedTicketDataInput.id && selectedTicketDataInput.promoCode) {
          const updTicketList = ticketList.map((eachTicket) => {
            let promoCodeDtls = {};
            if (eachTicket.id === selectedTicketDataInput.id) {
              promoCodeDtls = {
                promoCode: selectedTicketDataInput.promoCode,
                promoCodeAmount: responseData?.discount || 0,
              };
            }
            return {
              ...eachTicket,
              ...promoCodeDtls,
            };
          });

          if (setTicketList) {
            setTicketList(updTicketList);
          }
        }

        setPaymentLoading(false);
        setApplyLoading(false);
      } catch (error) {
        setPaymentLoading(false);
        setApplyLoading(false);
        setError({
          ticketId: selectedTicketDataInput.id,
          message: t("unable-to-apply-promo-code"),
        });
      }
    } else {
      setPaymentLoading(false);
      setApplyLoading(false);
      setError({
        ticketId: selectedTicketDataInput.id,
        message: t("invalid-promo-code"),
      });
    }

    selectedTicketData = {
      id: null,
      promoCode: "",
    };
  };

  const handleToastError = (error: string) => {
    setToast({
      message: error,
      type: "error",
    });
  };

  useEffect(() => {
    if (!isEmpty(applyDiscountData)) {
      if (!isEmpty(applyDiscountData?.orderTickets)) {
        const ticketsInfo = getTicketInfoPayload(null, ticketList, true);
        setContinueLoading(false);

        if (!ticketData?.isBuyerForm) {
          const ids = store?.discountData?.ticketsInfo.map((item) => item.id);
          setAttendeeFormRequest(PageState.AttendeeDetails, {
            ticketingLink: store.widgetData?.isWidgetExpand ? configData?.communityUrl : `${configData?.communityUrl}/register`,
            orderToken: store?.discountData && store?.discountData?.orderToken,
            ticketId: ids,
            isSimpleRegistrationEnabled: store?.registrationToggle
          } as IAttendeeFormSetRequest);
        }
        setOrderNo(applyDiscountData.orderNo);
        setDiscount(
          ticketData?.isBuyerForm
            ? PageState.BuyDetails
            : PageState.AttendeeDetails,
          {
            ...discountData,
            orderToken,
            orderNo: applyDiscountData.orderNo,
            ticketsInfo,
          } as IApplyDiscount
        );

        if (setPageState)
          setPageState(
            ticketData?.isBuyerForm
              ? PageState.BuyDetails
              : PageState.AttendeeDetails
          );
      } else {
        updatePaymentDetails(applyDiscountData, selectedTicketData);
      }
      setApplyDiscountCall(false);
    } else if (discountError) {
      handleToastError(
        typeof discountError === "string"
          ? discountError
          : t("something-went-wrong")
      );
      setApplyDiscountError(null);
      setContinueLoading(false);
      setApplyLoading(false);
      setPaymentLoading(false);
      if (applyDiscountCall && setPageState) {
        setContinueLoading(true);
        setTimeout(() => {
          setPageState(PageState.TicketInfo);
          setContinueLoading(false);
        }, TOAST_TIMEOUT);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyDiscountData, discountError]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log("isWidgetExpand", {
      cond: isCustomLP || store?.widgetData?.isWidgetExpand || Boolean(userData?.case && userData?.case !== "PRF_PND" && userData?.case !== "APRVL_PND"),
      isCustomLP, isWidgetExpand: store?.widgetData?.isWidgetExpand,
      default: Boolean(userData?.case && userData?.case !== "PRF_PND" && userData?.case !== "APRVL_PND")
    });
    if (pageState === PageState.TicketSummary) {
      (async () => {
        try {
          // eslint-disable-next-line no-console
          console.log("isWidgetExpand", store?.widgetData?.isWidgetExpand);
          if (isCustomLP || store?.widgetData?.isWidgetExpand || Boolean(userData?.case && userData?.case !== "PRF_PND" && userData?.case !== "APRVL_PND")) {
            const ticketsInfo = getTicketInfoPayload(null, ticketList);
            const payload = {
              payload: {
                data: {
                  orderToken,
                  ticketsInfo,
                } as IApplyDiscount,
              },
            };
            setApplyDiscountCall(true);
            applyDiscount(
              {
                authorization: configData?.accessToken,
                language: configData?.language,
              } as IHeadersRequest,
              { ...payload }
            );
          }
        } catch (e) {
          // console.log(e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState, isCustomLP]);

  const handleIncrement = (ticketId: number, quantity: number): void => {
    const index = ticketList.findIndex((x) => x.id === ticketId);
    const items = [...ticketList];
    const item = { ...items[index] };

    selectedTicketData = {
      ...selectedTicketData,
      id: ticketId,
      promoCode: items[index]?.promoCode || "",
    };

    item.selectedQuantity = quantity + 1;
    items[index] = item;
    if (setTicketList) {
      setTicketList(items);
    }

    const ticketsInfo = getTicketInfoPayload(ticketId, items);
    const payload = {
      payload: {
        data: {
          orderToken,
          ticketsInfo,
        } as IApplyDiscount,
      },
    };
    setApplyLoading(true);
    setPaymentLoading(true);
    applyDiscount(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        ...payload,
      }
    );
  };

  useEffect(() => {
    if (
      ticketList.filter((eachTicket) => eachTicket.selectedQuantity).length ===
      0 &&
      setPageState
    ) {
      setPageState(PageState.TicketInfo);
    }
  }, [ticketList, setPageState]);

  const handleDecrement = (ticketId: number, quantity: number): void => {
    const index = ticketList.findIndex((x) => x.id === ticketId);
    const items = [...ticketList];

    selectedTicketData = {
      ...selectedTicketData,
      id: ticketId,
      promoCode: items[index]?.promoCode || "",
    };

    const item = { ...items[index] };
    item.selectedQuantity = quantity - 1;
    items[index] = item;

    if (setTicketList) {
      setTicketList(items);
    }
    if (
      items.filter((eachTicket) => eachTicket.selectedQuantity).length === 0
    ) {
      if (setPageState) setPageState(PageState.TicketInfo);
    }

    const ticketsInfo = items
      .filter(
        (eachTicket: ITicketData) => (eachTicket.selectedQuantity || 0) > 0
      )
      .map((eachTicket: ITicketData) => {
        if (eachTicket.id === ticketId) {
          return {
            id: eachTicket.id,
            quantity: eachTicket.selectedQuantity,
            coupon: eachTicket.promoCode,
            isApplied: !!eachTicket.promoCode,
            isComplete: false,
          };
        } else {
          return {
            id: eachTicket.id,
            quantity: eachTicket.selectedQuantity,
            coupon: eachTicket.promoCode,
            isApplied: false,
            isComplete: false,
          };
        }
      });

    const payload = {
      payload: {
        data: {
          orderToken,
          ticketsInfo,
        } as IApplyDiscount,
      },
    };
    setApplyLoading(true);
    setPaymentLoading(true);
    applyDiscount(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      { ...payload }
    );
  };

  // CLEAR PROMO CODE
  const handlePromoCodeClear = (ticketId: number): void => {
    const ticketsInfo = ticketList
      .filter(
        (eachTicket: ITicketData) => (eachTicket.selectedQuantity || 0) > 0
      )
      .map((eachTicket: ITicketData) => {
        if (eachTicket.id === ticketId) {
          return {
            id: eachTicket.id,
            quantity: eachTicket.selectedQuantity,
            coupon: "",
            isApplied: false,
            isComplete: false,
          };
        } else {
          return {
            id: eachTicket.id,
            quantity: eachTicket.selectedQuantity,
            coupon: eachTicket.promoCode,
            isApplied: !!eachTicket.promoCode,
            isComplete: false,
          };
        }
      });

    selectedTicketData = {
      ...selectedTicketData,
      id: ticketId,
      promoCode: "",
    };

    setPaymentLoading(true);
    applyDiscount(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: {
            orderToken,
            ticketsInfo,
          } as IApplyDiscount,
        },
      }
    );

    const index = ticketList.findIndex((x) => x.id === ticketId);
    const items = [...ticketList];
    const item = { ...items[index] };
    item.promoCode = "";
    item.promoCodeAmount = 0;
    items[index] = item;
    if (setTicketList) {
      setTicketList(items);
    }
  };

  const handleApplyNow = (ticketId: number, promoCode: string): void => {
    selectedTicketData = {
      ...selectedTicketData,
      id: ticketId,
      promoCode: promoCode,
    };
    const ticketsInfo = ticketList
      .filter(
        (eachTicket: ITicketData) => (eachTicket.selectedQuantity || 0) > 0
      )
      .map((eachTicket: ITicketData) => {
        const isSameTicket = eachTicket.id === ticketId;
        const { selectedQuantity, promoCode: selectedCoupon } = eachTicket;

        return {
          id: eachTicket.id,
          quantity: selectedQuantity,
          coupon: isSameTicket ? promoCode : selectedCoupon,
          isApplied: isSameTicket && !!(promoCode || selectedCoupon),
          isComplete: false,
        };
      });
    setPaymentLoading(true);
    setApplyLoading(true);
    applyDiscount(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: {
            orderToken,
            ticketsInfo,
          } as IApplyDiscount,
        },
      }
    );
  };
  const trackContinuePayment = async () => {
    const amplitudePayload = {
      ticketId: 0,
      ticketName: "",
      quantity: 0,
      currency: "",
      amount: 0,
      discount: 0,
      discountApplied: false,
    } as any;
    ticketList
      .filter((eachTicket) => (eachTicket?.selectedQuantity || 0) > 0)
      .forEach((ticket) => {
        amplitudePayload.ticketId = ticket.id;
        amplitudePayload.ticketName = ticket.title;
        amplitudePayload.quantity = ticket.selectedQuantity || 0;
        amplitudePayload.currency = ticket.currency?.symbol || "";
        amplitudePayload.amount = ticket.ticketFee;
        amplitudePayload.discount = ticket.promoCodeAmount || 0;
      });
    if (amplitudePayload.discount > 0) {
      amplitudePayload.discountApplied = true;
    }
    delete amplitudePayload.discount;
    Track.continuePayment(amplitudePayload);
  };

  const handleSubmit = async () => {
    trackContinuePayment();
    setContinueLoading(true);
    const ticketsInfo = getTicketInfoPayload(null, ticketList, true);
    await applyDiscount(
      {
        authorization: configData?.accessToken,
        language: configData?.language,
      } as IHeadersRequest,
      {
        payload: {
          data: {
            orderToken,
            ticketsInfo,
          } as IApplyDiscount,
        },
      }
    );
  };

  const handlePromoCodeChange = (ticketId: number) => {
    setError({
      ticketId,
      message: "",
    });
  };

  const ticketSummaryFormProps = {
    applyDiscountData: applyDiscountData,
    handleIncrement: handleIncrement,
    handleDecrement: handleDecrement,
    handleSubmit: handleSubmit,
    handleApplyNow: handleApplyNow,
    handlePromoCodeClear: handlePromoCodeClear,
    isMonetaryTransaction: isMonetaryTransaction,
    paymentDescription: paymentDescription,
    error: error,
    handlePromoCodeChange: handlePromoCodeChange,
    loading: {
      isPaymentLoading: isPaymentLoading,
      applyDiscountLoading: applyDiscountLoading,
      isApplyLoading: isApplyLoading,
      isContinueLoading: isContinueLoading,
    },
    ticketData,
    setKeepMounted: setKeepMounted,
    microFeEventHandler: microFeEventHandler,
    isCustomLP:  isCustomLP,
  };

  return (
    <>
      {(!store?.widgetData?.isWidgetExpand && isAndroidIPhoneIPad()) || (!store?.widgetData?.isWidgetExpand && isAndroidIPhone()) || (store?.widgetData?.isWidgetExpand && isAndroidIPhone()) ? (
        <CustomModal open offsetFromTop={0} isOnlyChildren closeIcon={false}>
          <Box
            height="100%"
            sx={() => ({
              // background: theme.palette.background.cardColor,
              borderRadius: "8px",
            })}
          >
            <TicketSummaryForm
              className={classes.mobFooterStyle}
              {...ticketSummaryFormProps}
            />
          </Box>
        </CustomModal>
      ) : (
        <TicketSummaryForm {...ticketSummaryFormProps} />
      )}
    </>
  );
};

export default TicketSummary;
