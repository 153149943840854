import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  socialIcon: {
    border: `1px solid ${theme.palette.divider.default}`,
    borderRadius: "50%",
    padding: 6,
    marginRight: 5,
    marginTop:8,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },

    "& .MuiSvgIcon-root": {
      fontSize: 16,
    },
  },
  truncateText:{
    display: "-webkit-box",
    lineClamp: 2,
    boxOrient: "vertical",  
    overflow: "hidden",
  },
   widgetForTicketInfo:{
     position: "relative",
     height:"100%",
     overflow:"hidden",
     paddingRight:theme.spacing(0),
     "&.simpleFlowRegInfo":{
       paddingRight:"0px !important",
       height:"auto"
     },
     "@media(max-width:1024px)":{
        background:theme.palette.background.lightGray,
        // padding:theme.spacing(2),
        overflowY:"auto",
        paddingRight:theme.spacing(1),
        paddingLeft:theme.spacing(0.5),
     },
     "@media(max-width:480px)":{
      background:theme.palette.background.lightGray,
      // padding:theme.spacing(2),
      overflowY:"auto",
      paddingRight:theme.spacing(0),
      paddingLeft:theme.spacing(0),
   },
     "& .widgetTicketInfoDesc":{
      width:"38%",
      padding:theme.spacing(2),
      position:"absolute",
      right:0,
      top:0,
      left:"auto",
      minHeight:"95px",
      display:"flex",
      flexDirection:"column",
      justifyContent:"center",
      backgroundColor:theme.palette.background.cardColor,
      borderRadius:"8px",
      "@media screen and (max-width:1024px)":{
        width:"100%",
        top:0,
        right: 0,
        position:"relative",
      },
      "&.dNone":{
        display:"none"
      }
     },
  //   display:"flex",
  //   justifyContent:"space-between",
  //   "& .widgetTicketInfoDesc":{
  //       width:"38%",
  //       height:"100%",

  //     padding:theme.spacing(2),
  //   },
  //   "& .ticketWrapperInnerWidget":{
  //     padding:theme.spacing(2),

  //   },
  //   "&>div":{
  //     backgroundColor:theme.palette.background.cardColor,
  //     borderRadius:theme.spacing(1),
  //     "&:last-child":{
  //       marginTop:0,
  //       width:"60%",
  //     }
  //   },
  //   "@media screen and (max-width:1024px)":{
  //     display:"block",
  //     "& .widgetTicketInfoDesc":{
  //       width:"100%",
  //       border:"none",
  //       borderBottomLeftRadius:0,
  //       borderBottomRightRadius:0,
  //     },
  //     "&>div":{
  //       "&:last-child":{
  //         width:"100% !important",
  //         borderTopLeftRadius:0,
  //         borderTopRightRadius:0,


  //       }
  //     },
  //   }
   },
  clockIcon: {
    color: theme.palette.primary.main,
  },
  eventLogo: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "flex-start",
    "& img": {
      maxHeight: "100%",
      maxWidth: "100%",
      display: "block",
    },
  },
}));
