import React from "react";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import makeStyles from "@mui/styles/makeStyles";

import CheckIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useStore, IToastComp } from "../Context";
import { TOAST_TIMEOUT } from "../Constants/AppConstants";
import ReactDOM from "react-dom";


const useStyle = makeStyles((theme: any) => ({
  alertStyle: {
    bottom:"40px",
    "& .MuiPaper-root": {
      borderRadius:"10px",
      backgroundColor: theme.palette.background.cardColor,
      padding: "8px 16px",
      display:"flex",
      minHeight:"73px",
      alignItems:"center",
    },
    "& .MuiAlert-message": {
      fontSize: 14,
      fontWeight: 400,
      display: "flex",
      alignItems: "center",
      marginLeft:12,
      color:theme.palette.text.primary,
      width:"75%",
      "@media(max-width:325px)":{
        width:"65%"
      }
    },
    "& .MuiAlert-standardError": {
      border: "1px solid #ef574c",
    },
    "& .MuiAlert-icon":{
      height:35,
      width:35,
      borderRadius:"50%",
      marginRight:0,
      background:"#ef574c",
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      "&>svg":{
        height:25,
        width:25,
        color:"#fff"
      }
    },
    "& .MuiAlert-action": {
      display: "flex",
      alignItems: "center",
      marginRight:0,
      marginLeft:0,
      "&>button":{
        "&>svg":{
          color:theme.palette.text.primary
        }
      }
    },
    "& .MuiAlert-standardSuccess": {
      border: "1px solid #4caf50",
    },
  },
}));

export type ToastProps = {
  type?: "error" | "success" | "";
  handleClose?: (event: React.SyntheticEvent<any> | Event) => void;
  message?: string;
  open?: boolean;
};

const Toast = ({
  message = "",
  handleClose,
  open,
  type = "",
}: ToastProps): JSX.Element => {
  const { setToast } = useStore();
  const classes = useStyle();
  const handleCloseIconClick = (
    event: React.SyntheticEvent<any> | Event,
    reason: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setToast({} as IToastComp);
    if (handleClose) handleClose(event);
  };

  return ReactDOM.createPortal(
    <Stack spacing={2}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={TOAST_TIMEOUT}
        sx={{ width: 430, "@media(max-width:600px)": { width: "95%" } }}
        className={classes.alertStyle}
        onClose={(event: React.SyntheticEvent<any> | Event, reason: string) =>
          handleCloseIconClick(event, reason)
        }
      >
        {type ? (
          <Alert
            icon={
              type === "error" ? (
                <CloseIcon fontSize="large" />
              ) : null || type === "success" ? (
                <CheckIcon fontSize="large" />
              ) : null
            }
            severity={type || undefined}
            action={
              <IconButton
                sx={{ padding: "5.5px","&>svg":{fontSize:"20px"} }}
                onClick={(event) => handleCloseIconClick(event, "")}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            }
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        ) : undefined}
      </Snackbar>
    </Stack>,
    document.body
  );
};

export default Toast;
