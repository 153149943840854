// libraries
import React, { useState, useEffect } from "react";

// Components
import TextField from "./InputField";
import { Typography, Box } from "@mui/material";
import SelectDropDown from "./SelectDropDown";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  selectBoxWrapper: {
    width: "100%",
    "&>div":{
      padding:"9px 10px",
    }
  },
  otherField: {
    marginTop: 5,
  },
}));
// Custom Interfaces
interface ICompPros {
  id: string;
  name: string;
  wrapperClassName?: string;
  className?: string;
  label?: string;
  isMandatory?: boolean;
  value: string;
  otherValue?: string;
  labelKey?: string;
  placeHolder?: string;
  valueKey?: string;
  isError?: boolean;
  labelHint?: string;
  tabIndex?: number;
  position?: string;
  options: any;
  errorMsg?: string;
  onChange?: (id: string, value: unknown, name: string) => void;
  onChangeOther?: (id: string, event: unknown) => void;
}

const DropDownWithError = (currProps: ICompPros) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const {
    id = "",
    wrapperClassName = "",
    label = "",
    name = "",
    isMandatory = false,
    value = "",
    otherValue = "",
    labelKey = "",
    valueKey = "",
    placeHolder = "Select",
    position = "bottom",
    options = [],
    onChange,
    onChangeOther,
    errorMsg = "",
    tabIndex,
  } = currProps;

  const [getOther, setOther] = useState<string>("");
  useEffect(() => {
    setOther(otherValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherValue]);

  const onOtherChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOther(e.target.value);
  };
  return (
    <Box
      className={`${wrapperClassName} mb20`}
      sx={{ textAlign: "left !important" }}
    >
      {label ? (
        <Typography
          component="label"
          variant="caption"
          htmlFor={id}
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
            textAlign: "left !important",
          })}
        >
          {label}{" "}
          {isMandatory ? (
            <Typography
              component="span"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                lineHeight: 0,
              })}
            >
              *
            </Typography>
          ) : (
            ""
          )}{" "}
        </Typography>
      ) : null}
      <Box sx={{ width: "100%" }}>
        <SelectDropDown
          key={id}
          id={String(id)}
          className={classes.selectBoxWrapper}
          name={name as string}
          required={isMandatory}
          value={value && name ? { [name as string]: value } : ""}
          otherValue={otherValue}
          labelKey={labelKey as string}
          valueKey={valueKey as string}
          options={options}
          placeHolder={placeHolder}
          onChange={onChange}
          position={position}
        />
      </Box>

      {value === t("other") ? (
        <TextField
          key={id}
          id={id}
          placeHolder={"other"}
          onChange={onOtherChange}
          onBlur={onChangeOther}
          type="text"
          maxLength={40}
          value={getOther}
          tabIndex={tabIndex && tabIndex + 1}
          className={classes.otherField}
        />
      ) : (
        false
      )}
      {errorMsg ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            color: theme.palette.error.main,
            fontWeight: 400,
            marginTop: "-2px",
          })}
        >
          {errorMsg}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default DropDownWithError;
