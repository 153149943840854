import { useState, useCallback } from "react";
import { patchApi } from "../Core/HttpClient";

export interface IPaymentMethodRequest {
  payload: {
    data: {
      type: string;
      paymentMethod: string;
      ticketingLink: string;
    };
  };
}

export interface IPaymentMethodResponse {
  message?: string;
  stripeSessionId?: string;
}

const usePaymentMethod = () => {
  const [data, setData] = useState<IPaymentMethodResponse>(
    {} as IPaymentMethodResponse
  );
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const paymentMethod = useCallback((headers, input?: IPaymentMethodRequest, token?: string) => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await patchApi<IPaymentMethodResponse, IPaymentMethodRequest>(
          `orders/${token}/payment-method`,
          headers,
          input
        );

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setDataFetched(true);
          setData(response?.success?.data as IPaymentMethodResponse);
        }
      } catch (err) {
        setError(err);
      } finally {
        setSubmitLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, []);

  return { data, isSubmitLoading, setSubmitLoading, error, paymentMethod, dataFetched };
};

export default usePaymentMethod;
