import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import {
  IAttendeeFormRequest,
  IAttendeeFormResponse,
} from "../Types/AttendeeForm";

const useAttendeeForm = () => {
  const [data, setData] = useState<IAttendeeFormResponse>(
    {} as IAttendeeFormResponse
  );
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null | string>();

  const attendeeFormSave = useCallback(
    (headers, input?: IAttendeeFormRequest) => {
      let didCancel = false;
      async function fetchApi() {
        try {
          const response = await postApi<
            IAttendeeFormResponse,
            IAttendeeFormRequest
          >(AppConstants.attendeeForm, headers, input);
          if (response?.error) {
            throw new Error(response.error.message);
          }
          if (!didCancel) {
            setData(response?.success?.data as IAttendeeFormResponse);
          }
        } catch (err: any) {
          setError(err?.message||  err);
        } finally {
          setSubmitLoading(false);
        }
      }
      fetchApi();
      return () => {
        didCancel = true;
      };
    },
    []
  );

  return { data, setData, isSubmitLoading, error, attendeeFormSave, setSubmitLoading, setError };
};

export default useAttendeeForm;
