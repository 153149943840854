import { useState, useCallback } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IBuyerFormRequest, IBuyerFormResponse } from "../Types/BuyerForm";

const useBuyerForm = () => {
  const [data, setData] = useState<IBuyerFormResponse>(
    {} as IBuyerFormResponse
  );
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>();

  const buyerForm = useCallback((headers, input?: IBuyerFormRequest) => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await postApi<IBuyerFormResponse, IBuyerFormRequest>(
          AppConstants.buyerForm,
          headers,
          input
        );

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as IBuyerFormResponse);
        }
      } catch (err) {
        setError(err);
      } finally {
        setSubmitLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
  }, []);

  return { data, setData, isSubmitLoading, error, buyerForm, setSubmitLoading };
};

export default useBuyerForm;
