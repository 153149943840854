import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "../../Assets/Svg/DeleteIcon";
import InputField from "../InputField";
import TagIcon from "../../Assets/Svg/TagIcon";
import CustomToolTip from "../ToolTip";
import StepperWithBackButton from "../StepperWithBackButton";
import { IApplyDiscountResponse } from "../../Types/ApplyDiscount";
import { useStore } from "../../Context";
// import GrossAmount from "./GrossAmount";
// import DiscountAmount from "./DiscountAmount";
// import TaxAmount from "./TaxAmount";
// import NetAmount from "./NetAmount";
import ticketSummaryStyle from "./TicketSummaryStyle";
import { ITicketDetailsResponse } from "../../Types/Ticket";
import { ITicketData } from "../../Types/LandingPage";
import TicketPaymentInfo from "./TicketPaymentInfo";
import GenericLoader from "../Loaders/GenericLoader";
import { TicketSummaryBillLoader } from "../Loaders/TicketSummaryLoader";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { isTicketAmountFloat } from "Utils/common";

interface ILoaders {
  isPaymentLoading: boolean;
  applyDiscountLoading: boolean;
  isApplyLoading: boolean;
  isContinueLoading: boolean;
}

export type TicketSummaryFormProps = {
  className?: string;
  applyDiscountData?: IApplyDiscountResponse;
  selectedTickets?: ITicketData[];
  handleIncrement?: (ticketId: number, quantity: number) => void;
  handleDecrement?: (ticketId: number, quantity: number) => void;
  handleSubmit?: () => void;
  handleApplyNow: (ticketId: number, promoCode: string) => void;
  handlePromoCodeClear: (ticketId: number) => void;
  ticketData?: ITicketDetailsResponse;
  isMonetaryTransaction?: boolean;
  paymentDescription?: any;
  error?: any;
  handlePromoCodeChange: (ticketId: number) => void;
  loading: ILoaders;
  setKeepMounted: () => void;
  microFeEventHandler?: () => void;
  isCustomLP?: boolean;
};

export interface ITicketListProps {
  handleIncrement?: (ticketId: number, quantity: number) => void;
  handleDecrement?: (ticketId: number, quantity: number) => void;
  applyDiscountData?: IApplyDiscountResponse;
  handleSubmit?: () => void;
  handleApplyNow: (ticketId: number, promoCode: string) => void;
  handlePromoCodeClear: (ticketId: number) => void;
  error?: any;
  handlePromoCodeChange: (ticketId: number) => void;
  loading: ILoaders;
  isCustomLP? : boolean;
}

interface IPromoCodeState {
  ticketId: number | null;
  promoCode: string;
}

const TicketList = (props: ITicketListProps): React.ReactElement => {
  const {
    handleIncrement,
    handleDecrement,
    handleApplyNow,
    handlePromoCodeClear,
    handlePromoCodeChange,
    error,
    loading,
    isCustomLP,
  } = props;
  const [t] = useTranslation();
  const { store } = useStore();
  const { ticketList } = store;

  const { isApplyLoading } = loading;
  const classes = ticketSummaryStyle();
  const [promoCodeDtls, setPromoCodeDtls] = useState<IPromoCodeState[]>([]);
  const paidTicketPresent =
    ticketList &&
    ticketList?.find((data) => !!data.selectedQuantity && data.ticketFee > 0);

  const getPromoCodeDtlObj = (selectedTicketId: number, promoCode: string) => {
    const selectedPromoObjIdx = promoCodeDtls.findIndex(
      (eachDtl) => eachDtl.ticketId === selectedTicketId
    );
    let updPromoCodeDtls = promoCodeDtls;
    if (selectedPromoObjIdx > -1) {
      updPromoCodeDtls[selectedPromoObjIdx] = {
        ticketId: selectedTicketId,
        promoCode: promoCode,
      };
    } else {
      updPromoCodeDtls = [
        ...updPromoCodeDtls,
        {
          ticketId: selectedTicketId,
          promoCode: promoCode,
        },
      ] as IPromoCodeState[];
    }
    return updPromoCodeDtls;
  };

  const getPromoCodeByTicketId = (selectedTicketId: number) => {
    const selectedPromoObjIdx = promoCodeDtls.findIndex(
      (eachDtl) => eachDtl.ticketId === selectedTicketId
    );
    if (selectedPromoObjIdx > -1) {
      return promoCodeDtls[selectedPromoObjIdx].promoCode;
    }
    return "";
  };

  return (
    <Box className={`${classes.mobScrollForTicketCode} scrollForWidget landingticketScroll ${isCustomLP ? !store.widgetData?.isWidgetExpand ? "dudaWidgetTicketInfo":"":"" }`}>
      {ticketList &&
        ticketList?.map((ticket: ITicketData) => {
          const { selectedQuantity = 0 } = ticket;

          if (selectedQuantity > 0) {
            return (
              <Box
                key={ticket.id}
                className="mobHeader"
                pb={2.5}
                pt={2.5}
                sx={(theme: any) => ({
                  borderBottom: `1px solid ${theme.palette.divider.default}`,
                  "@media(max-width:640px)":{
                    paddingTop:theme.spacing(1.5),
                    paddingBottom:theme.spacing(1.5),
                  },
                  "&:last-child":{
                    borderBottom:"none"
                  }
                })}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="body1"
                    component="label"
                    sx={{
                      fontWeight: store?.widgetData?.isWidgetExpand ? 600 : 400,
                      width: "50%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "noWrap",
                      textAlign: "left",
                    }}
                    color="textPrimary"
                  >
                    {ticket?.title}
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={
                      paidTicketPresent ? "space-between" : "flex-end"
                    }
                    width="50%"
                  >
                    <Box display="flex" alignItems="center">
                      <IconButton
                        onClick={() => {
                          if (handleDecrement)
                            handleDecrement(
                              ticket?.id,
                              selectedQuantity as number
                            );
                        }}
                        size="small"
                        sx={(theme: any) => ({
                          border: `1px solid ${theme.palette.divider.default}`,
                          color: theme.palette.text.primary,
                          marginRight: 1.5,
                          fontWeight: 400,
                          "@media(max-width:321px)":{
                            marginRight:1,
                          },
                        })}
                        disabled={isApplyLoading}
                      >
                        {isApplyLoading ? (
                          <CircularProgress
                            sx={(theme: any) => ({
                              display: "flex",
                              width: "15px !important",
                              height: "15px !important",
                              color: theme.palette.text.secondaryFontColor,
                            })}
                          />
                        ) : (
                          <RemoveIcon fontSize="small" />
                        )}
                      </IconButton>
                      <Typography
                        color="textPrimary"
                        style={{ fontWeight: 400, minWidth:"1.6rem", textAlign:"center"}}
                      >
                        {selectedQuantity}
                      </Typography>
                      <CustomToolTip
                        placement="bottom"
                        title={
                          selectedQuantity >= ticket.seatLeft ||
                          (ticket.maxLimit &&
                            selectedQuantity >= ticket.maxLimit) ? (
                            <Typography
                              component="p"
                              variant="caption"
                              sx={{ fontWeight: 400 }}
                            >
                              {t("the-ticket-has-reached-the-maximum-number")}
                            </Typography>
                          ) : (
                            ""
                          )
                        }
                      >
                        <IconButton
                          size="small"
                          disabled={
                            isApplyLoading ||
                            selectedQuantity >= ticket.seatLeft ||
                            (ticket.maxLimit
                              ? selectedQuantity >= ticket.maxLimit
                              : false)
                          }
                          onClick={() => {
                            if (handleIncrement)
                              handleIncrement(
                                ticket?.id,
                                selectedQuantity as number
                              );
                          }}
                          sx={(theme: any) => ({
                            border: `1px solid ${theme.palette.divider.default}`,
                            color: theme.palette.text.primary,
                            marginLeft: 1.5,
                            "@media(max-width:321px)":{
                              marginLeft:1,
                            },
                            fontWeight: 400,
                          })}
                        >
                          {isApplyLoading ? (
                            <CircularProgress
                              sx={(theme: any) => ({
                                display: "flex",
                                width: "15px !important",
                                height: "15px !important",
                                color: theme.palette.text.secondaryFontColor,
                              })}
                            />
                          ) : (
                            <AddIcon fontSize="small" />
                          )}
                        </IconButton>
                      </CustomToolTip>
                    </Box>
                    <Typography
                      sx={(theme: any) => ({
                        color: theme.palette.primary.main,
                        textAlign: "right",
                        fontWeight:600
                      })}
                    >
                      {`${
                        ticket?.ticketFee > 0 ? ticket?.currency?.symbol : ""
                      }${
                        ticket?.ticketFee > 0
                          ? ((selectedQuantity as number) * ticket?.ticketFee) %
                              1 ===
                            0
                            ? (isTicketAmountFloat((selectedQuantity as number) * ticket?.ticketFee))
                            : (
                                Math.round(
                                  (selectedQuantity as number) *
                                    ticket?.ticketFee *
                                    100
                                ) / 100
                              ).toFixed(2)
                          : paidTicketPresent
                          ? 0
                          : ""
                      }`}
                    </Typography>
                  </Box>
                </Box>
                {ticket?.ticketFee > 0 ? (
                  <>
                    {!ticket?.ticketFee ||
                    !ticket?.canShowCouponBox ? null : ticket.promoCode ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        mt={1}
                        py={0.6}
                        pl={1.5}
                        pr={0.5}
                        alignItems="center"
                        sx={(theme: any) => ({
                          background: theme.palette.primary.lighter,
                          borderRadius: "4px",
                        })}
                      >
                        <Typography
                          sx={(theme: any) => ({
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                          })}
                          variant="caption"
                          component="p"
                        >
                          {t("apply-discount", {
                            CODE: ticket.promoCode,
                            AMOUNT: `${ticket?.currency?.symbol}${ticket.promoCodeAmount}`
                          })}
                        </Typography>
                        <IconButton
                          sx={(theme: any) => ({
                            color: theme.palette.primary.main,
                          })}
                          onClick={() => {
                            setPromoCodeDtls(getPromoCodeDtlObj(ticket.id, ""));
                            handlePromoCodeClear(ticket.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        mt={1}
                        sx={(theme) => ({
                          "& svg": { color: theme.palette.primary.main },
                        })}
                      >
                        <InputField
                          startIcon
                          icon={<TagIcon />}
                          placeHolder={t("enter-discount-coupon")}
                          onChange={(e) => {
                            setPromoCodeDtls(
                              getPromoCodeDtlObj(ticket.id, e.target.value)
                            );
                            handlePromoCodeChange(ticket.id);
                          }}
                          errorMsg={
                            error.ticketId === ticket?.id ? error?.message : ""
                          }
                          onKeyPress={(
                            e: React.KeyboardEvent<HTMLDivElement>
                          ) =>
                            e.key === "Enter" &&
                            handleApplyNow(
                              ticket?.id,
                              getPromoCodeByTicketId(ticket?.id)
                            )
                          }
                          value={getPromoCodeByTicketId(ticket?.id)}
                          maxLength={40}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          sx={{ marginLeft: 1, minWidth: "auto",paddingLeft: store?.widgetData?.isWidgetExpand ? 3 : 2,paddingRight: store?.widgetData?.isWidgetExpand ? 3 : 2, }}
                          onClick={() =>
                            handleApplyNow
                              ? handleApplyNow(
                                  ticket?.id,
                                  getPromoCodeByTicketId(ticket?.id)
                                )
                              : null
                          }
                          disabled={isApplyLoading || !getPromoCodeByTicketId(ticket?.id)}
                        >
                          {t("apply-now")}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : null}
              </Box>
            );
          }
        })}
    </Box>
  );
};

const TicketSummaryForm = (props: TicketSummaryFormProps): JSX.Element => {
  const classes = ticketSummaryStyle();
  const {
    applyDiscountData,
    className,
    handleDecrement,
    handleIncrement,
    handleSubmit,
    handleApplyNow,
    handlePromoCodeClear,
    handlePromoCodeChange,
    isMonetaryTransaction,
    paymentDescription,
    error,
    loading,
    ticketData,
    setKeepMounted,
    microFeEventHandler,
    isCustomLP
  } = props;

  const [t] = useTranslation();
  const { store } = useStore();
  const { isContinueLoading } = loading;

  // eslint-disable-next-line no-console
  console.log("isCustomLP", isCustomLP);

  return (
    <Box height="100%">
      <Box className={`${classes.TicketSummaryWrapper}  ${className} ${store?.widgetData?.isWidgetExpand ? `${classes.ticketSummaryWidget} singleViewSummryWidget`:false } ${store.startFromSummary ? classes.landingSummryFlow:false} `}>
      <Box className="ticketSummryWidgetList">
        <StepperWithBackButton ticketData={ticketData} setKeepMounted={setKeepMounted} microFeEventHandler={microFeEventHandler}/>
        <Typography
          component="h2"
          variant="h2"
          className="title"
          color="textPrimary"
          sx={{
            mt: 3,
            mb: 2,
            textAlign: "left !important",
            px: store.startFromSummary ? 2.5 : 0,
            "@media(max-width:640px)": {
              px: 0,
              mt:1,
            },
          }}
        >
          {t("summary")}
        </Typography>
     
        <TicketList
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          applyDiscountData={applyDiscountData}
          handleApplyNow={handleApplyNow}
          handlePromoCodeClear={handlePromoCodeClear}
          error={error}
          handlePromoCodeChange={handlePromoCodeChange}
          loading={loading}
          isCustomLP={isCustomLP}
        />
        </Box>

        <Box className={`mobFooterStyle ${store.startFromSummary ? classes.landingSummryDetailsFlow:false}`}  pt={1}>
          <GenericLoader
            isLoading={loading?.isPaymentLoading}
            loaderComp={<TicketSummaryBillLoader />}
          >
            <TicketPaymentInfo
              isMonetaryTransaction={isMonetaryTransaction}
              payments={paymentDescription}
            >
              <Box
                pt={2}
                px={store.startFromSummary ? 2.5 : 0}
                width="100%"
                className="mobBtn"
              >
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%" }}
                  size="large"
                  disabled={isContinueLoading}
                >
                  {!isContinueLoading ? (
                    `${t("continue")}`
                  ) : (
                    <CircularProgress
                      sx={(theme: any) => ({
                        width: "20px !important",
                        height: "20px !important",
                        color: theme.palette.text.secondaryFontColor,
                      })}
                    />
                  )}
                </Button>
              </Box>
            </TicketPaymentInfo>
          </GenericLoader>
        </Box>
      </Box>
    </Box>
  );
};

export default TicketSummaryForm;
