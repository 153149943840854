import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import "dayjs/locale/de";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import "dayjs/locale/hi";
import "dayjs/locale/ja";
import "dayjs/locale/ko";
import "dayjs/locale/zh";
// import "Lib/ThirdParty/dayjs/locale/vi";
import "dayjs/locale/ru";
import "dayjs/locale/pt";
import "dayjs/locale/it";
import "dayjs/locale/id";
import EN from "./en";
import HN from "./hindi";
import ES from "./es";
import DE from "./de";
import FR from "./fr";
import ID from "./id";
import IT from "./it";
import PT from "./pt";
import RU from "./ru";
import VI from "./vi";
import ZH from "./zh";
import ZK from "./zk";
import KO from "./ko";
import JA from "./ja";
import BA from "./ba";
import BP from "./bp";
import CA from "./ca";
import TH from "./th";
import CS from "./cs";
import PL from "./pl";
import RO from "./ro";
import SK from "./sk";

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a
        // UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        resources: {
            en: {
                translation: EN,
            },
            hi: {
                translation: HN,
            },
            es: {
                translation: ES,
            },
            de: {
                translation: DE,
            },
            fr: {
                translation: FR,
            },
            id: {
                translation: ID,
            },
            it: {
                translation: IT,
            },
            pt: {
                translation: PT,
            },
            ru: {
                translation: RU,
            },
            vi: {
                translation: VI,
            },
            zh: {
                translation: ZH,
            },
            zk: {
                translation: ZK,
            },
            ko: {
                translation: KO,
            },
            ja: {
                translation: JA,
            },
            ba: {
                translation: BA,
            },
            bp: {
                translation: BP,
            },
            ca: {
                translation: CA,
            },
            th: {
                translation: TH,
            },
            cs: {
                translation: CS,
            },
            pl: {
                translation: PL,
            },
            ro: {
                translation: RO,
            },
            sk: {
                translation: SK,
            }
        },
        lng: "en", // if you're using a language detector, do not define the lng option
        fallbackLng: "en",

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
