import { useState, useEffect } from "react";
import { AppConstants } from "../Constants/AppConstants";
import { postApi } from "../Core/HttpClient";
import { IHeadersRequest, ITicketDetailsResponse } from "../Types/Ticket";
import { useStore } from "../Context";

const useGetTickets = (input?: IHeadersRequest) => {
  const { setIsBuyerFrom } = useStore();
  const [data, setData] = useState<ITicketDetailsResponse>(
    {} as ITicketDetailsResponse
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown | null>();

  useEffect(() => {
    let didCancel = false;
    async function fetchApi() {
      try {
        const response = await postApi<ITicketDetailsResponse>(
          AppConstants.getTickets,
          input
        );

        if (!response.status) {
          throw new Error(response.error.message);
        }
        if (!didCancel) {
          setData(response?.success?.data as ITicketDetailsResponse);
          setIsBuyerFrom(response?.success?.data?.isbuyerForm);
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
    fetchApi();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error };
};

export default useGetTickets;
