/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
// import { alpha } from '@mui/material/styles';
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme: any) => ({
  startIcon: {
    "& .MuiInputAdornment-root": {
      paddingLeft: theme.spacing(1.2),
    },
    "& input": {
      paddingLeft: `${theme.spacing(0.7)} !important`,
    },
  },
}));

const BootstrapInput = withStyles((theme: any) => ({
  root: {
    width: "100%",
    "& fieldset": {
      border: `1px solid ${theme.palette.divider.default}`,
        "&>legend":{
          width:"unset",
        }
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
    },
    "& .MuiInputBase-root": {
      width: "100%",
      padding: 0,
      border:"none !important",
      backgroundColor:"transparent",
      "&.Mui-focused":{
        boxShadow:"none",
      },
      "&:hover": {
        "& fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
    },
    "& .MuiInputAdornment-positionStart": {
      marginRight: 0,
      "&>svg":{
        width:"20px",
        height:"20px",
      }
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
      paddingRight: 10,
      fontSize: 14,
      fontWeight: 400,
      "&>svg":{
        width:"20px",
        height:"20px",
      },
      "&>p":{
        fontSize: "12px !important",
      }
    },
    "& .MuiInputBase-input": {
      borderRadius: "4px !important",
      position: "relative",
      // border: `1px solid ${theme.palette.border.border1}`,
      width: "100% !important",
      fontSize: "14px",
      padding: `${theme.spacing(1.125)}  ${theme.spacing(1.5)} !important`,
      height: "18px !important",
      border: "none !important",
      backgroundColor: "transparent !important",
      margin: "0px !important",
      color:theme.palette.text.primary,
      boxSizing: "initial !important",
      outline: "none",
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
      "&:focus": {
        borderColor: theme.palette.primary.main,
        boxShadow: "none",
      },
      "&.MuiInputBase-inputSizeSmall": {
        padding: `${theme.spacing(0.875)}  ${theme.spacing(1.5)}`,
        height: 14,
      },
      "&::placeholder": {
        color: theme.palette.text.primary,
        opacity:0.65,
      },
      "@media(min-width:768px)":{
        fontSize: "14px !important",
      },
    },
    "label + &": {
      marginTop: theme.spacing(0.2),
      outline: "none",
    },
    
    "&.Mui-disabled": {
      cursor: "not-allowed",
      textFillColor:theme.palette.text.secondary,
      "& input": {
        color: "red",
        textFillColor:"red",

        cursor: "not-allowed",
      },
    },
    "& .Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
}))(TextField);

export type InputFieldProps = {
  disabled?: boolean;
  placeHolder?: string;
  id?: string;
  name?: string;
  value?: string;
  defaultValue?: string;
  //	icon?: null | React.ReactNode;
  //	iconAlign?: 'start' | 'end';
  label?: string;
  hintText?: string;
  //	rounded?: boolean;
  required?: boolean;
  className?: string;
  tabIndex?: number;
  error?: boolean;
  errorMsg?: string;
  helperText?: string;
  width?: string;
  size?: "medium" | "small" | undefined;
  type?: string;
  multiLine?: boolean;
  rows?: number | undefined;
  icon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  maxLength?: number;
  regex?: any;
  onKeyPress?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onKeyUp?:(event: React.KeyboardEvent<HTMLDivElement>) => void;
  startIcon?: boolean;
};

const InputField = ({
  id = "",
  name = "",
  className = "",
  label = "",
  hintText = "",
  tabIndex = 1,
  placeHolder = "",
  // defaultValue = "",
  value = "",
  errorMsg = "",
  helperText = "",
  width = "100%",
  size = "medium",
  disabled = false,
  required = false,
  type = "text",
  multiLine = false,
  rows = undefined,
  icon,
  endIcon,
  onChange,
  onBlur,
  maxLength,
  onKeyPress,
  onKeyUp,
  startIcon = false,
}: InputFieldProps) => {
  const classes = useStyle();
  return (
    <Box sx={{ width: width, textAlign: "left" }}>
      {label ? (
        <Typography
          component="label"
          variant="caption"
          htmlFor={id}
          sx={(theme: any) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
            textAlign: "left",
          })}
        >
          {label}{" "}
          {required ? (
            <Typography
              component="span"
              sx={(theme: any) => ({
                color: theme.palette.primary.main,
                fontSize: 16,
                lineHeight: 0,
              })}
            >
              *
            </Typography>
          ) : (
            ""
          )}{" "}
        </Typography>
      ) : null}
      <BootstrapInput
        className={`${className} ${icon ? classes.startIcon : null}`}
        multiline={multiLine}
        rows={multiLine && rows ? rows : 1}
        tabIndex={tabIndex}
        type={type || "text"}
        // defaultValue={defaultValue}
        value={value || ""}
        error={errorMsg !== "" ? true : false}
        size={size}
        disabled={disabled}
        helperText={errorMsg !== "" ? errorMsg : helperText}
        placeholder={placeHolder}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        autoComplete="off"
        inputProps={{ maxLength: maxLength, autoComplete: "off"}}
        InputProps={{
          startAdornment: startIcon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : (
            false
          ),
          endAdornment: endIcon ? (
            <InputAdornment position="end">{endIcon}</InputAdornment>
          ) : (
            false
          ),
        }}
        InputLabelProps={{shrink: false}}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
      />
      {hintText ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            marginTop: 2,
            color: theme.palette.text.secondary,
          })}
        >
          {hintText}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default InputField;
