import React from "react";
interface RegisterEventIconProps {
    className?: string;
    width?: string;
    height?: string;
  }

const RegisterEventIcon = ({width="78",height="79",className}:RegisterEventIconProps):JSX.Element => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 78 79" fill="none">
<g clipPath="url(#clip0_1208_41770)">
<path d="M6.16026 66.4191C6.60292 66.4191 6.95336 66.4191 7.32224 66.4191C13.0952 66.4191 18.8498 66.4007 24.6228 66.4191C26.9652 66.4191 28.4776 68.5586 27.6292 70.6244C27.1312 71.8417 26.1905 72.561 24.8626 72.561C18.0198 72.5795 11.1955 72.5979 4.35274 72.561C1.9919 72.5426 0.0737187 70.5506 -0.0738339 68.2082C-0.092278 67.9869 -0.0738339 67.784 -0.0738339 67.5627C-0.0738339 46.85 -0.0738339 26.1557 -0.0738339 5.46146C-0.0738339 3.02684 1.21725 1.21932 3.39365 0.647553C3.8732 0.536888 4.37119 0.5 4.85073 0.5C21.9299 0.5 39.0092 0.5 56.0884 0.5C59.0763 0.5 61.0314 2.36285 61.0498 5.33235C61.0867 11.8616 61.0683 18.3908 61.0498 24.92C61.0498 26.6906 59.9801 28.0001 58.4492 28.2214C56.9368 28.4428 55.5166 27.6128 55.0555 26.1557C54.908 25.6946 54.8895 25.1966 54.8895 24.7171C54.8711 19.0732 54.8711 13.4293 54.908 7.78541C54.908 6.93698 54.7235 6.62343 53.8013 6.64188C38.253 6.66032 22.7046 6.66032 7.15624 6.66032C6.82425 6.66032 6.49226 6.67877 6.12338 6.69721C6.16026 26.6168 6.16026 46.4442 6.16026 66.4191Z" fill="current"/>
<path d="M54.6313 31.5414C67.6344 31.5782 78.0922 42.0729 78.0737 55.076C78.0553 68.0791 67.5237 78.5369 54.5207 78.5C41.536 78.4631 31.0414 67.8762 31.1151 54.91C31.1889 41.9438 41.702 31.5045 54.6313 31.5414ZM71.895 55.0944C71.9503 45.5404 64.2407 37.757 54.6867 37.6832C45.2064 37.6095 37.3308 45.3744 37.257 54.91C37.1648 64.464 44.9482 72.3028 54.5576 72.3212C64.13 72.3581 71.8397 64.7038 71.895 55.0944Z" fill="current"/>
<path d="M30.7095 22.3563C25.2501 22.3563 19.8091 22.3747 14.3496 22.3563C11.9888 22.3563 10.4395 20.1061 11.3801 18.0404C11.9519 16.7677 13.0032 16.1775 14.405 16.1775C19.0713 16.1775 23.7192 16.1775 28.3856 16.1775C34.6012 16.1775 40.8353 16.1591 47.051 16.1775C49.5778 16.1775 51.0902 18.7043 49.836 20.807C49.1721 21.9136 48.1576 22.3563 46.9034 22.3563C41.4993 22.3378 36.0952 22.3563 30.7095 22.3563Z" fill="current"/>
<path d="M20.9154 33.4596C23.2209 33.4596 25.508 33.4412 27.8135 33.4596C29.6579 33.4781 30.9675 34.6954 31.0043 36.4291C31.0412 38.1997 29.7686 39.6015 27.9242 39.6199C23.2394 39.6568 18.5546 39.6568 13.8698 39.6199C12.0254 39.6015 10.7527 38.2182 10.7712 36.4476C10.8081 34.7323 12.1176 33.4781 13.9436 33.4596C16.2675 33.4596 18.5915 33.4596 20.9154 33.4596Z" fill="current"/>
<path d="M17.7615 50.7417C19.071 50.7417 20.399 50.7233 21.7085 50.7417C23.2947 50.7786 24.5673 51.9406 24.6964 53.4346C24.844 55.1314 23.8849 56.4963 22.2434 56.7914C21.5056 56.9205 20.7125 56.902 19.9563 56.902C17.9275 56.9205 15.9171 56.9389 13.8882 56.8836C11.97 56.8283 10.6974 55.4818 10.7527 53.6559C10.808 51.9406 12.1176 50.7602 13.9804 50.7417C14.4046 50.7417 14.8473 50.7417 15.2715 50.7417C16.1015 50.7417 16.9315 50.7417 17.7615 50.7417Z" fill="current"/>
<path d="M52.4368 56.3302C53.2299 55.4817 53.9123 54.7071 54.6132 54.0062C56.808 51.7929 59.0213 49.5612 61.253 47.3848C62.581 46.0753 64.4623 46.0937 65.6981 47.3295C66.8785 48.5283 66.86 50.4465 65.6243 51.7191C61.9539 55.4264 58.2835 59.1152 54.5947 62.7856C53.3036 64.0767 51.4592 64.0398 50.1497 62.7487C47.9733 60.5907 45.8153 58.4143 43.6758 56.2379C42.311 54.8546 42.2925 52.9918 43.5836 51.7376C44.8378 50.5203 46.6822 50.5756 48.0286 51.8851C49.4304 53.2684 50.8506 54.7071 52.4368 56.3302Z" fill="current"/>
</g>
<defs>
<clipPath id="clip0_1208_41770">
<rect width="78.1476" height="78" fill="white" transform="translate(-0.0737305 0.5)"/>
</clipPath>
</defs>
</svg>
    );
};

export default RegisterEventIcon;