import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme?: any) => ({
  ticketListWrapper: {
    display: "block",
    color: theme.palette.text.primary,
    borderTop: `1px dashed ${theme.palette.divider.default}`,
    paddingTop: 20,
    marginTop: 20,
  },
  widgetTicketList:{
    // padding:theme.spacing(3),
    paddingTop:0,
    height:"100%",
    borderRadius:theme.spacing(1),
    marginTop:theme.spacing(0),
    background:theme.palette.background.lightGray,
    // border:`1px solid ${theme.palette.divider.default}`,
    borderTop:"none",
    "@media(max-width:1024px)":{
      padding:theme.spacing(0),
      height:"auto",
      borderRadius:theme.spacing(0),
      marginTop:theme.spacing(2),


    },
    "& .ticketListingWidget":{
      background:theme.palette.background.cardColor,
      "&:hover":{
        background:theme.palette.background.cardColor,

      },
      "&:last-child":{
        marginBottom:0,
      }
    },
  },
  checkboxWrapper: {
    textAlign:"left",
    alignItems: "start",
    "& .MuiCheckbox-root": {
      padding:"1px 10px !important",
      textAlign:"left !important",
      "&>input":{
        minWidth:0,
        width:0,
      },
      "&>svg":{
        height:"20px",
        width:"20px",
      }
    },
    "& .MuiTypography-root": {
      fontSize: 12,
      fontWeight: 400,
      "&>span":{
        display:"block",
      },
      "& a":{
        fontSize:"12px !important",
      },
    },
  },
  mailIconColor: {
    color: theme.palette.primary.main,
  },
  ticketListCommon: {
    borderRadius: 7,
    border: `1px solid ${theme.palette.divider.default}`,
    position: "relative",
    fontWeight: 400,
    paddingBottom: 0,
    boxShadow: "none",
    overflow: "visible",
    background: theme.palette.background.cardColor,
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
      "& .ticketPrice": {
        "&::before": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderLeft: 0,
        },
        "&::after": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRight: 0,
        },
      },
    },
    "&.active": {
      border: `1px solid ${theme.palette.primary.main}`,
      "& .ticketPrice": {
        "&::before": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderLeft: 0,
        },
        "&::after": {
          border: `1px solid ${theme.palette.primary.main}`,
          borderRight: 0,
        },
      },
    },
    "& .roundIcon": {
      border: `1px solid ${theme.palette.divider.default}`,
      height: 20,
      width: 20,
      padding: theme.spacing(1.5),
      color: theme.palette.text.primary,
      "&.moreInfo": {
        border: "none",
        color: theme.palette.text.secondary,
      },
      "&>svg":{
        width:"20px",
        height:"20px",
        maxWidth:"20px",
      }
    },
    "& .MuiCardContent-root": {
      "&:last-child": {
        paddingBottom: 12,
      },
    },
    "& .ticketPriceForMobile":{
      "&::before": {
        backgroundColor: theme.palette.background.cardColor,
        left: -21,
      },
      "&::after": {
        backgroundColor: theme.palette.background.cardColor,
        right: -21,
      }
    },
    "& .ticketPrice": {
      borderTop: `1px dashed ${theme.palette.divider.default}`,
      paddingTop: 10,
      position: "relative",
      
      "&::before": {
        content: "''",
        width: 12,
        height: 21.4,
        backgroundColor: theme.palette.background.cardColor,
        borderRadius: "50%",
        position: "absolute",
        left: -21,
        top: -11.8,
        border: `1px solid ${theme.palette.divider.default}`,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        borderLeft: 0,
      },
      "&::after": {
        content: "''",
        width: 12,
        height: 21.4,
        backgroundColor: theme.palette.background.cardColor,
        borderRadius: "50%",
        position: "absolute",
        right: -21,
        top: -11.8,
        border: `1px solid ${theme.palette.divider.default}`,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
        borderRight: 0,
      },
    },
  },
  // widgetWithEvent:{
  //   overflow:"hidden",
  //   borderRadius:"8px",
  //   "@media screen and (max-width:1024px)":{
  //     borderRadius:0,
  //   },
  //   "& .inputEmailWrapperWidget":{
  //     height:"auto",
  //     background:theme.palette.background.cardColor,
  //     border:`1px solid ${theme.palette.divider.default}`,
  //     borderTopLeftRadius:"1rem",
  //     borderTopRightRadius:"1rem",
  //     borderBottomLeftRadius:"8px",
  //     padding:theme.spacing(2),
  //     borderBottomRightRadius:"8px",
  //    },
  //    "& .ticketWrapperInnerWidget":{
  //     maxHeight:"20rem",
  //     overflowY: "auto",
  //     "& .ticketPrice":{
  //       "&::before":{
  //         backgroundColor:theme.palette.background.cardColor,
  //       },
  //       "&::after":{
  //         backgroundColor:theme.palette.background.cardColor,

  //       },
  //     }
  //    }
  // },
  ticketListMain: {
    position: "relative",
    height: "calc((var(--vh, 1vh) * 100) - 0rem)",
    padding: `${theme.spacing(7.5)}  ${theme.spacing(1)}  ${theme.spacing(1.875)}  ${theme.spacing(1.875)}`,
    "@media(max-width:768px)":{
      height:"100%",
    }
  },
  ticketWrapperWidget:{
    display:"flex",
    height:"100%",
    justifyContent:"space-between",
    "& .ticketWrapperInnerWidget":{
        width:"60%",
        overflowY: "auto",
        overflowX: "hidden",
        padding:"1.6rem",
        // border:`1px solid ${theme.palette.divider.default}`,
        background: theme.palette.background.cardColor,
        borderRadius:theme.spacing(1),

        maxHeight:"100%",
         
        "& .ticketPrice":{
          "&::before":{
            background: theme.palette.background.cardColor,
          },
          "&::after":{
            background: theme.palette.background.cardColor,
          }
        },
        "@media screen and (max-width: 1024px)": {
          maxHeight:"100%",
          minHeight:"0",
          background: "none",
          overflowY:"hidden",
          border:"none",
          padding:0,
          "& .ticketPrice":{
            "&::before":{
              background: `${theme.palette.background.lightGray} !important`,
            },
            "&::after":{
              background: `${theme.palette.background.lightGray} !important`,
            }
          },
         },
         "@media(max-width:480px)":{
           maxHeight:"calc((var(--vh, 1vh) * 100) - 35rem)",
           minHeight:"calc((var(--vh, 1vh) * 100) - 35rem)",
           overflowY:"auto",
           paddingRight:theme.spacing(1.5),
           "& .ticketPrice":{
            "&::before":{
              background: `${theme.palette.background.cardColor} !important`,
            },
            "&::after":{
              background: `${theme.palette.background.cardColor} !important`,
            }
          },
         }
    },
    "& .inputEmailWrapperWidget":{
        width:"38%",
        background: theme.palette.background.cardColor,
        // border:`1px solid ${theme.palette.divider.default}`,
        padding:"1.6rem",
        position:"absolute",
        height:"auto",
        right:0,
        left:"auto",
        borderRadius:"8px",
        "&.isWidgetEventName":{
          marginTop:theme.spacing(15),
          "@media screen and (max-width: 1024px)": {
            marginTop:theme.spacing(4),

          },
          "&.widgetWithoutEventDetails":{
            marginTop:theme.spacing(0),
          },
        },
      "@media screen and (max-width: 1024px)": {
        height:"auto",
        position:"relative",
        marginTop:theme.spacing(2),
        background:theme.palette.background.cardColor,
        border:`1px solid ${theme.palette.divider.default}`,
        // borderTopLeftRadius:"1rem",
        // borderTopRightRadius:"1rem",
        borderBottomLeftRadius:"0rem",
        borderBottomRightRadius:"0rem",

       },
       "@media(max-width:480px)":{
         position: "absolute",
         bottom:0,
         left:0,
       }
    },

    "@media screen and (max-width: 1024px)": {
      display: "block",
      width:"100%",
      "& .ticketWrapperInnerWidget":{
        width:"100%",
      },
      "& .inputEmailWrapperWidget":{
        width:"100%"
      },
    },
    "@media screen and (max-width: 768px)":{
      padding:"0rem",

    },
    "@media screen and (max-width: 480px)":{
      padding: `${theme.spacing(7.5)}  ${theme.spacing(1)} ${theme.spacing(1.875)} ${theme.spacing(1.875)}`,

    },
  },
  
  ticketListInner: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight:theme.spacing(1.3),
    maxHeight: "calc((var(--vh, 1vh) * 100) - 16rem)",
    "@media(max-width:640px)":{
     maxHeight: "calc((var(--vh, 1vh) * 100) - 28rem)",

    }
  },
  simpleRegForMob:{
    "@media(max-width:640px)":{
      maxHeight: "calc((var(--vh, 1vh) * 100) - 10rem) !important",
      minHeight: "calc((var(--vh, 1vh) * 100) - 10rem) !important",
 
     }
  },
  emailBoxWrapper: {
    position: "absolute",
    left: 0,
    bottom: "0rem",
    width: "100%",
    padding: theme.spacing(1.875),
    background: theme.palette.background.cardColor,
    borderTop: `1px dotted ${theme.palette.divider.default}`,
    marginTop: 25,
  },
  termandpolicy: {
    color: theme.palette.text.primary,
    fontSize: "12px !important",
    "& a": {
      color: `${theme.palette.primary.main} !important `,
      textDecoration: "none !important",
      fontSize: "14px !important",
    },
    "& p": {
      fontSize: "12px !important",
      marginTop:"0px !important",
      marginBottom:"0px !important",
      margin: 0,
      lineHeight:"20px",
    },
  },  
  suggestionBox: {
    fontSize: "12px!important",
    fontWeight: "500",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    marginTop: "0rem",
    marginLeft: "0",
    display: "block",
    position: "relative",
    top: "-5px",
}
}));