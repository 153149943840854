import CustomModal from "../../Components/CustomModal";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import TicketPurchaseFailedIcon from "../../Assets/Svg/TicketPurchaseFailedIcon";
import TicketPurchaseSuccess from "../../Assets/Svg/TicketPurchaseSuccessIcon";
import RegisterEventIcon from "../../Assets/Svg/RegisterEventIcon";
import { IConfigData } from "Types/LandingPage";
import { IHeadersRequest } from "Types/Ticket";
import { IResendEmailRequest, IResendEmailResponse } from "Types/BuyerForm";
import useResendLink from "Hooks/useResendLink";
import { ActiveModalTypes, IWebinarSubmits } from "Types/WebinarForm";
import useWebinarForm from "Hooks/useWebinarForm";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";

interface IWebinarConfirmationModalProps {
  configData?: IConfigData;
  formData: IWebinarSubmits[] | null;
  trackingPixelApps?: any;
}

const WebinarConfirmationModal = (props: IWebinarConfirmationModalProps):JSX.Element => {
  const isHideGoToCTA= true;  // HUB-28199
    const {
      configData,
      formData,
    } = props;
    const {
      data: resendLinkData,
      resendLink,
      setData: resetResentData,
      loading: sendingEmail
    } = useResendLink();
    const [t] = useTranslation();

    const {
      store: { activeModalData, landingPageData, isWebinarModalOpen: open, configData: { webinarData, communityPageShow } },
      setActiveModalData,
      setWebinarModalOpen: setOpen,
    } = useStore();
    const allowSessionOnDemandValue =
    // @ts-ignore
    landingPageData?.sessions?.data[0]?.data[0]?.streamTypeId === 6
      ? // @ts-ignore
      landingPageData?.sessions?.data[0]?.data[0]?.preRecordedIsReplayVideo
      : // @ts-ignore
      landingPageData?.sessions?.data[0]?.data[0]?.allowSessionOnDemand;
      
    const eventEnd = landingPageData?.eventEndtime && (landingPageData?.eventEndtime && Date.now() > Number(landingPageData?.eventEndtime));
    const { submitWebinarForm, isSubmitLoading } = useWebinarForm({
      authorization: configData?.accessToken,
      language: configData?.language,
      trackingPixelApps: props?.trackingPixelApps,
    } as IHeadersRequest, null);

    const closeModal = (event: React.SyntheticEvent) => {
      event.stopPropagation();
      setActiveModalData(null);
      setOpen(false);
    };

    const goToEvent = () => window.open(configData?.communityUrl, "_blank");

    useEffect(() => {
      if (resendLinkData) {
        resetResentData(false as IResendEmailResponse);
        setOpen(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resendLinkData]);

    const userEmail = formData?.find((field: IWebinarSubmits) => field.fieldType === "email")?.value || "";

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleResendLink = () => {
      if (sendingEmail) return;
      resendLink(
        {
          authorization: configData?.accessToken,
          language: configData?.language,
        } as IHeadersRequest,
        {
          payload: {
            data: {
              buyerEmail: userEmail,
              allowSessionOnDemand: allowSessionOnDemandValue === 1 && eventEnd ? true : false
            },
          },
        } as IResendEmailRequest
      );
      setOpen(false);
    };

    const handleTryAgain = () => {
      if (isSubmitLoading) return;
      submitWebinarForm(
        {
          payload: {
            data: {
              values: formData,
              ticketingLink: `${configData?.communityUrl}/register`,
              isSimpleRegistrationEnabled: true,
              orderToken: "",
              ...(allowSessionOnDemandValue === 1 && eventEnd ? { allowSessionOnDemand: true } : {}),
            },
            ticketId: webinarData?.ticketId || ""
          },
        }
      );
      setOpen(false);
    };

    return activeModalData ? (
        <CustomModal open={open} onRequestClose={closeModal} isOnlyChildren customWidth={500}>
        <Box sx={{padding:"3.2rem 3.8rem","@media(max-width:640px)":{padding:"2.8rem"}}}>
            <Box sx={(theme)  => ({display:"flex",justifyContent:"center","& .eventRegisterIcon":{fill:theme.palette.primary.main}})}>
              {
                activeModalData.type === ActiveModalTypes.SUCCESS ?
                  <TicketPurchaseSuccess/> :
                  activeModalData.type === ActiveModalTypes.ERROR ?
                    <TicketPurchaseFailedIcon/> :
                    activeModalData.type === ActiveModalTypes.RESEND ?
                      <RegisterEventIcon className="eventRegisterIcon"/> :
                      null
              }
            </Box>
            <Typography variant="h5" sx={{marginTop:"2.4rem"}} component="h3" color="textPrimary" textAlign="center">
              {activeModalData.title}
            </Typography>
            <Typography variant="body2" component="p" textAlign="center" color="textSecondary" sx={{marginTop:"2.4rem"}}>
              {activeModalData.subTitle}
            </Typography>
            <Box sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",marginTop:"2.4rem",
            }}>
            {
              activeModalData.type === ActiveModalTypes.SUCCESS && webinarData?.showGoToEventCTA && communityPageShow  ? (
                isHideGoToCTA? <></>:<Button variant="contained" color="primary" onClick={goToEvent}>
                {webinarData?.goToEventCtaLabel}
              </Button>
              ) : activeModalData.type === ActiveModalTypes.ERROR ? (
                <Button variant="contained" color="primary" onClick={handleTryAgain} disabled={isSubmitLoading}>
                  Try Again
                </Button>
              ) :  activeModalData.type === ActiveModalTypes.RESEND ? (
                <>
                  <Button variant="contained" color="primary" sx={{marginRight:"1.6rem"}} disabled={sendingEmail} onClick={handleResendLink}>
                      {t("RESEND_INVITE")}
                  </Button>
                  {
                    webinarData?.showGoToEventCTA && communityPageShow && (
                      isHideGoToCTA?<></>:<Button variant="contained" color="primary" onClick={goToEvent}>
                      {webinarData?.goToEventCtaLabel}
                    </Button>
                    )
                  }
                  
                </>
              ) : null
            }
            </Box>
        </Box>
        </CustomModal>
    ) : <></>;
};

export default WebinarConfirmationModal;