import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  calendarWrapper: {
    padding: `${theme.spacing(8)}  ${theme.spacing(8)}`,
    marginLeft: 0,
    textAlign: "center",
    width:"100%",
    flexWrap: "nowrap",
    "@media (min-width: 768px) and (max-width: 1024px)": {
      padding: `${theme.spacing(8)}  ${theme.spacing(6)}`,
    },
    "@media (max-width: 600px)": {
      padding: `${theme.spacing(3.75)}  ${theme.spacing(0)}`,
      flexWrap: "wrap",
    },
    "& a": {
      "@media (max-width: 600px)": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: 20,
      },
    },
  },
  calendarImgWrapper: {
    lineHeight: "100%",
    margin: "0 auto",
    border: `1px solid ${theme.palette.divider.default}`,
    padding: theme.spacing(2),
    borderRadius: "50%",
    width: 75,
    height: 75,
    textAlign: "center",
    "& svg": {
      width: 30,
      height: "100%",
    },
    "@media (max-width: 600px)": {
      margin: `${theme.spacing(0)}  ${theme.spacing(3.75)} ${theme.spacing(
        0
      )} ${theme.spacing(0)}`,
    },
  },
  mobileTitle: {
    "@media(max-width:600px)":{
      paddingLeft: 40,
    }
  },
}));