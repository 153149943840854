import React from "react";
import PropTypes from "prop-types";

export default function GenericLoader(props:any) {
    if (props.isLoading) {
        return <>{props.loaderComp}</>;
    }
    return <>{props.children}</>;
}

GenericLoader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    loaderComp: PropTypes.element,
    children: PropTypes.element.isRequired,
};
