import React from "react";
import { IIconProps } from "./types";

const ClockIcon = ({
  width = "17",
  height = "16",
  className = "",
}: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.25 0.5C6.76664 0.5 5.3166 0.939867 4.08323 1.76398C2.84986 2.58809 1.88856 3.75943 1.32091 5.12987C0.75325 6.50032 0.604725 8.00832 0.894114 9.46318C1.1835 10.918 1.89781 12.2544 2.9467 13.3033C3.9956 14.3522 5.33197 15.0665 6.78683 15.3559C8.24168 15.6453 9.74968 15.4968 11.1201 14.9291C12.4906 14.3614 13.6619 13.4001 14.486 12.1668C15.3101 10.9334 15.75 9.48336 15.75 8C15.75 7.01509 15.556 6.03982 15.1791 5.12987C14.8022 4.21993 14.2497 3.39314 13.5533 2.6967C12.8569 2.00026 12.0301 1.44781 11.1201 1.0709C10.2102 0.693993 9.23492 0.5 8.25 0.5ZM8.25 14C7.06332 14 5.90328 13.6481 4.91658 12.9888C3.92989 12.3295 3.16085 11.3925 2.70673 10.2961C2.2526 9.19974 2.13378 7.99334 2.36529 6.82946C2.5968 5.66557 3.16825 4.59647 4.00736 3.75736C4.84648 2.91824 5.91558 2.3468 7.07946 2.11529C8.24335 1.88378 9.44975 2.0026 10.5461 2.45672C11.6425 2.91085 12.5795 3.67988 13.2388 4.66658C13.8981 5.65327 14.25 6.81331 14.25 8C14.25 9.5913 13.6179 11.1174 12.4926 12.2426C11.3674 13.3679 9.8413 14 8.25 14ZM8.25 3.5C8.05109 3.5 7.86033 3.57902 7.71967 3.71967C7.57902 3.86032 7.5 4.05109 7.5 4.25V7.9475L6.4725 9.725C6.37305 9.89805 6.34641 10.1035 6.39845 10.2962C6.45049 10.4889 6.57695 10.653 6.75 10.7525C6.92306 10.852 7.12853 10.8786 7.32123 10.8266C7.51392 10.7745 7.67805 10.6481 7.7775 10.475L8.9025 8.525C8.97466 8.38943 9.00346 8.23497 8.985 8.0825L9 8V4.25C9 4.05109 8.92099 3.86032 8.78033 3.71967C8.63968 3.57902 8.44892 3.5 8.25 3.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
export default ClockIcon;
