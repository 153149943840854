// Language: Japanese

export default {
    "already-registered": "既に登録済み",
    "add-to-calendar": "カレンダーに追加",
    "event-starts-in": "イベントの開始日",
    "event-is-live": "イベントはライブです。",
    "event-has-ended": "イベント終了",
    "powered-by": "で作成されています",
    "please-enter-your-email-to-continue": "続行するには、Eメールを入力してください",
    add: "追加",
    offers: "提供",
    free: "無料",
    "available-till": "{{DATE}}まで利用可能",
    "enter-email-address": "Eメールを入力してください",
    "sold-out": "完売",
    "the-ticket-has-reached-the-maximum-number": "チケットが最大数に達しました",
    "seat-left": "残り{{SEAT}}席",
    "seats-left": "残り{{SEAT}}席",
    proceed: "続行",
    summary: "概要",
    "buyer-info": "購入者情報",
    continue: "続行",
    "attendees-info": "参加者情報",
    "registration": "参加登録",
    "attendees-information": "参加者情報",
    submit: "送信",
    "register-to-join-events": "登録してイベントに参加",
    "by-clicking-on-the-button":
        "ボタンを押すと、このイベントに登録できるチケット発行プラットフォームにリダイレクトされます。",
    other: "その他",
    "please-enter-a-value-for": "<FIELD_NAME>の値を入力してください",
    "please-select-a-value-for": "<FIELD_NAME>の値を選択してください",
    "enter-other-value-for": "<FIELD_NAME>のその他の値を入力します。",
    "enter-a-valid": "有効な<FIELD_NAME>を入力します。",
    "should-be-at-least": "<FIELD_NAME>は、<FIELD_LENGTH><FIELD_TYPE>以上である必要があります。",
    "cannot-be-more-than": "<FIELD_NAME>は<FIELD_LENGTH><FIELD_TYPE>以上にすることはできません。",
    "is-required": "<FIELD_NAME>は必須です。",
    "at-least-required": "<FIELD_LENGTH><FIELD_NAME><VERB>以上が必要です。",
    "maximum-allowed": "最大許容値<FIELD_LENGTH><FIELD_NAME><VERB>",
    "user-with-same-email-id-already-exists":
        "同じEメールIDを持つユーザーが既に存在します。 他のメールIDを使ってください。",
    "user-with-same-email-id-has-already-registered-for-this-event":
        "同じEメールIDを持つユーザは、このイベントにすでに登録されています。 他のメールIDを使ってください。",
    "mail-successfully-sent": "メールが正常に送信されました!",
    "we-are-unable-to-deliver-your-mail-at-this-moment":
        "今あなたのメールを送ることができません。 しばらくしてから試してください",
    "something-went-wrong": "何か問題があった！ 後でもう一度試してください",
    "please-select-a-ticket-to-proceed": "続行するにはチケットを選択してください",
    "register-now": "今すぐ登録",
    "add-to-your-calendar": "カレンダーに追加",
    calendar: "カレンダー",
    "resend-link": "リンクの再送信",
    "go-to-event": "イベントに移動",
    "ticket-registration-done-by": "チケットの登録は、次の者が行います。",
    "you-have-already-purchased-the-ticket-for": "のチケットはすでに購入済みです。",
    purchased: "購入済み",
    "event-date": "イベント日",
    "ticket-quantity": "チケットの数量",
    payment: "支払い",
    "unable-to-submit-data": "データを送信できません",
    "unable-to-apply-promo-code": "プロモーションコードを適用できません",
    "invalid-promo-code": "無効なプロモコード",
    "apply-now": "申し込み",
    "enter-discount-coupon": "割引クーポンを入力してください",
    "same-as-buyer": "購入者と同じ",
    "please-check-all-the-fields-are-correctly-filled":
        "すべてのフィールドが正しく入力されていることを確認してください",
    "ticket-purchased": "購入済みチケット",
    "ticket-purchase-failed": "チケット購入に失敗しました",
    "you-have-successfully-purchased-the-ticket":
        "チケットの購入に成功しました。 このチケットでイベントに参加できます。 ご購入ありがとうございます!",
    "there-was-an-error-while-booking-your-tickets":
        "チケットの予約中にエラーが発生しました。 もう一度試してください。",
    "try-again": "再試行",
    "you-dont-have-access": "チケットの一部を購入することはできません。",
    "please-enter-a-valid-email": "有効なEメールを入力してください",
    "payment-summary": "支払いの概要",
    // [HUB-8587] Added multiple language support for Promo Code Success message
    "apply-discount": "{{CODE}}が正常に適用されました。追加で{{AMOUNT}}を保存しました。",
    // [HUB-8597] Added multiple language support for Buyer Taxation Support
    "taxation-concent": "タックスインボイスを作りますか？",
    "taxation-tnc-pp": "続行することにより、あなたは私たちに同意します",
    "taxation-tnc": "規約と条件",
    and: "と",
    "taxation-pp": "プライバシーポリシー",
    "company-name-label": "会社名",
    "company-name-place-holder": "会社名を入力します。",
    "taxId-label": "会社の納税者番号",
    "taxId-place-holder": "会社の納税者番号を入力してください",
    "company-address-label": "会社住所",
    "company-address-place-holder": "住所",
    "country-place-holder": "国を選択",
    "state-place-holder": "州を選択",
    "city-place-holder": "市町村を入力",
    "zipcode-label": "郵便番号",
    "zipcode-place-holder": "郵便番号の入力",
    "company-error-msg": "会社名を入力してください",
    "taxId-error-msg": "有効な会社の納税者番号を入力してください",
    "address-error-msg": "アドレスを入力してください。",
    "country-error-msg": "国を選択してください",
    "state-error-msg": "都道府県を選択してください",
    "city-error-msg": "市町村を入力してください。",
    "zipcode-error-msg": "郵便番号を入力してください。",
    // [HUB-8579] Added multiple language support for Buyer Default Fields
    firstName: "名前（ファーストネーム",
    lastName: "姓（ラストネーム",
    email: "Eメール",
    phone: "電話",
    dialerCode: "ダイヤラコード",
    // [HUB-8602] Added multiple language support for Already Ticket Purchase Modal
    "you-have-already-purchased-ticket-for": "のチケットはすでに購入済みです。",
    "upload-file": "ファイルのアップロード",
    "browse-a-file-to-upload": "アップロードするファイルを参照する",
    "max-size": "最大サイズ",
    "file-supported-types": "対応ファイルの種類",
    "uploaded-successfully": "アップロードに成功しました",
    "failed!-please-retry": "失敗しました 再試行してください",
    "this-file-type-is-not-supported-supported-attachments-are":
        "このファイル形式はサポートされていません。サポートされている添付ファイルは",
    "the-file-size-is-too-high-please-upload-document-lesser-than":
        "ファイルサイズが大きすぎます。以下のサイズのドキュメントをアップロードしてください。",
    person: "人物",
    select: "選択",
    search: "検索",
    "uploaded-file": "アップロードされたファイル",
    uploading: "アップロード",
    delete: "削除",
    "no-item": "項目なし",
    // [HUB-9007] "Read more, Read less and about" text are not converted in Selected language or Event Description and for Booth
    "google-calendar": "Googleカレンダー",
    "outlook-calendar": "Outlookカレンダー",
    "yahoo-calendar": "Yahooカレンダー",
    "read-more": "続きを読む",
    "read-less": "もっと読む",
    "about-title": "について",
    "verb-are": "それは",
    "verb-is": "は",
    character: "文字",
    // [HUB-9250] Date format translation
    "date-format": "日付/月/年",
    "timer-hr": "時間",
    "timer-hrs": "時間",
    "timer-min": "分",
    "timer-mins": "分",
    "timer-sec": "秒",
    "timer-secs": "秒",
    "timer-day": "日",
    "timer-days": "日",
    // [HUB-9352] On Hover of file upload button content shown in english language only
    "select-file-title": "選択したファイルはありません",
    today: "今日",
    yesterday: "昨日",
    tomorrow: "明日",
    last: "最後",
    "internet-down": "Internet seems to be disconnected!",
    "internet-up": "Internet is now connected",
    "network-issue": "Some network issue occurred error message, please try again!",
    registrationSuccess: "登録が完了しました",
    registrationSuccessDetail: "イベントへの登録が完了しました。ありがとうございました。",
    "search-here": "検索..",
    "did-you-mean": "という意味ですか ",
    DISPOSABLE_EMAIL: "指定された電子メールアドレスは、使い捨ての電子メールサービスに属しています。",
    RISTRICTED_DOMAIN: "指定された電子メールアドレスは、制限されたドメイン内にあります。有効なメールアドレスを入力してください。",
    INVALID_EMAIL: "有効なEメールを入力してください",

    // New Keys
    CHOOSE_PAYMENT_METHOD: "お支払い方法を選択",
    PAY_NOW: "今すぐ支払う",
    INSTANT_TICKET_CONFIRMATION: "インスタントチケットの確認",
    PAY_VIA_OTHER_METHODS: "他の方法で支払う",
    FUNDS_TRANSFER: "資金移動",
    DIGITAL_WALLET: "デジタル財布",
    CHEQUE: "小切手",
    PAY_AT_VENUE: "会場でのお支払い",
    TICKET_NOT_CONFIRMED_ORGANIZER: "チケットは、イベント主催者が支払いが受領されたことを承認し、キャンセルの対象となるまで確認されません。",
    TICKET_NOT_CONFIRMED_UNTIL_PAYMENT: "チケットは、イベント会場でお支払いいただくまで確定されず、キャンセルされる場合があります。",
    TICKET_CONFIRMED_BY_ORGANIZER: "チケットは、イベント主催者が支払いを受け取ったことを承認したときに確認されます。",
    COMPLETE_PAYMENT_OF: "の完全な支払い",
    COMPLETE_PAYMENT_TICKET: "チケットのお支払いを完了します。",
    PAYMENT_CONFIRMATION_TITLE: "イベント主催者の一意の取引IDを入力して、支払いを確認します。",
    ENTER_TRANSACTION_ID: "取引IDを入力します",
    SHARE_DETAILS_TITLE: "イベント主催者は、詳細を共有している場合にのみ支払いを承認できます。 無料航空券を含むお客様の航空券は、承認後に確認されます。",
    UPLOAD_SCREENSHOT: "スクリーンショットのアップロード(オプション)", // UPLOAD_SCREENSHOT (not done for uploadNewUI component common)
    SHARE_PAYMENT_DETAILS: "お支払いの詳細を共有する",
    TICKET_STATUS: "チケット状況", // not found
    ORGANIZER_YET_TO_CONFIRM: "イベント主催者は、支払いが受領されたことをまだ確認していません。",
    TICKET_CONFIRMATION_ORGANIZER: "無料チケットを含むチケット、および請求書は、イベント主催者の承認後に確認され、お客様と共有されます。",
    PAYMENT_DETAILS: "お支払いの詳細",
    FAQ_TITLE: "ご質問がある場合は、Faqを参照してください",
    MAKE_PAYMENT_TITLE: "チケットの購入を確認するために、イベント会場で支払いを行います。",
    CONFIRM_TICKET_PRIOR_EVENT: "イベント前にチケットを確認したい場合, 別の方法で今支払う。", // need to handle differently 
    CONFIRM_TICKETS_PRIOR_EVENT: "イベント前にチケットの確認を希望される場合。",
    PAY_NOW_2: "今すぐ支払う",
    WITH_DIFFERENT_METHOD: "別の方法で支払う",
    PAYMENT_NOT_RCVD: "イベント主催者は支払いを受け取っていません。",
    PAYMENT_COULD_NOT_BE_VERIFIED: "共有された支払いの詳細は、イベント主催者によって確認できませんでした。",
    TICKETS_NOT_AVAILABLE: "チケットはイベントのために利用できなくなりました。",
    PAYMENT_DETAILS_YET_TO_SHARE: "チケットの支払い詳細はまだ共有されていません。.", // not found in code
    WELCOME_BACK: "おかえりなさい",
    VIEW_OR_PROCEED_PURCHASE: "以前のチケットを表示したり、選択したチケットの購入を進めることができます。",
    VIEW_PREV_TICKETS: "前のチケットを見る",
    PROCEED_TO_PURCHASE: "購入に進む",
    PREVIOUS_TICKETS: "前のチケット",
    PENDING_TICKETS: "保留中のチケット",
    CONFIRMED_TICKETS: "確認済みのチケット",
    PAYMENT_PENDING: "支払い保留中",
    AWAITING_ORG_APPROVAL: "主催者の承認を待っています",
    CHECK_TICKET_STATUS: "チケットの状態を確認する",
    PAYMENT_METHOD_NOT_AVAILABLE: "お支払い方法はご利用いただけませんので、再度お試しください。", // not found in code
    HERE: "ここだ",
    // [HUB-25026] Multiple Ticket Purchase for Attendees
    RE_ENTER_EMAIL: "購入されたすべてのチケットの参加者情報を更新してください。これにより、すべての参加者がイベントにアクセスできるようになります。",
    DUPLICATE_EMAIL: "あなたはすでにこのチケットを購入しています。 再登録を選択できます。 この場合、入力された最新のデータが保存されます。",
    DEFAULT_DUPLICATE_EMAIL: "このメールはすでに別のチケットで使用されています。 入力したメールアドレスを再確認するか、複数のチケットに同じメールアドレスを自由に使用してください。",
    "REGISTER_NOW": "今すぐ登録",
    "REG_FAILED": "登録失敗",
    "REG_FAILED_DESC": "登録に失敗しました。 もう一度試してください。",
    "REG_DONE_BY": "登録は次の者によって行われます",
    "INVITE_AGAIN": "もう一度招待してもらいたいですか？",
    "REG_SUCCESS_DONE": "登録が正常に完了しました",
    "WATCH_REC_LINK": "録画を見るためのリンクを記載したメールが送信されました",
    "WEBINAR_INV_LINK": "ウェビナーリンクを記載した招待メールが送信されました",
    "MAIL_SENT_SUCCESS": "メールが正常に送信されました",
    "ALREADY_REGISTERED": "すでに登録されています",
    "RESEND_INVITE": "招待を再送信",
    "WATCH_RECORDING_REGISTER_DESC": "下記に登録していただくとウェビナーの録画を視聴できます。",

};
