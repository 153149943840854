// libraries
import React, { useCallback } from "react";
import get from "lodash/get";
import { makeStyles } from "@mui/styles";

// UI Components
import { Typography, Box } from "@mui/material";
import TextField from "./InputField";

// Utils
import allCountryCode from "../Utils/countryCode";

// Interfaces
import { IRegion } from "../Types/BuyerDetails";
import SelectDropDown from "./SelectDropDown";
import { useStore } from "./../Context";

const useStyles = makeStyles((theme: any) => ({
  countryCodeforLandingpage: {
    "& .countryCode": {
      "&>div": {
        height: "40px",
        minHeight: "40px",
      },
    }
  },
  countryCodeWrapper: {
    marginTop: 2,
    "& .countryCode": {
      "& .MuiSelect-select": {
        paddingRight: theme.spacing(2),

        "&>p": {
          minWidth: theme.spacing(5),
        },
      },
      "&>div": {
        borderRight: "none",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        marginTop: 0,
        height: "40px",

      },
      "& .MuiSelect-icon": {
        right: "-3px",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${theme.palette.divider.default} !important`,
        },
      },
      "&:hover": {
        "& fieldset": {
          borderColor: `${theme.palette.divider.default} !important`,
        },
      },
      "& .popup": {
        width: "300px",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(3),
      },
    },
    "& .errorDialer": {
      "&>div": {
        border: "1px solid red",
        borderRight: "none"
      }
    },
    "& .errorPhone": {
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderTop: "1px solid red !important",
          borderBottom: "1px solid red !important",
          borderRight: "1px solid red !important",
        },
      },
      "& fieldset": {
        border: "1px solid red",
        borderLeft: "none"
      },
      "& .MuiOutlinedInput-root": {
        "&:hover": {
          "& fieldset": {
            borderColor: "red",
          }
        },
      },
      "&:hover": {
        "& fieldset": {
          borderColor: "red !important",
        }
      },
      "&:focus": {
        "& fieldset": {
          borderColor: "red !important",
        }
      },
    },
    "& .phoneNumberTextBox": {
      "&:hover": {
        "& fieldset": {
          borderColor: `${theme.palette.divider.default} !important`,
        },
      },
      "&:focus": {
        "& fieldset": {
          borderColor: `${theme.palette.divider.default} !important`,
        },
      },
      "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderTop: `1px solid ${theme.palette.divider.default} !important`,
          borderBottom: `1px solid ${theme.palette.divider.default} !important`,
          borderRight: `1px solid ${theme.palette.divider.default} !important`,
        },
      },
      "& input": {
        paddingLeft: "0px !important",
      },
      "& .MuiInputBase-formControl": {
        "& fieldset": {
          borderLeft: 0,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      },
    },
  },
}));

export interface TextBoxAutoSelectProps {
  placeHolder?: string;
  id?: string;
  name?: string;
  value?: string | number;
  countryCode?: string;
  textLabel?: string;
  textType?: "text" | "number" | "phone";
  className?: string;
  rounded?: boolean;
  icon?: null | React.ReactNode;
  iconAlign?: "start" | "end";
  iconColor?: "primary" | "secondary" | "themecolor";
  radius?: boolean;
  bgColor?: string;
  withoutRadius?: string;
  testId?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (option: IRegion) => void;
  errorType?: "error" | "success" | "";
  hintText?: string;
  maxLength?: number;
  isCharLimit?: boolean;
  charLimit?: number;
  otherLabelKey?: string;
  isMandatory?: any;
  errorMsg?: string;
  position?: string;
}

export type TValueObj = Record<string, string | number>;
export type TValueType = TValueObj | string | number;

const TextBoxAutoSelect = ({
  id = "",
  value = "",
  countryCode = "",
  textLabel = "",
  textType = "number",
  onChange,
  onBlur,
  onSelect,
  isMandatory = false,
  otherLabelKey,
  errorMsg,
  position,
  isCharLimit,
  charLimit,
  maxLength,
}: TextBoxAutoSelectProps): JSX.Element => {
  const classes = useStyles();

  const handleDDSelect = (param: TValueType): void => {
    if (onSelect) {
      const payload: IRegion = {
        name: get(param, "name", ""),
        dial_code: get(param, "dial_code", ""),
        code: get(param, "code", ""),
      };
      onSelect(payload);
    }
  };

  const handleChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e);
    },
    [onChange]
  );
  const {
    store,
  } = useStore();

  return (
    <Box>
      <Box sx={{ textAlign: "left" }}>
        {textLabel ? (
          <Typography
            component="label"
            variant="caption"
            htmlFor={id}
            sx={(theme: any) => ({
              color: theme.palette.primary.main,
              fontWeight: 400,
            })}
          >
            {textLabel}{" "}
            {isMandatory ? (
              <Typography
                component="span"
                sx={(theme: any) => ({
                  color: theme.palette.primary.main,
                  fontSize: 16,
                  lineHeight: 0,
                })}
              >
                *
              </Typography>
            ) : (
              ""
            )}{" "}
          </Typography>
        ) : null}
      </Box>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          className={`${classes.countryCodeWrapper} ${store.startFromSummary ? classes.countryCodeforLandingpage : ""}`}
        >
          <SelectDropDown
            value={countryCode}
            onChange={(opt: any) => handleDDSelect(opt)}
            options={allCountryCode}
            placeHolder="+00"
            name="CountryCode"
            position={position}
            labelKey="name"
            valueKey="dial_code"
            otherLabelKey={otherLabelKey}
            className={`${errorMsg ?  "errorDialer": ""} countryCode`}
          />
          <TextField
            id={String(id)}
            name={textLabel}
            type={`${textType}`}
            placeHolder={textLabel}
            value={value.toString()}
            onChange={handleChanges}
            maxLength={maxLength}
            endIcon={maxLength && isCharLimit ? charLimit?.toString() : null}
            onBlur={onBlur}
            className={`${errorMsg ?  "errorPhone": ""} phoneNumberTextBox`}
          />
        </Box>
      </Box>
      {errorMsg ? (
        <Typography
          component="p"
          variant="caption"
          sx={(theme: any) => ({
            color: theme.palette.error.main,
            fontWeight: 400,
            marginTop: "2px",
          })}
        >
          {errorMsg}
        </Typography>
      ) : (
        false
      )}
    </Box>
  );
};

export default TextBoxAutoSelect;
