import React, { useState, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Fade,
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  ClickAwayListener,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import InputField from "./InputField";
import { useStore } from "Context";
import { useTranslation } from "react-i18next";

export type TValueObj = Record<string, string | number>;
export type TValueType = TValueObj | string | number;

const useStyle = makeStyles((theme: any) => ({
  menuList: {
    paddingTop: "6px",
    paddingBottom: "6px",

    cursor: "pointer",
    "&.Mui-selected": {
      background: theme.palette.primary.lighter,
      "&>div":{
        "&>span":{
          color: theme.palette.primary.main,
        }
      }
    },
    "&:hover": {
      background: theme.palette.background.lightGray,
      color:theme.palette.text.primary,
    },
    "&>div": {
      "&>span": {
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },
    },
  },
  searchBox:{
    background: theme.palette.background.lightGray,
    borderRadius:"0.32rem",
    color:theme.palette.text.secondary,
    "& svg":{
      color:theme.palette.text.secondary,
    }
  }
}));

interface FiltersProps {
  label?: string;
  className?: string;
  options?: any;
  placeHolder?: string;
  value?: any;
  onChange?: any;
  id?: any;
  required?: any;
  name?: any;
  valueKey?: any;
  labelKey?: any;
  otherValue?: any;
  errorMsg?: any;
  otherLabelKey?: any;
  position?:string;
}

const SelectDropDown = ({
  placeHolder = "",
  errorMsg,
  label,
  required,
  options,
  valueKey,
  onChange,
  labelKey,
  value,
  position="top",
  className,
  otherLabelKey,
}: FiltersProps): JSX.Element => {
  const [t] = useTranslation();
  const classes = useStyle();
  const [isMenuOpen, toggleMenu] = useState(false);
  const [search, setSearch] = useState("");
  const {
    store,
  } = useStore();
  const handelSelectOption = (event: React.SyntheticEvent, val: TValueType) => {
    event.stopPropagation();
    if (onChange) {
      onChange(val, name, event);
      setSearch("");
    }
    toggleMenu(false);
  };

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const currOpt = useMemo(() => {
    if (search === "") return options;
    else {
      return options.filter((eachOption: TValueType) => {
        if (typeof eachOption === "string") {
          return eachOption.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        } else if (typeof eachOption === "number") {
          return (
            String(eachOption).toLowerCase().indexOf(search.toLowerCase()) !==
            -1
          );
        } else if (
          typeof eachOption === "object" &&
          (labelKey || otherLabelKey)
        ) {
          if (otherLabelKey) {
            return (
              String(eachOption[labelKey])
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1 ||
              String(eachOption[otherLabelKey])
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
            );
          } else {
            return (
              String(eachOption[labelKey])
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
            );
          }
        } else return true;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, options, labelKey]);

  function getLabelByValueKey(options: any, curValue: any, valueKey: any) {
    const val =
      typeof curValue === "object"
        ? curValue[valueKey][valueKey]
          ? curValue[valueKey][valueKey]
          : curValue[valueKey]
        : curValue;
    const curIndex = options.findIndex((ele: any) => ele[valueKey] === val);
    if (curIndex > -1) {
      return options[curIndex][valueKey];
    } else {
      return "";
    }
  }
  return (
    <ClickAwayListener onClickAway={()=>toggleMenu(false)}>
    <Box position="relative" className={className}>
      {label ? (
        <Typography
          component="label"
          variant="caption"
          sx={(theme) => ({
            color: theme.palette.primary.main,
            fontWeight: 400,
          })}
        >
          {label}{" "}
          {required ? <Typography component="span">*</Typography> : false}
        </Typography>
      ) : (
        false
      )}
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        sx={(theme: any) => ({
          border: `1px solid ${theme.palette.divider.default}`,
          marginTop: "1px",
          borderRadius: 1,
          minHeight: store.startFromSummary ? "40px":22,
          padding: store.widgetData?.isWidgetExpand ? "9px 10px": "7px 10px",
          cursor: "pointer",
          ...(currOpt?.length === 0 && {
            background: theme.palette.background.lightGray,
            cursor: "not-allowed"
          }),
        })}
        onClick={() => currOpt?.length && toggleMenu(!isMenuOpen)}
      >
        <Box
          display="flex"
          alignItems="center"
          component="span"
          sx={(theme: any) => ({
            color: theme.palette.text.secondary,
            fontFamily: "Helvetica Neue New",
            fontSize: 14,
          })}
        >
          {!value || (Array.isArray(value) && !value.length) ? (
            <Box component="span" color="text.grayColor">
              {placeHolder}
            </Box>
          ) : (
            <Box component="span" sx={(theme:any)=>({fontWeight:400,color:theme.palette.text.primary, wordBreak:"initial"})}>
              {getLabelByValueKey(options, value, valueKey)}
            </Box>
          )}
        </Box>
        <Box
          ml={1}
          sx={(theme:any)=>({color:theme.palette.text.primary,"&>svg":{height:"20px",width:"20px"}})}
          component="span"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          {!isMenuOpen ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
        </Box>
      </Box>

      <Fade in={isMenuOpen}>
        <Box
           className="popup"
           position="absolute"
           top={`${position === "top" ? "100%" : "auto"}`}
           bottom={`${position === "top" ? "auto" : "100%"}`}
           left="0"
           right="0"
           zIndex={5}
           pt={1.2}
           pb={1.2}
        >
          <Paper
            elevation={2}
            sx={(theme: any) => ({
              background: theme.palette.background.cardColor,
              border:`1px solid ${theme.palette.divider.default}`,
              overflow: "hidden",
            })}
          >
            <Box px={1.5} pt={1.5}>
              <Box>
                <InputField
                  type="search"
                  id="search"
                  startIcon
                  icon={<SearchIcon/>}
                  placeHolder={t("search-here")}
                  value={search}
                  className={classes.searchBox}
                  onChange={handleSearch}
                  tabIndex={currOpt.length + 2}
                />
              </Box>
            </Box>
            {isMenuOpen && (
              <List sx={{maxHeight:"170px",overflowY:"auto",paddingBottom:"8px !important",mt:"0px !important",mb:"0px !important"}}>
                {currOpt &&
                  currOpt?.map((data: any, i: any) => {
                    const curValue =
                      typeof value === "object" ? value[valueKey] : value;
                    const curOpt = data;
                    return (
                      <ListItem
                        component="li"
                        key={i}
                        className={`${classes.menuList}`}
                        selected={!!(value && curValue === curOpt[valueKey])}
                        onClick={(e: any) => handelSelectOption(e, data)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ variant: "body2" }}
                          primary={data[labelKey]}
                        ></ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
            )}
          </Paper>
        </Box>
      </Fade>
      {errorMsg ? (
        <Typography
          variant="caption"
          component="p"
          sx={(theme) => ({ color: theme.palette.error.main, fontWeight: 400 })}
        >
          {errorMsg}
        </Typography>
      ) : (
        false
      )}
    </Box>
    </ClickAwayListener>
  );
};

export default SelectDropDown;
