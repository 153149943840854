// eslint-disable

import envConfig from "config";

function getEventLogoImage(
  imageFileName: string,
  organiserId: string | number,
  size: string | number = 300
): string {
  const eventLogo = `${envConfig.BASE_CDN_URL}logo`;
  let url = "";
  if (imageFileName) {
    url = `${eventLogo}/${organiserId}/${size}/${imageFileName}`;
  } else {
    url = `${eventLogo}/comm_v2/images/profile/default_hash.png`;
  }
  return url;
}

export const fixRegExp = (regex = "" as string): [string, string] => {
  if (regex && regex.startsWith("/")) {
    const regexExpression = regex.substring(1, regex.lastIndexOf("/"));
    const regexOptions = regex.substring(
      regex.lastIndexOf("/") + 1,
      regex.length
    );
    return [regexExpression, regexOptions];
  }
  return [regex, ""];
};

export const getPhoneCode = (val: string): string[] => {
  if (val) {
    const numberSplits = val.split("-");
    if (numberSplits && Array.isArray(numberSplits) && numberSplits.length) {
      return [numberSplits[0], numberSplits[numberSplits.length - 1]];
    }
  }

  return ["", ""];
};

export interface ICheckArray<T> {
  isArray: boolean;
  array: T[];
}

export const isTrueArray = <T>(array: T[]): ICheckArray<T> => {
  if (array && Array.isArray(array)) {
    return { isArray: true, array };
  }
  return { isArray: false, array };
};

export const isTrueValueArray = <T>(array: T[]): boolean => {
  const { isArray: actArray, array: current } = isTrueArray<T>(array);
  if (actArray && current.length) {
    return true;
  }
  return false;
};

export const getObjectElement = (obj: any, key: any, init?: any) => {
  let returnData = init;
  if (obj && typeof obj === "object") {
    if (key in obj) {
      returnData = obj[key];
    }
  }
  return returnData;
};

export const getUpperCaseString = (strValue: string) => {
  return strValue.toUpperCase().replace(" ", "").trim();
};

export const roundOffTo2 = (amount: number | null): number =>
  amount ? Math.ceil(amount * 100) / 100 : 0;

export { getEventLogoImage };

export function getParameterByName<T>(
  name: string,
  url = window?.location?.href as string
): null | string | T {
  // eslint-disable-next-line no-useless-escape
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const getPathName = (uri: string) => {
  const uriDetails = new URL(uri);
  return `${uriDetails.pathname}/register`;
};

export const stripQueryParams = (uri: string) => {
  const url = new URL(uri);
  return `${url.origin}${url.pathname}`;
};

export const stringToBase64 = (str: string): string => {
  if (str) {
    // BTOA is not deprecated for web, checkout https://developer.mozilla.org/en-US/docs/Web/API/btoa
    return btoa(str);
  }
  return "";
};

export const isTicketAmountFloat = (n: number) => {
  const isFloat = n !== 0 && !isNaN(n) && Math.round(n) !== n;
  return isFloat ? n.toFixed(2) : n;
};

export const getUTM = () => {
  const source = getParameterByName<string>("utm_source") || undefined;
	const campaign = getParameterByName<string>("utm_campaign") || undefined;
	const medium = getParameterByName<string>("utm_medium") || undefined;
	const name = getParameterByName<string>("utm_name") || undefined;
	const term = getParameterByName<string>("utm_term") || undefined;
  const content = getParameterByName<string>("utm_content") || undefined;
  let utm;
  if (source || medium || campaign || name || term || content) {
    utm = {
      source,
      medium,
      campaign,
      name,
      term,
      content,
    };
  }
  return utm;
};

export const getUTMUrlPath = () => {
  const utm = getUTM();
  let code = "";
    if (utm?.source)
      code = code.length
        ? code + `&utm_source=${encodeURIComponent(utm?.source)}`
        : `?utm_source=${encodeURIComponent(utm?.source)}`;
    if (utm?.campaign)
      code = code.length
        ? code + `&utm_campaign=${encodeURIComponent(utm?.campaign)}`
        : `?utm_campaign=${encodeURIComponent(utm?.campaign)}`;
    if (utm?.medium)
      code = code.length
        ? code + `&utm_medium=${encodeURIComponent(utm?.medium)}`
        : `?utm_medium=${encodeURIComponent(utm?.medium)}`;
    if (utm?.name)
      code = code.length
        ? code + `&utm_name=${encodeURIComponent(utm?.name)}`
        : `?utm_name=${encodeURIComponent(utm?.name)}`;
    if (utm?.content)
      code = code.length
        ? code + `&utm_content=${encodeURIComponent(utm?.content)}`
        : `?utm_content=${encodeURIComponent(utm?.content)}`;
    if (utm?.term)
      code = code.length
        ? code + `&utm_term=${encodeURIComponent(utm?.term)}`
        : `?utm_term=${encodeURIComponent(utm?.term)}`;
  return code;
};