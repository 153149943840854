import envConfig from "config";

export function getBannerImage(
    imageFileName: string,
    organiserId: string | number,
    size: string | number = 1036,
): string {
    const cdnDomainUrl = envConfig.BASE_CDN_URL;
    const communityBanner = `${cdnDomainUrl}${envConfig.COMMUNITY_BANNER}`;
    let url = "";
    // https://d28hsb6vkzynsw.cloudfront.net/banner/community_banner/66594/1036/2137_9767_695042001607665221.png
    if (imageFileName) {
        url = `${communityBanner}/${organiserId}/${size}/${imageFileName}`;
        // url = `https://d28hsb6vkzynsw.cloudfront.net/banner/community_banner/66594/1036/${imageFileName}`;
    } else {
        url = `${url}${envConfig.DEFAULT_USER_IMAGE}`;
    }
    return url;
}




