export default {
    "already-registered": "Sunteți deja înregistrat?",
    "add-to-calendar": "Adăugați la calendar",
    "event-starts-in": "Evenimentul începe în",
    "event-is-live": "EVENIMENTUL ESTE ÎN DIRECT",
    "event-has-ended": "EVENIMENTUL S-A ÎNCHEIAT",
    "powered-by": "Oferit de",
    "please-enter-your-email-to-continue": "Vă rugăm să introduceți e-mailul dvs. pentru a continua",
    "add": "Adăugați",
    "offers": "Oferte",
    "free": "Gratuit",
    "available-till": "Disponibil pana la {{DATE}}",
    "enter-email-address": "Introduceți adresa de e-mail",
    "sold-out": "Vândut",
    "the-ticket-has-reached-the-maximum-number": "Biletul a atins numărul maxim",
    "seat-left": "{{SEAT}} loc rămas",
    "seats-left": "{{SEAT}} locuri rămase",
    "proceed": "Continuați",
    "summary": "Rezumat",
    "buyer-info": "Informații despre cumpărător",
    "attendees-info": "Informații despre participanți",
    "registration": "Înregistrare",
    "continue": "Continuă",
    "attendees-information": "Informații pentru participanți",
    "submit": "Trimiteți",
    "register-to-join-the-events": "Înregistrați-vă pentru a participa la evenimente",
    "by-clicking-on-the-button": "Făcând clic pe buton, veți fi redirecționat către platforma de bilete unde vă puteți înregistra pentru acest eveniment.",
    "other": "Alte",
    "please-enter-a-value-for": "Vă rugăm să introduceți o valoare pentru <FIELD_NAME>.",
    "please-select-a-value-for": "Vă rugăm să selectați o valoare pentru <FIELD_NAME>.",
    "enter-other-value-for": "Introduceți altă valoare pentru <FIELD_NAME>",
    "enter-a-valid": "Introduceți un <FIELD_NAME> valabil",
    "should-be-at-least": "<FIELD_NAME> ar trebui să fie de cel puțin <FIELD_LENGTH> <FIELD_TYPE>",
    "cannot-be-more-than": "<FIELD_NAME> nu poate fi mai mare de <FIELD_LENGTH> <FIELD_TYPE>",
    "is-required": "<FIELD_NAME> este obligatoriu",
    "at-least-required": "Cel puțin <FIELD_LENGTH> <FIELD_NAME> <VERB> este necesar",
    "maximum-allowed": "Maximum <FIELD_LENGTH> <FIELD_NAME> <VERB> permis",
    "user-with-same-email-id-already-exists": "Utilizator cu același ID de e-mail există deja. Vă rugăm să utilizați unul diferit.",
    "user-with-same-email-id-has-already-registered-for-this-event": "Utilizatorul cu același ID de e-mail s-a înregistrat deja pentru acest eveniment. Vă rugăm să utilizați unul diferit.",
    "mail-successfully-sent": "Mail trimis cu succes!",
    "we-are-unable-to-deliver-your-mail-at-this-moment": "În acest moment nu putem să vă livrăm corespondența! Vă rugăm să încercați după ceva timp",
    "something-went-wrong": "Ceva nu a mers bine. Vom remedia acest lucru. Ne cerem scuze pentru neplăcerile cauzate.",
    "register-now": "Înregistrați-vă acum",
    "add-to-your-calendar": "Adăugați la calendar",
    "please-select-a-ticket-to-proceed": "Vă rugăm să selectați un bilet pentru a continua",
    "resend-link": "Trimiteți din nou link-ul",
    "go-to-event": "Mergeți la eveniment",
    "ticket-registration-done-by": "Înregistrarea biletelor se face până la",
    "you-have-already-purchased-the-ticket-for": "Ați cumpărat deja biletul pentru",
    "purchased": "Achiziționat",
    "event-date": "Data evenimentului",
    "ticket-quantity": "Cantitatea de bilete",
    "payment": "Plata",
    "unable-to-submit-data": "Nu se pot trimite date",
    "unable-to-apply-promo-code": "Nu se poate aplica codul promoțional",
    "invalid-promo-code": "Cod promoțional nevalid",
    "apply-now": "Aplicați",
    "enter-discount-coupon": "Introduceți cuponul de reducere",
    "same-as-buyer": "Același ca și cumpărătorul",
    "please-check-all-the-fields-are-correctly-filled": "Vă rugăm să verificați dacă toate câmpurile sunt completate corect",
    "ticket-purchased": "Bilet achiziționat!",
    "ticket-purchase-failed": "Achiziționarea biletelor a eșuat!",
    "you-have-successfully-purchased-the-ticket": "Ați achiziționat cu succes biletul. Acest bilet vă va permite să participați la eveniment. Vă mulțumim pentru achiziție!",
    "there-was-an-error-while-booking-your-tickets": "A apărut o eroare la rezervarea biletelor. Vă rugăm să încercați din nou.",
    "try-again": "Încercați din nou",
    "you-dont-have-access": "Nu aveți acces să cumpărați unele dintre bilete",
    "please-enter-a-valid-email": "Vă rugăm să introduceți un e-mail valid",
    "please-enter-email-to-continue": "Vă rugăm să introduceți adresa de e-mail pentru a continua",
    "payment-summary": "Rezumatul plății",
    "apply-discount": "{{CODE}} aplicat cu succes. Ați salvat suplimentar {{AMOUNT}}.",
    "and": "și",
    "taxation-concent": "Doriți să generați o factură fiscală?",
    "taxation-tnc-pp": "Continuând, sunteți de acord cu",
    "taxation-tnc": "Termeni și condiții",
    "taxation-pp": "Politica de confidențialitate",
    "company-name-label": "Numele companiei",
    "company-name-place-holder": "Introduceți numele companiei",
    "taxId-label": "ID-ul fiscal al companiei",
    "taxId-place-holder": "Introduceți ID-ul fiscal al companiei",
    "company-address-label": "Adresa companiei",
    "company-address-place-holder": "Adresa",
    "country-place-holder": "Selectați țara",
    "state-place-holder": "Selectați statul",
    "city-place-holder": "Introduceți orașul",
    "zipcode-label": "Cod poștal",
    "zipcode-place-holder": "Introduceți codul poștal",
    "company-error-msg": "Vă rugăm să introduceți numele companiei",
    "taxId-error-msg": "Vă rugăm să introduceți codul fiscal al companiei",
    "address-error-msg": "Vă rugăm să introduceți adresa",
    "country-error-msg": "Vă rugăm să selectați Țara",
    "state-error-msg": "Vă rugăm să selectați Statul",
    "city-error-msg": "Vă rugăm să introduceți orașul",
    "zipcode-error-msg": "Vă rugăm să introduceți codul poștal",
    "firstName": "Nume",
    "lastName": "Prenume",
    "email": "E-mail",
    "phone": "Telefon",
    "dialerCode": "Cod de apelare",
    "you-have-already-purchased-ticket-for": "Ați achiziționat deja bilet pentru",
    "browse-a-file-to-upload": "Răsfoiți un fișier pentru a încărca",
    "upload-file": "Încărcați fișierul",
    "max-size": "Dimensiunea maximă",
    "file-supported-types": "Tipuri de fișiere acceptate",
    "uploaded-successfully": "Încărcat cu succes",
    "failed!-please-retry": "Eșuat! Vă rugăm să încercați din nou",
    "this-file-type-is-not-supported-supported-attachments-are": "Acest tip de fișier nu este acceptat. Atașamentele acceptate sunt",
    "the-file-size-is-too-high-please-upload-document-lesser-than": "Dimensiunea fișierului este prea mare. Vă rugăm să încărcați un document mai mic de",
    "person": "Persoană",
    "select": "Selectați",
    "search": "Căutare",
    "uploaded-file": "Fișier încărcat",
    "uploading": "Se încarcă",
    "delete": "Ștergeți",
    "no-item": "Niciun articol",
    "read-more": "Citește mai mult",
    "read-less": "Citește mai puțin",
    "about-title": "Despre",
    "google-calendar": "Google Calendar",
    "outlook-calendar": "Outlook Calendar",
    "yahoo-calendar": "Yahoo Calendar",
    "verb-are": "sunt",
    "verb-is": "este",
    "character": "caracter",
    "date-format": "DD/MM/YYYY",
    "timer-hr": "oră",
    "timer-hrs": "ore",
    "timer-min": "minut",
    "timer-mins": "minute",
    "timer-sec": "secundă",
    "timer-secs": "secunde",
    "timer-day": "ziua",
    "timer-days": "zile",
    "select-file-title": "Niciun fișier ales",
    "today": "Astăzi",
    "yesterday": "Ieri",
    "tomorrow": "Mâine",
    "last": "Ultimul",
    "internet-down": "Internetul pare să fie deconectat!",
    "internet-up": "Internetul este acum conectat",
    "network-issue": "A apărut un mesaj de eroare privind o problemă de rețea, vă rugăm să încercați din nou!",
    "registrationSuccess": "Înregistrare efectuată cu succes!",
    "registrationSuccessDetail": "V-ați înregistrat cu succes la eveniment. Vă mulțumim!",
    "search-here": "Căutați aici..",
    "did-you-mean": "Ați vrut să spuneți",
    "DISPOSABLE_EMAIL": "Adresa de e-mail dată aparține unui serviciu de e-mail de unică folosință",
    "RISTRICTED_DOMAIN": "Adresa de e-mail furnizată se află într-un domeniu restricționat. Vă rugăm să introduceți o adresă de e-mail validă",
    "INVALID_EMAIL": "Vă rugăm să introduceți un e-mail valid",
    "ORDERED": "Comandat",
    "CHOOSE_PAYMENT_METHOD": "Alegeți metoda de plată",
    "PAY_NOW": "Plătiți acum",
    "INSTANT_TICKET_CONFIRMATION": "Confirmare instantanee a biletului",
    "PAY_VIA_OTHER_METHODS": "Plătiți prin alte metode",
    "FUNDS_TRANSFER": "Transfer de fonduri",
    "DIGITAL_WALLET": "Portofel digital",
    "CHEQUE": "Cec",
    "PAY_AT_VENUE": "Plătiți la locul de desfășurare",
    "TICKET_NOT_CONFIRMED_ORGANIZER": "Biletul (biletele) nu va (vor) fi confirmat(e) până când organizatorul evenimentului nu aprobă că plata a fost primită și poate (pot) fi anulat(e).",
    "TICKET_NOT_CONFIRMED_UNTIL_PAYMENT": "Biletul (biletele) nu va (vor) fi confirmat(e) până când nu efectuați plata la locul evenimentului și pot fi anulate.",
    "TICKET_CONFIRMED_BY_ORGANIZER": "Biletul (biletele) va (vor) fi confirmat(e) în momentul în care organizatorul evenimentului va aproba primirea plății.",
    "COMPLETE_PAYMENT_OF": "Plata completă a",
    "COMPLETE_PAYMENT_TICKET": "Efectuați plata pentru biletul (biletele) dumneavoastră.",
    "PAYMENT_CONFIRMATION_TITLE": "Introduceți ID-ul unic de tranzacție pentru organizatorul evenimentului pentru a verifica plata.",
    "ENTER_TRANSACTION_ID": "Introduceți ID-ul tranzacției",
    "SHARE_DETAILS_TITLE": "Organizatorul evenimentului poate aproba plata numai dacă îi comunicați detaliile. Biletul (biletele) dumneavoastră, inclusiv orice bilet (bilete) gratuit(e), va (vor) fi confirmat(e) după aprobare.",
    "UPLOAD_SCREENSHOT": "Încărcați captura de ecran (opțional)",
    "SHARE_PAYMENT_DETAILS": "Partajați detaliile de plată",
    "TICKET_STATUS": "Starea biletului",
    "ORGANIZER_YET_TO_CONFIRM": "Organizatorul evenimentului nu a confirmat deocamdată că plata a fost primită.",
    "TICKET_CONFIRMATION_ORGANIZER": "Biletul (biletele), inclusiv orice bilet (bilete) gratuit(e), și factura vor fi confirmate și vă vor fi comunicate după aprobarea organizatorului evenimentului.",
    "PAYMENT_DETAILS": "Detaliile de plată",
    "FAQ_TITLE": "În cazul în care aveți întrebări, consultați secțiunea Întrebări frecvente.",
    "MAKE_PAYMENT_TITLE": "Efectuați plata la locul de desfășurare a evenimentului pentru a confirma achiziționarea biletului (biletelor).",
    "CONFIRM_TICKET_PRIOR_EVENT": "Dacă doriți să vă confirmați biletul (biletele) înainte de eveniment, plătiți acum cu o altă metodă.",
    "CONFIRM_TICKETS_PRIOR_EVENT": "Dacă doriți să vă confirmați biletul (biletele) înainte de eveniment,",
    "PAY_NOW_2": "Plătiți acum",
    "WITH_DIFFERENT_METHOD": "cu o metodă diferită",
    "PAYMENT_NOT_RCVD": "Organizatorul evenimentului nu a primit plata.",
    "PAYMENT_COULD_NOT_BE_VERIFIED": "Detaliile de plată comunicate nu au putut fi verificate de către organizatorul evenimentului.",
    "TICKETS_NOT_AVAILABLE": "Biletele nu mai sunt disponibile pentru acest eveniment.",
    "PAYMENT_DETAILS_YET_TO_SHARE": "Nu ați comunicat încă detaliile de plată pentru bilet(e).",
    "WELCOME_BACK": "Bine ați revenit!",
    "VIEW_OR_PROCEED_PURCHASE": "Puteți vizualiza biletele dvs. anterioare sau puteți continua cu achiziționarea biletelor pe care tocmai le-ați selectat.",
    "VIEW_PREV_TICKETS": "Vezi Bilete anterioare",
    "PROCEED_TO_PURCHASE": "Continuați la Cumpărare",
    "PREVIOUS_TICKETS": "Bilete anterioare",
    "PENDING_TICKETS": "Bilete în așteptare",
    "CONFIRMED_TICKETS": "Bilete confirmate",
    "PAYMENT_PENDING": "Plată în așteptare",
    "AWAITING_ORG_APPROVAL": "În așteptarea aprobării organizatorului",
    "CHECK_TICKET_STATUS": "Verificați starea biletului",
    "PAYMENT_METHOD_NOT_AVAILABLE": "metoda de plată nu este disponibilă, vă rugăm să încercați din nou.",
    "HERE": "aici.",
    "RE_ENTER_EMAIL": "Vă rugăm să actualizați informațiile unice ale participanților pentru toate biletele achiziționate. Acest lucru va permite tuturor participanților să aibă acces la eveniment.",
    "DUPLICATE_EMAIL": "Ați cumpărat deja acest bilet. Puteți alege să vă reînregistrați. În acest caz, vor fi salvate cele mai recente date introduse.",
    "DEFAULT_DUPLICATE_EMAIL": "Acest e-mail este deja folosit pentru un alt bilet. Vă rugăm să verificați de două ori adresa de e-mail pe care ați introdus-o sau să nu ezitați să folosiți același e-mail pentru mai multe bilete.",
    "REGISTER_NOW": "Înregistrați-vă acum",
    "REG_FAILED": "Înregistrare eșuată",
    "REG_FAILED_DESC": "Înregistrarea dvs. a eșuat. Vă rugăm să încercați din nou.",
    "REG_DONE_BY": "Înregistrare făcută de",
    "INVITE_AGAIN": "Doriți invitația din nou?",
    "REG_SUCCESS_DONE": "Înregistrare efectuată cu succes",
    "WATCH_REC_LINK": "Un e-mail cu un link pentru a viziona înregistrarea a fost trimis către",
    "WEBINAR_INV_LINK": "Un e-mail de invitație cu linkul webinarului a fost trimis către",
    "MAIL_SENT_SUCCESS": "Mail trimis cu succes",
    "ALREADY_REGISTERED": "V-ați înregistrat deja pentru",
    "RESEND_INVITE": "Retrimiteți invitația",
    "WATCH_RECORDING_REGISTER_DESC": "Puteți viziona înregistrarea webinarului înregistrându-vă mai jos.",

};
