import React from "react";
import { IIconProps } from "./types";

const TagIcon = ({ className = "" }: IIconProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      className={className}
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.1665 9.69426C16.1675 9.51887 16.1339 9.34502 16.0675 9.18266C16.0012 9.0202 15.9034 8.87245 15.7798 8.74786L15.7784 8.74642L9.01174 1.97975L8.86529 1.83331H8.65819H2.33318H1.83318V2.33331V8.65831V8.86559L1.97985 9.01208L8.74652 15.7704L8.74735 15.7712C8.99717 16.0196 9.3351 16.159 9.68735 16.159C10.0396 16.159 10.3775 16.0196 10.6274 15.7712L10.6281 15.7705L15.7781 10.6288L15.7798 10.6271C16.0261 10.3789 16.1649 10.0439 16.1665 9.69426ZM16.1665 9.69426C16.1665 9.69409 16.1665 9.69392 16.1665 9.69375L15.6665 9.69164L16.1665 9.69453C16.1665 9.69444 16.1665 9.69435 16.1665 9.69426ZM8.99985 1.16665L9.00274 1.16663C9.04661 1.16638 9.0901 1.17479 9.13071 1.19137C9.17105 1.20785 9.20776 1.23207 9.23877 1.26267C9.23898 1.26288 9.2392 1.26309 9.23941 1.2633L16.0999 8.13191V8.17357L16.2461 8.31997C16.6206 8.69497 16.831 9.20331 16.831 9.73331C16.831 10.2629 16.6209 10.7709 16.2469 11.1459C16.2466 11.1461 16.2463 11.1464 16.2461 11.1466L11.1061 16.245L11.1049 16.2462C10.7299 16.6207 10.2215 16.8311 9.69152 16.8311C9.16152 16.8311 8.6532 16.6207 8.2782 16.2462C8.27819 16.2462 8.27819 16.2462 8.27819 16.2462L1.26318 9.23953C1.26297 9.23932 1.26275 9.23911 1.26254 9.23889C1.23195 9.20788 1.20773 9.17117 1.19125 9.13083C1.17466 9.09022 1.16626 9.04673 1.16651 9.00286H1.16652V8.99997V1.49997C1.16652 1.41157 1.20164 1.32678 1.26415 1.26427C1.32666 1.20176 1.41145 1.16664 1.49985 1.16664L8.99985 1.16665ZM4.83317 4.62637C4.95651 4.54396 5.10152 4.49997 5.24985 4.49997C5.44876 4.49997 5.63953 4.57899 5.78018 4.71964C5.92083 4.8603 5.99985 5.05106 5.99985 5.24997C5.99985 5.39831 5.95586 5.54331 5.87345 5.66665C5.79104 5.78999 5.67391 5.88612 5.53686 5.94288C5.39982 5.99965 5.24902 6.0145 5.10353 5.98556C4.95805 5.95662 4.82441 5.88519 4.71952 5.7803C4.61463 5.67541 4.5432 5.54178 4.51426 5.39629C4.48532 5.25081 4.50018 5.10001 4.55694 4.96296C4.61371 4.82592 4.70984 4.70878 4.83317 4.62637Z"
        fill="black"
        stroke="currentColor"
      />
    </svg>
  );
};
export default TagIcon;
